import React from 'react';
import { Button, Divider, makeStyles, Modal, Backdrop } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        margin: 'auto',
        width: '500px',
        marginTop: '200px',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
}))

export default function ModalConfirmDocumentoDuplicado(props)  {

    const classes = useStyles()

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>
                <h3 className={classes.title}>
                    Já existe um documento salvo com esse número para esse fornecedor.
                </h3>
                <h3 className={classes.title}>
                    Deseja continuar mesmo assim?
                </h3>

                <form className={classes.form}>
                    <Divider className={classes.divider} />

                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.cancel();
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="inherit"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={props.continue}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Continuar
                        </Button>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}
