import { UnidadesCadastro } from 'pages/unidadesDeMedida/unidades.cadastro'
import { UnidadesDeMedida } from 'pages/unidadesDeMedida'

export const UnidadesDeMedidaRoute = [
    {
        path: '/app/unidades/cadastro',
        key: 'APP_UNIDADES_CADASTRO',
        exact: true,
        component: UnidadesCadastro,
    },
    {
        path: '/app/unidades/listagem',
        key: 'APP_UNIDADES_LISTAGEM',
        exact: true,
        component: UnidadesDeMedida,
    },
    {
        path: '/app/unidades/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_UNIDADES_EDITAR_VISUALIZAR',
        exact: true,
        component: UnidadesCadastro,
    },
]
