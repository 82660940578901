import React, { useState } from 'react'
import MaterialTable from '@material-table/core'
import { Button, TextField, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import NumberFormat from 'react-number-format'
import FormControl from '@material-ui/core/FormControl'
import DateHelper from '../../../utils/dataHelpder'
import { Edit3 as Edit3Icon } from 'react-feather'
import AddIcon from '@material-ui/icons/Add'

const opcoesParcelas = [
    {
        value: 1,
        label: 'À vista',
    },
    {
        value: 2,
        label: '2x',
    },
    {
        value: 3,
        label: '3x',
    },
    {
        value: 4,
        label: '4x',
    },
    {
        value: 5,
        label: '5x',
    },
    {
        value: 6,
        label: '6x',
    },
    {
        value: 7,
        label: '7x',
    },
    {
        value: 8,
        label: '8x',
    },
    {
        value: 9,
        label: '9x',
    },
    {
        value: 10,
        label: '10x',
    },
    {
        value: 11,
        label: '11x',
    },
    {
        value: 12,
        label: '12x',
    },
]

const diasEntreParcelas = [
    {
        value: 1,
        label: '1 dia',
    },
    {
        value: 2,
        label: '2 dias',
    },
    {
        value: 3,
        label: '3 dias',
    },
    {
        value: 4,
        label: '4 dias',
    },
    {
        value: 5,
        label: '5 dias',
    },
    {
        value: 6,
        label: '6 dias',
    },
    {
        value: 7,
        label: '7 dias',
    },
    {
        value: 8,
        label: '8 dias',
    },
    {
        value: 9,
        label: '9 dias',
    },
    {
        value: 10,
        label: '10 dias',
    },
    {
        value: 11,
        label: '11 dias',
    },
    {
        value: 12,
        label: '12 dias',
    },
    {
        value: 13,
        label: '13 dias',
    },
    {
        value: 14,
        label: '14 dias',
    },
    {
        value: 15,
        label: '15 dias',
    },
    {
        value: 30,
        label: '30 dias (1 mês)',
    },
    {
        value: 90,
        label: '90 dias (3 meses)',
    },
    {
        value: 183,
        label: '183 dias (6 meses)',
    },
    {
        value: 365,
        label: '365 dias (1 ano)',
    },
    {
        value: 730,
        label: '730 (2 anos)',
    },
    {
        value: 1095,
        label: '1095 dias (3 anos)',
    },
]

const diasDaParcela = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },
    {
        value: 11,
        label: '11',
    },
    {
        value: 12,
        label: '12',
    },
    {
        value: 13,
        label: '13',
    },
    {
        value: 14,
        label: '14',
    },
    {
        value: 15,
        label: '15',
    },
    {
        value: 16,
        label: '16',
    },
    {
        value: 17,
        label: '17',
    },
    {
        value: 18,
        label: '18',
    },
    {
        value: 19,
        label: '19',
    },
    {
        value: 20,
        label: '20',
    },
    {
        value: 21,
        label: '21',
    },
    {
        value: 22,
        label: '22',
    },
    {
        value: 23,
        label: '23',
    },
    {
        value: 24,
        label: '24',
    },
    {
        value: 25,
        label: '25',
    },
    {
        value: 26,
        label: '26',
    },
    {
        value: 27,
        label: '27',
    },
    {
        value: 28,
        label: '28',
    },
    {
        value: 29,
        label: '29',
    },
    {
        value: 30,
        label: '30',
    },
    {
        value: 31,
        label: '31',
    },
]

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    tabela: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    errors: {
        color: 'red',
    },
}))

export default function Parcelamento(props) {
    const classes = useStyles()
    const [erroNumeroParcelas, setErroNumeroParcelas] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroDataVencimento, setErroDataVencimento] = useState({
        erro: false,
        mensagem: '',
    })
    const [disableDiasEntreParcelas, setDisableDiasEntreParcelas] =
        useState(false)
    const [disableDiaDaParcela, setDisableDiaDaParcela] = useState(false)
    const [hiddenCamposOpcionais, setHiddenCamposOpcionais] = useState(true)

    let colunasParcelas = [
        {
            title: 'Parcela',
            field: 'parcela',
            editable: 'never',
        },
        {
            title: 'Vencimento',
            field: 'dataVencimento',
            editComponent: (prop) => (
                <div>
                    <TextField
                        id="vencimento"
                        name="vencimento"
                        type="date"
                        value={DateHelper.formataStringData(
                            prop.rowData.dataVencimento
                        )}
                        style={{ width: 140 }}
                        error={prop.rowData.dataVencimento === 'ed/ed/'}
                        helperText={
                            prop.rowData.dataVencimento === 'ed/ed/'
                                ? 'Campo obrigatório'
                                : ''
                        }
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            let novoValor =
                                DateHelper.transformStringDataUsaToPtBr(
                                    item.target.value
                                )
                            var data = { ...prop.rowData }
                            data.dataVencimento = novoValor
                            prop.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{inputProps: {max: '9999-12-31'}}}
                    />
                </div>
            ),
        },
        {
            title: 'Valor',
            field: 'valorFormatado',
            editComponent: (prop) => (
                <div>
                    <NumberFormat
                        prefix={'R$ '}
                        error={prop.rowData.valor <= 0}
                        helperText={
                            prop.rowData.valor <= 0 ? 'Campo obrigatório' : ''
                        }
                        style={{ width: 140 }}
                        value={prop.rowData.valor}
                        decimalScale={2}
                        decimalSeparator={','}
                        customInput={TextField}
                        onChange={(item) => {
                            let novoValor = item.target.value
                            if (novoValor.indexOf(',') === -1) {
                                novoValor += ',00'
                            }
                            var data = { ...prop.rowData }
                            data.valor = parseFloat(
                                novoValor
                                    .replace('R$', '')
                                    .replace(' ', '')
                                    .replace(',', '.')
                            )
                            data.valorFormatado = novoValor
                            prop.onRowDataChange(data)
                        }}
                        id="valor"
                        name="valor"
                        onKeyDown={(e) => handleKey(e)}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Valor/Nota',
            field: 'valorTotalNota',
            editable: 'never',
            render: () =>
                props.valorConta?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
        {
            title: 'Situação',
            field: 'situacao',
            editable: 'never',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="situacao"
                        id="situacao"
                        options={props.opcoesSituacoes}
                        error={prop.rowData.situacao === undefined}
                        helperText={
                            prop.rowData.situacao === undefined
                                ? 'Campo obrigatório'
                                : ''
                        }
                        className={classes.textField}
                        value={prop.rowData.situacao}
                        onKeyDown={(e) => handleKey(e)}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.situacao = newValue
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="situacao"
                                id="situacao"
                                error={prop.rowData.situacao === null}
                                helperText={
                                    prop.rowData.situacao === null
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Conta',
            field: 'conta.label',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        options={props.opcoesContas}
                        getOptionLabel={(option) => option.label}
                        disabled
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.conta = newValue
                            prop.onRowDataChange(data)
                        }}
                        style={{ width: 140 }}
                        value={prop.rowData.conta}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={'Selecione'}
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Pago',
            field: 'valorPagoFormatado',
            editable: 'never',
            editComponent: (prop) => (
                <div className="width-100">
                    <NumberFormat
                        prefix={'R$ '}
                        value={prop.rowData.valorPago}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        customInput={TextField}
                        onChange={(item) => {
                            let novoValor = item.target.value
                            var data = { ...prop.rowData }
                            data.valorPago = novoValor.replace('R$', '')
                            data.valorPagoFormatado = novoValor
                            prop.onRowDataChange(data)
                        }}
                        onKeyDown={(e) => handleKey(e)}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Forma Pagamento',
            field: 'formaPagamento.label',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        id="formaPagamento"
                        size="small"
                        name="formaPagamento"
                        disabled
                        style={{ width: 140 }}
                        options={props.opcoesFormaPagamento}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.formaPagamento = newValue
                            prop.onRowDataChange(data)
                        }}
                        value={prop.rowData.formaPagamento}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Selecione"
                                placeholder="Selecione"
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Documento Pago',
            field: 'documentoPago',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="text"
                        value={prop.rowData.documentoPago}
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            if (item.target) {
                                let novoValor = item.target.value
                                var data = { ...prop.rowData }
                                data.documentoPago = novoValor
                                prop.onRowDataChange(data)
                            }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Código de barras',
            field: 'codigoDeBarras',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        id="codigoDeBarras"
                        name="codigoDeBarras"
                        type="text"
                        value={prop.rowData.codigoDeBarras}
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            if (item.target) {
                                let novoValor = item.target.value
                                var data = { ...prop.rowData }
                                data.codigoDeBarras = novoValor
                                prop.onRowDataChange(data)
                            }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Pagamento',
            field: 'dataPagamento',
            editable: 'never',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="date"
                        value={DateHelper.formataStringData(
                            prop.rowData.dataPagamento
                        )}
                        error={prop.rowData.dataPagamento === 'ed/ed/'}
                        helperText={
                            prop.rowData.dataPagamento === 'ed/ed/'
                                ? 'Campo obrigatório'
                                : ''
                        }
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            let novoValor =
                                DateHelper.transformStringDataUsaToPtBr(
                                    item.target.value
                                )
                            var data = { ...prop.rowData }
                            data.dataPagamento = novoValor
                            prop.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            {
                                inputProps: {max: '9999-12-31'}
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Compensação',
            field: 'dataCompensacao',
            editable: 'never',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="date"
                        value={DateHelper.formataStringData(
                            prop.rowData.dataCompensacao
                        )}
                        error={prop.rowData.dataCompensacao === 'ed/ed/'}
                        helperText={
                            prop.rowData.dataCompensacao === 'ed/ed/'
                                ? 'Campo obrigatório'
                                : ''
                        }
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            let novoValor =
                                DateHelper.transformStringDataUsaToPtBr(
                                    item.target.value
                                )
                            var data = { ...prop.rowData }
                            data.dataCompensacao = novoValor
                            prop.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            {
                                inputProps: {max: '9999-12-31'}
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Cheque',
            field: 'cheque',
            editable: 'never',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="text"
                        value={prop.rowData.cheque}
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            if (item.target) {
                                let novoValor = item.target.value
                                var data = { ...prop.rowData }
                                data.cheque = novoValor
                                prop.onRowDataChange(data)
                            }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
    ]

    const limparParcelas = async () => {
        props.limpar()
    }

    const gerarParcelas = async () => {

        // Date.prototype.setDate(int) é um incrementador não um setter como sugere o nome da funcao
        // props.dataVencimento vem 1 dia atrasado - debito tecnico.

        let valido = validaCamposParcelamento()
        if (valido) {
            let valorParcelas = props.valorConta / props.numeroParcelas.value

            valorParcelas = valorParcelas.toFixed(2)
            let parcelas = []
            let vencimento = props.dataVencimento

            let dataComDiasEntreParcelas = new Date(vencimento)
            dataComDiasEntreParcelas.setDate(dataComDiasEntreParcelas.getDate() + 1)

            let dataSomada = new Date(dataComDiasEntreParcelas)
            let aux = dataSomada

            for (var i = 0; i < props.numeroParcelas.value; ++i) {
                dataSomada = aux
                let novaData = new Date(vencimento)
                novaData.setDate(novaData.getDate() + 1)
                var mes = novaData.getMonth() + i
                novaData.setMonth(mes)

                if (props.diasEntreParcelas) {
                    if (i === 0) {
                        dataSomada = dataComDiasEntreParcelas
                    } else {
                        dataSomada.setDate(dataSomada.getDate() + props.diasEntreParcelas.value)
                    }
                }

                if (props.diaDaParcela) {
                    if (i === 0) {
                        dataSomada = dataComDiasEntreParcelas
                    } else {
                        const ultimoDiaDoMes = new Date(dataSomada.getFullYear(), mes + 1, 0).getDate()

                        if (props.diaDaParcela.value <= ultimoDiaDoMes) {
                            dataSomada.setDate(props.diaDaParcela.value)
                        } else {
                            dataSomada.setDate(ultimoDiaDoMes)
                        }

                        dataSomada = new Date(dataSomada.getFullYear(), mes, dataSomada.getDate())
                    }
                }


                if(!props.diaDaParcela && !props.diasEntreParcelas) {
                    if(i === 0) {
                        novaData = DateHelper.converterDataParaString(novaData)
                    } else {

                        let data1vencimento = parcelas[0].dataVencimento.split('/')[0]
                        let ano1vencimento = parcelas[0].dataVencimento.split('/')[2]

                        if(["29", "30", "31"].includes(data1vencimento)) {

                            const ultimoDiaDoMes = new Date(ano1vencimento, mes + 1, 0).getDate()
                            if((data1vencimento) > ultimoDiaDoMes) {
                                novaData = new Date(ano1vencimento, mes + 1, 0)
                                novaData = DateHelper.converterDataParaString(novaData)
                            } else {
                                novaData = DateHelper.converterDataParaString(novaData)
                            }
                        } else novaData = DateHelper.converterDataParaString(novaData)
                    }
                }


                let novaParcela = {
                    parcela:
                        (i + 1).toString() +
                        '/' +
                        props.numeroParcelas.value.toString(),
                    dataVencimento: props.diasEntreParcelas || props.diaDaParcela ? DateHelper.converterDataParaString(dataSomada) : novaData,
                    valorTotalNota: parseFloat(props.valorConta.toFixed(2)),
                    dataCompensacao: '',
                    valor: parseFloat(valorParcelas),
                    valorFormatado: parseFloat(valorParcelas).toLocaleString(
                        'pt-br',
                        {
                            style: 'currency',
                            currency: 'BRL',
                        }
                    ),
                    valorPago: '',
                    codigoDeBarras: '',
                    situacao: 'Definitivo',
                    conta: '',
                    formaPagamento: '',
                    documentoPago: '',
                    cheque: '',
                }

                if (
                    parseFloat(valorParcelas) * props.numeroParcelas.value !==
                        props.valorConta &&
                    i + 1 === props.numeroParcelas.value
                ) {
                    var diferenca =
                        props.valorConta -
                        parseFloat(valorParcelas) * props.numeroParcelas.value
                    novaParcela.valor = novaParcela.valor + diferenca
                    novaParcela.valorFormatado =
                        novaParcela.valor.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })
                }
                parcelas.push(novaParcela)
            }

            props.geraParcelas(parcelas)
        }
    }

    const validaCamposParcelamento = () => {
        let valido = true
        if (!props.dataVencimento) {
            setErroDataVencimento({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroDataVencimento({ erro: false, mensagem: '' })
        }
        if (!props.numeroParcelas) {
            setErroNumeroParcelas({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroNumeroParcelas({ erro: false, mensagem: '' })
        }
        return valido
    }

    const handleKey = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation()
        }
    }

    const validSubmit = async (lista) => {
        let error = true
        await lista.forEach((item) => {
            if (
                item.parcela === (null || '') ||
                item.valor === (null || '' || 'R$' || '$' || 0) ||
                item.valor <= 0 ||
                item.vencimento === (null || 'ed/ed/' || '') ||
                item.situacao === null
            ) {
                error = false
            }
        })

        return error
    }

    return (
        <>
            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <h2 style={{ marginLeft: '5px', marginBottom: '15px' }}>
                        Forma de Pagamento
                    </h2>
                </Grid>
            </Grid>
            {!props.disabled && props.parcelas.length === 0 && (
                <Grid container item xs={12} display="flex">
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                name="parcelas"
                                id="parcelas"
                                options={opcoesParcelas}
                                className={classes.textField}
                                value={props.numeroParcelas}
                                disabled={props.parcelas.length > 0}
                                getOptionLabel={(option) =>
                                    option.label ? option.label : ''
                                }
                                onChange={(event, newValue) => {
                                    if (newValue !== null && newValue.label !== "À vista") {
                                        setHiddenCamposOpcionais(false)
                                    } else setHiddenCamposOpcionais(true)

                                    props.mudaNumeroParcelas(
                                        newValue ? newValue : null
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="parcelas"
                                        id="parcelas"
                                        variant="standard"
                                        label="Parcelas:*"
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            <p className={classes.errors}>
                                {erroNumeroParcelas.erro
                                    ? erroNumeroParcelas.mensagem
                                    : ''}
                            </p>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="dataVenciamento"
                                label={
                                    props.numeroParcelas
                                        ? props.numeroParcelas.value === 1
                                            ? 'Data de vencimento:*'
                                            : 'Data do 1º vencimento:*'
                                        : 'Data de vencimento:*'
                                }
                                name="dataVenciamento"
                                type="date"
                                value={props.dataVencimento}
                                onChange={(e) => props.mudaDataVencimento(e)}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        inputProps: {max: '9999-12-31'}
                                    }
                                }
                            />
                            <p className={classes.errors}>
                                {erroDataVencimento.erro
                                    ? erroDataVencimento.mensagem
                                    : ''}
                            </p>
                        </FormControl>
                    </Grid>
                    {
                        !hiddenCamposOpcionais && (
                            <>
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                name="diasEntreParcelas"
                                id="diasEntreParcelas"
                                options={diasEntreParcelas}
                                className={classes.textField}
                                value={props.diasEntreParcelas}
                                disabled={
                                    props.parcelas.length > 0 ||
                                    disableDiasEntreParcelas
                                }
                                getOptionLabel={(option) =>
                                    option.label ? option.label : ''
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setDisableDiaDaParcela(true)
                                    } else setDisableDiaDaParcela(false)

                                    props.mudaDiasEntreParcelas(
                                        newValue ? newValue : null
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="diasEntreParcelas"
                                        id="diasEntreParcelas"
                                        variant="standard"
                                        label="Dias entre parcelas:"
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                name="diaDaParcela"
                                id="diaDaParcela"
                                options={diasDaParcela}
                                className={classes.textField}
                                onReset={(abc) => console.log(abc)}
                                value={props.diaDaParcela}
                                disabled={
                                    props.parcelas.length > 0 ||
                                    disableDiaDaParcela
                                }
                                getOptionLabel={(option) =>
                                    option.label ? option.label : ''
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setDisableDiasEntreParcelas(true)
                                    } else setDisableDiasEntreParcelas(false)

                                    props.mudaDiaDaParcela(
                                        newValue ? newValue : null
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="diaDaParcela"
                                        id="diaDaParcela"
                                        variant="standard"
                                        label="Dia da parcela:"
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                                        </>
                        )
                    }
                    {props.parcelas.length === 0 && (
                        <Grid
                            xs={2}
                            container
                            item
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={gerarParcelas}
                                size="small"
                                id='gerarParcelasButton'
                            >
                                Gerar Parcelas
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            {props.parcelas.length > 0 && (
                <>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="right"
                        style={{ marginBottom: 10 }}
                    >
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={limparParcelas}
                            size="small"
                        >
                            Limpar Parcelas
                        </Button>
                    </Grid>
                </>
            )}

            <Grid className={classes.tabela}>
                <MaterialTable
                    title="Parcelas"
                    columns={colunasParcelas}
                    data={props.parcelas}
                    options={{
                        paging: false,
                        search: false,
                        rowStyle: {
                            fontSize: 14,
                        },
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        toolbar: !props.disabled,
                        emptyRowsWhenPaging: false,
                        showTitle: false,
                    }}
                    onBulkEditOpen={(edit) => {
                        if (props.onChangeEdit) {
                            props.onChangeEdit(edit)
                        }
                    }}
                    editable={{
                        isDeletable: (rowData) => !props.disabled,

                        onBulkUpdate: (changes) =>
                            new Promise(async (resolve, reject) => {
                                let array = []
                                // eslint-disable-next-line no-unused-vars
                                for (let [key, value] of Object.entries(
                                    changes
                                )) {
                                    array.push({
                                        id: value.newData.id,
                                        parcela: value.newData.parcela,
                                        valor: value.newData.valor
                                            ? value.newData.valor
                                            : '',
                                        valorFormatado: value.newData
                                            .valorFormatado
                                            ? value.newData.valorFormatado
                                            : '',
                                        valorTotalNota: props.valorConta,
                                        valorPago: value.newData.valorPago
                                            ? value.newData.valorPago
                                            : '',
                                        valorPagoFormatado: value.newData
                                            .valorPagoFormatado
                                            ? value.newData.valorPagoFormatado
                                            : '',
                                        dataVencimento:
                                            value.newData.dataVencimento,
                                        dataCompensacao:
                                            value.newData.dataCompensacao,
                                        quantidadeTotal:
                                            value.newData.quantidadeTotal,
                                        codigoDeBarras:
                                            value.newData.codigoDeBarras,
                                        situacao: value.newData.situacao,
                                        conta: value.newData.conta,
                                        dataPagamento:
                                            value.newData.dataPagamento,
                                        documentoPago:
                                            value.newData.documentoPago,
                                        cheque: value.newData.cheque,
                                        tableData: value.newData.tableData,
                                        formaPagamento:
                                            value.newData.formaPagamento,
                                    })
                                }
                                let valido = await validSubmit(array)
                                if (valido) {
                                    props.atualizaParcelas(array)
                                    resolve()
                                } else {
                                    reject()
                                }
                            }),
                    }}
                    icons={{
                        Edit: Edit3Icon,
                        Add: (props) => {
                            return (
                                <AddIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        borderRadius: 100,
                                    }}
                                />
                            )
                        },
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro para exibir',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Editar',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                        },
                    }}
                />
            </Grid>
        </>
    )
}

/*parseFloat(
    value.newData.valor
        .toString()
        .replace('R$ ', ''),
).toFixed(2)*/
