import * as Yup from 'yup'

export const ContaBancariaEsquemaTipoBanco = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    banco: Yup.string().nullable(),
    agencia: Yup.string().nullable(),
    conta: Yup.string().nullable(),
    dataSaldoInicial: Yup.string().required('Campo obrigatório'),
    limite: Yup.string(),
    saldoInicial: Yup.string()
        .required('Campo obrigatório')
        .test(
            'saldoInicial',
            'Saldo Inicial não pode ser maior que o Limite',
            function (item) {
                var itemFormatado = parseFloat(
                    item.replaceAll('.', '').replace(',', '.').replace('R$', '')
                )
                var limiteFormatado = parseFloat(
                    this.parent.limite
                        .replaceAll('.', '')
                        .replace(',', '.')
                        .replace('R$', '')
                )
                if (itemFormatado > limiteFormatado && limiteFormatado > 0) {
                    return false
                } else {
                    return true
                }
            }
        ),
})

export const ContaBancariaEsquemaTipoInvestimento = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    banco: Yup.string().required('Campo obrigatório').nullable(),
    agencia: Yup.string().required('Campo obrigatório'),
    conta: Yup.string().required('Campo obrigatório'),
    dataSaldoInicial: Yup.string().required('Campo obrigatório'),
    saldoInicial: Yup.string()
        .required('Campo obrigatório')

})

export const ContaBancariaEsquemaTipoCaixa = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    dataSaldoInicial: Yup.string().required('Campo obrigatório'),
    limite: Yup.string(),
    saldoInicial: Yup.string()
        .required('Campo obrigatório')
        .test(
            'saldoInicial',
            'Saldo Inicial não pode ser maior que o Limite',
            function (item) {
                var itemFormatado = parseFloat(
                    item.replaceAll('.', '').replace(',', '.').replace('R$', '')
                )
                var limiteFormatado = parseFloat(
                    this.parent.limite
                        .replaceAll('.', '')
                        .replace(',', '.')
                        .replace('R$', '')
                )
                if (itemFormatado > limiteFormatado && limiteFormatado > 0) {
                    return false
                } else {
                    return true
                }
            }
        ),
})

export const ContaBancariaEsquemaTipoCartaoCredito = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    dataSaldoInicial: Yup.string().required('Campo obrigatório'),
    limite: Yup.string(),
    saldoInicial: Yup.string()
        .required('Campo obrigatório')
        .test(
            'saldoInicial',
            'Saldo Inicial não pode ser maior que o Limite',
            function (item) {
                var itemFormatado = parseFloat(
                    item.replaceAll('.', '').replace(',', '.').replace('R$', '')
                )
                var limiteFormatado = parseFloat(
                    this.parent.limite
                        .replaceAll('.', '')
                        .replace(',', '.')
                        .replace('R$', '')
                )
                if (itemFormatado > limiteFormatado && limiteFormatado > 0) {
                    return false
                } else {
                    return true
                }
            }
        ),
})
