import { FornecedorCliente } from '../pages/fornecedorCliente'
import { FornecedorClienteCadastro } from '../pages/fornecedorCliente/FornecedorClienteCadastro'

export const fornecedorClienteRoute = [
    {
        path: '/app/fornecedorCliente',
        key: 'APP_FORNECEDOR_CLIENTE_LISTAGEM',
        exact: true,
        component: FornecedorCliente,
    },
    {
        path: '/app/fornecedorCliente/cadastro',
        key: 'APP_FORNECEDOR_CLIENTE_CADASTRO',
        exact: true,
        component: FornecedorClienteCadastro,
    },
    {
        path: '/app/fornecedorCliente/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_FORNECEDOR_CLIENTE_EDITAR',
        exact: true,
        component: FornecedorClienteCadastro,
    },
    {
        path: '/app/fornecedorCliente/cadastro/:modo(aba)',
        key: 'APP_FORNECEDOR_CLIENTE_CADASTRO_MODO',
        exact: true,
        component: FornecedorClienteCadastro,
    },
]
