import React, { useState, useEffect } from 'react'
import Page from '../../../components/Page'
import Titulo from '../../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Table from '../../../components/Table'
import {
    Container,
    Card,
    Divider,
    CardContent,
    TextField,
    Button,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Parcelamento from './Parcelamento'
import DataHelper from '../../../utils/dataHelpder'
import ModalProdutosServicos from './ModalProdutosServicos'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Request from '../requests'
import ToastHelper from 'utils/toastHelper'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import PedidosService from 'services/PedidosService'
import { cadastroEsquema } from './cadastro.validate'
import { yupResolver } from '@hookform/resolvers'
import NumberFormat from 'react-number-format'
import ObjectHelper from 'utils/objetcHelper'
import ContaBancariaService from '../../../services/ContaBancariaService'
import FormaPagamentoService from '../../../services/TipoPagamentoRecebimentoService'
import AsyncAutoComplete from '../../../components/AsyncAutoComplete/index'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonModalProdutosServicos: {
        marginBottom: '-25px',
        zIndex: 9,
        marginRight: 10,
        marginTop: 10,
        float: 'right',
    },
}))

export const PedidosCadastro = (props) => {
    const fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
    const classes = useStyles()
    const [titulo, setTitulo] = useState('Cadastro de Pedido')
    const {
        match: { params },
    } = props

    const [parcelas, setParcelas] = useState([])
    const [numeroParcelas, setNumeroParcelas] = useState()
    const [dataVencimento, setDataVencimento] = useState('')

    const [opcoesContas, setOpcoesContas] = useState([])
    const [opcoesFormaPagamento, setOpcoesFormaPagamento] = useState([])
    const [opcoesProdutosServicos, setOpcoesProdutosServicos] = useState([])
    const [opcoesCentroCusto, setOpcoesCentroCusto] = useState([])
    const [opcoesContaGerencial, setOpcoesContaGerencial] = useState([])
    const [fornecedoresClientes, setFornecedores] = useState([])

    const [acrescimo, setAcrescimo] = React.useState(0)
    const [desconto, setDesconto] = React.useState(0)

    const history = useHistory()

    const pathname = window.location.pathname.split("/")[3]
    const isVisualizar = pathname === "visualizar"

    const [form, setForm] = useState({
        dataPedido: DataHelper.today(),
        fazendaId: fazendaId,
        pedidoFeitoA: 'Fornecedor',
    })

    const [produtoServicoListagem, setProdutoServicoListagem] = React.useState(
        []
    )

    const tiposFornecedorCliente = [
        {
            value: 'FORNECEDOR',
            label: 'Fornecedor',
        },
        {
            value: 'CLIENTE',
            label: 'Cliente',
        },
    ]

    const [openModalProdutosServicos, setOpenModalProdutosServicos] =
        React.useState(false)

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {},
        resolver: yupResolver(cadastroEsquema),
    })

    useEffect(() => {
        async function init() {
            await geraNumeroPedido()
            await buscarFornecedoresClientes('Fornecedor')
            await buscarContasBancarias()
            await buscarFormasPagamento()
            await buscarProdutosServicos()
            await buscarCentrosDeCusto()
            await buscarContasGerenciais()
            if (params.id) {
                if (pathname === "visualizar") setTitulo("Visualizar Pedido")
                else setTitulo('Editar Pedido')

                await buscarPorId(params.id)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        buscarFornecedoresClientes(form.pedidoFeitoA)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.pedidoFeitoA])

    useEffect(() => {
        let newValor = 0

        produtoServicoListagem.forEach((item) => {
            newValor += item.valorTotal
        })

        var valorLiquido = newValor + acrescimo - desconto

        setForm({ ...form, valorTotal: newValor, valorLiquido: valorLiquido })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtoServicoListagem])

    useEffect(() => {
        let _parcelas = parcelas
        _parcelas.forEach((item) => {
            item.valorTotalNota = parseFloat(
                form.valorLiquido.toFixed(2)
            ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        })
        atualizaParcelas(_parcelas)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.valorLiquido])

    const handleOpenModalProdutosServicos = () => {
        setOpenModalProdutosServicos(true)
    }

    const handleCloseModalProdutosServicos = () => {
        setOpenModalProdutosServicos(false)
    }

    const geraNumeroPedido = () => {
        PedidosService.gerarNumeroPedido(fazendaId).then((response) => {
            if (response.status === 200) {
                setForm({ ...form, numeroPedido: response.data })
            }
        })
    }

    const changeValorLiquido = () => {
        var descontosConvertidos = desconto ? desconto : 0
        var acrescimosConvertidos = acrescimo ? acrescimo : 0
        var liquido =
            form.valorTotal + acrescimosConvertidos - descontosConvertidos
        setForm({
            ...form,
            valorLiquido: liquido,
            valorTotalNota: liquido,
        })
    }

    const onChangeAcrescimos = (e) => {
        let value = e.target.value

        if (value === '') value = 'R$ 0,00'

        let acrescimo = parseFloat(
            value
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
        )
        setAcrescimo(acrescimo)
        setForm({ ...form, acrescimo: e.target.value })
    }

    const onChangeDescontos = (e) => {
        let value = e.target.value

        if (value === '') value = 'R$ 0,00'

        let desconto = parseFloat(
            value
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
        )
        setDesconto(desconto)
        setForm({ ...form, desconto: e.target.value })
    }

    const buscarPorId = async (id) => {
        let data = await Request.buscarPedidos(id)
        data.pedidos.modalItens = true
        data.pedidos.valorTotal = data.valorTotal
        data.pedidos.valorLiquido = data.valorLiquido
        data.pedidos.pedidoFeitoA =
            data.pedidos.pedidoFeitoA === 0 ? 'Fornecedor' : 'Cliente'

        await Request.buscarFornecedoresClientes(
            data.pedidos.pedidosFeitoA === 0 ? 'Fornecedor' : 'Cliente'
        )

        await setForm(data.pedidos)
        await setProdutoServicoListagem(data.produtoServico)
        await setParcelas(data.parcelas)
        await setAcrescimo(data.pedidos.acrescimo)
        await setDesconto(data.pedidos.desconto)
    }

    const buscarFornecedoresClientes = async (tipoFornecedorCliente) => {
        let data = await Request.buscarFornecedoresClientes(
            tipoFornecedorCliente
        )
        setFornecedores(data)
    }

    const buscarUnidades = async (unidadeAtual) => {
        let data = await Request.buscarUnidadesDeMedida(unidadeAtual)
        return data
    }

    const validSubmit = () => {
        let valorParcelas = 0

        parcelas.forEach((item) => {
            valorParcelas += item.valor
        })

        if (produtoServicoListagem.length === 0) {
            ToastHelper.error(
                'Necessário escolher pelo menos um produto ou serviço e adicionar uma forma de pagamento!'
            )
            return false
        }

        if (valorParcelas !== form.valorLiquido) {
            ToastHelper.error(
                'O valor das parcelas R$' +
                    valorParcelas +
                    ' não confere com o valor total do pedido R$' +
                    form.valorLiquido
            )
            return false
        }

        if (parcelas.length === 0) {
            ToastHelper.error(
                'Necessário escolher pelo menos uma forma de pagamento!'
            )
            return false
        }
        return true
    }

    const dadosPedidoAdiantamento = () => {
        let dados = form
        dados.parcelas = parcelas
        dados.pedidos = produtoServicoListagem
        dados.acrescimo = acrescimo
        dados.desconto = desconto
        dados.id = params.id

        return dados
    }

    const onSubmitPedidoAdiantamento = async (dados) => {
        let data = Request.cadastrar(dados)
        data.then((res) => {
            ToastHelper.success('Pedido registrado com sucesso!')
        }).catch((e) => {
            if (e)
                return ToastHelper.error(
                    'Ocorreu um erro, por favor acione o administrador do sistema.'
                )
        })
    }

    const submitEGerarAdiantamento = async () => {
        if (!validSubmit()) return

        const dadosPedido = params.id ? null : dadosPedidoAdiantamento()

        if (parcelas.length !== 1) {
            ToastHelper.error(
                'Somente Pedidos com uma parcela podem gerar Adiantamento a Pagar'
            )

            return
        }

        history.push({
            pathname: '/app/adiantamentoFornecedoresClientes/pagar/cadastro',
            adiantamentoPagar: {
                ...form,
                dataVencimento: parcelas[0].dataVencimento,
                valor: parcelas[0].valor,
            },
            dadosPedido,
            onSubmitPedidoAdiantamento,
        })
    }

    const onSubmit = async (tipo) => {
        if (!validSubmit()) return
        let dados = form
        dados.parcelas = parcelas
        dados.pedidos = produtoServicoListagem
        dados.acrescimo = acrescimo
        dados.desconto = desconto
        dados.id = params.id
        if (params.id) {
            Request.atualizar(dados)
                .then((res) => {
                    switch (tipo) {
                        case 'gerar':
                            window.location.href = `/app/conta/pagar/gerar/${res.data.id}`
                            break
                        default:
                            window.location.href = '/app/pedidos/'
                    }

                    ToastHelper.success('Pedido atualizado com sucesso!')
                })
                .catch((e) => {
                    if (e)
                        return ToastHelper.error(
                            'Ocorreu um erro, por favor acione o administrador do sistema.'
                        )
                })
        } else {
            let data = Request.cadastrar(dados)
            data.then((res) => {
                switch (tipo) {
                    case 'gerar':
                        window.location.href = `/app/conta/pagar/gerar/${res.data.id}`
                        break
                    default:
                        window.location.href = '/app/pedidos/'
                }
                ToastHelper.success('Pedido registrado com sucesso!')
            }).catch((e) => {
                if (e)
                    return ToastHelper.error(
                        'Ocorreu um erro, por favor acione o administrador do sistema.'
                    )
            })
        }
    }

    const geraParcelas = (novasParcelas) => {
        setParcelas(novasParcelas)
    }

    const mudaNumeroParcelas = (event) => {
        setNumeroParcelas(event)
    }

    const mudaDataVencimento = (novaData) => {
        if (novaData) setDataVencimento(novaData.target.value)
        else setDataVencimento('')
    }

    const atualizaParcelas = (novasParcelas) => {
        let arrayParcelas = [...parcelas]
        var parcelasAtualizadas = []
        arrayParcelas.forEach((antigas) => {
            novasParcelas.forEach((novas) => {
                if (antigas.tableData.id === novas.tableData.id) {
                    antigas = novas
                }
            })
            parcelasAtualizadas.push(antigas)
        })
        setParcelas(parcelasAtualizadas)
    }

    const deletaParcela = (novasParcelas) => {
        let data = [...novasParcelas]
        setParcelas(data)
    }

    const limpar = () => {
        setParcelas([])
    }

    const geraItens = async (itens) => {
        await produtoServicoListagem.push(itens)
        var valorTotal = 0
        produtoServicoListagem.forEach(
            (item) => (valorTotal += item.valorTotal)
        )

        var newValorLiquido = valorTotal + acrescimo - desconto

        await setForm({
            ...form,
            valorLiquido: newValorLiquido,
            valorTotal: valorTotal,
            quantidadeItens: produtoServicoListagem.length,
        })
    }

    const colunas = [
        {
            title: 'Item',
            field: 'produtoServico.nome',
            editComponent: (prop) => (
                <div>
                    <Autocomplete
                        name="produtoServico"
                        id="produtoServico"
                        options={opcoesProdutosServicos}
                        className={classes.textField}
                        value={
                            prop.rowData.produtoServico
                                ? prop.rowData.produtoServico
                                : ''
                        }
                        defaultValue={
                            prop.rowData.produtoServico
                                ? prop.rowData.produtoServico
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => option.nome}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }

                            data.unidade = null
                            data.unidadeId = null

                            newValue
                                ? (data.produtoServico = newValue)
                                : (data.produtoServico = null)
                            newValue
                                ? (data.produtoServicoId = newValue.id)
                                : (data.produtoServicoId = null)
                            if (!data.produtoServico?.produto) {
                                data.unidadeId = null
                                data.unidade = []
                                data.localEstoqueId = null
                                data.localEstoque = []
                            }
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={
                                    prop.rowData.produtoServico === null ||
                                    prop.rowData.produtoServico === ''
                                }
                                helperText={
                                    prop.rowData.produtoServico === null ||
                                    prop.rowData.produtoServico === ''
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                                name="produtoServico"
                                id="produtoServico"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Unidade',
            field: 'unidade.sigla',
            editComponent: (prop) => (
                <div className="width-100">
                    <AsyncAutoComplete
                        id="unidade"
                        name="unidade"
                        disabled={!prop.rowData.produtoServico?.produto}
                        style={{ width: 150 }}
                        disableClearable
                        value={prop.rowData.unidade ? prop.rowData.unidade : ''}
                        className={classes.textField}
                        getOptionLabel={(option) => option.sigla}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.unidade = newValue
                            data.unidadeId = newValue.id
                            prop.onRowDataChange(data)
                        }}
                        defaultValue={
                            prop.rowData.unidade ? prop.rowData.unidade : ''
                        }
                        asyncLoadOptions={() =>
                            buscarUnidades(
                                prop.rowData.produtoServico?.unidadeMedidaId
                            )
                        }
                        useCache
                        cacheHash={prop.rowData.produtoServico?.nome}
                        error={
                            prop.rowData.produtoServico?.produto === true
                                ? !prop.rowData.unidadeId
                                : ''
                        }
                        helperText={
                            prop.rowData.produtoServico?.produto === true
                                ? !prop.rowData.unidadeId
                                    ? 'Campo Obrigatório'
                                    : ''
                                : ''
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="unidade"
                                id="unidade"
                                error={
                                    prop.rowData.unidade === null ||
                                    prop.rowData.unidade === [] ||
                                    prop.rowData.unidade === ''
                                }
                                helperText={
                                    prop.rowData.unidade === null ||
                                    prop.rowData.unidade === [] ||
                                    prop.rowData.unidade === ''
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Quantidade',
            field: 'quantidade',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="text"
                        value={prop.rowData.quantidade}
                        error={prop.rowData.quantidade <= 0}
                        helperText={
                            prop.rowData.quantidade <= 0 ? 'Obrigatório' : ''
                        }
                        onChange={(item) => {
                            var data = { ...prop.rowData }
                            item.target
                                ? (data.quantidade = item.target.value)
                                : (data.quantidade = 0)

                            let novoValorTotal =
                                data.valorUnitario * data.quantidade
                            data.valorTotal = novoValorTotal

                            prop.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Unitário',
            field: 'valorUnitario',
            render: (rowData) =>
                rowData.valorUnitario?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
            editComponent: (prop) => (
                <div>
                    <NumberFormat
                        prefix={'R$ '}
                        style={{ width: 140 }}
                        value={prop.rowData.valorUnitario}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        error={prop.rowData.valorUnitario <= 0}
                        helperText={
                            prop.rowData.valorUnitario <= 0
                                ? 'Campo obrigatório'
                                : ''
                        }
                        fixedDecimalScale={2}
                        onChange={(item) => {
                            let novoValor = item.target.value

                            var data = { ...prop.rowData }

                            if (novoValor === '') novoValor = 'R$ 0,00'

                            data.valorUnitario = parseFloat(
                                novoValor
                                    .replace('R$', '')
                                    .replace(' ', '')
                                    .replace(',', '.')
                            )

                            let novoValorTotal =
                                data.valorUnitario * data.quantidade
                            data.valorTotal = novoValorTotal

                            prop.onRowDataChange(data)
                        }}
                        id="valorUnitario"
                        name="valorUnitario"
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Total',
            field: 'valorTotal',
            render: (rowData) =>
                rowData.valorTotal?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
            editComponent: (prop) => (
                <div>
                    <NumberFormat
                        prefix={'R$ '}
                        style={{ width: 140 }}
                        value={prop.rowData.valorTotal}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        error={prop.rowData.valorTotal <= 0}
                        helperText={
                            prop.rowData.valorTotal <= 0
                                ? 'Campo obrigatório'
                                : ''
                        }
                        fixedDecimalScale={2}
                        onChange={(item) => {
                            let novoValor = item.target.value

                            var data = { ...prop.rowData }

                            if (novoValor === '') novoValor = 'R$ 0,00'

                            data.valorTotal = parseFloat(
                                novoValor
                                    .replace('R$', '')
                                    .replace(' ', '')
                                    .replace(',', '.')
                            )

                            let novoValorUnitario =
                                data.valorTotal / data.quantidade
                            data.valorUnitario = novoValorUnitario

                            prop.onRowDataChange(data)
                        }}
                        id="valorTotal"
                        name="valorTotal"
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },

        {
            title: 'Centro Custo',
            field: 'centroCusto.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="centroCusto"
                        id="centroCusto"
                        options={opcoesCentroCusto}
                        className={classes.textField}
                        disabled={
                            prop.rowData.localEstoqueId || prop.rowData.safraId
                        }
                        value={
                            prop.rowData.centroCusto
                                ? prop.rowData.centroCusto
                                : ''
                        }
                        defaultValue={
                            prop.rowData.centroCusto
                                ? prop.rowData.centroCusto
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => option.descricao}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.centroCusto = newValue
                            data.safra = ''
                            data.safraId = null
                            data.localEstoque = ''
                            data.localEstoqueId = null
                            newValue
                                ? (data.centroCustoId = newValue.id)
                                : (data.centroCustoId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="centroCusto"
                                id="centroCusto"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Conta Gerencial',
            field: 'contaGerencial.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="contaGerencial"
                        id="contaGerencial"
                        options={opcoesContaGerencial}
                        className={classes.textField}
                        value={
                            prop.rowData.contaGerencial
                                ? prop.rowData.contaGerencial
                                : ''
                        }
                        defaultValue={
                            prop.rowData.contaGerencial
                                ? prop.rowData.contaGerencial
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) =>
                            option.descricao
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.contaGerencial = newValue
                            newValue
                                ? (data.contaGerencialId = newValue.id)
                                : (data.contaGerencialId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="contaGerencial"
                                id="contaGerencial"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Previsão de Entrega',
            field: 'previsaoEntrega',
            editComponent: (props) => (
                <div className="width-100">
                    <TextField
                        type="date"
                        value={DataHelper.formataStringData(
                            props.rowData.previsaoEntrega
                        )}
                        error={props.rowData.previsaoEntrega === 'ed/ed/'}
                        helperText={
                            props.rowData.previsaoEntrega === 'ed/ed/'
                                ? 'Campo obrigatório'
                                : ''
                        }
                        onChange={(item) => {
                            let novoValor =
                                DataHelper.transformStringDataUsaToPtBr(
                                    item.target.value
                                )
                            var data = { ...props.rowData }
                            data.previsaoEntrega = novoValor
                            props.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: { max: '9999-12-31' },
                        }}
                    />
                </div>
            ),
        },
    ]

    const buscarContasBancarias = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        await ContaBancariaService.buscarTudo(params).then((response) => {
            setOpcoesContas(
                ObjectHelper.getValuesDescricaoLabelParseToSelect(
                    response.data.itens
                )
            )
        })
    }

    const buscarFormasPagamento = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        let params = await ObjectHelper.createParams(filtro)
        await FormaPagamentoService.buscarTudo(params).then(
            async (response) => {
                if (response.status === 200) {
                    let itens =
                        await ObjectHelper.getValuesFormaPagamentoParseToSelect(
                            response.data.itens
                        )
                    setOpcoesFormaPagamento(itens)
                } else {
                    ToastHelper.error(response.statusText)
                }
            }
        )
    }

    const buscarProdutosServicos = async () => {
        let data = await Request.buscarProdutosServicos()
        setOpcoesProdutosServicos(data)
    }

    const buscarCentrosDeCusto = async () => {
        let data = await Request.buscarCentrosDeCusto()
        setOpcoesCentroCusto(data)
    }

    const buscarContasGerenciais = async () => {
        let data = await Request.buscarContasGerenciais()
        setOpcoesContaGerencial(data)
    }

    const validSubmitEditTableProdutosServicos = async (lista) => {
        let error = true
        await lista.forEach((item) => {
            if (
                item.produtoServico === null ||
                item.valorUnitario === (null || '' || 'R$' || '$') ||
                item.valorUnitario <= 0 ||
                item.valorTotal <= 0 ||
                item.quantidade <= 0 ||
                // item.unidade === [] ||
                // item.unidade === '' ||
                // item.unidade === null ||
                item.contaGerencial === null
            ) {
                error = false
            }
        })

        return error
    }

    const submitEGerarConta = async () => await onSubmit('gerar')

    return (
        <Page className={classes.root} title={titulo}>
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Nº do Pedido:"
                                            disabled={true}
                                            value={form.numeroPedido}
                                            className={classes.textField}
                                            inputRef={register}
                                            id="numeroPedido"
                                            name="numeroPedido"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="date"
                                            label="Data do Pedido:*"
                                            disabled={isVisualizar}
                                            value={form.dataPedido}
                                            defaultValue={form.dataPedido}
                                            className={classes.textField}
                                            onChange={(event) => {
                                                setForm({
                                                    ...form,
                                                    dataPedido:
                                                        event.target.value,
                                                })
                                            }}
                                            inputRef={register}
                                            id="dataPedido"
                                            name="dataPedido"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.dataPedido
                                                ? errors.dataPedido.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="pedidoFeitoA"
                                            id="pedidoFeitoA"
                                            disabled={isVisualizar}
                                            options={
                                                tiposFornecedorCliente || []
                                            }
                                            getOptionLabel={(option) =>
                                                option?.label
                                            }
                                            value={
                                                form.pedidoFeitoA
                                                    ? tiposFornecedorCliente.find(
                                                          (item) =>
                                                              item.label ===
                                                              form.pedidoFeitoA
                                                      )
                                                    : ''
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setForm({
                                                    ...form,
                                                    pedidoFeitoA: newValue
                                                        ? newValue.label
                                                        : '',
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="pedidoFeitoA"
                                                    id="pedidoFeitoA"
                                                    variant="standard"
                                                    label="Pedido feito a:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.pedidoFeitoA
                                                ? errors.pedidoFeitoA.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="fornecedorCliente"
                                            id="fornecedorClienteId"
                                            disabled={isVisualizar}
                                            options={fornecedoresClientes || []}
                                            value={
                                                form.fornecedorCliente
                                                    ? form.fornecedorCliente
                                                    : ''
                                            }
                                            defaultValue={
                                                form.fornecedorCliente
                                                    ? form.fornecedorCliente
                                                    : ''
                                            }
                                            getOptionLabel={(option) =>
                                                option ? option.label : ''
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setForm({
                                                    ...form,
                                                    fornecedorClienteId:
                                                        newValue?.value,
                                                    fornecedorCliente:
                                                        newValue || {},
                                                    modalItens: true,
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="fornecedorCliente"
                                                    disabled={isVisualizar}
                                                    id="fornecedorClienteId"
                                                    variant="standard"
                                                    label={
                                                        form.pedidoFeitoA ===
                                                        'Fornecedor'
                                                            ? 'Fornecedor*:'
                                                            : 'Cliente*:'
                                                    }
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.fornecedorCliente?.message}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlObs}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição:"
                                            className={classes.textField}
                                            inputRef={register}
                                            disabled={isVisualizar}
                                            id="descricao"
                                            name="descricao"
                                            value={form.descricao}
                                            defaultValue={form.descricao}
                                            onChange={(event) =>
                                                setForm({
                                                    ...form,
                                                    descricao:
                                                        event.target.value,
                                                })
                                            }
                                            multiline
                                            variant="outlined"
                                            rows={2}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {form.modalItens && (
                                <>
                                    <Divider className={classes.divider} />
                                    {
                                        !isVisualizar && (
                                            <Fab
                                                onClick={
                                                    handleOpenModalProdutosServicos
                                                }
                                                className={
                                                    classes.buttonModalProdutosServicos
                                                }
                                                color="primary"
                                                size="small"
                                                aria-label="add"
                                            >
                                                <AddIcon />
                                            </Fab>
                                        )
                                    }
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ display: 'block' }}
                                    >
                                        <Table
                                            id="table"
                                            title={'Produtos e Serviços'}
                                            columns={colunas}
                                            emptyRowsWhenPaging={false}
                                            data={produtoServicoListagem}
                                            page={1}
                                            totalCount={1}
                                            pageSize={
                                                produtoServicoListagem
                                                    ? produtoServicoListagem.length
                                                    : 100
                                            }
                                            paging={false}
                                            editable={!isVisualizar && {
                                                isEditable: false,
                                                onRowDelete: (oldData) =>
                                                    new Promise(
                                                        (resolve, reject) => {
                                                            const dataDelete = [
                                                                ...produtoServicoListagem,
                                                            ]
                                                            const index =
                                                                oldData
                                                                    .tableData
                                                                    .id
                                                            dataDelete.splice(
                                                                index,
                                                                1
                                                            )
                                                            setProdutoServicoListagem(
                                                                dataDelete
                                                            )
                                                            resolve()
                                                        }
                                                    ),

                                                onBulkUpdate: (changes) =>
                                                    new Promise(
                                                        async (
                                                            resolve,
                                                            reject
                                                        ) => {
                                                            let array = [
                                                                ...produtoServicoListagem,
                                                            ]

                                                            for (let [
                                                                // eslint-disable-next-line no-unused-vars
                                                                key,
                                                                value,
                                                            ] of Object.entries(
                                                                changes
                                                            )) {
                                                                const index =
                                                                    value
                                                                        .oldData
                                                                        .tableData
                                                                        .id
                                                                array[index] =
                                                                    value.newData
                                                            }

                                                            let valido =
                                                                await validSubmitEditTableProdutosServicos(
                                                                    array
                                                                )
                                                            if (valido) {
                                                                setProdutoServicoListagem(
                                                                    array
                                                                )
                                                                resolve()
                                                            } else {
                                                                reject()
                                                            }
                                                        }
                                                    ),
                                            }}
                                        />

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <TextField
                                                        label="Quantidade de itens"
                                                        value={
                                                            produtoServicoListagem
                                                                ? produtoServicoListagem.length
                                                                : ''
                                                        }
                                                        disabled={true}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <TextField
                                                        label="Valor dos Produtos/Serviços"
                                                        value={
                                                            form.valorTotal
                                                                ? form.valorTotal.toLocaleString(
                                                                      'pt-br',
                                                                      {
                                                                          style: 'currency',
                                                                          currency:
                                                                              'BRL',
                                                                      }
                                                                  )
                                                                : ''
                                                        }
                                                        defaultValue={
                                                            form.valorTotal
                                                                ? form.valorTotal.toLocaleString(
                                                                      'pt-br',
                                                                      {
                                                                          style: 'currency',
                                                                          currency:
                                                                              'BRL',
                                                                      }
                                                                  )
                                                                : ''
                                                        }
                                                        disabled={true}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <NumberFormat
                                                        prefix="R$"
                                                        label="Acréscimos:"
                                                        disabled={isVisualizar}
                                                        value={acrescimo}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        decimalScale={2}
                                                        fixedDecimalScale={2}
                                                        customInput={TextField}
                                                        onChange={(e) =>
                                                            onChangeAcrescimos(
                                                                e
                                                            )
                                                        }
                                                        inputRef={register}
                                                        id="acrescimo"
                                                        name="acrescimo"
                                                        inputProps={{
                                                            min: 0,
                                                            style: {
                                                                textAlign:
                                                                    'left',
                                                            },
                                                        }}
                                                        isplaceholderanimated={
                                                            (false, 'top')
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onBlur={
                                                            changeValorLiquido
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <NumberFormat
                                                        prefix="R$"
                                                        label="Descontos:"
                                                        disabled={isVisualizar}
                                                        value={desconto}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        decimalScale={2}
                                                        fixedDecimalScale={2}
                                                        customInput={TextField}
                                                        onChange={(e) =>
                                                            onChangeDescontos(e)
                                                        }
                                                        inputRef={register}
                                                        id="desconto"
                                                        name="desconto"
                                                        inputProps={{
                                                            min: 0,
                                                            style: {
                                                                textAlign:
                                                                    'left',
                                                            },
                                                        }}
                                                        isplaceholderanimated={
                                                            (false, 'top')
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onBlur={
                                                            changeValorLiquido
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <TextField
                                                        label="Valor Líquido"
                                                        value={
                                                            form.valorLiquido
                                                                ? form.valorLiquido.toLocaleString(
                                                                      'pt-br',
                                                                      {
                                                                          style: 'currency',
                                                                          currency:
                                                                              'BRL',
                                                                      }
                                                                  )
                                                                : ''
                                                        }
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Divider className={classes.divider} />

                            {produtoServicoListagem.length > 0 && (
                                <>
                                    <Grid container item xs={12} display="flex">
                                        <Parcelamento
                                            limpar={limpar}
                                            numeroParcelas={numeroParcelas}
                                            mudaNumeroParcelas={
                                                mudaNumeroParcelas
                                            }
                                            geraParcelas={geraParcelas}
                                            dataVencimento={dataVencimento}
                                            mudaDataVencimento={
                                                mudaDataVencimento
                                            }
                                            valorConta={
                                                form.valorLiquido
                                                    ? form.valorLiquido
                                                    : ''
                                            }
                                            parcelas={parcelas ? parcelas : []}
                                            deletaParcela={deletaParcela}
                                            isVisualizar={isVisualizar}
                                            atualizaParcelas={atualizaParcelas}
                                            opcoesContas={opcoesContas}
                                            opcoesFormaPagamento={
                                                opcoesFormaPagamento
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <Grid
                        container
                        item
                        xs={12}
                        display="flex"
                        className={classes.card}
                    >
                        <Grid
                            xs={4}
                            container
                            item
                            direction="row"
                            alignItems="center"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => {
                                    window.location.href = '/app/pedidos/'
                                }}
                            >
                                Voltar
                            </Button>
                        </Grid>
                        <Grid
                            xs={8}
                            container
                            item
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            {
                                !isVisualizar && (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            size="small"
                                            onClick={onSubmit}
                                        >
                                            Confirmar
                                        </Button>
                                        <div className={classes.divider} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            size="small"
                                            onClick={submitEGerarConta}
                                        >
                                            Gerar Conta a Pagar
                                        </Button>
                                        <div className={classes.divider} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            size="small"
                                            onClick={submitEGerarAdiantamento}
                                        >
                                            Gerar Adiantamento a Pagar
                                        </Button>
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </form>
            </Container>
            <Modal
                className={classes.modal}
                open={openModalProdutosServicos}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={openModalProdutosServicos}>
                        <ModalProdutosServicos
                            close={() => handleCloseModalProdutosServicos()}
                            setItens={(x) => geraItens(x)}
                            opcoesProdutosServicos={opcoesProdutosServicos}
                            opcoesCentroCusto={opcoesCentroCusto}
                            opcoesContaGerencial={opcoesContaGerencial}
                            id={params.id}
                            dataPedido={form.dataPedido}
                        />
                    </Fade>
                </div>
            </Modal>
        </Page>
    )
}
