import ConciliacaoBancariaListagem from 'pages/conciliacaoBancaria/ConciliacaoBancariaListagem';

export const ConciliacaoBancariaRoute = [
    {
        path: '/app/ConciliacaoBancaria',
        key: 'APP_CONCILIACAO_BANCARIA',
        exact: true,
        component: ConciliacaoBancariaListagem,
    }
];
