import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    makeStyles,
    Card,
    CardContent,
    TextField,
    FormControl,
    Box,
} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Container from '@material-ui/core/Container'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { yupResolver } from '@hookform/resolvers'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import { DragAndDropFile } from '../../components/dragAndDropFile'
import TableOrcamento from './TableOrcamento'
import { importacaoOrcamentoEsquema } from './orcamento.validate'
import ObjectHelper from '../../utils/objetcHelper'
import ToastHelper from '../../utils/toastHelper'
import CentroCustoService from '../../services/CentroCustoService'
import OrcamentoService from '../../services/OrcamentoService'
import ModalErrosPlanilha from './ModalErrosPlanilha'
import moment from 'moment'
import Loading from '../../components/Loading'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(5, 1, 1),
    },
    textField: {
        width: '25ch',
    },
    marginButton: {
        marginTop: '15px',
    },
    buttonContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
}))

export const ImportacaoOrcamento = (props) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [arquivo, setArquivo] = useState(null)
    const [centroCustos, setCentroCustos] = useState([])
    const [centroCustoSelecionado, setCentroCustoSelecionado] = useState({})
    const [respostaPlanilha, setRespostaPlanilha] = useState(false)
    const [orcamentoItens, setOrcamentoItens] = useState()
    const [errosPlanilha, setErrosPlanilha] = React.useState([])
    const [openModalErros, setOpenModalErros] = React.useState(false)
    const [dataInicial, setDataInicial] = React.useState('')
    const [dataFinal, setDataFinal] = React.useState('')
    const fecharModalErros = () => {
        setOpenModalErros(false)
    }
    const [filtro] = useState(
        useSelector((state) => state.centroCusto.filtroListagem)
    )
    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            id: '',
            ano: '',
            centroCustoId: centroCustoSelecionado
                ? centroCustoSelecionado.value
                : '',
        },
        resolver: yupResolver(importacaoOrcamentoEsquema),
    })

    const buscarCentroCusto = async (filtro) => {
        filtro.fazendaId = FazendaId
        filtro.ordem = 'Descricao'
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        await CentroCustoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setCentroCustos(
                    ObjectHelper.getValuesParseToSelect(
                        response.data.itens,
                        'id',
                        'descricao'
                    )
                )
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        buscarCentroCusto(filtro)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUploadFile = async (acceptedFiles) => {
        setRespostaPlanilha(false)
        setOrcamentoItens(null)
        if (acceptedFiles.length > 0) {
            setArquivo(acceptedFiles[0])
        }
    }

    const validarPlanilha = async (dados) => {
        setLoading(true)

        var orcamento = {
            dataInicial: moment(dados.dataInicial).format('MM/DD/YYYY'),
            dataFinal: moment(dados.dataFinal).format('MM/DD/YYYY'),
            fazendaId: FazendaId,
            centroCustoId: centroCustoSelecionado.value,
            arquivo: arquivo,
        }

        if (arquivo) {
            await OrcamentoService.validar(orcamento)
                .then((response) => {
                    if (response.status === 200) {
                        var resposta = response.data
                        if (
                            resposta.orcamentoItens &&
                            resposta.orcamentoItens.length > 0
                        ) {
                            setOrcamentoItens(resposta.orcamentoItens)
                            setRespostaPlanilha(true)
                        } else {
                            setErrosPlanilha(resposta.erros)
                            setOpenModalErros(true)
                        }
                    } else {
                        ToastHelper.error(response.statusText)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
                .finally(() => setLoading(false))
        } else {
            ToastHelper.error('Necessário um arquivo')
            setLoading(false)
        }
    }

    const carregarDados = async (dados) => {
        setLoading(true)

        var orcamento = {
            dataInicial: moment(dados.dataInicial).format('MM/DD/YYYY'),
            dataFinal: moment(dados.dataFinal).format('MM/DD/YYYY'),
            fazendaId: FazendaId,
            centroCustoId: centroCustoSelecionado.value,
        }

        let params = await ObjectHelper.createParams(orcamento)
        await OrcamentoService.carregar(params)
            .then((response) => {
                if (response.status === 200) {
                    var itens = response.data
                    if (itens && itens.length > 0) {
                        setOrcamentoItens(itens)
                        setRespostaPlanilha(true)
                    }
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                if (error.response) {
                    // Solicitação feita com resposta do servidor
                    ToastHelper.error(error.response.data)
                }
            })
            .finally(() => setLoading(false))
    }

    const salvarOrcamento = async (dados) => {
        setLoading(true)

        var orcamento = {
            dataInicial: moment(dados.dataInicial).format('MM/DD/YYYY'),
            dataFinal: moment(dados.dataFinal).format('MM/DD/YYYY'),
            fazendaId: FazendaId,
            centroCustoId: centroCustoSelecionado.value,
            orcamentoItems: orcamentoItens,
        }

        await OrcamentoService.cadastrar(orcamento)
            .then((response) => {
                if (response.status === 200) {
                    var resposta = response.data
                    if (resposta.erros && resposta.erros.length > 0) {
                        setErrosPlanilha(resposta.erros)
                        setOpenModalErros(true)
                    } else {
                        ToastHelper.success('Orçamento cadastrado com sucesso!')
                    }
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                if (error.response) {
                    // Solicitação feita com resposta do servidor
                    ToastHelper.error(error.response.data)
                }
            })
            .finally(() => setLoading(false))
    }

    const baixarModelo = async (dados) => {
        setLoading(true)
        const params = await ObjectHelper.createParams({
            fazendaId: FazendaId,
            dataInicial: moment(dados.dataInicial).format('MM/DD/YYYY'),
            dataFinal: moment(dados.dataFinal).format('MM/DD/YYYY'),
        })
        await OrcamentoService.downloadModelo(params)
            .then((response) => {
                let tempLink = document.createElement('a')
                var data = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                })
                var planilhaURL = window.URL.createObjectURL(data)
                tempLink = document.createElement('a')
                tempLink.href = planilhaURL
                tempLink.setAttribute('download', 'orcamento.xlsx')
                tempLink.click()
            })
            .catch((error) => {
                if (error.response) {
                    // Solicitação feita com resposta do servidor
                    ToastHelper.error(error.response.data)
                }
            })
            .finally(() => setLoading(false))
    }

    return (
        <Page className={classes.root} title="Orçamento">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Orçamento" />
                <form className={classes.form}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="dataInicial"
                                            name="dataInicial"
                                            label="Data Inicial:*"
                                            variant="standard"
                                            type={'month'}
                                            inputRef={register}
                                            placeholder="Selecione"
                                            value={dataInicial}
                                            onChange={(_, newValue) =>
                                                setDataInicial(newValue)
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12'}
                                                }
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.dataInicial
                                                ? errors.dataInicial.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="dataFinal"
                                            name="dataFinal"
                                            label="Data Final:*"
                                            variant="standard"
                                            type={'month'}
                                            inputRef={register}
                                            placeholder="Selecione"
                                            value={dataFinal}
                                            onChange={(_, newValue) =>
                                                setDataFinal(newValue)
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12'}
                                                }
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.dataFinal
                                                ? errors.dataFinal.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="centroCusto"
                                            id="centroCusto"
                                            inputRef={register}
                                            options={centroCustos}
                                            value={centroCustoSelecionado}
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setCentroCustoSelecionado(
                                                    newValue
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="centroCusto"
                                                    id="centroCusto"
                                                    variant="standard"
                                                    label="Centro de Custo*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.centroCusto
                                                ? errors.centroCusto.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    className={classes.buttonContent}
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        onClick={handleSubmit(carregarDados)}
                                    >
                                        Carregar
                                    </Button>
                                </Grid>
                            </Grid>
                            <Accordion className={classes.margin}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Importar dados da planilha do Excel
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        container
                                        spacing={2}
                                        item
                                        xs={12}
                                        display="flex"
                                    >
                                        <DragAndDropFile
                                            onUploadFile={onUploadFile}
                                            text="Clique ou arraste sua planilha aqui"
                                        />
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.buttonContent}
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                type="submit"
                                                style={{ marginRight: '10px' }}
                                                onClick={handleSubmit(
                                                    baixarModelo
                                                )}
                                            >
                                                Baixar modelo
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                type="submit"
                                                onClick={handleSubmit(
                                                    validarPlanilha
                                                )}
                                            >
                                                Carregar planilha
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                    {respostaPlanilha && (
                        <Box mt={3}>
                            <Grid>
                                <TableOrcamento dados={orcamentoItens} />
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                    className={classes.card}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        size="small"
                                        onClick={handleSubmit(salvarOrcamento)}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </form>
            </Container>
            <Modal
                open={openModalErros}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
                onClose={fecharModalErros}
            >
                <Fade in={openModalErros}>
                    <ModalErrosPlanilha
                        erros={errosPlanilha}
                        close={fecharModalErros}
                    />
                </Fade>
            </Modal>
            <Loading open={loading} texto="Carregando" />
        </Page>
    )
}
