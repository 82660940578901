import { ContaPagar } from '../pages/contasPagarReceber/contaPagar'
import { ContaReceber } from '../pages/contasPagarReceber/contaReceber'
import { ContaPagarReceberCadastro } from '../pages/contasPagarReceber/components/ContaPagarReceberCadastro'

export const contaPagarReceberRoute = [
    {
        path: '/app/conta/pagar',
        key: 'APP_CONTA_PAGAR_LISTAGEM',
        exact: true,
        component: ContaPagar,
    },
    {
        path: '/app/conta/receber',
        key: 'APP_CONTA_RECEBER_LISTAGEM',
        exact: true,
        component: ContaReceber,
    },
    {
        path: '/app/conta/:tipo(pagar|receber)/cadastro',
        key: 'APP_CONTA_PAGAR_RECEBER_CADASTRO',
        exact: true,
        component: ContaPagarReceberCadastro,
    },
    {
        path: '/app/conta/:tipo(pagar|receber)/:modo(editar|gerar|duplicar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})', // Ainda não há rota para visualizar
        key: 'APP_CONTA_PAGAR_RECEBER_EDITAR_GERAR',
        exact: true,
        component: ContaPagarReceberCadastro,
    }
]
