import { LocaisEstoque } from '../pages/locaisEstoque/index';
import { LocaisEstoqueCadastro } from '../pages/locaisEstoque/LocaisEstoqueCadastro';

export const locaisEstoqueRoute = [
    {
        path: '/app/locaisEstoque',
        key: 'APP_LOCAIS_ESTOQUE',
        exact: true,
        component: LocaisEstoque,
    },
    {
        path: '/app/locaisEstoque/cadastro',
        key: 'APP_LOCAIS_ESTOQUE_CADASTRO',
        exact: true,
        component: LocaisEstoqueCadastro,
    },
    {
        path: '/app/locaisEstoque/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_LOCAIS_ESTOQUE_EDITAR',
        exact: true,
        component: LocaisEstoqueCadastro,
    },
];
