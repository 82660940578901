export const SET_FILTRO_LISTAGEM_PEDIDOS = 'SET_FILTRO_LISTAGEM_PEDIDOS'
export const SET_ITENS_MODAL = 'SET_ITENS_MODAL'

export const setFiltroListagem = (filtroListagem) => ({
    type: SET_FILTRO_LISTAGEM_PEDIDOS,
    filtroListagem,
})

export const setItensModal = (itens) => ({
    type: SET_ITENS_MODAL,
    itens,
})
