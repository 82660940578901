import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Card, Typography, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'auto',
    },
    container: {
        margin: theme.spacing(1),
        textAlign: 'center',
    },
    icon: {
        fontSize: 70,
    },
}))

export const DragAndDropFile = (props) => {
    const classes = useStyles()
    const maxSize = 5242880
    const acceptFiles =
        props.acceptedFiles != null
            ? this.props.acceptedFiles
            : 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const maxFiles = props.maxFiles != null ? this.props.maxFiles : 1

    const [file, setFile] = useState(null)

    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles)
        props.onUploadFile(acceptedFiles)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        isDragActive,
        getRootProps,
        getInputProps,
        isDragReject,
        rejectedFiles,
        fileRejections,
    } = useDropzone({
        accept: acceptFiles,
        maxFiles: maxFiles,
        onDrop,
    })

    const isFileTooLarge =
        rejectedFiles &&
        rejectedFiles.length > 0 &&
        rejectedFiles[0].size > maxSize

    const fileReject = fileRejections && fileRejections.length > 0

    return (
        <Card {...getRootProps()} className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <CloudUploadIcon className={classes.icon} />
                </Grid>
                <Grid item xs={12}>
                    <input {...getInputProps()} name="files" multiple />
                    {!isDragActive &&
                        file &&
                        file.length > 0 &&
                        file.map((item, indice) => (
                            <Grid key={indice}>
                                <Typography>{item.path}</Typography>
                            </Grid>
                        ))}
                    {!isDragActive &&
                        (!file || file.length === 0) &&
                        !fileReject && <Typography>{props.text}</Typography>}
                    {isDragActive && !isDragReject && (
                        <Typography>
                            {'Solte o arquivo para carregar'}
                        </Typography>
                    )}
                    {isDragReject && (
                        <Typography>{'Tipo de arquivo inválido'}</Typography>
                    )}
                    {isFileTooLarge && (
                        <Typography>
                            {'Arquivo muito grande, tamanho maximo de 5mb.'}
                        </Typography>
                    )}
                    {fileReject && (
                        <Typography>{'Arquivo(s) inválido(s).'}</Typography>
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}
