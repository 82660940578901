import * as Yup from 'yup'
import { validaCpf, validaCnpj } from './../../utils/validateHelper'

export const ProdutorRuralEsquema = Yup.object().shape({
    inscricaoEstadual: Yup.string().required('Campo obrigatório'),
    cpfCnpj: Yup.string()
        .required('Campo obrigatório')
        .test('cpfCnpj', function (item) {
            if (item.length <= 14) {
                return validaCpf(item) === false
                    ? this.createError({
                          message: `CPF inválido!`,
                          path: 'cpfCnpj',
                      })
                    : true
            } else {
                return validaCnpj(item) === false
                    ? this.createError({
                          message: `CNPJ inválido!`,
                          path: 'cpfCnpj',
                      })
                    : true
            }
        }),
    nome: Yup.string().required('Campo obrigatório'),
    cnae: Yup.string(),
    regimeApuracao: Yup.string(),
    categoria: Yup.string(),
    dataInicio: Yup.date()
        .required('Campo obrigatório')
        .typeError('Data inválida'),
    grupoLocal: Yup.object().nullable().required('Campo obrigatório'),
    cep: Yup.string().required('Campo obrigatório'),
    estado: Yup.object().nullable().required('Campo obrigatório'),
    municipio: Yup.object().nullable().required('Campo obrigatório'),
    bairro: Yup.string().required('Campo obrigatório'),
    logradouro: Yup.string().required('Campo obrigatório'),
    numero: Yup.string().required('Campo obrigatório'),
    complemento: Yup.string(),
    referenciaLocalizacao: Yup.string(),
    distritoPovoado: Yup.string(),
})
