import { SET_FILTRO_LISTAGEM_TRANSFERENCIA_ESTOQUE } from '../actions/transferenciaEstoque';

export const storageTransferenciaEstoque = 'transferenciaEstoque';

const initialState = {
    filtroListagem: {},
};

export const transferenciaEstoque = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_TRANSFERENCIA_ESTOQUE:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
