import * as Yup from 'yup'

export const CentroCustoEsquema = Yup.object().shape({
    codigo: Yup.string()
        .trim()
        .max(50, 'Máximo 50 caracteres')
        .required('Campo obrigatório')
        .matches(/^[A-Za-z0-9]+$/, 'Apenas letras e números'),
    descricao: Yup.string()
        .trim()
        .max(100, 'Máximo 100 caracteres')
        .required('Campo obrigatório')
        .matches(/^[A-Za-z0-9\s]+$/, 'Apenas letras e números'),
    tipo: Yup.string().required('Campo obrigatório'),
})

export const CentroCustoRateioEsquema = Yup.object().shape({
    percentual: Yup.string().required('Campo obrigatório'),
    dataInicial: Yup.string().required('Campo obrigatório'),
    centroCustoSelecionado: Yup.string().required('Campo obrigatório'),
})
