import service from './service'

export default {
    buscarPorId(id) {
        return service.get(`/GestaoContrato/${id}`)
    },
    buscarFornecedorClientePorId(id) {
        return service.get(`/GestaoContrato/fornecedorCliente/${id}`)
    },
    duplicar(id, fazendaId) {
        return service.get(`/GestaoContrato/Duplicar/${id}/${fazendaId}`)
    },
    buscarTudo(param) {
        return service.get(`/GestaoContrato${param}`)
    },
    gerarNumero(fazendaId) {
        return service.get(`/GestaoContrato/GerarNumero/${fazendaId}`)
    },
    cadastrar(param) {
        return service.post(`/GestaoContrato`, param)
    },
    atualizar(param) {
        return service.put(`/GestaoContrato`, param)
    },
    ativarDesativar(id) {
        return service.put(`/GestaoContrato/AtivarDesativar`, id)
    },
    deletar(id) {
        return service.delete(`/GestaoContrato/${id}`)
    },
}
