import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import FiltroSimples from 'components/BasicPageTopBar/FiltroSimples'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { Divider, Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '10px',
        marginBottom: '10px',
    },
}))

const Toolbar = ({ handleFiltro, filtroInicial, className, ...props }) => {
    const classes = useStyles()
    const [filtro, setFiltro] = useState(filtroInicial)
    const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded)

    const setStateAcocordion = () => {
        let newFiltro = { ...filtro }
        newFiltro.filtroExpanded = !filtroExpanded
        setFiltro(newFiltro)
        setFiltroExpanded(!filtroExpanded)
    }

    const changeFiltro = (campo, event) => {
        let newFiltro = { ...filtro }
        newFiltro[campo] = event.target.value
        setFiltro(newFiltro)
    }

    const submit = (event) => {
        handleFiltro(filtro)
    }

    const { getValues, setValue, register } = useForm({
        defaultValues: {
            centroCusto: "",
            centroCustoId: "",
            contaGerencial: "",
            contaGerencialId: "",
            dataEntregaInicio: "",
            dataEntregaFim: "",
            dataPedidoInicio: "",
            dataPedidoFim: "",
            descricao: "",
            fornecedorCliente: "",
            fornecedorClienteId: "",
            numeroPedido: "",
            produtoServico: "",
            produtoServicoId: "",
            valorTotalInicio: "",
            valorTotalFim: ""
        }
    })

    const clearFiltros = () => {
        const {
            centroCusto,
            centroCustoId,
            contaGerencial,
            contaGerencialId,
            dataEntregaInicio,
            dataEntregaFim,
            dataPedidoInicio,
            dataPedidoFim,
            descricao,
            filtro: filtroPesquisa,
            fornecedorCliente,
            fornecedorClienteId,
            numeroPedido,
            produtoServico,
            produtoServicoId,
            valorTotalInicio,
            valorTotalFim,
            ...rest
        } = filtro

        setValue("centroCusto", "")
        setValue("centroCustoId", "")
        setValue("contaGerencial", "")
        setValue("contaGerencialId", "")
        setValue("dataEntregaInicio", "")
        setValue("dataEntregaFim", "")
        setValue("dataPedidoInicio", "")
        setValue("dataPedidoFim", "")
        setValue("descricao", "")
        setValue("fornecedorCliente", "")
        setValue("fornecedorClienteId", "")
        setValue("numeroPedido", "")
        setValue("produtoServico", "")
        setValue("produtoServicoId", "")
        setValue("valorTotalInicio", "")
        setValue("valorTotalFim", "")

        const newFiltro = {
            ...rest,
            filtro: ""
        }

        setFiltro(newFiltro)
        handleFiltro(newFiltro)
    }

    useEffect(() => {
        setFiltro(filtroInicial)
    }, [filtroInicial])

    return (
        <Box item mt={3}>
            <Accordion
                expanded={filtroExpanded}
                onChange={(e) => setStateAcocordion}
            >
                <AccordionSummary
                    expandIcon={
                        !props.temFiltroAvancado ? '' : <ExpandMoreIcon />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={() => setStateAcocordion(!filtroExpanded)}
                >
                    <FiltroSimples
                        filtro={filtro}
                        changeFiltro={changeFiltro}
                        submit={submit}
                        temFiltroAvancado={props.temFiltroAvancado}
                        clickadd={props.clickadd}
                    />
                </AccordionSummary>
                {props.temFiltroAvancado && (
                    <AccordionDetails>
                        <Grid container item md={12} display="flex">
                            <Grid container item md={12} display="flex">
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data Inicial:"
                                            onChange={(e) => {
                                                setValue("dataPedidoInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataPedidoInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().dataPedidoInicio}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("dataPedidoInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data Final:"
                                            type="date"
                                            onChange={(e) => {
                                                setValue("dataPedidoFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataPedidoFim:
                                                        e.target.value,
                                                })
                                            }}
                                            className={classes.textField}
                                            value={getValues().dataPedidoFim}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("dataPedidoFim")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Número do Contrato:"
                                            onChange={(e) => {
                                                setValue("numeroPedido", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    numeroPedido:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().numeroPedido}
                                            InputLabelProps={{ shrink: true }}
                                            {...register("numeroPedido")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={
                                                props.opcoesFornecedoresClientes ||
                                                []
                                            }
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            onChange={(event, option) => {
                                                if (option) {
                                                    setValue("fornecedorCliente", option)
                                                    setValue("fornecedorClienteId", option.value)

                                                    setFiltro({
                                                        ...filtro,
                                                        fornecedorCliente:
                                                        option,
                                                        fornecedorClienteId:
                                                        option.value,
                                                    })
                                                } else {
                                                    setValue("fornecedorCliente", option)
                                                    setValue("fornecedorClienteId", "")

                                                    setFiltro({
                                                        ...filtro,
                                                        fornecedorCliente:
                                                        option,
                                                        fornecedorClienteId: '',
                                                    })
                                                }
                                            }}
                                            value={getValues().fornecedorCliente ? getValues().fornecedorCliente : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={
                                                        'Fornecedor/Cliente:'
                                                    }
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("fornecedorCliente")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />

                            <Grid container item md={12} display="flex">
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.produtosServicos}
                                            getOptionLabel={(option) =>
                                                option.nome
                                            }
                                            onChange={(event, option) => {
                                                if (option) {
                                                    setValue("produtoServico", option)
                                                    setValue("produtoServicoId", option.id)

                                                    setFiltro({
                                                        ...filtro,
                                                        produtoServico: option,
                                                        produtoServicoId:
                                                        option.id,
                                                    })
                                                } else {
                                                    setValue("produtoServico", option)
                                                    setValue("produtoServicoId", "")

                                                    setFiltro({
                                                        ...filtro,
                                                        produtoServico: option,
                                                        produtoServicoId: '',
                                                    })
                                                }
                                            }}
                                            value={getValues().produtoServico ? getValues().produtoServico : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Produto/Serviço'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("produtoServico")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            key={filtro.centroCusto}
                                            options={props.centroCusto || []}
                                            getOptionLabel={(option) =>
                                                option.descricao
                                            }
                                            onChange={(event, option) => {
                                                if (option !== null) {
                                                    setValue("centroCusto", option)
                                                    setValue("centroCustoId", option.id)

                                                    setFiltro({
                                                        ...filtro,
                                                        centroCusto: option,
                                                        centroCustoId:
                                                            option.id,
                                                    })
                                                } else {
                                                    setValue("centroCusto", undefined)
                                                    setValue("centroCustoId", "")

                                                    setFiltro({
                                                        ...filtro,
                                                        centroCusto:
                                                            undefined,
                                                        centroCustoId: '',
                                                    })
                                                }
                                            }}
                                            value={getValues().centroCusto ? getValues().centroCusto : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Centro de Custo"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("centroCusto")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data Entrega Inicial:"
                                            onChange={(e) => {
                                                setValue("dataEntregaInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataEntregaInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().dataEntregaInicio}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("dataEntregaInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data Entrega Final:"
                                            type="date"
                                            onChange={(e) => {
                                                setValue("dataEntregaFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataEntregaFim:
                                                        e.target.value,
                                                })
                                            }}
                                            className={classes.textField}
                                            value={getValues().dataEntregaFim}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("dataEntregaFim")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container item md={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição:"
                                            onChange={(e) => {
                                                setValue("descricao", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    descricao: e.target.value,
                                                })
                                            }}
                                            value={getValues().descricao}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            {...register("descricao")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.contaGerencial || []}
                                            getOptionLabel={(option) =>
                                                option.descricao
                                            }
                                            onChange={(event, option) => {
                                                if (option) {
                                                    setValue("contaGerencial", option)
                                                    setValue("contaGerencialId", option.id)

                                                    setFiltro({
                                                        ...filtro,
                                                        contaGerencial: option,
                                                        contaGerencialId:
                                                            option.id,
                                                    })
                                                } else {
                                                    setValue("contaGerencial", option)
                                                    setValue("contaGerencialId", "")

                                                    setFiltro({
                                                        ...filtro,
                                                        contaGerencial: option,
                                                        contaGerencialId: '',
                                                    })
                                                }
                                            }}
                                            value={getValues().contaGerencial ? getValues().contaGerencial : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Conta Gerencial'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("contaGerencial")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Valor Total Inicial:"
                                            onChange={(e) => {
                                                setValue("valorTotalInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorTotalInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().valorTotalInicio}
                                            InputLabelProps={{ shrink: true }}
                                            {...register("valorTotalInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Valor Total Final:"
                                            onChange={(e) => {
                                                setValue("valorTotalFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorTotalFim:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().valorTotalFim}
                                            InputLabelProps={{ shrink: true }}
                                            {...register("valorTotalFim")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid
                                md={12}
                                container
                                item
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                className={classes.margin}
                            >
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    size="small"
                                    onClick={clearFiltros}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={(e) => submit(e)}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
            </Accordion>
        </Box>
    )
}

export default Toolbar
