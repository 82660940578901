import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import NumberFormat from 'react-number-format'
import LocaisEstoqueService from '../../services/LocaisEstoqueService'
import ProdutosServicosService from '../../services/ProdutosServicosService'
import UnidadeConversaoService from '../../services/UnidadeConversaoService'
import UnidadeDeMedidaService from 'services/UnidadeDeMedidaService'

import FooterBottons from '../../components/FooterBottons/FooterBottons'

import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { TransferenciaEstoqueEsquema } from './TranferenciaEstoque.validate'
import TransferenciaEstoqueService from '../../services/TransferenciaEstoqueService'
import ToastHelper from '../../utils/toastHelper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ObjectHelper from '../../utils/objetcHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlSituacao: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
}))

export const TransferenciaEstoqueCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [titulo, setTitulo] = useState(
        'Cadastro de Transferência entre Estoques'
    )
    const [visualizar, setVisualizar] = useState(false)

    const [data, setData] = useState()
    const [localOrigemId, setLocalOrigemId] = useState()
    const [localDestinoId, setLocalDestinoId] = useState()
    const [produtoId, setProdutoId] = useState([])
    const [unidadeMedidaId, setUnidadeMedidaId] = useState([])
    const [quantidade, setQuantidade] = useState()
    const [observacao, setObservacao] = useState()

    const [unidadesDeMedida, setUnidadesDeMedida] = useState([])
    const [locais, setLocais] = useState([])
    const [produtos, setProdutos] = useState([])

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {},
        resolver: yupResolver(TransferenciaEstoqueEsquema),
    })

    const {
        match: { params },
    } = props

    useEffect(() => {
        buscarLocais()
        buscarProdutos()
        buscarUnidadesDeMedida()

        if (params.modo === 'visualizar') {
            setVisualizar(true)
            setTitulo('Visualizar transferência entre estoques')
            buscarTransferencia(params.id)
        }

        if (params.local && params.produto) {
            buscarLocais(params.local)
            buscarProdutos(params.produto)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = fazendaId
        newData.data = data
        newData.localOrigemId = localOrigemId.value
        newData.localDestinoId = localDestinoId.value
        newData.produtoId = produtoId.value
        newData.unidadeMedidaId = unidadeMedidaId.value
        newData.quantidade = quantidade
        newData.observacao = observacao
        newData.ativo = true

        await TransferenciaEstoqueService.cadastrar(newData)
            .then((response) => {
                if (response.status === 200) {
                    ToastHelper.success(
                        'Transferência entre estoques cadastrada com sucesso!'
                    )
                    history.goBack()
                } else ToastHelper.error(response.statusText)
            })
            .catch(function (error) {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                }
            })
    }

    const buscarLocais = async (local) => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await LocaisEstoqueService.buscarTudo(params)
        var locais = []
        data.itens.forEach((item) =>
            locais.push({
                value: item['id'],
                label: item['nome'] + ' - ' + item['tipoEstoque'],
            })
        )
        setLocais(locais)

        if (local) {
            var autoFill = data.itens.find((item) => item.id === local)
            setLocalOrigemId({
                value: autoFill ? autoFill['id'] : '',
                label: autoFill ? autoFill['nome'] : '',
            })
        }
    }

    const buscarProdutos = async (produto) => {
        const { data } = await ProdutosServicosService.comControleEstoque(
            fazendaId
        )
        setProdutos(getValuesParseToSelect(data))

        if (produto) {
            var autoFill = data.find((item) => item.id === produto)
            setProdutoId({
                value: autoFill['id'],
                label: autoFill['nome'],
            })
            await buscarUnidadesDeMedida(autoFill.unidadeMedidaId)
        }
    }

    const getValuesParseToSelect = (list, valueName, labelName) => {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'nome'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                ativo: item.ativo ? item.ativo : undefined,
                unidadeMedida: item.unidadeMedidaId,
            })
        })
        return options
    }

    const buscarUnidadesDeMedida = async (unidadeAtual) => {
        if (!unidadeAtual) {
            setUnidadesDeMedida([])
            return
        }

        let options = []
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.unidade = unidadeAtual
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)

        await UnidadeConversaoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                response.data.forEach((item) => {
                    options.push({
                        value: item.unidadeConvertida['id'],
                        label: item.unidadeConvertida['sigla'],
                    })
                })
            } else {
                ToastHelper(response.statusText)
            }
        })

        await UnidadeDeMedidaService.conversoes(params).then((response) => {
            if (response.status === 200) {
                response.data.forEach((item) => {
                    options.push({
                        value: item['id'],
                        label: item['sigla'],
                    })
                })
            } else {
                ToastHelper(response.statusText)
            }
        })

        await UnidadeDeMedidaService.buscarPorId(filtro.unidade).then(
            (response) => {
                if (response.status === 200) {
                    options.push({
                        value: response.data['id'],
                        label: response.data['sigla'],
                    })
                } else {
                    ToastHelper(response.statusText)
                }
            }
        )

        options = options.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.value === thing.value && t.label === thing.label
                )
        )

        setUnidadesDeMedida(options)
    }

    const buscarTransferencia = async (transferencia) => {
        await TransferenciaEstoqueService.buscarPorId(transferencia).then(
            (response) => {
                if (response.status === 200) {
                    setData(response.data.data)
                    setLocalOrigemId({
                        value: response.data.localOrigem.id,
                        label: response.data.localOrigem.nome,
                    })
                    setLocalDestinoId({
                        value: response.data.localDestino.id,
                        label: response.data.localDestino.nome,
                    })
                    setProdutoId({
                        value: response.data.produto.id,
                        label: response.data.produto.nome,
                    })
                    setUnidadeMedidaId({
                        value: response.data.unidadeMedida.id,
                        label: response.data.unidadeMedida.sigla,
                    })
                    setQuantidade(response.data.quantidade)
                    setObservacao(response.data.observacao)
                } else ToastHelper.error(response.statusText)
            }
        )
    }

    const trataQuantidade = (quantidade) => {
        var newQtd = parseFloat(
            quantidade.toString().replaceAll('.', '').replace(',', '.')
        )
        setQuantidade(newQtd)
    }

    return (
        <Page
            className={classes.root}
            title="Transferência entre Estoques cadastro"
        >
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="date"
                                            label="Data:*"
                                            disabled={visualizar}
                                            value={data}
                                            className={classes.textField}
                                            inputRef={register}
                                            onChange={(x) =>
                                                setData(x.target.value)
                                            }
                                            id="data"
                                            name="data"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.data
                                                ? errors.data.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="localOrigemId"
                                            id="localOrigemId"
                                            inputRef={register}
                                            options={locais}
                                            value={
                                                localOrigemId
                                                    ? localOrigemId
                                                    : ''
                                            }
                                            defaultValue={
                                                localOrigemId
                                                    ? localOrigemId
                                                    : ''
                                            }
                                            disabled={visualizar}
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setLocalOrigemId(
                                                    newValue ? newValue : null
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="localOrigemId"
                                                    id="localOrigemId"
                                                    variant="standard"
                                                    label="Local de Origem:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.localOrigemId
                                                ? errors.localOrigemId.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="localDestinoId"
                                            id="localDestinoId"
                                            inputRef={register}
                                            options={locais}
                                            value={
                                                localDestinoId
                                                    ? localDestinoId
                                                    : ''
                                            }
                                            defaultValue={
                                                localDestinoId
                                                    ? localDestinoId
                                                    : ''
                                            }
                                            disabled={visualizar}
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setLocalDestinoId(
                                                    newValue ? newValue : null
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="localDestinoId"
                                                    id="localDestinoId"
                                                    variant="standard"
                                                    label="Local de Destino:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.localDestinoId
                                                ? errors.localDestinoId.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="produtoId"
                                            id="produtoId"
                                            inputRef={register}
                                            options={produtos}
                                            value={produtoId}
                                            defaultValue={
                                                produtoId ? produtoId : ''
                                            }
                                            disabled={visualizar}
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setProdutoId(
                                                    newValue ? newValue : null
                                                )
                                                setUnidadeMedidaId(null)
                                                buscarUnidadesDeMedida(
                                                    newValue?.unidadeMedida
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="produtoId"
                                                    id="produtoId"
                                                    variant="standard"
                                                    label="Produto:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.produtoId
                                                ? errors.produtoId.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            label="Quantidade:"
                                            value={quantidade ? quantidade : 0}
                                            defaultValue={
                                                quantidade ? quantidade : 0
                                            }
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            disabled={visualizar}
                                            customInput={TextField}
                                            onChange={(x) =>
                                                trataQuantidade(x.target.value)
                                            }
                                            inputRef={register}
                                            id="quantidade"
                                            name="quantidade"
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'right' },
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.quantidade
                                                ? errors.quantidade.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="unidadeMedidaId"
                                            id="unidadeMedidaId"
                                            inputRef={register}
                                            options={unidadesDeMedida}
                                            value={
                                                unidadeMedidaId
                                                    ? unidadeMedidaId
                                                    : ''
                                            }
                                            defaultValue={
                                                unidadeMedidaId
                                                    ? unidadeMedidaId
                                                    : ''
                                            }
                                            disabled={visualizar}
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setUnidadeMedidaId(
                                                    newValue || ''
                                                )
                                                buscarUnidadesDeMedida(
                                                    produtoId?.unidadeMedida
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="unidadeMedidaId"
                                                    id="unidadeMedidaId"
                                                    variant="standard"
                                                    label="Unidade de Medida:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.unidadeMedidaId
                                                ? errors.unidadeMedidaId.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlObs}
                                    >
                                        <TextField
                                            type="text"
                                            label="Observação:"
                                            disabled={visualizar}
                                            className={classes.textField}
                                            inputRef={register}
                                            id="observacao"
                                            name="observacao"
                                            value={observacao}
                                            onChange={(event, newValue) => {
                                                setObservacao(
                                                    event
                                                        ? event.target.value
                                                        : null
                                                )
                                            }}
                                            multiline
                                            rows={2}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons desabilitaSubmit={visualizar} />
                </form>
            </Container>
        </Page>
    )
}
