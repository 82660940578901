import service from './service';

export default {
    cadastrar(param) {
        return service.post(`/TipoEstoque`, param);
    },
    atualizar(param) {
        return service.put(`/TipoEstoque`, param);
    },
    buscarTudo(param) {
        return service.get(`/TipoEstoque${param}`);
    },
    deletar(id) {
        return service.delete(`/TipoEstoque/${id}`);
    },
    buscarPeloId(id) {
        return service.get(`/TipoEstoque/${id}`);
    },
};
