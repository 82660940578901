import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { TipoDocumentoEsquema } from './TipoDocumento.validate'
import TipoDocumentoService from '../../services/TipoDocumentoService'
import ToastHelper from '../../utils/toastHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
}))

export const TipoDocumentoCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [venda, setVenda] = useState(false)
    const [compra, setCompra] = useState(false)
    const [movimentaFluxoCaixa, setMovimentaFluxoCaixa] = useState(false)
    const [integrarEstoque, setIntegrarEstoque] = useState(false)
    const {
        match: { params },
    } = props

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            descricao: '',
            venda: false,
            compra: false,
            ativo: false,
            integrarEstoque: false,
            movimentaFluxoCaixa: false,
        },
        resolver: yupResolver(TipoDocumentoEsquema),
    })

    useEffect(() => {
        async function init() {
            const {
                match: { params },
            } = props
            if (params.id) await buscarParaEdicao(params.id)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarParaEdicao = (contaBancariaId) => {
        TipoDocumentoService.buscarPorId(contaBancariaId).then((response) => {
            if (response.status === 200) {
                setVenda(response.data.venda)
                setCompra(response.data.compra)
                setMovimentaFluxoCaixa(response.data.movimentaFluxoCaixa)
                setIntegrarEstoque(response.data.integrarEstoque)
                setValue('descricao', response.data.descricao)
                setValue('compra', response.data.compra)
                setValue('venda', response.data.venda)
                setValue(
                    'movimentaFluxoCaixa',
                    response.data.movimentaFluxoCaixa
                )
                setValue('intregrarEstoque', response.data.integrarEstoque)
            } else ToastHelper.error(response.statusText)
        })
    }

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = fazendaId
        newData.venda = venda
        newData.compra = compra
        newData.movimentaFluxoCaixa = movimentaFluxoCaixa
        newData.integrarEstoque = integrarEstoque

        if (params.id) {
            newData.id = params.id
            await TipoDocumentoService.atualizar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Tipo de documento atualizado')
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        ToastHelper.error(error.response.data)
                    }
                })
        } else {
            newData.ativo = true
            await TipoDocumentoService.cadastrar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Tipo de documento cadastrado com sucesso'
                        )
                        if (params.modo === 'aba') {
                            window.close()
                        } else {
                            history.goBack()
                        }
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        ToastHelper.error(error.response.data)
                    }
                })
        }
    }

    return (
        <Page className={classes.root} title="Tipo de documento cadastro">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Tipo de Documento'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={5} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição*"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="descricao"
                                            name="descricao"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    display="flex"
                                    style={{ marginLeft: '5px' }}
                                >
                                    <FormControlLabel
                                        inputRef={register}
                                        name="venda"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={venda}
                                                onChange={() =>
                                                    setVenda(!venda)
                                                }
                                            />
                                        }
                                        label="Documento de Venda"
                                        labelPlacement="venda"
                                    />
                                    <FormControlLabel
                                        inputRef={register}
                                        name="compra"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={compra}
                                                onChange={() =>
                                                    setCompra(!compra)
                                                }
                                            />
                                        }
                                        label="Documento de Compra"
                                        labelPlacement="compra"
                                    />
                                    <FormControlLabel
                                        inputRef={register}
                                        name="movimentaFluxoCaixa"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={movimentaFluxoCaixa}
                                                onChange={() =>
                                                    setMovimentaFluxoCaixa(
                                                        !movimentaFluxoCaixa
                                                    )
                                                }
                                            />
                                        }
                                        label="Movimenta Fluxo de Caixa"
                                        labelPlacement="movimentaFluxoCaixa"
                                    />
                                    <FormControlLabel
                                        inputRef={register}
                                        name="intregrarEstoque"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={integrarEstoque}
                                                onChange={() =>
                                                    setIntegrarEstoque(
                                                        !integrarEstoque
                                                    )
                                                }
                                            />
                                        }
                                        label="Integra com o Estoque"
                                        labelPlacement="integrarEstoque"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
