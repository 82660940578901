import { SET_FILTRO_LISTAGEM_MOVIMENTO_ESTOQUE } from '../actions/movimentoEstoque'

export const storageMovimentoEstoque = 'movimentoEstoque'

const initialState = {
    filtroListagem: {},
}

export const movimentoEstoque = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_MOVIMENTO_ESTOQUE:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
