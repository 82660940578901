import { SET_FILTRO_LISTAGEM_CONTA_BANCARIA } from '../actions/contaBancaria';

export const storageContaBancaria = 'contaBancaria';

const initialState = {
    filtroListagem: {},
};

export const contaBancaria = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CONTA_BANCARIA:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
