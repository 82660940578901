import { SET_FILTRO_LISTAGEM_TIPO_DOCUMENTO } from '../actions/tipoDocumento';

export const storageCaixa = 'tipoDocumento';

const initialState = {
    filtroListagem: {},
};

export const tipoDocumento = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_TIPO_DOCUMENTO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
