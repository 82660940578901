import FluxoDeCaixa from '../pages/fluxoDeCaixa/fluxoDeCaixa'

export const FluxoCaixaRoute = [
    {
        path: '/app/fluxoCaixa',
        key: 'APP_FLUXO_CAIXA',
        exact: true,
        component: FluxoDeCaixa,
    },
]
