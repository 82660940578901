import React from 'react'
import Table from '../../components/Table'

export default function MovimentoEstoqueListagem(props) {
    const colunas = [
        { title: 'Data', field: 'data', editable: 'never' },
        { title: 'Insumo/Produto', field: 'produto', editable: 'never' },
        { title: 'Operação', field: 'movimentoEstoqueTipo', editable: 'never' },
        { title: 'Safra', field: 'safra', editable: 'never' },
        { title: 'Local do Estoque', field: 'localEstoque', editable: 'never' },
        {
            title: 'Conta Gerencial',
            field: 'contaGerencial',
            editable: 'never',
        },
        {
            title: 'Quantidade',
            field: 'quantidade',
            editable: 'never',
            render: (rowData) => rowData.quantidade?.toLocaleString('pt-br'),
        },
        {
            title: 'Unidade de Medida',
            field: 'unidadeMedida.sigla',
            editable: 'never'
        },
        {
            title: 'Valor Unitário',
            field: 'valorUnitario',
            editable: 'never',
            render: (rowData) =>
                rowData.valorUnitario?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
        {
            title: 'Valor Total',
            field: 'totalEntrada',
            editable: 'never',
            render: (rowData) =>
                rowData.totalEntrada?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                pageSize={props.pageSize}
                emptyRowsWhenPaging={false}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                editarCelula={props.editarCelula}
            />
        </>
    )
}
