import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/TipoPagamentoRecebimento/${id}`);
    },
    cadastrar(param) {
        return service.post(`/TipoPagamentoRecebimento`, param);
    },
    atualizar(param) {
        return service.put(`/TipoPagamentoRecebimento`, param);
    },
    buscarTudo(param) {
        return service.get(`/TipoPagamentoRecebimento${param}`);
    },
    deletar(id) {
        return service.delete(`/TipoPagamentoRecebimento/${id}`);
    }
};
