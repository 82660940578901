import React from 'react'
import { Redirect } from 'react-router-dom'
import { RenderRoutes } from './RenderRoutes'
import { isAuthenticated } from '../services/Auth'

export const EnsureAuthentication = (props) => {
    if (!isAuthenticated()) {
        return <Redirect to={'logout'} />
    } else {
        return <RenderRoutes {...props} history={props.history} />
    }
}
