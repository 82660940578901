import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, makeStyles, Box } from '@material-ui/core'
import Titulo from '../../components/Titulo/index'
import Page from '../../components/Page'
import ToolBarProdutorRural from './ToolBarProdutorRural'
import ProdutorRuralListagem from './ProdutorRuralListagem'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import ProdutorRuralService from 'services/ProdutorRuralService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const ProdutorRural = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [loading, setLoading] = useState(false)

    const [produtorRurais, setProdutorRurais] = useState([])
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.produtorRural.filtroListagem)
    )

    useEffect(() => {
        const getProdutorRurais = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'nome'
            novoFiltro.tamanhoPagina = 100
            BuscarProdutorRurais(novoFiltro)
        }
        getProdutorRurais()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_PRODUTOR_RURAL',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        BuscarProdutorRurais(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_PRODUTOR_RURAL',
            filtroListagem: filtro,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        BuscarProdutorRurais(novoFiltro)
    }

    const BuscarProdutorRurais = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId

        let params = await ObjectHelper.createParams(filtroParaPesquisa)

        ProdutorRuralService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    newFiltro.ordem = filtro.ordem

                    alteraFiltro(newFiltro)
                    setProdutorRurais(response.data.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar produtor rural')
                setLoading(false)
            })
    }

    const cadastrar = (item) => {
        history.push({
            pathname: `/app/produtorRural/cadastro`,
        })
    }

    // const visualizar = async (item) => {
    //     history.push(`/app/patrimonio/visualizar/${item.id}`)
    // }

    const editar = (itemId) => {
        history.push(`/app/produtorRural/editar/${itemId}`)
    }

    const ativarDesativar = async (itemId) => {
        setLoading(true)
        await ProdutorRuralService.ativarDesativar(itemId)
            .then((response) => {
                let novoFiltro = { ...filtro }
                BuscarProdutorRurais(novoFiltro)
            })
            .catch((error) => {
                ToastHelper.error(error.response.statusText)
                setLoading(false)
            })
    }

    const deletar = async (item) => {
        await ProdutorRuralService.deletar(item.id)
            .then((response) => {
                ToastHelper.success('Cadastro excluído com sucesso!')
                let novoFiltro = { ...filtro }
                BuscarProdutorRurais(novoFiltro)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        if (colunas[numeroColuna]?.field === 'fazenda') {
            novoFiltro.ordem = 'GrupoLocais.Nome'
        } else {
            novoFiltro.ordem = colunas[numeroColuna]?.field
        }
        alteraFiltro(novoFiltro)
        BuscarProdutorRurais(novoFiltro)
    }

    return (
        <Page className={classes.root} title="Produtores Rurais">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Produtores Rurais" />
                <ToolBarProdutorRural
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroAtivo
                />
                <Box mt={3}>
                    <ProdutorRuralListagem
                        data={produtorRurais}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        ativarDesativar={ativarDesativar}
                        deletar={deletar}
                        //visualizar={visualizar}
                        mudaOrdenacao={mudaOrdenacao}
                    />
                </Box>
            </Container>
        </Page>
    )
}
