import service from './service'

export default {
    buscarPorId(id) {
        return service.get(`/ContaBancaria/${id}`)
    },
    buscarTudo(param) {
        return service.get(`/ContaBancaria${param}`)
    },
    buscarExtrato(param) {
        return service.get(`/ContaBancaria/Extrato${param}`)
    },
    cadastrar(param) {
        return service.post(`/ContaBancaria`, param)
    },
    atualizar(param) {
        return service.put(`/ContaBancaria`, param)
    },
    ativarDesativar(id) {
        return service.put(`/ContaBancaria/AtivarDesativar`, id)
    },
    deletar(id) {
        return service.delete(`/ContaBancaria/${id}`)
    },
}
