import { TipoPagamentoRecebimento } from '../pages/tipoPagamentoRecebimento';
import { TipoPagamentoRecebimentoCadastro } from '../pages/tipoPagamentoRecebimento/TipoPagamentoRecebimentoCadastro';

export const TipoPagamentoRecebimentoRoute = [
    {
        path: '/app/tipopagamentorecebimento',
        key: 'APP_PAGAMENTO_RECEBIMENTO',
        exact: true,
        component: TipoPagamentoRecebimento,
    },
    {
        path: '/app/tipopagamentorecebimento/cadastro',
        key: 'APP_PAGAMENTO_RECEBIMENTO_CADASTRO',
        exact: true,
        component: TipoPagamentoRecebimentoCadastro,
    },
    {
        path: '/app/tipopagamentorecebimento/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_PAGAMENTO_RECEBIMENTO_EDITAR',
        exact: true,
        component: TipoPagamentoRecebimentoCadastro,
    },
];
