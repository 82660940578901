import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    linkChangeFilter: {
        marginTop: '50px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    initialGrid: {
        marginBottom: 15,
    },
    margin: {
        marginTop: '15px',
    },
}))

export default function FiltroSimples(props) {
    const classes = useStyles()
    const onKeyPres = (e) => {
        if (e.key === 'Enter') props.submit(e)
    }
    const valoresAtivo = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
    ]

    const valoresControleEstoque = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
    ]

    const valoresProdutoAcabado = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
    ]

    const valoresAnaliticoSintetico = [
        { value: 0, label: 'Sintética' },
        { value: 1, label: 'Analítica' },
    ]

    const valoresFornecedorCliente = [
        { value: '1', label: 'Cliente' },
        { value: '0', label: 'Fornecedor' },
    ]

    const valoresTipoProdutoServico = [
        { value: true, label: 'Produto' },
        { value: false, label: 'Serviço' },
    ]

    const valoresAplicadoContasPagarReceber = [
        {
            value: 'pagar',
            label: 'Aplicados à Contas a Pagar',
        },
        {
            value: 'receber',
            label: 'Aplicados à Contas a Receber',
        },
    ]

    const valoresAcrescimoDesconto = [
        {
            value: '0',
            label: 'Desconto',
        },
        {
            value: '1',
            label: 'Acréscimo',
        },
    ]

    const valoresSituacaoAdiantamento = [
        {
            value: 'DF',
            label: 'Definitivo',
        },
        {
            value: 'PG',
            label: 'Pago',
        },
        {
            value: 'CO',
            label: 'Conciliado',
        },
    ]

    const { getValues, setValue, register } = useForm({
        defaultValues: {
            analiticoSintetico: "",
            aplicadoContasPagarReceber: "",
            ativo: "",
            banco: "",
            codigo: "",
            contaCreditoId: "",
            contaDebitoId: "",
            contaGerencialId: "",
            controleEstoque: "",
            CpfCnpj: "",
            DataVencimentoFim: "",
            dataVencimentoInicio: "",
            fornecedorClienteId: "",
            locaisEstoqueId: "",
            localDestinoId: "",
            localEstoqueId: "",
            localOrigemId: "",
            nome: "",
            nomeFantasia: "",
            numeroDocumento: "",
            operacaoMovimentoEstoqueId: "",
            periodoAte: "",
            periodoDe: "",
            produto: "",
            produtoId: "",
            produtoAcabado: "",
            safraId: "",
            situacao: "",
            tipo: "",
            tipoDeConta: "",
            tipoFornecedorCliente: ""
        }
    })

    const clearFiltros = () => {
        const {
            analiticoSintetico,
            aplicadoContasPagarReceber,
            ativo,
            banco,
            codigo,
            contaCreditoId,
            contaDebitoId,
            contaGerencialId,
            controleEstoque,
            CpfCnpj,
            DataVencimentoFim,
            dataVencimentoInicio,
            fornecedorClienteId,
            locaisEstoqueId,
            localDestinoId,
            localEstoqueId,
            localOrigemId,
            nome,
            nomeFantasia,
            numeroDocumento,
            operacaoMovimentoEstoqueId,
            periodoAte,
            periodoDe,
            produto,
            produtoId,
            produtoAcabado,
            safraId,
            situacao,
            tipo,
            tipoDeConta,
            tipoFornecedorCliente,
            ...rest
        } = props.filtro

        const newFiltro = {
            ...rest,
            filtro: ""
        }

        setValue("analiticoSintetico", "")
        setValue("aplicadoContasPagarReceber", "")
        setValue("ativo", "")
        setValue("banco", "")
        setValue("codigo", "")
        setValue("contaCreditoId", "")
        setValue("contaDebitoId", "")
        setValue("contaGerencialId", "")
        setValue("controleEstoque", "")
        setValue("CpfCnpj", "")
        setValue("DataVencimentoFim", "")
        setValue("dataVencimentoInicio", "")
        setValue("fornecedorClienteId", "")
        setValue("locaisEstoqueId", "")
        setValue("localDestinoId", "")
        setValue("localEstoqueId", "")
        setValue("localOrigemId", "")
        setValue("nome", "")
        setValue("nomeFantasia", "")
        setValue("numeroDocumento", "")
        setValue("operacaoMovimentoEstoqueId", "")
        setValue("periodoAte", "")
        setValue("periodoDe", "")
        setValue("produto", "")
        setValue("produtoId", "")
        setValue("produtoAcabado", "")
        setValue("safraId", "")
        setValue("situacao", "")
        setValue("tipo", "")
        setValue("tipoDeConta", "")
        setValue("tipoFornecedorCliente", "")

        props.setFiltro(newFiltro)
        props.handleFiltro(newFiltro)
    }

    React.useEffect(() => {
        props.setFiltro(props.filtroInicial)
    }, [props.filtroInicial])

    return (
        <Grid item container xs={12} display="flex">
            <Grid
                item
                container
                xs={12}
                display="flex"
                className={classes.initialGrid}
            >
                <h4>Filtro avançado</h4>
            </Grid>
            {props.filtroNome && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="text"
                            isplaceholderanimated={(false, 'top')}
                            label="Nome"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event, newValue) => {
                                setValue("nome", newValue)

                                props.changeFiltroTextField(
                                    'nome',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().nome}
                            onKeyDown={(e) => onKeyPres(e)}
                            {...register("nome")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroNomeFantasia && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="text"
                            isplaceholderanimated={(false, 'top')}
                            label="Nome Fantasia"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event, newValue) => {
                                setValue("nomeFantasia", newValue)

                                props.changeFiltroTextField(
                                    'nomeFantasia',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().nomeFantasia}
                            onKeyDown={(e) => onKeyPres(e)}
                            {...register("nomeFantasia")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroCpfCnpj && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="text"
                            isplaceholderanimated={(false, 'top')}
                            label="CNPJ / CPF"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event, newValue) => {
                                setValue("CpfCnpj", newValue)

                                props.changeFiltroTextField(
                                    'CpfCnpj',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().CpfCnpj}
                            onKeyDown={(e) => onKeyPres(e)}
                            {...register("CpfCnpj")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroCodigo && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="text"
                            isplaceholderanimated={(false, 'top')}
                            label="Código"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event, newValue) => {
                                setValue("codigo", newValue)

                                props.changeFiltroTextField(
                                    'codigo',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().codigo}
                            onKeyDown={(e) => onKeyPres(e)}
                            {...register("codigo")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroTiposDeConta && props.filtroTiposDeConta.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="tipoDeConta"
                            size="small"
                            options={props.filtroTiposDeConta}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("tipoDeConta", newValue)

                                props.changeFiltroAutoComplete(
                                    'tipoDeConta',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().tipoDeConta ? getValues().tipoDeConta : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Tipo de conta"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("tipoDeConta")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroContas && props.filtroContas.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="conta"
                            size="small"
                            options={props.filtroContas}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) =>
                                props.changeFiltroAutoComplete(
                                    'conta',
                                    event,
                                    newValue
                                )
                            }
                            value={
                                props.filtroContas.filter(
                                    (x) =>
                                        x.value ===
                                        (props.filtro.conta
                                            ? props.filtro.conta.value
                                            : '')
                                )[0]
                            }
                            defaultValue={
                                props.filtroContas.filter(
                                    (x) =>
                                        x.value ===
                                        (props.filtro.conta
                                            ? props.filtro.conta.value
                                            : '')
                                )[0]
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Conta"
                                    placeholder="Selecione"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroBancos && props.filtroBancos.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="banco"
                            size="small"
                            options={props.filtroBancos}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("banco", newValue)

                                props.changeFiltroAutoComplete(
                                    'banco',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().banco ? getValues().banco : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Banco"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("banco")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroOperacoes && props.filtroOperacoes.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="operacao"
                            size="small"
                            options={props.filtroOperacoes}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) =>
                                props.changeFiltroAutoComplete(
                                    'operacao',
                                    event,
                                    newValue
                                )
                            }
                            value={
                                props.filtroOperacoes.filter(
                                    (x) =>
                                        x.value ===
                                        (props.filtro.operacao
                                            ? props.filtro.operacao.value
                                            : '')
                                )[0]
                            }
                            defaultValue={
                                props.filtroOperacoes.filter(
                                    (x) =>
                                        x.value ===
                                        (props.filtro.operacao
                                            ? props.filtro.operacao.value
                                            : '')
                                )[0]
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Operação"
                                    placeholder="Selecione"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroAnaliticoSintetico && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="analiticoSintetico"
                            size="small"
                            options={valoresAnaliticoSintetico}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("analiticoSintetico", newValue)

                                props.changeFiltroAtivo(
                                    'analiticoSintetico',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().analiticoSintetico ? getValues().analiticoSintetico : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Analítico / Sintético"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("analiticoSintetico")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroFornecedorCliente && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="fornecedorCliente"
                            size="small"
                            options={valoresFornecedorCliente}
                            getOptionLabel={(option) => option?.label}
                            onChange={(event, newValue) => {
                                setValue("tipoFornecedorCliente", newValue ? newValue : "")

                                props.changeFiltroAtivo(
                                    'tipoFornecedorCliente',
                                    event,
                                    newValue?.value
                                    )
                            }}
                            value={getValues().tipoFornecedorCliente ? getValues().tipoFornecedorCliente : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Fornecedor / Cliente"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("tipoFornecedorCliente")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroSituacaoAdiantamento && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="situacao"
                            size="small"
                            options={valoresSituacaoAdiantamento}
                            getOptionLabel={(option) => option?.label}
                            onChange={(event, newValue) => {
                                setValue("situacao", newValue ? newValue : "")

                                props.changeFiltroAtivo(
                                    'situacao',
                                    event,
                                    newValue?.value
                                    )
                            }}
                            value={getValues().situacao ? getValues().situacao : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Situação"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("situacao")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroProdutoServico && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="produtoServico"
                            size="small"
                            options={valoresTipoProdutoServico}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("produto", newValue)

                                props.changeFiltroProdutoServico(
                                    'produto',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().produto ? getValues().produto : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Tipo"
                                />
                            )}
                            {...register("produto")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroControleEstoque && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="controleEstoque"
                            size="small"
                            options={valoresControleEstoque}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("controleEstoque", newValue)

                                props.changeFiltroControleEstoque(
                                    'controleEstoque',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().controleEstoque ? getValues().controleEstoque : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Possui controle de estoque:"
                                />
                            )}
                            {...register("controleEstoque")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroProdutoAcabado && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="produtoAcabado"
                            size="small"
                            options={valoresProdutoAcabado}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("produtoAcabado", newValue)

                                props.changeFiltroControleEstoque(
                                    'produtoAcabado',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().produtoAcabado ? getValues().produtoAcabado : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Produto Acabado"
                                />
                            )}
                            {...register("produtoAcabado")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroAtivo && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="ativo"
                            size="small"
                            options={valoresAtivo}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("ativo", newValue)

                                props.changeFiltroAtivo(
                                    'ativo',
                                    event,
                                    newValue
                                    )
                            }}
                            value={getValues().ativo ? getValues().ativo : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Ativo"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("ativo")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroPeriodoDataVencimento && (
                <>
                    <Grid item container xs={2} display="flex">
                        <FormControl className={classes.formControl}>
                            <TextField
                                type="date"
                                isplaceholderanimated={(false, 'top')}
                                label="Data Vencimento De: "
                                className={classes.textField}
                                id="periodoDataVencimentoDe"
                                name="periodoDataVencimentoDe"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        inputProps: {max: '9999-12-31'}
                                    }
                                }
                                onChange={(event, newValue) => {
                                    setValue("dataVencimentoInicio", newValue)

                                    props.changeFiltroTextField(
                                        'dataVencimentoInicio',
                                        event,
                                        newValue
                                        )
                                }}
                                value={getValues().dataVencimentoInicio}
                                {...register("dataVencimentoInicio")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={2} display="flex">
                        <FormControl className={classes.formControl}>
                            <TextField
                                type="date"
                                isplaceholderanimated={(false, 'top')}
                                label="Data Vencimento Até: "
                                className={classes.textField}
                                id="periodoDataVencimentoAte"
                                name="periodoDataVencimentoAte"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        inputProps: {max: '9999-12-31'}
                                    }
                                }
                                onChange={(event, newValue) => {
                                    setValue("DataVencimentoFim", newValue)

                                    props.changeFiltroTextField(
                                        'DataVencimentoFim',
                                        event,
                                        newValue
                                    )
                                }}
                                value={getValues().DataVencimentoFim}
                                {...register("DataVencimentoFim")}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            {props.filtroPeriodo && (
                <>
                    <Grid item container xs={2} display="flex">
                        <FormControl className={classes.formControl}>
                            <TextField
                                type="date"
                                isplaceholderanimated={(false, 'top')}
                                label="De: "
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        inputProps: {max: '9999-12-31'}
                                    }
                                }
                                onChange={(event, newValue) => {
                                    setValue("periodoDe", newValue)

                                    props.changeFiltroTextField(
                                        'periodoDe',
                                        event,
                                        newValue
                                        )
                                }}
                                value={getValues().periodoDe}
                                {...register("periodoDe")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={2} display="flex">
                        <FormControl className={classes.formControl}>
                            <TextField
                                type="date"
                                isplaceholderanimated={(false, 'top')}
                                label="Até: "
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        inputProps: {max: '9999-12-31'}
                                    }
                                }
                                onChange={(event, newValue) => {
                                    setValue("periodoAte", newValue)

                                    props.changeFiltroTextField(
                                        'periodoAte',
                                        event,
                                        newValue
                                        )
                                }}
                                value={getValues().periodoAte}
                                {...register("periodoAte")}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            {props.filtroContasDebito && props.filtroContasDebito.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="contaDebitoId"
                            size="small"
                            name="contaDebitoId"
                            options={props.filtroContasDebito}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("contaDebitoId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'contaDebitoId',
                                    event,
                                    newValue?.value
                                )
                            }}
                            value={getValues().contaDebitoId ? getValues().contaDebitoId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Conta de débito"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("contaDebitoId")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroContasCredito && props.filtroContasCredito.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="contaCreditoId"
                            size="small"
                            name="contaCreditoId"
                            options={props.filtroContasCredito}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("contaCreditoId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'contaCreditoId',
                                    event,
                                    newValue?.value
                                    )
                            }}
                            value={getValues().contaCreditoId ? getValues().contaCreditoId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Conta de crédito"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("contaCreditoId")}
                        />
                    </FormControl>
                </Grid>
            )}

            {props.filtroNumeroDocumento && (
                <Grid item container xs={2} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="text"
                            isplaceholderanimated={(false, 'top')}
                            label="Núm. documento"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event, newValue) => {
                                setValue("numeroDocumento", newValue)

                                props.changeFiltroTextField(
                                    'numeroDocumento',
                                    event,
                                    newValue
                                )
                            }}
                            value={getValues().numeroDocumento}
                            onKeyDown={(e) => onKeyPres(e)}
                            {...register("numeroDocumento")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroLocalOrigemDestino &&
                props.filtroLocalOrigemDestino.length > 0 && (
                    <>
                        <Grid item container xs={2} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    size="small"
                                    options={props.filtroLocalOrigemDestino}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setValue("localOrigemId", newValue ? newValue : "")

                                        props.changeFiltroAutoComplete(
                                            'localOrigemId',
                                            event,
                                            newValue?.value
                                            )
                                    }}
                                    value={getValues().localOrigemId ? getValues().localOrigemId : ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Local de Origem"
                                            placeholder="Selecione"
                                        />
                                    )}
                                    {...register("localOrigemId")}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container xs={2} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    size="small"
                                    options={props.filtroLocalOrigemDestino}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setValue("localDestinoId", newValue ? newValue : "")

                                        props.changeFiltroAutoComplete(
                                            'localDestinoId',
                                            event,
                                            newValue?.value
                                            )
                                    }}
                                    value={getValues().localDestinoId ? getValues().localDestinoId : ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Local de Destino"
                                            placeholder="Selecione"
                                        />
                                    )}
                                    {...register("localDestinoId")}
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
            {props.filtroProdutos && props.filtroProdutos.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="produtoId"
                            size="small"
                            name="produtoId"
                            options={props.filtroProdutos}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("produtoId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'produtoId',
                                    event,
                                    newValue?.value
                                    )
                            }}
                            value={getValues().produtoId ? getValues().produtoId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Produto"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("produtoId")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroLocalEstoque && props.filtroLocalEstoque.length > 0 && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="localEstoqueId"
                            size="small"
                            name="localEstoque"
                            options={props.filtroLocalEstoque}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("localEstoqueId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'localEstoqueId',
                                    event,
                                    newValue?.value
                                    )
                            }}
                            value={getValues().localEstoqueId ? getValues().localEstoqueId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Local de Estoque"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("localEstoqueId")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroContasGerenciais &&
                props.filtroContasGerenciais.length > 0 && (
                    <Grid item container xs={3} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="contasGerenciais"
                                size="small"
                                options={props.filtroContasGerenciais}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    setValue("contaGerencialId", newValue ? newValue : '')

                                    props.changeFiltroAutoComplete(
                                        'contaGerencialId',
                                        event,
                                        newValue ? newValue.value : ''
                                        )
                                }}
                                value={getValues().contaGerencialId ? getValues().contaGerencialId : ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Conta Gerencial"
                                        placeholder="Selecione"
                                    />
                                )}
                                {...register("contaGerencialId")}
                            />
                        </FormControl>
                    </Grid>
                )}
            {props.filtroFornecedoresClientes &&
                props.filtroFornecedoresClientes.length > 0 && (
                    <Grid item container xs={3} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="fornecedoresClientesInput"
                                name="fornecedoresClientes"
                                size="small"
                                options={props.filtroFornecedoresClientes}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                    setValue("fornecedorClienteId", newValue ? newValue : "")

                                    props.changeFiltroAutoComplete(
                                        'fornecedorClienteId',
                                        event,
                                        newValue ? newValue.value : ''
                                        )
                                }}
                                value={getValues().fornecedorClienteId ? getValues().fornecedorClienteId : ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Fornecedor/Cliente"
                                        placeholder="Selecione"
                                    />
                                )}
                                {...register("fornecedorClienteId")}
                            />
                        </FormControl>
                    </Grid>
            )}
            {props.filtroAplicadoContasPagarReceber && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="aplicadoContasPagarReceber"
                            size="small"
                            options={valoresAplicadoContasPagarReceber}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("aplicadoContasPagarReceber", newValue ? newValue : '')

                                props.changeFiltroAutoComplete(
                                    'aplicadoContasPagarReceber',
                                    event,
                                    newValue ? newValue.value : ''
                                )
                            }}
                            value={getValues().aplicadoContasPagarReceber ? getValues().aplicadoContasPagarReceber : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Aplicado à"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("aplicadoContasPagarReceber")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroAcrescimoDesconto && (
                <Grid item container xs={3} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="tipo"
                            size="small"
                            options={valoresAcrescimoDesconto}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setValue("tipo", newValue ? newValue : '')

                                props.changeFiltroAutoComplete(
                                    'tipo',
                                    event,
                                    newValue ? newValue.value : ''
                                    )
                            }}
                            value={getValues().tipo ? getValues().tipo : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Tipo"
                                    placeholder="Selecione"
                                />
                            )}
                            {...register("tipo")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroProduto && props.filtroProduto.length > 0 && (
                <Grid item container xs={2} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="produto"
                            size="small"
                            options={props.filtroProduto}
                            getOptionLabel={(option) =>
                                option ? option.nome : null
                            }
                            onChange={(event, newValue) => {
                                setValue("produtoId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'produtoId',
                                    event,
                                    newValue ? newValue.id : ''
                                    )
                            }}
                            value={getValues().produtoId ? getValues().produtoId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Produto"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            {...register("produtoId")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroOperacaoMovimentoEstoque &&
                props.filtroOperacaoMovimentoEstoque.length > 0 && (
                    <Grid item container xs={2} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="operacaoMovimentoEstoque"
                                size="small"
                                options={props.filtroOperacaoMovimentoEstoque}
                                getOptionLabel={(option) =>
                                    option ? option.nome : null
                                }
                                onChange={(event, newValue) => {
                                    setValue("operacaoMovimentoEstoqueId", newValue ? newValue : "")

                                    props.changeFiltroAutoComplete(
                                        'operacaoMovimentoEstoqueId',
                                        event,
                                        newValue ? newValue.id : ''
                                        )
                                }}
                                value={getValues().operacaoMovimentoEstoqueId ? getValues().operacaoMovimentoEstoqueId : ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Operação"
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                {...register("operacaoMovimentoEstoqueId")}
                            />
                        </FormControl>
                    </Grid>
                )}
            {props.filtroSafra && props.filtroSafra.length > 0 && (
                <Grid item container xs={2} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="safra"
                            size="small"
                            options={props.filtroSafra}
                            getOptionLabel={(option) =>
                                option ? option.nome : ''
                            }
                            onChange={(event, newValue) => {
                                setValue("safraId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'safraId',
                                    event,
                                    newValue ? newValue.id : ''
                                    )
                            }}
                            value={getValues().safraId ? getValues().safraId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Safra"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            {...register("safraId")}
                        />
                    </FormControl>
                </Grid>
            )}
            {props.filtroLocaisEstoque && props.filtroLocaisEstoque.length > 0 && (
                <Grid item container xs={2} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            id="locaisEstoque"
                            size="small"
                            options={props.filtroLocaisEstoque}
                            getOptionLabel={(option) =>
                                option ? option.label : ''
                            }
                            onChange={(event, newValue) => {
                                setValue("locaisEstoqueId", newValue ? newValue : "")

                                props.changeFiltroAutoComplete(
                                    'locaisEstoqueId',
                                    event,
                                    newValue ? newValue.value : ''
                                    )
                            }}
                            value={getValues().locaisEstoqueId ? getValues().locaisEstoqueId : ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Local de Estoque"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            {...register("locaisEstoqueId")}
                        />
                    </FormControl>
                </Grid>
            )}
            <Grid
                xs={12}
                container
                item
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.margin}
            >
                <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={clearFiltros}
                >
                    Limpar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                        window.history.replaceState(null, null, window.location.pathname);

                        props.submit(e)
                    }}
                >
                    Buscar
                </Button>
            </Grid>
        </Grid>
    )
}
