import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import clsx from 'clsx'
import {
    Container,
    Card,
    Grid,
    Button,
    FormControlLabel,
    TextField,
    makeStyles,
    FormControl, 
} from '@material-ui/core'
import Titulo from 'components/Titulo'
import { useSelector, useDispatch } from 'react-redux'
import DataHelper from '../../utils/dataHelpder'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Resumo from './resumo'
import TabelaFluxoCaixa from './tabelaFluxoCaixa'
import { Search as SearchIcon } from 'react-feather'
import MenuSuspensoContasFiltradas from './menuSuspensoContasFiltradas'
import ObjectHelper from '../../utils/objetcHelper'
import ToastHelper from '../../utils/toastHelper'
import Grafico from './grafico'
import FluxoCaixaService from '../../services/FluxoCaixaService'
import Switch from '@material-ui/core/Switch'
import Slide from '@material-ui/core/Slide'
import moment from 'moment'
import ContasPagarReceberService from '../../services/ContaPagarReceberService'
import ContasBancariaService from '../../services/ContaBancariaService'
import Loading from '../../components/Loading'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    cardResumo: {
        marginTop: '15px',
        padding: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    icon: {
        marginRight: '5px',
        width: '15px',
    },
    buttonBuscar: {
        marginTop: '15px',
        height: '40px',
        marginRight: '40px',
    },
    list: {
        width: 'auto',
    },
    fullList: {
        width: 'auto',
    },
    error: {
        color: 'red',
    },
}))

export default function FluxoDeCaixa(props) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [checked, setChecked] = React.useState(false)

    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    const [opcoesPeriodoFiltro] = useState([
        {
            nome: 'Personalizado',
            id: 0,
        },
        {
            nome: 'Mensal',
            id: 2
        },
    ])
    
    const opcoesSituacao = [
        {id: "DF", nome: "Definitivo"},
        {id: "PG", nome: "Pago"},  
        {id: "CO", nome: "Conciliado"},  
    ]

    const [filtro, setFiltro] = useState(
        useSelector((state) => state.fluxoCaixa.filtroListagem)
    )

    const [dados, setDados] = useState([])
    const [colunas, setColunas] = useState([])
    const [saldoInicial, setSaldoInicial] = useState(0.0)
    const [totalEntradas, setTotalEntradas] = useState(0.0)
    const [totalSaidas, setTotalSaidas] = useState(0.0)
    const [saldoFinal, setSaldoFinal] = useState(0.0)

    const [opcoesContaBancaria, setOpcoesContaBancaria] = useState([]); 

    const [stateMenuSuspenso, setStateMenuSuspenso] = React.useState(false)
    const [itemSelecionado, setItemSelecionado] = React.useState([])
    const [categoria, setCategoria] = React.useState()
    const [periodoSelecionado, setPeriodoSelecionado] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [erroDataInvalida, setErroDataInvalida] = React.useState({
        error: true,
        mensagem: '',
    })

    const [erroPeriodoInicial, setErroPeriodoInicial] = React.useState({
        error: true,
        mensagem: '',
    })
    
    const [erroPeriodoFinal, setErroPeriodoFinal] = React.useState({
        error: true,
        mensagem: '',
    })

    const buscarContasPagarAdiantamentos = async (filtro, operacao) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.tamanhoPagina = 9999999
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await ContasPagarReceberService.buscarTudoFluxoCaixa(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.tamanhoPagina = 9999999

                    let result = response.data.itens;
                    result.creditoDebito = operacao
                    
                    setItemSelecionado(result)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar contas')
                setLoading(false)
            })
    }

    const buscarContasBancarias = async (filtro) => {
        
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.tamanhoPagina = 9999999
        let params = await ObjectHelper.createParams(filtroParaPesquisa)

        setLoading(true)
        await ContasBancariaService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.tamanhoPagina = 9999999

                    let opcoes = ObjectHelper.getValuesParseToSelect(response.data.itens, 'id', "descricao");
                    setOpcoesContaBancaria(opcoes)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const toggleDrawer = (_, open, item, coluna) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        if (open) {
            var dataInicial = ''
            var dataFinal = ''

            var meses = {
                janeiro: 0,
                fevereiro: 1,
                março: 2,
                abril: 3,
                maio: 4,
                junho: 5,
                julho: 6,
                agosto: 7,
                setembro: 8,
                outubro: 9,
                novembro: 10,
                dezembro: 11,
            }

            switch (filtro.opcaoPeriodoFiltroSelecionada.id) {
                case 0:
                    dataInicial = coluna?.title
                    dataFinal = coluna?.title
                    break
                case 1:
                    var datas = coluna?.title.split(' a ')
                    dataInicial = datas[0]
                    dataFinal = datas[1]
                    break
                case 2:
                    var tituloParse = coluna?.title.split('/')
                    var nomeMes = tituloParse[0]
                    var ano = tituloParse[1]
                    dataInicial = new Date(ano, meses[nomeMes], 1)
                    dataFinal = new Date(ano, meses[nomeMes] + 1, 0)
                    break
                default:
                    var anoFiltro = coluna?.title
                    dataInicial = new Date(anoFiltro, 1, 1)
                    dataFinal = new Date(anoFiltro, 11 + 1, 0)
                    break
            }

            var filtroContas = {
                contaGerencialId: item.id,
                CreditoDebito: item.operacao === 'Débito' ? 1 : 0,
                dataInicial: moment(dataInicial, 'DD/MM/YYYY').format(
                    'YYYY/MM/DD'
                ),
                dataFinal: moment(dataFinal, 'DD/MM/YYYY').format('YYYY/MM/DD'),
                fazendaId: fazendaId,
            }

            let operacao = item.operacao === "Crédito" ? 0 : 1;

            setPeriodoSelecionado(coluna?.title)

            setCategoria(item.categoria)

            buscarContasPagarAdiantamentos(filtroContas, operacao)
        } else {
            setPeriodoSelecionado('')
            setCategoria('')
            setItemSelecionado([])
        }

        setStateMenuSuspenso(open)
    }

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

    const alteraFiltroInRedux = async (filtro) => {
        await dispatch({
            type: 'SET_FILTRO_FLUXO_CAIXA',
            filtroListagem: filtro,
        })
    }

    const buscar = async () => {
        if (validacaoDeFiltro(filtro)) {
            alteraFiltroInRedux(filtro)

            var filtroMontado = {
                fazendaId: fazendaId,
                tipoFiltro: filtro.opcaoPeriodoFiltroSelecionada.id,
                dataInicio: moment(filtro.periodoInicial).format('MM/DD/YYYY'),
                dataFim: moment(filtro.periodoFinal).format('MM/DD/YYYY'),
                ...(filtro.situacoes?.length > 0 && {situacoes: filtro.situacoes.map(x => x.id)}),
                ...(filtro.contasBancaria?.length > 0 && {contasBancaria: filtro.contasBancaria.map(x => x.value)})
            }

            setLoading(true)

            await FluxoCaixaService.buscarTudo(filtroMontado).then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.fazendaId = fazendaId

                    let colunas = response.data.colunas
                    let colunasNovas = []

                    setDados(response.data.itens)

                    colunas.forEach((coluna, i) => {
                        coluna.align = 'left'

                        Object.entries(coluna).forEach((element) => {
                            let key = element[0]
                            let value = element[1]
                            if (key === 'field') {
                                coluna = {
                                    ...coluna,
                                    render: (prop) => (
                                        <div
                                            onClick={
                                                prop.tipo === 'Analítica' &&
                                                coluna.field !== 'categoria' &&
                                                prop[value] !== 0
                                                    ? toggleDrawer(
                                                          'bottom',
                                                          true,
                                                          prop,
                                                          coluna
                                                      )
                                                    : () => {}
                                            }
                                            style={{
                                                fontWeight:
                                                    prop.id ===
                                                        'saldoInicial' ||
                                                    prop.id === 'saldoFinal'
                                                        ? 500
                                                        : '',
                                                minWidth:
                                                    prop.categoria ===
                                                    'Saldo Inicial'
                                                        ? 100
                                                        : '',
                                                cursor:
                                                    prop.tipo === 'Analítica' &&
                                                    coluna.field !==
                                                        'categoria' &&
                                                    prop[value] !== 0
                                                        ? 'pointer'
                                                        : '',
                                            }}
                                        >
                                            {prop[value]?.toLocaleString(
                                                'pt-br',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }
                                            )}
                                        </div>
                                    ),
                                }
                            }
                        })
                        colunasNovas.push(coluna)
                    })

                    setColunas(colunasNovas)
                    setSaldoInicial(response.data.saldoInicial)
                    setTotalEntradas(response.data.totalEntradas)
                    setTotalSaidas(response.data.totalSaidas)
                    setSaldoFinal(response.data.saldoFinal)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
        }
    }

    const validacaoDeFiltro = (filtro) => {
        let valido = true

        if (!filtro.periodoInicial) {
            setErroPeriodoInicial({
                error: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroPeriodoInicial({
                error: false,
                mensagem: '',
            })
        }
        if (!filtro.periodoFinal) {
            setErroPeriodoFinal({
                error: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroPeriodoFinal({
                error: false,
                mensagem: '',
            })
        }
        if (filtro.periodoInicial === parseInt(filtro.periodoInicial, 10)) {
            if (filtro.periodoInicial > filtro.periodoFinal) {
                setErroDataInvalida({
                    error: true,
                    mensagem: 'Período inicial não pode ser maior que o final.',
                })
                valido = false
            } else {
                setErroDataInvalida({
                    error: false,
                    mensagem: '',
                })
            }
        } else {
            var periodoInicial = moment(filtro.periodoInicial).format(
                'MM-DD-YYYY'
            )
            var periodoFinal = moment(filtro.periodoFinal).format('MM-DD-YYYY')

            periodoInicial = new Date(periodoInicial)
            periodoFinal = new Date(periodoFinal)
            if (periodoFinal.getTime() < periodoInicial.getTime()) {
                setErroDataInvalida({
                    error: true,
                    mensagem: 'Período inicial não pode ser maior que o final',
                })
                valido = false
            } else {
                setErroDataInvalida({
                    error: false,
                    mensagem: '',
                })
            }
        }

        if(filtro.periodoFinal - filtro.periodoInicial > 7) {
            setErroPeriodoFinal({
                error: true, 
                mensagem: 'Periodo não pode ser maior que 7 dias.'
            })
        }
        return valido
    }

    const renderFilterPersonalizado = () => {
        return (
            <>
                <Grid container item md={2} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Período inicial:*"
                            variant="standard"
                            type={'date'}
                            placeholder="Selecione"
                            value={filtro.periodoInicial}
                            onChange={(e) =>
                                setFiltro({
                                    ...filtro,
                                    periodoInicial: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: { max: '9999-12-31' },
                            }}
                        />
                        <p className={classes.error}>
                            {erroPeriodoInicial.error
                                ? erroPeriodoInicial.mensagem
                                : ''}
                        </p>
                        <p className={classes.error}>
                            {erroDataInvalida.error
                                ? erroDataInvalida.mensagem
                                : ''}
                        </p>
                    </FormControl>
                </Grid>
                <Grid container item md={2} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Período Final:*"
                            variant="standard"
                            type={'date'}
                            placeholder="Selecione"
                            value={filtro.periodoFinal}
                            onChange={(e) =>
                                setFiltro({
                                    ...filtro,
                                    periodoFinal: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: { max: '9999-12-31' },
                            }}
                        />
                        <p className={classes.error}>
                            {erroPeriodoFinal.error
                                ? erroPeriodoFinal.mensagem
                                : ''}
                        </p>
                    </FormControl>
                </Grid>
            </>
        )
    }

    useEffect(() => {
        buscarContasBancarias();
    }, [])

    return (
        <Page className={classes.root} title="Fluxo de caixa">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Fluxo de caixa'} />
                <Card className={classes.card}>
                    <Box mt={1} mb={1}>
                        <Grid
                            container
                            item
                            md={12}
                            display="flex"
                            style={{ marginLeft: '15px' }}
                        >
                            <Grid container item md={2} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Autocomplete
                                        options={opcoesPeriodoFiltro}
                                        getOptionLabel={(option) =>
                                            option ? option.nome : ''
                                        }
                                        disableClearable={true}
                                        onChange={(_, data) => {
                                            if (data) {
                                                setFiltro({
                                                    opcaoPeriodoFiltroSelecionada:
                                                        data,
                                                    periodoInicial:
                                                        data.id === 0
                                                            ? DataHelper.today()
                                                            : '',
                                                })
                                            }
                                        }}
                                        size="small"
                                        getOptionSelected={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        value={
                                            filtro.opcaoPeriodoFiltroSelecionada
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Tipo de Visualização:"
                                                placeholder="Selecione"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {filtro.opcaoPeriodoFiltroSelecionada &&
                                filtro.opcaoPeriodoFiltroSelecionada.id === 1 &&
                                renderFilterPersonalizado()}
                            {filtro.opcaoPeriodoFiltroSelecionada &&
                                filtro.opcaoPeriodoFiltroSelecionada.id === 2 &&
                                renderFilterPersonalizado()}
                            {filtro.opcaoPeriodoFiltroSelecionada &&
                                filtro.opcaoPeriodoFiltroSelecionada.id === 3 &&
                                renderFilterPersonalizado()}
                            {filtro.opcaoPeriodoFiltroSelecionada &&
                                filtro.opcaoPeriodoFiltroSelecionada.id === 0 &&
                                renderFilterPersonalizado()}
                            
                            <Grid container item md={2} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Autocomplete
                                        options={opcoesSituacao}
                                        getOptionLabel={(option) =>
                                            option ? option.nome : ''
                                        }
                                        multiple={true}
                                        onChange={(_, data) => {
                                            if (data) {
                                                setFiltro({...filtro, situacoes: data})
                                            }
                                        }}
                                        size="small"
                                        getOptionSelected={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        value={
                                            filtro.situacoes
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Situação:"
                                                placeholder="Selecione"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid container item md={2} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Autocomplete
                                        options={opcoesContaBancaria}
                                        getOptionLabel={(option) =>
                                            option ? option.label : ''
                                        }
                                        multiple={true}
                                        onChange={(_, data) => {
                                            if (data) {
                                                setFiltro({...filtro, contasBancaria: data})
                                            }
                                        }}
                                        size="small"
                                        getOptionSelected={(option, value) =>
                                            option?.value === value?.value
                                        }
                                        value={
                                            filtro.contasBancaria
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Conta Bancária:"
                                                placeholder="Selecione"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid
                                container
                                item
                                md={2}
                                display="flex"
                                justify={'flex-end'}
                            >
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => buscar()}
                                    className={classes.buttonBuscar}
                                >
                                    <SearchIcon
                                        className={clsx(
                                            classes.icon,
                                            props.className
                                        )}
                                    />
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
                <Card className={classes.cardResumo}>
                    <Grid container item md={12} display="inline-flex">
                        <Grid
                            container
                            item
                            justify="flex-start"
                            md={12}
                            display="inline-flex"
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        style={{ color: '#2E7D32' }}
                                        checked={checked}
                                        onChange={handleChange}
                                    />
                                }
                                label="Exibir gráfico"
                                style={{ display: 'none' }}
                            />
                        </Grid>
                        <Slide
                            direction="down"
                            in={!checked}
                            mountOnEnter
                            unmountOnExit
                            timeout={300}
                        >
                            <Grid
                                container
                                item
                                justify="center"
                                md={12}
                                display="inline-flex"
                            >
                                <Resumo
                                    mostraGrafico={checked}
                                    saldoInicial={saldoInicial}
                                    totalSaidas={totalSaidas}
                                    totalEntradas={totalEntradas}
                                    saldoFinal={saldoFinal}
                                />
                            </Grid>
                        </Slide>
                        <Slide
                            direction="right"
                            in={checked}
                            mountOnEnter
                            unmountOnExit
                            timeout={200}
                        >
                            <Grid
                                container
                                item
                                justify="center"
                                md={6}
                                display="inline-flex"
                            >
                                <Resumo
                                    mostraGrafico={checked}
                                    saldoInicial={saldoInicial}
                                    totalSaidas={totalSaidas}
                                    totalEntradas={totalEntradas}
                                    saldoFinal={saldoFinal}
                                />
                            </Grid>
                        </Slide>
                        <Slide
                            direction="left"
                            in={checked}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Grid
                                container
                                item
                                md={6}
                                display="inline-flex"
                                justify="center"
                            >
                                <Grafico colunas={colunas} dados={dados} />
                            </Grid>
                        </Slide>
                    </Grid>
                </Card>

                <Loading open={loading} texto="Carregando fluxo de caixa" />
                <TabelaFluxoCaixa colunas={colunas || []} data={dados || []} />
                <MenuSuspensoContasFiltradas
                    periodoSelecionado={periodoSelecionado}
                    setStateMenuSuspenso={setStateMenuSuspenso}
                    stateMenuSuspenso={stateMenuSuspenso}
                    toggleDrawer={toggleDrawer}
                    itemSelecionado={itemSelecionado}
                    categoria={categoria}
                />
            </Container>
        </Page>
    )
}
