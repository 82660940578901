import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import { ProdutorRuralEsquema } from './ProdutorRural.validate'
import Page from '../../components/Page'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import {
    Container,
    Grid,
    Paper,
    TextField,
    makeStyles,
    FormControl,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Box,
} from '@material-ui/core'
import IconeInfo from '@material-ui/icons/InfoOutlined'
import { IMaskInput } from 'react-imask'
import Titulo from 'components/Titulo'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ToastHelper from '../../utils/toastHelper'
import EstadoService from 'services/EstadoService'
import MunicipioService from 'services/MunicipioService'
import GrupoLocalService from 'services/GrupoLocalService'
import ProdutorRuralService from 'services/ProdutorRuralService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    paper: {
        marginTop: '15px',
        padding: theme.spacing(2),
    },
    h2: {
        marginLeft: '5px',
        marginBottom: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    marginTop: {
        marginTop: '15px',
    },
}))

export const ProdutorRuralCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()

    const { handleSubmit, setValue, errors, control } = useForm({
        defaultValues: {
            inscricaoEstadual: '',
            cpfCnpj: '',
            nome: '',
            cnae: '',
            regimeApuracao: '',
            categoria: '',
            dataInicio: '',
            grupoLocal: null,
            padrao: false,
            cep: '',
            estado: null,
            municipio: null,
            bairro: '',
            logradouro: '',
            numero: '',
            complemento: '',
            referenciaLocalizacao: '',
            distritoPovoado: '',
        },
        resolver: yupResolver(ProdutorRuralEsquema),
    })

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

    const {
        match: { params },
    } = props

    const [opcoesEstado, setOpcoesEstado] = useState([])
    const [opcoesMunicipio, setOpcoesMunicipio] = useState([])
    const [opcoesGrupoLocais, setOpcoesGrupoLocais] = useState([])

    const buscarEstados = async () => {
        EstadoService.buscarTudo(fazendaId)
            .then((response) => {
                setOpcoesEstado(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarMunicipios = async (uf) => {
        MunicipioService.buscarPorEstado(uf)
            .then((response) => {
                setOpcoesMunicipio(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarGrupoLocais = async () => {
        GrupoLocalService.buscarPorFazenda(fazendaId)
            .then((response) => {
                setOpcoesGrupoLocais(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    function TextMaskCPFCNPJ(props) {
        return (
            <IMaskInput
                {...props}
                mask={
                    props.value.length <= 14
                        ? '000.000.000-00[0]'
                        : '00.000.000/0000-00'
                }
            />
        )
    }

    function TextMaskCEP(props) {
        return <IMaskInput {...props} mask={'00000-000'} />
    }

    function TextMaskNumero(props) {
        return <IMaskInput {...props} mask={Number} scale={0} />
    }

    const onSubmit = async (dados) => {
        var produtorRural = {
            inscricaoEstadual: dados.inscricaoEstadual,
            cpfCnpj: dados.cpfCnpj,
            nome: dados.nome,
            cnae: dados.cnae,
            regimeApuracao: dados.regimeApuracao,
            categoria: dados.categoria,
            dataInicio: dados.dataInicio,
            grupoLocalId: dados.grupoLocal.id,
            padrao: dados.padrao,
            cep: dados.cep,
            estadoId: dados.estado.id,
            municipioId: dados.municipio.id,
            bairro: dados.bairro,
            logradouro: dados.logradouro,
            numero: dados.numero,
            complemento: dados.complemento,
            referenciaLocalizacao: dados.referenciaLocalizacao,
            distritoPovoado: dados.distritoPovoado,
            fazendaId: fazendaId,
        }

        if (params.id) {
            produtorRural.id = params.id
            ProdutorRuralService.atualizar(produtorRural)
                .then((response) => {
                    ToastHelper.success('Produtor rural cadastrado com sucesso')
                    history.push(`/app/produtorRural`)
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data)
                })
        } else {
            ProdutorRuralService.cadastrar(produtorRural)
                .then((response) => {
                    ToastHelper.success('Produtor rural cadastrado com sucesso')
                    if (params.modo === 'aba') {
                        window.close()
                    } else {
                        history.push(`/app/produtorRural`)
                    }
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data)
                })
        }
    }

    const buscarProdutorRural = async (id) => {
        ProdutorRuralService.buscarPorId(id)
            .then(async (response) => {
                var produtorRural = response.data

                setValue('inscricaoEstadual', produtorRural.inscricaoEstadual)
                setValue('cpfCnpj', produtorRural.cpfCnpj)
                setValue('nome', produtorRural.nome)
                setValue('cnae', produtorRural.cnae)
                setValue('regimeApuracao', produtorRural.regimeApuracao)
                setValue('categoria', produtorRural.categoria)
                setValue('dataInicio', produtorRural.dataInicio)
                setValue('grupoLocal', produtorRural.grupoLocais)
                setValue('padrao', produtorRural.padrao)
                setValue('cep', produtorRural.cep)
                setValue('estado', produtorRural.estado)
                setValue('municipio', produtorRural.municipio)
                setValue('bairro', produtorRural.bairro)
                setValue('logradouro', produtorRural.logradouro)
                setValue('numero', produtorRural.numero)
                setValue('complemento', produtorRural.complemento)
                setValue(
                    'referenciaLocalizacao',
                    produtorRural.referenciaLocalizacao
                )
                setValue('distritoPovoado', produtorRural.distritoPovoado)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    useEffect(() => {
        async function init() {
            //metodos iniciais
            buscarEstados()
            buscarGrupoLocais()
        }
        init()
        if (params.id) {
            buscarProdutorRural(params.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page className={classes.root} title="Cadastro de Produtor Rural">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Produtor Rural'} />
                <form
                    key={1}
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Paper elevation={1} className={classes.paper}>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={6} display="flex">
                                <h2 className={classes.h2}>Dados Gerais</h2>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="inscricaoEstadual"
                                        name="inscricaoEstadual"
                                        as={
                                            <TextField
                                                label="Inscrição Estadual*"
                                                variant="standard"
                                                placeholder="Digite a inscrição estadual"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.inscricaoEstadual
                                            ? errors.inscricaoEstadual.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="cpfCnpj"
                                        name="cpfCnpj"
                                        as={
                                            <TextField
                                                label={'CPF/CNPJ*'}
                                                variant="standard"
                                                placeholder={
                                                    'Digite o CPF/CNPJ'
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputComponent:
                                                        TextMaskCPFCNPJ,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.cpfCnpj
                                            ? errors.cpfCnpj.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="nome"
                                        name="nome"
                                        as={
                                            <TextField
                                                label="Nome*"
                                                variant="standard"
                                                placeholder="Digite o nome"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.nome ? errors.nome.message : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="cnae"
                                        name="cnae"
                                        as={
                                            <TextField
                                                label="CNAE"
                                                variant="standard"
                                                placeholder="Digite a inscrição estadual"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.cnae ? errors.cnae.message : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="regimeApuracao"
                                        name="regimeApuracao"
                                        as={
                                            <TextField
                                                label="Regime de Apuração/Enquadramento"
                                                variant="standard"
                                                placeholder={
                                                    'Digite a Apuração/Enquadramento'
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.regimeApuracao
                                            ? errors.regimeApuracao.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="categoria"
                                        name="categoria"
                                        as={
                                            <TextField
                                                label="Categoria"
                                                variant="standard"
                                                placeholder="Digite a categoria"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.categoria
                                            ? errors.categoria.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="dataInicio"
                                        name="dataInicio"
                                        as={
                                            <TextField
                                                label="Data de Início do Contrato*"
                                                type="date"
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={
                                                    {
                                                        inputProps: {max: '9999-12-31'}
                                                    }
                                                }
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.dataInicio
                                            ? errors.dataInicio.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="grupoLocal"
                                        name="grupoLocal"
                                        as={(props) => (
                                            <Autocomplete
                                                options={opcoesGrupoLocais}
                                                getOptionLabel={(option) =>
                                                    option ? option.nome : ''
                                                }
                                                onChange={(_, data) => {
                                                    props.onChange(data)
                                                }}
                                                size="small"
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) => option?.id === value?.id}
                                                value={props.value}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Fazenda*"
                                                        placeholder="Selecione uma Fazenda"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                autoSelect
                                            />
                                        )}
                                    />
                                    <p className={classes.errors}>
                                        {errors.grupoLocal
                                            ? errors.grupoLocal.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <Box>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            name="padrao"
                                            id="padrao"
                                            as={(props) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(e) =>
                                                                props.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={
                                                                props.value
                                                            }
                                                        />
                                                    }
                                                    label="Produtor Rural Padrão"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <Tooltip
                                        title="Produtor Rural"
                                        className={classes.marginTop}
                                    >
                                        <IconeInfo />
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className={classes.paper}>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={6} display="flex">
                                <h2 className={classes.h2}>Endereço</h2>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="cep"
                                        name="cep"
                                        as={
                                            <TextField
                                                label="CEP*"
                                                variant="standard"
                                                placeholder="Digite o CEP"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputComponent: TextMaskCEP,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.cep ? errors.cep.message : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="estado"
                                        name="estado"
                                        as={(props) => (
                                            <Autocomplete
                                                options={opcoesEstado}
                                                getOptionLabel={(option) =>
                                                    option ? option.nome : ''
                                                }
                                                onChange={(_, data) => {
                                                    props.onChange(data)
                                                    if (data) {
                                                        buscarMunicipios(
                                                            data.uf
                                                        )
                                                    }
                                                    setValue('municipio', null)
                                                }}
                                                size="small"
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) => option?.id === value?.id}
                                                value={props.value}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Estado*"
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                autoSelect
                                            />
                                        )}
                                    />
                                    <p className={classes.errors}>
                                        {errors.estado
                                            ? errors.estado.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="municipio"
                                        name="municipio"
                                        as={(props) => (
                                            <Autocomplete
                                                options={opcoesMunicipio}
                                                getOptionLabel={(option) =>
                                                    option ? option.nome : ''
                                                }
                                                onChange={(_, data) => {
                                                    props.onChange(data)
                                                }}
                                                size="small"
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) => option?.id === value?.id}
                                                value={props.value}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Município*"
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                autoSelect
                                            />
                                        )}
                                    />
                                    <p className={classes.errors}>
                                        {errors.municipio
                                            ? errors.municipio.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="bairro"
                                        name="bairro"
                                        as={
                                            <TextField
                                                label="Bairro*"
                                                variant="standard"
                                                placeholder="Digite o Bairro"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.bairro
                                            ? errors.bairro.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="logradouro"
                                        name="logradouro"
                                        as={
                                            <TextField
                                                label="Logradouro*"
                                                variant="standard"
                                                placeholder="Digite o Logradouro"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.logradouro
                                            ? errors.logradouro.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="numero"
                                        name="numero"
                                        as={
                                            <TextField
                                                label="Número*"
                                                variant="standard"
                                                placeholder="Digite o Número"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputComponent:
                                                        TextMaskNumero,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.numero
                                            ? errors.numero.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="complemento"
                                        name="complemento"
                                        as={
                                            <TextField
                                                label="Complemento"
                                                variant="standard"
                                                placeholder="Digite o Complemento"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.complemento
                                            ? errors.complemento.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="referenciaLocalizacao"
                                        name="referenciaLocalizacao"
                                        as={
                                            <TextField
                                                label="Referência de Localização"
                                                variant="standard"
                                                placeholder="Digite a Referência de Localização"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.referenciaLocalizacao
                                            ? errors.referenciaLocalizacao
                                                  .message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="distritoPovoado"
                                        name="distritoPovoado"
                                        as={
                                            <TextField
                                                label="Distrito/Povoado"
                                                variant="standard"
                                                placeholder="Digite o Distrito/Povoado"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {errors.distritoPovoado
                                            ? errors.distritoPovoado.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
