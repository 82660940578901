import { SET_FILTRO_LISTAGEM_ADIANTAMENTO } from '../actions/adiantamento';

export const storageAdiantamento = 'adiantamento';

const initialState = {
    filtroListagem: {
        fazendaId: JSON.parse(localStorage.getItem('fazenda')) ? JSON.parse(localStorage.getItem('fazenda')).value : undefined,
        ativo: true
    },
};

export const adiantamento = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_ADIANTAMENTO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
