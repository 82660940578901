import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/CategoriaProdutoServico/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/CategoriaProdutoServico${param}`);
    },
    buscarBancos() {
        return service.get('/CategoriaProdutoServico');
    },
    cadastrar(param) {
        return service.post(`/CategoriaProdutoServico`, param);
    },
    atualizar(param) {
        return service.put(`/CategoriaProdutoServico`, param);
    },
    ativarDesativar(id) {
        return service.put(`/CategoriaProdutoServico/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/CategoriaProdutoServico/${id}`);
    },
};
