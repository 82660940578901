 import { TipoPatrimonioCadastro } from '../pages/tipoPatrimonio/TipoPatrimonioCadastro';
import { TipoPatrimonio } from '../pages/tipoPatrimonio/index';

export const TipoPatrimonioRoute = [
    {
        path: '/app/tipoPatrimonio/cadastro',
        key: 'APP_TIPO_PATRIMONIO_CADASTRO',
        exact: true,
        component: TipoPatrimonioCadastro,
    },
    {
        path: '/app/tipoPatrimonio',
        key: 'APP_TIPO_PATRIMONIO',
        exact: true,
        component: TipoPatrimonio,
    },
    {
        path: '/app/tipoPatrimonio/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_TIPO_PATRIMONIO_EDITAR_VISUALIZAR',
        exact: true,
        component: TipoPatrimonioCadastro,
    }
];
