import { STORE_TOKEN } from '../actions/auth';

export const storageTokenKey = '@projeto-token';

const initialState = {
    token: localStorage.getItem(storageTokenKey),
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case STORE_TOKEN:
            if (action.token) {
                localStorage.setItem(storageTokenKey, action.token);
            } else {
                localStorage.removeItem(storageTokenKey);
            }

            return {
                ...state,
                token: action.token,
            };
        default:
            return state;
    }
};
