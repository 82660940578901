import React, { useState, useEffect } from 'react'
import Page from 'components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from './Toolbar'
import GestaoContratoListagem from './GestaoContratoListagem'
import Titulo from 'components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import GestaoContratoService from 'services/GestaoContratoService'
import ToastHelper from 'utils/toastHelper'
import ObjectHelper from 'utils/objetcHelper'
import Request from './Request'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const GestaoContrato = (props) => {
    const fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [contas, setContas] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.gestaoContrato.filtroListagem)
    )

    useEffect(() => {
        const getContasPagar = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'NumeroPedido'
            novoFiltro.tamanhoPagina = 100
            buscarGestaoContratos(novoFiltro)
        }
        getContasPagar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_GESTAO_CONTRATO',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (param) => {
        buscarGestaoContratos(param)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_GESTAO_CONTRATO',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/gestaocontrato/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarGestaoContratos(novoFiltro)
    }

    const buscarGestaoContratos = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        if (filtroParaPesquisa.ordem === undefined)
            filtroParaPesquisa.ordem = 'NumeroPedido'
        await alteraFiltro(filtroParaPesquisa)
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await GestaoContratoService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    newFiltro.ordem = filtro.ordem

                    alteraFiltro(newFiltro)
                    setContas(response.data.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar contratos')
                setLoading(false)
            })
    }

    const editar = (item) => {
        window.location.href = `/app/gestaocontrato/editar/${item.id}`
    }

    const visualizar = (item) => {
        window.location.href = `/app/gestaocontrato/visualizar/${item.id}`
    }

    const duplicar = async (item) => {
        setLoading(true)
        let data = await Request.duplicar(item, fazendaId)
        if (data.isOk) handleFiltro(filtro)
        else ToastHelper.error('Erro ao duplicar contrato!')
        setLoading(false)
    }

    const gerarContaReceber = async (id) => {
        window.location.href = `/app/conta/receber/gerar/${id}`
    }

    const deletar = async (item) => {
        setLoading(true)
        await GestaoContratoService.deletar(item.id)
            .then((response) => {
                ToastHelper.success('Contrato excluído com sucesso.')
                handleFiltro(filtro)
                setLoading(false)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        if (numeroColuna === -1) return

        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        if (novoFiltro.ordem === 'produto') return

        if (novoFiltro.ordem === 'entregaPrevista') return

        if (novoFiltro.ordem === 'cliente')
            novoFiltro.ordem = 'FornecedorCliente.NomeFantasia'

        if (novoFiltro.ordem === 'valor') novoFiltro.ordem = 'ValorLiquido'

        alteraFiltro(novoFiltro)
        buscarGestaoContratos(novoFiltro)
    }

    const [produtosServicos, setProdutosServicos] = useState([])
    const [centroCusto, setcentroCusto] = useState([])
    const [contaGerencial, setcontaGerencial] = useState([])

    const [opcoesFornecedoresClientes, setOpcoesFornecedoresClientes] =
        useState([])
    const buscarFornecedoresClientes = async () => {
        let data = await Request.buscarFornecedoresClientes()
        setOpcoesFornecedoresClientes(data)
    }

    const buscarCentrosDeCusto = async () => {
        let data = await Request.buscarCentrosDeCusto()
        setcentroCusto(data)
    }

    const buscarProdutosSerivocs = async () => {
        let data = await Request.buscarProdutosServicos()
        setProdutosServicos(data)
    }

    const buscarContasGerenciais = async () => {
        let data = await Request.buscarContasGerenciais()
        setcontaGerencial(data)
    }

    useEffect(() => {
        buscarProdutosSerivocs()
        buscarCentrosDeCusto()
        buscarContasGerenciais()
        buscarFornecedoresClientes()
    }, [])

    return (
        <Page className={classes.root} title={'Gestão de Contratos'}>
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Gestão de Contratos'} />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroAtivo
                    temFiltroAvancado
                    contaGerencial={contaGerencial}
                    produtosServicos={produtosServicos}
                    centroCusto={centroCusto}
                    opcoesFornecedoresClientes={opcoesFornecedoresClientes}
                />
                <Box mt={3}>
                    <GestaoContratoListagem
                        data={contas}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        visualizar={visualizar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        renderizarLinhasEmBranco={false}
                        duplicar={duplicar}
                        gerarContaReceber={gerarContaReceber}
                    />
                </Box>
            </Container>
        </Page>
    )
}
