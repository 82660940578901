import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/MovimentoEstoque/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/MovimentoEstoque${param}`);
    },
    cadastrar(param) {
        return service.post(`/MovimentoEstoque`, param);
    },
    atualizar(param) {
        return service.put(`/MovimentoEstoque`, param);
    },
    ativarDesativar(id) {
        return service.put(`/MovimentoEstoque/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/MovimentoEstoque/${id}`);
    },
    buscarTipos(fazendaId, tipoOperacao){
        return service.get(`/MovimentoEstoque/BuscarTipos/${fazendaId}/${tipoOperacao}`);
    }
};
