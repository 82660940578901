import * as Yup from 'yup';

export const ContaGerencialEsquema = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    item: Yup.string().required('Campo obrigatório'),
    operacao: Yup.string().required('Campo obrigatório'),
});

export const ContaGerencialEsquema2 = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    item: Yup.string().required('Campo obrigatório'),
});

export const ContaGerencialEsquemaEditarComFilhos = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
});
