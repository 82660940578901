import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    Card,
    CardContent,
    TextField,
    InputAdornment,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import CentroCustoService from '../../services/CentroCustoService'
import ToastHelper from '../../utils/toastHelper'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MaterialTable from 'material-table'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Edit3 as Edit3Icon } from 'react-feather'
import DataHelper from '../../utils/dataHelpder'
import { CentroCustoRateioEsquema } from './CentroCusto.validate'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#009688',
        },
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    addButton: {
        marginTop: 10,
    },
    textField: {},
}))

export const RateioCentroDeCusto = (props) => {

    const classes = useStyles()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda')).value

    const [opcoesCentroCusto, setOpcoesCentroCusto] = useState([])
    const [centroCustoSelecionado, setCentroCustoSelecionado] = useState('')

    const colunas = [
        {
            title: 'Centro de Custo',
            field: 'centroCusto.label',
            sorting: false,
            render: (rowData) => rowData.centroCusto.label,
            editComponent: (props) => {
                return (
                    <Autocomplete
                        name="centroCusto"
                        id="centroCusto"
                        inputRef={register}
                        options={opcoesCentroCusto}
                        value={props.value}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option) => option.value}
                        size="small"
                        onChange={(event, newValue) => {
                            var data = { ...props.rowData }
                            data.centroCusto = newValue
                            props.onRowDataChange(data)
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    name="centroCusto"
                                    id="centroCusto"
                                    inputRef={register}
                                    value={props.value}
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )
                        }}
                        autoSelect
                    />
                )
            },
        },
        {
            title: 'Percentual',
            field: 'percentual',
            sorting: false,
            render: (rowData) => (
                <TextField
                    type="number"
                    step="any"
                    disabled={true}
                    value={rowData.percentual}
                    inputProps={{
                        min: 0,
                        max: 100,
                        style: { textAlign: 'right' },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />
            ),
            editComponent: (props) => {
                return (
                    <TextField
                        type="number"
                        step="any"
                        value={props.value}
                        inputProps={{
                            min: 0,
                            max: 100,
                            style: { textAlign: 'right' },
                        }}
                        InputProps={{
                            defaultValue: props.value,
                            startAdornment: (
                                <InputAdornment position="start">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            var data = { ...props.rowData }
                            data.percentual = e.target.value
                            props.onRowDataChange(data)
                        }}
                        error={props.value === ''}
                        helperText={props.value === '' ? 'Inválido' : ''}
                    />
                )
            },
        },
        {
            title: 'Data Inicial',
            field: 'dataInicial',
            sorting: false,
            render: (rowData) => (
                <TextField
                    type="month"
                    step={1}
                    disabled={true}
                    value={rowData.dataInicial}
                />
            ),
            editComponent: (props) => {
                return (
                    <TextField
                        id="dataInicial"
                        name="dataInicial"
                        type="month"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            {
                                inputProps: {max: '9999-12'}
                            }
                        }
                        value={props.value}
                        onChange={(e) => {
                            var data = props.rowData
                            if (e.target.value !== '')
                                data.dataInicial = e.target.value
                            props.onRowDataChange(data)
                        }}
                    />
                )
            },
        },
        {
            title: 'Observação',
            field: 'observacao',
            sorting: false,
            editComponent: (props) => {
                return (
                    <TextField
                        id="observacao"
                        name="observacao"
                        type="text"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={props.value}
                        onChange={(e) => {
                            var data = props.rowData
                            if (e.target.value !== '')
                                data.observacao = e.target.value
                            props.onRowDataChange(data)
                        }}
                    />
                )
            },
        },
    ]

    const { register, handleSubmit, reset, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            dataInicial: '',
            percentual: '',
            centroCustoSelecionado: '',
        },
        resolver: yupResolver(CentroCustoRateioEsquema),
    })

    const addCentroCusto = (parametros) => {
        parametros.PatrimonioId = props.PatrimonioId
        parametros.centroCusto = centroCustoSelecionado

        var array = props.dados
        var podeAdicionar = true
        var dataInicial = ''
        var centroCusto = ''

        array.forEach((element) => {
            if (
                element.centroCusto.value === parametros.centroCusto.value &&
                element.dataInicial === parametros.dataInicial
            ) {
                podeAdicionar = false
                centroCusto = parametros.centroCusto.label
                dataInicial = DataHelper.transformStringDataMonthUsaToPtBr(
                    parametros.dataInicial
                )
            }
        })

        if (podeAdicionar) {
            array.push(parametros)
            array.sort((a, b) => (a.dataInicial < b.dataInicial ? 1 : -1))
            props.setDados(array)
            setCentroCustoSelecionado('')
            setValue('centroCustoSelecionado', undefined)
            reset()
        } else {
            ToastHelper.error(
                'Centro de custo ' +
                    centroCusto +
                    ' já está adicionado na data de ' +
                    dataInicial
            )
        }
    }

    useEffect(() => {
        buscarCentrosDeCusto()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarCentrosDeCusto = async () => {
        const { data } = await CentroCustoService.BuscarCentrosCustoRateioPatrimonio(
            FazendaId
        )
        setOpcoesCentroCusto(data)
    }

    const deletarItem = async (params) => {
        let newDados = props.dados
        await newDados.splice(params.tableData.id, 1)
        await props.setDados([])
        await props.setDados(newDados)
        ToastHelper.success('Operação realizada com sucesso!')
    }

    return (
        <>

                <form
                    className={classes.form}
                    id="form-centro-custo"
                    onSubmit={handleSubmit(addCentroCusto)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item md={12} display="flex">
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="centroCustoSelecionado"
                                            id="centroCustoSelecionado"
                                            inputRef={register()}
                                            disabled={props.visualizar}
                                            options={opcoesCentroCusto}
                                            value={centroCustoSelecionado}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setCentroCustoSelecionado(
                                                    newValue
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="centroCustoSelecionado"
                                                    id="centroCustoSelecionado"
                                                    inputRef={register({
                                                        required: true,
                                                    })}
                                                    variant="standard"
                                                    label="Centro de Custo*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                        <p className={classes.errors}>
                                            {errors.centroCustoSelecionado
                                                ? errors.centroCustoSelecionado
                                                        .message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            step="any"
                                            type="number"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            label="Percentual*"
                                            disabled={props.visualizar}
                                            className={classes.textField}
                                            inputRef={register}
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                                style: { textAlign: 'right' },
                                            }}
                                            name="percentual"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="end">
                                                        %
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.percentual
                                                ? errors.percentual.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>

                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="dataInicial"
                                            name="dataInicial"
                                            label="Data Inicial*"
                                            type="month"
                                            disabled={props.visualizar}
                                            step={1}
                                            className={classes.textField}
                                            inputRef={register}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12'}
                                                }
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.dataInicial
                                                ? errors.dataInicial.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>

                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={
                                            classes.formControl
                                        }
                                    >
                                        <TextField
                                            type="text"
                                            id="observacao"
                                            name="observacao"
                                            disabled={props.visualizar}
                                            label="Observação:"
                                            className={
                                                classes.textField
                                            }
                                            inputRef={register}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={1} display="flex">
                                    <Fab
                                        color="primary"
                                        size="small"
                                        form={"form-centro-custo"}
                                        onClick={handleSubmit(addCentroCusto)}
                                        disabled={props.visualizar}
                                        className={classes.addButton}
                                        aria-label="add"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            title={'Apropriações'}
                            columns={colunas}
                            data={props.dados}
                            options={{
                                pageSize: 500,
                                paging: false,
                                padding: 'dense',
                                rowStyle: {
                                    fontSize: 14,
                                },
                                search: false,
                                actionsColumnIndex: -1,
                                emptyRowsWhenPaging: false,
                            }}
                            editable={{
                                isEditable: rowData => props.visualizar === false,
                                isDeletable: rowData => props.visualizar === false,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            var array = props.dados
                                            array[oldData.tableData.id] =
                                                newData
                                                props.setDados(array)
                                            resolve()
                                        }, 1000)
                                    }),
                            }}
                            actions={[
                                {
                                    disabled: props.visualizar === true,
                                    icon: DeleteIcon,
                                    tooltip: 'Excluir',
                                    onClick: (event, rowData) =>
                                        deletarItem(rowData),
                                },
                            ]}
                            icons={{ Edit: Edit3Icon }}
                            localization={{
                                header: {
                                    actions: 'Ações',
                                },
                                body: {
                                    emptyDataSourceMessage:
                                        'Nenhum registro para exibir',
                                    addTooltip: 'Adicionar',
                                    deleteTooltip: 'Excluir',
                                    editTooltip: 'Edição rápida',
                                    editRow: {
                                        deleteText:
                                            'Deseja excluir essa linha?',
                                        cancelTooltip: 'Cancelar',
                                        saveTooltip: 'Confirmar',
                                    },
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: 'Pesquisar',
                                },
                            }}
                        />
                    </MuiThemeProvider>
                </form>
        </>
    )
}
