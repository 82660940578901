import { PatrimonioCadastro } from '../pages/patrimonio/PatrimonioCadastro'
import { Patrimonio } from '../pages/patrimonio/index'

export const PatrimonioRoute = [
    {
        path: '/app/patrimonio',
        key: 'APP_PATRIMONIO',
        exact: true,
        component: Patrimonio,
    },
    {
        path: '/app/patrimonio/cadastro',
        key: 'APP_PATRIMONIO_CADASTRO',
        exact: true,
        component: PatrimonioCadastro,
    },
    {
        path: '/app/patrimonio/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_PATRIMONIO_EDITAR_VISUALIZAR',
        exact: true,
        component: PatrimonioCadastro,
    }
]
