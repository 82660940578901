import React, { } from 'react'
import Grid from '@material-ui/core/Grid'
import {
    Tooltip,
} from '@material-ui/core'
import IconeInfo from '@material-ui/icons/InfoOutlined'

/**
 * 
 * @param {string label, string tooltipText, int mt}
 *                                                      label:           Texto a ser apresentado no label do componente
 *                                                      tooltipText:     Texto a ser apresentado no tooltip
 *                                                      mt(opcional):    Margem superior para alinhamento do ícone, quando necessário
 * @returns object Componente para estilização do label
 */

export const LabelWithInfo = ({ label, tooltipText, mt = -5 }) => (
    <>
        <Grid
            container
            justify="space-between"
            spacing={24}
            display="flex"
        >
            <Grid>{label}</Grid>
            <Grid>
                <Tooltip
                    title={tooltipText}
                >
                    <IconeInfo style={{ marginTop: mt }} />
                </Tooltip>
            </Grid>:
        </Grid>
    </>
)
/**
 * @link requisição https://trello.com/c/u1IhHpBA/66-financeiro-contas-a-pagar-e-receber-numero-de-documento-3-vt-alinhado 
 */