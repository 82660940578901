import React from 'react'
import { Link } from 'react-router-dom'
import DataHelper from 'utils/dataHelpder'
import Table from '../../../src/components/Table'
import { ExportCsv, ExportPdf } from '@material-table/exporters'

export default function ExtratoBancarioListagem(props) {
    const getRota = (tipoOperacaoExtrato, idEntidade) => {
        switch (tipoOperacaoExtrato) {
            case 0:
                return `/app/conta/pagar/editar/${idEntidade}`
            case 1:
                return `/app/conta/receber/editar/${idEntidade}`
            case 2:
            case 3:
                return `/app/adiantamentoFornecedoresClientes/editar/${idEntidade}`
            case 4:
            case 5:
                return `/app/transferenciaBancaria/visualizar/${idEntidade}`
            default:
                return ''
        }
    }

    return (
        <>
            <Table
                title={'Listagem'}
                columns={[
                    {
                        title: 'Data Mov.',
                        field: 'dataMovimentacao',
                        editable: 'never',
                        cellStyle: {
                            width: '10%',
                            minWidth: '10%',
                            maxWidth: '10%',
                        },
                        headerStyle: {
                            width: '10%',
                            minWidth: '10%',
                            maxWidth: '10%',
                        },
                        render: (rowData) =>
                            rowData.dataMovimentacao
                                ? DataHelper.transformStringDataUsaToPtBr(
                                      rowData.dataMovimentacao
                                  )
                                : '-',
                    },
                    {
                        title: 'Doc.',
                        field: 'numeroDocumento',
                        editable: 'never',
                        render: (rowData) => {
                            if (rowData.idEntidade) {
                                const rota = getRota(
                                    rowData.tipoOperacaoExtrato,
                                    rowData.idEntidade
                                )
                                return (
                                    <Link to={rota} target="_blank">
                                        {rowData.numeroDocumento}
                                    </Link>
                                )
                            }

                            return rowData.numeroDocumento
                        },
                        cellStyle: {
                            width: '10%',
                            minWidth: '10%',
                            maxWidth: '10%',
                        },
                        headerStyle: {
                            width: '10%',
                            minWidth: '10%',
                            maxWidth: '10%',
                        },
                    },
                    {
                        title: 'Histórico',
                        field: 'historico',
                        editable: 'never',
                        cellStyle: {
                            width: '50%',
                            minWidth: '50%',
                            maxWidth: '50%',
                        },
                        headerStyle: {
                            width: '50%',
                            minWidth: '50%',
                            maxWidth: '50%',
                        },
                    },
                    {
                        title: 'Valor',
                        field: 'valor',
                        editable: 'never',
                        cellStyle: {
                            width: '15%',
                            minWidth: '15%',
                            maxWidth: '15%',
                        },
                        headerStyle: {
                            width: '15%',
                            minWidth: '15%',
                            maxWidth: '15%',
                        },
                        render: (rowData) =>
                            rowData.creditoDebito === 0 ? (
                                <p style={{ color: 'blue' }}>
                                    {rowData.valor?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </p>
                            ) : (
                                <p style={{ color: 'red' }}>
                                    {`${rowData.valor?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}`}
                                </p>
                            ),
                    },
                    {
                        title: 'Saldo',
                        field: 'saldo',
                        editable: 'never',
                        cellStyle: {
                            width: '15%',
                            minWidth: '15%',
                            maxWidth: '15%',
                        },
                        headerStyle: {
                            width: '15%',
                            minWidth: '15%',
                            maxWidth: '15%',
                        },
                        render: (rowData) =>
                            rowData.saldo >= 0 ? (
                                <p style={{ color: 'blue' }}>
                                    {rowData.saldo?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </p>
                            ) : (
                                <p style={{ color: 'red' }}>
                                    {rowData.saldo?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </p>
                            ),
                    },
                ]}
                data={props.data}
                paging={false}
                exportMenu={[
                    {
                        label: 'Exportar PDF',
                        exportFunc: (cols, datas) =>
                            ExportPdf(
                                cols,
                                datas,
                                `Extrato Bancário ${props.contaBancaria} (${props.dataInicial} a ${props.dataFinal})`
                            ),
                    },
                    {
                        label: 'Exportar CSV',
                        exportFunc: (cols, datas) =>
                            ExportCsv(
                                cols,
                                datas,
                                `Extrato Bancário ${props.contaBancaria} (${props.dataInicial} a ${props.dataFinal})`
                            ),
                    },
                ]}
            />
        </>
    )
}
