import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/CentroNegocio/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/CentroNegocio${param}`);
    },
    cadastrar(param) {
        return service.post(`/CentroNegocio`, param);
    },
    atualizar(param) {
        return service.put(`/CentroNegocio`, param);
    },
    ativarDesativar(id) {
        return service.put(`/CentroNegocio/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/CentroNegocio/${id}`);
    },
};
