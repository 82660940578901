import React from 'react'
import { Button, Divider, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { numeroParaTextoCardinais } from '../util'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),
        margin: 'auto',
        width: '400px',
        marginTop: '200px',
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    valorTotal: {
        display: 'inline-flex !important',
    },
}))

export default function ModalExclusaoListagem(props) {
    const classes = useStyles()

    const submit = () => {
        props.close()
        props.deletar()
    }

    const gerarMensagem = () => {
        const txtParcela = ['parcela', 'parcelas'];
        const txtArtigo = ['', ' as'];
        const txtQuantidade = ['essa', 'TODAS'];
        const txtAcao = ['será excluída', 'serão excluídas'];

        let flexao = 0;
        if(props.quantidadeParcela > 1){
            flexao = 1;
        }

        let mensagem = `Essa conta possui ${numeroParaTextoCardinais(props.quantidadeParcela)} ${txtParcela[flexao]}.`;
        mensagem += ` Se você continuar ${txtQuantidade[flexao]}${txtArtigo[flexao]} ${txtParcela[flexao]} ${txtAcao[flexao]}.
`;
        mensagem += ` Deseja continuar?`;

        return mensagem;
    }

    return (
        <>
            <div className={classes.paper}>
                <h3 className={classes.title}>
                    {gerarMensagem()}
                </h3>

                <form className={classes.form}>
                    <Divider className={classes.divider} />

                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.close()
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="inherit"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={submit}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </form>
            </div>
        </>
    )
}
