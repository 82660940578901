import React from 'react'
import { Card, Grid, makeStyles } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import Table from '../../components/Table'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'

import MoneyOnIcon from '@material-ui/icons/AttachMoney'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import ConciliadoIcon from '@material-ui/icons/DoneAll'
import NConciliadoIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
    list: {
        width: 'auto',
    },
    fullList: {
        width: 'auto',
    },
    card: {
        overflow: 'auto',
        height: '400px',
    },
}))


export default function MenuSuspensoContasFiltradas(props) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#009688',
            },
        },
        overrides: {
            MuiGrid: {
                container: {
                    display: 'inline !important',
                    width: '100% !important',
                },
            },
        },
    })

    const classes = useStyles()

    const getUrlToRedirect = (prop) => {

        let url = '';
        switch(prop.tipoTransacaoFinanceira){
            case 0: 
                prop.creditoDebito === 0
                    ? url = `/app/conta/receber/editar/${prop.despesaReceitaId}`
                    : url = `/app/conta/pagar/editar/${prop.despesaReceitaId}`
                break;
            case 1:
                prop.creditoDebito === 0
                    ? url = `/app/adiantamentoFornecedoresClientes/receber/visualizar/${prop.id}`
                    : url = `/app/adiantamentoFornecedoresClientes/pagar/visualizar/${prop.id}`
                break;

            case 2: 
                url = `/app/transferenciaBancaria/visualizar/${prop.id}`

        }

        return url;

    }

    const colunas = [
        { title: 'Venc.', field: 'dataVencimento', editable: 'never' },
        {
            title: 'Fornecedor/Cliente',
            field: 'nomeFantasia',
            editable: 'never',
        },
        {
            title: 'Documento',
            field: 'documento',
            editable: 'never',
            render: (prop) => (
                <Link
                    target="_blank"
                    to={getUrlToRedirect(prop)}
                    style={{ color: 'blue', cursor: 'pointer' }}
                >
                    {prop.documento}
                </Link>
            ),
        },
        {
            title: 'Descrição',
            field: 'descricao',
            editable: 'never',
            render: (prop) => (
                <div
                    style={{
                        width: '400px',
                    }}
                >
                    {prop.descricao}
                </div>
            ),
        },
        { title: 'Parcela', field: 'parcela', editable: 'never' },
        {
            title: 'Valor',
            field: 'valor',
            editable: 'never',
            cellStyle: {
                textAlign: 'left',
            },
            render: (prop) => (
                <div
                    style={{
                        width: '100px',
                        color: props?.itemSelecionado?.creditoDebito === 1 ? 'red' : '#2E7D32',
                    }}
                >
                    {prop.valor.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            ),
        },
        {
            title: 'Pago/Conciliado',
            field: 'dataPagamento',
            editable: 'never',
            cellStyle: {
                textAlign: 'left',
            },
            render: (prop) => (
                <div style={{ display: 'inline-flex' }}>
                    <div
                        style={{
                            color: prop?.dataPagamento ? '#2E7D32' : 'red',
                            marginRight: '10px',
                        }}
                    >
                        <Tooltip
                            title={
                                prop?.dataPagamento
                                    ? 'Data de pagamento: ' +
                                      prop?.dataPagamento
                                    : ''
                            }
                        >
                            {prop?.dataPagamento ? (
                                <MoneyOnIcon />
                            ) : (
                                <MoneyOffIcon />
                            )}
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            color: prop?.dataCompensacao ? '#2E7D32' : 'red',
                            marginRight: '15px',
                        }}
                    >
                        <Tooltip
                            title={
                                prop?.dataCompensacao
                                    ? 'Data de compensação: ' +
                                      prop?.dataCompensacao
                                    : ''
                            }
                        >
                            {prop?.dataCompensacao ? (
                                <ConciliadoIcon />
                            ) : (
                                <NConciliadoIcon />
                            )}
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]

    const conteudo = (anchor) => (
        <MuiThemeProvider theme={theme}>
            <Card className={classes.card}>
                <Grid
                    container
                    item
                    md={12}
                    style={{ width: '100% !important' }}
                >
                    <Table
                        title={
                            props.categoria +
                            ' - Total no período: ' +
                            props?.periodoSelecionado
                        }
                        maxBodyHeight={'300px'}
                        removeToolbar={false}
                        draggable={false}
                        columns={colunas}
                        data={props.itemSelecionado}
                        paging={false}
                        haveParentChild={false}
                        emptyRowsWhenPaging={false}
                    />
                </Grid>
            </Card>
        </MuiThemeProvider>
    )

    return (
        <div>
            <Drawer
                anchor={'bottom'}
                open={props.stateMenuSuspenso}
                onClose={props.toggleDrawer('bottom', false)}
            >
                {conteudo('bottom')}
            </Drawer>
        </div>
    )
}
