import { ContaBancaria } from '../pages/contaBancaria';
import { ContaBancariaCadastro } from '../pages/contaBancaria/ContaBancariaCadastro';

export const contaBancariaRoute = [
    {
        path: '/app/contaBancaria',
        key: 'APP_CONTA_BANCARIA_LISTAGEM',
        exact: true,
        component: ContaBancaria,
    },
    {
        path: '/app/contaBancaria/cadastro',
        key: 'APP_CONTA_BANCARIA_CADASTRO',
        exact: true,
        component: ContaBancariaCadastro,
    },
    {
        path: '/app/contaBancaria/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CONTA_BANCARIA_EDITAR',
        exact: true,
        component: ContaBancariaCadastro,
    },
];
