import { SET_FILTRO_LISTAGEM_PEDIDOS } from '../actions/pedidos'
import { SET_ITENS_MODAL } from '../actions/pedidos'

export const storagePedidos = 'pedidos'

const initialState = {
    filtroListagem: {},
    itens: {},
}

export const pedidos = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_PEDIDOS:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        case SET_ITENS_MODAL:
            return {
                ...state,
                itens: action.itens,
            }
        default:
            return state
    }
}
