import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import FiltroSimples from 'components/BasicPageTopBar/FiltroSimples'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { Divider, Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '10px',
        marginBottom: '10px',
    },
}))

const Toolbar = ({ handleFiltro, filtroInicial, className, ...props }) => {
    const classes = useStyles()
    const [filtro, setFiltro] = useState(filtroInicial)
    const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded)

    const onKeyPress = (e) => {
        if (e.key === 'Enter') submit(e)
    }

    const setStateAcocordion = () => {
        let newFiltro = { ...filtro }
        newFiltro.filtroExpanded = !filtroExpanded
        setFiltro(newFiltro)
        setFiltroExpanded(!filtroExpanded)
    }

    const changeFiltro = (campo, event) => {
        let newFiltro = { ...filtro }
        newFiltro[campo] = event.target.value
        setFiltro(newFiltro)
    }

    const submit = (event) => {
        handleFiltro(filtro)
    }

    const { getValues, setValue, register } = useForm({
        defaultValues: {
            centroCustoId: "",
            contaGerencialId: "",
            dataPedidoAte: "",
            dataPedidoDe: "",
            descricao: "",
            fornecedorId: "",
            numeroPedido: "",
            previsaoEntregaAte: "",
            previsaoEntregaDe: "",
            produtoServicoId: "",
            valorTotalAte: "",
            valorTotalDe: ""
        }
    })

    const clearFiltros = () => {
        const {
            centroCustoId,
            contaGerencialId,
            dataPedidoAte,
            dataPedidoDe,
            descricao,
            filtro: filtroPesquisa,
            fornecedorId,
            numeroPedido,
            previsaoEntregaAte,
            previsaoEntregaDe,
            produtoServicoId,
            valorTotalAte,
            valorTotalDe,
            ...rest
        } = filtro

        setValue("centroCustoId", "")
        setValue("contaGerencialId", "")
        setValue("dataPedidoAte", "")
        setValue("dataPedidoDe", "")
        setValue("descricao", "")
        setValue("fornecedorId", "")
        setValue("numeroPedido", "")
        setValue("previsaoEntregaAte", "")
        setValue("previsaoEntregaDe", "")
        setValue("produtoServicoId", "")
        setValue("valorTotalAte", "")
        setValue("valorTotalDe", "")

        const newFiltro = {
            ...rest,
            filtro: ""
        }

        setFiltro(newFiltro)
        handleFiltro(newFiltro)
    }

    useEffect(() => {
        setFiltro(filtroInicial)
    }, [filtroInicial])

    return (
        <Box item mt={3}>
            <Accordion
                expanded={filtroExpanded}
                onChange={(e) => setStateAcocordion}
            >
                <AccordionSummary
                    expandIcon={
                        !props.temFiltroAvancado ? '' : <ExpandMoreIcon />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={() => setStateAcocordion(!filtroExpanded)}
                >
                    <FiltroSimples
                        filtro={filtro}
                        changeFiltro={changeFiltro}
                        submit={submit}
                        temFiltroAvancado={props.temFiltroAvancado}
                        clickadd={props.clickadd}
                    />
                </AccordionSummary>
                {props.temFiltroAvancado && (
                    <AccordionDetails>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label={'Data do Pedido Inicial:'}
                                            onChange={(e) => {
                                                setValue("dataPedidoDe", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataPedidoDe:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().dataPedidoDe}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("dataPedidoDe")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data do Pedido Final:"
                                            onChange={(e) => {
                                                setValue("dataPedidoAte", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataPedidoAte:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().dataPedidoAte}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("dataPedidoAte")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Número do Pedido"
                                            onChange={(e) => {
                                                setValue("numeroPedido", e.target.value || "")

                                                setFiltro({
                                                    ...filtro,
                                                    numeroPedido:
                                                        e.target.value,
                                                })
                                            }}
                                            onKeyDown={(e) => onKeyPress(e)}
                                            type="number"
                                            value={getValues().numeroPedido}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...register("numeroPedido")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.fornecedores || []}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            value={getValues().fornecedorId ? getValues().fornecedorId : ""}
                                            onChange={(event, option) => {
                                                setValue("fornecedorId", option)

                                                setFiltro({
                                                    ...filtro,
                                                    fornecedorId: option
                                                        ? option.value
                                                        : '',
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Fornecedor/Cliente'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("fornecedorId")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            size="small"
                                            options={props.centrosCusto || []}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            value={getValues().centroCustoId ? getValues().centroCustoId : ""}
                                            onChange={(e, newValue) => {
                                                setValue("centroCustoId", newValue)

                                                setFiltro({
                                                    ...filtro,
                                                    centroCustoId: newValue
                                                        ? newValue.value
                                                        : '',
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Centro de Custo"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("centroCustoId")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label={'Entrega Prevista Inicial:'}
                                            onChange={(e) => {
                                                setValue("previsaoEntregaDe", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    previsaoEntregaDe:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().previsaoEntregaDe}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("previsaoEntregaDe")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Entrega Prevista Final:"
                                            onChange={(e) => {
                                                setValue("previsaoEntregaAte", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    previsaoEntregaAte:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().previsaoEntregaAte}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                            {...register("previsaoEntregaAte")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            size="small"
                                            options={props.produtosServicos}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            onChange={(e, newValue) => {
                                                setValue("produtoServicoId", newValue)

                                                setFiltro({
                                                    ...filtro,
                                                    produtoServicoId: newValue
                                                        ? newValue.value
                                                        : '',
                                                })
                                            }}
                                            value={getValues().produtoServicoId ? getValues().produtoServicoId : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Produto/Serviço"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("produtoServicoId")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Descrição"
                                            onChange={(e) => {
                                                setValue("descricao", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    descricao: e.target.value,
                                                })
                                            }}
                                            value={getValues().descricao}
                                            onKeyDown={(e) => onKeyPress(e)}
                                            type="text"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...register("descricao")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            size="small"
                                            options={
                                                props.contasGerencial || []
                                            }
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            onChange={(e, newValue) => {
                                                setValue("contaGerencialId", newValue)

                                                setFiltro({
                                                    ...filtro,
                                                    contaGerencialId: newValue
                                                        ? newValue.value
                                                        : '',
                                                })
                                            }}
                                            value={getValues().contaGerencialId ? getValues().contaGerencialId : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Conta Gerencial"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("contaGerencialId")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Valor Total Inicial:"
                                            onChange={(e) => {
                                                setValue("valorTotalDe", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorTotalDe:
                                                        e.target.value,
                                                })
                                            }}
                                            value={getValues().valorTotalDe}
                                            onKeyDown={(e) => onKeyPress(e)}
                                            type="number"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...register("valorTotalDe")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Valor Total Final:"
                                            onChange={(e) => {
                                                setValue("valorTotalAte", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorTotalAte:
                                                        e.target.value,
                                                })
                                            }}
                                            type="number"
                                            value={getValues().valorTotalAte}
                                            onKeyDown={(e) => onKeyPress(e)}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...register("valorTotalAte")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} display="flex">
                                <Divider className={classes.divider} />

                                <Grid
                                    xs={12}
                                    container
                                    item
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    className={classes.margin}
                                >
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        onClick={clearFiltros}
                                    >
                                        Limpar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={(e) => submit(e)}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
            </Accordion>
        </Box>
    )
}

export default Toolbar
