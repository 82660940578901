import React, { useState, useEffect } from 'react'
import Page from 'components/Page'
import Titulo from 'components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import {
    Container,
    Card,
    Divider,
    CardContent,
    TextField,
    Button,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Parcelamento from './Parcelamento'
import NumberFormat from 'react-number-format'
import DataHelper from 'utils/dataHelpder'
import ModalProdutosServicos from './ModalProdutosServicos'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Request from './Request'
import ToastHelper from 'utils/toastHelper'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MaterialTable from 'material-table'
import ObjectHelper from 'utils/objetcHelper'
import { Edit3 as Edit3Icon } from 'react-feather'
import DateHelper from 'utils/dataHelpder'
import AsyncAutoComplete from '../../components/AsyncAutoComplete/index'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    error: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonModalProdutosServicos: {
        marginBottom: '-25px',
        zIndex: 9,
        marginRight: 10,
        marginTop: 10,
        float: 'right',
    },
}))

export const GestaoContratoCadastro = (props) => {
    const fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
    const history = useHistory();
    const [form, setForm] = useState({
        pedidoA: 'Cliente',
        dataPedido: DataHelper.today(),
    })
    const [produtos, setprodutos] = React.useState([])

    const {
        match: { params },
    } = props

    const [titulo, setTitulo] = useState('Cadastro de Contrato')
    const [openModalProdutosServicos, setOpenModalProdutosServicos] =
        React.useState(false)

    const handleOpenModalProdutosServicos = () => {
        setOpenModalProdutosServicos(true)
    }

    const isVisualizar = params.modo === "visualizar"

    const handleCloseModalProdutosServicos = async (produto) => {
        var arrayProdutos = [...produtos]
        arrayProdutos.push(produto)
        var total = 0
        arrayProdutos.forEach((item) => {
            if (typeof item.valorUnitario === 'string')
                item.valorUnitario = ObjectHelper.convertCurrencyStringToFloat(
                    item.valorUnitario
                )

            total = total + item.quantidade * item.valorUnitario
        })
        setTotal(total)
        setValorLiquido(total)
        setprodutos(arrayProdutos)
        setOpenModalProdutosServicos(false)
    }

    const validSubmitEditTableProdutosServicos = async (lista) => {
        let error = true
        await lista.forEach((item) => {
            if (
                item.produtoServico === null ||
                item.valorUnitario === (null || '' || 'R$' || '$') ||
                item.valorUnitario <= 0 ||
                item.quantidade <= 0 ||
                item.unidade === null
            ) {
                error = false
            }
        })

        return error
    }

    const [opcoespedidoA] = useState([
        {
            value: 'CLIENTE',
            label: 'Cliente',
        },
        {
            value: 'FORNECEDOR',
            label: 'Fornecedor',
        },
    ])

    const [opcoesFornecedoresClientes, setOpcoesFornecedoresClientes] =
        useState([])

    const [contaAPagar] = useState(params.tipo === 'pagar' ? true : false)
    const { register, handleSubmit } = useForm({ defaultValues: {} })
    const classes = useStyles()
    const [pedidoA] = useState()
    const [numeroParcelas, setNumeroParcelas] = useState()
    const [parcelas, setParcelas] = useState([])
    const [dataVencimento, setDataVencimento] = useState('')

    useEffect(() => {
        const {
            match: { params },
        } = props
        const init = async () => {
            if (params.id) {
                buscarPorId(params.id)
                if (params.modo === 'editar') {
                    setTitulo('Editar Contrato')
                } else if (params.modo === 'visualizar') {
                    setTitulo('Visualizar Contrato')
                }
            }

            await gerarNumero()
            await buscarFornecedoresClientes('Cliente')
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [total, setTotal] = React.useState(0)
    const [valorLiquido, setValorLiquido] = React.useState(0)
    const [acrescimo, setAcrescimo] = React.useState(0)
    const [descontos, setDescontos] = React.useState(0)

    const onChangeAcrescimos = (e) => {
        let acrescimo = parseFloat(
            e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
        )
        setAcrescimo(acrescimo)
        setForm({ ...form, acrescimo: e.target.value })
    }

    const changeValorLiquido = () => {
        var descontosConvertidos = descontos ? descontos : 0
        var acrescimosConvertidos = acrescimo ? acrescimo : 0
        var liquido = total + acrescimosConvertidos - descontosConvertidos
        setValorLiquido(liquido)
    }

    const onChangeDescontos = (e) => {
        let desconto = parseFloat(
            e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
        )
        setDescontos(desconto)
        setForm({ ...form, desconto: e.target.value })
    }

    const buscarPorId = async (id) => {
        let data = await Request.buscarConta(id)
        await buscarFornecedoresClientes(
            data.gestaoContrato.pedidoA.toLowerCase()
        )
        setForm(data.gestaoContrato)
        setprodutos(data.produtoServico)
        setParcelas(data.parcelas)
        setTotal(data.valorTotal)
        setValorLiquido(data.valorTotal)
        setAcrescimo(data.gestaoContrato.acrescimo);
        setDescontos(data.gestaoContrato.desconto);
    }

    const gerarNumero = async () => {
        let data = await Request.GerarNumero(fazendaId)
        setForm({ ...form, numeroPedido: data })
    }

    const buscarFornecedoresClientes = async (TipoFornecedorCliente) => {
        let data = await Request.buscarFornecedoresClientes(
            TipoFornecedorCliente
        )
        var dataOrdenada = await ObjectHelper.ordenaLista(data, 'label', false)
        setOpcoesFornecedoresClientes(dataOrdenada)
    }

    let [opcoesProdutosServicos, setOpcoesProdutosServicos] = React.useState([])
    let [opcoesCentroCusto, setOpcoesCentroCusto] = React.useState([])
    let [opcoesContaGerencial, setOpcoesContaGerencial] = React.useState([])

    useEffect(() => {
        buscarFornecedoresClientes(pedidoA)
        buscarProdutosSerivocs()
        buscarCentrosDeCusto()
        buscarContasGerenciais()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidoA])

    const mudaNumeroParcelas = (event) => {
        setNumeroParcelas(event)
    }

    useEffect(() => {
        let newValor = 0
        produtos.forEach((item) => {
            newValor += item.valorTotal
        })
        setTotal(newValor)
        setValorLiquido(
            newValor + (acrescimo ? acrescimo : 0) - (descontos ? descontos : 0)
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtos])

    useEffect(() => {
        parcelas.forEach((item) => {
            item.valorTotalNota = parseFloat(
                valorLiquido.toFixed(2)
            ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valorLiquido])

    const limpar = () => {
        setParcelas([])
    }

    const [error, setError] = React.useState([])

    const validarParcelasEProdutos = async () => {
        if (produtos.length === 0) {
            ToastHelper.error(
                'Necessário escolher pelo menos um produto ou serviço e adicionar uma forma de pagamento'
            )
            return false
        }

        if (parcelas.length === 0) {
            ToastHelper.error(
                'Necessário escolher pelo menos uma forma de pagamento'
            )
            return false
        }

        let valorParcelas = 0

        parcelas.forEach((item) => {
            valorParcelas += item.valor
        })

        if (valorParcelas !== valorLiquido) {
            ToastHelper.error(
                'O valor total das parcelas (' +
                    valorParcelas.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }) +
                    ') não confere com o valor liquido (' +
                    valorLiquido.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }) +
                    ') da nota'
            )
            return false
        }
        return true
    }

    const onSubmit = async (tipo) => {
        let valid = await validarParcelasEProdutos()
        if (!valid) return

        let dados = form
        dados.creditoDebito = contaAPagar ? 1 : 0
        dados.id = params.id
        dados.fazendaId = fazendaId
        dados.produtos = produtos
        dados.parcelas = parcelas
        dados.acrescimo = acrescimo
        dados.desconto = descontos
        dados.previsto = form.previsto ? form.previsto : false
        dados.valorLiquido = valorLiquido

        dados.produtos.forEach((item) => {
            if (typeof item.valorTotal === 'string') {
                item.valorTotal = Number(
                    // eslint-disable-next-line
                    item.valorTotal.replace(/[^0-9\.-]+/g, '')
                )
            }
            if (typeof item.valorTotal === 'string') {
                item.valorUnitario = Number(
                    // eslint-disable-next-line
                    item.valorUnitario.replace(/[^0-9\.-]+/g, '')
                )
            }
        })

        let data = Request.cadastrar(dados)
        data.then((res) => {
            if (params.id)
                ToastHelper.success('Contrato atualizado com sucesso!')
            else ToastHelper.success('Contrato registrado com sucesso!')
            if (tipo === 'gerar') {
                window.location.href = `/app/conta/receber/gerar/${res.data.id}`
            } else {
                window.location.href = '/app/gestaocontrato'
            }
        }).catch((e) => {
            const { data } = e.response
            if (e.response.status === 500)
                return ToastHelper.error(
                    e.response.data ||
                        'Ocorreu um erro, por favor acione o administrador do sistema.'
                )
            setError(data)
        })
    }

    const deletarProduto = async (param) => {
        new Promise((resolve, reject) => {
            setTimeout(async () => {
                var arryProdutos = []
                produtos.forEach((item) => {
                    if (item.id !== param.id) arryProdutos.push(item)
                })
                setprodutos(arryProdutos)
                resolve()
            }, 1000)
        })
    }

    const mudaDataVencimento = (novaData) => {
        if (novaData) setDataVencimento(novaData.target.value)
        else setDataVencimento('')
    }

    const buscarUnidades = async (unidadeAtual) => {
        let data = await Request.buscarUnidadesDeMedida(unidadeAtual)
        return data
    }

    const geraParcelas = (novasParcelas) => {
        setParcelas(novasParcelas)
    }

    const atualizaParcelas = (novasParcelas) => {
        let arrayParcelas = [...parcelas]
        var parcelasAtualizadas = []
        arrayParcelas.forEach((antigas) => {
            novasParcelas.forEach((novas) => {
                if (antigas.tableData.id === novas.tableData.id) {
                    antigas = novas
                }
            })
            parcelasAtualizadas.push(antigas)
        })
        setParcelas(parcelasAtualizadas)
    }

    const deletaParcela = (novasParcelas) => {
        let data = [...novasParcelas]
        setParcelas(data)
    }

    const colunas = [
        {
            title: 'Item',
            field: 'produtoServico.nome',
            editComponent: (prop) => (
                <div>
                    <Autocomplete
                        name="produtoServico"
                        id="produtoServico"
                        options={opcoesProdutosServicos}
                        className={classes.textField}
                        value={
                            prop.rowData.produtoServico
                                ? prop.rowData.produtoServico
                                : ''
                        }
                        defaultValue={
                            prop.rowData.produtoServico
                                ? prop.rowData.produtoServico
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => option.nome}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            newValue
                                ? (data.produtoServico = newValue)
                                : (data.produtoServico = null)
                            newValue
                                ? (data.produtoServicoId = newValue.id)
                                : (data.produtoServicoId = null)
                            if (!data.produtoServico?.produto) {
                                data.unidadeId = null
                                data.unidade = []
                                data.localEstoqueId = null
                                data.localEstoque = []
                            }
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={
                                    prop.rowData.produtoServico === null ||
                                    prop.rowData.produtoServico === ''
                                }
                                helperText={
                                    prop.rowData.produtoServico === null ||
                                    prop.rowData.produtoServico === ''
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                                name="produtoServico"
                                id="produtoServico"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Unidade',
            field: 'unidade.sigla',
            editComponent: (prop) => (
                <div className="width-100">
                    <AsyncAutoComplete
                        id="unidade"
                        name="unidade"
                        disabled={
                            prop.rowData.produtoServico?.produto === false
                        }
                        style={{ width: 150 }}
                        disableClearable
                        value={prop.rowData.unidade ? prop.rowData.unidade : ''}
                        className={classes.textField}
                        getOptionLabel={(option) => option.sigla}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.unidade = newValue
                            data.unidadeId = newValue.id
                            prop.onRowDataChange(data)
                        }}
                        defaultValue={
                            prop.rowData.unidade ? prop.rowData.unidade : ''
                        }
                        useCache
                        cacheHash={prop.rowData.produtoServico?.nome}
                        asyncLoadOptions={() =>
                            buscarUnidades(
                                prop.rowData.produtoServico?.unidadeMedidaId
                            )
                        }
                        error={
                            prop.rowData.produtoServico?.produto === true
                                ? !prop.rowData.unidadeId
                                : ''
                        }
                        helperText={
                            prop.rowData.produtoServico?.produto === true
                                ? !prop.rowData.unidadeId
                                    ? 'Campo Obrigatório'
                                    : ''
                                : ''
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="unidade"
                                id="unidade"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Quantidade',
            field: 'quantidade',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="text"
                        value={prop.rowData.quantidade}
                        error={prop.rowData.quantidade <= 0}
                        helperText={
                            prop.rowData.quantidade <= 0 ? 'Obrigatório' : ''
                        }
                        onChange={(item) => {
                            var data = { ...prop.rowData }
                            item.target
                                ? (data.quantidade = item.target.value)
                                : (data.quantidade = 0)

                            let novoValorTotal =
                                data.valorUnitario * data.quantidade
                            data.valorTotal = novoValorTotal

                            prop.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Unitário',
            field: 'valorUnitario',
            render: (rowData) =>
                rowData.valorUnitario.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
            editComponent: (prop) => (
                <div>
                    <NumberFormat
                        prefix={'R$ '}
                        style={{ width: 140 }}
                        value={prop.rowData.valorUnitario}
                        decimalSeparator={','}
                        decimalScale={2}
                        customInput={TextField}
                        error={prop.rowData.valorUnitario <= 0}
                        helperText={
                            prop.rowData.valorUnitario <= 0
                                ? 'Campo obrigatório'
                                : ''
                        }
                        fixedDecimalScale={2}
                        onChange={(item) => {
                            let novoValor = item.target.value

                            var data = { ...prop.rowData }
                            data.valorUnitario = parseFloat(
                                novoValor
                                    .replace('R$', '')
                                    .replace(' ', '')
                                    .replace(',', '.')
                            )
                            let novoValorTotal =
                                data.valorUnitario * data.quantidade
                            data.valorTotal = novoValorTotal
                            prop.onRowDataChange(data)
                        }}
                        id="valorUnitario"
                        name="valorUnitario"
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Total',
            field: 'valorTotal',
            editable: 'never',
            render: (rowData) =>
                rowData.valorTotal.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
        {
            title: 'Centro Custo',
            field: 'centroCusto.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="centroCusto"
                        id="centroCusto"
                        options={opcoesCentroCusto}
                        className={classes.textField}
                        disabled={
                            prop.rowData.localEstoqueId || prop.rowData.safraId
                        }
                        value={
                            prop.rowData.centroCusto
                                ? prop.rowData.centroCusto
                                : ''
                        }
                        defaultValue={
                            prop.rowData.centroCusto
                                ? prop.rowData.centroCusto
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => option.descricao}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.centroCusto = newValue
                            data.safra = ''
                            data.safraId = null
                            data.localEstoque = ''
                            data.localEstoqueId = null
                            newValue
                                ? (data.centroCustoId = newValue.id)
                                : (data.centroCustoId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="centroCusto"
                                id="centroCusto"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Conta Gerencial',
            field: 'contaGerencial.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="contaGerencial"
                        id="contaGerencial"
                        options={opcoesContaGerencial}
                        className={classes.textField}
                        value={
                            prop.rowData.contaGerencial
                                ? prop.rowData.contaGerencial
                                : ''
                        }
                        defaultValue={
                            prop.rowData.contaGerencial
                                ? prop.rowData.contaGerencial
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => option.descricao}
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.contaGerencial = newValue
                            newValue
                                ? (data.contaGerencialId = newValue.id)
                                : (data.contaGerencialId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="contaGerencial"
                                id="contaGerencial"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },

        {
            title: 'Previsão de Entrega',
            field: 'previsaoEntrega',
            editComponent: (props) => (
                <div className="width-100">
                    <TextField
                        type="date"
                        value={DateHelper.formataStringData(
                            props.rowData.previsaoEntrega
                        )}
                        error={props.rowData.previsaoEntrega === 'ed/ed/'}
                        helperText={
                            props.rowData.previsaoEntrega === 'ed/ed/'
                                ? 'Campo obrigatório'
                                : ''
                        }
                        onChange={(item) => {
                            let novoValor =
                                DateHelper.transformStringDataUsaToPtBr(
                                    item.target.value
                                )
                            var data = { ...props.rowData }
                            data.previsaoEntrega = novoValor
                            props.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            {
                                inputProps: {max: '9999-12-31'}
                            }
                        }
                    />
                </div>
            ),
        },
    ]

    const buscarProdutosSerivocs = async () => {
        let data = await Request.buscarProdutosServicos()
        setOpcoesProdutosServicos(data)
    }

    const buscarCentrosDeCusto = async () => {
        let data = await Request.buscarCentrosDeCusto()
        setOpcoesCentroCusto(data)
    }

    const buscarContasGerenciais = async () => {
        let data = await Request.buscarContasGerenciais()
        setOpcoesContaGerencial(data)
    }

    const submitEGerar = async () => {
        await onSubmit('gerar')
    }

    const dadosContratoAdiantamento = () => {
        let dados = form
        dados.creditoDebito = contaAPagar ? 1 : 0
        dados.id = params.id
        dados.fazendaId = fazendaId
        dados.produtos = produtos
        dados.parcelas = parcelas
        dados.acrescimo = acrescimo
        dados.desconto = descontos
        dados.previsto = form.previsto ? form.previsto : false
        dados.valorLiquido = valorLiquido

        dados.produtos.forEach((item) => {
            if (typeof item.valorTotal === 'string') {
                item.valorTotal = Number(
                    // eslint-disable-next-line
                    item.valorTotal.replace(/[^0-9\.-]+/g, '')
                )
            }
            if (typeof item.valorTotal === 'string') {
                item.valorUnitario = Number(
                    // eslint-disable-next-line
                    item.valorUnitario.replace(/[^0-9\.-]+/g, '')
                )
            }
        })
            return dados;
    }

   const onSubmitContratoEmAdiantamento = async (dados) => {
    let data = Request.cadastrar(dados)
    data.then((res) => {
        if (params.id)
            ToastHelper.success('Contrato atualizado com sucesso!')
        else ToastHelper.success('Contrato registrado com sucesso!')
    }).catch((e) => {
        const { data } = e.response
        if (e.response.status === 500)
            return ToastHelper.error(
                e.response.data ||
                    'Ocorreu um erro, por favor acione o administrador do sistema.'
            )
        setError(data)
    })
   }

    const submitEGerarAdiantamento = async () => {
        let valid = await validarParcelasEProdutos()
        if (!valid) return
        const dadosContrato = params.id ? null : dadosContratoAdiantamento();
        if (parcelas.length > 1) {
            ToastHelper.error(
                'Somente Contratos com uma parcela podem gerar Adiantamento a Receber'
            )
            return false
        }
        history.push({
            pathname:'/app/adiantamentoFornecedoresClientes/receber/cadastro',
            adiantamentoReceber:{...form, valorTotal:valorLiquido, dataVencimento:parcelas[0].dataVencimento },
            dadosContrato:dadosContrato,
            onSubmitContratoEmAdiantamento,
        });
    }

    return (
        <Page className={classes.root} title={titulo}>
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Nº do Contrato:"
                                            value={
                                                form.numeroPedido !== undefined
                                                    ? form.numeroPedido
                                                    : ''
                                            }
                                            disabled
                                            className={classes.textField}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data do Contrato:*"
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    dataPedido: e.target.value,
                                                })
                                                setError([])
                                            }}
                                            value={
                                                form.dataPedido
                                                    ? form.dataPedido
                                                    : ''
                                            }
                                            defaultValue={
                                                form.dataPedido
                                                    ? form.dataPedido
                                                    : ''
                                            }
                                            type="date"
                                            className={classes.textField}
                                            disabled={isVisualizar}
                                            inputRef={register}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                        />
                                        {error.find(
                                            (x) => x === 'dataPedido'
                                        ) && (
                                            <p className={classes.error}>
                                                Campo Obrigatório !
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={opcoespedidoA || []}
                                            disabled={isVisualizar}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            onChange={(event, value) => {
                                                if (value) {
                                                    buscarFornecedoresClientes(
                                                        value.value
                                                    )
                                                    setForm({
                                                        ...form,
                                                        pedidoA: value.label,
                                                        pedidoAValue: value,
                                                        fornecedorCliente:
                                                            undefined,
                                                        fornecedorClienteId:
                                                            undefined,
                                                    })
                                                } else {
                                                    setForm({
                                                        ...form,
                                                        pedidoA: null,
                                                        pedidoAValue: null,
                                                        fornecedorCliente:
                                                            undefined,
                                                        fornecedorClienteId:
                                                            undefined,
                                                    })
                                                }
                                            }}
                                            value={
                                                form.pedidoA
                                                    ? opcoespedidoA.find(
                                                          (x) =>
                                                              x.label ===
                                                              form.pedidoA
                                                      )
                                                    : ''
                                            }
                                            defaultValue={
                                                form.pedidoA
                                                    ? opcoespedidoA.find(
                                                          (x) =>
                                                              x.label ===
                                                              form.pedidoA
                                                      )
                                                    : ''
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Pedido feito a:*'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        {error.find((x) => x === 'pedidoA') && (
                                            <p className={classes.error}>
                                                Campo Obrigatório !
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="fornecedorCliente"
                                            disableClearable
                                            id="fornecedorCliente"
                                            inputRef={register}
                                            disabled={form.pedidoA == null || isVisualizar}
                                            options={
                                                opcoesFornecedoresClientes || []
                                            }
                                            value={
                                                form.fornecedorCliente
                                                    ? form.fornecedorCliente
                                                    : ''
                                            }
                                            defaultValue={
                                                form.fornecedorCliente
                                                    ? form.fornecedorCliente
                                                    : ''
                                            }
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            onChange={(event, newValue) => {
                                                setError([])
                                                setForm({
                                                    ...form,
                                                    fornecedorCliente: newValue,
                                                    fornecedorClienteId:
                                                        newValue.value,
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="fornecedorCliente"
                                                    id="fornecedorCliente"
                                                    value={
                                                        form.fornecedorCliente
                                                    }
                                                    variant="standard"
                                                    label={
                                                        form.pedidoA
                                                            ? form.pedidoA ===
                                                              'Fornecedor'
                                                                ? 'Fornecedor:*'
                                                                : 'Cliente:*'
                                                            : 'Cliente:*'
                                                    }
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        {error.find(
                                            (x) => x === 'fornecedorClienteId'
                                        ) && (
                                            <p className={classes.error}>
                                                Campo Obrigatório !
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlObs}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição:"
                                            className={classes.textField}
                                            inputRef={register}
                                            disabled={isVisualizar}
                                            id="descricao"
                                            name="descricao"
                                            value={form.descricao}
                                            onChange={(event) =>
                                                setForm({
                                                    ...form,
                                                    descricao:
                                                        event.target.value,
                                                })
                                            }
                                            multiline
                                            variant="outlined"
                                            rows={2}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {form.fornecedorCliente && (
                                <>
                                    <Divider className={classes.divider} />
                                    {
                                        !isVisualizar && (
                                            <Fab
                                                onClick={
                                                    handleOpenModalProdutosServicos
                                                }
                                                className={
                                                    classes.buttonModalProdutosServicos
                                                }
                                                color="primary"
                                                size="small"
                                                aria-label="add"
                                            >
                                                <AddIcon />
                                            </Fab>
                                        )
                                    }
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ display: 'block' }}
                                    >
                                        <MaterialTable
                                            title={'Produtos e Serviços'}
                                            columns={colunas}
                                            data={produtos}
                                            options={{
                                                pageSize: props.pageSize
                                                    ? props.pageSize
                                                    : 100,
                                                paging: false,
                                                rowStyle: {
                                                    fontSize: 14,
                                                },
                                                padding: 'dense',
                                                search: false,
                                                actionsColumnIndex: -1,
                                                emptyRowsWhenPaging: false,
                                                selection: props.options
                                                    ? props.options.selection
                                                    : false,
                                            }}
                                            isLoading={props.isLoading}
                                            editable={!isVisualizar && {
                                                onRowDelete: (item) =>
                                                    deletarProduto(item),
                                                onBulkUpdate: (changes) =>
                                                    new Promise(
                                                        async (
                                                            resolve,
                                                            reject
                                                        ) => {
                                                            let array = [
                                                                ...produtos,
                                                            ]

                                                            for (let [
                                                                // eslint-disable-next-line no-unused-vars
                                                                key,
                                                                value,
                                                            ] of Object.entries(
                                                                changes
                                                            )) {
                                                                const index =
                                                                    value
                                                                        .oldData
                                                                        .tableData
                                                                        .id
                                                                array[index] =
                                                                    value.newData
                                                            }

                                                            let valido =
                                                                await validSubmitEditTableProdutosServicos(
                                                                    array
                                                                )
                                                            if (valido) {
                                                                setprodutos(
                                                                    array
                                                                )
                                                                resolve()
                                                            } else {
                                                                reject()
                                                            }
                                                        }
                                                    ),
                                            }}
                                            icons={{
                                                Edit: Edit3Icon,
                                                Add: (props) => {
                                                    return (
                                                        <AddIcon
                                                            style={{
                                                                width: 30,
                                                                height: 30,
                                                                marginRight: 100,
                                                                backgroundColor:
                                                                    '#2e7d32',
                                                                color: '#fff',
                                                                borderRadius: 100,
                                                            }}
                                                        />
                                                    )
                                                },
                                            }}
                                            localization={{
                                                header: {
                                                    actions: 'Ações',
                                                },
                                                body: {
                                                    emptyDataSourceMessage:
                                                        'Nenhum registro para exibir',
                                                    addTooltip: 'Adicionar',
                                                    deleteTooltip: 'Excluir',
                                                    editTooltip:
                                                        'Edição rápida',
                                                    editRow: {
                                                        deleteText:
                                                            'Deseja excluir essa linha?',
                                                        cancelTooltip:
                                                            'Cancelar',
                                                        saveTooltip:
                                                            'Confirmar',
                                                    },
                                                },
                                            }}
                                        />
                                        {produtos.length > 0 && (
                                            <>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    display="flex"
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        display="flex"
                                                    >
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <TextField
                                                                label="Quantidade de itens"
                                                                value={
                                                                    produtos.length
                                                                }
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        display="flex"
                                                    >
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <TextField
                                                                label="Valor dos Produtos/Serviços"
                                                                value={total.toLocaleString(
                                                                    'pt-br',
                                                                    {
                                                                        style: 'currency',
                                                                        currency:
                                                                            'BRL',
                                                                    }
                                                                )}
                                                                disabled={true}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        display="flex"
                                                    >
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <NumberFormat
                                                                prefix="R$"
                                                                label="Acréscimos:"
                                                                disabled={isVisualizar}
                                                                value={
                                                                    acrescimo
                                                                }
                                                                thousandSeparator={
                                                                    '.'
                                                                }
                                                                decimalSeparator={
                                                                    ','
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    2
                                                                }
                                                                customInput={
                                                                    TextField
                                                                }
                                                                onChange={(e) =>
                                                                    onChangeAcrescimos(
                                                                        e
                                                                    )
                                                                }
                                                                inputRef={
                                                                    register
                                                                }
                                                                id="acrescimo"
                                                                name="acrescimo"
                                                                inputProps={{
                                                                    min: 0,
                                                                    style: {
                                                                        textAlign:
                                                                            'left',
                                                                    },
                                                                }}
                                                                isplaceholderanimated={
                                                                    (false,
                                                                    'top')
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onBlur={
                                                                    changeValorLiquido
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        display="flex"
                                                    >
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <NumberFormat
                                                                prefix="R$"
                                                                label="Descontos:"
                                                                disabled={isVisualizar}
                                                                value={
                                                                    descontos
                                                                }
                                                                thousandSeparator={
                                                                    '.'
                                                                }
                                                                decimalSeparator={
                                                                    ','
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    2
                                                                }
                                                                customInput={
                                                                    TextField
                                                                }
                                                                onChange={(e) =>
                                                                    onChangeDescontos(
                                                                        e
                                                                    )
                                                                }
                                                                inputRef={
                                                                    register
                                                                }
                                                                id="desconto"
                                                                name="desconto"
                                                                inputProps={{
                                                                    min: 0,
                                                                    style: {
                                                                        textAlign:
                                                                            'left',
                                                                    },
                                                                }}
                                                                isplaceholderanimated={
                                                                    (false,
                                                                    'top')
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onBlur={
                                                                    changeValorLiquido
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    display="flex"
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        display="flex"
                                                    >
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <TextField
                                                                label="Valor Líquido"
                                                                value={valorLiquido.toLocaleString(
                                                                    'pt-br',
                                                                    {
                                                                        style: 'currency',
                                                                        currency:
                                                                            'BRL',
                                                                    }
                                                                )}
                                                                disabled={true}
                                                                type="text"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </>
                            )}
                            {produtos.length > 0 && (
                                <>
                                    <Divider className={classes.divider} />
                                    <Grid container item xs={12} display="flex">
                                        <Parcelamento
                                            limpar={limpar}
                                            numeroParcelas={numeroParcelas}
                                            mudaNumeroParcelas={
                                                mudaNumeroParcelas
                                            }
                                            geraParcelas={geraParcelas}
                                            dataVencimento={dataVencimento}
                                            mudaDataVencimento={
                                                mudaDataVencimento
                                            }
                                            valorConta={valorLiquido}
                                            parcelas={parcelas}
                                            deletaParcela={deletaParcela}
                                            isVisualizar={isVisualizar}
                                            atualizaParcelas={atualizaParcelas}
                                        />
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <Grid
                        container
                        item
                        xs={12}
                        display="flex"
                        className={classes.card}
                    >
                        <Grid
                            xs={4}
                            container
                            item
                            direction="row"
                            alignItems="center"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => {
                                    window.location.href =
                                        '/app/gestaocontrato'
                                }}
                            >
                                Voltar
                            </Button>
                        </Grid>
                        {
                            !isVisualizar && (
                                <Grid
                                    xs={8}
                                    container
                                    item
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        size="small"
                                        onClick={onSubmit}
                                    >
                                        Confirmar
                                    </Button>
                                    <div className={classes.divider} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        size="small"
                                        onClick={submitEGerar}
                                    >
                                        Gerar Conta a Receber
                                    </Button>
                                    <div className={classes.divider} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        size="small"
                                        onClick={submitEGerarAdiantamento}
                                    >
                                        Gerar Adiantamento a Receber
                                    </Button>
                                </Grid>
                            )
                        }
                    </Grid>
                </form>
            </Container>
            <Modal
                className={classes.modal}
                open={openModalProdutosServicos}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={openModalProdutosServicos}>
                        <ModalProdutosServicos
                            dataPedido={form.dataPedido}
                            close={handleCloseModalProdutosServicos}
                            cancelar={() => setOpenModalProdutosServicos(false)}
                        />
                    </Fade>
                </div>
            </Modal>
        </Page>
    )
}
