import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/CentroCusto/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/CentroCusto${param}`);
    },
    BuscarParaCentroNegocio(param) {
        return service.get(`/CentroCusto/BuscarParaCentroNegocio${param}`);
    },
    cadastrar(param) {
        return service.post(`/CentroCusto`, param);
    },
    atualizar(param) {
        return service.put(`/CentroCusto`, param);
    },
    ativarDesativar(id) {
        return service.put(`/CentroCusto/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/CentroCusto/${id}`);
    },
    buscarCentrosCustoRateio(fazendaId, id) {
        return service.get(
            `/CentroCusto/BuscarCentrosCustoRateio/${fazendaId}/${id}`,
        );
    },
    BuscarCentrosCustoRateioPatrimonio(fazendaId) {
        return service.get(
            `/CentroCusto/BuscarCentrosCustoRateioPatrimonio/${fazendaId}`,
        );
    },
    cadastrarCentrosCustoRateio(params) {
        return service.post(
            `/CentroCusto/CadastrarCentrosCustoRateio`,
            params,
        );
    },
    buscarCentroCustoRateadosPorId(id) {
        return service.get(
            `/CentroCusto/BuscarCentroCustoRateadosPorId/${id}`,
        );
    },
    excluirCentrosCustoRateio(id) {
        return service.delete(
            `/CentroCusto/ExcluirCentrosCustoRateio/${id}`,
        );
    },
    excluirCentrosCustoSafra(id) {
        return service.delete(
            `/CentroCusto/ExcluirCentrosCustoSafra/${id}`,
        );
    },
    cadastrarCentroCustoSafra(params) {
        return service.post(
            `/CentroCusto/CadastrarCentroCustoSafra`,
            params,
        );
    },
    buscarCentroCustoSafrasPorId(id) {
        return service.get(
            `/CentroCusto/BuscarCentroCustoSafrasPorId/${id}`,
        );
    },
};
