import * as Yup from 'yup'

export const ExtratoBancarioEsquema = Yup.object().shape({
    periodo: Yup.object().required('Campo obrigatório').nullable(),
    contaBancaria: Yup.object().required('Campo obrigatório').nullable(),
    dataInicial: Yup.date()
        .when('periodo', {
            is: (val) => val && val.value === 4,
            then: Yup.date().required('Campo obrigatório'),
        })
        .typeError('Data inválida'),
    dataFinal: Yup.date()
        .when('periodo', {
            is: (val) => val && val.value === 4,
            then: Yup.date().required('Campo obrigatório'),
        })
        .typeError('Data inválida'),
})
