import axios from 'axios';

const api = axios.create({
    baseURL: '/api/tecnico',
})

api.interceptors.request.use(
    (config) => {
        const TOKEN_KEY = '@projeto-token'
        const token = localStorage.getItem(TOKEN_KEY)
        config.headers.Authorization = token;
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401) {
            const portalUrl = localStorage.getItem('@portal-url')
            localStorage.removeItem('@projeto-token')
            if (portalUrl) window.location.replace(portalUrl)
        }

        return Promise.reject(error)
    }
)

export default api
