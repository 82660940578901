import React from 'react'
import Table from '../../components/Table'
import VisualizarIcon from '@material-ui/icons/SearchOutlined'

export default function TransferenciaBancariaListagem(props) {
    const colunas = [
        { title: 'Data', field: 'data', editable: 'never' },
        {
            title: 'Conta de Débito',
            field: 'contaDebito.descricao',
            editable: 'never',
        },
        {
            title: 'Conta de Crédito',
            field: 'contaCredito.descricao',
            editable: 'never',
        },
        {
            title: 'Valor',
            field: 'valor',
            editable: 'never',
            render: (rowData) =>
                rowData.valor?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
        {
            title: 'Núm. Documento',
            field: 'numeroDocumento',
            editable: 'never',
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                isLoading={props.isLoading}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                pageSize={props.pageSize}
                emptyRowsWhenPaging={false}
                editarCelula={props.editarCelula ? props.editarCelula : false}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                onChangePage={(page) => props.onChangePage(page)}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                }}
                actions={[
                    {
                        icon: VisualizarIcon,
                        tooltip: 'Visualizar',
                        onClick: (event, rowData) =>
                            props.visualizar(rowData.id),
                    },
                ]}
            />
        </>
    )
}
