import React, { useState, useEffect } from 'react'
import { Button, TextField, Divider, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { useForm } from 'react-hook-form'
import FormaPagamentoService from 'services/TipoPagamentoRecebimentoService'
import ContaBancariaService from 'services/ContaBancariaService'
import AdiantamentoService from 'services/AdiantamentoFornecedoresClientesService'
import ToastHelper from 'utils/toastHelper'
import ObjectHelper from 'utils/objetcHelper'
import DateHelper from 'utils/dataHelpder'
import CamposDinamicosService from 'services/CamposParcelaService';
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 100,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    valorTotal: {
        display: 'inline-flex !important',
    },
    hidden: {
        display: 'none',
    },
}))

export default function ModalQuitarContas(props) {


    const classes = useStyles()
    const [FazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : null)

    const [conciliando, setConciliando] = React.useState(false);

    const [opcoesFormaPagamento, setOpcoesFormaPagamento] = useState([])
    const [opcoesConta, setOpcoesConta] = useState([])

    const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState()
    const [contaBancariaSelecionada, setContaBancariaSelecionada] = useState()

    const [dataPagamento, setDataPagamento] = useState(null)
    const [dataCompensacao, setDataCompensacao] = useState(null)
    const [valor, setValor] = useState()
    const [valorTotal, setValorTotal] = useState()
    const [observacao, setObservacao] = useState()
    const [numeroDocumento, setNumeroDocumento] = useState()
    const [formErrors, setFormErrors] = useState({});

    const [camposDinamicos, setCamposDinamicos] = useState([]);

    const { handleSubmit } = useForm({})

    const validaCamposQuitacao = () => {
    
        let errors = {...formErrors}
        var isValid = true;

        if(!dataPagamento) {
            errors = ({...errors, dataPagamento: {message: 'Campo Obrigatório.'}})
            isValid = false;
        }

        if(dataPagamento > DateHelper.today()) {
            errors = ({...formErrors, dataPagamento: {message: 'A data de pagamento não pode ser maior que a data atual. '}})
            isValid = false;
        }

        if(dataCompensacao > DateHelper.today()) {
            errors = ({...formErrors, dataCompensacao: {message: 'A data de compensação não pode ser maior que a data atual. '}})
            isValid = false;
        }


        if(!!dataCompensacao && dataCompensacao < dataPagamento) {
            isValid = false;
            errors = ({...errors, dataCompensacao: {message: 'Data de compensação deve ser maior que a data de pagamento.'}})
        }

        if(!formaPagamentoSelecionada.value || !contaBancariaSelecionada.value || !dataPagamento){
            isValid = false;
            if(!formaPagamentoSelecionada.value)
                errors = ({...errors, formaPagamento: {message: 'Campo Obrigatório.'}})

            if(!contaBancariaSelecionada.value)
                errors = ({...errors, contaBancaria: {message: 'Campo Obrigatório.'}})
                
        }

        setFormErrors(errors);
        return isValid;
    }

    const validaCamposConciliacao = () => {
        
        var isValid = true;
        var dataPagamentoFormatted = DateHelper.formatStringDataBackTextFieldData(dataPagamento)

        if(dataCompensacao > DateHelper.today()) {
            setFormErrors({...formErrors, dataCompensacao: {message: 'A data de compensação não pode ser maior que a data atual. '}})
            isValid = false;
        }

        if(!dataCompensacao) {
            setFormErrors({...formErrors, dataCompensacao: {message: 'Campo Obrigatório.'}})
            isValid = false;
        }
        if(dataCompensacao < dataPagamentoFormatted) {
            setFormErrors({...formErrors, dataCompensacao: {message: 'Data de compensação deve ser maior que a data de pagamento.'}})
            isValid = false;
        }

        return isValid;
    }

    const submit = async () => {
        if (conciliando) {
            if (validaCamposConciliacao()) {
                let params = {
                    dataCompensacao: dataCompensacao,
                    id: props.adiantamento.id,
                }
                AdiantamentoService.atualizarDataCompensacao(params).then(
                    (res) => {
                        if (res.status === 200) {
                            props.closeModalQuitacao()
                            props.buscarTudo()
                            return ToastHelper.success('Adiantamento Conciliado.')
                        }
                    }
                )
            }
        } else {
            if (validaCamposQuitacao()) {

                let valoresCamposDinamicos = [];
                camposDinamicos.forEach((item) => {
                    valoresCamposDinamicos.push({
                        id: item.id,
                        Valor: item.valor
                    })
                })

                let quitacao = {
                    id: props.adiantamento.id,
                    dataPagamento: dataPagamento,
                    dataCompensacao: dataCompensacao,
                    valorPago: valorTotal,
                    contaBancariaId: contaBancariaSelecionada.value,
                    observacao: observacao,
                    numeroDocumento: numeroDocumento,
                    formaPagamentoId: formaPagamentoSelecionada.value,
                    camposDinamicos: valoresCamposDinamicos,
                }

                let result = await AdiantamentoService.quitarAdiantamento(quitacao);
                if(result?.status === 200) {
                    props.buscarTudo();
                    props.closeModalQuitacao()
                    return ToastHelper.success('Adiantamento quitado.')
                }else{
                    props.buscarTudo();
                    props.closeModalQuitacao()
                    return ToastHelper.error('Erro ao quitar adiantamento.')
                }
            }
        }
    }

    const buscarFormasPagamento = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId;
        filtro.ativo = true;
        filtro.tamanhoPagina = 999;
        let params = await ObjectHelper.createParams(filtro)
        await FormaPagamentoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setOpcoesFormaPagamento(
                    ObjectHelper.getValuesFormaPagamentoParseToSelect(
                        response.data.itens.filter((item) => item.codigo !== 'AD')
                    )
                )
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarContasBancaria = async () => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = FazendaId
        filtroParaPesquisa.ativo = true
        filtroParaPesquisa.tamanhoPagina = 9999;
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        await ContaBancariaService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setOpcoesConta(
                    ObjectHelper.getValuesDescricaoLabelParseToSelect(
                        response.data.itens
                    )
                )
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarCamposDinamicos = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId
        filtro.ativo = true
        filtro.tamanhoPagina = 999;
        filtro.AplicadoAdiantamentosPagarReceber = props.adiantamento.creditoDebito === 0 ? 'receber' : 'pagar';

        let params = await ObjectHelper.createParams(filtro)
        await CamposDinamicosService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setCamposDinamicos(response.data.itens)
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const calcularCamposDinamicos = (campos) => {

        let camposDinamicosAcomulado = 0.00;
        camposDinamicos.forEach(c => {
            if(c.tipo === 'Acréscimo' && c.valor){
                camposDinamicosAcomulado += c.valor;
            }else if(c.tipo === "Desconto" && c.valor){
                camposDinamicosAcomulado -= c.valor;
            }
        })
        
        setValorTotal(valor + camposDinamicosAcomulado)

    }


    useEffect(() => {

        if(!props.adiantamento?.camposDinamicos?.length) {
            buscarCamposDinamicos();
        }

        buscarFormasPagamento();
        buscarContasBancaria();
        setConciliando(!!props.adiantamento.dataPagamento && !props.adiantamento.dataCompensacao)

        if(!!props.adiantamento.dataPagamento && !props.adiantamento.dataCompensacao) 
        {   
            setDataPagamento(props.adiantamento?.dataPagamento);
        }else{
            setDataPagamento(DateHelper.formatStringDataBackTextFieldData(props.adiantamento?.dataPagamento));
        }

        setCamposDinamicos(props.adiantamento.camposDinamicos)
        setDataCompensacao(DateHelper.formatStringDataBackTextFieldData(props.adiantamento?.dataCompensacao));
        setValor(props.adiantamento.valor);
        setValorTotal(props.adiantamento.valorPago ?? props.adiantamento.valor)
        setObservacao(props.adiantamento?.observacao);
        setFormaPagamentoSelecionada({
            label: props.adiantamento?.formaPagamento?.nome, 
            value: props.adiantamento?.formaPagamento?.id
        })
        setContaBancariaSelecionada({
            label: props.adiantamento?.contaBancaria?.descricao, 
            value: props.adiantamento?.contaBancaria?.id
        })
        setNumeroDocumento(props.adiantamento?.numeroDocumento)
    // eslint-disable-next-line
    }, [props.showModalQuitarContas])

    return (
        <>
            <div className={classes.paper}>
                <h2 className={classes.title}>
                    {conciliando ? 'Conciliação' : 'Pagamento de Adiantamentos'}
                </h2>

                <form className={classes.form} onSubmit={handleSubmit(submit)}>
                    <Divider className={classes.divider} />
                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    disabled={conciliando}
                                    label="Data de Pagamento:*"
                                    name="dataPagamento"
                                    type={(conciliando) ? 'text' : 'date'}
                                    value={dataPagamento}
                                    onChange={(e) => {
                                        setDataPagamento(e.target.value)
                                    }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={
                                        {
                                            inputProps: {max: '9999-12-31'}
                                        }
                                    }
                                />
                                <p className={classes.errors}>
                                    {formErrors?.dataPagamento?.message}
                                </p>
                            </FormControl>
                        </Grid>

                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    label={
                                        'Data de Compensação:'
                                    }
                                    name="dataCompensacao"
                                    type="date"
                                    value={dataCompensacao}
                                    onChange={(e) => {
                                        setDataCompensacao(e.target.value)
                                    }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={
                                        {
                                            inputProps: {max: '9999-12-31'}
                                        }
                                    }
                                />
                                <p className={classes.errors}>
                                    {formErrors?.dataCompensacao?.message}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid
                            className={classes.valorTotal}
                            container
                            item
                            xs={3}
                            style={{ marginRight: '2%', marginTop: '2%' }}
                        >
                            <FormControl
                                className={classes.formControl}
                                display="inline-flex"
                            >
                                <div
                                    className={classes.valorTotal}
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {'(=) Valor do documento: '}
                                    </h4>
                                    <span>
                                        {ObjectHelper.convertFloatToCurrency(valor)}
                                    </span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    {camposDinamicos.length > 0 && (
                        <>
                            <Grid container item xs={12} display="flex">
                                {camposDinamicos.map((item) => (
                                    <Grid
                                        container
                                        item
                                        xs={3}
                                        display="flex"
                                    >
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                             <NumberFormat
                                                prefix={'R$ '}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                customInput={TextField}
                                                inputProps={{
                                                    min: 0,
                                                    style: { textAlign: 'right' },
                                                }}
                                                isplaceholderanimated={(false, 'top')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={item.valor}
                                                disabled={conciliando}
                                                name={
                                                    (item.tipoCodigo === 0
                                                        ? '(-)'
                                                        : '(+)') +
                                                    item.nome.toLowerCase()
                                                }
                                                id={
                                                    (item.tipoCodigo === 0
                                                        ? '(-)'
                                                        : '(+)') +
                                                    item.nome.toLowerCase()
                                                }
                                                label={
                                                    (item.tipoCodigo === 0
                                                        ? '(-)'
                                                        : '(+)') +
                                                    item.nome.toLowerCase()
                                                }
                                                onValueChange={(e) => {
                                                    let campoIndex = camposDinamicos.findIndex(x => x.nome === item.nome);
                                                    let newCamposDinamicos = camposDinamicos
                                                    newCamposDinamicos[campoIndex].valor = e.floatValue; 
                                                    setCamposDinamicos(newCamposDinamicos);
                                                    calcularCamposDinamicos(newCamposDinamicos)
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    <Grid
                        className={classes.formControl}
                        container
                        item
                        xs={12}
                        display="inline-flex"
                    >
                        <Grid
                            className={classes.valorTotal}
                            container
                            item
                            xs={3}
                            style={{ marginRight: '2%' }}
                        >
                            <FormControl
                                className={classes.formControl}
                                display="inline-flex"
                            >
                                <div
                                    className={classes.valorTotal}
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {'(=) Valor Total: '}
                                    </h4>
                                    <span>
                                        {ObjectHelper.convertFloatToCurrency(valorTotal)}
                                    </span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />

                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    disabled={conciliando}
                                    options={opcoesFormaPagamento}
                                    disableClearable
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, option) => {
                                        setFormaPagamentoSelecionada(option)
                                    }}
                                    value={formaPagamentoSelecionada || {}}
                                    defaultValue={
                                        formaPagamentoSelecionada
                                            ? formaPagamentoSelecionada
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Forma de Pagamento:*'}
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {formErrors?.formaPagamento?.message}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    disabled={conciliando}
                                    options={opcoesConta}
                                    disableClearable
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, option) => {
                                        setContaBancariaSelecionada(option)
                                    }}
                                    value={contaBancariaSelecionada || {}}
                                    defaultValue={
                                        contaBancariaSelecionada ? contaBancariaSelecionada : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Conta:*'}
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {formErrors?.contaBancaria?.message}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    disabled={conciliando}
                                    type="number"
                                    name="numeroDocumento"
                                    id="numeroDocumento"
                                    variant="standard"
                                    autoComplete="off"
                                    label={
                                        formaPagamentoSelecionada &&
                                        formaPagamentoSelecionada.label ===
                                            'Cheque'
                                            ? 'Nº do cheque:'
                                            : 'Nº do documento:'
                                    }
                                    value={numeroDocumento}
                                    onChange={(e) =>
                                        setNumeroDocumento(e.target.value)
                                    }
                                    placeholder="Digite o numero"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <p className={classes.errors}>
                                    {formErrors?.numeroDocumento?.message}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={12} display="flex">
                            <FormControl
                                className={classes.formControlTextArea}
                            >
                                <TextField
                                    disabled={conciliando}
                                    type="text"
                                    label="Observações"
                                    className={classes.textField}
                                    id="observacao"
                                    value={observacao}
                                    defaultValue={observacao}
                                    onChange={(e) =>
                                        setObservacao(e.target.value)
                                    }
                                    name="observacao"
                                    multiline
                                    variant="outlined"
                                    rows={2}
                                    isplaceholderanimated={(false, 'top')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.closeModalQuitacao()
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="secundary"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            {conciliando ? 'Conciliar' : 'Cadastrar'}
                        </Button>
                    </Grid>
                </form>
            </div>
        </>
    )
}
