import React from 'react'
import Table from '../../components/Table'
import {
    TextField,
    Divider,
    Button,
    Grid,
    Card,
    CardContent,
    FormControl,
    makeStyles,
    Box,
    Container,
} from '@material-ui/core'
import Page from 'components/Page'
import Titulo from 'components/Titulo/index'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Request from './Request'
import ToastHelper from 'utils/toastHelper'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import ContaPagarReceberService from 'services/ContaPagarReceberService'
import ConteudoModalQuitarContas from './ModalQuitarContas'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import DateHelper from '../../utils/dataHelpder'
import MoneyOnIcon from '@material-ui/icons/AttachMoney'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import ConteudoModalCancelarPagamento from './ModalCancelarPagamento'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    errors: {
        color: 'red',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '10px',
        marginBottom: '10px',
    },
}))

export default function ConciliacaoBancariaListagem(props) {
    const classes = useStyles()
    const colunas = [
        { title: 'Data Pagamento', field: 'dataPagamento', editable: 'never' },
        { title: 'Documento', field: 'documento', editable: 'never' },
        { title: 'Parcela', field: 'parcela', editable: 'never' },
        {
            title: 'Pagamento A',
            field: 'nomeFantasia',
            editable: 'never',
        },
        { title: 'Descrição', field: 'descricao', editable: 'never' },
        {
            title: 'Data da Compensação',
            field: 'dataCompensacao',
            render: (rowData) =>
                `${
                    rowData.dataCompensacao
                        ? rowData.dataCompensacao
                        : 'Não compensado!'
                }`,
            editComponent: (props) => {
                return (
                    <TextField
                        id="dataCompensacao"
                        name="dataCompensacao"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            {
                                inputProps: {max: '9999-12-31'}
                            }
                        }
                        value={DateHelper.formataStringData(
                            props.rowData.dataVencimento
                        )}
                        onChange={(e) => {
                            let novoValor =
                                DateHelper.transformStringDataUsaToPtBr(
                                    e.target.value
                                )
                            var data = { ...props.rowData }
                            data.dataCompensacao = novoValor
                        }}
                    />
                )
            },
        },
        {
            title: 'Valor',
            field: 'valor',
            editable: 'never',
            render: (rowData) =>
                rowData?.creditoDebito === 0 ? (
                    <p style={{ color: 'blue' }}>{rowData?.valor}</p>
                ) : (
                    <p style={{ color: 'red' }}>{rowData?.valor}</p>
                ),
        },
        {
            title: 'Saldo Acumulado',
            field: 'saldoAcumulado',
            editable: 'never',
            render: (rowData) =>
                rowData?.saldoAcumulado > 0 ? (
                    <p style={{ color: 'blue' }}>
                        {rowData?.saldoAcumulado?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </p>
                ) : (
                    <p style={{ color: 'red' }}>
                        {rowData?.saldoAcumulado?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </p>
                ),
        },
    ]

    const [contaBancaria, setcontaBancaria] = React.useState({})
    const [showModalQuitarContas, setShowModalQuitarContas] =
        React.useState(false)
    const [showModalConfirmaCancelamento, setShowModalConfirmaCancelamento] =
        React.useState(false)
    const [conciliacao, setConciliacao] = React.useState()
    const [contasSelecionadas, setContasSelecionadas] = React.useState([])
    const [conciliacaoBancaria, setconciliacaoBancaria] = React.useState({
        itens: [],
        totalItens: 0,
    })

    const [carregado, setCarregado] = React.useState(false)

    const buscarContasBancarias = async () => {
        let data = await Request.buscarContasBancarias()
        setcontaBancaria(data)
    }

    React.useEffect(() => {
        buscarContasBancarias()
    }, [])

    const [form, setform] = React.useState({
        pagina: 1,
    })

    const [errors, seterrors] = React.useState([])

    const validate = () => {
        var objParams = [
            'dataInicio',
            'dataFim',
            'contaBancariaId',
            'situacao',
            'saldoDoExtrato',
        ]
        var keyNames = Object.keys(form)
        var valid = objParams.filter((e) => keyNames.indexOf(e) === -1)
        if (valid.length > 0) {
            seterrors(valid)
            return false
        } else {
            return true
        }
    }

    const submit = async (params) => {
        if (validate()) {
            var filtro = params
            filtro.tamanhoPagina = 100
            if (!filtro.ordem) {
                filtro.ordem = 'DataPagamento'
                filtro.decrescente = false
            }
            setform(filtro)
            let data = await Request.buscarConciliacoesBancarias(filtro)
            setconciliacaoBancaria(data)
            setCarregado(true)
        }
    }

    const [situacao] = React.useState([
        { label: 'Conciliados', codigo: 'CONCILIADOS' },
        { label: 'Não Conciliados', codigo: 'NAO_CONCILIADOS' },
        { label: 'Todos', codigo: 'TODOS' },
    ])

    const onChangePage = async (page) => {
        setform({ ...form, pagina: page })
        var filtro = { ...form }
        filtro.pagina = page
        await submit(filtro)
    }

    const editarCelula = async (newValue, oldValue, rowData, columnDef) => {
        var nValue = moment(newValue, 'DD/MM/YYYY').format('YYYY-MM-DD')
        var dataAtual = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        if (nValue > dataAtual) {
            ToastHelper.error(
                'Data de compensação não pode ser maior que data atual!'
            )
        } else {
            await ContaPagarReceberService.atualizarDataCompensacao([
                {
                    id: rowData.id,
                    dataCompensacao: moment(nValue, 'DD/MM/YYYY').format(
                        'YYYY/MM/DD'
                    ),
                },
            ])
                .then((response) => {
                    if (response.status === 200) {
                        nValue = moment(nValue, 'DD/MM/YYYY')
                    }
                })
                .catch((error) => {
                    ToastHelper.error(
                        error.response.data
                            ? error.response.data.message
                            : 'Ocorreu um erro!'
                    )
                    nValue = moment(nValue, 'DD/MM/YYYY')
                })
        }
    }

    const mudaOrdenacao = async (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...form }
        novoFiltro.decrescente = !novoFiltro.decrescente
        if (colunas[numeroColuna]?.field === 'documento') {
            novoFiltro.ordem = 'DespesaReceita.Documento'
        } else if (colunas[numeroColuna]?.field === 'pagamentoA') {
            novoFiltro.ordem = 'DespesaReceita.FornecedorCliente.NomeFantasia'
        } else {
            novoFiltro.ordem = colunas[numeroColuna]?.field
        }

        await submit(novoFiltro)
    }

    const fechaModal = () => {
        setShowModalQuitarContas(false)
        setConciliacao(undefined)
    }

    const quitarConta = (row) => {
        if (row.dataPagamento > 0) {
            ToastHelper.error('Conta selecionada já foi paga.')
        } else {
            setContasSelecionadas([row])
            setShowModalQuitarContas(true)
        }
    }

    const cancelarPagamento = (row) => {
        if (row.dataPagamento > 0) {
            ToastHelper.error('Conta selecionada não foi paga.')
        } else if (row.dataPagamento > 0) {
            ToastHelper.error(
                'Não é possivel cancelar o pagamento de uma conta conciliada.'
            )
        } else {
            setShowModalConfirmaCancelamento(true)
            setContasSelecionadas([row])
        }
    }

    return (
        <>
            <Page className={classes.root} title="Conciliação Bancária">
                <Container maxWidth={'xl'}>
                    <br></br>
                    <Titulo titulo={'Conciliação Bancária'} />
                    <br></br>
                    <Card className={classes.card}>
                        <CardContent>
                            <Box item mt={3}>
                                <Grid container item xs={12} display="flex">
                                    <Grid container item xs={12} display="flex">
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Autocomplete
                                                    options={situacao || []}
                                                    getOptionLabel={(option) =>
                                                        option.label
                                                    }
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        setform({
                                                            ...form,
                                                            situacao: value,
                                                        })
                                                        seterrors(
                                                            errors.filter(
                                                                function (
                                                                    value
                                                                ) {
                                                                    return (
                                                                        value !==
                                                                        'situacao'
                                                                    )
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    value={
                                                        form.situacao
                                                            ? form.situacao
                                                            : ''
                                                    }
                                                    defaultValue={
                                                        form.situacao
                                                            ? form.situacao
                                                            : ''
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label={'Situação:*'}
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.find(
                                                    (x) => x === 'situacao'
                                                ) && (
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        Campo Obrigatório !
                                                    </p>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <TextField
                                                    label={
                                                        form.situacao?.label ===
                                                        'Conciliados'
                                                            ? 'Data de Compensação Inicial*: '
                                                            : 'Data de Pagamento Inicial*: '
                                                    }
                                                    onChange={(e) => {
                                                        setform({
                                                            ...form,
                                                            dataInicio:
                                                                e.target.value,
                                                        })
                                                        seterrors(
                                                            errors.filter(
                                                                function (
                                                                    value
                                                                ) {
                                                                    return (
                                                                        value !==
                                                                        'dataInicio'
                                                                    )
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    type="date"
                                                    className={
                                                        classes.textField
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={
                                                        {
                                                            inputProps: {max: '9999-12-31'}
                                                        }
                                                    }
                                                />
                                                {errors.find(
                                                    (x) => x === 'dataInicio'
                                                ) && (
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        Campo Obrigatório !
                                                    </p>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <TextField
                                                    label={
                                                        form.situacao?.label ===
                                                        'Conciliados'
                                                            ? 'Data de Compensação Final*: '
                                                            : 'Data de Pagamento Final*: '
                                                    }
                                                    onChange={(e) => {
                                                        setform({
                                                            ...form,
                                                            dataFim:
                                                                e.target.value,
                                                        })
                                                        seterrors(
                                                            errors.filter(
                                                                function (
                                                                    value
                                                                ) {
                                                                    return (
                                                                        value !==
                                                                        'dataFim'
                                                                    )
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    type="date"
                                                    className={
                                                        classes.textField
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={
                                                        {
                                                            inputProps: {max: '9999-12-31'}
                                                        }
                                                    }
                                                />
                                                {errors.find(
                                                    (x) => x === 'dataFim'
                                                ) && (
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        Campo Obrigatório !
                                                    </p>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Autocomplete
                                                    options={
                                                        contaBancaria || []
                                                    }
                                                    disableClearable
                                                    getOptionLabel={(option) =>
                                                        option.descricao
                                                    }
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        setform({
                                                            ...form,
                                                            contaBancariaId:
                                                                value.id,
                                                            contaBancaria:
                                                                value,
                                                        })
                                                        seterrors(
                                                            errors.filter(
                                                                function (
                                                                    value
                                                                ) {
                                                                    return (
                                                                        value !==
                                                                        'contaBancariaId'
                                                                    )
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    value={
                                                        form.contaBancariaId
                                                            ? form.contaBancaria
                                                            : ''
                                                    }
                                                    defaultValue={
                                                        form.contaBancariaId
                                                            ? form.contaBancaria
                                                            : ''
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label={
                                                                'Conta Bancária:*'
                                                            }
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.find(
                                                    (x) =>
                                                        x === 'contaBancariaId'
                                                ) && (
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        Campo Obrigatório !
                                                    </p>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <NumberFormat
                                                    value={form.saldoDoExtrato}
                                                    label="Saldo do Extrato:*"
                                                    prefix={'R$ '}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    decimalScale={2}
                                                    placeholder="R$ 0,00"
                                                    customInput={TextField}
                                                    inputProps={{
                                                        min: 0,
                                                        style: {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => {
                                                        setform({
                                                            ...form,
                                                            saldoDoExtrato:
                                                                e.target.value,
                                                        })
                                                        seterrors(
                                                            errors.filter(
                                                                function (
                                                                    value
                                                                ) {
                                                                    return (
                                                                        value !==
                                                                        'saldoDoExtrato'
                                                                    )
                                                                }
                                                            )
                                                        )
                                                    }}
                                                />
                                                {errors.find(
                                                    (x) =>
                                                        x === 'saldoDoExtrato'
                                                ) && (
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        Campo Obrigatório !
                                                    </p>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider className={classes.divider} />
                            <Grid
                                xs={12}
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.margin}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => submit(form)}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                    {carregado && (
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid
                                    container
                                    justify="space-between"
                                    spacing={24}
                                    display="flex"
                                >
                                    <Grid
                                        container
                                        item
                                        xs={9}
                                        display="flex"
                                    ></Grid>
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                type="text"
                                                disabled
                                                step="any"
                                                value={
                                                    conciliacaoBancaria.saldoAnterior
                                                }
                                                label="Saldo Anterior:*"
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'right',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Table
                                    title={'Entrada/Saída'}
                                    columns={colunas}
                                    filtro={form}
                                    data={conciliacaoBancaria.itens}
                                    onChangePage={(page) => onChangePage(page)}
                                    page={form.pagina}
                                    emptyRowsWhenPaging={false}
                                    totalCount={conciliacaoBancaria.totalItens}
                                    pageSize={100}
                                    onOrderChange={(numeroColuna, orientacao) =>
                                        mudaOrdenacao(
                                            numeroColuna,
                                            orientacao,
                                            colunas
                                        )
                                    }
                                    editarCelula={editarCelula}
                                    actions={[
                                        (rowData) => ({
                                            icon:
                                                rowData.dataPagamento ===
                                                (null || '')
                                                    ? MoneyOnIcon
                                                    : MoneyOffIcon,
                                            tooltip:
                                                rowData.dataPagamento ===
                                                (null || '')
                                                    ? 'Pagar conta(s)'
                                                    : 'Cancelar pagamento(s)',
                                            onClick: (event, data) =>
                                                rowData.dataPagamento ===
                                                (null || '')
                                                    ? quitarConta(data)
                                                    : cancelarPagamento(data),
                                        }),
                                    ]}
                                />
                                <Grid container item xs={12} display="flex">
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                type="text"
                                                disabled
                                                step="any"
                                                value={
                                                    conciliacaoBancaria.saldoFinal
                                                }
                                                label="Saldo Final:*"
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'right',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                type="text"
                                                disabled
                                                step="any"
                                                value={
                                                    conciliacaoBancaria.saldoInformado
                                                }
                                                label="Saldo do Extrato Informado:*"
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'right',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                type="text"
                                                disabled
                                                step="any"
                                                value={
                                                    conciliacaoBancaria.diferenca
                                                }
                                                label="Diferença:*"
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'right',
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}

                    <Modal
                        className={classes.modal}
                        open={showModalQuitarContas}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <div className={classes.paper}>
                            <Fade in={showModalQuitarContas}>
                                <ConteudoModalQuitarContas
                                    close={() => fechaModal()}
                                    contasSelecionadas={contasSelecionadas}
                                    showModalQuitarContas={
                                        showModalQuitarContas
                                    }
                                    buscarContas={() => submit(form)}
                                    tipo={'pagar'}
                                    conciliacao={conciliacao}
                                />
                            </Fade>
                        </div>
                    </Modal>
                    <Modal
                        className={classes.modal}
                        open={showModalConfirmaCancelamento}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <div className={classes.paper}>
                            <Fade in={showModalConfirmaCancelamento}>
                                <ConteudoModalCancelarPagamento
                                    buscarContas={() => submit(form)}
                                    close={() =>
                                        setShowModalConfirmaCancelamento(false)
                                    }
                                    contasSelecionadas={contasSelecionadas}
                                />
                            </Fade>
                        </div>
                    </Modal>
                </Container>
            </Page>
        </>
    )
}
