import service from './service'

export default {
    cadastrar(param) {
        return service.post(`/patrimonio`, param)
    },
    buscarTudo(param) {
        return service.get(`/patrimonio${param}`)
    },
    ativarDesativar(id) {
        return service.put(`/patrimonio/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/patrimonio/${id}`);
    },
    buscarPorId(id) {
        return service.get(`/patrimonio/${id}`)
    },
    atualizar(param) {
        return service.put(`/patrimonio`, param)
    },
}
