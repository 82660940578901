import React from 'react'
import Table from '../../components/Table'
import EditCompleteIcon from '@material-ui/icons/EditOutlined'

export default function CentroNegocioListagem(props) {
    const colunas = [
        { title: 'Nome', field: 'nome', editable: 'never' },
        { title: 'Observação', field: 'observacao', editable: 'never' },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                emptyRowsWhenPaging={false}
                pageSize={props.pageSize}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                editarCelula={props.editarCelula}
                actions={[
                    {
                        icon: EditCompleteIcon,
                        tooltip: 'Edição',
                        onClick: (event, rowData) => props.editar(rowData.id),
                    },
                ]}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                }}
            />
        </>
    )
}
