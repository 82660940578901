import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NumberFormat from 'react-number-format'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
    RadioGroup,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { MovimentoEstoqueEsquema } from './MovimentoEstoque.validate'
import MovimentoEstoqueService from '../../services/MovimentoEstoqueService'
import ToastHelper from '../../utils/toastHelper'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import ProdutoService from '../../services/ProdutosServicosService'
import LocaisEstoqueService from '../../services/LocaisEstoqueService'
import UnidadeConversaoService from '../../services/UnidadeConversaoService'
import UnidadeDeMedidaService from '../../services/UnidadeDeMedidaService'
import EstoqueService from '../../services/EstoqueService'
import ObjectHelper from '../../utils/objetcHelper'
import ContaGerencialService from '../../services/ContaGerencialService'
import DataHelper from '../../utils/dataHelpder'
import SafraService from '../../services/SafraService'
import CentroCustoService from '../../services/CentroCustoService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlTextArea: {
        marginTop: '15px',
        minWidth: '96%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    textArea: {
        width: '100%',
        margin: theme.spacing(1),
    },
}))

export const MovimentoEstoqueCadastro = (props) => {
    const {
        match: { params },
    } = props
    let history = useHistory()
    const classes = useStyles()

    const {
        handleSubmit,
        setValue,
        getValues,
        watch,
        clearErrors,
        errors,
        control,
    } = useForm({
        defaultValues: {
            data: DataHelper.today(),
            tipoOperacao: 'entrada',
            operacao: null,
            produto: null,
            local: null,
            quantidade: '',
            valorUnitario: '',
            unidadeMedida: null,
            totalEntrada: '',
            contaGerencial: null,
            observacao: '',
            safra: null,
            centroCusto: null,
        },
        resolver: yupResolver(MovimentoEstoqueEsquema),
    })

    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [tipos, setTipos] = useState([])
    const [produtos, setProdutos] = useState([])
    const [locais, setLocais] = useState([])
    const [unidadesDeMedida, setUnidadesDeMedida] = useState([])
    const [opcoesContaGerencial, setOpcoesContaGerencial] = useState([])
    const [opcoesSafras, setOpcoesSafras] = useState([])
    const [opcoesCentroCusto, setOpcoesCentroCusto] = useState([])

    const [disableValorUnitario, setDisableValorUnitario] = useState(false)

    const setarTotal = (valorUnitario, quantidade) => {
        quantidade =
            typeof quantidade === 'string'
                ? parseFloat(
                      getValues('quantidade')
                          ?.replaceAll('.', '')
                          ?.replace(',', '.')
                  )
                : typeof quantidade === 'number'
                ? quantidade
                : 0

        valorUnitario =
            typeof valorUnitario === 'string'
                ? parseFloat(
                      getValues('valorUnitario')
                          ?.replace('R$', '')
                          ?.replaceAll('.', '')
                          ?.replace(',', '.')
                  )
                : typeof valorUnitario === 'number'
                ? valorUnitario
                : 0

        var total = isNaN(valorUnitario * quantidade)
            ? 0
            : valorUnitario * quantidade
        setValue(
            'total',
            total?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        )
    }

    useEffect(() => {
        async function getItems() {
            await buscarTipoOperacoes(getValues('tipoOperacao'))
            await buscarProdutos()
            await buscarLocais()
            await buscarContasGerenciais(getValues('tipoOperacao'))
            await buscarSafras()
            await buscarCentroCustos()
            if (params.id) await buscarPorId()
        }
        getItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            getValues('tipoOperacao') === 'saida' &&
            getValues('operacao')?.label !== 'Venda de Produtos'
        ) {
            setDisableValorUnitario(true)
            return
        }

        setDisableValorUnitario(false)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues('operacao'), getValues('tipoOperacao')])

    const buscarPorId = async () => {
        const { data } = await MovimentoEstoqueService.buscarPorId(params.id)
        setValue('data', data.data)
        setValue('quantidade', data.quantidade)
        setValue('total', data.totalEntrada)
        setValue('valorUnitario', data.valorUnitario)
        setValue('tipo', data.tipo.label)
        setValue('observacao', data.observacao)

        buscarEstoque(data.produto.value)
    }

    const buscarEstoque = (produtoId) => {
        EstoqueService.buscar(produtoId, fazendaId).then((response) => {
            if (response.status === 200) {
                let data = response.data['id']
                setValue('estoque', data)
            }
        })
    }

    const buscarTipoOperacoes = async (params) => {
        var tipoOperacaoParam = params === 'entrada' ? true : false

        const { data } = await MovimentoEstoqueService.buscarTipos(
            fazendaId,
            tipoOperacaoParam
        )
        setValue('operacao', null)
        setTipos(data)
    }

    const buscarProdutos = async (produto) => {
        const { data } = await ProdutoService.comControleEstoque(fazendaId)

        var opcoesOrdenadas = await ObjectHelper.ordenaLista(
            data,
            'nome',
            false
        )

        setProdutos(getValuesParseToSelect(opcoesOrdenadas))
        if (produto) {
            var autoFill = await data.find((item) => item.id === produto)
            await buscarUnidadesDeMedida(autoFill.unidadeMedida.id)
            await buscarEstoque(autoFill.id)
        }
    }

    const getValuesParseToSelect = (list, valueName, labelName) => {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'nome'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                ativo: item.ativo ? item.ativo : undefined,
                quantidadeMinima: item.quantidadeMinima,
                unidadeMedida: item.unidadeMedidaId,
                localEstoquePadrao: item.localEstoquePadrao,
                contaGerencialPadraoCompra: converterContaGerencial(
                    item.contaGerencialPadraoCompra
                ),
                contaGerencialPadraoVenda: converterContaGerencial(
                    item.contaGerencialPadraoVenda
                ),
                produtoAcabado: item.produtoAcabado,
            })
        })
        return options
    }

    const buscarLocais = async (local) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Nome'
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.ativo = true
        let params = await ObjectHelper.createParams(novoFiltro)
        var locais = []
        LocaisEstoqueService.buscarTudo(params)
            .then((response) => {
                response.data.itens.forEach((item) =>
                    locais.push({
                        value: item['id'],
                        label: item['nome'] + ' - ' + item['tipoEstoque'],
                    })
                )
                setLocais(locais)
            })
            .catch(function (error) {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                }
            })

        if (local) {
            var autoFill = await locais.find((item) => item.value === local)
            setValue('local', autoFill)
        }
    }

    const onSubmit = async (dados) => {
        var movimentacaoEstoque = {
            fazendaId: fazendaId,
            data: dados.data,
            entrada: dados.tipoOperacao === 'entrada' ? true : false,
            tipoOperacaoId: dados.operacao.value,
            produtoId: dados.produto.value,
            locaisEstoqueId: dados.local.value,
            quantidade: parseFloat(
                dados.quantidade.replaceAll('.', '').replace(',', '.')
            ),
            valorUnitario: parseFloat(
                dados.valorUnitario
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
            ),
            unidadeMedidaId: dados.unidadeMedida.value,
            totalEntrada:
                parseFloat(
                    dados.quantidade.replaceAll('.', '').replace(',', '.')
                ) *
                parseFloat(
                    dados.valorUnitario
                        .replace('R$', '')
                        .replaceAll('.', '')
                        .replace(',', '.')
                ),
            contaGerencialId: dados.contaGerencial.id,
            safraId: dados.safra ? dados.safra.id : null,
            centroCustoId: dados.centroCusto ? dados.centroCusto.id : null,
            observacao: dados.observacao,
        }

        if (params.id) {
            movimentacaoEstoque.id = params.id
            await MovimentoEstoqueService.atualizar(movimentacaoEstoque)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Dados atualizados!')
                        history.goBack()
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        ToastHelper.error(error.response.data)
                    }
                })
        } else {
            await MovimentoEstoqueService.cadastrar(movimentacaoEstoque)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Movimento de Estoque cadastrado com sucesso!'
                        )
                        history.goBack()
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        ToastHelper.error(error.response.data)
                    }
                })
        }
    }

    const setarQuantidade = (param) => {
        if (param) {
            setValue('quantidade', param)
            clearErrors('quantidade')
        } else {
            setValue('quantidade', '')
        }
    }

    const buscarUnidadesDeMedida = async (unidade) => {
        let options = []
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.unidade = unidade
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)

        await UnidadeConversaoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                response.data.forEach((item) => {
                    options.push({
                        value: item.unidadeConvertida['id'],
                        label: item.unidadeConvertida['sigla'],
                    })
                })
            } else {
                ToastHelper(response.statusText)
            }
        })

        await UnidadeDeMedidaService.conversoes(params).then((response) => {
            if (response.status === 200) {
                response.data.forEach((item) => {
                    options.push({
                        value: item['id'],
                        label: item['sigla'],
                    })
                })
            } else {
                ToastHelper(response.statusText)
            }
        })

        await UnidadeDeMedidaService.buscarPorId(filtro.unidade).then(
            (response) => {
                if (response.status === 200) {
                    options.push({
                        value: response.data['id'],
                        label: response.data['sigla'],
                    })
                } else {
                    ToastHelper(response.statusText)
                }
            }
        )

        options = options.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.value === thing.value && t.label === thing.label
                )
        )

        var unidadeSelecionado = options.find((x) => {
            if (x.value === getValues('produto').unidadeMedida) {
                return x
            }
            return null
        })
        setValue('unidadeMedida', unidadeSelecionado)
        setUnidadesDeMedida(options)
    }

    const sugerirLocal = async (produto) => {
        var produtoSelecionado = await produtos.find(
            (item) => item.value === produto
        )
        if (
            produtoSelecionado &&
            produtoSelecionado.localEstoquePadrao !== null
        ) {
            if (getValues('tipoOperacao') === 'saida') {
                const { data } =
                    await EstoqueService.buscarValorUnitarioNoEstoque(
                        produto,
                        fazendaId,
                        produtoSelecionado.localEstoquePadrao['id']
                    )

                const valorUnitarioSugerido = data ? data.valorUnitario : null

                if (valorUnitarioSugerido !== null)
                    setValue('valorUnitario', valorUnitarioSugerido.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }))
            }

            setValue('local', {
                value: produtoSelecionado.localEstoquePadrao['id'],
                label: produtoSelecionado.localEstoquePadrao['nome'],
            })
            clearErrors('local')
        } else {
            setValue('local', {
                value: '',
                label: '',
            })
        }
    }

    const buscarContasGerenciais = async (tipoOperacao) => {
        let novoFiltro = { fazendaId }
        novoFiltro.tipo = 1
        novoFiltro.ordem = 'Descricao'
        novoFiltro.tipo = 1
        novoFiltro.ativo = true
        novoFiltro.operacao = tipoOperacao === 'entrada' ? 1 : 0
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.semPadroes = true;
        let params = await ObjectHelper.createParams(novoFiltro)
        const { data } = await ContaGerencialService.buscarTudo(params)
        data.itens.forEach((item) => {
            item.descricao = `${item.numero} - ${item.descricao}`
        })
        setOpcoesContaGerencial(data.itens)
    }

    const converterContaGerencial = (contaGerencial) => {
        if (contaGerencial) {
            contaGerencial.descricao = `${contaGerencial.numero} - ${contaGerencial.descricao}`
        }
        return contaGerencial
    }

    const setarContaGerencial = async (contaGerencial) => {
        setValue('contaGerencial', contaGerencial)
        if (contaGerencial) {
            clearErrors('contaGerencial')
        }
    }

    const buscarSafras = async () => {
        const { data } = await SafraService.buscarSafras(fazendaId)
        setOpcoesSafras(data)
    }

    const buscarCentroCustos = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        filtro.ordem = 'descricao'
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await CentroCustoService.buscarTudo(params)
        setOpcoesCentroCusto(data.itens)
    }

    return (
        <Page
            className={classes.root}
            title={
                params.id
                    ? 'Editar Movimento de Estoque'
                    : 'Cadastrar Movimento de Estoque'
            }
        >
            <Container maxWidth={'xl'}>
                <Titulo
                    titulo={
                        params.id
                            ? 'Editar Movimento de Estoque'
                            : 'Cadastrar Movimento de Estoque'
                    }
                />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item md={12} display="flex">
                                <Controller
                                    control={control}
                                    id="tipoOperacao"
                                    name="tipoOperacao"
                                    as={
                                        <RadioGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label="Entrada"
                                                value="entrada"
                                                onChange={(e) => {
                                                    buscarTipoOperacoes(
                                                        e.target.value
                                                    )
                                                    buscarContasGerenciais(
                                                        e.target.value
                                                    )
                                                    if (getValues('produto')) {
                                                        setarContaGerencial(
                                                            getValues('produto')
                                                                .contaGerencialPadraoCompra
                                                        )
                                                    }
                                                }}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label="Saída"
                                                value="saida"
                                                onChange={(e) => {
                                                    buscarTipoOperacoes(
                                                        e.target.value
                                                    )
                                                    buscarContasGerenciais(
                                                        e.target.value
                                                    )
                                                    if (getValues('produto')) {
                                                        setarContaGerencial(
                                                            getValues('produto')
                                                                .contaGerencialPadraoVenda
                                                        )
                                                    }
                                                }}
                                            />
                                        </RadioGroup>
                                    }
                                />
                            </Grid>
                            <Divider
                                style={{ marginTop: 20, marginBottom: 20 }}
                            />
                            <Grid container item md={12} display="flex">
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="data"
                                            name="data"
                                            as={
                                                <TextField
                                                    type="date"
                                                    label="Data*"
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            max: '9999-12-31',
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.data
                                                ? errors.data.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="operacao"
                                            name="operacao"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={tipos}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.label
                                                            : ''
                                                    }
                                                    size="small"
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.value ===
                                                        value?.value
                                                    }
                                                    value={watch('operacao')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Operação*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.operacao
                                                ? errors.operacao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="produto"
                                            name="produto"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={produtos}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.label
                                                            : ''
                                                    }
                                                    size="small"
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                        if (data) {
                                                            sugerirLocal(
                                                                data.value
                                                            )
                                                            setarQuantidade(
                                                                data.quantidadeMinima
                                                            )
                                                            setarTotal(
                                                                getValues(
                                                                    'valorUnitario'
                                                                ),
                                                                data.quantidadeMinima
                                                            )
                                                            buscarUnidadesDeMedida(
                                                                data.unidadeMedida
                                                            )
                                                            if (
                                                                getValues(
                                                                    'tipoOperacao'
                                                                ) === 'entrada'
                                                            ) {
                                                                setarContaGerencial(
                                                                    data.contaGerencialPadraoCompra
                                                                )
                                                            } else {
                                                                setarContaGerencial(
                                                                    data.contaGerencialPadraoVenda
                                                                )
                                                            }
                                                        } else {
                                                            sugerirLocal(null)
                                                            setarQuantidade(
                                                                null
                                                            )
                                                            setUnidadesDeMedida(
                                                                []
                                                            )
                                                        }
                                                        setValue(
                                                            'unidadeMedida',
                                                            null
                                                        )
                                                    }}
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.value ===
                                                        value?.value
                                                    }
                                                    value={watch('produto')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Produto*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.produto
                                                ? errors.produto.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="local"
                                            name="local"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={locais}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.label
                                                            : ''
                                                    }
                                                    size="small"
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.value ===
                                                        value?.value
                                                    }
                                                    value={watch('local')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Local*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.local
                                                ? errors.local.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="quantidade"
                                            name="quantidade"
                                            as={
                                                <NumberFormat
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    label="Quantidade*:"
                                                    customInput={TextField}
                                                    onValueChange={setarTotal(
                                                        watch('valorUnitario'),
                                                        watch('quantidade')
                                                    )}
                                                    inputProps={{
                                                        min: 1,
                                                        style: {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.quantidade
                                                ? errors.quantidade.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="valorUnitario"
                                            name="valorUnitario"
                                            as={
                                                <NumberFormat
                                                    prefix={'R$ '}
                                                    decimalScale={2}
                                                    fixedDecimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    disabled={
                                                        disableValorUnitario
                                                    }
                                                    label="Valor Unitário*:"
                                                    customInput={TextField}
                                                    onValueChange={setarTotal(
                                                        watch('valorUnitario'),
                                                        watch('quantidade')
                                                    )}
                                                    inputProps={{
                                                        min: 1,
                                                        style: {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.valorUnitario
                                                ? errors.valorUnitario.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="unidadeMedida"
                                            name="unidadeMedida"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={unidadesDeMedida}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.label
                                                            : ''
                                                    }
                                                    size="small"
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.value ===
                                                        value?.value
                                                    }
                                                    value={watch(
                                                        'unidadeMedida'
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Unidade de Medida*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.unidadeMedida
                                                ? errors.unidadeMedida.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            id="total"
                                            name="total"
                                            control={control}
                                            as={
                                                <TextField
                                                    label={
                                                        watch(
                                                            'tipoOperacao'
                                                        ) === 'entrada'
                                                            ? 'Total Entrada'
                                                            : 'Total Saída'
                                                    }
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={true}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item md={12} display="flex">
                                    <Grid container item md={3} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Controller
                                                control={control}
                                                id="contaGerencial"
                                                name="contaGerencial"
                                                as={(props) => (
                                                    <Autocomplete
                                                        options={
                                                            opcoesContaGerencial
                                                        }
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option
                                                                ? option.descricao
                                                                : ''
                                                        }
                                                        size="small"
                                                        onChange={(_, data) => {
                                                            props.onChange(data)
                                                        }}
                                                        getOptionSelected={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option?.id ===
                                                            value?.id
                                                        }
                                                        value={watch(
                                                            'contaGerencial'
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label="Conta Gerencial*"
                                                                placeholder="Selecione"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                            <p className={classes.errors}>
                                                {errors.contaGerencial
                                                    ? errors.contaGerencial
                                                          .message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    {watch('produto') &&
                                        watch('produto').produtoAcabado && (
                                            <Grid
                                                container
                                                item
                                                md={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <Controller
                                                        control={control}
                                                        id="safra"
                                                        name="safra"
                                                        as={(props) => (
                                                            <Autocomplete
                                                                options={
                                                                    opcoesSafras
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option
                                                                        ? option.nome
                                                                        : ''
                                                                }
                                                                size="small"
                                                                onChange={(
                                                                    _,
                                                                    data
                                                                ) => {
                                                                    props.onChange(
                                                                        data
                                                                    )
                                                                }}
                                                                getOptionSelected={(
                                                                    option,
                                                                    value
                                                                ) =>
                                                                    option?.id ===
                                                                    value?.id
                                                                }
                                                                value={watch(
                                                                    'safra'
                                                                )}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Safra*"
                                                                        placeholder="Selecione"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        {errors.safra
                                                            ? errors.safra
                                                                  .message
                                                            : ''}
                                                    </p>
                                                </FormControl>
                                            </Grid>
                                        )}
                                    {watch('tipoOperacao') === 'saida' && (
                                        <Grid
                                            container
                                            item
                                            md={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="centroCusto"
                                                    name="centroCusto"
                                                    as={(props) => (
                                                        <Autocomplete
                                                            options={
                                                                opcoesCentroCusto
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option
                                                                    ? option.descricao
                                                                    : ''
                                                            }
                                                            size="small"
                                                            onChange={(
                                                                _,
                                                                data
                                                            ) => {
                                                                props.onChange(
                                                                    data
                                                                )
                                                            }}
                                                            getOptionSelected={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option?.id ===
                                                                value?.id
                                                            }
                                                            value={watch(
                                                                'centroCusto'
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Centro de Custo*"
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.centroCusto
                                                        ? errors.centroCusto
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlTextArea}
                                    >
                                        <Controller
                                            id="observacao"
                                            name="observacao"
                                            control={control}
                                            as={
                                                <TextField
                                                    label="Observação"
                                                    type="text"
                                                    multiline
                                                    variant="outlined"
                                                    rows={2}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
