import React, { useState } from 'react'
import MaterialTable from 'material-table'
import { Button, TextField, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import NumberFormat from 'react-number-format'
import FormControl from '@material-ui/core/FormControl'
import DateHelper from 'utils/dataHelpder'
import { Edit3 as Edit3Icon } from 'react-feather'
import AddIcon from '@material-ui/icons/Add'

const opcoesParcelas = [
    {
        value: 1,
        label: 'À vista',
    },
    {
        value: 2,
        label: '2x',
    },
    {
        value: 3,
        label: '3x',
    },
    {
        value: 4,
        label: '4x',
    },
    {
        value: 5,
        label: '5x',
    },
    {
        value: 6,
        label: '6x',
    },
    {
        value: 7,
        label: '7x',
    },
    {
        value: 8,
        label: '8x',
    },
    {
        value: 9,
        label: '9x',
    },
    {
        value: 10,
        label: '10x',
    },
    {
        value: 11,
        label: '11x',
    },
    {
        value: 12,
        label: '12x',
    },
]

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    tabela: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    errors: {
        color: 'red',
    },
}))

export default function Parcelamento(props) {
    const classes = useStyles()
    const [erroNumeroParcelas, setErroNumeroParcelas] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroDataVencimento, setErroDataVencimento] = useState({
        erro: false,
        mensagem: '',
    })

    const [colunasParcelas] = useState([
        { title: 'Parcela', field: 'parcela', editable: 'never' },
        {
            title: 'Vencimento',
            field: 'dataVencimento',
            editComponent: (props) => (
                <div className="width-100">
                    <TextField
                        id="vencimento"
                        name="vencimento"
                        type="date"
                        value={DateHelper.formataStringData(
                            props.rowData.dataVencimento
                        )}
                        error={props.rowData.dataVencimento === 'ed/ed/'}
                        helperText={
                            props.rowData.dataVencimento === 'ed/ed/'
                                ? 'Campo obrigatório'
                                : ''
                        }
                        onKeyDown={(e) => handleKey(e)}
                        onChange={(item) => {
                            let novoValor =
                                DateHelper.transformStringDataUsaToPtBr(
                                    item.target.value
                                )
                            var data = { ...props.rowData }
                            data.dataVencimento = novoValor
                            props.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            {
                                inputProps: {max: '9999-12-31'}
                            }
                        }
                    />
                </div>
            ),
        },

        {
            title: 'Valor',
            field: 'valorFormatado',
            editComponent: (props) => (
                <div className="width-100">
                    <NumberFormat
                        prefix={'R$ '}
                        error={props.rowData.valor <= 0}
                        helperText={
                            props.rowData.valor <= 0 ? 'Campo obrigatório' : ''
                        }
                        style={{ width: 140 }}
                        value={props.rowData.valor}
                        decimalScale={2}
                        decimalSeparator={','}
                        customInput={TextField}
                        onChange={(item) => {
                            let novoValor = item.target.value
                            if (novoValor.indexOf(',') === -1) {
                                novoValor += ',00'
                            }
                            var data = { ...props.rowData }
                            data.valor = parseFloat(
                                novoValor
                                    .replace('R$', '')
                                    .replace(' ', '')
                                    .replace(',', '.')
                            )
                            data.valorFormatado = novoValor
                            props.onRowDataChange(data)
                        }}
                        id="valor"
                        name="valor"
                        onKeyDown={(e) => handleKey(e)}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
    ])

    const limparParcelas = async () => {
        props.limpar()
    }

    const gerarParcelas = async () => {
        let valido = validaCamposParcelamento()
        if (valido) {
            let valorParcelas = props.valorConta / props.numeroParcelas.value

            valorParcelas = valorParcelas.toFixed(2)
            let parcelas = []
            let vencimento = props.dataVencimento
            for (var i = 0; i < props.numeroParcelas.value; ++i) {
                let novaData = new Date(vencimento)
                novaData.setDate(novaData.getDate() + 1)
                var mes = novaData.getMonth() + i
                novaData.setMonth(mes)

                novaData = DateHelper.converterDataParaString(novaData)
                let novaParcela = {
                    parcela: i + 1,
                    dataVencimento: novaData,
                    dataCompensacao: '',
                    valor: parseFloat(valorParcelas),
                    valorFormatado: parseFloat(valorParcelas).toLocaleString(
                        'pt-br',
                        { style: 'currency', currency: 'BRL' }
                    ),
                    valorPago: '',
                    codigoDeBarras: '',
                    conta: '',
                    formaPagamento: '',
                    documentoPago: '',
                    cheque: '',
                }

                if (
                    parseFloat(valorParcelas) * props.numeroParcelas.value !==
                        props.valorConta &&
                    i + 1 === props.numeroParcelas.value
                ) {
                    var diferenca =
                        props.valorConta -
                        parseFloat(valorParcelas) * props.numeroParcelas.value
                    novaParcela.valor = novaParcela.valor + diferenca
                    novaParcela.valorFormatado =
                        novaParcela.valor.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })
                }
                parcelas.push(novaParcela)
            }

            props.geraParcelas(parcelas)
        }
    }

    const validaCamposParcelamento = () => {
        let valido = true
        if (!props.dataVencimento) {
            setErroDataVencimento({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroDataVencimento({ erro: false, mensagem: '' })
        }
        if (!props.numeroParcelas) {
            setErroNumeroParcelas({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroNumeroParcelas({ erro: false, mensagem: '' })
        }
        return valido
    }

    const handleKey = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation()
        }
    }

    const validSubmit = async (lista) => {
        let error = true
        await lista.forEach((item) => {
            if (
                item.parcela === (null || '') ||
                item.valor === (null || '' || 'R$' || '$' || 0) ||
                item.valor <= 0 ||
                item.vencimento === (null || 'ed/ed/' || '')
            ) {
                error = false
            }
        })

        return error
    }

    return (
        <>
            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <h4 style={{ marginLeft: '5px', marginBottom: '15px' }}>
                        Forma de Pagamento
                    </h4>
                </Grid>
            </Grid>
            {!props.disabled && props.parcelas.length === 0 && (
                <Grid container item xs={12} display="flex">
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                name="parcelas"
                                id="parcelas"
                                options={opcoesParcelas}
                                className={classes.textField}
                                value={props.numeroParcelas}
                                disabled={props.parcelas.length > 0}
                                getOptionLabel={(option) =>
                                    option.label ? option.label : ''
                                }
                                onChange={(event, newValue) => {
                                    props.mudaNumeroParcelas(
                                        newValue ? newValue : null
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="parcelas"
                                        id="parcelas"
                                        variant="standard"
                                        label="Parcelas:*"
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            <p className={classes.errors}>
                                {erroNumeroParcelas.erro
                                    ? erroNumeroParcelas.mensagem
                                    : ''}
                            </p>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="dataVenciamento"
                                label={
                                    props.numeroParcelas
                                        ? props.numeroParcelas.value === 1
                                            ? 'Data de vencimento:*'
                                            : 'Data do 1º vencimento:*'
                                        : 'Data de vencimento:*'
                                }
                                name="dataVenciamento"
                                type="date"
                                value={props.dataVencimento}
                                onChange={(e) => props.mudaDataVencimento(e)}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        inputProps: {max: '9999-12-31'}
                                    }
                                }
                            />
                            <p className={classes.errors}>
                                {erroDataVencimento.erro
                                    ? erroDataVencimento.mensagem
                                    : ''}
                            </p>
                        </FormControl>
                    </Grid>
                    {props.parcelas.length === 0 && (
                        <Grid
                            xs={2}
                            container
                            item
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={gerarParcelas}
                                size="small"
                            >
                                Gerar Parcelas
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            {props.parcelas.length > 0 && !props.isVisualizar && (
                <>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="right"
                        style={{ marginBottom: 10 }}
                    >
                        <Button
                            variant="contained"
                            color="secundary"
                            onClick={limparParcelas}
                            size="small"
                        >
                            Limpar Parcelas
                        </Button>
                    </Grid>
                </>
            )}

            <Grid className={classes.tabela}>
                <MaterialTable
                    title="Parcelas"
                    columns={colunasParcelas}
                    data={props.parcelas}
                    options={{
                        paging: false,
                        search: false,
                        rowStyle: {
                            fontSize: 14,
                        },
                        padding: 'dense',
                        actionsColumnIndex: -1,
                        toolbar: !props.disabled,
                        emptyRowsWhenPaging: false,
                    }}
                    editable={{
                        isDeletable: (rowData) => !props.disabled,
                        onBulkUpdate: (changes) =>
                            new Promise(async (resolve, reject) => {
                                let array = []
                                // eslint-disable-next-line no-unused-vars
                                for (let [key, value] of Object.entries(
                                    changes
                                )) {
                                    array.push({
                                        id: value.newData.id,
                                        parcela: value.newData.parcela,
                                        valor: value.newData.valor
                                            ? value.newData.valor
                                            : '',
                                        valorFormatado: value.newData
                                            .valorFormatado
                                            ? value.newData.valorFormatado
                                            : '',
                                        dataVencimento:
                                            value.newData.dataVencimento,
                                        tableData: value.newData.tableData,
                                    })
                                }
                                let valido = await validSubmit(array)
                                if (valido) {
                                    props.atualizaParcelas(array)
                                    resolve()
                                } else {
                                    reject()
                                }
                            }),
                    }}
                    icons={{
                        Edit: Edit3Icon,
                        Add: (props) => {
                            return (
                                <AddIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        borderRadius: 100,
                                    }}
                                />
                            )
                        },
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhuma parcela encontrada',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Editar',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                        },
                    }}
                />
            </Grid>
        </>
    )
}
