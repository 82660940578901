import { SET_FILTRO_LISTAGEM_ESTOQUE } from '../actions/estoque';

export const storageEstoque = 'Estoque';

const initialState = {
    filtroListagem: {},
};

export const estoque = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_ESTOQUE:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
