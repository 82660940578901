import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import TipoDocumentoListagem from './TipoDocumentoListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import TipoDocumentoService from '../../services/TipoDocumentoService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const TipoDocumento = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [loading, setLoading] = useState(false)
    const [tiposDocumentos, setTiposDocumentos] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.tipoDocumento.filtroListagem)
    )

    useEffect(() => {
        const getTipoDocumentos = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'Descricao'
            novoFiltro.tamanhoPagina = 100
            buscarTiposDocumentos(novoFiltro)
        }
        getTipoDocumentos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_TIPO_DOCUMENTO',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarTiposDocumentos(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_TIPO_DOCUMENTO',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/tipoDocumento/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarTiposDocumentos(novoFiltro)
    }

    const buscarTiposDocumentos = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.ativo = filtro.ativo ? filtro.ativo.value : ''
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await TipoDocumentoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = fazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                setTiposDocumentos(response.data.itens)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const editar = (itemId) => {
        history.push(`/app/tipoDocumento/editar/${itemId}`)
    }

    const ativarDesativar = async (itemId) => {
        setLoading(true)
        await TipoDocumentoService.ativarDesativar({ id: itemId }).then(
            (response) => {
                if (response.status === 200) {
                    let novoFiltro = { ...filtro }
                    buscarTiposDocumentos(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const deletar = async (item) => {
        await TipoDocumentoService.deletar(item.id)
            .then((response) => {
                ToastHelper.success('Tipo de documento excluído com sucesso!')
                let novoFiltro = { ...filtro }
                buscarTiposDocumentos(novoFiltro)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarTiposDocumentos(novoFiltro)
    }

    const mudarTipoDocumento = (campo, rowData) => {}

    const editarCelula = async (
        novoValor,
        valorAntigo,
        dadosDaLinha,
        dadosDaColuna
    ) => {
        if (novoValor !== '') {
            dadosDaLinha[dadosDaColuna.field] =
                dadosDaColuna.field === 'saldoAtual'
                    ? parseInt(novoValor)
                    : novoValor
            dadosDaLinha.fazendaId = fazendaId
            await TipoDocumentoService.atualizar(dadosDaLinha)
                .then((response) => {
                    if (response.status !== 200)
                        ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    dadosDaLinha[dadosDaColuna.field] = valorAntigo
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
        }
    }

    return (
        <Page className={classes.root} title="Tipos de Documentos">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Tipos de Documentos" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroAtivo
                    temFiltroAvancado
                />
                <Box mt={3}>
                    <TipoDocumentoListagem
                        data={tiposDocumentos}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        ativarDesativar={ativarDesativar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        mudarTipoDocumento={mudarTipoDocumento}
                        editarCelula={editarCelula}
                    />
                </Box>
            </Container>
        </Page>
    )
}
