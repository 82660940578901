import * as Yup from 'yup';

export const TransferenciaEstoqueEsquema = Yup.object().shape({
    data: Yup.string().required('Campo obrigatório'),
    localOrigemId: Yup.string().required('Campo obrigatório'),
    localDestinoId: Yup.string().required('Campo obrigatório'),
    produtoId: Yup.string().required('Campo obrigatório'),
    quantidade: Yup.string().required('Campo obrigatório'),
    unidadeMedidaId: Yup.string().required('Campo obrigatório'),
});
