import { SET_FILTRO_FLUXO_CAIXA } from '../actions/fluxoCaixa'
import DataHelper from '../../utils/dataHelpder'

export const storageFluxoCaixa = 'fluxoCaixa'

const initialState = {
    filtroListagem: {
        opcaoPeriodoFiltroSelecionada: {
            nome: 'Mensal',
            id: 2,
        },
        periodoInicial: DataHelper.today(),
        periodoFinal: '',
    },
}

export const fluxoCaixa = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_FLUXO_CAIXA:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
