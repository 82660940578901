import React from 'react'
import Select from 'react-select'
import { colors } from '@material-ui/core'

const SelectItensPage = (props) => {
    return (
        <div style={props.styleComponent}>
            <strong style={{ fontSize: 15 }}>Itens por página:</strong>
            <Select
                placeholder="Selecionar"
                options={props.optionsPage}
                onChange={(item) => props.onChangeNumberItens(item.value)}
                styles={{
                    option: (defaultStyles, state) => ({
                        ...defaultStyles,
                        zIndex: 9999,
                        backgroundColor: !state.isSelected
                            ? state.isFocused
                                ? colors.green[800]
                                : '#FFFFFF'
                            : colors.blue[600],
                        color:
                            state.isFocused || state.isSelected
                                ? '#FFFFFF'
                                : '#000000',
                        cursor: 'pointer',
                    }),
                    menu: (defaultStyles, state) => ({
                        ...defaultStyles,
                        zIndex: 9999,
                    }),
                }}
                menuPlacement={props.menuPlacement}
                value={{ value: props.itensPage, label: props.itensPage }}
            />
            <br />
        </div>
    )
}

export default SelectItensPage
