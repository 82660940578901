import React from 'react'
import clsx from 'clsx'
import {
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Search as SearchIcon } from 'react-feather'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    linkChangeFilter: {
        marginTop: '50px',
    },
    po: {
        marginBottom: 0,
        paddingBottom: 0,
    },
}))

function stopPropagation(event) {
    event.stopPropagation()
}

export default function FiltroSimples(props) {
    const classes = useStyles()
    const onKeyPres = (e) => {
        if (e.key === 'Enter') props.submit(e)
    }
    return (
        <Grid
            item
            container
            xs={12}
            display="flex"
            onClick={
                props.temFiltroAvancado ? () => {} : (e) => stopPropagation(e)
            }
            className={classes.po}
        >
            <Grid
                item
                xs={10}
                className={classes.po}
                onClick={
                    props.temFiltroAvancado
                        ? () => {}
                        : (e) => stopPropagation(e)
                }
            >
                <TextField
                    className={classes.po}
                    fullWidth
                    onClick={(e) => stopPropagation(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                    <SearchIcon
                                        className={clsx(
                                            classes.linkChangeFilter,
                                            props.className
                                        )}
                                    />
                                </SvgIcon>
                            </InputAdornment>
                        ),
                    }}
                    value={props.filtro.filtro}
                    placeholder="Pesquisar"
                    variant="outlined"
                    onChange={(e) => props.changeFiltro('filtro', e)}
                    onKeyDown={(e) => onKeyPres(e)}
                />
            </Grid>
            <Grid
                item
                xs={2}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                onClick={(e) => stopPropagation(e)}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    {props.clickadd !== undefined ? (
                        <Fab
                            color="primary"
                            size="small"
                            aria-label="add"
                            onClick={() => props.clickadd()}
                        >
                            <AddIcon />
                        </Fab>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}
