import React from 'react'
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts'
import { makeStyles, Grid, Card } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    grafico: {
        margin: theme.spacing(2),
    },
}))

export const Graficos = (props) => {
    const classes = useStyles()

    const RADIAN = Math.PI / 180
    const COLORS = [
        '#0088FE',
        '#00C49F',
        '#FFBB28',
        '#FF8042',
        '#FF2742',
        '#FF5555',
        '#FF1215',
    ]

    let renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
    }

    const renderTooltip = (props) => {
        const { payload } = props

        return payload.map((entry, index) => (
            <Card>
                <strong>
                    {entry.value.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </strong>
            </Card>
        ))
    }

    return (
        <Card>
            <Grid container display="flex" justify="center">
                <PieChart width={400} height={350} className={classes.grafico}>
                    <Pie
                        data={props.data}
                        cx={200}
                        cy={120}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        dataKey="value"
                        isAnimationActive={true}
                    >
                        {props.data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                    <Legend verticalAlign="bottom" layout="vertical" />
                    <Tooltip content={renderTooltip} />
                </PieChart>
            </Grid>
        </Card>
    )
}
