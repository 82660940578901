import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Button, TextField, Divider } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Request from '../requests'
import NumberFormat from 'react-number-format'
import DataHelper from '../../../utils/dataHelpder'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 80,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
}))

export default function ModalProdutosServicos(props) {
    const classes = useStyles()
    const [form, setform] = useState({})
    const [opcoesUnidades, setOpcoesUnidade] = useState([])
    const [errors, setErrors] = React.useState([])
    const [errorDataPrevisaoEntrega, seterrorDataPrevisaoEntrega] =
        React.useState(null)

    const submit = async () => {
        var convertForm = { ...form }
        if (
            !DataHelper.compareTwoDates(
                convertForm.previsaoEntrega,
                props.dataPedido
            )
        )
            return seterrorDataPrevisaoEntrega(
                "A data de Previsão de entrega deve ser maior que a Data do Pedido '" +
                    DataHelper.transformStringDataUsaToPtBr(props.dataPedido) +
                    "'."
            )
        convertForm.previsaoEntrega = DataHelper.transformStringDataUsaToPtBr(
            convertForm.previsaoEntrega
        )
        var valid = await Request.validacaoFormModalProdutosServicos(
            convertForm
        )
        if (valid.length > 0) {
            setErrors(valid)
        } else {
            props.setItens(convertForm)
            props.close()
        }
    }

    const onChangeQuantidade = (e) => {
        var valorUnitario = form.valorUnitario
            ? parseFloat(form.valorUnitario)
            : 0
        let total = parseFloat(e.target.value) * valorUnitario
        setform({
            ...form,
            valorTotal: total,
            quantidade: parseFloat(e.target.value),
        })
        setErrors([])
    }

    const buscarUnidades = async (unidadeAtual) => {
        let data = await Request.buscarUnidadesDeMedida(unidadeAtual)
        setOpcoesUnidade(data)
    }

    const onChangeValorUnitario = (e) => {
        var quantidade = form.quantidade ? parseFloat(form.quantidade) : 0
        let total =
            parseFloat(e.target.value.replaceAll('.', '').replace(',', '.')) *
            quantidade
        setform({
            ...form,
            valorTotal: total,
            valorUnitario: parseFloat(
                e.target.value.replaceAll('.', '').replace(',', '.')
            ),
        })
        setErrors([])
    }

    const onChangeProduct = (e) => {
        if (e?.produto) {
            buscarUnidades(e.unidadeMedida.id)
        } else {
            buscarUnidades()
        }
        setErrors([])
        setform({
            ...form,
            produtoServico: e ? e : '',
            produtoServicoId: e?.id,
            contaGerencial: {
                descricao: e?.contaGerencialPadraoCompra
                    ? e.contaGerencialPadraoCompra.numero +
                      ' - ' +
                      e.contaGerencialPadraoCompra.descricao
                    : '',
                id: e?.contaGerencialPadraoCompra?.id,
            },
            contaGerencialId: e?.contaGerencialPadraoCompra?.id,
            centroCusto: e?.centroCustoPadrao ? e.centroCustoPadrao : '',
            centroCustoId: e?.centroCustoPadrao?.id,
            unidade: {
                sigla: e?.unidadeMedida?.sigla,
                id: e?.unidadeMedida?.id,
            },
            unidadeId: e?.unidadeMedida?.id,
        })
    }

    return (
        <div className={classes.paper}>
            <h2 className={classes.title}>Cadastro de Produtos/Serviços</h2>
            <Divider className={classes.divider} />

            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            options={props.opcoesProdutosServicos}
                            getOptionLabel={(option) => option.nome}
                            onChange={(event, option) => {
                                onChangeProduct(option)
                            }}
                            value={
                                form.produtoServico ? form.produtoServico : ''
                            }
                            defaultValue={
                                form.produtoServico ? form.produtoServico : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={'Produtos/Serviços*'}
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {errors.find((x) => x.id === 'produtoServicoId') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            options={opcoesUnidades}
                            disableClearable
                            getOptionLabel={(option) => option.sigla}
                            onChange={(event, option) => {
                                setform({
                                    ...form,
                                    unidade: option,
                                    unidadeId: option?.id,
                                })
                                setErrors([])
                            }}
                            value={form.unidade ? form.unidade : ''}
                            defaultValue={form.unidade ? form.unidade : ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Unidade*"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {errors.find((x) => x.id === 'unidadeId') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="quantidade"
                            type="number"
                            id="quantidade"
                            variant="standard"
                            autoComplete="off"
                            label="Quantidade*"
                            onChange={(e) => onChangeQuantidade(e)}
                            placeholder="Digite a quantidade"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={form.quantidade ? form.quantidade : ''}
                            defaultValue={
                                form.quantidade ? form.quantidade : ''
                            }
                        />
                        {errors.find((x) => x.id === 'quantidade') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <NumberFormat
                            label="Valor Unitário*"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            customInput={TextField}
                            onChange={(e) => {
                                onChangeValorUnitario(e)
                            }}
                            id="nome"
                            name="nome"
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'left' },
                            }}
                            isplaceholderanimated={(false, 'top')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.find((x) => x.id === 'valorUnitario') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Valor Total"
                            value={form.valorTotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                            disabled={true}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            key={form.centroCusto}
                            options={props.opcoesCentroCusto}
                            getOptionLabel={(option) => option.descricao}
                            onChange={(event, option) => {
                                setErrors([])
                                option !== null
                                    ? setform({
                                          ...form,
                                          centroCusto: option,
                                          centroCustoId: option.id,
                                      })
                                    : setform({
                                          ...form,
                                          centroCusto: undefined,
                                          centroCustoId: undefined,
                                      })
                            }}
                            defaultValue={
                                form.centroCusto ? form.centroCusto : ''
                            }
                            value={form.centroCusto ? form.centroCusto : ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Centro de Custo"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {errors.find((x) => x.id === 'centroCustoId') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            options={props.opcoesContaGerencial}
                            getOptionLabel={(option) => option.descricao}
                            onChange={(event, option) => {
                                setform({
                                    ...form,
                                    contaGerencial: option,
                                    contaGerencialId: option?.id,
                                })
                                setErrors([])
                            }}
                            value={
                                form.contaGerencial ? form.contaGerencial : ''
                            }
                            defaultValue={
                                form.contaGerencial ? form.contaGerencial : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={'Conta Gerencial*'}
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {errors.find((x) => x.id === 'contaGerencialId') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Previsão de Entrega:*"
                            onChange={(e) => {
                                if (
                                    !DataHelper.compareTwoDates(
                                        e.target.value,
                                        props.dataPedido
                                    )
                                ) {
                                    setform({
                                        ...form,
                                        previsaoEntrega: e.target.value,
                                    })
                                    seterrorDataPrevisaoEntrega(
                                        "A data de Previsão de entrega deve ser maior ou igual que a Data do Pedido '" +
                                            DataHelper.transformStringDataUsaToPtBr(
                                                props.dataPedido
                                            ) +
                                            "'."
                                    )
                                } else {
                                    seterrorDataPrevisaoEntrega(null)
                                    setform({
                                        ...form,
                                        previsaoEntrega: e.target.value,
                                    })
                                }
                            }}
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={
                                {
                                    inputProps: {max: '9999-12-31'}
                                }
                            }
                        />
                        {errors.find((x) => x.id === 'previsaoEntrega') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                        {errorDataPrevisaoEntrega && (
                            <p className={classes.errors}>
                                {errorDataPrevisaoEntrega}
                            </p>
                        )}
                    </FormControl>
                </Grid>
            </Grid>

            <Grid
                xs={12}
                container
                item
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Button
                    onClick={props.close}
                    className={classes.button2}
                    variant="contained"
                    color="secundary"
                    size="small"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={submit}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    Cadastrar
                </Button>
            </Grid>
        </div>
    )
}
