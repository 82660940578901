import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { getUsername } from '../../services/Auth'

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 80,
        marginLeft: -125,
    },
    media: {
        height: 140,
    },
})

export default function Welcome() {
    const classes = useStyles()

    const sair = () => {
        window.location.href = '/logout'
    }

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image="/campos.jpg"
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h1" component="h1">
                        {getUsername()
                            ? `Bem vindo, ${getUsername().split(' ')[0]}!`
                            : `Bem vindo!`}
                    </Typography>
                    <Typography
                        variant="h4"
                        color="textSecondary"
                        component="p"
                    >
                        Para iniciar o sistema é necessário selecionar um
                        cliente no menu superior.
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={sair}>
                    Voltar para o portal
                </Button>
                <Button size="small" color="primary" onClick={sair}>
                    Sair
                </Button>
            </CardActions>
        </Card>
    )
}
