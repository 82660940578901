import React, { useState, useEffect } from 'react'
import { Button, TextField, Divider, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { useForm } from 'react-hook-form'
import FormaPagamentoService from '../../../services/TipoPagamentoRecebimentoService'
import ContaBancariaService from '../../../services/ContaBancariaService'
import CamposDinamicosService from '../../../services/CamposParcelaService'
import DespesaReceitaService from '../../../services/ContaPagarReceberService'
import ToastHelper from '../../../../src/utils/toastHelper'
import ObjectHelper from '../../../../src/utils/objetcHelper'
import DateHelper from '../../../../src/utils/dataHelpder'
import moment from 'moment'
import AdiantamentoService from 'services/AdiantamentoFornecedoresClientesService'
import DataHelper from '../../../../src/utils/dataHelpder'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 100,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    valorTotal: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    hidden: {
        display: 'none',
    },
}))

export default function ModalQuitarContas(props) {
    const classes = useStyles()

    const [opcoesFormaPagamento, setOpcoesFormaPagamento] = useState([])
    const [opcoesAdiantamento, setOpcoesAdiantamento] = useState([])
    const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState()
    const [opcoesConta, setOpcoesConta] = useState([])
    const [camposDinamicos, setCamposDinamicos] = useState([])
    const [FazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : null
    )

    const [erroDataPagamento, setErroDataPagamento] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroDataCompensacao, setErroDataCompensacao] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroFormaPagamento, setErroFormaPagamento] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroConta, setErroConta] = useState({
        erro: false,
        mensagem: '',
    })

    const [erroAdiantamento, setErroAdiantamento] = useState({
        erro: false,
        mensagem: '',
    })

    const [dataPagamento, setDataPagamento] = useState()
    const [dataCompensacao, setDataCompensacao] = useState()
    const [valorDocumento, setValorDocumento] = useState()
    const [contaSelecionada, setContaSelecionada] = useState()
    const [adiantamentoSelecionado, setAdiantamentoSelecionado] = useState()
    const [valorTotal, setValorTotal] = useState()
    const [observacao, setObservacao] = useState()
    const [contasSelecionadas, setContasSelecionadas] = useState([])
    const [saldoAdiantamento] = useState(0)
    const [saldoAdiantamentoPosQuitacao, setSaldoAdiantamentoPosQuitacao] =
        useState(0)
    const [numeroDocumento, setNumeroDocumento] = useState()
    const [conciliando, setConciliando] = useState(false)
    const [camposDinamicosValues, setCamposDinamicosValues] = useState([])
    const [formaPagamentoLabel, setFormaPagamentoLabel] = useState("")
    const [camposDinamicosLista, setCamposDinamicosLista] = useState([])

    const { handleSubmit, watch } = useForm({})

    useEffect(() => {
        const fornecedorClienteSelecionado = props.contasSelecionadas[0].fornecedorCliente;
        const fornecedorClienteId = fornecedorClienteSelecionado.id;
        const isFornecedor = fornecedorClienteSelecionado.tipoFornecedorCliente === 0 ? true : false
        const isCliente = fornecedorClienteSelecionado.tipoFornecedorCliente === 1 ? true : false

        AdiantamentoService.buscarFornecedorClientePorId(fornecedorClienteId).then((res) => {
            if (res.data.length > 0) {
                if (isFornecedor) {
                    ToastHelper.info("Você tem adiantamento(s) cadastrado(s) para esse fornecedor!")
                }

                if (isCliente) {
                    ToastHelper.info("Você tem adiantamento(s) cadastrado(s) para esse cliente!")
                }
            }
        })

        async function init() {
            await buscarCamposDinamicos()
            await buscarContas()
            await setarContasSelecionadasParaPagamento()
            await buscarFormasPagamento()
        }
        init()
        if (props.conciliacao !== undefined) {
            setConciliando(true)
            if (props.conciliacao.length === 1) buscarParaConciliacao()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatStringDate = (dateRcv) => {
        let dayRcv = dateRcv.split('/')[0]
        dayRcv = ('0' + dayRcv).slice(-2)
        let monthRcv = dateRcv.split('/')[1]
        monthRcv = ('0' + monthRcv).slice(-2)
        const yearRcv = dateRcv.split('/')[2]

        return `${yearRcv}-${monthRcv}-${dayRcv}`
    }

    useEffect(() => {
        if (props.contasSelecionadas.length > 0) {
            let valorContas = 0
            let valorTotal = 0
            let dates = []

            props.contasSelecionadas.forEach((parcela) => {
                dates.push(formatStringDate(parcela.dataVencimento))
                dates.sort((a, b) => (a > b ? 1 : -1))
                let stringValor = parcela.valor
                    .replace('R$ ', '')
                    .replace(/\./g, '')
                    .replace(',', '.')

                valorContas = valorContas + parseFloat(stringValor)
                valorTotal = valorContas

                if (
                    props.contasSelecionadas.length === 1 &&
                    parcela?.camposDinamicos
                ) {
                    parcela.camposDinamicos.forEach((campo) => {
                        if (campo.tipoCodigo === 0) {
                            valorTotal -= campo.valor

                            return
                        }

                        valorTotal += campo.valor
                    })
                }
            })

            setDataPagamento(dates[0])
            setValorDocumento(
                valorContas.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })
            )

            setValorTotal(
                valorTotal.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModalQuitarContas])

    useEffect(() => {
        async function init() {
            let vTotal = valorTotal
                ? parseFloat(
                      valorTotal
                          .replace('R$', '')
                          .replaceAll('.', '')
                          .replaceAll(',', '.')
                  )
                : 0
            setSaldoAdiantamentoPosQuitacao(saldoAdiantamento - vTotal)
        }
        init()
    }, [valorTotal, saldoAdiantamento])

    const formatarAdiantamento = (adiantamento) => {
        const data = DataHelper.transformStringDataUsaToPtBr(adiantamento.dataCriacao);
        const saldo = adiantamento.saldo.toLocaleString(
            'pt-br',
            {
                style: 'currency',
                currency: 'BRL',
            }
        )
        return {
            ...adiantamento,
            value:adiantamento.id,
            label:`${adiantamento.documento} - ${data} - ${saldo}`
        }
    }

    useEffect(() => {
        const buscarAdiantamentos = async () => {
            const tipo = props.tipo === 'pagar' ? 1 : 0
            const valor = valorTotal ? ObjectHelper.convertCurrencyToFloat(valorTotal) : 0

            const filtro = {
                fornecedorClienteId:
                    props.contasSelecionadas[0].fornecedorCliente.id,
                valor: valor,
                creditoDebito :tipo,
            }
            let params = await ObjectHelper.createParams(filtro)
            await AdiantamentoService.BuscarPagoPorFornecedorCliente(params).then(
                (response) => {
                    var adiantamentos = response.data.result.map((item) => formatarAdiantamento(item));
                    setOpcoesAdiantamento(adiantamentos)
                }
            )
        }
        buscarAdiantamentos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formaPagamentoSelecionada,valorTotal])



    /*const buscarAdiantamentos = async (opcoesAtuaisDePagamento) => {
        if (props.contasSelecionadas.length > 1) {
            let contaAnterior
            let valid = true
            await props.contasSelecionadas.forEach((conta) => {
                if (
                    contaAnterior &&
                    contaAnterior.fornecedorCliente.id !==
                        conta.fornecedorCliente.id
                ) {
                    valid = false
                }
                contaAnterior = conta
            })
            if (valid) {
                await buscarSaldoAdiantamento(
                    props.contasSelecionadas[0].fornecedorCliente.id,
                    opcoesAtuaisDePagamento
                )
            }
        } else {
            await buscarSaldoAdiantamento(
                props.contasSelecionadas[0].fornecedorCliente.id,
                opcoesAtuaisDePagamento
            )
        }
    }

    const buscarSaldoAdiantamento = async (
        idFornecedorCliente,
        opcoesAtuaisDePagamento
    ) => {
        await AdiantamentoService.buscarSaldoAdiantamentoPorFornecedorClienteId(
            idFornecedorCliente
        )
            .then((response) => {
                if (response.data.isOk)
                    if (response.data.result.temAdiantamento) {
                        let newOpcoesFormaPagamento = [
                            ...opcoesAtuaisDePagamento,
                        ]
                        newOpcoesFormaPagamento.push({
                            label: 'Adiantamento',
                            value: 'ADIANTAMENTO',
                            codigo: 'ADIANTAMENTO',
                        })
                        setOpcoesFormaPagamento(newOpcoesFormaPagamento)
                        setSaldoAdiantamento(response.data.result.saldo)
                    }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar contratos')
            })
    }*/

    const setarContasSelecionadasParaPagamento = async () => {
        props.contasSelecionadas.length > 0
            ? setContasSelecionadas(props.contasSelecionadas)
            : setContasSelecionadas([])
    }

    const buscarCamposDinamicos = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId
        filtro.ativo = true
        filtro.AplicadoContasPagarReceber = props.tipo
        let params = await ObjectHelper.createParams(filtro)
        await CamposDinamicosService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setCamposDinamicos(response.data.itens)
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarFormasPagamento = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        await FormaPagamentoService.buscarTudo(params).then(
            async (response) => {
                if (response.status === 200) {
                    await setOpcoesFormaPagamento(
                        ObjectHelper.getValuesFormaPagamentoParseToSelect(
                            response.data.itens
                        )
                    )
                    /*await buscarAdiantamentos(
                        ObjectHelper.getValuesFormaPagamentoParseToSelect(
                            response.data.itens
                        )
                    )*/
                } else {
                    ToastHelper.error(response.statusText)
                }
            }
        )
    }

    const buscarContas = async () => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = FazendaId
        filtroParaPesquisa.Ativo = true
        filtroParaPesquisa.tamanhoPagina = 9999999
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        await ContaBancariaService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setOpcoesConta(
                    ObjectHelper.getValuesDescricaoLabelParseToSelect(
                        response.data.itens
                    )
                )
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const limpaErros = () => {
        setErroDataCompensacao({
            erro: false,
            mensagem: '',
        })
        setErroDataPagamento({
            erro: false,
            mensagem: '',
        })
        setErroFormaPagamento({
            erro: false,
            mensagem: '',
        })
        setErroConta({
            erro: false,
            mensagem: '',
        })
    }

    const validaCampos = () => {
        let valido = true
        if(adiantamentoSelecionado &&
            adiantamentoSelecionado.saldo < ObjectHelper.convertCurrencyToFloat(valorTotal)) {
                ToastHelper.error(
                    'Saldo do adiantamento insuficiente para pagar a conta.'
                )
                valido = false
            }
        const contas = props.contasSelecionadas
        const checarFornecedorCliente = !contas.every((item) => item.fornecedorCliente.id === contas[0].fornecedorCliente.id);
        if(checarFornecedorCliente && formaPagamentoSelecionada.codigo === 'AD') {
            ToastHelper.error(
                'Forma de pagamento indísponível para fornecedores/clientes diferentes.'
            )
            valido = false
        }
        if (
            formaPagamentoSelecionada &&
            formaPagamentoSelecionada.codigo === 'ADIANTAMENTO' &&
            saldoAdiantamentoPosQuitacao < 0
        ) {
            ToastHelper.error(
                'Saldo de adiantamento insuficiente para o pagamento.'
            )
            valido = false
        }

        if (!dataPagamento) {
            setErroDataPagamento({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            var partesData = dataPagamento.split('-')
            var data = new Date(partesData[0], partesData[1] - 1, partesData[2])
            if (data > new Date()) {
                ToastHelper.error(
                    'Data de pagamento não pode ser maior que a data atual.'
                )
                valido = false
            }
            setErroDataPagamento({
                erro: false,
                mensagem: '',
            })
        }

        if (!formaPagamentoSelecionada) {
            setErroFormaPagamento({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroFormaPagamento({
                erro: false,
                mensagem: '',
            })

            if (
                formaPagamentoSelecionada.codigo === 'AD' &&
                !adiantamentoSelecionado
            ) {
                setErroAdiantamento({
                    erro: true,
                    mensagem: 'Campo obrigatório',
                })
                valido = false
            } else {
                setErroAdiantamento({
                    erro: false,
                    mensagem: '',
                })
            }
        }

        if (!contaSelecionada && formaPagamentoLabel !== "Adiantamento") {
            setErroConta({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroConta({
                error: false,
                mensagem: '',
            })
        }
        var dataAtual = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')

        if (dataCompensacao) {
            if (dataCompensacao < dataPagamento) {
                setErroDataCompensacao({
                    erro: true,
                    mensagem:
                        'Data de compensação não pode ser menor que a data de pagamento! ',
                })
                valido = false
            } else if (dataCompensacao > dataAtual) {
                setErroDataCompensacao({
                    erro: true,
                    mensagem:
                        'Data de compensação não pode ser maior que data atual!',
                })
                valido = false
            } else {
                setErroDataCompensacao({
                    erro: false,
                    mensagem: '',
                })
            }
        }

        return valido
    }

    const submit = async () => {
        if (props.conciliacao) {
            if (!dataCompensacao) {
                return setErroDataCompensacao({
                    erro: true,
                    mensagem: 'Campo Obrigatório',
                })
            }
            var params = []
            props.conciliacao.forEach((item) => {
                params.push({
                    id: item.id,
                    dataCompensacao:
                        DateHelper.transformStringDataUsaToPtBr(
                            dataCompensacao
                        ),
                })
            })
            DespesaReceitaService.atualizarDataCompensacao(params)
                .then((res) => {
                    if (res.status === 200) {
                        ToastHelper.success('Operação realizada com sucesso')
                        props.close()
                        props.buscarContas()
                    } else {
                        ToastHelper.error(res.statusText)
                    }
                    props.mudaContasSelecionadas([])
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data.message)
                })
        } else {
            if (validaCampos()) {
                let idContasSelecionadas = []
                contasSelecionadas.forEach((conta) => {
                    idContasSelecionadas.push(conta.id)
                })

                let valoresCamposDinamicos = []
                await camposDinamicos.forEach((item) => {
                    const values = {}

                    camposDinamicosLista.map(x => values[x.campo] = x.valor)

                    for (var [campo, valor] of Object.entries(values)) {
                        if (
                            campo
                                .replace('(-)', '')
                                .replace('(+)', '')
                                .toLowerCase() === item.nome.toLowerCase()
                        ) {
                            valoresCamposDinamicos.push({
                                Id: item.id,
                                Valor: parseFloat(
                                    valor.replace(',', '.') || 0
                                ).toFixed(2),
                            })
                        }
                    }
                })

                let quitacao = {
                    adiantamentoFornecedorClienteId:adiantamentoSelecionado ? adiantamentoSelecionado.id : null,
                    dataPagamento: dataPagamento,
                    dataCompensacao: dataCompensacao ? dataCompensacao : null,
                    situacao: dataCompensacao ? 'Conciliado' : 'Pago',
                    valorDocumento: parseFloat(
                        valorDocumento
                            .replace('R$', '')
                            .replaceAll('.', '')
                            .replaceAll(',', '.')
                    ),
                    valorTotal: parseFloat(
                        valorTotal
                            .replace('R$', '')
                            .replaceAll('.', '')
                            .replaceAll(',', '.')
                    ),
                    formaPagamento: formaPagamentoSelecionada
                        ? formaPagamentoSelecionada.value
                        : '',
                    conta: contaSelecionada ? contaSelecionada.value : '',
                    numeroDocumento: numeroDocumento,
                    camposDinamicos: valoresCamposDinamicos,
                    observacao: observacao,
                    idContasSelecionadas: idContasSelecionadas,
                }

                await DespesaReceitaService.quitarContas(quitacao)
                    .then(async (response) => {
                        limpaErros()
                        ToastHelper.success('Operação realizada com sucesso.')
                        await props.buscarContas()
                        props.close()
                        props.mudaContasSelecionadas([])
                    })
                    .catch((error) => {
                        ToastHelper.error('Erro ao tentar pagar conta(s).')
                    })
            }
        }
    }

    const atualizaValorTotal = (valorDoc) => {
        let novoValorTotal = valorDoc ? parseFloat(valorDoc) : valorDocumento
        novoValorTotal = novoValorTotal
            .replaceAll('R$', '')
            .replaceAll('.', '')
            .replace(',', '.')
        if (contasSelecionadas.length === 1) {
            const values = {}

            camposDinamicosLista.map(x => values[x.campo] = x.valor)

            for (var [campo, valor] of Object.entries(values)) {
                if (campo.indexOf('(-)') !== -1) {
                    if (valor) {
                        novoValorTotal =
                            novoValorTotal - valor.replace(',', '.')
                    }
                } else {
                    if (valor) {
                        novoValorTotal =
                            parseFloat(novoValorTotal) +
                            parseFloat(valor.replace(',', '.'))
                    }
                }
            }
        }
        setValorTotal(
            parseFloat(novoValorTotal).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        )
    }

    const buscarParaConciliacao = async () => {
        //Busca conciliação se tiver apenas uma conta selecionada
        var row = props.conciliacao[0]
        if (row) {
            setDataPagamento(row?.dataPagamento)
            setFormaPagamentoSelecionada({
                label: row?.formaPagamento?.nome,
                value: '',
                codigo:row?.formaPagamento?.nome === 'Adiantamento' ? 'AD': ''
            })
            setContaSelecionada({
                label: row?.contaBancariaPagamento?.descricao,
            })
            setNumeroDocumento(row?.documento)
            setObservacao(row?.descricao)
            setCamposDinamicosValues(row?.camposDinamicos?.map((item) => {
                return {
                    ...item,
                    valor: item.valor.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }
            }))

            if(row.adiantamentoFornecedorCliente) {
                setAdiantamentoSelecionado(formatarAdiantamento(row.adiantamentoFornecedorCliente))
            }
        }
    }

    const valorSaldo = (adiantamento) => {
        const valor = adiantamento ? adiantamento.saldo : 0;
        return valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
        })
    }

    const toCurrency = (valorMonetario) => {
        const valorNumerico = valorMonetario?.replace(/[^0-9,-]/g, '');

        const valorDecimal = valorNumerico?.replace(',', '.');

        return parseFloat(valorDecimal);
    }

    return (
        <>
            <div className={classes.paper}>
                <h2 className={classes.title}>
                    {conciliando
                        ? 'Conciliação de contas'
                        : 'Pagamento de contas'}
                </h2>

                <form className={classes.form} onSubmit={handleSubmit(submit)}>
                    <Divider className={classes.divider} />
                    <Grid container item xs={12} display="flex">
                        {!conciliando || props.conciliacao.length === 1 ? (
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        disabled={conciliando}
                                        label="Data de Pagamento:*"
                                        name="dataPagamento"
                                        type={conciliando ? 'text' : 'date'}
                                        value={dataPagamento}
                                        onChange={(e) =>
                                            setDataPagamento(e.target.value)
                                        }
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: { max: '9999-12-31' },
                                        }}
                                    />
                                    <p className={classes.errors}>
                                        {erroDataPagamento.erro
                                            ? erroDataPagamento.mensagem
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        ) : (
                            ''
                        )}

                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    label={'Data de Compensação:'}
                                    name="dataCompensacao"
                                    type="date"
                                    value={dataCompensacao}
                                    onChange={(e) =>
                                        setDataCompensacao(e.target.value)
                                    }
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { max: '9999-12-31' },
                                    }}
                                />
                                <p className={classes.errors}>
                                    {erroDataCompensacao.erro
                                        ? erroDataCompensacao.mensagem
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid
                            className={
                                props.conciliacao?.length > 1
                                    ? classes.hidden
                                    : classes.valorTotal
                            }
                            container
                            item
                            xs={3}
                            style={{ marginRight: '2%', marginTop: '2%' }}
                        >
                            <FormControl
                                className={classes.formControl}
                                display="inline-flex"
                            >
                                <div
                                    className={classes.valorTotal}
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {contasSelecionadas.length > 1
                                            ? '(=) Valor dos documentos: '
                                            : '(=) Valor do documento: '}
                                    </h4>
                                    <span>{valorDocumento}</span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    {camposDinamicos.length > 0 &&
                        contasSelecionadas.length === 1 && (
                            <>
                                <Grid container item xs={12} display="flex">
                                    {camposDinamicos.map((item, index) => {
                                        const simbolo = item.tipoCodigo === 0 ? '(-)' : '(+)'

                                        const campo = `${simbolo}${item.nome.toLowerCase()}`

                                        if (camposDinamicosLista[index]?.campo !== campo) {
                                            const campoDinamico = {
                                                campo,
                                                valor: "0"
                                            }

                                            setCamposDinamicosLista(
                                                x => [...x, campoDinamico]
                                            )
                                        }

                                        return (
                                            <Grid
                                                container
                                                item
                                                xs={4}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        props.conciliacao?.length >
                                                        1
                                                            ? classes.hidden
                                                            : classes.formControl
                                                    }
                                                >
                                                    <NumberFormat
                                                        prefix={'R$ '}
                                                        disabled={conciliando}
                                                        label={`${simbolo} ${item.nome}`}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        decimalScale={2}
                                                        autoComplete='off'
                                                        fixedDecimalScale={2}
                                                        customInput={TextField}
                                                        value={
                                                            camposDinamicosValues.find(
                                                                (x) => x.campoParcelaId === item.id
                                                            )?.valor || watch(`${simbolo}${item.nome.toLowerCase()}`)
                                                        }
                                                        onValueChange={({ value }) => {
                                                            const campo =`${simbolo}${item.nome.toLowerCase()}`

                                                            const novaLista = camposDinamicosLista.map((x) => {
                                                                if (x.campo === campo) {
                                                                    x.valor = value.toString()

                                                                    return x
                                                                }

                                                                return x
                                                            })

                                                            setCamposDinamicosLista(novaLista)

                                                            atualizaValorTotal()
                                                            setAdiantamentoSelecionado(null)
                                                        }}
                                                        isplaceholderanimated={(false, 'top')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </>
                        )}
                    <Grid
                        className={
                            props.conciliacao?.length > 1
                                ? classes.hidden
                                : classes.formControl
                        }
                        container
                        item
                        xs={12}
                        display="inline-flex"
                    >
                        <Grid
                            className={
                                props.conciliacao?.length > 1
                                    ? classes.hidden
                                    : classes.valorTotal
                            }
                            container
                            item
                            xs={3}
                            style={{ marginRight: '2%' }}
                        >
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                                display="inline-flex"
                            >
                                <div
                                    className={
                                        props.conciliacao?.length > 1
                                            ? classes.hidden
                                            : classes.valorTotal
                                    }
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {'(=) Valor Total: '}
                                    </h4>
                                    <span>
                                        {
                                            toCurrency(valorTotal) <= 0 ? 'R$ 0,00' : (
                                                <>
                                                    {valorTotal?.toLocaleString('pt-br', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    })}
                                                </>
                                            )
                                        }
                                    </span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />

                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={4} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                            >
                                <Autocomplete
                                    options={opcoesFormaPagamento}
                                    disableClearable
                                    disabled={conciliando}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, option) => {
                                        setAdiantamentoSelecionado(null)
                                        setFormaPagamentoSelecionada(option)
                                        setFormaPagamentoLabel(option.label)
                                    }}
                                    value={formaPagamentoSelecionada || {}}
                                    defaultValue={
                                        formaPagamentoSelecionada
                                            ? formaPagamentoSelecionada
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Forma de Pagamento:*'}
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {erroFormaPagamento.erro
                                        ? erroFormaPagamento.mensagem
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                            >
                                <Autocomplete
                                    options={opcoesConta}
                                    disabled={conciliando}
                                    disableClearable
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, option) => {
                                        setContaSelecionada(option)
                                    }}
                                    value={contaSelecionada || {}}
                                    defaultValue={
                                        contaSelecionada ? contaSelecionada : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Conta:*'}
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {erroConta.erro ? erroConta.mensagem : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                            >
                                <TextField
                                    type="number"
                                    name="numeroDocumento"
                                    disabled={conciliando}
                                    id="numeroDocumento"
                                    variant="standard"
                                    autoComplete="off"
                                    label={
                                        formaPagamentoSelecionada &&
                                        formaPagamentoSelecionada.codigo ===
                                            'CHEQUE'
                                            ? 'Nº do cheque:'
                                            : 'Nº do documento:'
                                    }
                                    value={numeroDocumento}
                                    onChange={(e) =>
                                        setNumeroDocumento(e.target.value)
                                    }
                                    placeholder="Digite o numero"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {formaPagamentoSelecionada &&
                        formaPagamentoSelecionada.codigo === 'AD' && (
                            <>
                                <Grid container item xs={12} display="flex">
                                    {/*<Grid container item xs={12} display="flex">
                                        <span
                                            style={{
                                                marginLeft: '10px',
                                            }}
                                        >
                                            {'Detalhes do adiantamento'}
                                        </span>
                                        </Grid>*/}
                                    <Grid container item xs={4} display="flex">
                                        <FormControl
                                            className={
                                                props.conciliacao?.length > 1
                                                    ? classes.hidden
                                                    : classes.formControl
                                            }
                                        >
                                            <Autocomplete
                                                options={opcoesAdiantamento}
                                                disabled={conciliando}
                                                disableClearable
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                onChange={(event, option) => {
                                                    setAdiantamentoSelecionado(
                                                        option
                                                    )
                                                }}
                                                value={
                                                    adiantamentoSelecionado ||
                                                    {}
                                                }
                                                defaultValue={
                                                    adiantamentoSelecionado
                                                        ? adiantamentoSelecionado
                                                        : ''
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={'Adiantamento:*'}
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className={classes.errors}>
                                                {erroAdiantamento.erro
                                                    ? erroAdiantamento.mensagem
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={4} display="flex">
                                        <FormControl
                                            className={
                                                props.conciliacao?.length > 1
                                                    ? classes.hidden
                                                    : classes.formControlTextArea
                                            }
                                        >
                                            <div
                                                className={
                                                    props.conciliacao?.length >
                                                    1
                                                        ? classes.hidden
                                                        : classes.valorTotal
                                                }
                                                display="inline-flex"
                                            >
                                                <h5
                                                    style={{
                                                        marginRight: '15px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {'Saldo: '}
                                                </h5>
                                                <span
                                                    style={{
                                                        color:
                                                            adiantamentoSelecionado &&
                                                            adiantamentoSelecionado.saldo >=
                                                                ObjectHelper.convertCurrencyToFloat(
                                                                    valorTotal
                                                                )
                                                                ? '#2E7D32'
                                                                : 'red',
                                                    }}
                                                >
                                                    {valorSaldo(
                                                        adiantamentoSelecionado
                                                    )}
                                                </span>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid container item xs={8} display="flex">
                                        <FormControl
                                            className={
                                                props.conciliacao?.length > 1
                                                    ? classes.hidden
                                                    : classes.formControlTextArea
                                            }
                                        >
                                            <div
                                                className={
                                                    props.conciliacao?.length >
                                                    1
                                                        ? classes.hidden
                                                        : classes.valorTotal
                                                }
                                                display="inline-flex"
                                            >
                                                <h5
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    {
                                                        '(=) Saldo Adiantamento pós pagamento: '
                                                    }
                                                </h5>
                                                <span
                                                    style={
                                                        saldoAdiantamentoPosQuitacao >
                                                        0
                                                            ? {
                                                                  color: '#2E7D32',
                                                              }
                                                            : { color: 'red' }
                                                    }
                                                >
                                                    {saldoAdiantamentoPosQuitacao.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        }
                                                    )}
                                                </span>
                                                <p
                                                    className={classes.errors}
                                                    style={{
                                                        marginLeft: '15px',
                                                    }}
                                                >
                                                    {saldoAdiantamentoPosQuitacao <
                                                    0
                                                        ? 'Saldo de adiantamento insuficiente'
                                                        : ''}
                                                </p>
                                            </div>
                                        </FormControl>
                                                    </Grid>*/}
                                </Grid>
                                <Divider className={classes.divider} />
                            </>
                        )}
                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={12} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControlTextArea
                                }
                            >
                                <TextField
                                    type="text"
                                    label="Observações"
                                    disabled={conciliando}
                                    className={classes.textField}
                                    id="observacao"
                                    value={observacao}
                                    defaultValue={observacao}
                                    onChange={(e) =>
                                        setObservacao(e.target.value)
                                    }
                                    name="observacao"
                                    multiline
                                    variant="outlined"
                                    rows={2}
                                    isplaceholderanimated={(false, 'top')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.close()
                                limpaErros()
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="inherit"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Cadastrar
                        </Button>
                    </Grid>
                </form>
            </div>
        </>
    )
}
