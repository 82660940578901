import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CreateIcon from '@material-ui/icons/Create'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ObjectHelper from 'utils/objetcHelper'
import ToastHelper from 'utils/toastHelper'
import UnidadeDeMedidaService from 'services/UnidadeDeMedidaService'
import { UnidadesListagem } from './unidades.listagem'
import Page from 'components/Page'
import { Container, Box } from '@material-ui/core'
import Titulo from 'components/Titulo'
import Toolbar from 'components/BasicPageTopBar/Toolbar'

export const UnidadesDeMedida = () => {
    const actions = [
        {
            tooltip: 'Visualizar',
            icon: VisibilityIcon,
            onClick: (_, { id }) => visualizar(id),
        },
        {
            tooltip: 'Editar',
            icon: CreateIcon,
            onClick: (_, { id }) => editar(id),
        },
    ]

    const history = useHistory()
    const dispatch = useDispatch()
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))?.value ?? ''
    )
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.unidades.filtroListagem)
    )
    const [unidades, setUnidades] = useState()

    const buscarUnidades = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId

        await alteraFiltro(filtroParaPesquisa)
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await UnidadeDeMedidaService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtroParaPesquisa.tamanhoPagina
                        ? filtroParaPesquisa.tamanhoPagina
                        : 20
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    alteraFiltro(newFiltro)
                    setUnidades(response.data.itens)
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                    return
                }

                ToastHelper.error(error)
            })
            .finally(() => setLoading(false))
    }

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_UNIDADES',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = async (filtro) => {
        await buscarUnidades(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_UNIDADES',
            filtroListagem: filtro,
        })
    }

    const novo = () => {
        history.push('/app/unidades/cadastro')
    }

    const editar = (id) => {
        history.push(`/app/unidades/editar/${id}`)
    }

    const visualizar = (id) => {
        history.push(`/app/unidades/visualizar/${id}`)
    }

    const excluir = async (id) => {
        setLoading(true)
        UnidadeDeMedidaService.deletar(id)
            .then((response) => {
                if (response.status === 204) {
                    ToastHelper.success(
                        'Unidade de medida excluída com sucesso!'
                    )
                    buscarUnidades(filtro)
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                    return
                }

                ToastHelper.error(error)
            })
            .finally(() => setLoading(false))
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        alteraFiltro(novoFiltro)
        await buscarUnidades(novoFiltro)
    }

    const onChangeNumberItens = (number) => {
        setFiltro({ ...filtro, tamanhoPagina: number })
        handleFiltro({ ...filtro, tamanhoPagina: number })
    }

    useEffect(() => {
        const buscar = async () => {
            let filtroParaPesquisa = { ...filtro }
            filtroParaPesquisa.fazendaId = fazendaId
            filtroParaPesquisa.tamanhoPagina = 20
            filtroParaPesquisa.decrescente = false
            filtroParaPesquisa.ordem = 'sigla'

            await buscarUnidades(filtroParaPesquisa)
        }

        buscar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Page title="Unidades de Medida">
                <Container maxWidth={'xl'}>
                    <Titulo titulo="Unidades de Medida" />
                    <Toolbar
                        filtroInicial={filtro}
                        clickadd={novo}
                        handleFiltro={handleFiltro}
                        filtroAtivo
                    />
                    <Box mt={3}>
                        <UnidadesListagem
                            data={unidades}
                            actions={actions}
                            filtro={filtro}
                            loading={loading}
                            onChangePage={onChangePage}
                            editable={{
                                onRowDelete: ({ id }) => excluir(id),
                            }}
                            onChangeNumberItens={onChangeNumberItens}
                        />
                    </Box>
                </Container>
            </Page>
        </>
    )
}
