import * as Yup from 'yup'

export const CentroCustoEsquema = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    codigo: Yup.string().required('Campo obrigatório'),
    tipo: Yup.string().required('Campo obrigatório'),
})

export const CentroCustoRateioEsquema = Yup.object().shape({
    percentual: Yup.string().required('Campo obrigatório'),
    dataInicial: Yup.string().required('Campo obrigatório'),
    centroCustoSelecionado: Yup.string().required('Campo obrigatório'),
})
