import React from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
}))

export default function FooterButtons({ route, disableSubmit }) {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Grid container item xs={12} display="flex" className={classes.card}>
            <Grid xs={6} container item direction="row" alignItems="center">
                <Button
                    size="small"
                    variant="contained"
                    color="default"
                    onClick={() => {
                        route ? history.push(route) : history.goBack()
                    }}
                >
                    Voltar
                </Button>
            </Grid>
            <Grid
                xs={6}
                container
                item
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                {!disableSubmit && (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                    >
                        Salvar
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}
