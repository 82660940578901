import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Backdrop, Grid, TextField, FormControl, Button, makeStyles }from '@material-ui/core';
import { schema as schemaValidate } from './validate/ModalCadastroProdutoTecnico.validate';
import { yupResolver } from '@hookform/resolvers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect } from 'react';
import gruposInsumoService from 'services/tecnico/gruposInsumoService';
import UnidadeDeMedidaService from 'services/UnidadeDeMedidaService';
import ObjectHelper from 'utils/objetcHelper';

export default function ModalCadastroProdutoTecnico(props) {

    const classes = useStyles()

    const [gruposProduto, setGruposProduto] = useState([]);
    const [unidades, setUnidades] = useState([])

    const [grupoProduto, setGrupoProduto] = useState({})
    const [unidade, setUnidade] = useState({})

    const { register, handleSubmit, setValue, getValues, errors, clearErrors} = useForm({
        defaultValues: {
            nome: "",
            codigo: "",
            grupoProduto: "",
            unidade: "",
        },
        resolver: yupResolver(schemaValidate),
    })

    const onSubmit = () => {
        var values = getValues()
        values.unidadeId = unidade.value;
        values.familiaMateriaisId = grupoProduto.value;
        props.submit(values)
    }

    useEffect(() => {

        const getGruposProduto = () => {
            var filter = {
                descedescending: false,
                fazendaId: JSON.parse(localStorage.getItem('fazenda')).value ?? '',
                filtro: "",
                order: "Nome",
                page: 1,
                pageSize: 99999
            }
            gruposInsumoService.buscarGruposInsumo(filter).then(res =>  {
                var result = [];
                var data = res.data.result.items;
                data = Array.isArray(data) ? data.filter(i => !i.permiteItensDeProducao && !i.permiteReceita) : [];
                data.forEach(gp => {
                    result.push({value: gp.id, label: gp.nome})
                })
                setGruposProduto(result);
            })
        }

        const buscarUnidadesDeMedida = async (nome) => {
            let novoFiltro = {}
            novoFiltro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value ?? ''
            novoFiltro.ordem = 'Sigla'
            novoFiltro.tamanhoPagina = 99999
            let params = await ObjectHelper.createParams(novoFiltro)
            UnidadeDeMedidaService.buscarTudo(params).then((response) => {
                let unidadesDeMedida = ObjectHelper.parseValuesUnidadesToSelect(
                    response.data.itens
                )
                setUnidades(unidadesDeMedida)
            })
        }

        getGruposProduto();
        buscarUnidadesDeMedida();

    },[])

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
                <div className={classes.paper}>


                        <h2 className={classes.title}>Cadastro de Produto Técnico</h2>

                        <Grid container item xs={12} display="flex">

                            <Grid container item xs={12}>
                                <Grid container item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            inputRef={register}
                                            id="nome"
                                            name="nome"
                                            variant="standard"
                                            label="Nome*"
                                            placeholder="Digite o nome"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.nome
                                                ? errors.nome.message
                                                : ''}
                                        </p>

                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            inputRef={register}
                                            id="codigo"
                                            name="codigo"
                                            variant="standard"
                                            label="Código*"
                                            placeholder="Digite o código"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.codigo
                                                ? errors.codigo.message
                                                : ''}
                                        </p>

                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item xs="12">
                                <Grid container item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <Autocomplete
                                            id="grupoProduto"
                                            name="grupoProduto"
                                            options={gruposProduto}
                                            getOptionLabel={(option) =>
                                                option
                                                    ? option.label
                                                    : ''
                                            }
                                            size="small"
                                            onChange={(_, data) => {
                                                setValue("grupoProduto", data)
                                                clearErrors("grupoProduto")
                                                setGrupoProduto(data);
                                            }}
                                            getOptionSelected={(option,value) =>
                                                option?.value ===
                                                value?.value
                                            }
                                            renderInput={(
                                                params
                                            ) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    id="grupoProduto"
                                                    name="grupoProduto"
                                                    variant="standard"
                                                    label="Grupo do Produto*"
                                                    placeholder="Selecione o grupo do produto"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.grupoProduto
                                                ? errors.grupoProduto.message
                                                : ''}
                                        </p>

                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <Autocomplete
                                            id="unidade"
                                            name="unidade"
                                            options={unidades}
                                            getOptionLabel={(option) =>
                                                option
                                                    ? option.label
                                                    : ''
                                            }
                                            onChange={(_, data) => {
                                                setValue("unidade", data)
                                                clearErrors("unidade")
                                                setUnidade(data)
                                            }}
                                            size="small"
                                            getOptionSelected={(option,value) =>
                                                option?.value ===
                                                value?.value
                                            }
                                            renderInput={(
                                                params
                                            ) => (
                                                <TextField
                                                    inputRef={register}
                                                    {...params}
                                                    id="unidade"
                                                    name="unidade"
                                                    variant="standard"
                                                    label="Unidade*"
                                                    placeholder="Selecione a unidade padrão"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.unidade
                                                ? errors.unidade.message
                                                : ''}
                                        </p>

                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid
                                xs={12}
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.margin}
                            >
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    size="small"
                                    onClick={props.close}
                                >
                                    Cancelar
                                </Button>
                                <div className={classes.margin} />
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type='submit'
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Cadastrar
                                </Button>
                            </Grid>

                        </Grid>

                </div>
        </Modal>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlDescricao: {
        margin: theme.spacing(1),
        minWidth: '90%',
        marginBottom: theme.spacing(2)
    },
    formControlCategoria: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
    title: {
        color: '#2E7D32',
    },
}))
