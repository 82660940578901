import { RenderRoutes } from './RenderRoutes'
import { App } from '../pages/app'
import { AuthCallback } from '../AuthCallback'
import { AuthLogout } from '../AuthLogout'
import { EnsureAuthentication } from './EnsureAuthentication'
import { contaGerencialRoute } from './ContaGerencialRoute'
import { tipoDocumentoRoute } from './TipoDocumentoRoute'
import { contaBancariaRoute } from './ContaBancariaRoute'
import { fornecedorClienteRoute } from './FornecedorClienteRoute'
import { centroCustoRoute } from './CentroCustoRoute'
import { centroNegocioRoute } from './CentroNegocioRoute'
import { contaPagarReceberRoute } from './ContaPagarReceberRoute'
import { locaisEstoqueRoute } from './LocaisEstoqueRoute'
import { movimentoEstoqueRoute } from './MovimentoEstoqueRoute'
import { produtosServicosRoute } from './ProdutosServicosRoute'
import { TransferenciaBancariaRoute } from './TransferenciaBancariaRoutes'
import { TransferenciaEstoqueRoute } from './TransferenciaEstoqueRoute'
import { TipoPagamentoRecebimentoRoute } from './TipoPagamentoRecebimentoRoute'
import { TipoEstoqueRoute } from './TipoEstoqueRoute'
import { EstoqueRoute } from './EstoqueRoute'
import { CamposParcelaRoute } from './CamposParcelaRoute'
import { ImportacaoOrcamentoRoute } from './ImportacaoOrcamentoRoute'
import { ConciliacaoBancariaRoute } from './ConciliacaoBancariaRoute'
import { GestaoContratoRoute } from './GestaoContratoRoute'
import { PedidosRoute } from './PedidosRoute'
import { AdiantamentoFornecedorClienteRoute } from './AdiantamentoFornecedorClienteRoute'
import { PatrimonioRoute } from './PatrimonioRoute'
import { TipoPatrimonioRoute } from './TipoPatrimonioRoute'
import { FluxoCaixaRoute } from './FluxoCaixaRoute'
import { ExtratoBancarioRoute } from './ExtratoBancarioRoute'
import { DetalhesDeCustoRoute } from './DetalhesDeCustoRoute'
import { ProdutorRuralRoute } from './ProdutorRuralRoute'
import { UnidadesDeMedidaRoute } from 'routes/UnidadesDeMedidaRoute'
import NotFound from 'pages/404/404'

export const routes = [
    {
        path: '/404',
        key: 'NOT_FOUND',
        exact: true,
        component: NotFound,
    },
    {
        path: '/auth-callback',
        key: 'AUTH_CALLBACK',
        exact: true,
        component: AuthCallback,
    },
    {
        path: '/logout',
        key: 'AUTH_LOGOUT',
        exact: true,
        component: AuthLogout,
    },
    {
        path: '/app',
        key: 'APP',
        component: EnsureAuthentication,
        routes: [
            {
                path: '/app',
                key: 'APP_ROOT',
                exact: true,
                component: App,
            },
            {
                path: '/app/conciliacaoBancaria',
                key: 'APP_CONCILIACAO_BANCARIA',
                component: RenderRoutes,
                routes: ConciliacaoBancariaRoute,
            },
            {
                path: '/app/gestaocontrato',
                key: 'APP_GESTAO_CONTRATO',
                component: RenderRoutes,
                routes: GestaoContratoRoute,
            },
            {
                path: '/app/adiantamentoFornecedoresClientes',
                key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES',
                component: RenderRoutes,
                routes: AdiantamentoFornecedorClienteRoute,
            },
            {
                path: '/app/contaGerencial',
                key: 'APP_CONTA_GERENCIAL',
                component: RenderRoutes,
                routes: contaGerencialRoute,
            },
            {
                path: '/app/contaBancaria',
                key: 'APP_CONTA_BANCARIA',
                component: RenderRoutes,
                routes: contaBancariaRoute,
            },
            {
                path: '/app/tipoDocumento',
                key: 'APP_TIPO_DOCUMENTO',
                component: RenderRoutes,
                routes: tipoDocumentoRoute,
            },
            {
                path: '/app/fornecedorCliente',
                key: 'APP_FORNECEDOR_CLIENTE',
                component: RenderRoutes,
                routes: fornecedorClienteRoute,
            },
            {
                path: '/app/conta/',
                key: 'APP_CONTA',
                component: RenderRoutes,
                routes: contaPagarReceberRoute,
            },
            {
                path: '/app/transferenciaBancaria/',
                key: 'APP_TRANSFERENCIA_BANCARIA',
                component: RenderRoutes,
                routes: TransferenciaBancariaRoute,
            },
            {
                path: '/app/transferenciaEstoque/',
                key: 'APP_TRANSFERENCIA_ESTOQUE',
                component: RenderRoutes,
                routes: TransferenciaEstoqueRoute,
            },
            {
                path: '/app/tipopagamentorecebimento/',
                key: 'APP_PAGAMENTO_RECEBIMENTO',
                component: RenderRoutes,
                routes: TipoPagamentoRecebimentoRoute,
            },
            {
                path: '/app/tipoEstoque/',
                key: 'APP_TIPO_ESTOQUE',
                component: RenderRoutes,
                routes: TipoEstoqueRoute,
            },
            {
                path: '/app/Estoque/',
                key: 'APP_ESTOQUE',
                component: RenderRoutes,
                routes: EstoqueRoute,
            },
            {
                path: '/app/CamposParcela/',
                key: 'APP_CAMPOS_PARCELA',
                component: RenderRoutes,
                routes: CamposParcelaRoute,
            },
            {
                path: '/app/importacaoorcamento/',
                key: 'APP_IMPORTACAO_ORCAMENTO',
                component: RenderRoutes,
                routes: ImportacaoOrcamentoRoute,
            },
            {
                path: '/app/centroNegocio',
                key: 'APP_CENTRO_NEGOCIO',
                component: RenderRoutes,
                routes: centroNegocioRoute,
            },
            {
                path: '/app/centroCusto',
                key: 'APP_CENTRO_CUSTO',
                component: RenderRoutes,
                routes: centroCustoRoute,
            },
            {
                path: '/app/locaisEstoque',
                key: 'APP_LOCAIS_ESTOQUE',
                component: RenderRoutes,
                routes: locaisEstoqueRoute,
            },
            {
                path: '/app/movimentoEstoque',
                key: 'APP_MOVIMENTO_ESTOQUE',
                component: RenderRoutes,
                routes: movimentoEstoqueRoute,
            },
            {
                path: '/app/produtosServicos',
                key: 'APP_PRODUTOS_SERVICOS',
                component: RenderRoutes,
                routes: produtosServicosRoute,
            },
            {
                path: '/app/pedidos',
                key: 'APP_PEDIDOS',
                component: RenderRoutes,
                routes: PedidosRoute,
            },
            {
                path: '/app/patrimonio',
                key: 'APP_PEDIDOS',
                component: RenderRoutes,
                routes: PatrimonioRoute,
            },
            {
                path: '/app/tipoPatrimonio',
                key: 'APP_PEDIDOS',
                component: RenderRoutes,
                routes: TipoPatrimonioRoute,
            },
            {
                path: '/app/fluxoCaixa',
                key: 'APP_FLUXO_CAIXA',
                component: RenderRoutes,
                routes: FluxoCaixaRoute,
            },
            {
                path: '/app/extratoBancario',
                key: 'APP_EXTRATO_BANCARIO',
                component: RenderRoutes,
                routes: ExtratoBancarioRoute,
            },
            {
                path: '/app/detalhesDeCusto',
                key: 'APP_DETALHES_CUSTO',
                component: RenderRoutes,
                routes: DetalhesDeCustoRoute,
            },
            {
                path: '/app/produtorRural',
                key: 'APP_PRODUTOR_RURAL',
                component: RenderRoutes,
                routes: ProdutorRuralRoute,
            },
            {
                path: '/app/unidades',
                key: 'APP_UNIDADES',
                component: RenderRoutes,
                routes: UnidadesDeMedidaRoute,
            },
        ],
    },
]
