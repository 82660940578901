import React, { useState, useEffect } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import { MTablePagination } from 'material-table'
import Grid from '@material-ui/core/Grid'
import { Edit3 as Edit3Icon } from 'react-feather'
import MoneyOnIcon from '@material-ui/icons/AttachMoney'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import AddIcon from '@material-ui/icons/Add'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
// import theme from '../../../shared/mainThemeCss'
import Tooltip from '@material-ui/core/Tooltip'
import ConciliadoIcon from '@material-ui/icons/DoneAll'
import NConciliadoIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import ToastHelper from 'utils/toastHelper'
import { useHistory } from 'react-router-dom'
import SelectItensPage from 'components/SelectItensPage'

export default function ContaPagarReceberListagem(props) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#4caf50',
            },
            inherit: {
                main: '#2E7D32',
            },
        },
    })
    const optionsPage = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
    ]
    const [tableData, setTableData] = useState([])
    const history = useHistory()
    const selecionaSomenteEstaLinha = (row) => {
        var rows = []
        tableData.forEach((r) => {
            if (r.tableData.id === row.tableData.id) {
                r.tableData.checked = true
                rows.push(r)
            } else {
                r.tableData.checked = false
                rows.push(r)
            }
        })
        setTableData(rows)
    }

    const validateIsRemovable = (data) => {
        return data.dataCompensacao === null && data.dataPagamento === null ? true : false;
    }

    const colunas = [
        { title: 'Venc.', field: 'dataVencimento', editable: 'never' },
        { title: 'Parcela', field: 'parcela', editable: 'never' },
        {
            title: 'Fornecedor / Cliente',
            field: 'nomeFantasia',
            editable: 'never',
        },
        { title: 'Doc.', field: 'documento', editable: 'never' },
        { title: 'Descrição', field: 'descricao', editable: 'never' },
        {
            title: 'Situação',
            field: 'situacao',
            editable: 'never',
            render: (rowData) => {
                const returnSimple = () => {
                    switch (rowData.situacao) {
                        case 'Conciliado':
                            return 'CO'
                        case 'Pago':
                            return 'PG'
                        case 'Definitivo':
                            return 'DF'
                        default:
                            return 'Previsão'
                    }
                }
                return (
                    <div>
                        <Tooltip title={rowData.situacao}>
                            <p>{returnSimple()}</p>
                        </Tooltip>
                    </div>
                )
            },
        },
        {
            title: props.tipo === 'pagar' ? 'Valor da Parcela' : 'Valor',
            field: 'valor',
            editable: 'never',
            render: (rowData) =>
                rowData.valor.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
        {
            title: 'Valor/Nota',
            field: 'valorTotalNota',
            editable: 'never',
            render: (rowData) =>
                rowData.valorTotalNota?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
        {
            title: 'Pago/Conciliado',
            field: 'dataPagamento',
            editable: 'never',
            cellStyle: {
                textAlign: 'center',
            },
            render: (prop) => (
                <div style={{ display: 'inline-flex' }}>
                    <div
                        style={{
                            color: prop?.dataPagamento ? '#2E7D32' : 'red',
                            marginRight: '10px',
                        }}
                    >
                        <Tooltip
                            title={
                                prop?.dataPagamento
                                    ? 'Data de pagamento: ' +
                                      prop?.dataPagamento
                                    : ''
                            }
                        >
                            {prop?.dataPagamento ? (
                                <MoneyOnIcon />
                            ) : (
                                <MoneyOffIcon />
                            )}
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            color: prop?.dataCompensacao ? '#2E7D32' : 'red',
                            marginRight: '15px',
                        }}
                    >
                        <Tooltip
                            title={
                                prop?.dataCompensacao
                                    ? 'Data de conciliação: ' +
                                      prop?.dataCompensacao
                                    : ''
                            }
                        >
                            {prop?.dataCompensacao ? (
                                <ConciliadoIcon />
                            ) : (
                                <NConciliadoIcon />
                            )}
                        </Tooltip>
                    </div>
                </div>
            ),
        },
        {
            title: 'Ações',
            field: '',
            editable: 'never',
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                textAlign: 'center',
            },
            render: (rowData) => (
                <div style={{ display: 'inline-flex' }}>
                    <div style={{ marginRight: '10px', cursor: 'pointer' }}>
                        <Tooltip title="Editar">
                            <EditIcon onClick={() => props.editar(rowData)} />
                        </Tooltip>
                    </div>
                    <div style={{ marginRight: '15px', cursor: 'pointer' }}>
                        {
                            <Tooltip
                                title={
                                    rowData.dataPagamento === null
                                        ? 'Pagar conta'
                                        : 'Cancelar pagamento'
                                }
                            >
                                {rowData.dataPagamento === null ? (
                                    <MoneyOnIcon
                                        onClick={() => {
                                            selecionaSomenteEstaLinha(rowData)
                                            props.mudaContasSelecionadas(
                                                Array.of(rowData)
                                            )
                                            props.quitarContaSimples()
                                        }}
                                    />
                                ) : (
                                    <MoneyOffIcon
                                        onClick={() => {
                                            if (rowData.dataCompensacao) {
                                                ToastHelper.error(
                                                    'Não é possivel cancelar o pagamento de uma conta conciliada.'
                                                )
                                            } else {
                                                selecionaSomenteEstaLinha(
                                                    rowData
                                                )
                                                props.mudaContasSelecionadas(
                                                    Array.of(rowData)
                                                )
                                                props.cancelarPagamento()
                                            }
                                        }}
                                    />
                                )}
                            </Tooltip>
                        }
                    </div>
                    <div style={{ marginRight: '15px', cursor: 'pointer' }}>
                        {
                            <Tooltip
                                title={
                                    rowData.dataCompensacao !== null
                                        ? 'Cancelar Conciliação'
                                        : 'Conciliar'
                                }
                            >
                                {rowData.dataCompensacao !== null ? (
                                    <NConciliadoIcon
                                        onClick={() => {
                                            selecionaSomenteEstaLinha(rowData)
                                            props.mudaContasSelecionadas(
                                                Array.of(rowData)
                                            )
                                            props.cancelarConciliacao(
                                                Array.of(rowData)
                                            )
                                        }}
                                    />
                                ) : (
                                    <ConciliadoIcon
                                        onClick={() => {
                                            selecionaSomenteEstaLinha(rowData)
                                            props.mudaContasSelecionadas(
                                                Array.of(rowData)
                                            )
                                            props.conciliar(Array.of(rowData))
                                        }}
                                    />
                                )}
                            </Tooltip>
                        }
                    </div>
                    <div style={{ marginRight: '10px', cursor: validateIsRemovable(rowData) ? 'pointer' : '' }}>
                        
                        <Tooltip title="Excluir" className={validateIsRemovable(rowData) ? '' : 'dynamicIconVisibility'}>
                            <DeleteIcon
                                onClick={() =>
                                    props.confirmaDeletar(rowData)
                                }
                            />
                        </Tooltip>
                    </div>
                    <div style={{ marginRight: '10px', cursor: 'pointer' }}>
                        <Tooltip title="Duplicar conta">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="23"
                                viewBox="0 0 24 24"
                                width="23"
                                onClick={() =>
                                    history.push(
                                        `/app/conta/${props.tipo}/duplicar/${rowData.despesaReceitaId}`
                                    )
                                }
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                            </svg>
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]

    useEffect(() => {
        setTableData(props.data)
    }, [props.data])

    const componentWithItensPage = {
        Pagination: (paginat) => {
            const { classes, headerIsHidden, ...other } = paginat
            return (
                <>
                    <Grid item container xs={12} justify="flex-end">
                        <MTablePagination
                            {...other}
                            onChangePage={(e, page) =>
                                props.onChangePage(page + 1)
                            }
                            page={props.filtro.pagina - 1}
                            count={props.filtro.totalItens}
                            rowsPerPage={props.pageSize}
                            localization={{
                                labelRowsSelect: 'recursos',
                                labelDisplayedRows: '{from}-{to} de {count}',
                            }}
                        />
                    </Grid>
                </>
            )
        },
        Toolbar: () => {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: 10,
                            paddingRight: 10,
                        }}
                    >
                        <div style={{ padding: 15 }}>
                            <p
                                style={{
                                    fontFamily: 'inherit',
                                    fontSize: 20,
                                    fontWeight: 'none',
                                }}
                            >
                                Listagem
                            </p>
                        </div>
                        <SelectItensPage
                            styleComponent={{ width: '150px' }}
                            onChangeNumberItens={props.onChangeNumberItens}
                            itensPage={props.filtro.tamanhoPagina}
                            optionsPage={optionsPage}
                        />
                    </div>
                </>
            )
        },
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <MaterialTable
                    title={'Listagem'}
                    columns={colunas}
                    data={tableData}
                    options={{
                        emptyRowsWhenPaging: props.renderizarLinhasEmBranco
                            ? props.renderizarLinhasEmBranco
                            : false,
                        pageSize: props.pageSize ? props.pageSize : 100,
                        paging: true,
                        padding: 'dense',
                        rowStyle: {
                            fontSize: 14,
                        },
                        search: false,
                        actionsColumnIndex: -1,
                        selection: true,
                    }}
                    isLoading={props.isLoading}
                    components={
                        tableData &&
                        tableData.some(
                            (item) => item.tableData && item.tableData.checked
                        )
                            ? delete componentWithItensPage.Toolbar
                            : componentWithItensPage
                    }
                    editable={
                        {
                            // onRowDelete: (item) => props.deletar(item),
                        }
                    }
                    actions={[
                        (rowData) => ({
                            icon:
                                rowData.filter(
                                    (item) => item.dataPagamento !== null
                                ).length === 0
                                    ? MoneyOnIcon
                                    : MoneyOffIcon,
                            tooltip:
                                rowData.filter(
                                    (item) => item.dataPagamento !== null
                                ).length === 0
                                    ? 'Pagar conta(s)'
                                    : 'Cancelar pagamento(s)',
                            onClick: (event, data) =>
                                rowData.filter(
                                    (item) => item.dataPagamento !== null
                                ).length === 0
                                    ? props.quitarContaSimples(data)
                                    : props.cancelarPagamento(data),
                        }),
                        (rowData) => ({
                            icon:
                                rowData[0].dataCompensacao === null
                                    ? ConciliadoIcon
                                    : NConciliadoIcon,
                            tooltip:
                                rowData[0].dataCompensacao === null
                                    ? 'Conciliar'
                                    : 'Cancelar Conciliação',
                            onClick: (event, rowData) =>
                                rowData[0].dataCompensacao !== null
                                    ? props.cancelarConciliacao(rowData)
                                    : props.conciliar(rowData),
                        }),
                    ]}
                    onOrderChange={(numeroColuna, orientacao) =>
                        props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                    }
                    onSelectionChange={(rows) =>
                        props.mudaContasSelecionadas(rows)
                    }
                    icons={{
                        Edit: Edit3Icon,
                        Add: (props) => {
                            return (
                                <AddIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        borderRadius: 100,
                                    }}
                                />
                            )
                        },
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro para exibir',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Edição rápida',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            nRowsSelected: '{0} contas(s) selecionadas',
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                        },
                    }}
                />
            </ThemeProvider>
        </>
    )
}
