import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/TransferenciaBancaria/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/TransferenciaBancaria${param}`);
    },
    cadastrar(param) {
        return service.post(`/TransferenciaBancaria`, param);
    },
    atualizar(param) {
        return service.put(`/TransferenciaBancaria`, param);
    },
    deletar(id) {
        return service.delete(`/TransferenciaBancaria/${id}`);
    }
};
