import service from './service'

export default {
    buscarPorId(id) {
        return service.get(`/AdiantamentoFornecedorCliente/${id}`)
    },
    buscarFornecedorClientePorId(id) {
        return service.get(`/AdiantamentoFornecedorCliente/fornecedorCliente/${id}`)
    },
    quitarAdiantamento(param) {
        return service.post(`/AdiantamentoFornecedorCliente/QuitarAdiantamento`, param);
    },
    buscarTudo(param) {
        return service.get(`/AdiantamentoFornecedorCliente${param}`)
    },
    BuscarPagoPorFornecedorCliente(param) {
        return service.get(`/AdiantamentoFornecedorCliente/BuscarPagoPorFornecedorCliente${param}`)
    },
    cancelarQuitacao(id) {
        return service.get(
            `/AdiantamentoFornecedorCliente/CancelarQuitacao/${id}`
        )
    },
    cadastrar(param) {
        return service.post(`/AdiantamentoFornecedorCliente`, param)
    },
    atualizar(param) {
        return service.put(`/AdiantamentoFornecedorCliente`, param)
    },
    deletar(id) {
        return service.delete(`/AdiantamentoFornecedorCliente/${id}`)
    },
    gerarDocumento(id) {
        return service.get(
            `/AdiantamentoFornecedorCliente/GerarDocumento/${id}`
        )
    },
    ativarDesativar(id) {
        return service.get(
            `/AdiantamentoFornecedorCliente/AtivarDesativar/${id}`
        )
    },
    cancelarConciliacao(id) {
        return service.put(`/AdiantamentoFornecedorCliente/CancelarConciliacao/${id}`)
    },
    atualizarDataCompensacao(param) {
        return service.put(`/AdiantamentoFornecedorCliente/AtualizarDataCompensacao`, param);
    },

}
