import { SET_FILTRO_LISTAGEM_CONTA_PAGAR } from '../actions/contaPagar'

export const storageContaPagar = 'contaPagar'

const initialState = {
    filtroListagem: {
        fazendaId: JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : undefined,
        tamanhoPagina: 100,
        pagina: 1,
    },
}

export const contaPagar = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CONTA_PAGAR:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
