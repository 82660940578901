import service from './service'

export default {
    buscarTudo(param) {
        return service.get(`/UnidadeMedida${param}`)
    },

    buscarPorId(id) {
        return service.get(`/UnidadeMedida/${id}`)
    },

    criar(params) {
        return service.post('/UnidadeMedida', params)
    },

    atualizar(params) {
        return service.put('/UnidadeMedida', params)
    },

    deletar(id) {
        return service.delete(`/UnidadeMedida/${id}`)
    },

    conversoes(params) {
        return service.get(`/UnidadeMedida/conversao${params}`)
    },
}
