import { MovimentoEstoque } from '../pages/movimentoEstoque/index';
import { MovimentoEstoqueCadastro } from '../pages/movimentoEstoque/MovimentoEstoqueCadastro';

export const movimentoEstoqueRoute = [
    {
        path: '/app/movimentoEstoque',
        key: 'APP_MOVIMENTO_ESTOQUE',
        exact: true,
        component: MovimentoEstoque,
    },
    {
        path: '/app/movimentoEstoque/cadastro',
        key: 'APP_MOVIMENTO_ESTOQUE_CADASTRO',
        exact: true,
        component: MovimentoEstoqueCadastro,
    },
    {
        path: '/app/movimentoEstoque/cadastro/:local/:produto',
        key: 'APP_MOVIMENTO_ESTOQUE_CADASTRO',
        exact: true,
        component: MovimentoEstoqueCadastro,
    },
];
