import DetalhesDeCusto from '../pages/detalhesDeCusto/detalhesDeCusto'

export const DetalhesDeCustoRoute = [
    {
        path: '/app/detalhesDeCusto',
        key: 'APP_DETALHES_CUSTO',
        exact: true,
        component: DetalhesDeCusto,
    },
]
