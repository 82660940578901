import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { storeToken } from './store/actions/auth'
import { storePortalUrl } from './store/actions/portal'
import FazendaService from 'services/FazendaService'
import { useEffect } from 'react'
import { STORE_FAZENDA } from './store/actions/fazenda'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const AuthCallback = () => {
    const history = useHistory()
    const query = useQuery()
    const dispatch = useDispatch()

    useEffect(() => {
        async function init() {
            // Recupera token da URL
            const token = query.get('token')
            const portalUrl = query.get('portal')
            const fazendaId = query.get('fazendaId')
            dispatch(storeToken(`Bearer ${token}`))
            dispatch(storePortalUrl(portalUrl))

            // Verifica se a fazenda mudou
            const fazenda = JSON.parse(localStorage.getItem('fazenda'))
            if (!fazenda || fazenda.value !== fazendaId) {
                FazendaService.buscarPorId(fazendaId)
                    .then((result) => {
                        const fazenda = result.data
                        dispatch({
                            type: STORE_FAZENDA,
                            fazenda: fazenda,
                        })
                        history.replace('/app/conta/pagar')
                    })
                    .catch(() => history.replace('/app'))
            } else {
                history.push('/app/conta/pagar')
            }
        }

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}
