import { AdiantamentoFornecedoresClientesPagar } from '../pages/adiantamentoFornecedoresClientes/pagar/index'
import { AdiantamentoFornecedoresClientesReceber } from '../pages/adiantamentoFornecedoresClientes/receber/index'
import { AdiantamentoFornecedoresClientesCadastro } from '../pages/adiantamentoFornecedoresClientes/components/adiantamentoFornecedoresClientes.cadastro'

export const AdiantamentoFornecedorClienteRoute = [
    {
        path: '/app/adiantamentoFornecedoresClientes/pagar',
        key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES_PAGAR_LISTAGEM',
        exact: true,
        component: AdiantamentoFornecedoresClientesPagar,
    },
    {
        path: '/app/adiantamentoFornecedoresClientes/receber',
        key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES_RECEBER_LISTAGEM',
        exact: true,
        component: AdiantamentoFornecedoresClientesReceber,
    },
    {
        path: '/app/adiantamentoFornecedoresClientes/pagar/cadastro',
        key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES_PAGAR_CADASTRO',
        exact: true,
        component: AdiantamentoFornecedoresClientesCadastro,
    },
    {
        path: '/app/adiantamentoFornecedoresClientes/receber/cadastro',
        key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES_RECEBER_CADASTRO',
        exact: true,
        component: AdiantamentoFornecedoresClientesCadastro,
    },
    {
        path: '/app/adiantamentoFornecedoresClientes/pagar/:tipo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES_PAGAR_EDITAR',
        exact: true,
        component: AdiantamentoFornecedoresClientesCadastro,
    },
    {
        path: '/app/adiantamentoFornecedoresClientes/receber/:tipo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_ADIANTAMENTO_FORNECEDORES_CLIENTES_RECEBER_EDITAR',
        exact: true,
        component: AdiantamentoFornecedoresClientesCadastro,
    },
]
