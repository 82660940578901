import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import MovimentoEstoqueListagem from './MovimentoEstoqueListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import MovimentoEstoqueService from '../../services/MovimentoEstoqueService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import ProdutoService from '../../services/ProdutosServicosService'
import TipoOperacaoService from '../../services/TipoOperacaoService'
import SafraService from '../../services/SafraService'
import LocaisEstoqueService from 'services/LocaisEstoqueService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const MovimentoEstoque = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [loading, setLoading] = useState(false)
    const [MovimentoEstoque, setMovimentoEstoque] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.movimentoEstoque.filtroListagem)
    )

    const [opcoesProduto, setOpcoesProduto] = useState([])
    const [opcoesTipoOperacao, setOpcoesTipoOperacao] = useState([])
    const [opcoesSafra, setOpcoesSafra] = useState([])
    const [opcoesLocalEstoque, setOpcoesLocalEstoque] = useState([])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_MOVIMENTO_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarMovimentoEstoque(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_MOVIMENTO_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        props.history.push({
            pathname: `/app/MovimentoEstoque/cadastro`,
        })
    }

    const handleUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);

        const filtroParams = {
            locaisEstoqueId: urlParams.get('locaisEstoqueId'),
            produtoId: urlParams.get('produtoId')
        }

        const possuiParametros = filtroParams.locaisEstoqueId && filtroParams.produtoId;

        if (possuiParametros) {
            handleFiltro(filtroParams)
        } else buscarMovimentoEstoque(filtro)
    }

    useEffect(() => {
        buscarProdutos()
        buscarOperacoes()
        buscarSafra()
        buscarLocaisEstoque()
        // buscarMovimentoEstoque(filtro)
        handleUrlParams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarMovimentoEstoque(novoFiltro)
    }

    const buscarMovimentoEstoque = async (filtro) => {
        var novoFiltro = {
            filtro: filtro.filtro,
            fazendaId: fazendaId,
            tamanhoPagina: 100,
            ordem: 'data',
            decrescente: true,
            periodoDe: filtro.periodoDe,
            periodoAte: filtro.periodoAte,
            produtoId: filtro.produtoId,
            operacaoMovimentoEstoqueId: filtro.operacaoMovimentoEstoqueId,
            safraId: filtro.safraId,
            locaisEstoqueId: filtro.locaisEstoqueId
        }
        let params = await ObjectHelper.createParams(novoFiltro)
        setLoading(true)
        await MovimentoEstoqueService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.fazendaId = fazendaId
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                        ? filtro.tamanhoPagina
                        : 100
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    newFiltro.ativo = true
                    alteraFiltro(newFiltro)
                    
                    setMovimentoEstoque(response.data.itens)
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response) {
                    ToastHelper.error(error.response.data)
                }
            })
    }
    //Codigo comentado por problemas no requisito ao excluir uma movimentação
    // const deletar = async (item) => {
    //     await MovimentoEstoqueService.deletar(item.id)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 ToastHelper.success('Movimento excluído com sucesso!')
    //                 let novoFiltro = { ...filtro }
    //                 buscarMovimentoEstoque(novoFiltro)
    //             }
    //         })
    //         .catch(function (error) {
    //             setLoading(false)
    //             if (error.response) {
    //                 ToastHelper.error(error.response.data)
    //             }
    //         })
    // }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente

        if (colunas[numeroColuna]?.field === 'movimentoEstoqueTipo') {
            novoFiltro.ordem = 'tipoOperacao.Nome'
        } else if (colunas[numeroColuna]?.field === 'safra') {
            novoFiltro.ordem = 'safra.Nome'
        } else if (colunas[numeroColuna]?.field === 'localEstoque') {
            novoFiltro.ordem = 'locaisEstoque.Nome'
        } else {
            novoFiltro.ordem = colunas[numeroColuna]?.field
        }
        alteraFiltro(novoFiltro)
        buscarMovimentoEstoque(novoFiltro)
    }

    const buscarProdutos = async () => {
        const { data } = await ProdutoService.comControleEstoque(fazendaId)
        setOpcoesProduto(data)
    }

    const buscarOperacoes = async () => {
        const { data } = await TipoOperacaoService.BuscarOperacoes(fazendaId)
        setOpcoesTipoOperacao(data)
    }

    const buscarSafra = async () => {
        const { data } = await SafraService.buscarSafras(fazendaId)
        setOpcoesSafra(data)
    }

    const buscarLocaisEstoque = async () => {
        const { data } = await LocaisEstoqueService.buscarPorFazendaId(fazendaId)
        setOpcoesLocalEstoque(data)
    }
 
    return (
        <Page className={classes.root} title="Movimentação de Estoque">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Movimentação de Estoque" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    temFiltroAvancado
                    filtroPeriodo
                    filtroProduto={opcoesProduto}
                    filtroOperacaoMovimentoEstoque={opcoesTipoOperacao}
                    filtroSafra={opcoesSafra}
                    filtroLocaisEstoque={opcoesLocalEstoque}
                />
                <Box mt={3}>
                    <MovimentoEstoqueListagem
                        data={MovimentoEstoque}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        mudaOrdenacao={mudaOrdenacao}
                    />
                </Box>
            </Container>
        </Page>
    )
}
