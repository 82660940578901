import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'
import EstoqueService from '../../services/EstoqueService'
import EstoqueListagem from './estoqueListagem'
import ProdutosServicosService from '../../services/ProdutosServicosService'
import LocaisEstoqueService from '../../services/LocaisEstoqueService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const Estoque = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.estoque.filtroListagem)
    )

    const [estoques, setEstoques] = useState([])
    const [produtos, setProdutos] = useState([])
    const [locaisEstoque, setLocaisEstoque] = useState([])

    useEffect(() => {
        const getEstoques = async () => {
            await buscarEstoques(filtro)
        }
        getEstoques()
        buscarProdutos()
        buscarLocais()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarEstoques(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        if (numeroColuna === 1) {
            novoFiltro.ordem = 'produto.nome'
        }
        alteraFiltro(novoFiltro)
        buscarEstoques(novoFiltro)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarEstoques(novoFiltro)
    }

    const buscarEstoques = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        filtroPesquisa.tamanhoPagina = filtro.tamanhoPagina
            ? filtro.tamanhoPagina
            : 100
        let params = await ObjectHelper.createParams(filtroPesquisa)

        EstoqueService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = fazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)

                const estoques = response.data.itens.map((item) => {
                    return {
                        produto: item.produto['nome'],
                        produtoId: item.produtoId,
                        unidade: item.unidadeMedida['sigla'],
                        quantidade: item['quantidade'],
                        valorUnitario: item.produto['valorUnitario'],
                        localEstoque: item.localEstoque['nome'],
                        tipoEstoque: item.localEstoque.tipoEstoque['nome'],
                        localEstoqueId: item.localEstoqueId,
                    }
                })
                setEstoques(estoques)
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarProdutos = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        filtro.ativo = true
        filtro.produto = true
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        ProdutosServicosService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newProdutos = ObjectHelper.getValuesParseToSelect(
                    response.data.itens
                )
                setProdutos(newProdutos)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const buscarLocais = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        LocaisEstoqueService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                var locais = []
                response.data.itens.forEach((item) =>
                    locais.push({
                        value: item['id'],
                        label: item['nome'] + ' - ' + item['tipoEstoque'],
                    })
                )
                setLocaisEstoque(locais)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const movimentarEstoque = (item) => {
        history.push(
            `/app/movimentoEstoque/cadastro/${item.localEstoqueId}/${item.produtoId}`
        )
    }

    const transferenciaEstoque = (item) => {
        history.push(
            `/app/transferenciaEstoque/cadastro/${item.localEstoqueId}/${item.produtoId}`
        )
    }

    return (
        <Page className={classes.root} title="Estoques">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Estoques" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    temFiltroAvancado
                    filtroLocalEstoque={locaisEstoque}
                    filtroProdutos={produtos}
                />
                <Box mt={3}>
                    <EstoqueListagem
                        data={estoques}
                        pageSize={filtro.tamanhoPagina}
                        filtro={filtro}
                        isLoading={loading}
                        mudaOrdenacao={mudaOrdenacao}
                        onChangePage={(page) => onChangePage(page)}
                        movimentarEstoque={movimentarEstoque}
                        transferenciaEstoque={transferenciaEstoque}
                    />
                </Box>
            </Container>
        </Page>
    )
}
