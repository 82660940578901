import React from 'react'
import Table from '../../components/Table'
import { TextField, colors } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import EditCompleteIcon from '@material-ui/icons/EditOutlined'
import LigaDesliga from '@material-ui/icons/PowerSettingsNew'
import IconeAtivo from '@material-ui/icons/CheckCircle'
import IconeInativo from '@material-ui/icons/Cancel'

export default function TipoDocumentoListagem(props) {
    const colunas = [
        { title: 'Descrição', field: 'descricao' },
        {
            title: 'Venda',
            field: 'venda',
            editable: 'never',
            render: (rowData) =>
                rowData.venda ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
            editComponent: (props) => {
                return (
                    <TextField
                        type="number"
                        step="any"
                        value={props.value}
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        InputProps={{
                            defaultValue: props.value,
                            startAdornment: (
                                <InputAdornment position="end">
                                    R$
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            var data = { ...props.rowData }
                            data.saldoAtual = e.target.value
                            props.onRowDataChange(data)
                        }}
                        error={props.value === ''}
                        helperText={props.value === '' ? 'Inválido' : ''}
                    />
                )
            },
        },
        {
            title: 'Compra',
            field: 'compra',
            editable: 'never',
            render: (rowData) =>
                rowData.compra ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
        {
            title: 'Ativo',
            field: 'ativo',
            editable: 'never',
            render: (rowData) =>
                rowData.ativo ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
        {
            title: 'Integra com Estoque',
            field: 'integrarEstoque',
            editable: 'never',
            render: (rowData) =>
                rowData.integrarEstoque ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
        {
            title: 'Movimenta Fluxo de Caixa',
            field: 'movimentaFluxoCaixa',
            editable: 'never',
            render: (rowData) =>
                rowData.movimentaFluxoCaixa ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                pageSize={props.pageSize}
                emptyRowsWhenPaging={false}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                editarCelula={props.editarCelula ? props.editarCelula : false}
                actions={[
                    {
                        icon: LigaDesliga,
                        tooltip: 'Ativar / Desativar',
                        onClick: (event, rowData) =>
                            props.ativarDesativar(rowData.id),
                    },
                    {
                        icon: EditCompleteIcon,
                        tooltip: 'Edição',
                        onClick: (event, rowData) => props.editar(rowData.id),
                    },
                ]}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                }}
            />
        </>
    )
}
