import { SET_FILTRO_DETALHES_CUSTO } from '../actions/detalhesDeCusto'

export const storageDetalhesDeCusto = 'detalhesDeCusto'

const initialState = {
    filtroListagem: {
        periodoInicial: '',
        periodoFinal: '',
    },
}

export const detalhesDeCusto = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_DETALHES_CUSTO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
