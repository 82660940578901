import React from 'react'
import Table from 'components/Table'
import SelectItensPage from 'components/SelectItensPage'

export function UnidadesListagem({
    data,
    actions,
    filtro,
    loading,
    onChangePage,
    editable,
    onChangeNumberItens,
}) {
    const columns = [
        { title: 'Sigla', field: 'sigla', editable: 'never', sorting: false },
        {
            title: 'Descrição',
            field: 'descricao',
            editable: 'never',
            sorting: false,
        },
    ]

    const optionsPage = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
    ]

    return (
        <>
            <Table
                columns={columns}
                data={data}
                actions={actions}
                pageSize={filtro.tamanhoPagina}
                page={filtro.pagina}
                title="Unidades de Medida"
                paging={true}
                isLoading={loading}
                onChangePage={onChangePage}
                totalCount={filtro.totalItens}
                editable={editable}
                components={{
                    Toolbar: () => {
                        return (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        paddingTop: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <div style={{ padding: 15 }}>
                                        <p
                                            style={{
                                                fontFamily: 'inherit',
                                                fontSize: 20,
                                                fontWeight: 'none',
                                            }}
                                        >
                                            Listagem
                                        </p>
                                    </div>
                                    <SelectItensPage
                                        styleComponent={{ width: '150px' }}
                                        onChangeNumberItens={
                                            onChangeNumberItens
                                        }
                                        itensPage={filtro.tamanhoPagina}
                                        optionsPage={optionsPage}
                                    />
                                </div>
                            </>
                        )
                    },
                }}
            />
        </>
    )
}
