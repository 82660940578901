import React from 'react'
import { useState, useEffect } from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts'

export default function Grafico(props) {
    const [data, setData] = useState([])

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        borderRadius: '15px',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        padding: '15px',
                    }}
                >
                    <p style={{ marginBottom: '5px' }}>{`${label}`}</p>
                    <p style={{ color: '#2E7D32' }}>
                        {'Saldo: ' +
                            payload[0].value?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                    </p>

                    <p style={{ color: '#8884d8' }}>
                        {'Receita: ' +
                            payload[1].value?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                    </p>

                    <p style={{ color: 'red' }}>
                        {'Despesa: ' +
                            payload[2].value?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                    </p>
                </div>
            )
        }

        return null
    }

    useEffect(() => {
        function init() {
            if (props.colunas && props.dados) {
                let dados = []
                props.colunas.forEach((coluna) => {
                    if (coluna.field !== 'categoria') {
                        let dado
                        dado = {
                            name: coluna.title,
                            key: coluna.field,
                            saldo: Math.floor(Math.random() * 40000 + 10000),
                            despesa: Math.floor(Math.random() * 500 + 1),
                            receita: Math.floor(Math.random() * 3000 + 1),
                        }
                        props.dados.forEach((data) => {
                            if (data.categoria === 'Saldo inicial') {
                                dado.saldo = data[coluna.field]
                            }
                            if (data.categoria === 'Despesas') {
                                dado.despesa = data[coluna.field]
                            }
                            if (data.categoria === 'Receitas') {
                                dado.receita = data[coluna.field]
                            }
                        })
                        dados.push(dado)
                    }
                })
                setData(dados)
            }
        }
        init()
    }, [props.colunas, props.dados])

    return (
        <div style={{ overflow: 'auto' }}>
            <LineChart
                width={props.colunas.length * 18}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis yAxisId="left" />
                <XAxis
                    dataKey="name"
                    style={{
                        fontSize: '0.6rem',
                        fontFamily: 'Arial',
                    }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend wrapperStyle={{ bottom: '0px' }} />
                <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="saldo"
                    stroke="#2E7D32"
                    activeDot={{ r: 8 }}
                />
                <Line
                    yAxisId="left"
                    dataKey="receita"
                    type="monotone"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                />
                <Line
                    yAxisId="left"
                    dataKey="despesa"
                    type="monotone"
                    stroke="red"
                    activeDot={{ r: 8 }}
                />
            </LineChart>
        </div>
    )
}
