import React from 'react'
import Table from '../../components/Table'
import LigaDesliga from '@material-ui/icons/PowerSettingsNew'
import EditCompleteIcon from '@material-ui/icons/EditOutlined'
import { colors } from '@material-ui/core'
import IconeAtivo from '@material-ui/icons/CheckCircle'
import IconeInativo from '@material-ui/icons/Cancel'
import AddSub from '@material-ui/icons/AddCircleOutline'

export default function ContaGerencialListagem(props) {
    const colunas = [
        {
            title: 'Número',
            field: 'numero',
            editable: 'never',
            defaultSort: 'asc',
            customSort: (a, b) => {
                if (a.numero === null || b.numero === null) return 0

                const x = String(a.numero).split('.').reduce((acc, cur) => acc + cur, '')
                const y = String(b.numero).split('.').reduce((acc, cur) => acc + cur, '')

                return x - y
            }
        },
        { title: 'Descrição', field: 'descricao', editable: 'never' },
        { title: 'Operação', field: 'operacao.descricao', editable: 'never' },
        { title: 'Tipo', field: 'tipo', editable: 'never' },
        { title: 'Código', field: 'codigoAlternativo', editable: 'never' },
        {
            title: 'Ativo',
            field: 'ativo',
            editable: 'never',
            render: (rowData) =>
                rowData.ativo ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
    ]

    const checkActionsIsDisabled = (rowData) => {
        const contasGerenciaisPadroes = [-1, -2, -3, -4]
        if(contasGerenciaisPadroes.includes(rowData.item))
            return true;
        return false;
    }

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                isLoading={props.isLoading}
                options={{
                    paging: false,
                    sorting: true
                }}
                haveParentChild={true}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                emptyRowsWhenPaging={false}
                editarCelula={props.editarCelula ? props.editarCelula : false}
                actions={[
                    (rowData) => (
                    {
                        icon: AddSub,
                        tooltip: 'Adiciona conta filha',
                        onClick: (event, rowData) =>
                            props.adicionaContaFilha(rowData.id),
                        disabled: checkActionsIsDisabled(rowData),
                        hidden: rowData.tipo === 'Analítica' && rowData.emUso
                    }),
                    (rowData) => (
                    {
                        icon: LigaDesliga,
                        tooltip: 'Ativar / Desativar',
                        onClick: (event, rowData) =>
                            props.ativarDesativar(rowData.id),
                        disabled: checkActionsIsDisabled(rowData)

                    }),
                    rowData => (
                    {
                        icon: EditCompleteIcon,
                        tooltip: 'Edição',
                        onClick: (event, rowData) => props.editar(rowData.id),
                        disabled: checkActionsIsDisabled(rowData)

                    })
                ]}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                    isDeletable: rowData => !checkActionsIsDisabled(rowData)
                }}
            />
        </>
    )
}
