import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import NumberFormat from 'react-number-format'

import FooterBottons from '../../components/FooterBottons/FooterBottons'

import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { TransferenciaBancariaEsquema } from './TranferenciaBancaria.validate'
import TransferenciaBancariaService from './../../services/TransferenciaBancariaService'
import ContaBancariaService from './../../services/ContaBancariaService'
import ToastHelper from '../../utils/toastHelper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ObjectHelper from '../../utils/objetcHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
}))

export const TransferenciaBancariaCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [data, setData] = useState()
    const [contaDebitoId, setContaDebitoId] = useState()
    const [contaCreditoId, setContaCreditoId] = useState()
    const [valor, setValor] = useState()
    const [numeroDocumento, setNumeroDocumento] = useState()
    const [contasBancarias, setContasBancarias] = useState([])

    const {
        match: { params },
    } = props

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            data: '',
            contaDebitoId: '',
            contaCreditoId: '',
            valor: '',
            numeroDocumento: '',
        },
        resolver: yupResolver(TransferenciaBancariaEsquema),
    })

    useEffect(() => {
        async function init() {
            await buscarContasBancarias()

            if (params.id) {
                await buscarTransferenciaBancaria(params.id)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarTransferenciaBancaria = async (id) => {
        await TransferenciaBancariaService.buscarPorId(id)
            .then((response) => {
                if (response.status === 200) {
                    setData(response.data.data)
                    setContaDebitoId(response.data.contaDebito.id)
                    setContaCreditoId(response.data.contaCredito.id)
                    setValor(response.data.valor)
                    setNumeroDocumento(response.data.numeroDocumento)

                    setValue('data', response.data.data)
                    setValue(
                        'contaDebitoId',
                        response.data.contaDebito.descricao
                    )
                    setValue(
                        'contaCreditoId',
                        response.data.contaCredito.descricao
                    )
                    setValue('valor', response.data.valor)
                    setValue('numeroDocumento', response.data.numeroDocumento)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = fazendaId
        newData.data = data
        newData.contaDebitoId = contaDebitoId
        newData.contaCreditoId = contaCreditoId
        newData.valor = valor
        newData.numeroDocumento = numeroDocumento

        if (params.id) {
            newData.id = params.id
            await TransferenciaBancariaService.atualizar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Transferência bancária atualizada!'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
        } else {
            newData.ativo = true
            await TransferenciaBancariaService.cadastrar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Transferência bancária cadastrada com sucesso!'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
        }
    }

    const buscarContasBancarias = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        await ContaBancariaService.buscarTudo(params).then((response) => {
            let newContas = ObjectHelper.getValuesDescricaoLabelParseToSelect(
                response.data.itens
            )
            setContasBancarias(newContas)
        })
    }

    const trataValor = (valor) => {
        var newValor = parseFloat(
            valor.toString().replaceAll('.', '').replace(',', '.')
        )
        setValor(newValor)
    }
    return (
        <Page className={classes.root} title="Transferência bancária cadastro">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de transferência bancária'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="date"
                                            label="Data:*"
                                            className={classes.textField}
                                            inputRef={register}
                                            onChange={(x) =>
                                                setData(x.target.value)
                                            }
                                            disabled={params.id}
                                            id="data"
                                            name="data"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.data
                                                ? errors.data.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="contaDebitoId"
                                            id="contaDebitoId"
                                            inputRef={register}
                                            disabled={params.id}
                                            options={contasBancarias}
                                            value={contaDebitoId}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setContaDebitoId(newValue.value)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="contaDebitoId"
                                                    id="contaDebitoId"
                                                    variant="standard"
                                                    label="Conta de débito:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.contaDebitoId
                                                ? errors.contaDebitoId.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="contaCreditoId"
                                            id="contaCreditoId"
                                            inputRef={register}
                                            disabled={params.id}
                                            options={contasBancarias}
                                            value={contaCreditoId}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setContaCreditoId(
                                                    newValue.value
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="contaCreditoId"
                                                    id="contaCreditoId"
                                                    variant="standard"
                                                    label="Conta de crédito:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.contaCreditoId
                                                ? errors.contaCreditoId.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            label="Valor:*"
                                            value={valor ? valor : 0}
                                            disabled={params.id}
                                            defaultValue={valor ? valor : 0}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            customInput={TextField}
                                            onChange={(x) =>
                                                trataValor(x.target.value)
                                            }
                                            inputRef={register}
                                            id="valor"
                                            name="valor"
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'right' },
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.valor
                                                ? errors.valor.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Número documento:*"
                                            disabled={params.id}
                                            className={classes.textField}
                                            onChange={(x) =>
                                                setNumeroDocumento(
                                                    x.target.value
                                                )
                                            }
                                            inputRef={register}
                                            id="numeroDocumento"
                                            name="numeroDocumento"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.numeroDocumento
                                                ? errors.numeroDocumento.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons
                        desabilitaSubmit={params.id}
                        rotaVoltar={'/app/transferenciaBancaria'}
                    />
                </form>
            </Container>
        </Page>
    )
}
