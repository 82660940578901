import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/ContaGerencial/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/ContaGerencial${param}`);
    },
    cadastrar(param) {
        return service.post(`/ContaGerencial`, param);
    },
    atualizar(param) {
        return service.put(`/ContaGerencial`, param);
    },
    ativarDesativar(id) {
        return service.put(`/ContaGerencial/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/ContaGerencial/${id}`);
    },
    indicarItem(id) {
        return service.get(`/ContaGerencial/IndicarItem/${id}`);
    },
};
