import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import FornecedorClienteListagem from './FornecedorClienteListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import FornecedorClienteService from '../../services/FornecedorClienteService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const FornecedorCliente = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [loading, setLoading] = useState(false)
    const [fornecedoresClientes, setFornecedoresClientes] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.fornecedorCliente.filtroListagem)
    )

    useEffect(() => {
        const getfornecedoresClientes = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'NomeFantasia'
            novoFiltro.tamanhoPagina = 100
            buscarFornecedoresClientes(novoFiltro)
        }
        getfornecedoresClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_FORNECEDOR_CLIENTE',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarFornecedoresClientes(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_FORNECEDOR_CLIENTE',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/fornecedorCliente/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarFornecedoresClientes(novoFiltro)
    }

    const buscarFornecedoresClientes = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.ativo = filtro.ativo ? filtro.ativo.value : ''
        filtroParaPesquisa.tamanhoPagina = 100
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await FornecedorClienteService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = fazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                setFornecedoresClientes(response.data.itens)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const editar = (itemId) => {
        history.push(`/app/fornecedorCliente/editar/${itemId}`)
    }

    const ativarDesativar = async (itemId) => {
        setLoading(true)
        await FornecedorClienteService.ativarDesativar({ id: itemId }).then(
            (response) => {
                if (response.status === 200) {
                    let novoFiltro = { ...filtro }
                    buscarFornecedoresClientes(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const deletar = async (item) => {
        await FornecedorClienteService.deletar(item.id).then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Cadastro excluído com sucesso!')
                let novoFiltro = { ...filtro }
                buscarFornecedoresClientes(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarFornecedoresClientes(novoFiltro)
    }

    return (
        <Page className={classes.root} title="Fornecedor / Cliente">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Fornecedor / Cliente" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    temFiltroAvancado
                    filtroAtivo
                    filtroNomeFantasia
                    filtroCpfCnpj
                    filtroFornecedorCliente
                />
                <Box mt={3}>
                    <FornecedorClienteListagem
                        data={fornecedoresClientes}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={
                            filtro.tamanhoPagina ? filtro.tamanhoPagina : 20
                        }
                        editar={editar}
                        ativarDesativar={ativarDesativar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                    />
                </Box>
            </Container>
        </Page>
    )
}
