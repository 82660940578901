import { combineReducers } from 'redux'
import { auth } from './auth'
import { portal } from './portal'
import { contas } from './contas'
import { fazenda } from './fazenda'
import { caixa } from './caixa'
import { contaBancaria } from './contaBancaria'
import { tipoDocumento } from './tipoDocumento'
import { contaGerencial } from './contaGerencial'
import { fornecedorCliente } from './fornecedorCliente'
import { contaPagar } from './contaPagar'
import { contaReceber } from './contaReceber'
import { produtosServicos } from './produtosServicos'
import { transferenciaBancaria } from './transferenciaBancaria'
import { transferenciaEstoque } from './transferenciaEstoque'
import { centroCusto } from './centroCusto'
import { centroNegocio } from './centroNegocio'
import { tipoEstoque } from './tipoEstoque'
import { estoque } from './estoque'
import { camposParcela } from './camposParcela'
import { gestaoContrato } from './gestaoContrato'
import { pedidos } from './pedidos'
import { adiantamento } from './adiantamento'
import { fluxoCaixa } from './fluxoCaixa'
import { extratoBancario } from './extratoBancario'
import { detalhesDeCusto } from './detalhesDeCusto'
import { movimentoEstoque } from './movimentoEstoque'
import { produtorRural } from './produtorRural'
import { unidades } from './unidades'

export const Reducers = combineReducers({
    auth,
    portal,
    contas,
    fazenda,
    caixa,
    contaBancaria,
    tipoDocumento,
    contaGerencial,
    fornecedorCliente,
    contaPagar,
    contaReceber,
    produtosServicos,
    transferenciaBancaria,
    transferenciaEstoque,
    centroCusto,
    centroNegocio,
    tipoEstoque,
    estoque,
    camposParcela,
    gestaoContrato,
    pedidos,
    adiantamento,
    fluxoCaixa,
    extratoBancario,
    detalhesDeCusto,
    movimentoEstoque,
    produtorRural,
    unidades,
})
