import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import PedidosListagem from './components/PedidosListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
// import Request from './requests'
import ToolbarPedidos from './components/ToolbarPedidos'
import PedidosService from './../../services/PedidosService'
import centroCustoService from './../../services/CentroCustoService'
import contaGerencialService from './../../services/ContaGerencialService'
import produtosServicosService from './../../services/ProdutosServicosService'
import fornecedorService from './../../services/FornecedorClienteService'
import DataHelper from './../../utils/dataHelpder'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const Pedidos = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.pedidos.filtroListagem)
    )

    const [pedidos, setPedidos] = useState([])

    useEffect(() => {
        const getPedidos = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'dataPedido'
            novoFiltro.tamanhoPagina = 100
            await buscarPedidos(novoFiltro)
        }
        getPedidos()
        buscarCentrosCusto()
        buscarContasGerenciais()
        buscarProdutosServicos()
        buscarFornecedores()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFiltro = (filtro) => {
        buscarPedidos(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_PEDIDOS',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        window.location.href = '/app/pedidos/cadastro'
    }

    const editar = (id) => {
        window.location.href = `/app/pedidos/editar/${id}`
    }

    const visualizar = (id) => {
        window.location.href = `/app/pedidos/visualizar/${id}`
    }

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_PEDIDOS',
            filtroListagem: filtro,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        await buscarPedidos(novoFiltro)
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field

        if (novoFiltro.ordem === 'nomeFantasia')
            novoFiltro.ordem = 'pedidos.fornecedorCliente.nomeFantasia'

        alteraFiltro(novoFiltro)
        buscarPedidos(novoFiltro)
    }

    const trataItensParaTabela = (itens) => {
        let novosItens = []

        itens.map((item) => {
            item.dataPedido = item.dataPedido
                ? DataHelper.transformStringDataUsaToPtBr(
                      DataHelper.formatStringDataBackToFront(item.dataPedido)
                  )
                : ''
            item.pedidos.forEach((data) => {
                data.previsaoEntrega = data.previsaoEntrega
                    ? DataHelper.transformStringDataUsaToPtBr(
                          data.previsaoEntrega
                      )
                    : ''
            })
            novosItens.push(item)
            return item
        })

        return itens
    }

    const buscarPedidos = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        await alteraFiltro(filtroPesquisa)
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        PedidosService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = response.data.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                let novosItens = trataItensParaTabela(response.data.itens)
                setPedidos(novosItens)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const deletar = async (id) => {
        PedidosService.deletar(id)
            .then((response) => {
                ToastHelper.success('Pedido excluído com sucesso!')
                let novoFiltro = { ...filtro }
                buscarPedidos(novoFiltro)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const duplicar = async (id) => {
        PedidosService.duplicar(id).then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Pedido dulicado com sucesso!')
                let novoFiltro = { ...filtro }
                buscarPedidos(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        }).catch(e => {
            ToastHelper.error("Ocorreu um erro, por favor acione o adimnistrador do sistema.")
        })
    }

    const buscarCentrosCusto = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        filtroPesquisa.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        centroCustoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                var data = ObjectHelper.getValuesDescricaoLabelParseToSelect(
                    response.data.itens
                )
                setCentrosCusto(data)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const buscarContasGerenciais = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        filtroPesquisa.tamanhoPagina = 99999
        filtroPesquisa.ativo = true
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        contaGerencialService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                var data = ObjectHelper.getValuesDescricaoLabelParseToSelect(
                    response.data.itens
                )
                setContasGerencial(data)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const buscarProdutosServicos = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        filtroPesquisa.tamanhoPagina = 99999
        filtroPesquisa.ativo = true
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        produtosServicosService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                var data = ObjectHelper.getValuesParseToSelect(
                    response.data.itens
                )
                setProdutosServicos(data)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const buscarFornecedores = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        filtroPesquisa.ativo = true
        filtroPesquisa.ordem = 'nomeFantasia'
        filtroPesquisa.tamanhoPagina = 99999
        filtroPesquisa.decrescente = false
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        fornecedorService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                var data = ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(
                    response.data.itens
                )
                setFornecedores(data)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const [produtosServicos, setProdutosServicos] = useState([])
    const [centrosCusto, setCentrosCusto] = useState([])
    const [contasGerencial, setContasGerencial] = useState([])
    const [fornecedores, setFornecedores] = useState([])

    const gerarContaPagar = async (id) => {
        window.location.href = `/app/conta/pagar/gerar/${id}`
    }

    return (
        <Page className={classes.root} title={'Pedidos'}>
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Pedidos'} />
                <ToolbarPedidos
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroAtivo
                    temFiltroAvancado
                    fornecedores={fornecedores}
                    centrosCusto={centrosCusto}
                    contasGerencial={contasGerencial}
                    produtosServicos={produtosServicos}
                />
                <Box mt={3}>
                    <PedidosListagem
                        data={pedidos}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        visualizar={visualizar}
                        duplicar={duplicar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        renderizarLinhasEmBranco={false}
                        gerarContaPagar={gerarContaPagar}
                    />
                </Box>
            </Container>
        </Page>
    )
}
