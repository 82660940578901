import {
    Card,
    CardContent,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core'
import Page from 'components/Page'
import Titulo from 'components/Titulo'
import { CustomSwitch } from './components/customSwitch'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ConversaoDeUnidade } from './components/conversaoDeUnidade'
import { useEffect } from 'react'
import UnidadeDeMedidaService from 'services/UnidadeDeMedidaService'
import ToastHelper from 'utils/toastHelper'
import ObjectHelper from 'utils/objetcHelper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { UnidadesCadastroValidate } from './unidades.validate'
import Loading from 'components/Loading'
import FooterButtons from './components/FooterButtons'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
}))

export function UnidadesCadastro({ match: { params } }) {
    const history = useHistory()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [unidadeConversao, setUnidadeConversao] = useState([])
    const [tmpUnidadeConversao, setTmpUnidadeConversao] = useState([])
    const [unidadesDeMedida, setUnidadesDeMedida] = useState([])
    const [titulo, setTitulo] = useState('Cadastro de Unidades de Medida')
    const [title, setTitle] = useState('Unidades de Medida - Cadastrar')

    const visualizar = params.modo === 'visualizar'
    const editar = params.modo === 'editar'

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))?.value ?? ''
    )
    const [enableUnitConversion, setEnableUnitConversion] = useState(visualizar)

    const handleEnableUnit = () =>
        setEnableUnitConversion(!enableUnitConversion)

    const handleAddUnitConversion = (data) => {
        const unidadeConvertida = unidadeConversao.find(
            (x) => x.unidadeConvertidaId === data.unidadeConvertidaId
        )

        if (unidadeConvertida) {
            ToastHelper.warn('Unidade de Medida já adicionada')
            return
        }

        setUnidadeConversao([...unidadeConversao, data])
    }

    const handleRemoveUnitConversion = (data) => {
        const unidadeConvertida = unidadeConversao.find(
            (x) => x.unidadeConvertidaId === data.unidadeConvertidaId
        )

        if (!unidadeConvertida) {
            ToastHelper.warn('Unidade de Medida não encontrada')
            return
        }

        setUnidadeConversao(
            unidadeConversao.filter(
                (x) => x.unidadeConvertidaId !== data.unidadeConvertidaId
            )
        )
    }

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            sigla: '',
            descricao: '',
        },
        resolver: yupResolver(UnidadesCadastroValidate),
    })

    const buscarParaEdicao = async (id) => {
        setLoading(true)
        UnidadeDeMedidaService.buscarPorId(id)
            .then((response) => {
                if (response.status === 200) {
                    setValue('id', response.data.id)
                    setValue('sigla', response.data.sigla)
                    setValue('descricao', response.data.descricao)
                    setUnidadeConversao(response.data.unidadeConversao)
                    setTmpUnidadeConversao(response.data.unidadeConversao)
                    setEnableUnitConversion(
                        response.data.unidadeConversao.length > 0
                    )
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                    return
                }

                ToastHelper.error(error)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (editar) {
            setTitulo('Editar Unidade de Medida')
            setTitle('Unidades de Medida - Editar')
            buscarParaEdicao(params.id)
        }

        if (visualizar) {
            setTitulo('Visualizar Unidade de Medida')
            setTitle('Unidades de Medida - Visualizar')
            buscarParaEdicao(params.id)
        }

        buscarUnidades({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarUnidades = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.tamanhoPagina = 9999
        filtroParaPesquisa.ordem = 'sigla'

        setLoading(true)
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        UnidadeDeMedidaService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    setUnidadesDeMedida(response.data.itens)
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                ToastHelper.error(error)
            })
            .finally(() => setLoading(false))
    }

    const onSubmit = async (data) => {
        let newData = {
            ...data,
            fazendaId: fazendaId,
        }

        if (tmpUnidadeConversao !== unidadeConversao) {
            newData.unidadeConversao = unidadeConversao.map((item) => {
                return {
                    unidadeConvertidaId: item.unidadeConvertidaId,
                    valor: item.valor,
                }
            })
        }

        setLoading(true)
        if (params.id) {
            newData.id = params.id
            UnidadeDeMedidaService.atualizar(newData)
                .then((response) => {
                    if (response.status === 204) {
                        ToastHelper.success(
                            'Unidade de medida editada com sucesso'
                        )
                        history.goBack()
                    } else {
                        ToastHelper.error(response.statusText)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        ToastHelper.error(error.response.data)
                        return
                    }

                    ToastHelper.error(error)
                })
                .finally(() => setLoading(false))
            return
        }

        UnidadeDeMedidaService.criar(newData)
            .then((response) => {
                if (response.status === 201) {
                    ToastHelper.success('Unidade de medida salva com sucesso')
                    history.goBack()
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                    return
                }

                ToastHelper.error(error)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Page className={classes.root} title={title}>
                <Loading open={loading} />
                <Container maxWidth="xl">
                    <Titulo titulo={titulo} />
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Sigla:*"
                                            className={classes.textField}
                                            id="sigla"
                                            name="sigla"
                                            inputRef={register}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={visualizar}
                                        />
                                        <p className={classes.errors}>
                                            {errors.sigla
                                                ? errors.sigla.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição:*"
                                            className={classes.textField}
                                            id="descricao"
                                            name="descricao"
                                            inputRef={register}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={visualizar}
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                {!visualizar && (
                                    <Grid container item xs={4} display="flex">
                                        <FormControlLabel
                                            control={
                                                <CustomSwitch
                                                    checked={
                                                        enableUnitConversion
                                                    }
                                                    onChange={handleEnableUnit}
                                                />
                                            }
                                            label="Cadastrar conversão de unidade?"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                )}
                                <ConversaoDeUnidade
                                    visualizar={visualizar}
                                    show={enableUnitConversion}
                                    options={unidadesDeMedida}
                                    unidadeConversao={unidadeConversao}
                                    handleAddUnitConversion={
                                        handleAddUnitConversion
                                    }
                                    handleRemoveUnitConversion={
                                        handleRemoveUnitConversion
                                    }
                                />
                            </CardContent>
                        </Card>
                        <FooterButtons disableSubmit={visualizar} />
                    </form>
                </Container>
            </Page>
        </>
    )
}
