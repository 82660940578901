import { SET_FILTRO_LISTAGEM_FORNECEDOR_CLIENTE } from '../actions/fornecedorCliente';

export const storageFornecedorCliente = 'fornecedorCliente';

const initialState = {
    filtroListagem: {},
};

export const fornecedorCliente = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_FORNECEDOR_CLIENTE:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
