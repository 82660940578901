import { SET_FILTRO_LISTAGEM_CENTRO_NEGOCIO } from '../actions/centroNegocio';

export const storageCentroNegocio = 'centroNegocio';

const initialState = {
    filtroListagem: {},
};

export const centroNegocio = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CENTRO_NEGOCIO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
