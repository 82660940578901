import { SET_FILTRO_LISTAGEM_PRODUTOR_RURAL } from '../actions/produtorRural'

export const storageProdutorRural = 'produtorRural'

const initialState = {
    filtroListagem: {},
}

export const produtorRural = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_PRODUTOR_RURAL:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
