import React from 'react'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { getUsername, getPerfil } from '../../services/Auth'
import {
    Card,
    CardHeader,
    Avatar,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        maxHeight: 70,
    },
    avatar: {
        cursor: 'pointer',
    },
}))

const UserMenu = () => {
    const classes = useStyles()

    const sair = () => {
        window.location.href = '/logout'
    }

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <Card elevation={0} className={classes.root}>
                        <CardHeader
                            avatar={
                                <Tooltip
                                    title={`${getUsername()} (${getPerfil()})`}
                                >
                                    <Avatar
                                        className={classes.avatar}
                                        alt={getUsername()}
                                        src="/broken-image.jpg"
                                        {...bindTrigger(popupState)}
                                    />
                                </Tooltip>
                            }
                        />
                    </Card>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem
                            onClick={() => {
                                sair()
                                popupState.close()
                            }}
                        >
                            Sair
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    )
}

export default UserMenu
