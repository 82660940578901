import TipoDocumentoService from 'services/TipoDocumentoService'
import FornecedorClienteService from 'services/FornecedorClienteService'
import ProdutosServicoService from 'services/ProdutosServicosService'
import ContaGerencialService from 'services/ContaGerencialService'
import ContaPagarReceberService from 'services/ContaPagarReceberService'
import ObjectHelper from 'utils/objetcHelper'
import ToastHelper from 'utils/toastHelper'
import LocaisEstoqueService from 'services/LocaisEstoqueService'
import SafraService from 'services/SafraService'
import CentroCustoService from 'services/CentroCustoService'
import UnidadeConversaoService from '../../services/UnidadeConversaoService'
import UnidadeDeMedidaService from '../../services/UnidadeDeMedidaService'
import PatrimonioService from 'services/PatrimonioService'
import ProdutorRuralService from 'services/ProdutorRuralService'
import GrupoSafraService from 'services/GrupoSafraService'

export default class ContasRequests {
    static validacaoFormModalProdutosServicos = async (form, tipoConta) => {
        var error = []

        // ( quantidade, valor unitário e valor total )

        if(!form.quantidade) error.push({ id: 'quantidade' })
        if(form.quantidade <= 0) error.push({ id: 'quantidade', message: 'Informe um valor positivo !'})

        if(!form.valorUnitario) error.push({id: 'valorUnitario'})
        if(form.valorUnitario <= 0) error.push({id: 'valorUnitario', message: 'Informe um valor positivo !'})

        if(!form.valorTotal) error.push({id: 'valorTotal'})
        if(form.valorTotal <= 0) error.push({id: 'valorTotal', message: 'Informe um valor positivo !'});
        
        // FIM ( quantidade, valor unitário e valor total )
        if(!form.grupoSafraId) {
            if(!form.centroCustoValidate || form.centroCustoValidate.tipo !== 0 || 
              form.centroCustoValidate.safras.length) {
               error.push({id:'grupoSafraId'})
            }
        }
        if (form.produtoServico?.produto) {
            if (form.centroCustoId) {
                if (!form.contaGerencialId)
                    error.push({
                        id: 'contaGerencialId',
                    })
                if (!form.produtoServicoId)
                    error.push({
                        id: 'produtoServicoId',
                    })
                if (!form.unidadeId) error.push({ id: 'unidadeId' })
                if (!form.centroCustoId) error.push({ id: 'centroCustoId' })
            } else {
                if (!form.contaGerencialId)
                    error.push({
                        id: 'contaGerencialId',
                    })
                if (!form.produtoServicoId)
                    error.push({
                        id: 'produtoServicoId',
                    })
                if (!form.unidadeId) error.push({ id: 'unidadeId' })
            }

        } else {
            if (!form.contaGerencialId)
                error.push({
                    id: 'contaGerencialId',
                })
            if (!form.produtoServicoId) error.push({ id: 'produtoServicoId' })
            if (!form.unidadeId) error.push({ id: 'unidadeId' })
        }
        if(form.alocacaoCusto === 'PATRIMONIO') {
            if(form.patrimonio === '' || !form.patrimonio) {
                error.push({ id: 'patrimonioId' })
            }
        }

        if (
            tipoConta === 'receber' &&
            form.alocacaoCusto === 'CENTRO_DE_CUSTO'
        ) {
            if (!form.centroCustoId) error.push({ id: 'centroCustoId' })
        }

        if (tipoConta === 'pagar' && form.alocacaoCusto === 'CENTRO_DE_CUSTO') {
            if (!form.centroCustoId) error.push({ id: 'centroCustoId' })
        }

        if (!form.alocacaoCusto) error.push({ id: 'alocacaoCusto' })


        return error
    }

    static buscarParcelasPorDespesaReceitaId = async (id) => {
        const { data } =
            await ContaPagarReceberService.buscarParcelasPorDespesaReceitaId(id)
        return data
    }

    static inserirProdutosServicos = async (form) => {
        let data = await ContaPagarReceberService.inserirProdutosServicos(form)
        return data
    }

    static cadastrar = async (dados) => {
        let data = {}
        if (dados.id) {
            data = await ContaPagarReceberService.atualizar(dados)
            return data
        } else {
            let cadastro = await ContaPagarReceberService.cadastrar(dados)
            return cadastro
        }
    }

    static buscarCentrosDeCusto = async () => {
        let filtro = {}
        filtro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await CentroCustoService.buscarTudo(params)
        return data.itens
    }

    static buscarSafra = async (grupoSafraId) => {
        const { data } = await SafraService.buscarSafrasPorGrupoSafra(
            JSON.parse(localStorage.getItem('fazenda')).value,
            grupoSafraId
        )
        return data
    }

    static buscarGrupoSafra = async () => {
        const { data } = await GrupoSafraService.buscarGrupoSafras(
            JSON.parse(localStorage.getItem('fazenda')).value
        )
        return data
    }

    static buscarLocaisEstoque = async () => {
        let filtro = {}
        filtro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await LocaisEstoqueService.buscarTudo(params)
        return data.itens
    }

    static buscarUnidadesDeMedida = async (unidadeAtual) => {
        if (unidadeAtual) {
            let options = []
            let filtro = {}
            filtro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
            filtro.unidade = unidadeAtual ? unidadeAtual : ''
            filtro.ativo = true
            filtro.tamanhoPagina = 99999
            let params = await ObjectHelper.createParams(filtro)

            await UnidadeConversaoService.buscarTudo(params).then(
                (response) => {
                    if (response.status === 200) {
                        response.data.forEach((item) => {
                            options.push({
                                id: item.unidadeConvertida['id'],
                                sigla: item.unidadeConvertida['sigla'],
                            })
                        })
                    } else {
                        ToastHelper(response.statusText)
                    }
                }
            )

            await UnidadeDeMedidaService.buscarPorId(filtro.unidade).then(
                (response) => {
                    if (response.status === 200) {
                        options.push({
                            id: response.data['id'],
                            sigla: response.data['sigla'],
                        })
                    } else {
                        ToastHelper(response.statusText)
                    }
                }
            )

            return options
        } else {
            let options = []
            let params = []
            params.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
            params.ativo = true
            params.tamanhoPagina = 99999

            let query = await ObjectHelper.createParams(params)
            await UnidadeDeMedidaService.buscarTudo(query).then((response) => {
                if (response.status === 200) {
                    response.data.itens.forEach((item) => {
                        options.push({
                            id: item['id'],
                            sigla: item['sigla'],
                        })
                    })
                } else {
                    ToastHelper(response.statusText)
                }
            })
            return options
        }
    }

    static buscarProdutosServicos = async () => {
        let filtro = {}
        filtro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await ProdutosServicoService.buscarTudo(params)
        return data.itens
    }

    static buscarFornecedoresClientes = async (TipoFornecedorCliente) => {
        if (TipoFornecedorCliente) {
            let filtro = {}
            filtro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
            filtro.ativo = true
            filtro.tamanhoPagina = 99999
            filtro.TipoFornecedorCliente =
                TipoFornecedorCliente.toUpperCase() === 'CLIENTE' ? 1 : 0
            let params = await ObjectHelper.createParams(filtro)
            const { data } = await FornecedorClienteService.buscarTudo(params)
            return ObjectHelper.removeInativos(
                ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(data.itens)
            )
        }
    }

    static buscarContasGerenciais = async (tipo) => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = JSON.parse(
            localStorage.getItem('fazenda')
        ).value
        filtroParaPesquisa.ativo = true
        filtroParaPesquisa.tamanhoPagina = 99999
        filtroParaPesquisa.tipo = 1
        filtroParaPesquisa.semPadroes = true;
        if (tipo) {
            tipo === 'pagar'
                ? (filtroParaPesquisa.operacao = 1)
                : (filtroParaPesquisa.operacao = 0)
        }
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        const { data } = await ContaGerencialService.buscarTudo(params)
        data.itens.forEach((item) => {
            item.descricao = `${item.numero} - ${item.descricao}`
        })
        let newItens = await ObjectHelper.ordenaLista(data.itens, 'descricao')
        return newItens
    }

    static buscarTiposDocumentos = async (contaAPagar) => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = JSON.parse(
            localStorage.getItem('fazenda')
        ).value
        filtroParaPesquisa.ativo = true
        filtroParaPesquisa.tamanhoPagina = 99999
        filtroParaPesquisa.tipo = contaAPagar ? 'compra' : 'venda'
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        const { data } = await TipoDocumentoService.buscarTudo(params)
        let result = data.itens

        return result
    }

    geraSituacao = (numSituacao) => {
        let situacao =
            numSituacao === 0
                ? { value: 'PREVISAO', label: 'Previsão' }
                : numSituacao === 0
                ? { value: 'DEFINITIVA', label: 'Definitiva' }
                : numSituacao === 0
                ? { value: 'APROVADA', label: 'Aprovada' }
                : { value: 'BAIXADA', label: 'Baixada' }
        return situacao
    }

    static apagarProdutosServicos = async (id) => {
        const { data } = await ContaPagarReceberService.apagarProdutosServicos(
            id
        )
        return data
    }

    static buscarConta = async (id) => {
        const { data } = await ContaPagarReceberService.buscarPorId(id)
        return data
    }

    static buscarPatrimonios = async () => {
        let novoFiltro = {}
        novoFiltro.decrescente = false
        novoFiltro.ordem = 'descricao'
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.ativo = true
        novoFiltro.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value

        let params = await ObjectHelper.createParams(novoFiltro)

        let novaLista = await PatrimonioService.buscarTudo(params)
            .then(async (response) => {
                if (response.status === 200) {
                    return response.data.itens
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar patrimônios')
            })

        return novaLista
    }

    static buscarProdutorRural = async () => {
        var fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        const { data } = await ProdutorRuralService.buscarTudoSelect(fazendaId)
        return data
    }

    static jaExisteDocumentoFornecedorCliente = async (
        fornecedorClienteId,
        documento,
        contaId = null
    ) => {
        var fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        let dto = {
            fazendaId: fazendaId,
            fornecedorClienteId: fornecedorClienteId,
            documento: documento,
            despesaReceitaId: contaId,
        }

        const { data } =
            await ContaPagarReceberService.validaDocumento(
                dto
            )
        return data
    }
}
