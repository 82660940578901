import { STORE_PORTAL_URL } from '../actions/portal';

export const storagePortalUrlKey = '@portal-url';

const initialState = {
    url: localStorage.getItem(storagePortalUrlKey),
};

export const portal = (state = initialState, action) => {
    switch (action.type) {
        case STORE_PORTAL_URL:
            if (action.url) {
                localStorage.setItem(storagePortalUrlKey, action.url);
            } else {
                localStorage.removeItem(storagePortalUrlKey);
            }

            return {
                ...state,
                url: action.url,
            };
        default:
            return state;
    }
};
