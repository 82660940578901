import * as Yup from 'yup'

export const PatrimonioEsquema = Yup.object().shape({
    numero: Yup.string().required('Campo obrigatório'),
    tipoPatrimonio: Yup.object().required('Campo obrigatório').nullable(),
    descricao: Yup.string().required('Campo obrigatório'),
    quantidadeItem: Yup.string()
        .required('Campo obrigatório')
        .test('val', 'Precisa ser maior que zero', function (item) {
            var itemFormatado = parseFloat(
                item.replaceAll('.', '').replace(',', '.').replace('R$', '')
            )
            if (item && itemFormatado <= 0) {
                return false
            } else {
                return true
            }
        }),
    unidade: Yup.object().required('Campo obrigatório').nullable(),
    tipoValor: Yup.object().required('Campo obrigatório').nullable(),
    valorUnitario: Yup.string()
        .required('Campo obrigatório')
        .test('val', 'Precisa ser maior que zero', function (item) {
            var itemFormatado = parseFloat(
                item.replaceAll('.', '').replace(',', '.').replace('R$', '')
            )
            if (item && itemFormatado <= 0) {
                return false
            } else {
                return true
            }
        }),
    valorTotal: Yup.string()
        .required('Campo obrigatório')
        .test('val', 'Precisa ser maior que zero', function (item) {
            var itemFormatado = parseFloat(
                item.replaceAll('.', '').replace(',', '.').replace('R$', '')
            )
            if (item && itemFormatado <= 0) {
                return false
            } else {
                return true
            }
        }),
    chassi: Yup.string(),
    placa: Yup.string(),
    tipoApuracao: Yup.object().nullable(),
    valorHora: Yup.string().when('tipoApuracao', {
        is: (val) => val && val.codigo === 'PORHORA',
        then: Yup.string().required('Campo obrigatório'),
    }),
    valorKm: Yup.string().when('tipoApuracao', {
        is: (val) => val && val.codigo === 'PORKM',
        then: Yup.string().required('Campo obrigatório'),
    }),
    centroCusto: Yup.object()
        .nullable()
        .when('tipoApuracao', {
            is: (val) => val != null,
            then: Yup.object().required('Campo obrigatório'),
        }),
    dataApuracaoCusto: Yup.string().when('tipoApuracao', {
        is: (val) => val != null,
        then: Yup.string().required('Campo obrigatório'),
    }),
    depreciavel: Yup.bool(),
    metodoDepreciacao: Yup.object()
        .nullable()
        .when('depreciavel', {
            is: true,
            then: Yup.object().nullable().required('Campo obrigatório'),
        }),
    vidaUtil: Yup.string().when('depreciavel', {
        is: true,
        then: Yup.string().required('Campo obrigatório'),
    }),
    referenciaVidaUtil: Yup.object()
        .nullable()
        .when('depreciavel', {
            is: true,
            then: Yup.object().nullable().required('Campo obrigatório'),
        }),
    valorResidual: Yup.string().when('depreciavel', {
        is: true,
        then: Yup.string().required('Campo obrigatório'),
    }),
})
