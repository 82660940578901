import React, { useState, useEffect } from 'react'
import { Button, TextField, Divider, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { useForm } from 'react-hook-form'
import FormaPagamentoService from '../../services/TipoPagamentoRecebimentoService'
import ContaBancariaService from '../../services/ContaBancariaService'
import CamposDinamicosService from '../../services/CamposParcelaService'
import DespesaReceitaService from '../../services/ContaPagarReceberService'
import ToastHelper from '../../../src/utils/toastHelper'
import ObjectHelper from '../../../src/utils/objetcHelper'
import DateHelper from '../../../src/utils/dataHelpder'
import moment from 'moment'
//import AdiantamentoService from 'services/AdiantamentoFornecedoresClientesService'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 100,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    valorTotal: {
        display: 'inline-flex !important',
    },
    hidden: {
        display: 'none',
    },
}))

export default function ModalQuitarContas(props) {
    const classes = useStyles()

    const [opcoesFormaPagamento, setOpcoesFormaPagamento] = useState([])
    const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState()
    const [opcoesConta, setOpcoesConta] = useState([])
    const [camposDinamicos, setCamposDinamicos] = useState([])
    const [FazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : null
    )

    const [erroDataPagamento, setErroDataPagamento] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroDataCompensacao, setErroDataCompensacao] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroFormaPagamento, setErroFormaPagamento] = useState({
        erro: false,
        mensagem: '',
    })
    const [erroConta, setErroConta] = useState({
        erro: false,
        mensagem: '',
    })

    const [dataPagamento, setDataPagamento] = useState(
        new Intl.DateTimeFormat('fr-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(Date.now())
    )
    const [dataCompensacao, setDataCompensacao] = useState()
    const [valorDocumento, setValorDocumento] = useState()
    const [contaSelecionada, setContaSelecionada] = useState()
    const [valorTotal, setValorTotal] = useState()
    const [observacao, setObservacao] = useState()
    const [contasSelecionadas, setContasSelecionadas] = useState([])
    const [saldoAdiantamento] = useState(0)
    const [saldoAdiantamentoPosQuitacao, setSaldoAdiantamentoPosQuitacao] =
        useState(0)
    const [numeroDocumento, setNumeroDocumento] = useState()
    const [conciliando, setConciliando] = useState(false)

    const { register, handleSubmit, setValue, getValues } = useForm({})

    useEffect(() => {
        async function init() {
            await buscarCamposDinamicos()
            await buscarContas()
            await setarContasSelecionadasParaPagamento()
            await buscarFormasPagamento()
        }
        init()
        if (props.conciliacao !== undefined) {
            setConciliando(true)
            if (props.conciliacao.length === 1) buscarParaConciliacao()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.contasSelecionadas.length > 0) {
            let valorContas = 0
            props.contasSelecionadas.forEach((parcela) => {
                let stringValor = parcela.valor.replace('R$ ', '')
                valorContas =
                    valorContas +
                    parseFloat(stringValor.replace(/\./g, '').replace(',', '.'))
            })
            setValorDocumento(
                valorContas.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })
            )
            setValorTotal(
                valorContas.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModalQuitarContas])

    useEffect(() => {
        async function init() {
            let vTotal = valorTotal
                ? parseFloat(
                      valorTotal
                          .replace('R$', '')
                          .replaceAll('.', '')
                          .replaceAll(',', '.')
                  )
                : 0
            setSaldoAdiantamentoPosQuitacao(saldoAdiantamento - vTotal)
        }
        init()
    }, [valorTotal, saldoAdiantamento])

    /*const buscarAdiantamentos = async (opcoesAtuaisDePagamento) => {
        if (props.contasSelecionadas.length > 1) {
            let contaAnterior
            let valid = true
            await props.contasSelecionadas.forEach((conta) => {
                if (
                    contaAnterior &&
                    contaAnterior.fornecedorCliente.id !==
                        conta.fornecedorCliente.id
                ) {
                    valid = false
                }
                contaAnterior = conta
            })
            if (valid) {
                await buscarSaldoAdiantamento(
                    props.contasSelecionadas[0].fornecedorCliente.id,
                    opcoesAtuaisDePagamento
                )
            }
        } else {
            await buscarSaldoAdiantamento(
                props.contasSelecionadas[0].fornecedorCliente.id,
                opcoesAtuaisDePagamento
            )
        }
    }

    const buscarSaldoAdiantamento = async (
        idFornecedorCliente,
        opcoesAtuaisDePagamento
    ) => {
        await AdiantamentoService.buscarSaldoAdiantamentoPorFornecedorClienteId(
            idFornecedorCliente
        )
            .then((response) => {
                if (response.data.isOk)
                    if (response.data.result.temAdiantamento) {
                        let newOpcoesFormaPagamento = [
                            ...opcoesAtuaisDePagamento,
                        ]
                        newOpcoesFormaPagamento.push({
                            label: 'Adiantamento',
                            value: 'ADIANTAMENTO',
                            codigo: 'ADIANTAMENTO',
                        })
                        setOpcoesFormaPagamento(newOpcoesFormaPagamento)
                        setSaldoAdiantamento(response.data.result.saldo)
                    }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar contratos')
            })
    }*/

    const setarContasSelecionadasParaPagamento = async () => {
        props.contasSelecionadas.length > 0
            ? setContasSelecionadas(props.contasSelecionadas)
            : setContasSelecionadas([])
    }

    const buscarCamposDinamicos = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId
        filtro.ativo = true
        filtro.AplicadoContasPagarReceber = props.tipo
        let params = await ObjectHelper.createParams(filtro)
        await CamposDinamicosService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setCamposDinamicos(response.data.itens)
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarFormasPagamento = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId
        let params = await ObjectHelper.createParams(filtro)
        await FormaPagamentoService.buscarTudo(params).then(
            async (response) => {
                if (response.status === 200) {
                    await setOpcoesFormaPagamento(
                        ObjectHelper.getValuesFormaPagamentoParseToSelect(
                            response.data.itens
                        )
                    )
                    /*await buscarAdiantamentos(
                        ObjectHelper.getValuesFormaPagamentoParseToSelect(
                            response.data.itens
                        )
                    )*/
                } else {
                    ToastHelper.error(response.statusText)
                }
            }
        )
    }

    const buscarContas = async () => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = FazendaId
        filtroParaPesquisa.Ativo = true
        filtroParaPesquisa.tamanhoPagina = 9999999
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        await ContaBancariaService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setOpcoesConta(
                    ObjectHelper.getValuesDescricaoLabelParseToSelect(
                        response.data.itens
                    )
                )
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const limpaErros = () => {
        setErroDataCompensacao({
            erro: false,
            mensagem: '',
        })
        setErroDataPagamento({
            erro: false,
            mensagem: '',
        })
        setErroFormaPagamento({
            erro: false,
            mensagem: '',
        })
        setErroConta({
            erro: false,
            mensagem: '',
        })
    }

    const validaCampos = () => {
        let valido = true

        if (
            formaPagamentoSelecionada &&
            formaPagamentoSelecionada.value === 'ADIANTAMENTO' &&
            saldoAdiantamentoPosQuitacao < 0
        ) {
            ToastHelper.error(
                'Saldo de adiantamento insuficiente para o pagamento.'
            )
            valido = false
        }

        if (!dataPagamento) {
            setErroDataPagamento({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            var partesData = dataPagamento.split('-')
            var data = new Date(partesData[0], partesData[1] - 1, partesData[2])
            if (data > new Date()) {
                ToastHelper.error(
                    'Data de pagamento não pode ser maior que a data atual.'
                )
                valido = false
            }
            setErroDataPagamento({
                erro: false,
                mensagem: '',
            })
        }

        if (!formaPagamentoSelecionada) {
            setErroFormaPagamento({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroFormaPagamento({
                erro: false,
                mensagem: '',
            })
        }

        if (!contaSelecionada) {
            setErroConta({
                erro: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroConta({
                error: false,
                mensagem: '',
            })
        }
        var dataAtual = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')

        if (dataCompensacao) {
            if (dataCompensacao < dataPagamento) {
                setErroDataCompensacao({
                    erro: true,
                    mensagem:
                        'Data de compensação não pode ser menor que a data de pagamento! ',
                })
                valido = false
            } else if (dataCompensacao > dataAtual) {
                setErroDataCompensacao({
                    erro: true,
                    mensagem:
                        'Data de compensação não pode ser maior que data atual!',
                })
                valido = false
            } else {
                setErroDataCompensacao({
                    erro: false,
                    mensagem: '',
                })
            }
        }

        return valido
    }

    const submit = async () => {
        if (props.conciliacao) {
            if (!dataCompensacao) {
                return setErroDataCompensacao({
                    erro: true,
                    mensagem: 'Campo Obrigatório',
                })
            }
            var params = []
            props.conciliacao.forEach((item) => {
                params.push({
                    id: item.id,
                    dataCompensacao:
                        DateHelper.transformStringDataUsaToPtBr(
                            dataCompensacao
                        ),
                })
            })
            DespesaReceitaService.atualizarDataCompensacao(params)
                .then((res) => {
                    if (res.status === 200) {
                        ToastHelper.success('Operação realizada com sucesso')
                        props.close()
                        props.buscarContas()
                    } else {
                        ToastHelper.error(res.statusText)
                    }
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data.message)
                })
        } else {
            if (validaCampos()) {
                let idContasSelecionadas = []
                contasSelecionadas.forEach((conta) => {
                    idContasSelecionadas.push(conta.id)
                })

                let valoresCamposDinamicos = []
                await camposDinamicos.forEach((item) => {
                    for (var [campo, valor] of Object.entries(getValues())) {
                        if (
                            campo
                                .replace('(-)', '')
                                .replace('(+)', '')
                                .toLowerCase() === item.nome.toLowerCase()
                        ) {
                            valoresCamposDinamicos.push({
                                Id: item.id,
                                Valor: parseFloat(
                                    valor.replace(',', '.')
                                ).toFixed(2),
                            })
                        }
                    }
                })

                let quitacao = {
                    dataPagamento: dataPagamento,
                    dataCompensacao: dataCompensacao ? dataCompensacao : null,
                    valorDocumento: parseFloat(
                        valorDocumento
                            .replace('R$', '')
                            .replaceAll('.', '')
                            .replaceAll(',', '.')
                    ),
                    valorTotal: parseFloat(
                        valorTotal
                            .replace('R$', '')
                            .replaceAll('.', '')
                            .replaceAll(',', '.')
                    ),
                    formaPagamento: formaPagamentoSelecionada
                        ? formaPagamentoSelecionada.value
                        : '',
                    conta: contaSelecionada ? contaSelecionada.value : '',
                    numeroDocumento: numeroDocumento,
                    camposDinamicos: valoresCamposDinamicos,
                    observacao: observacao,
                    idContasSelecionadas: idContasSelecionadas,
                }

                await DespesaReceitaService.quitarContas(quitacao)
                    .then((response) => {
                        ToastHelper.success('Operação realizada com sucesso.')
                        props.buscarContas()
                        props.close()
                        limpaErros()
                    })
                    .catch((error) => {
                        ToastHelper.error('Erro ao tentar pagar conta(s).')
                    })
            }
        }
    }

    const atualizaValorTotal = (valorDoc) => {
        let novoValorTotal = valorDoc ? parseFloat(valorDoc) : valorDocumento
        novoValorTotal = novoValorTotal
            .replaceAll('R$', '')
            .replaceAll('.', '')
            .replace(',', '.')
        if (contasSelecionadas.length === 1) {
            for (var [campo, valor] of Object.entries(getValues())) {
                if (campo.indexOf('(-)') !== -1) {
                    if (valor) {
                        novoValorTotal =
                            novoValorTotal - valor.replace(',', '.')
                    }
                } else {
                    if (valor) {
                        novoValorTotal =
                            parseFloat(novoValorTotal) +
                            parseFloat(valor.replace(',', '.'))
                    }
                }
            }
        }
        setValorTotal(
            parseFloat(novoValorTotal).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        )
    }

    const buscarParaConciliacao = async () => {
        //Busca conciliação se tiver apenas uma conta selecionada
        var row = props.conciliacao[0]
        if (row) {
            setDataPagamento(row?.dataPagamento)
            setFormaPagamentoSelecionada({
                label: row?.formaPagamento?.nome,
                value: '',
            })
            setContaSelecionada({
                label: row?.contaBancariaPagamento?.descricao,
            })
            setNumeroDocumento(row?.documento)
            setObservacao(row?.descricao)
        }
    }

    return (
        <>
            <div className={classes.paper}>
                <h2 className={classes.title}>
                    {conciliando
                        ? 'Conciliação de contas'
                        : 'Pagamento de contas'}
                </h2>

                <form className={classes.form} onSubmit={handleSubmit(submit)}>
                    <Divider className={classes.divider} />
                    <Grid container item xs={12} display="flex">
                        {!conciliando || props.conciliacao.length === 1 ? (
                            <Grid container item xs={4} display="flex">
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        disabled={conciliando}
                                        label="Data de Pagamento:*"
                                        name="dataPagamento"
                                        type={conciliando ? 'text' : 'date'}
                                        value={dataPagamento}
                                        onChange={(e) =>
                                            setDataPagamento(e.target.value)
                                        }
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={
                                            {
                                                inputProps: {max: '9999-12-31'}
                                            }
                                        }
                                    />
                                    <p className={classes.errors}>
                                        {erroDataPagamento.erro
                                            ? erroDataPagamento.mensagem
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                        ) : (
                            ''
                        )}

                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    label={'Data de Compensação:'}
                                    name="dataCompensacao"
                                    type="date"
                                    value={dataCompensacao}
                                    onChange={(e) =>
                                        setDataCompensacao(e.target.value)
                                    }
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={
                                        {
                                            inputProps: {max: '9999-12-31'}
                                        }
                                    }
                                />
                                <p className={classes.errors}>
                                    {erroDataCompensacao.erro
                                        ? erroDataCompensacao.mensagem
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid
                            className={
                                props.conciliacao?.length > 1
                                    ? classes.hidden
                                    : classes.valorTotal
                            }
                            container
                            item
                            xs={3}
                            style={{ marginRight: '2%', marginTop: '2%' }}
                        >
                            <FormControl
                                className={classes.formControl}
                                display="inline-flex"
                            >
                                <div
                                    className={classes.valorTotal}
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {contasSelecionadas.length > 1
                                            ? '(=) Valor dos documentos: '
                                            : '(=) Valor do documento: '}
                                    </h4>
                                    <span>{valorDocumento}</span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    {camposDinamicos.length > 0 &&
                        contasSelecionadas.length === 1 && (
                            <>
                                <Grid container item xs={12} display="flex">
                                    {camposDinamicos.map((item) => (
                                        <Grid
                                            container
                                            item
                                            xs={4}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={
                                                    props.conciliacao?.length >
                                                    1
                                                        ? classes.hidden
                                                        : classes.formControl
                                                }
                                            >
                                                <TextField
                                                    disabled={conciliando}
                                                    type="text"
                                                    name={
                                                        (item.tipoCodigo === 0
                                                            ? '(-)'
                                                            : '(+)') +
                                                        item.nome.toLowerCase()
                                                    }
                                                    id={
                                                        (item.tipoCodigo === 0
                                                            ? '(-)'
                                                            : '(+)') +
                                                        item.nome.toLowerCase()
                                                    }
                                                    inputRef={register}
                                                    variant="standard"
                                                    autoComplete="off"
                                                    label={
                                                        (item.tipoCodigo === 0
                                                            ? '(-) '
                                                            : '(+) ') +
                                                        item.nome
                                                    }
                                                    onChange={(e) => {
                                                        setValue(
                                                            (item.tipoCodigo ===
                                                            0
                                                                ? '(-)'
                                                                : '(+)') +
                                                                item.nome.toLowerCase(),
                                                            e.target.value
                                                        )
                                                        atualizaValorTotal()
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}
                    <Grid
                        className={
                            props.conciliacao?.length > 1
                                ? classes.hidden
                                : classes.formControl
                        }
                        container
                        item
                        xs={12}
                        display="inline-flex"
                    >
                        <Grid
                            className={
                                props.conciliacao?.length > 1
                                    ? classes.hidden
                                    : classes.valorTotal
                            }
                            container
                            item
                            xs={3}
                            style={{ marginRight: '2%' }}
                        >
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                                display="inline-flex"
                            >
                                <div
                                    className={
                                        props.conciliacao?.length > 1
                                            ? classes.hidden
                                            : classes.valorTotal
                                    }
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {'(=) Valor Total: '}
                                    </h4>
                                    <span>
                                        {valorTotal?.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}
                                    </span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />

                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={4} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                            >
                                <Autocomplete
                                    options={opcoesFormaPagamento}
                                    disableClearable
                                    disabled={conciliando}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, option) => {
                                        setFormaPagamentoSelecionada(option)
                                    }}
                                    value={formaPagamentoSelecionada || {}}
                                    defaultValue={
                                        formaPagamentoSelecionada
                                            ? formaPagamentoSelecionada
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Forma de Pagamento:*'}
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {erroFormaPagamento.erro
                                        ? erroFormaPagamento.mensagem
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                            >
                                <Autocomplete
                                    options={opcoesConta}
                                    disabled={conciliando}
                                    disableClearable
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, option) => {
                                        setContaSelecionada(option)
                                    }}
                                    value={contaSelecionada || {}}
                                    defaultValue={
                                        contaSelecionada ? contaSelecionada : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={'Conta:*'}
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {erroConta.erro ? erroConta.mensagem : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControl
                                }
                            >
                                <TextField
                                    type="number"
                                    name="numeroDocumento"
                                    disabled={conciliando}
                                    id="numeroDocumento"
                                    variant="standard"
                                    autoComplete="off"
                                    label={
                                        formaPagamentoSelecionada &&
                                        formaPagamentoSelecionada.codigo ===
                                            'CHEQUE'
                                            ? 'Nº do cheque:'
                                            : 'Nº do documento:'
                                    }
                                    value={numeroDocumento}
                                    onChange={(e) =>
                                        setNumeroDocumento(e.target.value)
                                    }
                                    placeholder="Digite o numero"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {formaPagamentoSelecionada &&
                        formaPagamentoSelecionada.value === 'ADIANTAMENTO' && (
                            <>
                                <Divider className={classes.divider} />
                                <Grid container item xs={12} display="flex">
                                    <Grid container item xs={12} display="flex">
                                        <span
                                            style={{
                                                marginLeft: '10px',
                                            }}
                                        >
                                            {'Detalhes do adiantamento'}
                                        </span>
                                    </Grid>
                                    <Grid container item xs={4} display="flex">
                                        <FormControl
                                            className={
                                                props.conciliacao?.length > 1
                                                    ? classes.hidden
                                                    : classes.formControlTextArea
                                            }
                                        >
                                            <div
                                                className={
                                                    props.conciliacao?.length >
                                                    1
                                                        ? classes.hidden
                                                        : classes.valorTotal
                                                }
                                                display="inline-flex"
                                            >
                                                <h5
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    {'(=) Saldo Adiantamento: '}
                                                </h5>
                                                <span>
                                                    {saldoAdiantamento.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        }
                                                    )}
                                                </span>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={8} display="flex">
                                        <FormControl
                                            className={
                                                props.conciliacao?.length > 1
                                                    ? classes.hidden
                                                    : classes.formControlTextArea
                                            }
                                        >
                                            <div
                                                className={
                                                    props.conciliacao?.length >
                                                    1
                                                        ? classes.hidden
                                                        : classes.valorTotal
                                                }
                                                display="inline-flex"
                                            >
                                                <h5
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    {
                                                        '(=) Saldo Adiantamento pós pagamento: '
                                                    }
                                                </h5>
                                                <span
                                                    style={
                                                        saldoAdiantamentoPosQuitacao >
                                                        0
                                                            ? {
                                                                  color: '#2E7D32',
                                                              }
                                                            : { color: 'red' }
                                                    }
                                                >
                                                    {saldoAdiantamentoPosQuitacao.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        }
                                                    )}
                                                </span>
                                                <p
                                                    className={classes.errors}
                                                    style={{
                                                        marginLeft: '15px',
                                                    }}
                                                >
                                                    {saldoAdiantamentoPosQuitacao <
                                                    0
                                                        ? 'Saldo de adiantamento insuficiente'
                                                        : ''}
                                                </p>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                            </>
                        )}
                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={12} display="flex">
                            <FormControl
                                className={
                                    props.conciliacao?.length > 1
                                        ? classes.hidden
                                        : classes.formControlTextArea
                                }
                            >
                                <TextField
                                    type="text"
                                    label="Observações"
                                    disabled={conciliando}
                                    className={classes.textField}
                                    id="observacao"
                                    value={observacao}
                                    defaultValue={observacao}
                                    onChange={(e) =>
                                        setObservacao(e.target.value)
                                    }
                                    name="observacao"
                                    multiline
                                    variant="outlined"
                                    rows={2}
                                    isplaceholderanimated={(false, 'top')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.close()
                                limpaErros()
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="secundary"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Cadastrar
                        </Button>
                    </Grid>
                </form>
            </div>
        </>
    )
}
