import React from 'react'
import Table from '../../components/Table'
import { colors } from '@material-ui/core'
import EditCompleteIcon from '@material-ui/icons/EditOutlined'
import LigaDesliga from '@material-ui/icons/PowerSettingsNew'
import IconeAtivo from '@material-ui/icons/CheckCircle'
import IconeInativo from '@material-ui/icons/Cancel'

export default function ProdutorRuralListagem(props) {
    const colunas = [
        { title: 'Nome', field: 'nome', editable: 'never' },
        { title: 'Data de Início', field: 'dataInicio', editable: 'never' },
        { title: 'Fazenda', field: 'fazenda', editable: 'never' },
        {
            title: 'Situação',
            field: 'ativo',
            editable: 'never',
            render: (rowData) =>
                rowData.ativo ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                pageSize={props.pageSize}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                emptyRowsWhenPaging={false}
                editarCelula={props.editarCelula ? props.editarCelula : null}
                actions={[
                    {
                        icon: LigaDesliga,
                        tooltip: 'Ativar / Desativar',
                        onClick: (event, rowData) =>
                            props.ativarDesativar(rowData.id),
                    },
                    {
                        icon: EditCompleteIcon,
                        tooltip: 'Edição',
                        onClick: (event, rowData) => props.editar(rowData.id),
                    },
                ]}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                }}
            />
        </>
    )
}
