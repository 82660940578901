import { GestaoContrato } from '../pages/gestaoContrato/index'
import { GestaoContratoCadastro } from '../pages/gestaoContrato/GestaoContratoCadastro'

export const GestaoContratoRoute = [
    {
        path: '/app/gestaocontrato',
        key: 'APP_GESTAO_CONTRATO_LISTAGEM',
        exact: true,
        component: GestaoContrato,
    },
    {
        path: '/app/gestaocontrato/cadastro',
        key: 'APP_GESTAO_CONTRATO_RECEBER_CADASTRO',
        exact: true,
        component: GestaoContratoCadastro,
    },
    {
        path: '/app/gestaocontrato/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_GESTAO_CONTRATO_RECEBER_EDITAR_VISUALIZAR',
        exact: true,
        component: GestaoContratoCadastro,
    },
]
