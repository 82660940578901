import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/TipoDocumento/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/TipoDocumento${param}`);
    },
    cadastrar(param) {
        return service.post(`/TipoDocumento`, param);
    },
    atualizar(param) {
        return service.put(`/TipoDocumento`, param);
    },
    ativarDesativar(id) {
        return service.put(`/TipoDocumento/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/TipoDocumento/${id}`);
    }
};
