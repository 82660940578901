import React from 'react'

const Logo = (props) => {
    const portal = () => {
        const portalUrl = localStorage.getItem('@portal-url')
        const fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        let url = `${portalUrl}`
        if (fazendaId) {
            url = `${url}/?fazendaId=${fazendaId}`
        }
        window.location.href = url
    }

    return (
        <img
            alt="Logo"
            src="/logo.png"
            {...props}
            width={150}
            style={{ cursor: 'pointer' }}
            onClick={() => portal()}
        />
    )
}

export default Logo
