import React from 'react'
import Table from '../../components/Table'
import { colors } from '@material-ui/core'
import EditCompleteIcon from '@material-ui/icons/EditOutlined'
import { useHistory } from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import PeopleIcon from '@material-ui/icons/People'

export default function CentroCustoListagem(props) {
    const history = useHistory()

    const rateio = (row) => {
        history.push(
            `/app/centroCusto/cadastro/rateio/${row.id}`
        )
    }

    const safra = (row) => {
        history.push(`/app/centroCusto/cadastro/safra/${row.id}`)
    }

    const colunas = [
        { title: 'Código', field: 'codigo', editable: 'never' },
        { title: 'Descrição', field: 'descricao', editable: 'never' },
        { title: 'Tipo', field: 'tipoNome', editable: 'never' },
        {
            title: 'Rateio',
            field: 'rateio',
            editable: 'never',
            sorting: false,
            render: (rowData) =>
                rowData.tipoNome === 'Rateado' ? (
                    <AddCircleIcon
                        style={{ color: colors.blue[800], cursor: 'pointer' }}
                        onClick={() => rateio(rowData)}
                    />
                ) : (
                    <AddCircleIcon
                        style={{ color: colors.blue[50] }}
                        disabled
                    />
                ),
        },
        {
            title: 'Safras',
            field: 'safras',
            editable: 'never',
            sorting: false,
            render: (rowData) =>
                rowData.tipoNome === 'Produtivo' ? (
                    <AddCircleIcon
                        style={{ color: colors.blue[800], cursor: 'pointer' }}
                        onClick={() => safra(rowData)}
                    />
                ) : (
                    <AddCircleIcon
                        style={{ color: colors.blue[50] }}
                        disabled
                    />
                ),
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                pageSize={props.pageSize}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                emptyRowsWhenPaging={false}
                editarCelula={props.editarCelula}
                actions={[
                    {
                        icon: EditCompleteIcon,
                        tooltip: 'Edição',
                        onClick: (event, rowData) => props.editar(rowData.id),
                    },
                    {
                        icon: PeopleIcon,
                        tooltip: 'Associar colaboradores',
                        onClick: (event, rowData) =>
                            props.associarColaboradores(rowData.id),
                    },
                ]}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                }}
            />
        </>
    )
}
