import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { useHistory } from 'react-router-dom'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import TransferenciaBancariaListagem from './transferenciaBancariaListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import TransferenciaBancariaService from '../../services/TransferenciaBancariaService'
import ContaBancariaService from '../../services/ContaBancariaService'
import ToastHelper from '../../utils/toastHelper'
import DataHelper from '../../utils/dataHelpder'
import ObjectHelper from '../../utils/objetcHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const TransferenciaBancaria = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [loading, setLoading] = useState(false)
    const [transferenciasBancarias, setTransferenciasBancarias] = useState()
    const [contasBancarias, setContasBancarias] = useState([])
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.transferenciaBancaria.filtroListagem)
    )

    useEffect(() => {
        const getTransferenciasBancarias = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'Data'
            novoFiltro.tamanhoPagina = 100
            buscarTransferenciasBancarias(novoFiltro)
        }
        getTransferenciasBancarias()
        buscarContasBancarias()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_TRANSFERENCIA_BANCARIA',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarTransferenciasBancarias(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_TRANSFERENCIA_BANCARIA',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/TransferenciaBancaria/cadastro`,
        })
    }

    const deletar = async (item) => {
        await TransferenciaBancariaService.deletar(item.id).then((response) => {
            if (response.status === 200) {
                ToastHelper.success(
                    'Transferência bancária excluída com sucesso!'
                )
                let novoFiltro = { ...filtro }
                buscarTransferenciasBancarias(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const visualizar = async (id) => {
        history.push(`/app/transferenciaBancaria/visualizar/${id}`)
    }

    const buscarTransferenciasBancarias = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.contaDebidoId = filtroParaPesquisa.contaDebitoId
            ? filtro.contaDebitoId.value
            : ''
        filtroParaPesquisa.contasCreditoId = filtroParaPesquisa.contaCreditoId
            ? filtro.contaCreditoId.value
            : ''
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await TransferenciaBancariaService.buscarTudo(params).then(
            (response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.fazendaId = fazendaId
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                        ? filtro.tamanhoPagina
                        : 100
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    alteraFiltro(newFiltro)
                    let novosItens = trataItensParaTabela(response.data.itens)
                    setTransferenciasBancarias(novosItens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const buscarContasBancarias = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        ContaBancariaService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newContas =
                    ObjectHelper.getValuesDescricaoLabelParseToSelect(
                        response.data.itens
                    )
                setContasBancarias(newContas)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarTransferenciasBancarias(novoFiltro)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarTransferenciasBancarias(novoFiltro)
    }

    const trataItensParaTabela = (itens) => {
        let novosItens = []

        itens.map((item) => {
            item.data = item.data
                ? DataHelper.transformStringDataUsaToPtBr(
                      DataHelper.formatStringDataBackToFront(item.data)
                  )
                : ''
            novosItens.push(item)
            return item
        })

        return novosItens
    }

    return (
        <Page className={classes.root} title="Transferência Bancária">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Transferência Bancária" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    temFiltroAvancado
                    filtroPeriodo
                    filtroContasDebito={contasBancarias}
                    filtroContasCredito={contasBancarias}
                    filtroNumeroDocumento
                />
                <Box mt={3}>
                    <TransferenciaBancariaListagem
                        data={transferenciasBancarias}
                        pageSize={filtro.tamanhoPagina}
                        filtro={filtro}
                        isLoading={loading}
                        deletar={deletar}
                        visualizar={visualizar}
                        mudaOrdenacao={mudaOrdenacao}
                        onChangePage={(page) => onChangePage(page)}
                    />
                </Box>
            </Container>
        </Page>
    )
}
