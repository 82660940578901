import { ExtratoBancario } from '../pages/extratoBancario';

export const ExtratoBancarioRoute = [
    {
        path: '/app/extratoBancario',
        key: 'APP_EXTRATO_BANCARIO',
        exact: true,
        component: ExtratoBancario,
    },
];
