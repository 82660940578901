import ContaBancariaService from 'services/ContaBancariaService'
import ContaPagarReceberService from 'services/ContaPagarReceberService'

import ObjectHelper from 'utils/objetcHelper'

const FAZENDA = JSON.parse(localStorage.getItem('fazenda'))

export default class ConciliacaoBancariaRequest {
    static buscarContasBancarias = async () => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value
        filtro.ativo = true
        filtro.tamanhoPagina = 9999999
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await ContaBancariaService.buscarTudo(params)
        return data.itens
    }

    static atualizarDataCompensacao = async (param) => {
        let data = await ContaPagarReceberService.atualizarDataCompensacao(
            param
        )
        return data
    }

    static buscarConciliacoesBancarias = async (form) => {
        let filtro = { ...form }
        filtro.situacao = filtro.situacao.codigo
        filtro.fazendaId = FAZENDA.value
        filtro.saldoDoExtrato = filtro.saldoDoExtrato
            .replaceAll('.', '')
            .replace(',', '.')
            .replace('R$', '')

        let params = await ObjectHelper.createParams(filtro)
        const { data } = await ContaPagarReceberService.conciliacaoBancaria(
            params
        )
        return data
    }
}
