import React from 'react'
import { Card, Grid, makeStyles } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import Table from '../../components/Table'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    list: {
        width: 'auto',
    },
    fullList: {
        width: 'auto',
    },
    card: {
        overflow: 'auto',
        height: '400px',
    },
}))

export default function MenuSuspensoDetalhesDeCusto(props) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#009688',
            },
        },
        overrides: {
            MuiGrid: {
                container: {
                    display: 'inline !important',
                    width: '100% !important',
                },
            },
        },
    })

    const classes = useStyles()

    const colunas = [
        {
            title: 'Data da Ação',
            field: 'dataAcao',
            editable: 'never',
            sorting: false,
        },
        {
            title: 'Descrição',
            field: 'descricao',
            editable: 'never',
            sorting: false,
        },
        {
            title: 'Valor',
            field: 'valor',
            editable: 'never',
            sorting: false,
            render: (prop) => (
                <div>
                    {prop.valor.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            ),
        },
    ]

    const conteudo = (anchor) => (
        <MuiThemeProvider theme={theme}>
            <Card className={classes.card}>
                <Grid
                    container
                    item
                    md={12}
                    style={{ width: '100% !important' }}
                >
                    <Table
                        title={'Detalhes'}
                        maxBodyHeight={'300px'}
                        removeToolbar={false}
                        draggable={false}
                        columns={colunas}
                        data={props.itemSelecionado}
                        paging={false}
                        haveParentChild={false}
                        emptyRowsWhenPaging={false}
                    />
                </Grid>
            </Card>
        </MuiThemeProvider>
    )

    return (
        <div>
            <Drawer
                anchor={'bottom'}
                open={props.stateMenuSuspenso}
                onClose={props.toggleDrawer('bottom', false)}
            >
                {conteudo('bottom')}
            </Drawer>
        </div>
    )
}
