import service from './service';

export default {
    buscarTudo(params) {
        return service.get(`/Estoque${params}`);
    },
    buscar(produtoId, fazendaId) {
        return service.get(`/Estoque/${produtoId}/${fazendaId}`);
    },
    buscarValorUnitarioNoEstoque(produtoId, fazendaId, localEstoque) {
        return service.get(`/Estoque/${produtoId}/${fazendaId}/${localEstoque}`);
    },
};
