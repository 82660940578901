import { TransferenciaEstoque } from '../pages/transferenciaEstoque/index';
import { TransferenciaEstoqueCadastro } from '../pages/transferenciaEstoque/TransferenciaEstoqueCadastro';

export const TransferenciaEstoqueRoute = [
    {
        path: '/app/transferenciaEstoque',
        key: 'APP_TRANSFERENCIA_ESTOQUE',
        exact: true,
        component: TransferenciaEstoque,
    },
    {
        path: '/app/transferenciaEstoque/cadastro',
        key: 'APP_TRANSFERENCIA_ESTOQUE_CADASTRO',
        exact: true,
        component: TransferenciaEstoqueCadastro,
    },
    {
        path: '/app/transferenciaEstoque/:modo(visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})', // Não há uma ação para editar na página de listagem
        key: 'APP_TRANSFERENCIA_ESTOQUE_EDITAR_VISUALIZAR',
        exact: true,
        component: TransferenciaEstoqueCadastro,
    },
    {
        path: '/app/transferenciaEstoque/cadastro/:local([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:produto([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_TRANSFERENCIA_ESTOQUE_CADASTRO',
        exact: true,
        component: TransferenciaEstoqueCadastro,
    },
];
