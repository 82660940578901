import service from './service'

export default {
    cadastrar(param) {
        return service.post(`/tipoPatrimonio`, param)
    },
    buscarTudo(param) {
        return service.get(`/tipoPatrimonio${param}`)
    },
    ativarDesativar(id) {
        return service.put(`/tipoPatrimonio/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/tipoPatrimonio/${id}`);
    },
    buscarPorId(id) {
        return service.get(`/tipoPatrimonio/${id}`)
    },
    atualizar(param) {
        return service.put(`/tipoPatrimonio`, param)
    },
}
