import service from './service'

export default {
    buscarPorId(id) {
        return service.get(`/pedidos/${id}`)
    },
    buscarFornecedorClientePorId(id) {
        return service.get(`/pedidos/fornecedorCliente/${id}`)
    },
    buscarTudo(param) {
        return service.get(`/pedidos${param}`)
    },

    cadastrar(param) {
        return service.post(`/pedidos`, param)
    },

    atualizar(param) {
        return service.put(`/pedidos`, param)
    },

    deletar(id) {
        return service.delete(`/pedidos/${id}`)
    },

    quitarContas(params) {
        return service.post(`/pedidos/QuitarContas`, params)
    },
    gerarNumeroPedido(fazendaId) {
        return service.get(`/Pedidos/geraNumeroPedido/${fazendaId}`)
    },
    duplicar(id) {
        return service.post(`/Pedidos/duplicar/${id}`)
    },
}
