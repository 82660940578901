import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import TipoEstoqueListagem from './tipoEstoqueListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import TipoEstoqueService from '../../services/TipoEstoqueService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const TipoEstoque = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.tipoEstoque.filtroListagem)
    )

    const [tiposEstoques, setTiposEstoques] = useState([])

    useEffect(() => {
        const getTiposEstoques = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'Nome'
            novoFiltro.tamanhoPagina = 100
            buscarTiposEstoques(novoFiltro)
        }
        getTiposEstoques()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_TIPO_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarTiposEstoques(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_TIPO_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/TipoEstoque/cadastro`,
        })
    }

    const deletar = async (item) => {
        TipoEstoqueService.deletar(item.id)
        .then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Tipo Estoque excluído com sucesso!')
                let novoFiltro = { ...filtro }
                buscarTiposEstoques(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
        .catch((error) =>{
            ToastHelper.error(error.response.data)
            setLoading(false)
        })
    }

    const editar = (id) => {
        history.push(`/app/tipoEstoque/editar/${id}`)
    }

    const buscarTiposEstoques = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        TipoEstoqueService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = fazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                var tiposEstoque = []
                response.data.itens.forEach((item) =>
                    tiposEstoque.push({
                        id: item['id'],
                        nome: item['nome'],
                        descricao: item['descricao'],
                        registroSistema: item['registroSistema'],
                    })
                )
                setTiposEstoques(tiposEstoque)
                setLoading(false)
            } else {
                return ToastHelper.error(response.statusText)
            }
        })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarTiposEstoques(novoFiltro)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarTiposEstoques(novoFiltro)
    }

    return (
        <Page className={classes.root} title="Tipos de Estoque">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Tipos de Estoques" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                />
                <Box mt={3}>
                    <TipoEstoqueListagem
                        data={tiposEstoques}
                        pageSize={filtro.tamanhoPagina}
                        filtro={filtro}
                        isLoading={loading}
                        deletar={deletar}
                        editar={editar}
                        mudaOrdenacao={mudaOrdenacao}
                        onChangePage={(page) => onChangePage(page)}
                    />
                </Box>
            </Container>
        </Page>
    )
}
