import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Button, TextField } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { FornecedorClienteEsquema } from './ClienteFornecedorCadastroModal.validate'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    gridRadius: {
        marginTop: '13px',
    },
    labelRadius: {
        marginTop: '13px',
        marginLeft: '10px',
        fontSize: 13,
    },
}))

export default function ClienteFornecedorCadastroModal(props) {
    const classes = useStyles()
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            nome: '',
        },
        resolver: yupResolver(FornecedorClienteEsquema),
    })

    const [tipoPessoa, setTipoPessoa] = useState('FISICA')

    const onSubmit = (data) => {
        const dataFormat = {
            nomeFantasia: data.nome,
            tipoPessoa: tipoPessoa === 'FISICA' ? 0 : 1,
            tipoFornecedorCliente: props.pagamentoA === 'Fornecedor' ? 0 : 1,
        }
        props.submit(dataFormat)
    }

    return (
        <>
            <h2 className={classes.title}>Cadastro de {props.pagamentoA}</h2>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            inputRef={register}
                            name="nome"
                            id="nome"
                            variant="standard"
                            label="Nome*"
                            placeholder="Digite o nome"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <p className={classes.errors}>
                            {errors.nome ? errors.nome.message : ''}
                        </p>
                    </FormControl>
                    <Grid
                        container
                        item
                        xs={5}
                        display="flex"
                        className={classes.gridRadius}
                    >
                        <FormLabel
                            component="legend"
                            className={classes.labelRadius}
                        >
                            Tipo de Pessoa:
                        </FormLabel>

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="tipoPessoa"
                                value={tipoPessoa}
                                onChange={(e) => setTipoPessoa(e.target.value)}
                            >
                                <FormControlLabel
                                    value={'FISICA'}
                                    control={<Radio />}
                                    label="Física"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value={'JURIDICA'}
                                    control={<Radio />}
                                    label="Jurídica"
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            className={classes.button2}
                            variant="contained"
                            color="inherit"
                            size="small"
                            onClick={props.onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Cadastrar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
