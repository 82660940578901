import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import Titulo from '../../components/Titulo/index';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TipoEstoqueService from '../../services/TipoEstoqueService';
import FooterBottons from '../../components/FooterBottons/FooterBottons';
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { TipoEstoqueEsquema } from './tipoEstoque.validate';
import ToastHelper from '../../utils/toastHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlSituacao: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
}));

export const TipoEstoqueCadastro = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null;

    const [titulo, setTitulo] = useState('Cadastro de Tipo de Estoque');

    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            nome: '',
            descricao: '',
        },
        resolver: yupResolver(TipoEstoqueEsquema),
    });

    const {
        match: { params },
    } = props;

    useEffect(() => {
        const init = async () => {
            if (params.modo === 'editar') {
                setTitulo('Editar Tipo de Estoque');
                await buscarParaEdicao(params.id);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (dados) => {
        let data = { ...dados };
        data.nome = nome;
        data.descricao = descricao;
        data.fazendaId = fazendaId;

        if (params.id) {
            data.id = params.id;
            TipoEstoqueService.atualizar(data).then((response) => {
                if (response.status === 200) {
                    ToastHelper.success('Tipo de Estoque atualizado!');
                    history.goBack();
                } else {
                    ToastHelper.error(response.statusText);
                }
            });
        } else {
            data.ativo = true;
            TipoEstoqueService.cadastrar(data).then((response) => {
                if (response.status === 200) {
                    ToastHelper.success(
                        'Tipo de Estoque cadastrado com sucesso!',
                    );
                    history.goBack();
                } else {
                    ToastHelper.error(response.statusText);
                }
            });
        }
    };

    const buscarParaEdicao = async (itemId) => {
        TipoEstoqueService.buscarPeloId(itemId).then((response) => {
            if (response.status === 200) {
                setNome(response.data['nome']);
                setDescricao(response.data['descricao']);
            } else {
                ToastHelper.error(response.statusText);
            }
        });
    };

    return (
        <Page className={classes.root} title="Tipos de Estoque">
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Nome:*"
                                            value={nome}
                                            className={classes.textField}
                                            inputRef={register}
                                            onChange={(x) =>
                                                setNome(x.target.value)
                                            }
                                            id="nome"
                                            name="nome"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.nome
                                                ? errors.nome.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlObs}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição:"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="descricao"
                                            name="descricao"
                                            value={descricao}
                                            onChange={(event) => {
                                                setDescricao(
                                                    event.target.value,
                                                );
                                            }}
                                            multiline
                                            rowsMax={2}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    );
};
