import TipoDocumentoService from 'services/TipoDocumentoService'
import FornecedorClienteService from 'services/FornecedorClienteService'
import ProdutosServicoService from 'services/ProdutosServicosService'
import ContaGerencialService from 'services/ContaGerencialService'
import GestaoContratoService from 'services/GestaoContratoService'
import ObjectHelper from 'utils/objetcHelper'
import ToastHelper from 'utils/toastHelper'
import CentroCustoService from 'services/CentroCustoService'
import UnidadeConversaoService from '../../services/UnidadeConversaoService'
import UnidadeDeMedidaService from '../../services/UnidadeDeMedidaService'

const FAZENDA = JSON.parse(localStorage.getItem('fazenda'))
export default class GestaoContratoRequests {
    static validacaoFormModalProdutosServicos = async (form) => {
        var error = []
        if (!form.produtoServicoId)
            error.push({
                id: 'produtoServicoId',
            })
        if (!form.unidadeId) error.push({ id: 'unidadeId' })
        if (!form.quantidade) error.push({ id: 'quantidade' })
        if (!form.valorUnitario)
            error.push({
                id: 'valorUnitario',
            })
        if (!form.previsaoEntrega) error.push({ id: 'previsaoEntrega' })
        if (!form.contaGerencialId) error.push({ id: 'contaGerencialId' })
        return error
    }

    static duplicar = async (id, fazendaId) => {
        const { data } = await GestaoContratoService.duplicar(id, fazendaId)
        return data
    }

    static GerarNumero = async (fazendaId) => {
        const { data } = await GestaoContratoService.gerarNumero(fazendaId)
        return data
    }

    static deletar = async () => {
        const { data } = await GestaoContratoService.gerarNumero()
        return data
    }

    static buscarParcelasPorDespesaReceitaId = async (id) => {
        const { data } =
            await GestaoContratoService.buscarParcelasPorDespesaReceitaId(id)
        return data
    }

    static inserirProdutosServicos = async (form) => {
        let data = await GestaoContratoService.inserirProdutosServicos(form)
        return data
    }

    static cadastrar = async (dados) => {
        let data = {}
        if (dados.id) {
            data = await GestaoContratoService.atualizar(dados)
            return data
        } else {
            let cadastro = await GestaoContratoService.cadastrar(dados)
            return cadastro
        }
    }

    static buscarCentrosDeCusto = async () => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        filtro.ordem = 'descricao'
        filtro.decrescente = false
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await CentroCustoService.buscarTudo(params)
        return data.itens
    }

    static buscarUnidadesDeMedida = async (unidadeAtual) => {
        if (unidadeAtual) {
            let options = []
            let filtro = {}
            filtro.fazendaId = FAZENDA.value
            filtro.unidade = unidadeAtual ? unidadeAtual : ''
            filtro.ativo = true
            let params = await ObjectHelper.createParams(filtro)

            await UnidadeConversaoService.buscarTudo(params).then(
                (response) => {
                    if (response.status === 200) {
                        response.data.forEach((item) => {
                            options.push({
                                id: item.unidadeConvertida['id'],
                                sigla: item.unidadeConvertida['sigla'],
                            })
                        })
                    } else {
                        ToastHelper(response.statusText)
                    }
                }
            )

            await UnidadeDeMedidaService.buscarPorId(filtro.unidade).then(
                (response) => {
                    if (response.status === 200) {
                        options.push({
                            id: response.data['id'],
                            sigla: response.data['sigla'],
                        })
                    } else {
                        ToastHelper(response.statusText)
                    }
                }
            )

            return options
        } else {
            let options = []
            let params = []
            params.fazendaId = FAZENDA.value
            params.ativo = true

            let query = await ObjectHelper.createParams(params)
            await UnidadeDeMedidaService.buscarTudo(query).then((response) => {
                if (response.status === 200) {
                    response.data.itens.forEach((item) => {
                        options.push({
                            id: item['id'],
                            sigla: item['sigla'],
                        })
                    })
                } else {
                    ToastHelper(response.statusText)
                }
            })
            return options
        }
    }

    static buscarProdutosServicos = async () => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        filtro.ordem = 'nome'
        filtro.decrescente = false
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await ProdutosServicoService.buscarTudo(params)
        return data.itens
    }

    static buscarFornecedoresClientes = async (TipoFornecedorCliente) => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value
        filtro.ativo = true
        filtro.tamanhoPagina = 99999
        filtro.ordem = 'nomeFantasia'
        filtro.decrescente = false
        if (TipoFornecedorCliente) {
            filtro.TipoFornecedorCliente =
                TipoFornecedorCliente.toUpperCase() === 'CLIENTE' ? 1 : 0
            let params = await ObjectHelper.createParams(filtro)
            const { data } = await FornecedorClienteService.buscarTudo(params)
            return ObjectHelper.removeInativos(
                ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(data.itens)
            )
        } else {
            let params = await ObjectHelper.createParams(filtro)
            const { data } = await FornecedorClienteService.buscarTudo(params)
            return ObjectHelper.removeInativos(
                ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(data.itens)
            )
        }
    }

    static buscarContasGerenciais = async () => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = FAZENDA.value
        filtroParaPesquisa.ativo = true
        filtroParaPesquisa.tipo = 1
        filtroParaPesquisa.operacao = 0
        filtroParaPesquisa.tamanhoPagina = 99999
        filtroParaPesquisa.semPadroes = true
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        const { data } = await ContaGerencialService.buscarTudo(params)
        data.itens.forEach((item) => {
            item.descricao = `${item.numero} - ${item.descricao}`
        })
        return data.itens
    }

    static buscarTiposDocumentos = async () => {
        let filtroParaPesquisa = {}
        filtroParaPesquisa.fazendaId = FAZENDA.value
        filtroParaPesquisa.ativo = true
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        const { data } = await TipoDocumentoService.buscarTudo(params)
        let result = data.itens

        return result
    }

    static apagarProdutosServicos = async (id) => {
        const { data } = await GestaoContratoService.apagarProdutosServicos(id)
        return data
    }

    static buscarConta = async (id) => {
        const { data } = await GestaoContratoService.buscarPorId(id)
        data.gestaoContrato.fornecedorCliente.label =
            data.gestaoContrato.fornecedorCliente.nomeFantasia
        data.gestaoContrato.fornecedorCliente.value =
            data.gestaoContrato.fornecedorCliente.id
        return data
    }
}
