import service from './service'

export default {
    validar(orcamento) {
        const data = new FormData()

        Object.keys(orcamento).forEach((key) => {
            data.append(key, orcamento[key])
        })

        return service.post(`/Orcamento/ValidarPlanilha`, data)
    },

    cadastrar(param) {
        return service.post(`/Orcamento/Cadastrar`, param)
    },

    carregar(param) {
        return service.get(`/Orcamento/Carregar${param}`)
    },

    downloadModelo(param) {
        return service.get(`/Orcamento/Modelo/${param}`, {
            responseType: 'blob',
        })
    },
}
