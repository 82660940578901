import service from './service'

export default {
    cadastrar(param) {
        return service.post(`/ProdutorRural`, param)
    },
    buscarTudo(param) {
        return service.get(`/ProdutorRural${param}`)
    },
    ativarDesativar(id) {
        return service.put(`/ProdutorRural/AtivarDesativar/${id}`)
    },
    deletar(id) {
        return service.delete(`/ProdutorRural/${id}`)
    },
    buscarPorId(id) {
        return service.get(`/ProdutorRural/${id}`)
    },
    atualizar(param) {
        return service.put(`/ProdutorRural`, param)
    },
    buscarTudoSelect(fazendaId) {
        return service.get(`/ProdutorRural/buscarTudo/${fazendaId}`)
    },
}
