import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Page from 'components/Page'
import Titulo from 'components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import {
    Container,
    Card,
    Paper,
    CircularProgress,
    CardContent,
    TextField,
    Button,
    makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NumberFormat from 'react-number-format'
import DataHelper from 'utils/dataHelpder'
import Request from '../request'
import ToastHelper from 'utils/toastHelper'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { esquema } from '../esquema'
import ObjectHelper from 'utils/objetcHelper'
import Table from 'components/Table'
import ContaPagarReceberService from 'services/ContaPagarReceberService'


export const AdiantamentoFornecedoresClientesCadastro = (props) => {
    const classes = useStyles()
    const [loading] = useState(false)
    const {
        match: { params },
    } = props
    const history = useHistory()
    const location = useLocation()

    const [visualizar, setVisualizar] = useState(params.tipo === 'visualizar')
    const editar = params.tipo === 'editar'
    const pagarReceber = location.pathname.includes('pagar')
        ? 'pagar'
        : 'receber'
    const [labelFornecedorCliente, setlabelFornecedorCliente] =
        React.useState('Cliente')
    const [opcoesFornecedoresClientes, setOpcoesFornecedoresClientes] =
        React.useState([])
    const [adiantamentoPago, setAdiantamentoPago] = useState(false)

    const [opcoesContaBancaria, setOpcoesContaBancaria] = React.useState([])
    const [opcoesFormasPagamento, setOpcoesFormasPagamento] = React.useState([])
    const [contasPagas, setContasPagas] = React.useState([])
    const [valorUtilizado,setValorUtilizado] = React.useState(0);
    const [filtroContasPagas ,setFiltroContasPagas] = React.useState({
                adiantamentoId:params.id,
                pagina:1,
                tamanhoPagina:20,
                ordem:'dataPagamento',
                decrescente:false,
           })

    const [opcoesAdiantamentoDe] = useState([
        {
            value: 'CLIENTE',
            label: 'Cliente',
        },
        {
            value: 'FORNECEDOR',
            label: 'Fornecedor',
        },
    ])

    const { handleSubmit, setValue, getValues, errors, control } = useForm({
        defaultValues: {
            documento: '',
            adiantamentoDe: null,
            fornecedorCliente: null,
            dataLancamento: DataHelper.today(),
            descricao: '',
            dataVencimento: '',
            valor: '',
            situacao: 'DF',
            contaBancaria: '',
            valorPago: '',
            formaPagamento: '',
            documentoPago: '',
            dataPagamento: '',
            dataCompensacao: '',
            cheque: '',
            saldo: '',
        },
        resolver: yupResolver(esquema),
    })

    const [formaPagamento, setFormaPagamento] = useState('')
    const [contaBancaria, setContaBancaria] = useState('')

    const contasPagasColunas = [
        {
            title: 'Data de Pagamento',
            field: 'dataPagamento',
        },
        {
            title: 'Doc.',
            field: 'documento',
        },
        {
            title: 'Descrição',
            field: 'descricao',
        },
        {
            title: 'Valor Pago',
            field: 'valorPagoFormatado',
        },

    ]

    const pagamentoColunas = [
        {
            title: 'Vencimento',
            field: 'dataVencimento',
            render: (prop) => (
                <>
                    <Controller
                        control={control}
                        id="dataVencimentoInput"
                        name="dataVencimento"

                        as={
                            <TextField
                                variant="standard"
                                type="date"
                                disabled={visualizar}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: { max: '9999-12-31' },
                                }}
                            />
                        }
                    />
                    <p className={classes.errors}>
                        {errors.dataVencimento
                            ? errors.dataVencimento.message
                            : ''}
                    </p>
                </>
            ),
        },
        {
            title: 'Valor',
            field: 'valor',
            render: (prop) => (
                <>
                    <Controller
                        control={control}
                        id="valorInput"
                        name="valor"
                        disabled={visualizar}
                        as={
                            <NumberFormat
                                prefix={'R$ '}
                                style={{ width: 140 }}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                                disabled={visualizar}
                                fixedDecimalScale={2}
                                customInput={TextField}
                                id="valorInput"
                                name="valor"
                                onValueChange={(item) => {
                                    setValue('valor', item.floatValue)
                                }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'right' },
                                }}
                                isplaceholderanimated={(false, 'top')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                    <p className={classes.errors}>
                        {errors.valor ? errors.valor.message : ''}
                    </p>
                </>
            ),
        },
        {
            title: 'Situação',
            field: 'situacao',
            render: (prop) => (
                <Controller
                    control={control}
                    id="situacao"
                    name="situacao"
                    as={<p>{prop.situacao}</p>}
                />
            ),
        },
        {
            title: 'Conta',
            field: 'contaBancaria.label',
            render: (prop) => (
                <Autocomplete
                    id="contaBancariaInput"
                    name="contaBancaria"
                    disabled={adiantamentoPago}
                    options={opcoesContaBancaria ?? []}
                    getOptionLabel={(option) => option.label ?? ''}
                    getOptionSelected={(option, value) =>
                        option?.value === value.value ?? ''
                    }
                    value={contaBancaria}
                    onChange={(event, value) => {
                        setContaBancaria(value)
                    }}
                    style={{ width: 140 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="contaBancaria"
                            variant="standard"
                            placeholder="Selecione"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            ),
        },
        {
            title: 'Valor Pago',
            field: 'valorPago',
            render: (prop) => (
                <>
                    <Controller
                        control={control}
                        id="valorPagoInput"
                        name="valorPago"

                        as={
                            <NumberFormat
                                prefix={'R$ '}
                                style={{ width: 140 }}
                                disabled={true}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                customInput={TextField}
                                id="valorPagoInput"
                                name="valorPago"
                                onValueChange={(item) => {
                                    setValue('valorPago', item.floatValue)
                                }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'right' },
                                }}
                                isplaceholderanimated={(false, 'top')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                    <p className={classes.errors}>
                        {errors.valorPago ? errors.valorPago.message : ''}
                    </p>
                </>
            ),
        },
        {
            title: 'Forma Pagamento',
            field: 'formaPagamento.label',
            render: (prop) => (
                <Autocomplete
                    id="formaPagamentoInput"
                    name="formaPagamento"
                    disabled={adiantamentoPago}
                    options={opcoesFormasPagamento ?? []}
                    getOptionLabel={(option) => option.label ?? ''}
                    getOptionSelected={(option, value) =>
                        option?.value === value.value ?? ''
                    }
                    value={formaPagamento}
                    onChange={(event, value) => {
                        setFormaPagamento(value)
                    }}
                    style={{ width: 140 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="formaPagamento"
                            variant="standard"
                            placeholder="Selecione"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            ),
        },
        {
            title: 'Documento Pago',
            field: 'documentoPago',
            render: (prop) => (
                <Controller
                    control={control}
                    id="documentoPagoInput"
                    name="documentoPago"
                    disabled={visualizar}
                    as={
                        <TextField
                            type="text"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={adiantamentoPago}
                        />
                    }
                />
            ),
        },
        {
            title: 'Pagamento',
            field: 'dataPagamento',
            render: (prop) => (
                <>
                    <Controller
                        control={control}
                        id="dataPagamentoInput"
                        name="dataPagamento"
                        as={
                            <TextField
                                variant="standard"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                InputProps={{
                                    inputProps: { max: '9999-12-31' },
                                }}
                            />
                        }
                    />
                    <p className={classes.errors}>
                        {errors.dataPagamento
                            ? errors.dataPagamento.message
                            : ''}
                    </p>
                </>
            ),
        },
        {
            title: 'Compensação',
            field: 'dataCompensacao',
            render: (prop) => (
                <>
                    <Controller
                        control={control}
                        id="dataCompensacaoInput"
                        name="dataCompensacao"
                        as={
                            <TextField
                                variant="standard"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                InputProps={{
                                    inputProps: { max: '9999-12-31' },
                                }}
                            />
                        }
                    />
                    <p className={classes.errors}>
                        {errors.dataCompensacao
                            ? errors.dataCompensacao.message
                            : ''}
                    </p>
                </>
            ),
        },
        {
            title: 'Cheque',
            field: 'cheque',
            render: (prop) => (
                <Controller
                    control={control}
                    id="cheque"
                    name="cheque"
                    as={
                        <TextField
                            variant="standard"
                            type="test"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                        />
                    }
                />
            ),
        },
        {
            title: 'Saldo',
            field: 'Saldo',
            render: (prop) => (
                <>
                    <Controller
                        control={control}
                        id="saldoInput"
                        name="saldo"
                        as={
                            <NumberFormat
                                prefix={'R$ '}
                                style={{ width: 140 }}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                disabled={true}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                customInput={TextField}
                                id="saldoInput"
                                name="saldo"
                                onValueChange={(item) => {
                                    setValue('saldo', item.floatValue)
                                }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'right' },
                                }}
                                isplaceholderanimated={(false, 'top')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                    <p className={classes.errors}>
                        {errors.saldo
                            ? errors.saldo.message
                            : ''}
                    </p>
                </>
            ),
        },
    ]

    const fillForm = (prop) => {
        setAdiantamentoPago(!!prop.dataPagamento)
        setValue('documento', prop.documento)
        setValue(
            'adiantamentoDe',
            opcoesAdiantamentoDe.find((x) => x.value === prop.adiantamentoDe)
        )
        setValue('fornecedorCliente', {
            label: prop.fornecedorCliente.nomeFantasia,
            value: prop.fornecedorCliente.id,
        })
        setValue('dataLancamento', prop.dataLancamento)
        setValue('descricao', prop.descricao)
        setValue('dataVencimento', prop.dataVencimento)
        setValue('valor', prop.valor)
        setValue('valorPago', prop.valorPago)
        setValue('situacao', prop.situacao)
        setContaBancaria({
            value: prop?.contaBancaria?.id,
            label: prop?.contaBancaria?.descricao,
        })
        setFormaPagamento({
            value: prop?.formaPagamento?.id,
            label: prop?.formaPagamento?.nome,
        })
        setValue('valorPago', prop.valorPago)
        setValue('documentoPago', prop.documentoPago)
        setValue('dataPagamento', prop.dataPagamento)
        setValue('dataCompensacao', prop.dataCompensacao)
        setValue('cheque', prop.cheque)
        setValue('saldo', prop.saldo)
    }

    const preencherCamposDeContrato = () => {
        if (props.location.adiantamentoReceber) {
            const adiantamentoReceber = props.location.adiantamentoReceber
            const dataVencimento = adiantamentoReceber.dataVencimento
            setValue(
                'adiantamentoDe',
                opcoesAdiantamentoDe.find(
                    (x) => x.value === adiantamentoReceber.pedidoA.toUpperCase()
                )
            )
            setValue('fornecedorCliente', adiantamentoReceber.fornecedorCliente)
            setValue(
                'dataVencimento',
                DataHelper.formatStringDataBackTextFieldData(dataVencimento)
            )
            setValue('valor', adiantamentoReceber.valorTotal)
        }

        if (props.location.adiantamentoPagar) {
            const adiantamentoPagar = props.location.adiantamentoPagar
            const dataVencimento = adiantamentoPagar.dataVencimento
            setValue(
                'adiantamentoDe',
                opcoesAdiantamentoDe.find(
                    (x) =>
                        x.value === adiantamentoPagar.pedidoFeitoA.toUpperCase()
                )
            )
            setValue('fornecedorCliente', adiantamentoPagar.fornecedorCliente)
            setValue(
                'dataVencimento',
                DataHelper.formatStringDataBackTextFieldData(dataVencimento)
            )
            setValue('valor', adiantamentoPagar.valor)
        }
    }

    const buscarPorId = async (id) => {
        Request.buscarPorId(id)
            .then((response) => {
                var res = response.result

                const situacao = res.situacao

                if ((situacao === 'PG' || situacao === 'CO') && editar) {
                    ToastHelper.error(
                        'Não é possível editar um adiantamento que já foi pago ou compensado.'
                    );

                    setVisualizar(true)
                    history.push(`/app/adiantamentoFornecedoresClientes/${pagarReceber}/visualizar/${id}`)
                }

                buscarFornecedoresClientes(res.adiantamentoDe.toLowerCase())

                fillForm(res)
            })
            .catch((error) => {
                ToastHelper.error(
                    'Ocorreu um erro, por favor acione o administrador do sistema.'
                )
            })
    }

    const mudaOrdenacaoContasPagas = (numeroColuna, orientacao, colunas) => {
        if (numeroColuna === -1) return

        let novoFiltro = { ...filtroContasPagas }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        if(novoFiltro.ordem ==='valorPagoFormatado') {
            novoFiltro.ordem = 'valorPago'
        }
        if (novoFiltro.ordem === 'documento') {
            novoFiltro.ordem = 'DespesaReceita.documento'
        }
        if (novoFiltro.ordem === 'descricao') {
            novoFiltro.ordem = 'DespesaReceita.Descricao'
        }
        setFiltroContasPagas(novoFiltro)
        buscarContasPagas(novoFiltro)
    }

    const gerarDocumento = async () => {
        let data = await Request.gerarDocumento()
        setValue('documento', data)
    }

    const buscarFornecedoresClientes = async (TipoFornecedorCliente) => {
        if (!TipoFornecedorCliente) return
        let data = await Request.buscarFornecedoresClientes(
            TipoFornecedorCliente
        )
        data = await ObjectHelper.ordenaLista(data, 'label')
        setOpcoesFornecedoresClientes(data)
    }

    const buscarContasBancaria = async () => {
        let result = await Request.buscarContasBancarias()
        setOpcoesContaBancaria(result)
    }

    const buscarFormasPagamento = async () => {
        let result = await Request.buscarFormasPagamento()
        setOpcoesFormasPagamento(result)
        return result
    }


    const onSubmit = async () => {
        let dto = {
            id: params.id,
            creditoDebito: pagarReceber === 'pagar' ? 1 : 0,
            documento: getValues().documento,
            adiantamentoDe: getValues().adiantamentoDe.value,
            fornecedorClienteId: getValues().fornecedorCliente.value,
            dataLancamento: getValues().dataLancamento,
            descricao: getValues().descricao,
            dataVencimento: getValues().dataVencimento,
            valor:
                typeof getValues().valor === 'string'
                    ? ObjectHelper.convertCurrencyStringToFloat(
                          getValues().valor
                      )
                    : getValues().valor,
            contaBancariaId: contaBancaria?.value,
            valorPago:
                typeof getValues().valorPago === 'string'
                    ? ObjectHelper.convertCurrencyStringToFloat(
                          getValues().valorPago
                      )
                    : getValues().valorPago,

            formaPagamentoId: formaPagamento?.value,
            documentoPago: getValues().documentoPago,
            dataPagamento: getValues().dataPagamento,
            dataCompensacao: getValues().dataCompensacao,
            cheque: getValues().cheque,
            saldo:
                typeof getValues().saldo === 'string'
                    ? ObjectHelper.convertCurrencyStringToFloat(
                          getValues().saldo
                      )
                    : getValues().saldo,
        }

        let data = Request.cadastrar(dto)
        data.then(async (res) => {
            if (!res.data.isOk) return ToastHelper.error(res.data.mensagem)

            if (params.id) ToastHelper.success('Adiantamento editado.')
            else ToastHelper.success('Adiantamento criado.')

            if (props.location.dadosContrato) {
                await props.location.onSubmitContratoEmAdiantamento(
                    props.location.dadosContrato
                )
            }

            if (props.location.dadosPedido) {
                await props.location.onSubmitPedidoAdiantamento(
                    props.location.dadosPedido
                )
            }

            if (pagarReceber === 'pagar') {
                history.push({
                    pathname: `/app/adiantamentoFornecedoresClientes/pagar`,
                })
            } else {
                history.push({
                    pathname: `/app/adiantamentoFornecedoresClientes/receber`,
                })
            }
        }).catch((e) => {
            return ToastHelper.error(
                'Ocorreu um erro, por favor acione o administrador do sistema.'
            )
        })
    }

    const buscarContasPagas = async (filtro) => {
        const params = await ObjectHelper.createParams(filtro);
        const result= await ContaPagarReceberService.buscarParcelasPorApontamentoId(params)
        await  setFiltroContasPagas({
            ...filtro,
             totalPaginas:result.data.totalPaginas,
             totalItens:result.data.totalItens
        })
        setContasPagas(result.data.itens);
        calcValorUtilizado(result.data.itens);
    }

    const calcValorUtilizado = (data) => {
        const valor =  data.reduce((acc,conta) => acc + conta.valorPago,0);
        setValorUtilizado(valor)
    }

    const onChangePage =  (page) => {
        let filtro = filtroContasPagas;
        filtro.pagina = page;
        buscarContasPagas(filtro)
    }

    useEffect(() => {
        buscarContasBancaria()
        buscarFormasPagamento()
        if(params.id) {
            buscarContasPagas(filtroContasPagas)
        }
        if (params.id) {
            buscarPorId(params.id)
            return
        }

        gerarDocumento()
        if (pagarReceber === 'pagar') {
            buscarFornecedoresClientes('Fornecedor')
            setValue('adiantamentoDe', {
                value: 'FORNECEDOR',
                label: 'Fornecedor',
            })
            setlabelFornecedorCliente('Fornecedor')
        } else {
            buscarFornecedoresClientes('Cliente')
            setValue('adiantamentoDe', {
                value: 'CLIENTE',
                label: 'Cliente',
            })
        }

        preencherCamposDeContrato()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const title = (visualizar ? `Visualização de Adiantamento a ` :
                    editar ? `Edição de Adiantamento a ` : `Cadastro de Adiantamento a `)
                    + ObjectHelper.capitalizeFirstLetter(pagarReceber)

    return (
        <Page
            className={classes.root}
            title={title}
        >
            <Container maxWidth={'xl'}>
                <Titulo
                    titulo={title}
                />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    key={1}
                    className={classes.form}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            {loading && (
                                <Grid
                                    container
                                    item
                                    md={12}
                                    className={classes.boxLoading}
                                >
                                    <CircularProgress color="primary" />
                                </Grid>
                            )}
                            {!loading && (
                                <>
                                    <Grid container item md={12} display="flex">
                                        <Grid
                                            container
                                            item
                                            md={6}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="documentoInput"
                                                    disabled={true}
                                                    name="documento"
                                                    as={
                                                        <TextField
                                                            label="Documento*"
                                                            variant="standard"
                                                            type="text"
                                                            disabled={true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.documento
                                                        ? errors.documento
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item md={12} display="flex">
                                        <Grid
                                            container
                                            item
                                            md={6}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    error={
                                                        errors.adiantamentoDe
                                                            ? true
                                                            : false
                                                    }
                                                    id="adiantamentoDe"
                                                    name="adiantamentoDe"

                                                    as={(props) => (
                                                        <Autocomplete
                                                            options={
                                                                opcoesAdiantamentoDe ||
                                                                []
                                                            }
                                                            disableClearable
                                                            disabled={
                                                                visualizar
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option
                                                                    ? option.label
                                                                    : ''
                                                            }
                                                            onChange={(
                                                                _,
                                                                data
                                                            ) => {
                                                                props.onChange(
                                                                    data
                                                                )
                                                                setValue(
                                                                    'adiantamentoDe',
                                                                    data
                                                                )
                                                                setValue(
                                                                    'fornecedorCliente',
                                                                    null
                                                                )
                                                                setlabelFornecedorCliente(
                                                                    data?.label
                                                                )
                                                                buscarFornecedoresClientes(
                                                                    data?.label
                                                                )
                                                            }}
                                                            size="small"
                                                            getOptionSelected={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option?.value ===
                                                                value?.value
                                                            }
                                                            value={getValues(
                                                                'adiantamentoDe'
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={
                                                                        'Adiantamento de:*'
                                                                    }
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.adiantamentoDe
                                                        ? errors.adiantamentoDe
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            md={6}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="fornecedorCliente"
                                                    name="fornecedorCliente"

                                                    as={(props) => (
                                                        <Autocomplete
                                                            options={
                                                                opcoesFornecedoresClientes ||
                                                                []
                                                            }
                                                            disabled={
                                                                visualizar
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option
                                                                    ? option.label
                                                                    : ''
                                                            }
                                                            onChange={(
                                                                _,
                                                                data
                                                            ) => {
                                                                props.onChange(
                                                                    data
                                                                )
                                                                setValue(
                                                                    'fornecedorCliente',
                                                                    data
                                                                )
                                                                setValue(
                                                                    'fornecedorClienteId',
                                                                    data?.value
                                                                )
                                                            }}
                                                            size="small"
                                                            getOptionSelected={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option?.value ===
                                                                value?.value
                                                            }
                                                            value={getValues(
                                                                'fornecedorCliente'
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={
                                                                        labelFornecedorCliente +
                                                                        '*:'
                                                                    }
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    error={
                                                                        errors.fornecedorCliente
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.fornecedorCliente
                                                        ? errors
                                                              .fornecedorCliente
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item md={12} display="flex">
                                        <Grid
                                            container
                                            item
                                            md={6}
                                            display="flex"
                                        >
                                            <Grid
                                                container
                                                item
                                                md={6}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <Controller
                                                        control={control}
                                                        disabled={true}
                                                        id="dataLancamento"
                                                        error={
                                                            errors.dataLancamento
                                                                ? true
                                                                : false
                                                        }
                                                        name="dataLancamento"
                                                        as={
                                                            <TextField
                                                                label="Data de Lançamento:"
                                                                variant="standard"
                                                                value={new Date().toLocaleDateString()}
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        {errors.dataLancamento
                                                            ? errors
                                                                  .dataLancamento
                                                                  .message
                                                            : ''}
                                                    </p>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item md={12} display="flex">
                                        <Grid
                                            container
                                            item
                                            md={12}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={
                                                    classes.formControlObs
                                                }
                                            >
                                                <Controller
                                                    control={control}
                                                    id="descricao"
                                                    name="descricao"
                                                    disabled={visualizar}
                                                    as={
                                                        <TextField
                                                            type="text"
                                                            label="Descrição:"
                                                            disabled={
                                                                visualizar
                                                            }
                                                            className={
                                                                classes.textField
                                                            }
                                                            multiline
                                                            variant="outlined"
                                                            rows={2}
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.descricao
                                                        ? errors.descricao
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <Paper elevation={1} className={classes.cardMeio}>
                        <Table
                            title=""
                            columns={pagamentoColunas}
                            removeToolbar
                            rowStyle={{ width: 300 }}
                            data={[getValues()]}
                            page={1}
                            totalCount={1}
                            paging={false}
                            pageSize={1}
                        />
                    </Paper>
                    {params.id ? <Table
                            title="Contas Pagas"
                            columns={contasPagasColunas}
                            onOrderChange={(numeroColuna, orientacao) =>
                                mudaOrdenacaoContasPagas(numeroColuna, orientacao, contasPagasColunas)
                            }
                            rowStyle={{ width: 300 }}
                            data={contasPagas}
                            onChangePage={onChangePage}
                            page={filtroContasPagas.pagina}
                            totalCount={filtroContasPagas.totalItens}
                            pageSize={filtroContasPagas.tamanhoPagina}
                            onRowClick={(_event,rowData) => history.push(`/app/conta/${rowData.tipo}/editar/${rowData.despesaReceitaId}`) }
                            components={{
                                Toolbar: () => {
                                    return (
                                        <>
                                            <div
                                                className={classes.containerTolbarContas}
                                            >
                                                <div>
                                                    <h2
                                                        className={classes.tituloTolbarContas}
                                                    >
                                                        Contas Pagas
                                                    </h2>
                                                </div>
                                               <div
                                                className={classes.containerTolbarValoresContas}
                                               >
                                                <h4 className={classes.tolbarContasTexto} >
                                                    Valor Total:
                                                    <span className={classes.tolbarContasValor}>
                                                        {ObjectHelper.convertFloatToCurrency(getValues('valorPago') || 0)}
                                                    </span>
                                                </h4>
                                                <h4 className={classes.tolbarContasTexto} >
                                                    Valor Utilizado:
                                                    <span className={classes.tolbarContasValor} >
                                                    {ObjectHelper.convertFloatToCurrency(valorUtilizado || 0)}
                                                    </span>
                                                </h4>
                                                <h4 className={classes.tolbarContasTexto} >
                                                    Valor Residual:
                                                    <span className={classes.tolbarContasValor} >
                                                        {ObjectHelper.convertFloatToCurrency(getValues('saldo') || 0)}
                                                    </span>
                                                </h4>
                                               </div>
                                            </div>
                                        </>
                                    )
                                },
                            }}
                        /> : null}
                    <Grid
                        container
                        item
                        md={12}
                        display="flex"
                        className={classes.card}
                    >
                        <Grid
                            md={6}
                            container
                            item
                            direction="row"
                            alignItems="center"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => {
                                    window.location.href =
                                        `/app/adiantamentoFornecedoresClientes/${pagarReceber}`
                                }}
                            >
                                Voltar
                            </Button>
                        </Grid>
                        {!visualizar && (
                            <Grid
                                md={6}
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="small"
                                >
                                    {params.id ? 'Confirmar' : 'Cadastrar'}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Container>
        </Page>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlSituacao: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonModalProdutosServicos: {
        marginBottom: '-25px',
        zIndex: 9,
        marginRight: 10,
        marginTop: 10,
        float: 'right',
    },
    cardMeio: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    tolbarContasTexto: {
       marginRight:8,
       color:'#2E7D32',
    },
    tolbarContasValor: {
        marginRight:5,
        color:'black',
        marginLeft:2,
    },
    containerTolbarContas : {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight:25,
        paddingTop:15,
        paddingBottom:20,
        alignItems:'center',
    },
    containerTolbarValoresContas : {
        display:'flex',
        paddingTop:15,
        paddingBottom:20,
    },
    tituloTolbarContas :{
        paddingLeft:25,
        fontFamily: 'inherit',
        color:'#2E7D32',
    }
}))
