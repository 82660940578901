import { TransferenciaBancaria } from '../pages/transferenciaBancaria/index'
import { TransferenciaBancariaCadastro } from '../pages/transferenciaBancaria/TransferenciaBancariaCadastro'

export const TransferenciaBancariaRoute = [
    {
        path: '/app/transferenciaBancaria',
        key: 'APP_TRANSFERENCIA_BANCARIA',
        exact: true,
        component: TransferenciaBancaria,
    },
    {
        path: '/app/transferenciaBancaria/cadastro',
        key: 'APP_TRANSFERENCIA_BANCARIA_CADASTRO',
        exact: true,
        component: TransferenciaBancariaCadastro,
    },
    {
        path: '/app/transferenciaBancaria/visualizar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_TRANSFERENCIA_BANCARIA_VISUALIZAR',
        exact: true,
        component: TransferenciaBancariaCadastro,
    },
]
