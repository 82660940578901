import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Divider, Hidden, makeStyles, TextField } from '@material-ui/core'
import { Archive as ArchiveIcon, Package as PackageIcon } from 'react-feather'
import NavItem from './NavItem'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { GlobalHotKeys } from 'react-hotkeys'
import { getTelasDoMenuRapido } from './MenuRapidoHelper'
import CadastroIcon from '@material-ui/icons/AddCircle'

import SearchIcon from '@material-ui/icons/Search'
import CamposParcelaIcon from '@material-ui/icons/CallToActionOutlined'
import CentroDeCustoIcon from '@material-ui/icons/CenterFocusStrongOutlined'
import CentroDeNgocioIcon from '@material-ui/icons/BusinessCenterOutlined'
import FormaPagamentoIcon from '@material-ui/icons/PaymentOutlined'
import ContaGerencialIcon from '@material-ui/icons/AccountTreeOutlined'
import TipoDocumentoIcon from '@material-ui/icons/ChromeReaderModeOutlined'
import ContaBancariaIcon from '@material-ui/icons/AccountBalanceOutlined'
import TransferenciaBancariaIcon from '@material-ui/icons/CompareArrowsOutlined'
import DespesaReceitaIcon from '@material-ui/icons/LocalAtmOutlined'
import AdiantamentoIcon from '@material-ui/icons/AccountBalanceWallet'
import ContaPagarIcon from '@material-ui/icons/MoneyOffOutlined'
import ContaReceberIcon from '@material-ui/icons/AttachMoneyOutlined'
import ContratoIcon from '@material-ui/icons/AssignmentOutlined'
import FornecedorClienteIcon from '@material-ui/icons/SupervisedUserCircleOutlined'
import OrcamentoIcon from '@material-ui/icons/ListAltOutlined'
import PedidosIcon from '@material-ui/icons/ReplyAllOutlined'
import LocalEstoqueIcon from '@material-ui/icons/MarkunreadMailboxOutlined'
import MovimentarEstoqueIcon from '@material-ui/icons/LowPriorityOutlined'
import TiposEstoqueIcon from '@material-ui/icons/FlipOutlined'
import PatrimonioIcon from '@material-ui/icons/Business'
import TiposPatrimonioIcon from '@material-ui/icons/FilterTiltShiftTwoTone'
import RelatorioIcon from '@material-ui/icons/BarChartOutlined'
import FluxoDeCaixaIcon from '@material-ui/icons/TimelineOutlined'
import ConciliacaoBancariaIcon from '@material-ui/icons/PlaylistAddCheckOutlined'
import TransferenciaEntreEstoquesIcon from '@material-ui/icons/TransformOutlined'
import ProdutosServicosIcon from '@material-ui/icons/WidgetsOutlined'
import ReceiptIcon from '@material-ui/icons/Receipt'
import Tooltip from '@material-ui/core/Tooltip'
import DetalhesDeCustoIcon from '@material-ui/icons/MultilineChart'
import PersonIcon from '@material-ui/icons/Person'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#fff',
    },
    mobileDrawer: {
        width: 250,
    },
    desktopDrawer: {
        width: 250,
        top: 75,
        height: 'calc(100% - 75px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
    formControl: {
        margin: '',
        minWidth: '100%',
    },
    divider: {},
    iconMarginRigth: {
        marginTop: '10px',
        marginBottom: '10px',
        maxHeight: '25px',
        minWidth: '25px',
        marginRight: '25px',
        marginLeft: '7px',
        cursor: 'pointer',
    },
}))

function getItems() {
    var json = {
        list: [
            {
                id: 1,
                items: [
                    {
                        id: 1,
                        index: 2,
                        name: 'Cadastros',
                        icon: CadastroIcon,
                        subitems: [
                            {
                                id: 2,
                                index: 3,
                                href: '/app/produtorRural',
                                icon: PersonIcon,
                                name: 'Produtor rural',
                            },
                            {
                                id: 3,
                                index: 4,
                                href: '/app/fornecedorCliente',
                                icon: FornecedorClienteIcon,
                                name: 'Fornecedor/Cliente',
                            },
                            {
                                id: 4,
                                index: 5,
                                href: '/app/centroNegocio',
                                icon: CentroDeNgocioIcon,
                                name: 'Centro de Negócio',
                            },
                            {
                                id: 5,
                                index: 6,
                                href: '/app/centroCusto',
                                icon: CentroDeCustoIcon,
                                name: 'Centro de Custo',
                            },
                            {
                                id: 7,
                                index: 8,
                                href: '/app/contaGerencial',
                                icon: ContaGerencialIcon,
                                name: 'Conta Gerencial',
                            },
                            {
                                id: 9,
                                index: 10,
                                href: `/app/produtosServicos`,
                                icon: ProdutosServicosIcon,
                                name: 'Produto/Serviço',
                            },
                            {
                                id: 10,
                                index: 11,
                                href: '/app/tipoPatrimonio',
                                icon: TiposPatrimonioIcon,
                                name: 'Tipos de Patrimônio',
                            },
                            {
                                id: 11,
                                index: 12,
                                href: '/app/patrimonio',
                                icon: PatrimonioIcon,
                                name: 'Patrimônio',
                            },
                            {
                                id: 12,
                                index: 13,
                                href: '/app/tipoDocumento',
                                icon: TipoDocumentoIcon,
                                name: 'Tipo de documento',
                            },
                            {
                                id: 13,
                                index: 14,
                                href: '/app/tipoPagamentoRecebimento',
                                icon: FormaPagamentoIcon,
                                name: 'Forma de Pagamento',
                            },
                            {
                                id: 14,
                                index: 15,
                                href: '/app/camposParcela',
                                icon: CamposParcelaIcon,
                                name: 'Campos da Parcela',
                            },
                            {
                                id: 15,
                                index: 16,
                                href: '/app/tipoEstoque',
                                icon: TiposEstoqueIcon,
                                name: 'Tipo de Estoque',
                            },
                            {
                                id: 16,
                                index: 17,
                                href: '/app/locaisEstoque',
                                icon: LocalEstoqueIcon,
                                name: 'Local Estoque',
                            },
                            {
                                id: 17,
                                index: 18,
                                href: '/app/unidades/listagem',
                                icon: AllInclusiveIcon,
                                name: 'Unidades de Medida',
                            },
                        ],
                    },
                ],
            },
            {
                id: 2,
                items: [
                    {
                        id: 1,
                        index: 18,
                        name: 'Contas',
                        icon: ContaBancariaIcon,
                        subitems: [
                            {
                                id: 1,
                                index: 19,
                                href: '/app/contaBancaria',
                                icon: ContaBancariaIcon,
                                name: 'Cadastro de Contas',
                            },
                            {
                                id: 2,
                                index: 20,
                                href: `/app/transferenciaBancaria/`,
                                icon: TransferenciaBancariaIcon,
                                name: 'Transferência Bancária',
                            },
                        ],
                    },
                ],
            },
            {
                id: 3,
                items: [
                    {
                        id: 1,
                        name: 'Despesa/Receita',
                        index: 21,
                        icon: DespesaReceitaIcon,
                        subitems: [
                            /*{
                                id: 1,
                                index: 22,
                                href: '/app/adiantamentoFornecedoresClientes',
                                icon: AdiantamentoIcon,
                                name: 'Adiantamento',
                            },*/
                            {
                                id: 2,
                                index: 23,
                                href: `/app/conta/${'pagar'}`,
                                icon: ContaPagarIcon,
                                name: 'Conta a Pagar',
                            },
                            {
                                id: 3,
                                index: 24,
                                href: `/app/conta/${'receber'}`,
                                icon: ContaReceberIcon,
                                name: 'Conta a Receber',
                            },
                            {
                                id: 4,
                                index: 25,
                                href: '/app/gestaocontrato',
                                icon: ContratoIcon,
                                name: 'Contrato',
                            },
                            {
                                id: 5,
                                index: 26,
                                href: '/app/importacaoorcamento',
                                icon: OrcamentoIcon,
                                name: 'Orçamento',
                            },
                            {
                                id: 6,
                                index: 27,
                                href: '/app/pedidos',
                                icon: PedidosIcon,
                                name: 'Pedido',
                            },
                        ],
                    },
                ],
            },
            {
                id: 4,
                items: [
                    {
                        id: 1,
                        name: 'Adiantamento',
                        index: 22,
                        icon: AdiantamentoIcon,
                        subitems: [
                            {
                                id: 1,
                                index: 23,
                                href: `/app/adiantamentoFornecedoresClientes/${'pagar'}`,
                                icon: AdiantamentoIcon,
                                name: 'A Pagar',
                            },
                            {
                                id: 1,
                                index: 24,
                                href: `/app/adiantamentoFornecedoresClientes/${'receber'}`,
                                icon: AdiantamentoIcon,
                                name: 'A Receber',
                            },
                        ],
                    },
                ],
            },
            {
                id: 5,
                items: [
                    {
                        id: 1,
                        index: 28,
                        name: 'Estoque',
                        icon: PackageIcon,
                        subitems: [
                            {
                                id: 1,
                                index: 29,
                                href: '/app/Estoque',
                                icon: ArchiveIcon,
                                name: 'Estoque',
                            },
                            {
                                id: 3,
                                index: 30,
                                href: '/app/movimentoEstoque',
                                icon: MovimentarEstoqueIcon,
                                name: 'Movimentação de Estoque',
                            },
                            {
                                id: 5,
                                index: 31,
                                href: '/app/transferenciaEstoque',
                                icon: TransferenciaEntreEstoquesIcon,
                                name: 'Transferência entre Estoques',
                            },
                        ],
                    },
                ],
            },
            {
                id: 6,
                items: [
                    {
                        id: 1,
                        index: 32,
                        name: 'Relatório',
                        icon: RelatorioIcon,
                        subitems: [
                            {
                                id: 1,
                                index: 33,
                                href: '/app/extratoBancario',
                                icon: ReceiptIcon,
                                name: 'Extrato Bancário',
                            },
                            {
                                id: 2,
                                index: 34,
                                href: '/app/ConciliacaoBancaria',
                                icon: ConciliacaoBancariaIcon,
                                name: 'Conciliação Bancária',
                            },
                            {
                                id: 3,
                                index: 35,
                                href: `/app/fluxoCaixa`,
                                icon: FluxoDeCaixaIcon,
                                name: 'Fluxo de caixa',
                            },
                            {
                                id: 4,
                                index: 36,
                                href: `/app/detalhesDeCusto`,
                                icon: DetalhesDeCustoIcon,
                                name: 'Detalhes de custo',
                            },
                        ],
                    },
                ],
            },
        ],
    }
    return json
}

let hotKeysKeyMap = {
    MENU_RAPIDO: 'ctrl+m',
}

let menuRapidoRef = React.createRef()

let telasDoMenuRapido = getTelasDoMenuRapido(getItems)

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
    const classes = useStyles()
    //const location = useLocation()
    const history = useHistory()

    const acessarMenuRapido = async () => {
        await rest.handleDrawerOpen()
        await menuRapidoRef.current.focus()
    }

    let hotKeyshandlers = {
        MENU_RAPIDO: acessarMenuRapido.bind(),
    }

    const [telaAtual] = useState(undefined)

    useEffect(
        (onMobileClose, openMobile) => {
            if (openMobile && onMobileClose) {
                onMobileClose()
            }
        }
        //[location.pathname]
    )

    useEffect(() => {
        if (rest.open) {
            menuRapidoRef.current.focus()
        }
    }, [rest.open])

    const onChangeMenu = (item, href) => {
        if (href) history.push(href.value)
    }

    const content = (
        <GlobalHotKeys keyMap={hotKeysKeyMap} handlers={hotKeyshandlers}>
            <Box
                height="100%"
                display="flex"
                flexDirection="column"
                className={classes.root}
            >
                {rest.open && (
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        p={2}
                    >
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                name="menuRapido"
                                id="menuRapido"
                                options={telasDoMenuRapido}
                                value={telaAtual}
                                defaultValue={telaAtual}
                                getOptionLabel={(option) => option.label}
                                size="small"
                                onChange={(item, item2) =>
                                    onChangeMenu(item, item2)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={menuRapidoRef}
                                        name="menuRapido"
                                        id="menuRapido"
                                        variant="standard"
                                        label="Menu rápido (Ctrl + M)"
                                        placeholder="Digite aqui"
                                        required={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                )}
                {!rest.open && (
                    <Tooltip title={'Menu rápido'}>
                        <SearchIcon
                            className={
                                rest.open
                                    ? classes.icon
                                    : classes.iconMarginRigth
                            }
                            onClick={() => acessarMenuRapido()}
                        />
                    </Tooltip>
                )}
                <Divider className={classes.divider} />

                <NavItem
                    handleDrawerOpen={() => rest.handleDrawerOpen()}
                    handleDrawerClose={() => rest.handleDrawerClose()}
                    open={rest.open}
                    getItems={() => getItems()}
                />
            </Box>
        </GlobalHotKeys>
    )
    return (
        <>
            {/*<Hidden lgUp>
                <Drawer
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="permanent"
                >
                    {content}
                </Drawer>
            </Hidden>*/}

            <Hidden classes={{ paper: classes.desktopDrawer }}>
                {content}
            </Hidden>
        </>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
}

NavBar.defaultProps = {
    openMobile: false,
}

export default NavBar
