import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {
    makeStyles,
    Button,
    TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
}));

export default function ModalCadastroCategoria(props) {
    const classes = useStyles();

    const [errors, setErrrors] = useState({})
    const [nomeCategoria, setNomeCategoria] = useState('');

    const submit = (nome) => {
        if(!nome || nome === "" || nome === " "){
            setErrrors({...errors, nome: {message: "Campo Obrigatório"}})
            return;
        }
        props.submit(nome);
        setNomeCategoria('');
    };

    return (
        <>
            <h2 className={classes.title}>Cadastro de categoria</h2>

            <Grid container item xs={12} display="flex">
                <FormControl className={classes.formControl}>
                    <TextField
                        name="nome"
                        id="nome"
                        variant="standard"
                        label="Nome*"
                        onChange={(e) => {
                            setErrrors({...errors, nome: false})
                            setNomeCategoria(e.target.value)
                        }}
                        placeholder="Digite o nome"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                     <p className={classes.errors}>
                        {errors.nome
                            ? errors.nome.message
                            : ''}
                    </p>
                </FormControl>
                <Grid
                    xs={12}
                    container
                    item
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Button
                        className={classes.button2}
                        variant="contained"
                        color="secundary"
                        size="small"
                        onClick={props.onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => submit(nomeCategoria)}
                    >
                        Cadastrar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
