import { SET_FILTRO_LISTAGEM_CONTA_GERENCIAL } from '../actions/contaGerencial';

export const storageContaGerencial = 'contaGerencial';

const initialState = {
    filtroListagem: {},
};

export const contaGerencial = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CONTA_GERENCIAL:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
