import { SET_FILTRO_LISTAGEM_CENTRO_CUSTO } from '../actions/centroCusto';

export const storageCentroCusto = 'centroCusto';

const initialState = {
    filtroListagem: {},
};

export const centroCusto = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CENTRO_CUSTO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
