import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/TransferenciaEstoque/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/TransferenciaEstoque${param}`);
    },
    cadastrar(param) {
        return service.post(`/TransferenciaEstoque`, param);
    },
    deletar(id) {
        return service.delete(`/TransferenciaEstoque/${id}`);
    },
};
