import moment from 'moment/moment.js'

export default class DataHelper {
    static getNomeMesAbreviado(mes) {
        const nomesMesesAbreviados = [
            'jan',
            'fev',
            'mar',
            'abr',
            'mai',
            'jun',
            'jul',
            'ago',
            'set',
            'out',
            'nov',
            'dez',
        ]

        return nomesMesesAbreviados[mes - 1]
    }

    static today() {
        let hoje = new Date()
        let ano = hoje.getFullYear()
        let mes = hoje.getMonth() + 1
        let dia = hoje.getDate()
        hoje =
            ano +
            '-' +
            (mes > 9 ? mes : '0' + mes) +
            '-' +
            (dia > 9 ? dia : '0' + dia)
        return hoje
    }

    static subtraiData(data, dias) {
        var result = new Date(data)
        result.setDate(result.getDate() - dias)
        let dataFormatada =
            result.getFullYear() +
            '-' +
            (result.getMonth() + 1) +
            '-' +
            result.getDate()
        return dataFormatada
    }

    static clone(object) {
        return JSON.parse(JSON.stringify(object))
    }

    static ValidarSeDataEValida(data) {
        if (data === undefined) return false
        return moment(data, 'DD/MM/YYYY', true).isValid()
    }

    static formataStringData(data) {
        if (data === undefined) return
        var dia = data.split('/')[0]
        var mes = data.split('/')[1]
        var ano = data.split('/')[2]

        return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2)
    }

    static transformStringDataUsaToPtBr(data) {
        if (!data) return
        var ano = data.split('-')[0]
        var mes = data.split('-')[1]
        var dia = data.split('-')[2]

        return ('0' + dia).slice(-2) + '/' + ('0' + mes).slice(-2) + '/' + ano
    }

    static formatStringDataBackToFront(data) {
        return data ? data.slice(0, 10) : ''
    }

    static formatStringDataBackTextFieldData(data) {
        if(!data) return;
        data = data ? data.slice(0, 10) : ''
        var ano = data.split('/')[2]
        var mes = data.split('/')[1]
        var dia = data.split('/')[0]

        return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2)
    }

    static converterDataParaString(data) {
        var dia =
            data.getDate().toString().length > 1
                ? data.getDate()
                : '0' + data.getDate()
        var mes =
            data.getMonth() + 1 >= 10
                ? data.getMonth() + 1
                : '0' + (data.getMonth() + 1)
        var ano = data.getFullYear()

        return `${dia}/${mes}/${ano}`
    }

    static compareTwoDates(paramFirstDate, paramSecondDate) {
        let firstDate = moment(paramFirstDate)
        let secondDate = moment(paramSecondDate)

        return firstDate._d >= secondDate._d
    }

    static transformStringDataMonthUsaToPtBr(data) {
        if (!data) return

        moment.locale('pt')
        moment.updateLocale('pt', {
            months: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
            ],
        })

        var dataTexto = data.split('-')
        var dataFormatada = new Date(dataTexto[0], dataTexto[1], 1)
        var dataConvertida = new Date(dataFormatada)

        return moment(
            dataConvertida.setMonth(dataConvertida.getMonth() - 1)
        ).format('MMMM [de] YYYY')
    }
}
