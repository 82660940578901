import React, { useState, useEffect } from 'react'
import Page from '../../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import ContaPagarReceberListagem from '../components/ContaPagarReceberListagem'
import Titulo from '../../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import ContasPagarReceberService from '../../../services/ContaPagarReceberService'
import ToastHelper from '../../../utils/toastHelper'
import ObjectHelper from '../../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'
import Modal from '@material-ui/core/Modal'
import Request from '../requests'
import ToolbarContasPagar from '../components/ToolbarContasPagar'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import ConteudoModalQuitarContas from '../components/ModalQuitarContas'
import ConteudoModalCancelarPagamento from '../components/ModalCancelarPagamento'
import ConteudoModalCancelarConciliacao from '../components/ModalCancelarConciliacao'
import ConteudoModalExclusaoListagem from '../components/ModalExclusãoListagem'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const ContaReceber = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [contasSelecionadas, setContasSelecionadas] = useState([])
    const [showModalQuitarContas, setShowModalQuitarContas] = useState(false)
    const [showModalConfirmaCancelamento, setShowModalConfirmaCancelamento] =
        useState(false)
    const [showModalConfirmaExclusao, setShowModalConfirmaExclusao] =
        useState(false)
    const [showModalConfirmaConciliacao, setShowModalConfirmaConciliacao] =
        useState(false)
    const [loading, setLoading] = useState(false)
    const [contas, setContas] = useState()
    const [conciliacao, setConciliacao] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.contaReceber.filtroListagem)
    )
    const [quantidadeParcela, setQuantidadeParcela] = useState(0)
    const [contaSelecionada, setContasSelecionada] = useState({})

    useEffect(() => {
        const getContasReceber = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'DataVencimento'
            buscarContasReceber(novoFiltro)
        }
        getContasReceber()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CONTA_RECEBER',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarContasReceber(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CONTA_RECEBER',
            filtroListagem: filtro,
        })
    }

    const onChangeNumberItens = (number) => {
        setFiltro({ ...filtro, tamanhoPagina: number })
        handleFiltro({ ...filtro, tamanhoPagina: number })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/conta/receber/cadastro`,
        })
    }

    const editar = (item) => {
        history.push(`/app/conta/receber/editar/${item.despesaReceitaId}`)
    }

    const confirmaDeletar = (item) => {
        const parcelas = contas.filter(
            (c) => c.despesaReceitaId === item.despesaReceitaId
        )
        const paga = parcelas.some((c) => c.dataPagamento)
        if (paga) {
            ToastHelper.error(
                `Conta com parcela paga, Documento ${item.documento}`
            )
            return
        }
        const quantidade = parcelas.length
        setQuantidadeParcela(quantidade)
        setContasSelecionada(item)
        setShowModalConfirmaExclusao(true)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarContasReceber(novoFiltro)
    }

    const buscarContasReceber = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.CreditoDebito = 0
        await alteraFiltro(filtroParaPesquisa)
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await ContasPagarReceberService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                        ? filtro.tamanhoPagina
                        : 100
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    alteraFiltro(newFiltro)
                    // let novosItens = trataItensParaTabela(response.data.itens);
                    setContas(response.data.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar contas')
                setLoading(false)
            })
    }

    const deletar = async (item) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.CreditoDebito = 0
        let params = { filtro: filtroParaPesquisa, idDespesa: item.despesaReceitaId }

        await ContasPagarReceberService.deletar(params).then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Parcela excluída com sucesso.')
                let newFiltro = { ...filtro }
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                // let novosItens = trataItensParaTabela(response.data.itens);
                setContas(response.data.itens)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field

        if (novoFiltro.ordem === 'nomeFantasia')
            novoFiltro.ordem = 'DespesaReceita.FornecedorCliente.NomeFantasia'

        if (novoFiltro.ordem === 'documento')
            novoFiltro.ordem = 'DespesaReceita.Documento'

        if (novoFiltro.ordem === 'descricao')
            novoFiltro.ordem = 'DespesaReceita.Descricao'

        alteraFiltro(novoFiltro)
        buscarContasReceber(novoFiltro)
    }

    const [produtosServicos, setProdutosServicos] = useState([])
    const [centroCusto, setcentroCusto] = useState([])
    const [locaisEstoque, setlocaisEstoque] = useState([])
    const [contaGerencial, setcontaGerencial] = useState([])

    const buscarCentrosDeCusto = async () => {
        let data = await Request.buscarCentrosDeCusto()
        setcentroCusto(data)
    }

    const buscarProdutosSerivocs = async () => {
        let data = await Request.buscarProdutosServicos()
        setProdutosServicos(data)
    }

    const buscarLocaisEstoque = async () => {
        let data = await Request.buscarLocaisEstoque()
        setlocaisEstoque(data)
    }

    const buscarContasGerenciais = async () => {
        let data = await Request.buscarContasGerenciais('receber')
        setcontaGerencial(data)
    }

    useEffect(() => {
        buscarProdutosSerivocs()
        buscarCentrosDeCusto()
        buscarLocaisEstoque()
        buscarContasGerenciais()
    }, [])

    const mudaContasSelecionadas = (rows) => {
        setContasSelecionadas(rows)
    }

    const quitarConta = (row) => {
        if (
            contasSelecionadas.filter(
                (item) => item.dataPagamento && item.tableData.checked
            ).length > 0
        ) {
            ToastHelper.error('Conta selecionada já foi paga.')
        } else {
            setShowModalQuitarContas(true)
        }
    }

    const cancelarPagamento = (row) => {
        if (
            contasSelecionadas.filter(
                (item) => !item.dataPagamento && item.tableData.checked
            ).length > 0
        ) {
            ToastHelper.error('Conta selecionada não foi paga.')
        } else if (
            contasSelecionadas.filter(
                (item) => item.dataCompensacao && item.tableData.checked
            ).length > 0
        ) {
            ToastHelper.error(
                'Não é possivel cancelar o pagamento de uma conta conciliada.'
            )
        } else setShowModalConfirmaCancelamento(true)
    }

    const conciliar = async (row) => {
        var valido = true
        row.forEach((item) => {
            if (item.dataPagamento === null) {
                valido = false
                ToastHelper.error('Conta não paga, documento ' + item.documento)
            }
        })
        if (valido) {
            setConciliacao(row)
            setShowModalQuitarContas(true)
        }
    }

    const cancelarConciliacao = async (row) => {
        var valido = true
        row.forEach((item) => {
            if (item.dataCompensacao === null) {
                valido = false
                ToastHelper.error(
                    'Conta não conciliada, documento ' + item.documento
                )
            }
        })
        if (valido) {
            setShowModalConfirmaConciliacao(true)
        }
    }

    const fechaModal = () => {
        setShowModalQuitarContas(false)
        setConciliacao(undefined)
    }

    return (
        <Page className={classes.root} title={'Contas a Receber'}>
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Contas a Receber'} />
                <ToolbarContasPagar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroAtivo
                    temFiltroAvancado
                    contaGerencial={contaGerencial}
                    locaisEstoque={locaisEstoque}
                    produtosServicos={produtosServicos}
                    centroCusto={centroCusto}
                />
                <Box mt={3}>
                    <ContaPagarReceberListagem
                        data={contas}
                        onChangeNumberItens={onChangeNumberItens}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        deletar={deletar}
                        confirmaDeletar={confirmaDeletar}
                        mudaOrdenacao={mudaOrdenacao}
                        mudaContasSelecionadas={(rows) =>
                            mudaContasSelecionadas(rows)
                        }
                        renderizarLinhasEmBranco={false}
                        quitarContaSimples={(row) => quitarConta(row)}
                        cancelarPagamento={(row) => cancelarPagamento(row)}
                        cancelarConciliacao={(row) => cancelarConciliacao(row)}
                        conciliar={(row) => conciliar(row)}
                        tipo="receber"
                    />
                </Box>
            </Container>
            <Modal
                className={classes.modal}
                open={showModalQuitarContas}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={showModalQuitarContas}>
                        <ConteudoModalQuitarContas
                            close={() => fechaModal()}
                            contasSelecionadas={contasSelecionadas}
                            showModalQuitarContas={showModalQuitarContas}
                            buscarContas={() => buscarContasReceber(filtro)}
                            tipo={'receber'}
                            conciliacao={conciliacao}
                            mudaContasSelecionadas={mudaContasSelecionadas}
                        />
                    </Fade>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={showModalConfirmaCancelamento}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={showModalConfirmaCancelamento}>
                        <ConteudoModalCancelarPagamento
                            buscarContas={() => buscarContasReceber(filtro)}
                            close={() =>
                                setShowModalConfirmaCancelamento(false)
                            }
                            contasSelecionadas={contasSelecionadas}
                            mudaContasSelecionadas={mudaContasSelecionadas}
                        />
                    </Fade>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={showModalConfirmaExclusao}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={showModalConfirmaExclusao}>
                        <ConteudoModalExclusaoListagem
                            close={() => setShowModalConfirmaExclusao(false)}
                            contas={contas}
                            quantidadeParcela={quantidadeParcela}
                            deletar={() => deletar(contaSelecionada)}
                        />
                    </Fade>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={showModalConfirmaConciliacao}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={showModalConfirmaCancelamento}>
                        <ConteudoModalCancelarConciliacao
                            buscarContas={() => buscarContasReceber(filtro)}
                            close={() => setShowModalConfirmaConciliacao(false)}
                            contasSelecionadas={contasSelecionadas}
                            mudaContasSelecionadas={mudaContasSelecionadas}
                        />
                    </Fade>
                </div>
            </Modal>
        </Page>
    )
}
