import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { RouteWithSubRoutes } from './RouteWithSubRoutes'
import '../shared/styles/404page.scss'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export const RenderRoutes = ({ routes }) => (
    <Switch>
        {routes.map((route) => {
            return (
                    <RouteWithSubRoutes
                        key={route.key}
                        history={history}
                        {...route}
                    />
            )
        })}
        <Redirect to="/404" />
    </Switch>
)
