import { ProdutorRuralCadastro } from '../pages/produtorRural/ProdutorRuralCadastro'
import { ProdutorRural } from '../pages/produtorRural/index'

export const ProdutorRuralRoute = [
    {
        path: '/app/produtorRural/cadastro',
        key: 'APP_PRODUTOR_CADASTRO',
        exact: true,
        component: ProdutorRuralCadastro,
    },
    {
        path: '/app/produtorRural',
        key: 'APP_PRODUTOR',
        exact: true,
        component: ProdutorRural,
    },
    {
        path: '/app/produtorRural/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_PRODUTOR_EDITAR',
        exact: true,
        component: ProdutorRuralCadastro,
    },
    {
        path: '/app/produtorRural/cadastro/:modo(aba)',
        key: 'APP_PRODUTOR_CADASTRO_MODO',
        exact: true,
        component: ProdutorRuralCadastro,
    },
]
