import React, { useState } from 'react'
import Page from '../../components/Page'
import clsx from 'clsx'
import {
    Container,
    Card,
    Grid,
    Button,
    TextField,
    makeStyles,
    FormControl,
} from '@material-ui/core'
import Titulo from 'components/Titulo'
import { useSelector, useDispatch } from 'react-redux'
import MenuSuspensoDetalhesDeCusto from './menuSuspensoDetalhesDeCusto'
import Box from '@material-ui/core/Box'
import Table from '../../components/Table'
import { Search as SearchIcon } from 'react-feather'
import ObjectHelper from '../../utils/objetcHelper'
import moment from 'moment'
import CustoService from 'services/CustoService'
import ToastHelper from '../../utils/toastHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    icon: {
        marginRight: '5px',
        width: '15px',
    },
    buttonBuscar: {
        marginTop: '20px',
        height: '40px',
        marginLeft: '30px',
    },
    error: {
        color: 'red',
    },
    paper: {
        paddingLeft: '10px',
    },
}))

export default function FluxoDeCaixa(props) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [erroDataInvalida, setErroDataInvalida] = React.useState({
        error: true,
        mensagem: '',
    })

    const [erroPeriodoInicial, setErroPeriodoInicial] = React.useState({
        error: true,
        mensagem: '',
    })
    const [erroPeriodoFinal, setErroPeriodoFinal] = React.useState({
        error: true,
        mensagem: '',
    })
    const [stateMenuSuspenso, setStateMenuSuspenso] = React.useState(false)
    const [itemSelecionado, setItemSelecionado] = React.useState([])

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

    const [dados, setDados] = React.useState()

    const [filtro, setFiltro] = useState(
        useSelector((state) => state.detalhesDeCusto.filtroListagem)
    )

    const colunas = [
        {
            title: 'Centro de custo',
            field: 'centroCusto',
            editable: 'never',
            sorting: false,
            render: (prop) => (
                <div
                    style={{ cursor: prop.idPai !== null ? 'pointer' : '' }}
                    onClick={
                        prop.idPai !== null
                            ? toggleDrawer('bottom', true, prop)
                            : () => {}
                    }
                >
                    {prop.centroCusto}
                </div>
            ),
        },
        {
            title: 'Custo',
            field: 'valor',
            editable: 'never',
            sorting: false,
            render: (prop) => (
                <div
                    style={{ cursor: prop.idPai !== null ? 'pointer' : '' }}
                    onClick={
                        prop.idPai !== null
                            ? toggleDrawer('bottom', true, prop)
                            : () => {}
                    }
                >
                    {prop.valor.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            ),
        },
    ]

    const validacaoDeFiltro = (filtro) => {
        let valido = true

        if (!filtro.periodoInicial) {
            setErroPeriodoInicial({
                error: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroPeriodoInicial({
                error: false,
                mensagem: '',
            })
        }
        if (!filtro.periodoFinal) {
            setErroPeriodoFinal({
                error: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroPeriodoFinal({
                error: false,
                mensagem: '',
            })
        }
        if (filtro.periodoInicial === parseInt(filtro.periodoInicial, 10)) {
            if (filtro.periodoInicial > filtro.periodoFinal) {
                setErroDataInvalida({
                    error: true,
                    mensagem: 'Período inicial não pode ser maior que o final.',
                })
                valido = false
            } else {
                setErroDataInvalida({
                    error: false,
                    mensagem: '',
                })
            }
        } else {
            var periodoInicial = moment(filtro.periodoInicial).format(
                'MM-DD-YYYY'
            )
            var periodoFinal = moment(filtro.periodoFinal).format('MM-DD-YYYY')

            periodoInicial = new Date(periodoInicial)
            periodoFinal = new Date(periodoFinal)
            if (periodoFinal.getTime() < periodoInicial.getTime()) {
                setErroDataInvalida({
                    error: true,
                    mensagem: 'Período inicial não pode ser maior que o final',
                })
                valido = false
            } else {
                setErroDataInvalida({
                    error: false,
                    mensagem: '',
                })
            }
        }
        return valido
    }

    const renderFilterMensal = () => {
        return (
            <>
                <Grid container item md={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Período inicial:*"
                            variant="standard"
                            type={'month'}
                            placeholder="Selecione"
                            value={filtro.periodoInicial}
                            onChange={(e) =>
                                setFiltro({
                                    ...filtro,
                                    periodoInicial: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={
                                {
                                    inputProps: {max: '9999-12'}
                                }
                            }
                        />
                        <p className={classes.error}>
                            {erroPeriodoInicial.error
                                ? erroPeriodoInicial.mensagem
                                : ''}
                        </p>
                        <p className={classes.error}>
                            {erroDataInvalida.error
                                ? erroDataInvalida.mensagem
                                : ''}
                        </p>
                    </FormControl>
                </Grid>
                <Grid container item md={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Período Final:*"
                            variant="standard"
                            type={'month'}
                            placeholder="Selecione"
                            value={filtro.periodoFinal}
                            style={{ marginRight: '30px' }}
                            onChange={(e) =>
                                setFiltro({
                                    ...filtro,
                                    periodoFinal: e.target.value,
                                })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={
                                {
                                    inputProps: {max: '9999-12'}
                                }
                            }
                        />
                        <p className={classes.error}>
                            {erroPeriodoFinal.error
                                ? erroPeriodoFinal.mensagem
                                : ''}
                        </p>
                    </FormControl>
                </Grid>
            </>
        )
    }

    const buscar = async () => {
        if (validacaoDeFiltro(filtro)) {
            alteraFiltroInRedux(filtro)

            var filtroMontado = {
                fazendaId: fazendaId,
                dataInicio: moment(filtro.periodoInicial).format('MM/DD/YYYY'),
                dataFim: moment(filtro.periodoFinal).format('MM/DD/YYYY'),
            }

            let params = await ObjectHelper.createParams(filtroMontado)

            CustoService.buscarTudo(params)
                .then((response) => {
                    setDados(response.data)
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data)
                })
        }
    }

    const alteraFiltroInRedux = async (filtro) => {
        await dispatch({
            type: 'SET_FILTRO_DETALHES_CUSTO',
            filtroListagem: filtro,
        })
    }

    const toggleDrawer = (_, open, item) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        if (open) {
            setItemSelecionado(item.detalhesCusto)
        } else {
            setItemSelecionado([])
        }

        setStateMenuSuspenso(open)
    }

    return (
        <Page className={classes.root} title="Detalhes de custo">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Detalhes de custo'} />
                <Grid container item md={12} display="flex">
                    <Card className={classes.card}>
                        <Box mt={1} mb={1}>
                            <Grid
                                container
                                item
                                md={12}
                                display="flex"
                                style={{ marginLeft: '15px' }}
                            >
                                {renderFilterMensal()}
                            </Grid>
                        </Box>
                    </Card>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => buscar()}
                        className={classes.buttonBuscar}
                    >
                        <SearchIcon
                            className={clsx(classes.icon, props.className)}
                        />
                        Buscar
                    </Button>
                </Grid>

                <Box mt={1} mb={1}>
                    <Table
                        title={''}
                        removeToolbar={true}
                        draggable={false}
                        columns={colunas}
                        data={dados}
                        paging={false}
                        emptyRowsWhenPaging={false}
                        haveParentChild={true}
                    />
                </Box>

                <MenuSuspensoDetalhesDeCusto
                    periodoSelecionado={''}
                    setStateMenuSuspenso={setStateMenuSuspenso}
                    stateMenuSuspenso={stateMenuSuspenso}
                    toggleDrawer={toggleDrawer}
                    itemSelecionado={itemSelecionado}
                />
            </Container>
        </Page>
    )
}
