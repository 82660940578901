import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    title: {
        flex: '1 1 100%',
        margin: 10
    },
    th: {
        fontWeight: 'bold'
    },
    tableContainer: {
        marginBottom: 20
    }
});


export default function Table2(props) {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {props.titulo}
            </Typography>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {
                                props.colunas.map((column) => (
                                    <TableCell className={classes.th} align="left">{column.title}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dados.map((rowData, index) => (
                            <TableRow key={index}>
                                {props.colunas.map(({ title, field }) => (
                                    <TableCell component="th" scope="row">
                                        {rowData[field]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
