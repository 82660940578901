import { STORE_FAZENDA } from '../actions/fazenda';

export const storageFazenda = 'fazenda';

const initialState = {
    fazendaSelecionada: null,
};

export const fazenda = (state = initialState, action) => {
    switch (action.type) {
        case STORE_FAZENDA:
            return {
                ...state,
                fazendaSelecionada: action.fazenda,
            };
        default:
            return state;
    }
};
