import React, { useEffect, useState } from 'react'
import MaterialTable from '@material-table/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import DataHelper from 'utils/dataHelpder'

export default function TableOrcamento({ ...props }) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#009688',
            },
        },
    })

    const [colunas, setColunas] = useState()
    const [dadosOriginais, setDadosOriginais] = useState()
    const [dadosTabela, setDadosTabela] = useState()

    useEffect(() => {
        if (props.dados) {
            const itens = props.dados

            itens.forEach(
                (item) =>
                    (item.tituloColuna = `${DataHelper.getNomeMesAbreviado(
                        item.mes
                    )}/${item.ano}`)
            )
            setDadosOriginais(itens)

            const colunas = [
                {
                    title: '',
                    field: 'contaGerencialDescricao',
                    cellStyle: {
                        fontWeight: 'bold',
                        minWidth: 200,
                    },
                    headerStyle: {
                        fontWeight: 'bold',
                        minWidth: 200,
                    },
                    editable: 'never',
                },
            ]
            const dados = []

            const colunasAdicionadas = []
            const dadosAdicionados = {}
            itens.forEach((item) => {
                if (!colunasAdicionadas.includes(item.tituloColuna)) {
                    colunas.push({
                        title: item.tituloColuna,
                        field: item.tituloColuna,
                        type: 'currency',
                        currencySetting: {
                            locale: 'pt-BR',
                            currencyCode: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        },
                        headerStyle: {
                            fontWeight: 'bold',
                            minWidth: 200,
                        },
                        cellStyle: {
                            minWidth: 200,
                        },
                        align: 'left',
                    })
                    colunasAdicionadas.push(item.tituloColuna)
                }

                if (!dadosAdicionados[item.contaGerencialDescricao]) {
                    const dado = {
                        contaGerencialId: item.contaGerencialId,
                        contaGerencialDescricao: item.contaGerencialDescricao,
                        contaGerencialPaiId: item.contaGerencialPaiId,
                    }
                    dadosAdicionados[item.contaGerencialDescricao] = dado
                    dados.push(dado)
                }
                dadosAdicionados[item.contaGerencialDescricao][
                    item.tituloColuna
                ] = item.valor
            })

            setDadosTabela(dados)
            setColunas(colunas)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dados])

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    columns={colunas}
                    parentChildData={(row, rows) =>
                        rows.find(
                            (a) =>
                                a.contaGerencialId === row.contaGerencialPaiId
                        )
                    }
                    data={dadosTabela}
                    options={{
                        paging: false,
                        padding: 'dense',
                        sorting: false,
                        draggable: false,
                        rowStyle: {
                            fontSize: 14,
                        },
                        search: false,
                        actionsColumnIndex: -1,
                        showTitle: false,
                        defaultExpanded: true,
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro para exibir',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Edição rápida',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                        },
                    }}
                    cellEditable={{
                        onCellEditApproved: (
                            newValue,
                            oldValue,
                            rowData,
                            columnDef
                        ) => {
                            return new Promise((resolve, reject) => {
                                rowData[columnDef.field] = newValue
                                const dadoOriginal = dadosOriginais.filter(
                                    (item) =>
                                        item.tituloColuna === columnDef.field &&
                                        item.contaGerencialId ===
                                            rowData.contaGerencialId
                                )
                                if (dadoOriginal && dadoOriginal.length > 0) {
                                    dadoOriginal[0].valor = newValue
                                }
                                resolve()
                            })
                        },
                    }}
                />
            </MuiThemeProvider>
        </>
    )
}
