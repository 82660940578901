export default class ObjectHelper {
    static clone(object) {
        return JSON.parse(JSON.stringify(object))
    }

    static removeInativos(list) {
        let ativos = []
        list.forEach((item) => {
            if (item.ativo !== undefined && item.ativo === true) {
                ativos.push(item)
            }
        })

        return ativos
    }

    static ordenaLista = async (lista, campo, decrescente) => {
        let newlista = [...lista]
        if (newlista.length > 0) {
            if (!decrescente) {
                newlista = await newlista.sort(function compare(a, b) {
                    if (a[campo]?.toLowerCase() < b[campo]?.toLowerCase())
                        return -1
                    if (a[campo]?.toLowerCase() > b[campo]?.toLowerCase())
                        return 1
                    return 0
                })
            } else {
                newlista = await newlista.sort(function compare(a, b) {
                    if (a[campo]?.toLowerCase() < b[campo]?.toLowerCase())
                        return 1
                    if (a[campo]?.toLowerCase() > b[campo]?.toLowerCase())
                        return -1
                    return 0
                })
            }
        }
        return newlista
    }

    static createParams = async (data) => {
        let url = await Object.keys(data)
            .map(function (k) {
                return data[k] !== null && data[k] !== undefined
                    ? encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                    : ''
            })
            .join('&')

        url = new URLSearchParams(url).toString()
        url = await url.replace('texto', 'filtro')
        return '?' + url
    }

    static getValuesParseToSelect(list, valueName, labelName) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'nome'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                ativo: item.ativo ? item.ativo : undefined,
            })
        })
        return options
    }

    static getValuesFormaPagamentoParseToSelect(
        list,
        valueName,
        labelName,
        codigo
    ) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'nome'
        codigo = codigo || 'codigo'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                codigo: item[codigo],
                ativo: item.ativo ? item.ativo : undefined,
            })
        })
        return options
    }

    static parseValuesUnidadesToSelect(list, valueName, labelName) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'sigla'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                ativo: item.ativo,
            })
        })
        return options
    }

    static getValuesContaPaiParseToSelect(
        list,
        valueName,
        labelName,
        numero,
        operacao
    ) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'descricao'
        numero = numero || 'numero'
        operacao = 'operacao'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                numero: item[numero],
                operacao: {
                    value: item[operacao].valor,
                    label: item[operacao].descricao,
                },
            })
        })
        return options
    }

    static getValuesFazendaParseToSelect(list, valueName, labelName) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'nomeRazao'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({ value: item[valueName], label: item[labelName] })
        })
        return options
    }

    static getValuesDescricaoLabelParseToSelect(list, valueName, labelName) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'descricao'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
            })
        })
        return options
    }

    static getValuesNomeFantasiaLabelParseToSelect(list, valueName, labelName) {
        let options = []

        valueName = valueName || 'id'
        labelName = labelName || 'nomeFantasia'
        list = Array.isArray(list) ? list : []
        list.forEach((item) => {
            options.push({
                value: item[valueName],
                label: item[labelName],
                ativo: item.ativo ? item.ativo : undefined,
            })
        })
        return options
    }

    static getValueByPropertyName(propertyName, object) {
        if (propertyName === undefined) return ''
        const parts = propertyName.split('.')
        const length = parts.length
        let i
        let value = object || this
        if (!value) return null

        for (i = 0; i < length; i++) {
            value = value[parts[i]]
        }

        if (value === 0) return value

        return value || null
    }

    static getObjectAndFieldName(string) {
        let splitName = string.split('.')
        if (splitName.length > 1) {
            return {
                object: splitName[0],
                field: splitName[1],
            }
        }

        return null
    }

    static convertCurrencyStringToFloat(string) {
        string = string.replace('R$', '')
        string = string.replaceAll('.', '')
        string = string.replace(',', '.')
        string = parseFloat(string)
        return string
    }

    static  convertCurrencyToFloat(dinheiro) {
       var numero = parseFloat(dinheiro.replace(/R\$/g, '').replace(/\./g, '').replace(',', '.'));
       return numero;
    }

    static convertFloatToCurrency(value) {
        var formatted = (parseFloat(value))?.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        return formatted;
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}
