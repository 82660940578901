import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import CentroCustoListagem from './CentroCustoListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import CentroCustoService from '../../services/CentroCustoService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const CentroCusto = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [loading, setLoading] = useState(false)
    const [centroCusto, setCentroCusto] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.centroCusto.filtroListagem)
    )

    useEffect(() => {
        buscarCentroCusto(filtro)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CENTRO_CUSTO',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        filtro.ativo = true
        buscarCentroCusto(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CENTRO_CUSTO',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        props.history.push({
            pathname: `/app/centroCusto/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarCentroCusto(novoFiltro)
    }

    const buscarCentroCusto = async (filtro) => {
        filtro.fazendaId = FazendaId
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        await CentroCustoService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = FazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                newFiltro.ativo = true
                alteraFiltro(newFiltro)

                const lista = ordenaCentroCustoListagem(response.data.itens)

                setCentroCusto(lista)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const editar = (itemId) => {
        history.push(`/app/centroCusto/editar/${itemId}`)
    }

    const deletar = async (item) => {
        await CentroCustoService.deletar(item.id)
            .then((response) => {
                if (response.status === 200) {
                    ToastHelper.success('Centro de custo excluído com sucesso!')
                    let novoFiltro = { ...filtro }
                    buscarCentroCusto(novoFiltro)
                } else {
                    ToastHelper.error(response)
                    setLoading(false)
                }
            })
            .catch((e) => {
                ToastHelper.error('Não é possivel excluir este centro de custo')
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        if (colunas[numeroColuna]?.field === 'tipoNome') {
            novoFiltro.ordem = 'tipo'
        } else {
            novoFiltro.ordem = colunas[numeroColuna]?.field
        }
        alteraFiltro(novoFiltro)
        buscarCentroCusto(novoFiltro)
    }

    const editarCelula = async (novoValor, valorAntigo, linha, coluna) => {
        var dados = centroCusto
        dados[linha.tableData.id][coluna.field] = novoValor

        await CentroCustoService.atualizar(dados[linha.tableData.id]).then(
            (response) => {
                if (response.status === 200) {
                    ToastHelper.success('Centro de custo excluído com sucesso!')
                    let novoFiltro = { ...filtro }
                    buscarCentroCusto(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const associarColaboradores = async (itemId) => {
        history.push(`/app/centroCusto/${itemId}/associarColaboradores`)
    }

    const ordenaCentroCustoListagem = (lista) => {
        const codigosComNumeros = []
        const codigosComLetras = []

        lista.map((item) => {
            const isNumero = !isNaN(Number(item.codigo))
            const isLetra = isNaN(Number(item.codigo))

            if (isNumero) codigosComNumeros.push(item)
            if (isLetra) codigosComLetras.push(item)
        })

        const numerosOrdenados = codigosComNumeros.sort((a, b) => {
            return Number(a.codigo) - Number(b.codigo)
        })

        const letrasOrdenadas = codigosComLetras.sort((a, b) => {
            if (a.codigo.toUpperCase() < b.codigo.toUpperCase()) {
                return -1;
            }

            if (a.codigo.toUpperCase() > b.codigo.toUpperCase()) {
                return 1;
            }

            return 0;
        })

        const listaOrdenada = [...numerosOrdenados, ...letrasOrdenadas]

        return listaOrdenada
    }

    return (
        <Page className={classes.root} title="Centro de Custos">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Centro de Custos" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                />
                <Box mt={3}>
                    <CentroCustoListagem
                        data={centroCusto}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        editarCelula={editarCelula}
                        associarColaboradores={(e) => associarColaboradores(e)}
                    />
                </Box>
            </Container>
        </Page>
    )
}
