import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import FiltroSimples from './FiltroSimples'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FiltroAvancado from './FiltroAvancado'

const Toolbar = ({ handleFiltro, filtroInicial, className, ...rest }) => {
    const [filtro, setFiltro] = useState(filtroInicial)
    const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded)
    const setStateAcocordion = () => {
        let newFiltro = { ...filtro }
        newFiltro.filtroExpanded = !filtroExpanded
        setFiltro(newFiltro);
        setFiltroExpanded(!filtroExpanded)
    }

    const changeFiltro = (campo, event) => {
        let newFiltro = { ...filtro }
        newFiltro[campo] = event.target.value;
        setFiltro(newFiltro);
    }

    const submit = (event) => {

        if ((event ? event.keyCode : event.which) === 13) {
            handleFiltro(filtro)
        }
    }

    const submitAvancado = () => {
        handleFiltro(filtro)
    }

    const changeFiltroTextField = (campo, event, newValue) => {
        let newFiltro = { ...filtro }
        if (event) newFiltro[campo] = event.target.value
        else newFiltro[campo] = ''
        setFiltro(newFiltro)
    }

    const changeFiltroAtivo = (campo, event, newValue) => {
        let newFiltro = { ...filtro }
        if (newValue) newFiltro[campo] = newValue
        else newFiltro[campo] = ''
        setFiltro(newFiltro)
    }

    const changeFiltroProdutoServico = (campo, event, newValue) => {
        let newFiltro = { ...filtro }
        if (newValue) newFiltro[campo] = newValue
        else newFiltro[campo] = ''
        setFiltro(newFiltro)
    }

    const changeFiltroControleEstoque = (campo, event, newValue) => {
        let newFiltro = { ...filtro }
        if (newValue) newFiltro[campo] = newValue
        else newFiltro[campo] = ''
        setFiltro(newFiltro)
    }

    const changeFiltroAutoComplete = (campo, event, newValue) => {
        let newFiltro = { ...filtro }
        if (newValue) newFiltro[campo] = newValue
        else newFiltro[campo] = ''
        setFiltro(newFiltro)
    }

    return (
        <Box item mt={3}>
            <Accordion
                expanded={filtroExpanded}
                onChange={(e) => setStateAcocordion}
            >
                <AccordionSummary
                    expandIcon={
                        !rest.temFiltroAvancado ? '' : <ExpandMoreIcon />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={() => setStateAcocordion(!filtroExpanded)}
                >
                    <FiltroSimples
                        filtro={filtro}
                        changeFiltro={changeFiltro}
                        submit={submit}
                        temFiltroAvancado={rest.temFiltroAvancado}
                        clickadd={rest.clickadd}
                    />
                </AccordionSummary>
                {rest.temFiltroAvancado && (
                    <AccordionDetails>
                        <FiltroAvancado
                            filtro={filtro}
                            filtroInicial={filtroInicial}
                            setFiltro={setFiltro}
                            handleFiltro={handleFiltro}
                            changeFiltroAutoComplete={changeFiltroAutoComplete}
                            changeFiltroAtivo={changeFiltroAtivo}
                            changeFiltroControleEstoque={
                                changeFiltroControleEstoque
                            }
                            changeFiltroProdutoServico={
                                changeFiltroProdutoServico
                            }
                            changeFiltroTextField={changeFiltroTextField}
                            filtroNome={rest.filtroNome}
                            filtroCodigo={rest.filtroCodigo}
                            filtroTiposDeConta={rest.filtroTiposDeConta}
                            filtroOperacoes={rest.filtroOperacoes}
                            filtroContas={rest.filtroContas}
                            filtroBancos={rest.filtroBancos}
                            filtroControleEstoque={rest.filtroControleEstoque}
                            filtroAtivo={rest.filtroAtivo}
                            filtroAnaliticoSintetico={
                                rest.filtroAnaliticoSintetico
                            }
                            filtroProdutoServico={rest.filtroProdutoServico}
                            filtroPeriodo={rest.filtroPeriodo}
                            filtroPeriodoDataVencimento={rest.filtroPeriodoDataVencimento}
                            filtroNumeroDocumento={rest.filtroNumeroDocumento}
                            filtroContasCredito={rest.filtroContasCredito}
                            filtroContasDebito={rest.filtroContasDebito}
                            filtroLocalOrigemDestino={
                                rest.filtroLocalOrigemDestino
                            }
                            filtroFornecedoresClientes={rest.filtroFornecedoresClientes}
                            filtroProdutos={rest.filtroProdutos}
                            filtroLocalEstoque={rest.filtroLocalEstoque}
                            filtroLocaisEstoque={rest.filtroLocaisEstoque}
                            filtroContasGerenciais={rest.filtroContasGerenciais}
                            filtroAplicadoContasPagarReceber={
                                rest.filtroAplicadoContasPagarReceber
                            }
                            filtroAcrescimoDesconto={
                                rest.filtroAcrescimoDesconto
                            }
                            filtroFornecedorCliente={
                                rest.filtroFornecedorCliente
                            }
                            filtroSituacaoAdiantamento={rest.filtroSituacaoAdiantamento}
                            filtroCpfCnpj={rest.filtroCpfCnpj}
                            filtroNomeFantasia={rest.filtroNomeFantasia}
                            filtroPeriodoExtratoBancario={
                                rest.filtroPeriodoExtratoBancario
                            }
                            filtroProdutoAcabado={rest.filtroProdutoAcabado}
                            filtroOperacaoMovimentoEstoque={
                                rest.filtroOperacaoMovimentoEstoque
                            }
                            filtroSafra={rest.filtroSafra}
                            filtroProduto={rest.filtroProduto}
                            changeFiltro={changeFiltro}
                            submit={(e) => submitAvancado(e)}
                        />
                    </AccordionDetails>
                )}
            </Accordion>
        </Box>
    )
}

export default Toolbar
