import { Pedidos } from '../pages/pedidos/index'
import { PedidosCadastro } from '../pages/pedidos/components/PedidosCadastro'

export const PedidosRoute = [
    {
        path: '/app/pedidos',
        key: 'APP_PEDIDOS',
        exact: true,
        component: Pedidos,
    },
    {
        path: '/app/pedidos/cadastro',
        key: 'APP_PEDIDOS_CADASTRO',
        exact: true,
        component: PedidosCadastro,
    },
    {
        path: '/app/pedidos/:modo(editar|visualizar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_PEDIDOS_EDITAR_VISUALIZAR',
        exact: true,
        component: PedidosCadastro,
    }
]
