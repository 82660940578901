import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Button, TextField, Divider } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Request from '../requests'
import NumberFormat from 'react-number-format'
import SelectWithButton from '../../../components/SelectWithButton'
import ModalCadastroContaGerencial from 'pages/produtosServicos/cadastrosRapido/ModalCadastroContaGerencial'
import AddCircle from '@material-ui/icons/AddCircle'
import Tooltip from '@material-ui/core/Tooltip'
import { Box } from '@material-ui/core'
import { useEffect } from 'react'
import CentroCustoService from 'services/CentroCustoService'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        margin: 80,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlCadastroRapido: {
        margin: theme.spacing(1),
        minWidth: '80%',
        marginRight: 0,
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    formControlProdutosServicos: {
        margin: theme.spacing(1),
        minWidth: '96%',
    },
    addCircle: {
        marginLeft: '0px',
    },
}))

export default function ModalProdutosServicos(props) {
    const [opcoesCusto] = useState([
        {
            value: 'CENTRO_DE_CUSTO',
            label: 'Centro de custo',
        },
        {
            value: 'ESTOQUE',
            label: 'Estoque',
        },
        {
            value: 'PATRIMONIO',
            label: 'Patrimônio',
        },
    ])

    const classes = useStyles()
    const [form, setform] = useState({
        alocacaoCusto: opcoesCusto[0].value,
    })

    const [opcoesUnidades, setOpcoesUnidades] = useState([])
    const [tipoServico, setTipoServico] = useState(false)
    const [possuiEstoque,setPossuiEstoque] = useState(false);
    let produtosServicos = props.opcoesProdutosServicos || []
    let centroCusto = props.opcoesCentroCusto || []
    let patrimonio = props.opcoesPatrimonio || []
    let locaisEstoque = props.opcoesLocaisEstoque || []
    let safras = props.opcoesSafra || []
    let grupoSafras = props.opcoesGrupoSafras || []
    let contaGerencial = props.opcoesContaGerencial || []
    const [
        openModalCadastroContaGerencialCompra,
        setOpenModalCadastroContaGerencialCompra,
    ] = useState(false)

    const [errors, setErrors] = React.useState([])

    const submit = async () => {
        if (!form.produtoServico?.produto) {
            form.localEstoqueId = ''
            form.localEstoque = ''
        }
        form.despesaReceitaId = props.id
        var valida = await Request.validacaoFormModalProdutosServicos(
            form,
            props.tipo
        )
        if (valida.length > 0) {
            setErrors(valida)
        } else {
            props.alteraProdutosServicosListagem(form)
            props.close()
            setform({})
        }
    }

    const buscarUnidades = async (unidadeAtual) => {
        let data = await Request.buscarUnidadesDeMedida(unidadeAtual)
        setOpcoesUnidades(data)
    }

    const onChangeValorTotal = (e) => {
        let valorUnitario =
            parseFloat(e.value || 0) / parseFloat(form.quantidade || 0)
        setform({
            ...form,
            valorTotal: parseFloat(e.value || 0),
            valorUnitario,
        })
        setErrors([])
    }

    const onChangeQuantidade = (e) => {
        var valorUnitario = form.valorUnitario
            ? parseFloat(form.valorUnitario)
            : 0
        let total = parseFloat(e.target.value || 0) * valorUnitario
        setform({
            ...form,
            valorTotal: total,
            quantidade: parseFloat(e.target.value || 0),
        })
        setErrors([])
    }

    const onChangeValorUnitario = (e) => {
        var quantidade = form.quantidade ? parseFloat(form.quantidade) : 0
        let total =
            parseFloat(e.target.value.replaceAll('.', '').replace(',', '.')) *
            quantidade
        setform({
            ...form,
            valorTotal: total,
            valorUnitario: parseFloat(
                e.target.value.replaceAll('.', '').replace(',', '.')
            ),
        })
        setErrors([])
    }


    useEffect(() => {
        if(form.alocacaoCusto !== 'CENTRO_DE_CUSTO') props.setCentroDeCusto(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form.alocacaoCusto])
 
    const onChangeProduct = (e) => {
        if(e?.possuiControleDeEstoque) {
            setPossuiEstoque(true)
        } else {
            setPossuiEstoque(false);
        }
        if (e?.produto) {
            buscarUnidades(e.unidadeMedida.id)
            setTipoServico(false)
        } else {
            setTipoServico(true)
            buscarUnidades()
        }
        const alocacaoCustoEstoque = opcoesCusto.find(
            (item) => item.value === 'ESTOQUE'
        )
        if (e?.produto) {
            if (props.tipo === 'pagar') {
                setform({
                    ...form,
                    servico: null,
                    servicoId: null,
                    produtoServico: e ? e : '',
                    produtoServicoId: e?.id,
                    contaGerencial: {
                        descricao: e?.contaGerencialPadraoCompra
                            ? e.contaGerencialPadraoCompra.numero +
                              ' - ' +
                              e.contaGerencialPadraoCompra.descricao
                            : '',
                        id: e?.contaGerencialPadraoCompra?.id,
                    },
                    contaGerencialId: e?.contaGerencialPadraoCompra?.id,
                    localEstoque: {
                        nome: e?.localEstoquePadrao
                            ? e?.localEstoquePadrao.nome +
                              ' - ' +
                              e?.localEstoquePadrao.tipoEstoque.nome
                            : '',
                        id: e?.localEstoquePadrao?.id,
                    },
                    localEstoqueId: e?.localEstoquePadrao?.id,
                    unidade: {
                        sigla: e?.unidadeMedida?.sigla,
                        id: e?.unidadeMedida?.id,
                    },
                    alocacaoCusto: e.possuiControleDeEstoque
                        ? alocacaoCustoEstoque.value
                        : opcoesCusto[0].value,
                    alocacaoCustoId: e.possuiControleDeEstoque
                        ? alocacaoCustoEstoque.value
                        : opcoesCusto[0].value,
                    unidadeId: e?.unidadeMedida?.id,
                    centroCustoId: '',
                    centroCusto:'',
                    safraId:'',
                    grupoSafraId:'',
                    safra:'',
                    grupoSafra:''
                })
            } else {
                setform({
                    ...form,
                    centroCusto:'',
                    centroCustoId: '',
                    produtoServico: e ? e : '',
                    produtoServicoId: e?.id,
                    contaGerencial: {
                        descricao: e?.contaGerencialPadraoVenda
                            ? e.contaGerencialPadraoVenda.numero +
                              ' - ' +
                              e.contaGerencialPadraoVenda.descricao
                            : '',
                        id: e?.contaGerencialPadraoVenda?.id,
                    },
                    contaGerencialId: e?.contaGerencialPadraoVenda?.id,
                    localEstoque: {
                        nome: e?.localEstoquePadrao
                            ? e?.localEstoquePadrao.nome +
                              ' - ' +
                              e?.localEstoquePadrao.tipoEstoque.nome
                            : '',
                        id: e?.localEstoquePadrao?.id,
                    },
                    localEstoqueId: e?.localEstoquePadrao?.id,
                    unidade: {
                        sigla: e?.unidadeMedida?.sigla,
                        id: e?.unidadeMedida?.id,
                    },
                    alocacaoCusto: e.possuiControleDeEstoque
                        ? alocacaoCustoEstoque.value
                        : opcoesCusto[0].value,
                    alocacaoCustoId: e.possuiControleDeEstoque
                        ? alocacaoCustoEstoque.value
                        : opcoesCusto[0].value,
                    unidadeId: e?.unidadeMedida?.id,
                    safraId:'',
                    grupoSafraId:'',
                    safra:'',
                    grupoSafra:''
                })
            }
        } else {
            if (props.tipo === 'pagar') {
                props.setCentroDeCusto(e?.centroCustoPadrao)
                setform({
                    ...form,
                    produtoServico: e ? e : '',
                    produtoServicoId: e?.id,
                    localEstoqueId: '',
                    localEstoque: '',
                    contaGerencial: {
                        descricao: e?.contaGerencialPadraoCompra
                            ? e.contaGerencialPadraoCompra.numero +
                              ' - ' +
                              e.contaGerencialPadraoCompra.descricao
                            : '',
                        id: e?.contaGerencialPadraoCompra?.id,
                    },
                    contaGerencialId: e?.contaGerencialPadraoCompra?.id,
                    centroCusto: e?.centroCustoPadrao
                        ? e.centroCustoPadrao
                        : '',
                    patrimonio: e?.patrimonioPadrao ? e.patrimonioPadrao : '',
                    centroCustoId: e?.centroCustoPadrao?.id,
                    patrimonioId: e?.patrimonioPadrao?.id,
                    unidade: [],
                    alocacaoCusto: opcoesCusto[0].value,
                    unidadeId: null,
                    safraId:'',
                    grupoSafraId:'',
                    safra:'',
                    grupoSafra:''
                })
            } else {
                props.setCentroDeCusto(e?.centroCustoPadrao)
                setform({
                    ...form,
                    produtoServico: e ? e : '',
                    produtoServicoId: e?.id,
                    localEstoqueId: '',
                    localEstoque: '',
                    contaGerencial: {
                        descricao: e?.contaGerencialPadraoVenda
                            ? e.contaGerencialPadraoVenda.numero +
                              ' - ' +
                              e.contaGerencialPadraoVenda.descricao
                            : '',
                        id: e?.contaGerencialPadraoVenda?.id,
                    },
                    contaGerencialId: e?.contaGerencialPadraoVenda?.id,
                    centroCusto: e?.centroCustoPadrao
                        ? e.centroCustoPadrao
                        : '',
                    patrimonio: e?.patrimonioPadrao ? e.patrimonioPadrao : '',
                    centroCustoId: e?.centroCustoPadrao?.id,
                    patrimonioId: e?.patrimonioPadrao?.id,
                    unidade: [],
                    unidadeId: null,
                    safraId:'',
                    grupoSafraId:'',
                    safra:'',
                    grupoSafra:''
                })
            }
        }
    }

    useEffect(() => {
        if(form.centroCusto) {
            const  buscarCentroCusto =  async () => {
               const centroCustoData =  await CentroCustoService.buscarPorId(form.centroCustoId)
               props.setCentroDeCusto(centroCustoData.data)
               setform(({...form,centroCustoValidate:centroCustoData.data}))
            }
            buscarCentroCusto();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form.centroCusto])
    const cadastroProdutoServico = async () => {
        const win = window.open(`/app/produtosServicos/cadastro/aba`, '_blank')
        win.addEventListener(
            'beforeunload',
            () => {
                props.buscarProdutosServicos()
            },
            false
        )
    }

    const checarTipo = props.tipo === 'pagar'
  
    return (
        <div className={classes.paper}>
            <h2 className={classes.title}>Cadastro de Produtos/Serviços</h2>
            <Divider className={classes.divider} />

            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <SelectWithButton
                            id="produtosServicos"
                            name="produtosServicos"
                            options={produtosServicos}
                            getOptionLabel={(option) =>
                                option ? option.nome : ''
                            }
                            getOptionSelected={(option, value) =>
                                option?.id === value?.id
                            }
                            value={
                                form.produtoServico ? form.produtoServico : ''
                            }
                            onChange={(event, option) => {
                                onChangeProduct(option)
                                setErrors([])
                            }}
                            label="Produtos/Serviços*"
                            placeholder="Selecione"
                            error={errors.find(
                                (x) => x.id === 'produtoServicoId'
                            )}
                            errorMessage={'Campo Obrigatório'}
                            formControlClass={
                                classes.formControlProdutosServicos
                            }
                            tooltip="Cadastro de produto/serviço"
                            ml={-1}
                            cadastro={cadastroProdutoServico}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            disabled={
                                tipoServico && props.tipo === 'pagar'
                                    ? false
                                    : true
                            }
                            options={props.opcoesServicos}
                            getOptionLabel={(option) => option.nome}
                            disableClearable
                            onChange={(event, option) => {
                                setform({
                                    ...form,
                                    servico: option ? option : '',
                                    servicoId: option?.id,
                                })
                                setErrors([])
                            }}
                            value={form.servico ? form.servico : ''}
                            defaultValue={form.servico ? form.servico : ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Serviço"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            options={opcoesUnidades}
                            getOptionLabel={(option) => option.sigla}
                            disableClearable
                            onChange={(event, option) => {
                                setform({
                                    ...form,
                                    unidade: option ? option : '',
                                    unidadeId: option?.id,
                                })
                                setErrors([])
                            }}
                            value={form.unidade ? form.unidade : ''}
                            defaultValue={form.unidade ? form.unidade : ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Unidade*"
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {errors.find((x) => x.id === 'unidadeId') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="quantidade"
                            type="number"
                            id="quantidade"
                            variant="standard"
                            autoComplete="off"
                            label="Quantidade*"
                            onChange={(e) => {
                                onChangeQuantidade(e)
                                setErrors([])
                            }}
                            placeholder="Digite a quantidade"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                        {errors.find((x) => x.id === 'quantidade') && (
                            <p className={classes.errors}>
                                {' '}
                                { errors.find(x => x.id === 'quantidade')?.message || 'Campo Obrigatório !' }
                            </p>
                        )}
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <NumberFormat
                            label="Valor Unitário*"
                            value={form.valorUnitario}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            customInput={TextField}
                            onChange={(e) => {
                                onChangeValorUnitario(e)
                                setErrors([])
                            }}
                            id="valorUnitario"
                            name="valorUnitario"
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'left' },
                            }}
                            isplaceholderanimated={(false, 'top')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.find((x) => x.id === 'valorUnitario') && (
                            <p className={classes.errors}>
                                {' '}
                                { errors.find(x => x.id === 'valorUnitario')?.message || 'Campo Obrigatório !' }
                            </p>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} display="flex">
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <NumberFormat
                            value={form.valorTotal}
                            label="Valor Total"
                            placeholder="R$ 0,00"
                            prefix={'R$ '}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={2}
                            customInput={TextField}
                            disabled={!form.quantidade || form.quantidade <= 0}
                            inputProps={{
                                min: 0,
                                style: {
                                    textAlign: 'left',
                                },
                            }}
                            isplaceholderanimated={(false, 'top')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onValueChange={(e) => onChangeValorTotal(e)}
                        />
                         {errors.find((x) => x.id === 'valorTotal') && (
                            <p className={classes.errors}>
                                {' '}
                                { errors.find(x => x.id === 'valorTotal')?.message || 'Campo Obrigatório !' }
                            </p>
                        )}
                    </FormControl>
                </Grid>
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            key={form.alocacaoCusto}
                            options={opcoesCusto}
                            disableClearable={true}
                            disabled={possuiEstoque}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, option) => {
                                option !== null
                                    ? option.value === 'PATRIMONIO'
                                        ? setform({
                                              ...form,
                                              alocacaoCusto: option.value,
                                              centroCusto: '',
                                              centroCustoId: '',
                                              safra: '',
                                              safraId: '',
                                              grupoSafra: '',
                                              grupoSafraId: '',
                                              localEstoque: '',
                                              localEstoqueId: '',
                                          })
                                        : option.value === 'CENTRO_DE_CUSTO'
                                        ? setform({
                                              ...form,
                                              alocacaoCusto: option.value,
                                              patrimonio: '',
                                              patrimonioId: '',
                                              safra: '',
                                              safraId: '',
                                              grupoSafra: '',
                                              grupoSafraId: '',
                                              localEstoque: '',
                                              localEstoqueId: '',
                                          })
                                        : setform({
                                              ...form,
                                              alocacaoCusto: option.value,
                                              centroCusto: '',
                                              centroCustoId: '',
                                              grupoSafra: '',
                                              grupoSafraId: '',
                                              patrimonio: '',
                                              patrimonioId: '',
                                          })
                                    : setform({
                                          ...form,
                                          alocacaoCusto: '',
                                          grupoSafra: '',
                                          grupoSafraId: '',
                                      })
                            }}
                            value={
                                form.alocacaoCusto
                                    ? opcoesCusto.find(
                                          (z) => z.value === form.alocacaoCusto
                                      )
                                    : ''
                            }
                            defaultValue={
                                form.alocacaoCusto
                                    ? opcoesCusto.find(
                                          (z) => z.value === form.alocacaoCusto
                                      )
                                    : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={'Alocação de custo'}
                                    placeholder="Selecione"
                                    name={'alocacaoCusto'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} display="flex">
                {form.alocacaoCusto === 'CENTRO_DE_CUSTO' && (
                    <Grid container item xs={6} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                key={form.centroCusto}
                                options={centroCusto}
                                getOptionLabel={(option) => option.descricao}
                                onChange={(event, option) => {
                                    if(option && form.alocacaoCusto === 'CENTRO_DE_CUSTO') {
                                        props.setCentroDeCusto(option)
                                    } else {
                                        props.setCentroDeCusto(null)
                                    }
                                    setErrors([])
                                    option !== null
                                        ? props.tipo === 'pagar'
                                            ? setform({
                                                  ...form,
                                                  centroCusto: option,
                                                  centroCustoId: option.id,
                                                  safra: '',
                                                  safraId: '',
                                                  grupoSafra: '',
                                                  grupoSafraId: '',
                                                  localEstoque: '',
                                                  localEstoqueId: '',
                                              })
                                            : setform({
                                                  ...form,
                                                  centroCusto: option,
                                                  centroCustoId: option.id,
                                                  safra: '',
                                                  safraId: '',
                                                  grupoSafra: '',
                                                  grupoSafraId: '',
                                              })
                                        : setform({
                                              ...form,
                                              centroCusto: '',
                                              centroCustoId: '',
                                              safra: '',
                                              safraId: '',
                                              grupoSafra: '',
                                              grupoSafraId: '',
                                          })
                                }}
                                value={form.centroCusto ? form.centroCusto : ''}
                                defaultValue={
                                    form.centroCusto ? form.centroCusto : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={
                                            props.tipo === 'receber'
                                                ? 'Centro de Custo*'
                                                : form.alocacaoCusto ===
                                                  'CENTRO_DE_CUSTO'
                                                ? 'Centro de Custo*'
                                                : 'Centro de Custo'
                                        }
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.find((x) => x.id === 'centroCustoId') && (
                                <p className={classes.errors}>
                                    {' '}
                                    Campo Obrigatório !
                                </p>
                            )}
                        </FormControl>
                    </Grid>
                )}
                {form.alocacaoCusto === 'PATRIMONIO' && (
                    <Grid container item xs={6} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                key={form.patrimonio}
                                options={patrimonio}
                                getOptionLabel={(option) => option.descricao}
                                onChange={(event, option) => {
                                    setErrors([])
                                    option !== null
                                        ? props.tipo === 'pagar'
                                            ? setform({
                                                  ...form,
                                                  patrimonio: option,
                                                  patrimonioId: option.id,
                                                  centroCusto: '',
                                                  centroCustoId: '',
                                                  safra: '',
                                                  safraId: '',
                                                  localEstoque: '',
                                                  localEstoqueId: '',
                                              })
                                            : setform({
                                                  ...form,
                                                  patrimonio: option,
                                                  patrimonioId: option.id,
                                                  centroCusto: '',
                                                  centroCustoId: '',
                                              })
                                        : setform({
                                              ...form,
                                              patrimonio: '',
                                              patrimonioId: '',
                                          })
                                }}
                                value={form.patrimonio ? form.patrimonio : ''}
                                defaultValue={
                                    form.patrimonio ? form.patrimonio : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={'Patrimônio*'}
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.find((x) => x.id === 'patrimonioId') && (
                                <p className={classes.errors}>
                                    {' '}
                                    Campo Obrigatório !
                                </p>
                            )}
                        </FormControl>
                    </Grid>
                )}
                {(form.alocacaoCusto === 'ESTOQUE' ||
                    props.tipo !== 'pagar') && (
                    <Grid container item xs={6} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="localEstoque"
                                options={locaisEstoque}
                                getOptionLabel={(option) => option.nome}
                                onChange={(event, option) => {
                                    setform({
                                        ...form,
                                        localEstoque: option,
                                        localEstoqueId: option?.id,
                                    })
                                    setErrors([])
                                }}
                                disabled={
                                    (form.centroCustoId || form.patrimonioId) &&
                                    props.tipo === 'pagar'
                                }
                                value={
                                    form.localEstoque ? form.localEstoque : ''
                                }
                                defaultValue={
                                    form.localEstoque ? form.localEstoque : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={'Local Estoque'}
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.find((x) => x.id === 'localEstoqueId') && (
                                <p className={classes.errors}>
                                    {' '}
                                    Campo Obrigatório !
                                </p>
                            )}
                        </FormControl>
                    </Grid>
                )}
                
                <Grid container item xs={6} display="flex">
                    <Grid container item xs={5} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="grupoSafra"
                                options={grupoSafras}
                                disabled={form.alocacaoCusto === 'CENTRO_DE_CUSTO' && !form.centroCustoId}
                                getOptionLabel={(option) => option.nome}
                                onChange={(event, option) => {
                                    if (option) {
                                        setform({
                                            ...form,
                                            grupoSafra: option,
                                            grupoSafraId: option.id,
                                            safra:'',
                                            safraId:'',
                                        })
                                        props.setGrupoSafraId(option.id)
                                        setErrors([])
                                    }
                                }}
                                value={form.grupoSafra ? form.grupoSafra : ''}
                                defaultValue={form.grupoSafra ? form.grupoSafra : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={
                                            props.centroDeCusto && props.centroDeCusto.tipo === 0 && 
                                            props.centroDeCusto.safras.length === 0 ? 
                                            'Safra':'Safra*'}
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.find((x) => x.id === 'grupoSafraId') && (
                                <p className={classes.errors}>
                                    {' '}
                                    Campo Obrigatório !
                                </p>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid container item xs={6} display="flex">
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                id="safra"
                                options={safras}
                                getOptionLabel={(option) => option.nome}
                                onChange={(event, option) => {
                                    if (option) {
                                        setform({
                                            ...form,
                                            safra: option,
                                            safraId: option.id,
                                        })
                                        setErrors([])
                                    }
                                }}
                                disabled={!form.grupoSafra
                                    //(form.centroCustoId || form.patrimonioId) &&
                                   // props.tipo === 'pagar'
                                }
                                value={form.safra ? form.safra : ''}
                                defaultValue={form.safra ? form.safra : ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={'Atividade'
                                           // props.tipo === 'receber' &&
                                            //form.produtoServico?.produtoAcabado
                                               // ? 'Safra*'
                                               // : 'Safra'
                                        }
                                        placeholder="Selecione"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.find((x) => x.id === 'safraId') && (
                                <p className={classes.errors}>
                                    {' '}
                                    Campo Obrigatório !
                                </p>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                
                <Grid container item xs={6} display="flex">
                    <FormControl className={classes.formControlCadastroRapido}>
                        <Autocomplete
                            id="contaGerencial"
                            options={contaGerencial}
                            getOptionLabel={(option) => option.descricao}
                            onChange={(event, option) => {
                                setform({
                                    ...form,
                                    contaGerencial: option,
                                    contaGerencialId: option?.id,
                                })
                                setErrors([])
                            }}
                            value={
                                form.contaGerencial ? form.contaGerencial : ''
                            }
                            defaultValue={
                                form.contaGerencial ? form.contaGerencial : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={'Conta Gerencial*'}
                                    placeholder="Selecione"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        {errors.find((x) => x.id === 'contaGerencialId') && (
                            <p className={classes.errors}>
                                {' '}
                                Campo Obrigatório !
                            </p>
                        )}
                    </FormControl>
                    <Grid container item xs={1} display="flex">
                        <Box ml={2} mt={2} style={{ marginLeft: 4 }}>
                            <Tooltip
                                title={`Cadastro nova conta gerencial padrão ${
                                    checarTipo ? 'compra' : 'venda'
                                }`}
                            >
                                <AddCircle
                                    color="primary"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        setOpenModalCadastroContaGerencialCompra(
                                            true
                                        )
                                    }
                                />
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs={12} display="flex"></Grid>

            <Grid
                xs={12}
                container
                item
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Button
                    onClick={props.close}
                    className={classes.button2}
                    variant="contained"
                    color="inherit"
                    size="small"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => submit()}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    id="insertProductServiceButton"
                >
                    Cadastrar
                </Button>
            </Grid>
            <ModalCadastroContaGerencial
                open={openModalCadastroContaGerencialCompra}
                close={() => setOpenModalCadastroContaGerencialCompra(false)}
                selecionarCadastrado={(dados) => {
                    setform({
                        ...form,
                        contaGerencial: {
                            descricao: dados.numero + ' - ' + dados.descricao,
                        },
                        contaGerencialId: dados?.id,
                    })
                    setErrors([])
                }}
                buscarContasGerenciaisPadrao={props.buscarContasGerenciais}
                tipo={checarTipo ? 1 : 0}
                tipoTitulo={checarTipo ? 'Compra' : 'Venda'}
            />
        </div>
    )
}
