import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    Container,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    makeStyles,
    Button,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import CentroCustoService from '../../services/CentroCustoService'
import ToastHelper from '../../utils/toastHelper'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MaterialTable from 'material-table'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Edit3 as Edit3Icon } from 'react-feather'
import DataHelper from '../../utils/dataHelpder'
import { CentroCustoRateioEsquema } from './CentroCusto.validate'
import moment from 'moment/moment.js'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#009688',
        },
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    addButton: {
        marginTop: 10,
    },
    textField: {},
}))

export const CentroCustoCadastroRateio = (props) => {
    const {
        match: { params },
    } = props
    const classes = useStyles()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda')).value

    const [opcoesCentroCusto, setOpcoesCentroCusto] = useState([])
    const [dados, setDados] = useState([])
    const [centroCustoSelecionado, setCentroCustoSelecionado] = useState('')

    const colunas = [
        {
            title: 'Centro de Custo',
            field: 'centroCusto',
            sorting: false,
            render: (rowData) => rowData.centroCusto.label,
            editComponent: (props) => {
                return (
                    <Autocomplete
                        name="centroCusto"
                        id="centroCusto"
                        inputRef={register}
                        options={opcoesCentroCusto}
                        value={props.value}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option) => option.value}
                        size="small"
                        onChange={(event, newValue) => {
                            var data = { ...props.rowData }
                            data.centroCusto = newValue
                            props.onRowDataChange(data)
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    name="centroCusto"
                                    id="centroCusto"
                                    inputRef={register}
                                    value={props.value}
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )
                        }}
                    />
                )
            },
        },
        {
            title: 'Percentual',
            field: 'percentual',
            sorting: false,
            render: (rowData) => (
                <TextField
                    type="number"
                    step="any"
                    disabled={true}
                    value={rowData.percentual}
                    inputProps={{
                        min: 0,
                        max: 100,
                        style: { textAlign: 'right' },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />
            ),
            editComponent: (props) => {
                return (
                    <TextField
                        type="number"
                        step="any"
                        value={props.value}
                        inputProps={{
                            min: 0,
                            max: 100,
                            style: { textAlign: 'right' },
                        }}
                        InputProps={{
                            defaultValue: props.value,
                            startAdornment: (
                                <InputAdornment position="start">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            var data = { ...props.rowData }
                            data.percentual = e.target.value
                            props.onRowDataChange(data)
                        }}
                        error={props.value === ''}
                        helperText={props.value === '' ? 'Inválido' : ''}
                    />
                )
            },
        },
        {
            title: 'Data Inicial',
            field: 'dataInicial',
            sorting: false,
            render: (rowData) => (
                <TextField
                    type="month"
                    step="any"
                    disabled={true}
                    value={rowData.dataInicial}
                />
            ),
            editComponent: (props) => {
                return (
                    <TextField
                        id="dataInicial"
                        name="dataInicial"
                        type="month"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={props.value}
                        onChange={(e) => {
                            var data = props.rowData
                            if (e.target.value !== '')
                                data.dataInicial = e.target.value
                            props.onRowDataChange(data)
                        }}
                    />
                )
            },
        },
        {
            title: 'Observação',
            field: 'observacao',
            sorting: false,
            render: (rowData) => (
                <TextField
                    type="month"
                    step="any"
                    disabled={true}
                    value={rowData.dataInicial}
                />
            ),
            editComponent: (props) => {
                return (
                    <TextField
                        id="observacao"
                        name="observacao"
                        type="text"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={props.value}
                        onChange={(e) => {
                            var data = props.rowData
                            if (e.target.value !== '')
                                data.observacao = e.target.value
                            props.onRowDataChange(data)
                        }}
                    />
                )
            },
        },
    ]

    const { register, handleSubmit, reset, errors } = useForm({
        defaultValues: {
            id: '',
            dataInicial: '',
            percentual: '',
            centroCustoSelecionado: '',
            observacao: '',
        },
        resolver: yupResolver(CentroCustoRateioEsquema),
    })

    const onSubmit = (parametros) => {
        parametros.idCentroCustoPrincipal = params.id
        parametros.id = ''
        parametros.centroCusto = centroCustoSelecionado

        var array = dados
        var podeAdicionar = true
        var dataInicial = ''
        var centroCusto = ''

        array.forEach((element) => {
            if (
                element.centroCusto.value === parametros.centroCusto.value &&
                element.dataInicial === parametros.dataInicial
            ) {
                podeAdicionar = false
                centroCusto = parametros.centroCusto.label
                dataInicial = DataHelper.transformStringDataMonthUsaToPtBr(
                    parametros.dataInicial
                )
            }
        })
        if (podeAdicionar) {
            array.push(parametros)
            array.sort((a, b) => (a.dataInicial < b.dataInicial ? 1 : -1))
            setDados(array)
            setCentroCustoSelecionado(null)
            reset()
        } else {
            ToastHelper.error(
                'Centro de custo ' +
                centroCusto +
                ' já está adicionado na data de ' +
                dataInicial
            )
        }
    }

    const enviarDados = async () => {
        var array = dados
        array.forEach((item) => {
            item.id = params.id
            item.percentual = parseInt(item.percentual)
        })

        var validado = false

        var centroCustoPorDatas = array.reduce(function(obj, item) {
            obj[item.dataInicial] = obj[item.dataInicial] || []
            obj[item.dataInicial].push(item)
            return obj
        }, {})

        for (var data in centroCustoPorDatas) {
            let soma = 0
            centroCustoPorDatas[data].forEach((centroCusto) => {
                soma += centroCusto.percentual
            })
            if (soma !== 100) {
                validado = false
                ToastHelper.error(
                    'Rateio diferente de 100%, no período de ' +
                    DataHelper.transformStringDataMonthUsaToPtBr(data)
                )
            } else {
                validado = true
            }
        }

        if (validado) {
            array.forEach((element) => {
                var dataTexto = element.dataInicial.split('-')
                var dataFormatada = new Date(dataTexto[0], dataTexto[1], 1)
                element.dataInicial = moment(
                    dataFormatada,
                    'DD/MM/YYYY'
                ).format('YYYY/MM/DD')
            })
            await CentroCustoService.cadastrarCentrosCustoRateio(array)
            props.history.goBack()
            return ToastHelper.success('Cadastro efetuado com sucesso!')
        }
    }

    useEffect(() => {
        if (params.id) {
            buscarCentroCustoRateiosPorId()
            buscarCentrosDeCusto()
            buscarCentroDeCustoPorId()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [centroCustoNome, setCentroCustoNome] = useState("")

    const buscarCentroDeCustoPorId = async () => {
        const { data } = await CentroCustoService.buscarPorId(params.id);

        setCentroCustoNome(data.descricao);
    }

    const buscarCentroCustoRateiosPorId = async () => {
        const { data } =
            await CentroCustoService.buscarCentroCustoRateadosPorId(params.id)
        if (data.length > 0) {
            data.map((item) => {
                item.centroCusto = {
                    value: item.centroCustoId,
                    label: item.centroCusto,
                }

                item.dataInicial = moment(
                    new Date(item.dataInicial),
                    'DD/MM/YYYY'
                ).format('YYYY-MM')
                return item
            })
            setDados(data)
        }
    }

    const buscarCentrosDeCusto = async () => {
        const { data } = await CentroCustoService.buscarCentrosCustoRateio(
            FazendaId,
            params.id
        )
        setOpcoesCentroCusto(data)
    }

    const deletarItem = async (params) => {
        let newDados = dados
        await newDados.splice(params.tableData.id, 1)
        await setDados([])
        await setDados(newDados)
        ToastHelper.success('Operação realizada com sucesso!')
    }

    return (
        <Page className={classes.root} title="Cadastro de Centro de Custo">
            <Container maxWidth={'xl'}>
                <Titulo titulo={centroCustoNome + ' - Cadastro de Rateio'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item md={12} display="flex">
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="centroCustoSelecionado"
                                            id="centroCustoSelecionado"
                                            inputRef={register()}
                                            options={opcoesCentroCusto}
                                            value={centroCustoSelecionado}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setCentroCustoSelecionado(
                                                    newValue
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="centroCustoSelecionado"
                                                    id="centroCustoSelecionado"
                                                    inputRef={register({
                                                        required: true,
                                                    })}
                                                    variant="standard"
                                                    label="Centro de Custo*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.centroCustoSelecionado
                                                ? errors.centroCustoSelecionado
                                                    .message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            step="any"
                                            type="number"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            label="Percentual*"
                                            className={classes.textField}
                                            inputRef={register}
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                                style: { textAlign: 'right' },
                                            }}
                                            name="percentual"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="end">
                                                        %
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.percentual
                                                ? errors.percentual.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>

                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="dataInicial"
                                            name="dataInicial"
                                            label="Data Inicial*"
                                            type="month"
                                            className={classes.textField}
                                            inputRef={register}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.dataInicial
                                                ? errors.dataInicial.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={1} display="flex">
                                    <Fab
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        className={classes.addButton}
                                        aria-label="add"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            title={'Rateios'}
                            columns={colunas}
                            data={dados}
                            options={{
                                pageSize: 500,
                                paging: false,
                                padding: 'dense',
                                rowStyle: {
                                    fontSize: 14,
                                },
                                search: false,
                                actionsColumnIndex: -1,
                                emptyRowsWhenPaging: false,
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            var array = dados
                                            array[oldData.tableData.id] =
                                                newData
                                            setDados(array)
                                            resolve()
                                        }, 1000)
                                    }),
                            }}
                            actions={[
                                {
                                    icon: DeleteIcon,
                                    tooltip: 'Excluir',
                                    onClick: (event, rowData) =>
                                        deletarItem(rowData),
                                },
                            ]}
                            icons={{ Edit: Edit3Icon }}
                            localization={{
                                header: {
                                    actions: 'Ações',
                                },
                                body: {
                                    emptyDataSourceMessage:
                                        'Nenhum registro para exibir',
                                    addTooltip: 'Adicionar',
                                    deleteTooltip: 'Excluir',
                                    editTooltip: 'Edição rápida',
                                    editRow: {
                                        deleteText:
                                            'Deseja excluir essa linha?',
                                        cancelTooltip: 'Cancelar',
                                        saveTooltip: 'Confirmar',
                                    },
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: 'Pesquisar',
                                },
                            }}
                        />
                    </MuiThemeProvider>
                    <Grid
                        container
                        item
                        xs={12}
                        display="flex"
                        className={classes.card}
                    >
                        <Grid
                            xs={6}
                            container
                            item
                            direction="row"
                            alignItems="center"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => props.history.goBack()}
                            >
                                Voltar
                            </Button>
                        </Grid>
                        <Grid
                            xs={6}
                            container
                            item
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={enviarDados}
                                size="small"
                            >
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Page>
    )
}
