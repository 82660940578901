import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'
import CamposParcelaListagem from './camposParcelaListagem'
import CamposParcelaService from './../../services/CamposParcelaService'
import ContasGerencialService from '../../services/ContaGerencialService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const CamposParcela = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.tipoEstoque.filtroListagem)
    )

    const [camposParcelas, setCamposParcelas] = useState([])
    const [contasGerenciais, setContasGerenciais] = useState([])

    useEffect(() => {
        const getCamposParcelas = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'Nome'
            novoFiltro.tamanhoPagina = 100
            buscarCamposParcelas(novoFiltro)
        }
        getCamposParcelas()
        buscarContasGerenciais()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CAMPOS_PARCELA',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarCamposParcelas(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CAMPOS_PARCELA',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/CamposParcela/cadastro`,
        })
    }

    const deletar = async (item) => {
        CamposParcelaService.deletar(item.id).then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Campo Parcela excluído com sucesso!')
                let novoFiltro = { ...filtro }
                buscarCamposParcelas(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const editar = (id) => {
        history.push(`/app/CamposParcela/editar/${id}`)
    }

    const buscarCamposParcelas = async (filtro) => {
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        let params = await ObjectHelper.createParams(filtroPesquisa)
        setLoading(true)
        CamposParcelaService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = fazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                setCamposParcelas(response.data.itens)
                setLoading(false)
            } else {
                return ToastHelper.error(response.statusText)
            }
        })
    }

    const ativarDesativar = async (id) => {
        setLoading(true)
        CamposParcelaService.ativarDesativar(id)
            .then((response) => {
                if (response.status === 200) {
                    let novoFiltro = { ...filtro }
                    buscarCamposParcelas(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarCamposParcelas(novoFiltro)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarCamposParcelas(novoFiltro)
    }

    const buscarContasGerenciais = async () => {
        let filtro = []
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        filtro.semPadroes = true
        let params = await ObjectHelper.createParams(filtro)
        ContasGerencialService.buscarTudo([params]).then((response) => {
            if (response.status === 200) {
                let data = ObjectHelper.getValuesDescricaoLabelParseToSelect(
                    response.data.itens
                )
                setContasGerenciais(data)
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    return (
        <Page className={classes.root} title="Campos da Parcela">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Campos da Parcela" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    temFiltroAvancado
                    filtroNome
                    filtroContasGerenciais={contasGerenciais}
                    filtroAplicadoContasPagarReceber
                    filtroAcrescimoDesconto
                />
                <Box mt={3}>
                    <CamposParcelaListagem
                        data={camposParcelas}
                        pageSize={filtro.tamanhoPagina}
                        filtro={filtro}
                        isLoading={loading}
                        deletar={deletar}
                        editar={editar}
                        ativarDesativar={ativarDesativar}
                        mudaOrdenacao={mudaOrdenacao}
                        onChangePage={(page) => onChangePage(page)}
                    />
                </Box>
            </Container>
        </Page>
    )
}
