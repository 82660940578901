import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../../components/Page'
import Titulo from '../../../components/Titulo/index'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../../components/FooterBottons/FooterBottons'
import {
    Container,
    Card,
    CardContent,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import CentroCustoService from '../../../services/CentroCustoService'
import ToastHelper from '../../../utils/toastHelper'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import GerenciadorDeColaboradoresVinculados from './GerenciadorDeCalaboradoresVinculados'
import ColaboradorService from '../../../services/ColaboradorService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
        border: '0,75px solid #2E7D32'
    },
}))

export const AssociarColaboradores = (props) => {
    const {
        match: { params },
    } = props
    let history = useHistory()
    const classes = useStyles()

    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [centroDeCusto, setCentroDeCusto] = useState({
        value: '',
        label: '',
    })

    const [colaboradoresVinculados, setColaboradoresVinculados] = useState([])
    const { handleSubmit } = useForm({})

    const [opcoesColaboradores, setOpcoesColaboradores] = useState([]);
    const [render, setRender] = useState(false);

    useEffect(() => {
        async function init(){

            await buscarColaboradores()
            await buscarCentroCustoPorId()
            await setRender(true)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarColaboradores = async () => {

        await ColaboradorService.buscarPorFazendaId(params.id, FazendaId).then(async (response) => {
            if (response.status === 200) {
                await setOpcoesColaboradores(response.data.pessoas)
                await setColaboradoresVinculados(response.data.pessoasVinculadas)
            } else ToastHelper.error(response.statusText)
        })
    }


    const buscarCentroCustoPorId = () => {
        CentroCustoService.buscarPorId(params.id).then((response) => {
            if (response.status === 200) {
                setCentroDeCusto(response.data)
            } else ToastHelper.error(response.statusText)
        })
    }

    const onSubmit = async (dados) => {
        await ColaboradorService.realizarVinculoComCentroDeCusto({centroCustoId : params.id, fazendaId: FazendaId, vinculados: colaboradoresVinculados}).then(async (response) => {
            if (response.status === 200) {
                await setOpcoesColaboradores(response.data.pessoas)
                await setColaboradoresVinculados(response.data.pessoasVinculadas)
                ToastHelper.success('Colaboradores vinculados com sucesso.')
                history.goBack()
            } else ToastHelper.error(response.statusText)
        })
    }

    return (
        <Page
            className={classes.root}
            titulo={'Associar Colaboradores ao centro de custo'}
        >
            <Container maxWidth={'xl'}>
                <Titulo
                    titulo={'Associar Colaboradores ao centro de custo'}
                />

                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item md={12} display="flex">
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <h3
                                        style={{
                                            marginBottom: '15px',
                                            marginTop: '15px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Dados do centro de custo
                                    </h3>
                                </Grid>
                                <Grid row md={12} display="flex">
                                    <Divider className={classes.divider} />
                                </Grid>

                                <Grid container item md={12} display="flex" style={{marginLeft: '15px'}}>
                                    <Grid container item md={6} display="flex" >
                                        <Grid container item md={6} display="flex">
                                            <Typography style={{fontWeight: 600}} variant="subtitle1">Código:</Typography>
                                            <Typography style={{marginLeft: "10px"}} variant="subtitle1">{centroDeCusto?.codigo}</Typography>
                                        </Grid>
                                        <Grid container item md={6} display="flex">
                                            <Typography style={{fontWeight: 600}} variant="subtitle1">Descrição:</Typography>
                                            <Typography style={{marginLeft: "10px"}} variant="subtitle1">{centroDeCusto?.descricao}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item md={6} display="flex" >

                                        <Grid container item md={6} display="flex">
                                            <Typography style={{fontWeight: 600}} variant="subtitle1">Tipo:</Typography>
                                            <Typography style={{marginLeft: "10px"}} variant="subtitle1">{centroDeCusto?.tipoNome}</Typography>
                                        </Grid>
                                        <Grid container item md={6} display="flex">
                                            <Typography style={{fontWeight: 600}} variant="subtitle1">Natureza:</Typography>
                                            <Typography style={{marginLeft: "10px"}} variant="subtitle1">{centroDeCusto?.natureza}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid row md={12} display="flex">
                                    <Divider className={classes.divider} />
                                </Grid>
                            </Grid>
                            {render &&

                                <Grid container item md={12} display="flex">
                                    <GerenciadorDeColaboradoresVinculados
                                        opcoesColaboradores={
                                            opcoesColaboradores
                                        }
                                        colaboradoresVinculados={
                                            colaboradoresVinculados
                                        }
                                        setColaboradoresVinculados={
                                            setColaboradoresVinculados
                                        }
                                    />
                                </Grid>
                            }
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
