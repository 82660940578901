import { ContaGerencial } from '../pages/contaGerencial';
import { ContaGerencialCadastro } from '../pages/contaGerencial/ContaGerencialCadastro';

export const contaGerencialRoute = [
    {
        path: '/app/contaGerencial',
        key: 'APP_CONTA_GERENCIAL_LISTAGEM',
        exact: true,
        component: ContaGerencial,
    },
    {
        path: '/app/contaGerencial/cadastro',
        key: 'APP_CONTA_GERENCIAL_CADASTRO',
        exact: true,
        component: ContaGerencialCadastro,
    },
    {
        path: '/app/contaGerencial/adicionaSubConta/:idContaPai([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CONTA_GERENCIAL_ADICIONA_SUB',
        exact: true,
        component: ContaGerencialCadastro,
    },
    {
        path: '/app/contaGerencial/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CONTA_GERENCIAL_EDITAR',
        exact: true,
        component: ContaGerencialCadastro,
    },
];
