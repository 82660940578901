import * as Yup from 'yup';
import ObjectHelper from 'utils/objetcHelper';

export const esquema = Yup.object().shape({
    adiantamentoDe: Yup.object().required('Campo obrigatório').nullable(),
    fornecedorCliente: Yup.object().required('Campo obrigatório').nullable(),
    dataLancamento: Yup.string().required('Campo obrigatório').nullable(),
    descricao: Yup.string().nullable().test("length", "Máximo 1000 caracteres", (text) => text.length <= 1000),
    dataVencimento: Yup.string().required('Campo obrigatório'),
    valor: Yup.string().required('Campo obrigatório').test('positive', "O valor deve ser positivo", (value) =>{
        let valueFormatted = typeof value === 'string'
            ? ObjectHelper.convertCurrencyStringToFloat(value)
            : value
        if(valueFormatted > 0) return true
        else return false;
    }),
    situacao: Yup.string(),
    contaBancaria: Yup.object().nullable(),
    formaPagamento: Yup.object().nullable(),
    valorPago: Yup.string().test('positive', "O valor deve ser positivo", (value) =>{
        if(value) {
            let valueFormatted = typeof value === 'string'
            ? ObjectHelper.convertCurrencyStringToFloat(value)
            : value
            if(valueFormatted > 0) return true
            else return false;
        }else return true;
    }).nullable(),
    documentoPago: Yup.string().nullable(),
    dataPagamento: Yup.string().nullable(),
    dataCompensacao: Yup.string().nullable(),
    cheque: Yup.string().nullable(),
    saldo: Yup.string().test('positive', "O valor deve ser positivo", (value) =>{
        if(value) {
            let valueFormatted = typeof value === 'string'
            ? ObjectHelper.convertCurrencyStringToFloat(value)
            : value
            if(valueFormatted > 0) return true
            else return false;
        }else return true;
        
    }).nullable(),
});
