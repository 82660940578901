import { SET_FILTRO_LISTAGEM_CAIXA } from '../actions/caixa';

export const storageCaixa = 'caixa';

const initialState = {
    filtroListagem: {},
};

export const caixa = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CAIXA:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
