import { ProdutosServicos } from '../pages/produtosServicos'
import { ProdutosServicosCadastro } from '../pages/produtosServicos/ProdutosServicosCadastro'

export const produtosServicosRoute = [
    {
        path: '/app/produtosServicos',
        key: 'APP_PRODUTOS_SERVICOS_LISTAGEM',
        exact: true,
        component: ProdutosServicos,
    },
    {
        path: '/app/produtosServicos/cadastro',
        key: 'APP_PRODUTOS_SERVICOS_CADASTRO',
        exact: true,
        component: ProdutosServicosCadastro,
    },
    {
        path: '/app/produtosServicos/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_PRODUTOS_SERVICOS_EDITAR',
        exact: true,
        component: ProdutosServicosCadastro,
    },
    {
        path: '/app/produtosServicos/cadastro/:modo(aba)',
        key: 'APP_PRODUTOS_SERVICOS_CADASTRO_MODO',
        exact: true,
        component: ProdutosServicosCadastro,
    },
]
