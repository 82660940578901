import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import { MTablePagination } from 'material-table'
import Grid from '@material-ui/core/Grid'
import { Edit3 as Edit3Icon } from 'react-feather'
import AddIcon from '@material-ui/icons/Add'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import FileCopy from '@material-ui/icons/FileCopy'
import Table2 from 'components/Table2/Table2'
import { Container } from '@material-ui/core'
import GerarContaReceberIcon from '@material-ui/icons/CallMissedOutgoing'
import VisibilityIcon from '@material-ui/icons/Visibility'

export default function GestaoContratoListagem(props) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#2E7D32',
            },
        },
    })

    const colunas = [
        { title: 'Nº do Contrato', field: 'numeroPedido', editable: 'never' },
        { title: 'Data', field: 'dataPedido', editable: 'never' },
        { title: 'Cliente / Fornecedor', field: 'cliente', editable: 'never' },
        { title: 'Valor', field: 'valor', editable: 'never' },
    ]

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    title={'Listagem de Contratos'}
                    columns={colunas}
                    data={props.data}
                    options={{
                        emptyRowsWhenPaging: props.renderizarLinhasEmBranco
                            ? props.renderizarLinhasEmBranco
                            : false,
                        pageSize: props.pageSize ? props.pageSize : 100,
                        paging: true,
                        padding: 'dense',
                        rowStyle: {
                            fontSize: 14,
                        },
                        search: false,
                        actionsColumnIndex: -1,
                        selection: props.options
                            ? props.options.selection
                            : false,
                    }}
                    isLoading={props.isLoading}
                    detailPanel={(rowData) => {
                        return (
                            <Container maxWidth="fixed">
                                <Table2
                                    titulo="Produtos"
                                    emptyRowsWhenPaging={false}
                                    colunas={[
                                        {
                                            title: 'Produtos',
                                            field: 'produto',
                                        },
                                        {
                                            title: 'Qtd.',
                                            field: 'quantidade',
                                        },
                                        {
                                            title: 'Valor Unitário',
                                            field: 'valorUnitario',
                                        },
                                        {
                                            title: 'Valor Total',
                                            field: 'valorTotal',
                                        },
                                        {
                                            title: 'Entrega Prevista',
                                            field: 'entregaPrevista',
                                        },
                                    ]}
                                    dados={rowData.produtos}
                                />
                            </Container>
                        )
                    }}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    components={{
                        Pagination: (paginat) => {
                            const { classes, headerIsHidden, ...other } =
                                paginat
                            return (
                                <>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        justify="flex-end"
                                    >
                                        <MTablePagination
                                            {...other}
                                            onChangePage={(e, page) =>
                                                props.onChangePage(page + 1)
                                            }
                                            page={props.filtro.pagina - 1}
                                            count={props.filtro.totalItens}
                                            rowsPerPage={props.pageSize}
                                            localization={{
                                                labelRowsSelect: 'recursos',
                                                labelDisplayedRows:
                                                    '{from}-{to} de {count}',
                                            }}
                                        />
                                    </Grid>
                                </>
                            )
                        },
                    }}
                    editable={{
                        onRowDelete: (item) => props.deletar(item),
                    }}
                    actions={[
                        {
                            icon: VisibilityIcon,
                            tooltip: 'Visualizar',
                            onClick: (event, rowData) => props.visualizar(rowData),
                        },
                        {
                            icon: EditIcon,
                            tooltip: 'Edição',
                            onClick: (event, rowData) => props.editar(rowData),
                        },
                        {
                            icon: FileCopy,
                            tooltip: 'Duplicar',
                            onClick: (event, rowData) =>
                                props.duplicar(rowData.id),
                        },
                        {
                            icon: GerarContaReceberIcon,
                            tooltip: 'Gerar Conta a Receber',
                            onClick: (event, rowData) =>
                                props.gerarContaReceber(rowData.id),
                        },
                    ]}
                    onOrderChange={(numeroColuna, orientacao) =>
                        props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                    }
                    onSelectionChange={(rows) =>
                        props.mudaContasSelecionadas(rows)
                    }
                    icons={{
                        Edit: Edit3Icon,
                        Add: (props) => {
                            return (
                                <AddIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        borderRadius: 100,
                                    }}
                                />
                            )
                        },
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro para exibir',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Edição rápida',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            nRowsSelected: '{0} contas(s) selecionadas',
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar ',
                        },
                    }}
                />
            </MuiThemeProvider>
        </>
    )
}
