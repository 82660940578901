import { SET_FILTRO_LISTAGEM_TIPO_ESTOQUE } from '../actions/tipoEstoque';

export const storageTipoEstoque = 'tpoEstoque';

const initialState = {
    filtroListagem: {},
};

export const tipoEstoque = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_TIPO_ESTOQUE:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
