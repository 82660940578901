import service from './service'

export default {
    buscarPorId(id) {
        return service.get(`/ProdutosServicos/${id}`)
    },
    buscarTudo(param) {
        return service.get(`/ProdutosServicos${param}`)
    },
    cadastrar(param) {
        return service.post(`/ProdutosServicos`, param)
    },
    atualizar(param) {
        return service.put(`/ProdutosServicos`, param)
    },
    ativarDesativar(id) {
        return service.put(`/ProdutosServicos/AtivarDesativar`, id)
    },
    deletar(id) {
        return service.delete(`/ProdutosServicos/${id}`)
    },
    comControleEstoque(fazendaId) {
        return service.get(
            `/ProdutosServicos/ProdutosComControleEstoque/${fazendaId}`
        )
    },
    buscarProdutosTecnicoRelacionado(fazendaId) {
        return service.get(
            `/ProdutosServicos/BuscarProdutosTecnicoRelacionado/${fazendaId}`
        )
    },
    isValidCode(fazendaId, code) {
        return service.get(
            `/ProdutosServicos/codigoValido/${fazendaId}/${code}`
        )
    }
}
