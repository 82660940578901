import React from 'react'
import './Main.css'
import { Router } from 'react-router-dom'
import { RenderRoutes } from './routes/RenderRoutes'
import { ThemeProvider } from '@material-ui/core'
import theme from './shared/mainThemeCss/index'
import { routes } from './routes'
import ToastHelper from '../src/utils/toastHelper'
import MainLayout from 'layouts/MainLayout'
import { createBrowserHistory } from 'history'



function Main() {
    const history = createBrowserHistory()
    ToastHelper.create()

    return (
        <ThemeProvider theme={theme} >

            <Router history={history}>
                <MainLayout>
                    <RenderRoutes routes={routes} />
                </MainLayout>
            </Router>
        </ThemeProvider>
    )
}

export default Main
