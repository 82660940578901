import React from 'react'
import Table from '../../components/Table'
import EditCompleteIcon from '@material-ui/icons/EditOutlined'
import IconeAtivo from '@material-ui/icons/CheckCircle'
import IconeInativo from '@material-ui/icons/Cancel'
import { colors } from '@material-ui/core'
import LigaDesliga from '@material-ui/icons/PowerSettingsNew'

export default function CamposParcelaListagem(props) {
    const colunas = [
        { title: 'Nome', field: 'nome', editable: 'never' },
        {
            title: 'Conta Gerencial',
            field: 'contaGerencial',
            editable: 'never',
            render: (rowData) =>
                rowData.contaGerencial
                    ? rowData.contaGerencial.numero +
                      ' - ' +
                      rowData.contaGerencial.descricao
                    : '',
        },

        {
            title: 'Aplicado à Contas a Pagar',
            field: 'aplicadoContasPagar',
            editable: 'never',
            render: (rowData) =>
                rowData.aplicadoContasPagar ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
        {
            title: 'Aplicado à Contas a Receber',
            field: 'aplicadoContasReceber',
            editable: 'never',
            render: (rowData) =>
                rowData.aplicadoContasReceber ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
        {
            title: 'Tipo',
            field: 'tipo',
            editable: 'never',
        },
        {
            title: 'Ativo',
            field: 'ativo',
            editable: 'never',
            render: (rowData) =>
                rowData.ativo ? (
                    <IconeAtivo style={{ color: colors.green[800] }} />
                ) : (
                    <IconeInativo style={{ color: colors.red[500] }} />
                ),
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                pageSize={props.pageSize}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                emptyRowsWhenPaging={false}
                actions={[
                    {
                        icon: LigaDesliga,
                        tooltip: 'Ativar / Desativar',
                        onClick: (event, rowData) =>
                            props.ativarDesativar(rowData.id),
                    },
                    {
                        icon: EditCompleteIcon,
                        tooltip: 'Edição',
                        onClick: (event, rowData) => props.editar(rowData.id),
                    },
                ]}
                editarCelula={props.editarCelula}
                editable={{
                    onRowDelete: (item) => props.deletar(item),
                }}
            />
        </>
    )
}
