import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { TipoPagamentoRecebimentoEsquema } from './TipoPagamentoRecebimento.validate'
import TipoPagamentoRecebimentoService from '../../services/TipoPagamentoRecebimentoService'
import ToastHelper from '../../utils/toastHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
}))

export const TipoPagamentoRecebimentoCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const {
        match: { params },
    } = props

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            nome: '',
            codigo: '',
        },
        resolver: yupResolver(TipoPagamentoRecebimentoEsquema),
    })

    useEffect(() => {
        async function init() {
            if (params.id) buscarParaEdicao(params.id)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarParaEdicao = (caixaId) => {
        TipoPagamentoRecebimentoService.buscarPorId(caixaId).then(
            (response) => {
                if (response.status === 200) {
                    setValue('id', response.data.id)
                    setValue('nome', response.data.nome)
                    setValue('codigo', response.data.codigo)
                } else ToastHelper.error(response.statusText)
            }
        )
    }

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = fazendaId

        if (params.id) {
            newData.id = params.id
            await TipoPagamentoRecebimentoService.atualizar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Forma pagamento/recebimento atualizado'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    } else if (error.request) {
                        // Solicitação feita sem resposta do servidor
                    } else {
                        // Erro na solicitação
                    }
                })
        } else {
            await TipoPagamentoRecebimentoService.cadastrar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Nova forma de pagamento/recebimento cadastrado com sucesso'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    } else if (error.request) {
                        // Solicitação feita sem resposta do servidor
                    } else {
                        // Erro na solicitação
                    }
                })
        }
    }

    return (
        <Page className={classes.root} title="Forma de Pagamento/Recebimento">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de forma de pagamento/recebimento'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            label="Nome*"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="nome"
                                            name="nome"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.nome
                                                ? errors.nome.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            label="Código*"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="codigo"
                                            name="codigo"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.codigo
                                                ? errors.codigo.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
