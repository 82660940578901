import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Container, makeStyles } from '@material-ui/core'
import Titulo from '../../components/Titulo/index'
import Page from '../../components/Page'
import ToolbarTipoPatrimonio from './ToolBarTipoPatrimonio'
import TipoPatrimonioListagem from './TipoPatrimonioListagem'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import TiposPatrimonioService from 'services/TiposPatrimonioService'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const TipoPatrimonio = (props) => {
     const history = useHistory()
     const classes = useStyles()
     const dispatch = useDispatch()

     const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

     const [loading, setLoading] = useState(false)

    const [patrimonio, setPatrimonio] = useState([])
    const [filtro, setFiltro] = useState({})

    useEffect(() => {
        const getTiposPatrimonios = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'descricao'
            novoFiltro.tamanhoPagina = 100
            BuscarTiposPatrimonios(novoFiltro)
        }
        getTiposPatrimonios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_TIPO_PATRIMONIO',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        BuscarTiposPatrimonios(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_TIPO_PATRIMONIO',
            filtroListagem: filtro,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        BuscarTiposPatrimonios(novoFiltro)
    }

    const BuscarTiposPatrimonios = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        let params = await ObjectHelper.createParams(filtroParaPesquisa)

        TiposPatrimonioService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    newFiltro.ordem = filtro.ordem

                    alteraFiltro(newFiltro)
                    setPatrimonio(response.data.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar tipos de patrimônio')
                setLoading(false)
            })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/tipoPatrimonio/cadastro`,
        })
    }

    const visualizar = async (item) => {
        history.push(`/app/tipoPatrimonio/visualizar/${item.id}`)
    }

    const editar = (itemId) => {
        history.push(`/app/tipoPatrimonio/editar/${itemId}`)
    }

    const ativarDesativar = async (itemId) => {
        setLoading(true)
        await TiposPatrimonioService.ativarDesativar({ id: itemId }).then(
            (response) => {
                if (response.status === 200) {
                    let novoFiltro = { ...filtro }
                    BuscarTiposPatrimonios(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const deletar = async (item) => {
        await TiposPatrimonioService.deletar(item.id)
        .then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Cadastro excluído com sucesso!')
                let novoFiltro = { ...filtro }
                BuscarTiposPatrimonios(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
        .catch((error) =>{
            ToastHelper.error(error.response.data)
            setLoading(false)
        })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        if (colunas[numeroColuna]?.field === 'nomeTipoPatrimonio') {
            novoFiltro.ordem = 'TipoPatrimonio.Nome'
        } else if (colunas[numeroColuna]?.field === 'unidadeSigla') {
            novoFiltro.ordem = 'UnidadeMedida.Sigla'
        } else {
            novoFiltro.ordem = colunas[numeroColuna]?.field
        }
        alteraFiltro(novoFiltro)
        BuscarTiposPatrimonios(novoFiltro)
    }

    return (
        <Page className={classes.root} title="Tipos de Patrimônios">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Tipos de Patrimônios" />
                <ToolbarTipoPatrimonio
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroAtivo
                />
                <Box mt={3}>
                    <TipoPatrimonioListagem
                        data={patrimonio}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        ativarDesativar={ativarDesativar}
                        deletar={deletar}
                        visualizar={visualizar}
                        mudaOrdenacao={mudaOrdenacao}
                    />
                </Box>
            </Container>
        </Page>
    )
}
