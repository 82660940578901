import service from './service'

export default {
    buscarSafras(fazendaId) {
        return service.get(`/Safra/${fazendaId}`)
    },
    buscarSafrasPorGrupoSafra(fazendaId,grupoSafraId) {
        return service.get(`/Safra/BuscarPorGrupoSafra/${fazendaId}/${grupoSafraId}`)
    },
}

