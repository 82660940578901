import { SET_FILTRO_LISTAGEM_EXTRATO_BANCARIO } from '../actions/extratoBancario';

export const storageExtratoBancario = 'extratoBancario';

const initialState = {
    filtroListagem: {},
};

export const extratoBancario = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_EXTRATO_BANCARIO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
