import React from 'react'
import { TextField, makeStyles, Box, Tooltip, Grid } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minWidth: '100%',
    },
    autocomplete: {
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    textField: {
        width: '100%',
    },
    addCircleButton: {
        cursor: 'pointer',
    },
    errors: {
        color: 'red',
    },
}))

export default function SelectWithButton(props) {
    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Autocomplete
                {...props}
                className={classes.autocomplete}
                renderInput={(params) => (
                    <Box className={classes.container}>
                        {props.disabled ? null : <Box ml={1} mt={2}>
                            <Tooltip title={props.tooltip}>
                                <AddCircle
                                    color="primary"
                                    className={classes.addCircleButton}
                                    onClick={() => props.cadastro()}
                                />
                            </Tooltip>
                        </Box>}
                        <TextField
                            {...params}
                            className={classes.textField}
                            variant="standard"
                            label={props.label}
                            placeholder={props.placeholder}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                )}
            />
            {props.error && (
                <p className={classes.errors}> {props.errorMessage}</p>
            )}
        </Grid>
    )
}
