import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
    ContaBancariaEsquemaTipoBanco,
    ContaBancariaEsquemaTipoCaixa,
    ContaBancariaEsquemaTipoCartaoCredito,
    ContaBancariaEsquemaTipoInvestimento
} from './ContaBancaria.validate'
import ContaBancariaService from '../../services/ContaBancariaService'
import ToastHelper from '../../utils/toastHelper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import BancoService from '../../services/BancoService'
import ObjectHelper from '../../utils/objetcHelper'
import { Box } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
}))

export const ContaBancariaCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()

    const [desconsideraSaldoInicial, setDesconsideraSaldoInicial] =
        useState(false)
    const tipoBanco = '0'
    const tipoCaixa = '1'
    const tipoInvestimento = '3'
    const [contaPadrao, setContaPadrao] = useState(false)
    const [bancos, setBancos] = useState([])
    const [saldoAtual, setSaldoAtual] = useState(0)
    const [titulo, setTitulo] = useState('Cadastro de Contas')
    const {
        match: { params },
    } = props
    const [tipo, setTipo] = useState(tipoBanco)
    const { handleSubmit, setValue, errors, getValues, control, watch, clearErrors } =
        useForm({
            defaultValues: {
                id: '',
                codigoSispag: '',
                descricao: '',
                banco: '',
                agencia: '',
                conta: '',
                saldoInicial: '',
                saldoAtual: '',
                dataSaldoInicial: '',
                limite: '',
                ativo: false,
                desconsiderarSaldoInicial: false,
                contaPadrao: false,
                tipo: '0',
                dataVencimento: '',
            },
            resolver: yupResolver(
                tipo === tipoBanco
                    ? ContaBancariaEsquemaTipoBanco
                    : tipo === tipoCaixa
                    ? ContaBancariaEsquemaTipoCaixa
                    : tipo === tipoInvestimento ? ContaBancariaEsquemaTipoInvestimento : ContaBancariaEsquemaTipoCartaoCredito
            ),
        })

    useEffect(() => {
        async function init() {
            const {
                match: { params },
            } = props
            await buscarBancos()
            if (params.id) {
                await buscarParaEdicao(params.id)
                await setTitulo('Editar Conta')
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo])

    const buscarBancos = () => {
        BancoService.buscarTodos().then((response) => {
            let newBancos = ObjectHelper.getValuesParseToSelect(
                response.data.itens
            )
            setBancos(newBancos)
        })
    }

    const buscarParaEdicao = (contaBancariaId) => {
        ContaBancariaService.buscarPorId(contaBancariaId).then((response) => {
            if (response.status === 200) {
                setValue('tipo', response.data.tipo.toString())
                setValue('id', response.data.id)
                setValue('codigoSispag', response.data.codigoSispag)
                setValue('descricao', response.data.descricao)
                setValue('titularConta', response.data.titularConta)
                setValue('chavePix', response.data.chavePix)
                setValue('banco', response.data.bancoSelecionado.value === null ? null : response.data.bancoSelecionado)
                setValue('agencia', response.data.agencia)

                setValue('saldoInicial', response.data.saldoInicial)
                setValue('dataSaldoInicial', response.data.dataSaldoInicial)
                setSaldoAtual(response.data.saldoAtual)
                setValue('limite', response.data.limite)
                setValue('contaPadrao', response.data.contaPadrao)
                setValue(
                    'desconsideraSaldoInicial',
                    response.data.desconsiderarSaldoInicial
                )
                setValue('conta', response.data.conta)

                setValue('dataVencimento', response.data.dataVencimento)
                setDesconsideraSaldoInicial(
                    response.data.desconsideraSaldoInicial
                        ? response.data.desconsideraSaldoInicial
                        : false
                )
                setContaPadrao(
                    response.data.contaPadrao
                        ? response.data.contaPadrao
                        : false
                )
                setTipo(response.data.tipo.toString())
            } else ToastHelper.error(response.statusText)
        })
    }

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        newData.saldoInicial = parseFloat(
            newData.saldoInicial
                .toString()
                .replace('R$', '')
                .replaceAll('.', '')
                .replaceAll(',', '.')
        )
        newData.saldoAtual = params.id
            ? parseFloat(
                  saldoAtual
                      .toString()
                      .replace('R$', '')
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
              )
            : parseFloat(
                  newData.saldoInicial
                      .toString()
                      .replace('R$', '')
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
              )
        newData.limite = parseFloat(
            newData.limite
                ? newData.limite
                      .toString()
                      .replace('R$', '')
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
                : 0
        )
        newData.tipo = tipo
        newData.bancoId = getValues().banco?.value
        newData.desconsideraSaldoInicial = desconsideraSaldoInicial
        newData.contaPadrao = contaPadrao
        if (params.id) {
            newData.id = params.id
            await ContaBancariaService.atualizar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Conta Bancária atualizado')
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    } else if (error.request) {
                        // Solicitação feita sem resposta do servidor
                    } else {
                        // Erro na solicitação
                    }
                })
        } else {
            await ContaBancariaService.cadastrar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Conta bancária cadastrada com sucesso'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    } else if (error.request) {
                        // Solicitação feita sem resposta do servidor
                    } else {
                        // Erro na solicitação
                    }
                })
        }
    }

    return (
        <Page className={classes.root} title="Cadastro de contas">
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Box mt={2}>
                                    <FormLabel component="legend">
                                        {' '}
                                        Tipo:{' '}
                                    </FormLabel>
                                </Box>
                                <Box ml={2}>
                                    <Controller
                                        control={control}
                                        id="tipo"
                                        name="tipo"
                                        as={
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onChange={(_) =>
                                                                setTipo('0')
                                                            }
                                                        />
                                                    }
                                                    label="Conta Bancária"
                                                    value="0"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onChange={(_) =>
                                                                setTipo('1')
                                                            }
                                                        />
                                                    }
                                                    label="Caixa"
                                                    value="1"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onChange={(_) =>
                                                                setTipo('2')
                                                            }
                                                        />
                                                    }
                                                    label="Cartão de Crédito"
                                                    value="2"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            onChange={(_) =>
                                                                setTipo('3')
                                                            }
                                                        />
                                                    }
                                                    label="Investimento"
                                                    value="3"
                                                />
                                            </RadioGroup>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <br />

                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            name={'descricao'}
                                            as={
                                                <TextField
                                                    type="text"
                                                    label="Descrição*"
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            name={'codigoSispag'}
                                            as={
                                                <TextField
                                                    type="text"
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    label="Código SISPAG"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                {watch('tipo') && (watch('tipo') === '0' || watch('tipo') === '3') && (
                                    <>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    name={'banco'}
                                                    id={'banco'}
                                                    as={(props) => (
                                                        <Autocomplete
                                                            options={bancos}
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option
                                                                    ? option.label
                                                                    : ''
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                props.onChange(
                                                                    newValue
                                                                )
                                                            }}
                                                            size="small"
                                                            getOptionSelected={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option?.value ===
                                                                value?.value
                                                            }
                                                            value={
                                                                getValues()
                                                                    .banco
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label={getValues().tipo === tipoBanco ? 'Banco' : 'Banco*'}
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.banco
                                                        ? errors.banco.message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    name={'agencia'}
                                                    as={
                                                        <TextField
                                                            type="text"
                                                            label={getValues().tipo === tipoBanco ? 'Agência' : 'Agência*'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.agencia
                                                        ? errors.agencia.message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="conta"
                                                    name="conta"
                                                    as={
                                                        <TextField
                                                            label={getValues().tipo === tipoBanco ? 'Conta' : 'Conta*'}
                                                            variant="standard"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.conta
                                                        ? errors.conta.message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    name={'titularConta'}
                                                    as={
                                                        <TextField
                                                            type="text"
                                                            label="Titular conta"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        {watch('tipo') && watch('tipo') !== '3' && (
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={classes.formControl}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name={'chavePix'}
                                                        as={
                                                            <TextField
                                                                type="text"
                                                                label="Chave Pix"
                                                                isplaceholderanimated={
                                                                    (false, 'top')
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </>
                                )}

                                <Grid
                                    container
                                    item
                                    xs={3}
                                    display="flex"
                                    className={classes.inputNumber}
                                >
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            name={'saldoInicial'}
                                            id={'saldoInicial'}
                                            as={
                                                <NumberFormat
                                                    prefix={'R$ '}
                                                    label="Saldo Inicial*"
                                                    decimalSeparator={','}
                                                    thousandSeparator={'.'}
                                                    customInput={TextField}
                                                    decimalScale={2}
                                                    disabled={params.id}
                                                    fixedDecimalScale={2}
                                                    inputProps={{
                                                        min: 0,
                                                        style: {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onValueChange={(v) => {
                                                        setSaldoAtual(
                                                            v.formattedValue
                                                        )
                                                    }}
                                                    value={
                                                        getValues().saldoInicial
                                                    }
                                                />
                                            }
                                        />

                                        <p className={classes.errors}>
                                            {errors.saldoInicial
                                                ? errors.saldoInicial.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={3}
                                    display="flex"
                                    className={classes.inputNumber}
                                >
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            name={'dataSaldoInicial'}
                                            as={
                                                <TextField
                                                    label="Data do Saldo Inicial*"
                                                    type="date"
                                                    disabled={params.id}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={
                                                        {
                                                            inputProps: {max: '9999-12-31'}
                                                        }
                                                    }
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.dataSaldoInicial
                                                ? errors.dataSaldoInicial
                                                      .message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                {watch('tipo') && watch('tipo') !== '3' && (

                                    <Grid
                                        container
                                        item
                                        xs={3}
                                        display="flex"
                                        className={classes.inputNumber}
                                    >
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Controller
                                                control={control}
                                                name={'limite'}
                                                as={
                                                    <NumberFormat
                                                        prefix={'R$ '}
                                                        label="Limite"
                                                        decimalSeparator={','}
                                                        thousandSeparator={'.'}
                                                        customInput={TextField}
                                                        decimalScale={2}
                                                        fixedDecimalScale={2}
                                                        inputProps={{
                                                            min: 0,
                                                            style: {
                                                                textAlign: 'right',
                                                            },
                                                        }}
                                                        isplaceholderanimated={
                                                            (false, 'top')
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={getValues('limite')}
                                                    />
                                                }
                                            />
                                            <p className={classes.errors}>
                                                {errors.limite
                                                    ? errors.limite.message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    item
                                    xs={3}
                                    display="flex"
                                    className={classes.inputNumber}
                                >
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type={'string'}
                                            label="Saldo Atual"
                                            disabled={true}
                                            value={saldoAtual?.toLocaleString(
                                                'pt-br',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }
                                            )}
                                            name="saldoAtual"
                                            id="saldoAtual"
                                        />
                                        <p className={classes.errors}>
                                            {errors.saldoAtual
                                                ? errors.saldoAtual.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                {watch('tipo') === '2' && (
                                    <>
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            display="flex"
                                            className={classes.inputNumber}
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="dataVencimento"
                                                    name="dataVencimento"
                                                    error={
                                                        errors.dataVencimento
                                                            ? true
                                                            : false
                                                    }
                                                    as={
                                                        <TextField
                                                            id="dataVencimento"
                                                            label="Dia de Vencimento"
                                                            name="dataVencimento"
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 1,
                                                                    max: 31,
                                                                    step: 1,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.dataVencimento
                                                        ? errors.dataVencimento
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {watch('tipo') && watch('tipo') !== '3' && (

                                <Grid container item xs={12} display="flex">
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        display="flex"
                                        justify="flex-end"
                                    >
                                        <Controller
                                            control={control}
                                            name={'contaPadrao'}
                                            as={
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={contaPadrao}
                                                            onChange={() =>
                                                                setContaPadrao(
                                                                    !contaPadrao
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label="Conta Padrão"
                                                />
                                            }
                                        />
                                        <Controller
                                            control={control}
                                            name={'desconsideraSaldoInicial'}
                                            as={
                                                <FormControlLabel
                                                    name="desconsideraSaldoInicial"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={
                                                                desconsideraSaldoInicial
                                                            }
                                                            onChange={() =>
                                                                setDesconsideraSaldoInicial(
                                                                    !desconsideraSaldoInicial
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label="Desconsiderar saldo inicial no fluxo de caixa"
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                    <FooterBottons/>
                </form>
            </Container>
        </Page>
    )
}
