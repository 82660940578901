import React from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: '15px',
        marginBottom: '15px',
    },
    headerTitle: {
        borderBottom: 'solid 1px #E0E0E0',
        fontSize: '20px',
        fontWeight: '400',
    },
    headerText: {
        borderBottom: 'solid 1px #E0E0E0',
        fontSize: '18px',
        padding: '5px',
        fontWeight: '250',
    },
    gridHeader: {
        paddingLeft: '20px',
        paddingRight: '20px',
        marginBottom: '10px',
    },
}))

export default function Resumo(props) {
    const classes = useStyles()

    const saldoFinal = () => {
        return (
            <>
                <Grid
                    container
                    item
                    md={12}
                    justify="center"
                    className={classes.headerTitle}
                >
                    Saldo Final
                </Grid>
                <Grid
                    container
                    item
                    md={12}
                    mt={2}
                    justify="center"
                    className={classes.headerText}
                    style={
                        props.saldoFinal < 0
                            ? { color: 'red' }
                            : { color: '#2E7D32' }
                    }
                >
                    {props.saldoFinal.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Grid>
            </>
        )
    }

    const totalDeSaidas = () => {
        return (
            <>
                <Grid
                    container
                    item
                    md={12}
                    justify="center"
                    className={classes.headerTitle}
                >
                    Total de Saídas
                </Grid>
                <Grid
                    container
                    item
                    md={12}
                    mt={2}
                    justify="center"
                    className={classes.headerText}
                    style={{ color: 'red' }}
                >
                    {props.totalSaidas.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Grid>
            </>
        )
    }

    return (
        <Box mt={1} mb={1}>
            <Grid container item md={12} display="flex" justify="center">
                <Grid
                    container
                    item
                    md={props.mostraGrafico ? 6 : 3}
                    justify="center"
                    className={classes.gridHeader}
                    style={
                        props.mostraGrafico
                            ? { marginTop: '5%' }
                            : { marginTop: '1%' }
                    }
                >
                    <Grid
                        container
                        item
                        md={12}
                        justify="center"
                        className={classes.headerTitle}
                    >
                        Saldo Inicial
                    </Grid>
                    <Grid
                        container
                        item
                        md={12}
                        mt={2}
                        justify="center"
                        className={classes.headerText}
                        style={
                            props.saldoInicial < 0
                                ? { color: 'red' }
                                : { color: '#2E7D32' }
                        }
                    >
                        {props.saldoInicial.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    md={props.mostraGrafico ? 6 : 3}
                    justify="center"
                    style={
                        props.mostraGrafico
                            ? { marginTop: '5%' }
                            : { marginTop: '1%' }
                    }
                    className={classes.gridHeader}
                >
                    <Grid
                        container
                        item
                        md={12}
                        justify="center"
                        className={classes.headerTitle}
                    >
                        Total de Entradas
                    </Grid>
                    <Grid
                        container
                        item
                        md={12}
                        mt={2}
                        justify="center"
                        className={classes.headerText}
                        style={{ color: '#6F98F0' }}
                    >
                        {props.totalEntradas.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    md={props.mostraGrafico ? 6 : 3}
                    justify="center"
                    className={classes.gridHeader}
                    style={
                        props.mostraGrafico
                            ? { marginTop: '5%' }
                            : { marginTop: '1%' }
                    }
                >
                    {props.mostraGrafico && saldoFinal()}
                    {!props.mostraGrafico && totalDeSaidas()}
                </Grid>
                <Grid
                    container
                    item
                    md={props.mostraGrafico ? 6 : 3}
                    justify="center"
                    style={
                        props.mostraGrafico
                            ? { marginTop: '5%' }
                            : { marginTop: '1%' }
                    }
                    className={classes.gridHeader}
                >
                    {!props.mostraGrafico && saldoFinal()}
                    {props.mostraGrafico && totalDeSaidas()}
                </Grid>
            </Grid>
        </Box>
    )
}
