import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    Divider,
    Button,
    makeStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const GreenCheckbox = withStyles({
    root: {
        '&$checked': {
            color: '#2E7D32',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    tabela: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    errors: {
        color: 'red',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    listVinculados: {
        width: 450,
        height: 400,
        backgroundColor: '#EFF7EF',
        overflow: 'auto',
    },
    listNaoVinculados: {
        width: 450,
        height: 400,
        backgroundColor: '#F5F5F5',
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

export default function GerenciadorDeColaboradoresVinculados(props) {

    const classes = useStyles();

    const [checked, setChecked] = React.useState([]);
    const [naoVinculados, setNaoVinculados] = React.useState(
        props.opcoesColaboradores || [],
    );
    const [vinculados, setVinculados] = React.useState(
        props.colaboradoresVinculados || [],
    );

    const naoVinculadosSelecionados = intersection(checked, naoVinculados);
    const vinculadosSelecioandos = intersection(checked, vinculados);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setVinculados(vinculados.concat(naoVinculadosSelecionados));
        setNaoVinculados(not(naoVinculados, naoVinculadosSelecionados));
        setChecked(not(checked, naoVinculadosSelecionados));
    };

    const handleCheckedLeft = () => {
        setNaoVinculados(naoVinculados.concat(vinculadosSelecioandos));
        setVinculados(not(vinculados, vinculadosSelecioandos));
        setChecked(not(checked, vinculadosSelecioandos));
    };

    // eslint-disable-next-line no-unused-vars
    const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    };

    useEffect(() => {
        let newVinculados = [];
        vinculados.forEach((item) => {
            newVinculados.push(item.value);
        });
        props.setColaboradoresVinculados(newVinculados);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vinculados]);

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <GreenCheckbox
                        onClick={handleToggleAll(items)}
                        checked={
                            numberOfChecked(items) === items.length &&
                            items.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(items) !== items.length &&
                            numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${
                    items.length
                } Selecionados`}
            />
            <Divider />
            <List
                className={
                    title === 'Vinculados'
                        ? classes.listVinculados
                        : classes.listNaoVinculados
                }
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <GreenCheckbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${value.label}`}
                            />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <h3
                    style={{
                        marginBottom: '15px',
                        marginTop: '15px',
                        textAlign: 'center',
                    }}
                >
                    Vincule os colaboradores a este centro de custo.
                </h3>
            </Grid>
            <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>{customList('Não vinculados', naoVinculados)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={naoVinculadosSelecionados.length === 0}
                            aria-label="Move para lista de selecionados"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={vinculadosSelecioandos.length === 0}
                            aria-label="Retira da lista de selecionados"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList('Vinculados', vinculados)}</Grid>
            </Grid>
        </>
    );
}
