import { CamposParcela } from '../pages/camposParcela';
import { CamposParcelaCadastro } from '../pages/camposParcela/camposParcelaCadastro';

export const CamposParcelaRoute = [
    {
        path: '/app/camposParcela',
        key: 'APP_CAMPOS_PARCELA',
        exact: true,
        component: CamposParcela,
    },
    {
        path: '/app/camposParcela/cadastro',
        key: 'APP_CAMPOS_PARCELA_CADASTRO',
        exact: true,
        component: CamposParcelaCadastro,
    },
    {
        path: '/app/camposParcela/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CAMPOS_PARCELA_EDITAR',
        exact: true,
        component: CamposParcelaCadastro,
    },
];
