import { SET_FILTRO_LISTAGEM_TRANSFERENCIA_BANCARIA } from '../actions/transferenciaBancaria';

export const storageTransferenciaBancaria = 'transferenciaBancaria';

const initialState = {
    filtroListagem: {},
};

export const transferenciaBancaria = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_TRANSFERENCIA_BANCARIA:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
