import { CentroNegocio } from '../pages/centroNegocio/index';
import { CentroNegocioCadastro } from '../pages/centroNegocio/CentroNegocioCadastro';

export const centroNegocioRoute = [
    {
        path: '/app/centroNegocio',
        key: 'APP_CENTRO_NEGOCIO',
        exact: true,
        component: CentroNegocio,
    },
    {
        path: '/app/centroNegocio/cadastro',
        key: 'APP_CENTRO_NEGOCIO_CADASTRO',
        exact: true,
        component: CentroNegocioCadastro,
    },
    {
        path: '/app/centroNegocio/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CENTRO_NEGOCIO_EDITAR',
        exact: true,
        component: CentroNegocioCadastro,
    },
];
