import { TipoDocumento } from '../pages/tipoDocumento'
import { TipoDocumentoCadastro } from '../pages/tipoDocumento/TipoDocumentoCadastro'

export const tipoDocumentoRoute = [
    {
        path: '/app/tipoDocumento',
        key: 'APP_TIPO_DOCUMENTO',
        exact: true,
        component: TipoDocumento,
    },
    {
        path: '/app/tipoDocumento/cadastro',
        key: 'APP_TIPO_DOCUMENTO_CADASTRO',
        exact: true,
        component: TipoDocumentoCadastro,
    },
    {
        path: '/app/tipoDocumento/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_TIPO_DOCUMENTO_EDITAR',
        exact: true,
        component: TipoDocumentoCadastro,
    },
    {
        path: '/app/tipoDocumento/cadastro/:modo(aba)',
        key: 'APP_TIPO_DOCUMENTO_CADASTRO_MODO',
        exact: true,
        component: TipoDocumentoCadastro,
    },
]
