import { SET_FILTRO_LISTAGEM_UNIDADES } from '../actions/unidades'

export const storageUnidades = 'Unidades'

const initialState = {
    filtroListagem: {},
}

export const unidades = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_UNIDADES:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
