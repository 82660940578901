import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import FornecedorClienteService from '../../services/FornecedorClienteService'
import ToastHelper from '../../utils/toastHelper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NumberFormat from 'react-number-format'
import ObjectHelper from '../../utils/objetcHelper'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { cpfMask } from '../../utils/maskHelper'
import BancoService from '../../services/BancoService'
import TabelaContatos from './TabelaContatosFornecedorCliente'
import { validaCpf } from './../../utils/validateHelper'
import { validaCnpj } from './../../utils/validateHelper'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '97%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    divider: {
        marginBottom: '15px',
    },
    labelRadius: {
        marginTop: '13px',
        marginLeft: '10px',
        marginRight: '50px',
    },
    gridRadius: {
        marginTop: '13px',
    },
    gridRadiusFornecedorCliente: {
        marginTop: '13px',
        marginBottom: '13px',
    },
    labelRadiusFornecedorCliente: {
        marginTop: '13px',
        marginLeft: '5px',
        marginRight: '10px',
    },
    gridCheckIsento: {
        marginLeft: '10px',
    },
    boxLoading: {
        paddingLeft: '45%',
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const tiposConta = [
    {
        label: 'Conta-corrente',
        value: 'CORRENTE',
    },
    {
        label: 'Conta-poupança',
        value: 'POUPANCA',
    },
]

export const FornecedorClienteCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const theme = useTheme()
    const [valueTab, setValueTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [bancos, setBancos] = useState([])
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const {
        match: { params },
    } = props

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            nomeFantasia: '',
            razaoSocial: '',
            site: '',
            tipoPessoa: '',
            cpfCnpj: '',
            isento: '',
            inscricaoEstadual: '',
            inscricaoMunicipal: '',
            cep: '',
            logradouro: '',
            numero: '',
            bairro: '',
            complemento: '',
            cidade: '',
            uf: '',
            tipoFornecedorCliente: '',
            fazendaId: '',
            categoria: '',
        },
    })

    //Dados fornecedor cliente
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [email, setEmail] = useState('')
    const [site, setSite] = useState('')
    const [inscricaoEstadual, setInscricaoEstadual] = useState('')
    const [inscricaoMunicipal, setInscricaoMunicipal] = useState('')
    const [observacao, setObservacao] = useState('')
    const [tipoFornecedorCliente, setTipoFornecedorCliente] =
        useState('FORNECEDOR')
    const [tipoDePessoa, setTipoDePessoa] = useState('FISICA')
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [categoria, setCategoria] = useState('')
    const [isento, setIsento] = useState(false)
    const [errorNomeFantasia, setErroNomeFantasia] = useState({
        error: false,
        mensagem: '',
    })
    const [erroRazaoSocial, setErroRazaoSocial] = useState({
        error: false,
        mensagem: '',
    })
    //Dados dados bancarios
    const [bancoSelecionado, setBancoSelecionado] = useState({})
    const [agencia, setAgencia] = useState('')
    const [conta, setConta] = useState('')
    const [operacao, setOperacao] = useState('')
    const [tipoContaSelecionada, setTipoContaSelecionada] = useState({})

    //Dados endereço
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [bairro, setBairro] = useState('')
    const [cep, setCep] = useState('')
    const [logradouro, setLogradouro] = useState('')
    const [numero, setNumero] = useState('')

    //Dados contatos
    const [contatos, setContatos] = useState([])

    useEffect(() => {
        async function init() {
            const {
                match: { params },
            } = props
            await buscarBancos()
            if (params.id) await buscarParaEdicao(params.id)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarBancos = () => {
        BancoService.buscarTodos().then((response) => {
            let newBancos = ObjectHelper.getValuesParseToSelect(
                response.data.itens
            )
            setBancos(newBancos)
        })
    }

    const setBanco = (banco) => {
        setBancoSelecionado(banco)
    }

    const buscarParaEdicao = async (fornecedorClienteId) => {
        setLoading(true)
        await FornecedorClienteService.buscarPorId(fornecedorClienteId).then(
            (response) => {
                if (response.status === 200) {
                    setNomeFantasia(response.data.nomeFantasia)
                    setRazaoSocial(response.data.razaoSocial)
                    setEmail(response.data.email)
                    setSite(response.data.site)
                    setInscricaoEstadual(response.data.inscricaoEstadual)
                    setInscricaoMunicipal(response.data.inscricaoMunicipal)
                    setObservacao(response.data.observacao)
                    setTipoFornecedorCliente(
                        response.data.tipoFornecedorCliente === 0
                            ? 'FORNECEDOR'
                            : 'CLIENTE'
                    )
                    setTipoDePessoa(
                        response.data.tipoPessoa === 0 ? 'FISICA' : 'JURIDICA'
                    )
                    setCpfCnpj(response.data.cpfCnpj)
                    setIsento(response.data.isento)
                    setCategoria(response.data.categoria)

                    if (response.data.contaBancariaFornecedorCliente) {
                        setBancoSelecionado({
                            value: response.data.contaBancariaFornecedorCliente
                                .banco.id,
                            label: response.data.contaBancariaFornecedorCliente
                                .banco.nome,
                        })
                        setAgencia(
                            response.data.contaBancariaFornecedorCliente.agencia
                        )
                        setConta(
                            response.data.contaBancariaFornecedorCliente.conta
                        )
                        setOperacao(
                            response.data.contaBancariaFornecedorCliente
                                .operacao
                        )
                        let tipoConta = tiposConta.filter(
                            (x) =>
                                x.value ===
                                response.data.contaBancariaFornecedorCliente
                                    .tipoConta
                        )[0]
                        setTipoContaSelecionada(tipoConta)
                    }

                    setUf(response.data.uf)
                    setCidade(response.data.cidade)
                    setBairro(response.data.bairro)
                    setCep(response.data.cep)
                    setLogradouro(response.data.logradouro)
                    setNumero(response.data.numero)

                    setContatos(response.data.contato)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const mudaTab = (event, newValue) => {
        setValueTab(newValue)
    }

    const mudaIndex = (index) => {
        setValue(index)
    }

    const MudaCpf = (e) => {
        setCpfCnpj(cpfMask(e.target.value))
    }

    const MudaCnpj = (e) => {
        setCpfCnpj(e.target.value)
    }

    const mudaTipoPessoa = (e) => {
        setTipoDePessoa(e.target.value)
        setCpfCnpj('')
        setErroRazaoSocial({ error: false })
        setErroNomeFantasia({ error: false })
    }

    const mudaTipoFornecedorCliente = (e) => {
        setTipoFornecedorCliente(e.target.value)
    }

    const valida = () => {
        let valido = true
        if (tipoDePessoa === 'JURIDICA') {
            if (!razaoSocial) {
                setErroRazaoSocial({
                    error: true,
                    mensagem: 'Campo obrigatório',
                })
                valido = false
            } else {
                setErroRazaoSocial({
                    error: false,
                    mensagem: '',
                })
            }
        }

        if (!nomeFantasia) {
            setErroNomeFantasia({
                error: true,
                mensagem: 'Campo obrigatório',
            })
            valido = false
        } else {
            setErroNomeFantasia({
                error: false,
                mensagem: '',
            })
        }

        return valido
    }

    const onSubmit = async () => {
        let cpfCnpjValido = true
        if (cpfCnpj) {
            if (tipoDePessoa === 'FISICA') {
                cpfCnpjValido = await validaCpf(cpfCnpj)
                if (!cpfCnpjValido) ToastHelper.error('CPF inválido.')
            } else {
                cpfCnpjValido = await validaCnpj(cpfCnpj)
                if (!cpfCnpjValido) ToastHelper.error('CNPJ inválido.')
            }
        }
        if (valida() && cpfCnpjValido) {
            let dadosSubmit = {
                fazendaId,
                nomeFantasia,
                razaoSocial,
                email,
                categoria,
                site,
                inscricaoEstadual,
                inscricaoMunicipal,
                observacao,
                tipoPessoa: tipoDePessoa === 'FISICA' ? 0 : 1,
                tipoFornecedorCliente:
                    tipoFornecedorCliente === 'FORNECEDOR' ? 0 : 1,
                cpfCnpj,
                bancoSelecionado,
                agencia,
                conta,
                operacao,
                tipoConta: tipoContaSelecionada
                    ? tipoContaSelecionada.value
                    : '',
                bancoId: bancoSelecionado ? bancoSelecionado.value : '',
                uf,
                cidade,
                bairro,
                cep,
                logradouro,
                numero,
                contatos,
                isento,
            }

            if (params.id) {
                dadosSubmit.id = params.id
                await FornecedorClienteService.atualizar(dadosSubmit)
                    .then((response) => {
                        if (response.status === 200) {
                            ToastHelper.success(
                                (dadosSubmit.tipoFornecedorCliente === 0
                                    ? 'Fornecedor '
                                    : 'Cliente ') + 'atualizado com sucesso'
                            )
                            history.goBack()
                        } else {
                            ToastHelper.error(response.statusText)
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            ToastHelper.error(error.response.data)
                        }
                    })
            } else {
                await FornecedorClienteService.cadastrar(dadosSubmit)
                    .then((response) => {
                        if (response.status === 200) {
                            ToastHelper.success(
                                (dadosSubmit.tipoFornecedorCliente === 0
                                    ? 'Fornecedor '
                                    : 'Cliente ') + 'cadastrado com sucesso'
                            )
                            if (params.modo === 'aba') {
                                window.close()
                            } else {
                                history.goBack()
                            }
                        } else {
                            ToastHelper.error(response.statusText)
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            ToastHelper.error(error.response.data)
                        }
                    })
            }
        }
    }

    return (
        <Page className={classes.root} title="Fornecedor / Cliente Cadastro">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Fornecedor / Cliente'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            {loading && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    className={classes.boxLoading}
                                >
                                    <CircularProgress color="primary" />
                                </Grid>
                            )}
                            {!loading && (
                                <>
                                    <Tabs
                                        value={valueTab}
                                        onChange={mudaTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                    >
                                        <Tab
                                            label="Fornecedor / Cliente"
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label="Dados bancários"
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label="Endereço"
                                            {...a11yProps(2)}
                                        />
                                        <Tab
                                            label="Contatos"
                                            {...a11yProps(3)}
                                        />
                                    </Tabs>
                                    <SwipeableViews
                                        axis={
                                            theme.direction === 'rtl'
                                                ? 'x-reverse'
                                                : 'x'
                                        }
                                        index={valueTab}
                                        onChangeIndex={mudaIndex}
                                    >
                                        <TabPanel
                                            value={valueTab}
                                            index={0}
                                            dir={theme.direction}
                                        >
                                            {tipoFornecedorCliente ===
                                            'FORNECEDOR'
                                                ? 'Fornecedor'
                                                : 'Cliente'}
                                            <Divider
                                                className={classes.divider}
                                            />
                                            <Grid container item xs={5}>
                                                <Grid
                                                    container
                                                    item
                                                    className={
                                                        classes.gridRadiusFornecedorCliente
                                                    }
                                                >
                                                    <FormLabel
                                                        component="legend"
                                                        className={
                                                            classes.labelRadiusFornecedorCliente
                                                        }
                                                    >
                                                        Tipo de Cadastro:
                                                    </FormLabel>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            row
                                                            aria-label="gender"
                                                            name="gender1"
                                                            value={
                                                                tipoFornecedorCliente
                                                            }
                                                            onChange={(e) =>
                                                                mudaTipoFornecedorCliente(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                value={
                                                                    'CLIENTE'
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Cliente"
                                                                labelPlacement="start"
                                                            />
                                                            <FormControlLabel
                                                                value={
                                                                    'FORNECEDOR'
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Fornecedor"
                                                                labelPlacement="start"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label={
                                                                tipoDePessoa ===
                                                                'JURIDICA'
                                                                    ? 'Nome Fantasia*'
                                                                    : 'Nome da Pessoa*'
                                                            }
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={nomeFantasia}
                                                            defaultValue={
                                                                nomeFantasia
                                                            }
                                                            onChange={(e) =>
                                                                setNomeFantasia(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="nomeFantasia"
                                                            name="nomeFantasia"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errorNomeFantasia.error
                                                                ? errorNomeFantasia.mensagem
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label={
                                                                tipoDePessoa ===
                                                                'JURIDICA'
                                                                    ? 'Razão Social*'
                                                                    : 'Razão Social'
                                                            }
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={razaoSocial}
                                                            defaultValue={
                                                                razaoSocial
                                                            }
                                                            onChange={(e) =>
                                                                setRazaoSocial(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="razaoSocial"
                                                            name="razaoSocial"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {erroRazaoSocial.error
                                                                ? erroRazaoSocial.mensagem
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Categoria"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={categoria}
                                                            defaultValue={
                                                                categoria
                                                            }
                                                            onChange={(e) =>
                                                                setCategoria(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="categoria"
                                                            name="categoria"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="email"
                                                            label="E-mail"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={email}
                                                            defaultValue={email}
                                                            onChange={(e) =>
                                                                setEmail(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="email"
                                                            name="email"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.email
                                                                ? errors.email
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Site"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={site}
                                                            defaultValue={site}
                                                            onChange={(e) =>
                                                                setSite(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="site"
                                                            name="site"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.site
                                                                ? errors.site
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={5}
                                                    display="flex"
                                                    className={
                                                        classes.gridRadius
                                                    }
                                                >
                                                    <FormLabel
                                                        component="legend"
                                                        className={
                                                            classes.labelRadius
                                                        }
                                                    >
                                                        Tipo de Pessoa:
                                                    </FormLabel>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            row
                                                            aria-label="gender"
                                                            name="gender1"
                                                            value={tipoDePessoa}
                                                            onChange={(e) =>
                                                                mudaTipoPessoa(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                value={'FISICA'}
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Física"
                                                                labelPlacement="start"
                                                            />
                                                            <FormControlLabel
                                                                value={
                                                                    'JURIDICA'
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label="Jurídica"
                                                                labelPlacement="start"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={2}
                                                    display="flex"
                                                >
                                                    {tipoDePessoa ===
                                                        'FISICA' && (
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <TextField
                                                                type="text"
                                                                label="CPF"
                                                                className={
                                                                    classes.textField
                                                                }
                                                                value={cpfCnpj}
                                                                onChange={
                                                                    MudaCpf
                                                                }
                                                                inputRef={
                                                                    register
                                                                }
                                                                id="cpfCnpj"
                                                                name="cpfCnpj"
                                                                maxLength="14"
                                                                isplaceholderanimated={
                                                                    (false,
                                                                    'top')
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    )}
                                                    {tipoDePessoa ===
                                                        'JURIDICA' && (
                                                        <FormControl
                                                            className={
                                                                classes.formControl
                                                            }
                                                        >
                                                            <NumberFormat
                                                                type="text"
                                                                label="CNPJ"
                                                                className={
                                                                    classes.textField
                                                                }
                                                                value={cpfCnpj}
                                                                format="##.###.###/####-##"
                                                                customInput={
                                                                    TextField
                                                                }
                                                                onChange={
                                                                    MudaCnpj
                                                                }
                                                                inputRef={
                                                                    register
                                                                }
                                                                id="cpfCnpj"
                                                                name="cpfCnpj"
                                                                isplaceholderanimated={
                                                                    (false,
                                                                    'top')
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={2}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Inscrição estadual"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={
                                                                inscricaoEstadual
                                                            }
                                                            defaultValue={
                                                                inscricaoEstadual
                                                            }
                                                            onChange={(e) =>
                                                                setInscricaoEstadual(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="inscricaoEstadual"
                                                            name="inscricaoEstadual"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.inscricaoEstadual
                                                                ? errors
                                                                      .inscricaoEstadual
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={2}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Inscrição municipal"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={
                                                                inscricaoMunicipal
                                                            }
                                                            defaultValue={
                                                                inscricaoMunicipal
                                                            }
                                                            onChange={(e) =>
                                                                setInscricaoMunicipal(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="inscricaoMunicipal"
                                                            name="inscricaoMunicipal"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.inscricaoMunicipal
                                                                ? errors
                                                                      .inscricaoMunicipal
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                    justify="flex-start"
                                                    className={
                                                        classes.gridCheckIsento
                                                    }
                                                >
                                                    <FormControlLabel
                                                        inputRef={register}
                                                        name="isento"
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isento}
                                                                onChange={() =>
                                                                    setIsento(
                                                                        !isento
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Isento"
                                                        labelPlacement="isento"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControlObs
                                                    }
                                                >
                                                    <TextField
                                                        type="text"
                                                        label="Observação"
                                                        className={
                                                            classes.textField
                                                        }
                                                        inputRef={register}
                                                        id="observacao"
                                                        value={observacao}
                                                        defaultValue={
                                                            observacao
                                                        }
                                                        onChange={(e) =>
                                                            setObservacao(
                                                                e.target.value
                                                            )
                                                        }
                                                        name="observacao"
                                                        multiline
                                                        variant="outlined"
                                                        rows={4}
                                                        isplaceholderanimated={
                                                            (false, 'top')
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel
                                            value={valueTab}
                                            index={1}
                                            dir={theme.direction}
                                        >
                                            Dados bancários
                                            <Divider
                                                className={classes.divider}
                                            />
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Autocomplete
                                                            name="banco"
                                                            id="banco"
                                                            inputRef={register}
                                                            options={bancos}
                                                            value={
                                                                bancoSelecionado
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.label
                                                                    ? option.label
                                                                    : ''
                                                            }
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setBanco(
                                                                    newValue
                                                                )
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputRef={
                                                                        register
                                                                    }
                                                                    name="banco"
                                                                    id="banco"
                                                                    variant="standard"
                                                                    label="Banco"
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                            autoSelect
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.banco
                                                                ? errors.banco
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Agência"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={agencia}
                                                            defaultValue={
                                                                agencia
                                                            }
                                                            onChange={(e) =>
                                                                setAgencia(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="agencia"
                                                            name="agencia"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.agencia
                                                                ? errors.agencia
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Conta"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={conta}
                                                            defaultValue={conta}
                                                            onChange={(e) =>
                                                                setConta(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="conta"
                                                            name="conta"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.conta
                                                                ? errors.conta
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Operação"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={operacao}
                                                            defaultValue={
                                                                operacao
                                                            }
                                                            onChange={(e) =>
                                                                setOperacao(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="operacao"
                                                            name="operacao"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Autocomplete
                                                            name="tipoConta"
                                                            id="tipoConta"
                                                            inputRef={register}
                                                            options={tiposConta}
                                                            value={
                                                                tipoContaSelecionada
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.label
                                                                    ? option.label
                                                                    : ''
                                                            }
                                                            size="small"
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setTipoContaSelecionada(
                                                                    newValue
                                                                )
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputRef={
                                                                        register
                                                                    }
                                                                    name="tipoConta"
                                                                    id="tipoConta"
                                                                    variant="standard"
                                                                    label="Tipo de conta"
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                            autoSelect
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel
                                            value={valueTab}
                                            index={2}
                                            dir={theme.direction}
                                        >
                                            Endereço
                                            <Divider
                                                className={classes.divider}
                                            />
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={1}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="UF"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={uf}
                                                            defaultValue={uf}
                                                            onChange={(e) =>
                                                                setUf(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="uf"
                                                            name="uf"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.uf
                                                                ? errors.uf
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Cidade"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={cidade}
                                                            defaultValue={
                                                                cidade
                                                            }
                                                            onChange={(e) =>
                                                                setCidade(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="cidade"
                                                            name="cidade"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.cidade
                                                                ? errors.cidade
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Bairro"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={bairro}
                                                            defaultValue={
                                                                bairro
                                                            }
                                                            onChange={(e) =>
                                                                setBairro(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="bairro"
                                                            name="bairro"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.bairro
                                                                ? errors.bairro
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <NumberFormat
                                                            type="text"
                                                            label="CEP"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={cep}
                                                            format="#####-###"
                                                            customInput={
                                                                TextField
                                                            }
                                                            onChange={(e) =>
                                                                setCep(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="cep"
                                                            name="cep"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={10}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="text"
                                                            label="Logradouro"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={logradouro}
                                                            defaultValue={
                                                                logradouro
                                                            }
                                                            onChange={(e) =>
                                                                setLogradouro(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="logradouro"
                                                            name="logradouro"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.logradouro
                                                                ? errors
                                                                      .logradouro
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={2}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <TextField
                                                            type="number"
                                                            label="Número"
                                                            className={
                                                                classes.textField
                                                            }
                                                            value={numero}
                                                            defaultValue={
                                                                numero
                                                            }
                                                            onChange={(e) =>
                                                                setNumero(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            inputRef={register}
                                                            id="numero"
                                                            name="numero"
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.numero
                                                                ? errors.numero
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel
                                            value={valueTab}
                                            index={3}
                                            dir={theme.direction}
                                        >
                                            <TabelaContatos
                                                contatos={contatos}
                                                setContatos={setContatos}
                                            />
                                        </TabPanel>
                                    </SwipeableViews>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
