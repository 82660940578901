import { Estoque } from '../pages/estoque/index';

export const EstoqueRoute = [
    {
        path: '/app/estoque',
        key: 'APP_ESTOQUE',
        exact: true,
        component: Estoque,
    },
];
