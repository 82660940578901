import React from 'react'
import { Button, Divider, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 5, 5),
        marginTop: 200,
        marginLeft: 500,
        marginRight: 500,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    valorTotal: {
        display: 'inline-flex !important',
    },
}))

export default function ModalCancelar(props) {
    const classes = useStyles()

    const { handleSubmit } = useForm({})

    const submit = async () => {
        if (props.tipo === 'pagamento') {
            props.cancelarQuitacao(props.adiantamentoSelecionado)
        } else {
            props.cancelarConciliacao(props.adiantamentoSelecionado)
        }
    }

    return (
        <>
            <div className={classes.paper}>
                <h2 className={classes.title}>
                    {props.tipo === 'pagamento'
                        ? 'Deseja realmente cancelar este pagamento?'
                        : 'Deseja realmente cancelar esta conciliação?'}
                </h2>

                <form className={classes.form} onSubmit={handleSubmit(submit)}>
                    <Divider className={classes.divider} />
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.close()
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="secundary"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </form>
            </div>
        </>
    )
}
