import React from 'react';
import './404.css';

export default function NotFound() {
    return (
        <section id="not-found">
            <div>
                <p>
                    404<br></br>
                    <small>Página não encontrada</small>
                </p>
            </div>
        </section>
    )
}
