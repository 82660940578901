import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MaterialTable from 'material-table'
import { MTablePagination } from 'material-table'
import Grid from '@material-ui/core/Grid'
import { Edit3 as Edit3Icon } from 'react-feather'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import ConteudoModalQuitarContas from '../modals/modalQuitarContas'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import ToastHelper from 'utils/toastHelper'
import MoneyOnIcon from '@material-ui/icons/AttachMoney'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import Tooltip from '@material-ui/core/Tooltip'
import ConciliadoIcon from '@material-ui/icons/DoneAll'
import NConciliadoIcon from '@material-ui/icons/Close'
import ModalCancelar from '../modals/modalCancelar'
import AdiantamentoRequests from '../request'
import ObjectHelper from 'utils/objetcHelper'
import {ConfirmExclusionModal} from 'components/Modals/ConfirmExclusionModal'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlSituacao: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonModalProdutosServicos: {
        marginBottom: '-25px',
        zIndex: 9,
        marginRight: 10,
        marginTop: 10,
        float: 'right',
    },
}))

export default function AdiantamentoFornecedoresClientesListagem(props) {
    const classes = useStyles()

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#2E7D32',
            },
        },
    })

    const colunas = [
        { title: 'Venc.', field: 'dataVencimento', editable: 'never' },
        { title: 'Fornecedor / Cliente', field: 'nomeFantasia', editable: 'never' },
        { title: 'Doc.', field: 'documento', editable: 'never' },
        { title: 'Descrição', field: 'descricao', editable: 'never' },
        { title: 'Situação', field: 'situacao', editable: 'never' },
        { title: 'Valor', field: 'valor', editable: 'never',
          render: (row) => (<p>{ObjectHelper.convertFloatToCurrency(row.valor)}</p>)      
        },
        { title: 'Saldo', field: 'saldo', editable: 'never',
        render: (row) => (<p>{ObjectHelper.convertFloatToCurrency(row.saldo)}</p>)      
        },
        {
            title: 'Pago/Conciliado',
            field: 'dataPagamento',
            editable: 'never',
            cellStyle: {
                textAlign: 'center',
            },
            render: (prop) => (
                <div style={{ display: 'inline-flex' }}>
                    <div
                        style={{
                            color: prop?.dataPagamento ? '#2E7D32' : 'red',
                            marginRight: '10px',
                        }}
                    >
                        <Tooltip
                            title={
                                prop?.dataPagamento
                                    ? 'Data de pagamento: ' +
                                      prop?.dataPagamento
                                    : ''
                            }
                        >
                            {prop?.dataPagamento ? (
                                <MoneyOnIcon />
                            ) : (
                                <MoneyOffIcon />
                            )}
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            color: prop?.dataCompensacao ? '#2E7D32' : 'red',
                            marginRight: '15px',
                        }}
                    >
                        <Tooltip
                            title={
                                prop?.dataCompensacao
                                    ? 'Data de conciliação: ' +
                                      prop?.dataCompensacao
                                    : ''
                            }
                        >
                            {prop?.dataCompensacao ? (
                                <ConciliadoIcon />
                            ) : (
                                <NConciliadoIcon />
                            )}
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ]

    const [openModalQuitarAdiantamento, setOpenModalQuitarAdiantamento] = React.useState(false)
    const [openModalConfirmCancel, setOpenModalConfirmCancel] = React.useState(false)
    const [openModalConfirmExclusion, setOpenModalConfirmExclusion] = React.useState(false)
    const [adiantamento, setAdiantamento] = React.useState(null);

    const closeModalQuitacao = async () => {
        setOpenModalQuitarAdiantamento(false)
    }

    const conciliar = async (row, tipo) => {
        if (row.dataPagamento === '')
            return ToastHelper.error('Adiantamento não quitado. Documento ' + row.documento)
        
        row.tipo = tipo;
        setAdiantamento(row)
        if(!row.dataCompensacao){
            setOpenModalQuitarAdiantamento(true)
        }else{
            setOpenModalConfirmCancel(true);
        }
        
    }

    const quitacao = async(data, tipo) => {
        data.tipo = tipo;
        setAdiantamento(data);
        if(!data.dataPagamento){
            setOpenModalQuitarAdiantamento(true)
        }else{
            setOpenModalConfirmCancel(true);
        }
       
    }

    const cancelarQuitacao = async() => {
        if(!!adiantamento.dataCompensacao) {
            setOpenModalConfirmCancel(false)
            return ToastHelper.error('Pagamento conciliado. Documento ' + adiantamento.documento)
        }

        let result = await AdiantamentoRequests.cancelarQuitacao(adiantamento.id);
        if(result?.data.isOk){
            ToastHelper.success('Pagamento cancelado.')
            props.buscarTudo();
            setOpenModalConfirmCancel(false)
        }else{
            ToastHelper.error(result.data.mensagem)
            props.buscarTudo();
            setOpenModalConfirmCancel(false)
        }
    }

    const cancelarConciliacao = async() => {
        let result = await AdiantamentoRequests.cancelarConciliacao(adiantamento.id);
        if(result?.status === 200){
            ToastHelper.success('Conciliação cancelada.')
            props.buscarTudo();
            setOpenModalConfirmCancel(false)
        }else{
            ToastHelper.error('Erro ao cancelar conciliação.')
            props.buscarTudo();
            setOpenModalConfirmCancel(false)

        }
    }

    const deletar = async () => {
        let result = await AdiantamentoRequests.deletar(adiantamento.id)
        if(result?.isOk){
            ToastHelper.success("Adiantamento excluído.")
            props.buscarTudo();
            setOpenModalConfirmExclusion(false)

        }else{
            ToastHelper.error(result.mensagem)
            props.buscarTudo();
            setOpenModalConfirmExclusion(false)
        }
    }

    const confirmDelete = (row) => {
        setAdiantamento(row);
        setOpenModalConfirmExclusion(true);
    }



    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    title={'Listagem'}
                    columns={colunas}
                    data={props.data}
                    options={{
                        emptyRowsWhenPaging: props.renderizarLinhasEmBranco
                            ? props.renderizarLinhasEmBranco
                            : false,
                        pageSize: props.pageSize ? props.pageSize : 100,
                        paging: true,
                        padding: 'dense',
                        rowStyle: {
                            fontSize: 14,
                        },
                        search: false,
                        actionsColumnIndex: -1,
                        selection: props.options
                            ? props.options.selection
                            : false,
                    }}
                    isLoading={props.isLoading}
                    components={{
                        Pagination: (paginat) => {
                            const { classes, headerIsHidden, ...other } =
                                paginat
                            return (
                                <>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        justify="flex-end"
                                    >
                                        <MTablePagination
                                            {...other}
                                            onChangePage={(e, page) =>
                                                props.onChangePage(page + 1)
                                            }
                                            page={props.filtro.pagina - 1}
                                            count={props.filtro.totalItens}
                                            rowsPerPage={props.pageSize || 100}
                                            localization={{
                                                labelRowsSelect: 'recursos',
                                                labelDisplayedRows:
                                                    '{from}-{to} de {count}',
                                            }}
                                        />
                                    </Grid>
                                </>
                            )
                        },
                    }}
                    actions={[
                       (rowData) =>({
                            icon: rowData.dataPagamento ? VisibilityIcon : EditIcon,
                            tooltip: rowData.dataPagamento ? 'Visualizar': 'Edição',
                            onClick: (event, rowData) => {
                                props.editar(rowData)
                                // verificação se adiantamento ja fui utilizado para editar
                                /*if (
                                    rowData.valorSemFormatacao !== rowData.saldo
                                ) {
                                    ToastHelper.error(
                                        'Você não pode editar um adiantamento já utilizado'
                                    )
                                } else {
                                    props.editar(rowData)
                                }*/
                            },
                        }),
                        (rowData) => ({
                            icon:
                                rowData.dataPagamento === ''
                                    ? MoneyOnIcon
                                    : MoneyOffIcon,
                            tooltip:
                                rowData.dataPagamento === ''
                                    ? 'Pagar adiantamento(s)'
                                    : 'Cancelar pagamento',
                            onClick: (event, data) => quitacao(data, 'pagamento')
                        }),
                        (rowData) => ({
                            icon: !rowData.dataCompensacao
                                ? ConciliadoIcon
                                : NConciliadoIcon,
                            tooltip:
                                rowData.dataCompensacao === ''
                                    ? 'Conciliar'
                                    : 'Cancelar Conciliação',
                            onClick: (event, rowData) => conciliar(rowData, 'conciliacao'),
                        }),
                        (rowData) => ({
                            icon: 'delete',
                            tooltip: 'Apagar',
                            onClick: (event, row) => confirmDelete(row),
                        }),
                    ]}
                    onOrderChange={(numeroColuna, orientacao) =>
                        props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                    }
                    onSelectionChange={(rows) =>
                        props.mudaContasSelecionadas(rows)
                    }
                    icons={{
                        Edit: Edit3Icon,
                        Add: (props) => {
                            return (
                                <AddIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        borderRadius: 100,
                                    }}
                                />
                            )
                        },
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro para exibir',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Edição rápida',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            nRowsSelected: '{0} contas(s) selecionadas',
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                        },
                    }}
                />
            </MuiThemeProvider>
            <Modal
                className={classes.modal}
                open={openModalQuitarAdiantamento}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={openModalQuitarAdiantamento}>
                        <ConteudoModalQuitarContas
                            closeModalQuitacao={(result) =>
                                closeModalQuitacao(result)
                            }
                            showModalQuitarContas={openModalQuitarAdiantamento}
                            buscarTudo={() => props.buscarTudo()}
                            adiantamento={adiantamento}
                        />
                    </Fade>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={openModalConfirmCancel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Fade in={openModalConfirmCancel}>
                        <ModalCancelar
                            tipo={adiantamento?.tipo}
                            close={() => setOpenModalConfirmCancel(false)}
                            cancelarQuitacao={cancelarQuitacao}
                            cancelarConciliacao={cancelarConciliacao}
                        />
                    </Fade>
                </div>
            </Modal>
            {openModalConfirmExclusion && 
                <ConfirmExclusionModal
                    identifier={adiantamento.documento}
                    close={() => setOpenModalConfirmExclusion(false)}
                    onConfirm={deletar}
                />
            }
        </>
    )
}
