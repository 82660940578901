import React from 'react'
import Table from '../../components/Table'
import { Card, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: '15px',
    },
    headerTitle: {
        borderBottom: 'solid 1px #E0E0E0',
        fontSize: '20px',
        fontWeight: '400',
    },
    headerText: {
        borderBottom: 'solid 1px #E0E0E0',
        fontSize: '18px',
        padding: '5px',
        fontWeight: '250',
    },
    gridHeader: {
        paddingLeft: '20px',
        paddingRight: '20px',
        marginBottom: '10px',
    },
}))

export default function Resumo(props) {
    const classes = useStyles()

    const revenueColors = {
        1: '#D7D7D7',
        2: '#F0F0F0',
        3: '#FFF',
    }

    const expenseColors = {
        1: '#D7D7D7',
        2: '#F0F0F0',
        3: '#FFF',
    }

    const columns = props.colunas.map((column) => {
        column.cellStyle = (_, rowData) => {
            const rowBackgroundColor =
                rowData.operacao === 'Crédito'
                    ? revenueColors[
                          rowData.tableData.path.length >= 3
                              ? 3
                              : rowData.tableData.path.length
                      ]
                    : rowData.operacao === 'Débito'
                    ? expenseColors[
                          rowData.tableData.path.length >= 3
                              ? 3
                              : rowData.tableData.path.length
                      ]
                    : '#fff'

            return {
                backgroundColor: rowBackgroundColor,
                borderColor: rowBackgroundColor,
            }
        }

        return column
    })

    return (
        <Card className={classes.card}>
            <Table
                title={''}
                removeToolbar={true}
                draggable={false}
                columns={columns}
                data={props.data}
                paging={false}
                emptyRowsWhenPaging={false}
                haveParentChild={true}
            />
        </Card>
    )
}
