import { SET_FILTRO_LISTAGEM_GESTAO_CONTRATO } from '../actions/gestaoContrato';

export const storageGestaoContrato = 'gestaoContrato';

const initialState = {
    filtroListagem: {
        fazendaId: JSON.parse(localStorage.getItem('fazenda')) ? JSON.parse(localStorage.getItem('fazenda')).value : undefined,

    },
};

export const gestaoContrato = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_GESTAO_CONTRATO:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
