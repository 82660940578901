import React, { useState } from 'react'
import MaterialTable from 'material-table'

export default function TabelaContatos(props) {
    const [contatosColunas] = useState([
        { title: 'Nomes', field: 'nome' },
        { title: 'E-mail', field: 'email' },
        { title: 'Telefone', field: 'telefone' },
        { title: 'Celular', field: 'celular' },
    ])

    return (
        <MaterialTable
            title="Contatos"
            columns={contatosColunas}
            data={props.contatos}
            options={{
                paging: false,
                rowStyle: {
                    fontSize: 14,
                },
                padding: 'dense',
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
            }}
            localization={{
                header: {
                    actions: 'Ações',
                },
                body: {
                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                    addTooltip: 'Adicionar',
                    deleteTooltip: 'Excluir',
                    editTooltip: 'Edição rápida',
                    editRow: {
                        deleteText: 'Deseja excluir essa linha?',
                        cancelTooltip: 'Cancelar',
                        saveTooltip: 'Confirmar',
                    },
                },
                toolbar: {
                    searchTooltip: 'Pesquisar',
                    searchPlaceholder: 'Pesquisar',
                },
            }}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            props.setContatos([...props.contatos, newData])

                            resolve()
                        }, 1000)
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...props.contatos]
                            const index = oldData.tableData.id
                            dataUpdate[index] = newData
                            props.setContatos([...dataUpdate])

                            resolve()
                        }, 1000)
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataDelete = [...props.contatos]
                            const index = oldData.tableData.id
                            dataDelete.splice(index, 1)
                            props.setContatos([...dataDelete])

                            resolve()
                        }, 1000)
                    }),
            }}
        />
    )
}
