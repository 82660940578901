import {
    Button,
    FormControl,
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core'
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { ConversaoDeUnidadeValidate } from './conversaoDeUnidade.validate'
import React from 'react'
import { TabelaConversao } from './tabelaConversao'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
}))

const columns = [
    { title: 'Unidade de Medida', field: 'unidadeConvertida' },
    { title: 'Valor de Conversão', field: 'valor' },
]

export const ConversaoDeUnidade = ({
    show,
    unidadeConversao,
    handleAddUnitConversion,
    handleRemoveUnitConversion,
    options,
    visualizar,
}) => {
    const classes = useStyles()

    const [valor, setValor] = useState('')
    const [unidadeConvertida, setUnidadeConvertida] = useState('')
    const [errors, setErrors] = useState({})

    const onSubmit = () => {
        ConversaoDeUnidadeValidate.validate(
            {
                unidadeConvertida,
                valor,
            },
            { abortEarly: false }
        )
            .then(() => {
                const newData = {
                    unidadeConvertida: unidadeConvertida,
                    unidadeConvertidaId: options.find(
                        (x) => x.sigla === unidadeConvertida
                    )?.id,
                    valor: Number(valor),
                }

                handleAddUnitConversion(newData)

                setValor('')
                setUnidadeConvertida('')
                setErrors({})
            })
            .catch((err) => {
                const validationErrors = {}

                err.inner.forEach((error) => {
                    validationErrors[error.path] = error
                })

                setErrors(validationErrors)
            })
    }

    const handleEditUnitConversion = (data) => {
        handleRemoveUnitConversion(data)

        setValor(data.valor)
        setUnidadeConvertida(data.unidadeConvertida)
    }

    return (
        show && (
            <>
                {!visualizar && (
                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={3} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    options={options}
                                    getOptionLabel={(option) => option?.sigla}
                                    onChange={(_, value) => {
                                        setErrors({
                                            ...errors,
                                            unidadeConvertida: undefined,
                                        })
                                        setUnidadeConvertida(value?.sigla || '')
                                    }}
                                    onInputChange={(_, value) => {
                                        setErrors({
                                            ...errors,
                                            unidadeConvertida: undefined,
                                        })
                                        setUnidadeConvertida(value || '')
                                    }}
                                    inputValue={unidadeConvertida}
                                    name="unidadeConvertida"
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Unidade de Medida:*"
                                            variant="standard"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="Selecione"
                                        />
                                    )}
                                    autoSelect
                                />

                                <p className={classes.errors}>
                                    {errors.unidadeConvertida &&
                                        errors.unidadeConvertida?.message}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={4} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    type="number"
                                    label="Valor de conversão:*"
                                    className={classes.textField}
                                    id="valor"
                                    name="valor"
                                    isplaceholderanimated={(false, 'top')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={valor}
                                    onChange={(e) => {
                                        setErrors({
                                            ...errors,
                                            valor: undefined,
                                        })
                                        setValor(e.target.value)
                                    }}
                                />
                                <p className={classes.errors}>
                                    {errors.valor && errors.valor?.message}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={3}
                            display="flex"
                            alignItems="center"
                            alignContent="center"
                        >
                            <FormControl className={classes.formControl}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={onSubmit}
                                >
                                    Adicionar
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container item xs={12} display="flex">
                    <TabelaConversao
                        columns={columns}
                        data={unidadeConversao}
                        actions={
                            !visualizar && [
                                {
                                    icon: <EditIcon />,
                                    tooltip: 'Editar',
                                    onClick: (_, rowData) =>
                                        handleEditUnitConversion(rowData),
                                },
                                {
                                    icon: <DeleteIcon />,
                                    tooltip: 'Deletar',
                                    onClick: (_, rowData) =>
                                        handleRemoveUnitConversion(rowData),
                                },
                            ]
                        }
                    />
                </Grid>
            </>
        )
    )
}
