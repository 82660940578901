import React, { useState, useEffect } from 'react'
import {
    Modal,
    Backdrop,
    Grid,
    TextField,
    FormControl,
    Button,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { ContaGerencialEsquema } from './validate/ModalCadastroContaGerencialCompra.validate'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ContaGerencialService from '../../../services/ContaGerencialService'
import ToastHelper from '../../../utils/toastHelper'
import ObjectHelper from '../../../utils/objetcHelper'

const operacoesTipo = [
    {
        label: 'Crédito',
        value: 0,
    },
    {
        label: 'Débito',
        value: 1,
    },
]

export default function ModalCadastroContaGerencial(props) {
    const classes = useStyles()
    const [contasPai, setContasPai] = useState([])
    const [contaPai, setContaPai] = useState({})
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            id: '',
            descricao: '',
            contaPai: undefined,
        },
        resolver: yupResolver(ContaGerencialEsquema),
    })

    const [operacaoSelecionada, setOperacaoSelecionada] = useState({})
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

    const buscarContasGerenciais = async () => {
        let novoFiltro = { fazendaId, ativo: true }
        novoFiltro.decrescente = false
        novoFiltro.ordem = 'Descricao'
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.operacao = props.tipo
        let params = await ObjectHelper.createParams(novoFiltro)
        await ContaGerencialService.buscarTudo(params).then(
            async (response) => {
                let contasPai =
                    await ObjectHelper.getValuesContaPaiParseToSelect(
                        response.data.itens
                    )
                await setContasPai(contasPai)
            }
        )
    }

    useEffect(() => {
        async function init() {
            await buscarContasGerenciais()
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = fazendaId
        if (contaPai) {
            newData.paiId = contaPai ? contaPai.value : ''
        }
        newData.operacao = operacaoSelecionada ? operacaoSelecionada.value : ''
        newData.numero = ''
        newData.ativo = true
        newData.item = parseInt(newData.item)

        await ContaGerencialService.cadastrar(newData)
            .then((response) => {
                if (response.status === 200) {
                    props.buscarContasGerenciaisPadrao()
                    props.selecionarCadastrado(response.data)
                    props.close()
                } else ToastHelper.error(response.statusText)
            })
            .catch(function (error) {
                if (error.response) {
                    // Solicitação feita com resposta do servidor
                    ToastHelper.error(error.response.data)
                }
            })
    }

    const setCp = (conta) => {
        if (conta) {
            setContaPai(conta)
            setOperacaoSelecionada(conta.operacao)
        } else setContaPai({})
    }

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>
                <h2 className={classes.title}>
                    {`Cadastro de Conta Gerencial Padrão de ${props.tipoTitulo}`}
                </h2>

                <Grid container item xs={12} display="flex">
                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={6} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    type="text"
                                    label="Descrição*"
                                    className={classes.textField}
                                    inputRef={register}
                                    id="descricao"
                                    name="descricao"
                                    isplaceholderanimated={(false, 'top')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <p className={classes.errors}>
                                    {errors.descricao
                                        ? errors.descricao.message
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>

                        <Grid container item xs={3} display="flex">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    type="number"
                                    label="Item*"
                                    className={classes.textField}
                                    inputRef={register}
                                    id="item"
                                    name="item"
                                    isplaceholderanimated={(false, 'top')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <p className={classes.errors}>
                                    {errors.item ? errors.item.message : ''}
                                </p>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} display="flex">
                        <Grid container item xs={6} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    name="contaPai"
                                    id="contaPai"
                                    inputRef={register}
                                    options={contasPai}
                                    value={contaPai}
                                    getOptionLabel={(option) => option.label}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setCp(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={register}
                                            name="contaPai"
                                            id="contaPai"
                                            variant="standard"
                                            label="Conta Pai*"
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {errors.contaPai
                                        ? errors.contaPai.message
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={3} display="flex">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    name="operacao"
                                    id="operacao"
                                    inputRef={register}
                                    disabled={true}
                                    options={operacoesTipo}
                                    value={operacaoSelecionada}
                                    getOptionLabel={(option) => option.label}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setOperacaoSelecionada(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={register}
                                            name="operacao"
                                            id="operacao"
                                            variant="standard"
                                            label="Operação*"
                                            placeholder="Selecione"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <p className={classes.errors}>
                                    {errors.operacao
                                        ? errors.operacao.message
                                        : ''}
                                </p>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={classes.margin}
                    >
                        <Button
                            className={classes.button2}
                            variant="contained"
                            size="small"
                            onClick={props.close}
                        >
                            Cancelar
                        </Button>
                        <div className={classes.margin} />
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Cadastrar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlDescricao: {
        margin: theme.spacing(1),
        minWidth: '90%',
        marginBottom: theme.spacing(2),
    },
    formControlCategoria: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
    title: {
        color: '#2E7D32',
    },
}))
