import service from './service'

export default {
    buscarTudo() {
        return service.get(`/Fazenda`)
    },
    buscarPorId(fazendaId) {
        return service.get(`/Fazenda/${fazendaId}`)
    },
}
