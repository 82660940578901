import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { CentroCustoEsquema } from './CentroCusto.validate'
import CentroCustoService from '../../services/CentroCustoService'
import ToastHelper from '../../utils/toastHelper'
import CentroNegocioService from '../../services/CentroNegocioService'
import ObjectHelper from '../../utils/objetcHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
}))

const tipos = [
    { value: 0, label: 'Produtivo' },
    { value: 1, label: 'Rateado' },
    { value: 2, label: 'Indireto' },
]

export const CentroCustoCadastro = (props) => {
    const {
        match: { params },
    } = props
    let history = useHistory()
    const classes = useStyles()

    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [tipo, setTipo] = useState({ value: '', label: '' })
    const [centroNegocio, setCentroNegocio] = useState({
        value: '',
        label: '',
    })
    const [opcoesCentroNegocio, setOpcoesCentroNegocio] = useState([])

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            descricao: '',
            codigo: '',
            natureza: '',
            tipo: '',
            ativo: true,
        },
        resolver: yupResolver(CentroCustoEsquema),
    })

    useEffect(() => {
        buscarCentroNegocio()
        if (params.id) buscarParaEdicao(params.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarCentroNegocio = async () => {
        let filtro = {}
        filtro.fazendaId = FazendaId
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        await CentroNegocioService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                setOpcoesCentroNegocio(
                    ObjectHelper.getValuesParseToSelect(response.data.itens)
                )
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarParaEdicao = (caixaId) => {
        CentroCustoService.buscarPorId(caixaId).then((response) => {
            if (response.status === 200) {
                setValue('id', response.data.id)
                setValue('codigo', response.data.codigo)
                setValue('natureza', response.data.natureza)
                setValue('descricao', response.data.descricao)
                setTipo({
                    label: response.data.tipoNome,
                    value: response.data.tipo,
                })
                if (response.data.centroNegocio) {
                    setCentroNegocio({
                        value: response.data.centroNegocio.id,
                        label: response.data.centroNegocio.nome,
                    })
                }
            } else ToastHelper.error(response.statusText)
        })
    }

    const onSubmit = async (dados) => {
        dados.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value
        dados.tipo = tipo.value
        dados.centroNegocioId = centroNegocio ? centroNegocio.value : null
        if (params.id) {
            dados.id = params.id
            await CentroCustoService.atualizar(dados)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Dados atualizados!')
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response.data.errors) {
                        const message = Object.values(error.response.data.errors)[0][0]

                        ToastHelper.error(message)
                    } else if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
        } else {
            await CentroCustoService.cadastrar(dados)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Centro de custo cadastrado com sucesso!'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response.data.errors) {
                        const message = Object.values(error.response.data.errors)[0][0]

                        ToastHelper.error(message)
                    } else if (error.response) {
                        // Solicitação feita com resposta do servidor

                        ToastHelper.error(error.response.data)
                    } else if (error.request) {
                        // Solicitação feita sem resposta do servidor
                    } else {
                        // Erro na solicitação
                    }
                })
        }
    }

    return (
        <Page
            className={classes.root}
            titulo={
                params.id
                    ? 'Editar Centro de Custo'
                    : 'Cadastro de Centro de Custo'
            }
        >
            <Container maxWidth={'xl'}>
                <Titulo
                    titulo={
                        params.id
                            ? 'Editar Centro de Custo'
                            : 'Cadastro de Centro de Custo'
                    }
                />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item md={12} display="flex">
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Código*"
                                            inputRef={register}
                                            id="codigo"
                                            name="codigo"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.codigo
                                                ? errors.codigo.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            label="Descrição*"
                                            inputRef={register}
                                            id="descricao"
                                            name="descricao"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="tipo"
                                            id="tipo"
                                            inputRef={register}
                                            options={tipos}
                                            value={tipo}
                                            defaultValue={tipos.filter(
                                                (x) => x === tipo?.value
                                            )}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setTipo(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="tipo"
                                                    id="tipo"
                                                    variant="standard"
                                                    label="Tipo*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                        <p className={classes.errors}>
                                            {errors.tipo
                                                ? errors.tipo.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item md={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="centroNegocio"
                                            id="centroNegocio"
                                            inputRef={register}
                                            options={opcoesCentroNegocio}
                                            value={centroNegocio}
                                            defaultValue={
                                                centroNegocio
                                                    ? opcoesCentroNegocio.filter(
                                                          (x) =>
                                                              x ===
                                                              centroNegocio.value
                                                      )
                                                    : null
                                            }
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setCentroNegocio(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="centroNegocio"
                                                    id="centroNegocio"
                                                    variant="standard"
                                                    label="Centro de negócio"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
