import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import Titulo from '../../components/Titulo/index';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FooterBottons from '../../components/FooterBottons/FooterBottons';
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { CentroNegocioEsquema } from './CentroNegocio.validate';
import CentroNegocioService from '../../services/CentroNegocioService';
import ToastHelper from '../../utils/toastHelper';
import GerenciadorDeCentroDeCusto from './CentroNegocioListagemCentroCusto';
import CentroCustoService from '../../services/CentroCustoService';
import ObjectHelper from '../../utils/objetcHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '97%',
    },
}));

export const CentroNegocioCadastro = (props) => {
    const {
        match: { params },
    } = props;

    let history = useHistory();
    const classes = useStyles();

    const [render, setRender] = useState(false);
    const [opcoesCentroCusto, setOpcoesCentroCusto] = useState([]);
    const [centroCustoSelecionados, setCentroCustoSelecionados] = useState([]);

    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            descricao: '',
            codigo: '',
            natureza: '',
            tipo: '',
            ativo: true,
        },
        resolver: yupResolver(CentroNegocioEsquema),
    });

    useEffect(() => {
        async function init() {
            await buscarCentrosDeCusto(params.id);
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buscarCentrosDeCusto = async (centroNegocioId) => {
        let filtro = {};
        filtro.tamanhoPagina = 99999
        filtro.fazendaId = JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : null;
        filtro.CentroNegocioId = centroNegocioId || null;
        let params = await ObjectHelper.createParams(filtro);

        await CentroCustoService.BuscarParaCentroNegocio(params).then(
            async (response) => {
                if (response.status === 200) {
                    let dados = await ObjectHelper.getValuesDescricaoLabelParseToSelect(
                        response.data.itens,
                    );
                    await setOpcoesCentroCusto(dados);

                    if (centroNegocioId) {
                        await buscarParaEdicao(centroNegocioId, dados);
                    }
                    if (!centroNegocioId) {
                        setRender(true);
                    }
                } else {
                    ToastHelper.error(response.statusText);
                }
            },
        );
    };

    const buscarParaEdicao = (centroNegocioId, opcoesCentroCusto) => {
        CentroNegocioService.buscarPorId(centroNegocioId).then(
            async (response) => {
                if (response.status === 200) {
                    let newList = [];
                    let newOpcoes = [...opcoesCentroCusto];
                    if (response.data.centrosCusto.length > 0) {
                        await response.data.centrosCusto.map((item) => {
                            newList.push({
                                label: item.descricao,
                                value: item.id,
                            });
                            newOpcoes.map((centroCusto) => {
                                if (item.id === centroCusto.value) {
                                    let index = newOpcoes.indexOf(centroCusto);
                                    newOpcoes.splice(index, 1);
                                }
                                return centroCusto
                            });
                            return item
                        });
                        await setOpcoesCentroCusto(newOpcoes);
                        await setCentroCustoSelecionados(newList);
                    }
                    await setRender(true);
                    await setValue('id', response.data.id);
                    await setValue('nome', response.data.nome);
                    await setValue('observacao', response.data.observacao);
                } else ToastHelper.error(response.statusText);
            },
        );
    };

    const onSubmit = async (dados) => {
        dados.fazendaId = JSON.parse(localStorage.getItem('fazenda')).value;
        dados.centrosCusto = centroCustoSelecionados;
        if (params.id) {
            dados.id = params.id;
            await CentroNegocioService.atualizar(dados)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Centro de negócio atualizado!');
                        history.goBack();
                    } else ToastHelper.error(response.statusText);
                })
                .catch(function (error) {
                    if (error.response) {
                        ToastHelper.error(error.response.data);
                    }
                });
        } else {
            await CentroNegocioService.cadastrar(dados)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Centro de negócio cadastrado com sucesso!',
                        );
                        history.goBack();
                    } else ToastHelper.error(response.statusText);
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data);
                    } else if (error.request) {
                        // Solicitação feita sem resposta do servidor
                    } else {
                        // Erro na solicitação
                    }
                });
        }
    };

    return (
        <Page
            className={classes.root}
            titulo={
                params.id
                    ? 'Editar Centro de Negócio'
                    : 'Cadastro de Centro de Negócio'
            }
        >
            <Container maxWidth={'xl'}>
                <Titulo
                    titulo={
                        params.id
                            ? 'Editar Centro de Negócio'
                            : 'Cadastro de Centro de Negócio'
                    }
                />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            {render && (
                                <Grid
                                    container
                                    item
                                    md={12}
                                    display="inline-flex"
                                >
                                    <Grid container item md={5} display="flex">
                                        <Grid
                                            container
                                            item
                                            md={12}
                                            style={{ marginTop: '20%' }}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <TextField
                                                    type="text"
                                                    label="Nome:*"
                                                    className={
                                                        classes.textField
                                                    }
                                                    inputRef={register}
                                                    id="nome"
                                                    name="nome"
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.nome
                                                        ? errors.nome.message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={
                                                    classes.formControlObs
                                                }
                                            >
                                                <TextField
                                                    type="text"
                                                    label="Observação"
                                                    className={
                                                        classes.textField
                                                    }
                                                    inputRef={register}
                                                    id="observacao"
                                                    name="observacao"
                                                    multiline
                                                    variant="outlined"
                                                    rows={3}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item md={7} display="flex">
                                        <GerenciadorDeCentroDeCusto
                                            opcoesCentroCusto={
                                                opcoesCentroCusto
                                            }
                                            centroDeCustoVinculados={
                                                centroCustoSelecionados
                                            }
                                            setCentroCustoSelecionados={
                                                setCentroCustoSelecionados
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    );
};
