import FornecedorClienteService from 'services/FornecedorClienteService'
import ObjectHelper from 'utils/objetcHelper'
import UnidadeService from 'services/UnidadeDeMedidaService'
import SafraService from 'services/SafraService'
import AdiantamentoService from 'services/AdiantamentoFornecedoresClientesService'
import ContaBancariaService from 'services/ContaBancariaService'
import TipoPagamentoRecebimentoService from 'services/TipoPagamentoRecebimentoService'

const FAZENDA = JSON.parse(localStorage.getItem('fazenda'))
export default class AdiantamentoRequests {

    static ativarDesativar = async (id) => {
        const { data } = await AdiantamentoService.ativarDesativar(id)
        return data
    }

    static cancelarQuitacao = async (id) => {
        const data = await AdiantamentoService.cancelarQuitacao(id)
        return data
    }

    static gerarDocumento = async () => {
        const { data } = await AdiantamentoService.gerarDocumento(FAZENDA.value)
        return data
    }

    static deletar = async (id) => {
        const { data } = await AdiantamentoService.deletar(id)
        return data
    }

    static cadastrar = async (dados) => {
        dados.fazendaId = FAZENDA.value
        let data = {}
        if (dados.id) {
            data = await AdiantamentoService.atualizar(dados)
            return data
        } else {
            let cadastro = await AdiantamentoService.cadastrar(dados)
            return cadastro
        }
    }

    static buscarSafra = async () => {
        const { data } = await SafraService.buscarSafras(FAZENDA.value)
        return data
    }

    static buscarUnidades = async () => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        const { data } = await UnidadeService.buscarTudo(params)
        return data.itens
    }

    static buscarFornecedoresClientes = async (TipoFornecedorCliente) => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value
        filtro.tamanhoPagina = 99999
        filtro.ativo = true
        if (TipoFornecedorCliente) {
            filtro.TipoFornecedorCliente =
                TipoFornecedorCliente.toUpperCase() === 'CLIENTE' ? 1 : 0
            let params = await ObjectHelper.createParams(filtro)
            const { data } = await FornecedorClienteService.buscarTudo(params)
            return ObjectHelper.removeInativos(
                ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(data.itens)
            )
        } else {
            let params = await ObjectHelper.createParams(filtro)
            const { data } = await FornecedorClienteService.buscarTudo(params)
            return ObjectHelper.removeInativos(
                ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(data.itens)
            )
        }
    }

    static buscarPorId = async (id) => {
        const { data } = await AdiantamentoService.buscarPorId(id)
        return data
    }

    static buscarContasBancarias = async () => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value;
        filtro.ativo = true;
        filtro.tamanhoPagina = 9999;
        let params = await ObjectHelper.createParams(filtro)
        let { data } = await ContaBancariaService.buscarTudo(params);
        return  ObjectHelper.getValuesDescricaoLabelParseToSelect(
            data.itens
        )
    }

    static buscarFormasPagamento = async () => {
        let filtro = {}
        filtro.fazendaId = FAZENDA.value;
        filtro.ativo = true;
        filtro.tamanhoPagina = 999;
        let params = await ObjectHelper.createParams(filtro);
        let { data } = await TipoPagamentoRecebimentoService.buscarTudo(params);
        return  ObjectHelper.getValuesFormaPagamentoParseToSelect(
            data.itens
        )
    }

    static cancelarConciliacao = async (id) => {
        let result = await AdiantamentoService.cancelarConciliacao(id)
        return result;
    }
}
