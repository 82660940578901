import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import TipoPagamentoRecebimentoListagem from './TipoPagamentoRecebimentoListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import TipoPagamentoRecebimentoService from '../../services/TipoPagamentoRecebimentoService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const TipoPagamentoRecebimento = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [loading, setLoading] = useState(false)
    const [tiposPagamentoRecebimento, setTiposPagamentoRecebimento] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.caixa.filtroListagem)
    )

    useEffect(() => {
        let novoFiltro = { ...filtro }
        novoFiltro.tamanhoPagina = 100
        buscarTipoPagamentoRecebimento(novoFiltro)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CAIXA',
            filtroListagem: filtro,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleFiltro = (filtro) => {
        filtro.ativo = true
        buscarTipoPagamentoRecebimento(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CAIXA',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        props.history.push({
            pathname: `/app/tipopagamentorecebimento/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarTipoPagamentoRecebimento(novoFiltro)
    }

    const buscarTipoPagamentoRecebimento = async (filtro) => {
        filtro.fazendaId = FazendaId
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        await TipoPagamentoRecebimentoService.buscarTudo(params).then(
            (response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.fazendaId = FazendaId
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    newFiltro.ativo = true
                    alteraFiltro(newFiltro)
                    setTiposPagamentoRecebimento(response.data.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const editar = (itemId) => {
        history.push(`/app/tipopagamentorecebimento/editar/${itemId}`)
    }

    const deletar = async (item) => {
        try {
            await TipoPagamentoRecebimentoService.deletar(item.id).then(
                (response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Forma de pagamento/recebimento excluida com sucesso!')
                        let novoFiltro = { ...filtro }
                        buscarTipoPagamentoRecebimento(novoFiltro)
                    } else {
                        ToastHelper.error(response.statusText)
                        setLoading(false)
                    }
                }
            )
        } catch (error) {
            ToastHelper.error(error.response.data)
        }
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarTipoPagamentoRecebimento(novoFiltro)
    }

    const editarCelula = async (novoValor, valorAntigo, linha, coluna) => {
        var dados = tiposPagamentoRecebimento
        dados[linha.tableData.id][coluna.field] = novoValor

        await TipoPagamentoRecebimentoService.atualizar(
            dados[linha.tableData.id]
        ).then((response) => {
            if (response.status === 200) {
                ToastHelper.success(
                    'Forma de pagamento/recebimento atualizada com sucesso!'
                )
                let novoFiltro = { ...filtro }
                buscarTipoPagamentoRecebimento(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    return (
        <Page className={classes.root} title="Forma de Pagamento/Recebimento">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Forma de Pagamento/Recebimento" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                />
                <Box mt={3}>
                    <TipoPagamentoRecebimentoListagem
                        data={tiposPagamentoRecebimento}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        editarCelula={editarCelula}
                    />
                </Box>
            </Container>
        </Page>
    )
}
