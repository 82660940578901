import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import FiltroSimples from 'components/BasicPageTopBar/FiltroSimples'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { Divider, Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '10px',
        marginBottom: '10px',
    },
}))

const ToolbarTipoPatrimonio = ({ handleFiltro, filtroInicial, className, ...props }) => {
    const classes = useStyles();
    const [filtro, setFiltro] = useState(filtroInicial);
    const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded);

    useEffect(() => {
        setFiltro(filtroInicial);
    }, [filtroInicial])

    const setStateAcocordion = () => {
        let newFiltro = { ...filtro }
        newFiltro.filtroExpanded = !filtroExpanded
        setFiltro(newFiltro)
        setFiltroExpanded(!filtroExpanded)
    }

    const changeFiltro = (campo, event) => {
        let newFiltro = { ...filtro }
        newFiltro[campo] = event.target.value
        setFiltro(newFiltro)
    }

    const submit = (event) => {
        handleFiltro(filtro)
    }

    return (
        <Box mt={3}>
            <Accordion
                expanded={filtroExpanded}
                onChange={(e) => setStateAcocordion}
            >
                <AccordionSummary
                    expandIcon={
                        !props.temFiltroAvancado ? '' : <ExpandMoreIcon />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={() => setStateAcocordion(!filtroExpanded)}
                >
                    <FiltroSimples
                        filtro={filtro}
                        changeFiltro={changeFiltro}
                        submit={submit}
                        temFiltroAvancado={props.temFiltroAvancado}
                        clickadd={props.clickadd}
                    />
                </AccordionSummary>
                {props.temFiltroAvancado && (
                    <AccordionDetails>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label={'Data de Referência Início:'}
                                            onChange={(e) => {
                                                setFiltro({
                                                    ...filtro,
                                                    dataReferenciaInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Referência Fim:"
                                            onChange={(e) => {
                                                setFiltro({
                                                    ...filtro,
                                                    dataReferenciaFim:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.opcoesSituacao}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            onChange={(event, option) => {
                                                setFiltro({
                                                    ...filtro,
                                                    situacao: option
                                                        ? option.value
                                                        : '',
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Situação'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.opcoesTipoPatrimonio || []}
                                            getOptionLabel={(option) =>
                                                option.nome
                                            }
                                            onChange={(event, option) => {
                                                setFiltro({
                                                    ...filtro,
                                                    TipoPatrimonioId: option
                                                        ? option.id
                                                        : '',
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Tipo de Patrimônio'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Divider className={classes.divider} />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                    className={classes.margin}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={(e) => submit(e)}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
            </Accordion>
        </Box>
    )
}

export default ToolbarTipoPatrimonio
