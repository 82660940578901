import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/LocaisEstoque/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/LocaisEstoque${param}`);
    },
    buscarPorFazendaId(param) {
        return service.get(`/LocaisEstoque/BuscarPorFazendaId/${param}`);
    },
    cadastrar(param) {
        return service.post(`/LocaisEstoque`, param);
    },
    atualizar(param) {
        return service.put(`/LocaisEstoque`, param);
    },
    ativarDesativar(id) {
        return service.put(`/LocaisEstoque/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/LocaisEstoque/${id}`);
    },
    buscarTipoEstoque(id){
        return service.get(`/LocaisEstoque/BuscarTipoEstoque/${id}`);
    }
};
