import { ImportacaoOrcamento } from '../pages/orcamento'

export const ImportacaoOrcamentoRoute = [
    {
        path: '/app/importacaoorcamento',
        key: 'APP_IMPORTACAO_ORCAMENTO',
        exact: true,
        component: ImportacaoOrcamento,
    },
]
