import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Backdrop, Grid, TextField, FormControl, Button, makeStyles }from '@material-ui/core';
import { schema as schemaValidate } from './validate/ModalCadastroCentroCusto.validate';
import { yupResolver } from '@hookform/resolvers';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ModalCadastroCentroCusto(props) {

    const classes = useStyles()

    const { register, handleSubmit, setValue, getValues, errors, clearErrors} = useForm({
        defaultValues: {
            codigo: "",
            descricao: "",
            tipo: null,
        },
        resolver: yupResolver(schemaValidate),
    })

    const tipos = [
        { value: 0, label: 'Produtivo' },
        { value: 1, label: 'Rateado' },
        { value: 2, label: 'Indireto' },
    ]

    const onSubmit = () => {
        var values = getValues()
        props.submit(values)
    }

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
                <div className={classes.paper}>


                        <h2 className={classes.title}>Cadastro de centro de custo</h2>

                        <Grid container item xs={12} display="flex">

                            <Grid container item xs={12}>

                                <Grid container item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            inputRef={register}
                                            id="codigo"
                                            name="codigo"
                                            variant="standard"
                                            label="Código*"
                                            placeholder="Digite o código"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.codigo
                                                ? errors.codigo.message
                                                : ''}
                                        </p>

                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <Autocomplete
                                            id="tipo"
                                            name="tipo"
                                            options={tipos}
                                            getOptionLabel={(option) =>
                                                option
                                                    ? option.label
                                                    : ''
                                            }
                                            onChange={(_, data) => {
                                                setValue("tipo", data)
                                                clearErrors("tipo")
                                            }}
                                            size="small"
                                            getOptionSelected={(option,value) =>
                                                option?.value ===
                                                value?.value
                                            }
                                            renderInput={(
                                                params
                                            ) => (
                                                <TextField
                                                    inputRef={register}
                                                    {...params}
                                                    id="tipo"
                                                    name="tipo"
                                                    variant="standard"
                                                    label="Tipo*"
                                                    placeholder="Selecione o tipo"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.tipo
                                                ? errors.tipo.message
                                                : ''}
                                        </p>

                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12}>
                                <FormControl className={classes.formControlDescricao}>
                                    <TextField
                                        inputRef={register}
                                        id={"descricao"}
                                        name={"descricao"}
                                        variant="standard"
                                        label="Descrição*"
                                        placeholder="Digite a descrição"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <p className={classes.errors}>
                                        {errors.descricao
                                            ? errors.descricao.message
                                            : ''}
                                    </p>

                                </FormControl>
                            </Grid>

                            <Grid
                                xs={12}
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.margin}
                            >
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    size="small"
                                    onClick={props.close}
                                >
                                    Cancelar
                                </Button>
                                <div className={classes.margin} />
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type='submit'
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Cadastrar
                                </Button>
                            </Grid>

                        </Grid>

                </div>
        </Modal>
    );
}


const useStyles = makeStyles((theme) => ({
    title: {
        color: '#2E7D32',
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlDescricao: {
        margin: theme.spacing(1),
        minWidth: '90%',
        marginBottom: theme.spacing(2)
    },
    formControlCategoria: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
}))
