import * as Yup from 'yup'

export const produtoEsquema = Yup.object().shape({
    categoria: Yup.object().required('Campo obrigatório').nullable(),
    nome: Yup.string().required('Campo obrigatório'),
    unidadeDeMedida: Yup.string().required('Campo obrigatório'),
    produtoAcabado: Yup.bool().nullable(),
    eProdutoTecnico: Yup.bool().nullable(),
    contaGerencialPadraoCompra: Yup.object()
        .nullable()
        .when('possuiControleDeEstoque', {
            is: 'estoque',
            then: Yup.object().nullable(),
        }),
    contaGerencialPadraoVenda: Yup.object()
        .nullable()
        .when('produtoAcabado', {
            is: true,
            then: Yup.object().required('Campo obrigatório'),
        }),
    localDeEstoquePadrao: Yup.object()
        .nullable()
        .when('possuiControleDeEstoque', {
            is: 'estoque',
            then: Yup.object().nullable().required('Campo obrigatório'),
        }),
    produtoTecnicoRelacionado: Yup.object()
        .nullable()
        .when('eProdutoTecnico', {
            is: true,
            then: Yup.object().nullable().required('Campo obrigatório'),
        }),
    possuiControleDeEstoque: Yup.string(),
    quantidadeMinima: Yup.string().when('possuiControleDeEstoque', {
        is: 'estoque',
        then: Yup.string().nullable().test(
            'val',
            'Precisa ser maior que zero',
            function (item) {
                var itemFormatado = parseFloat(
                    item.replaceAll('.', '').replace(',', '.').replace('R$', '')
                )
                if (item && itemFormatado <= 0) {
                    return false
                } else {
                    return true
                }
            }
        ),
    }),
    //deixando essa validação comentada para ser usada como exemplo futuramente.
    // codigo: Yup.string().test('test-valid-code', "Este código já foi cadastrado", async(value) => {
    //     var fazendaId = JSON.parse(localStorage.getItem('fazenda')).value ?? '';
    //     if(!!value && value !== "" && value !==  " ") {
    //         var { data } = await ProdutosServicosService.isValidCode(fazendaId, value);
    //         if(data === true)
    //             return true;
    //         else
    //             return false;
    //     }
    //     return true;
    // }).nullable()
})

export const servicoEsquema = Yup.object().shape({
    categoria: Yup.object().required('Campo obrigatório').nullable(),
    nome: Yup.string().required('Campo obrigatório'),
})
