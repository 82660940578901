import React from 'react';
import { useForm } from 'react-hook-form'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'
import { Button, Divider, makeStyles, Grid } from '@material-ui/core'




export const  ConfirmExclusionModal = (props) => {

    const classes = useStyles()
    const {handleSubmit} = useForm({});


    return (
        <Modal
                open={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div>
                    <Fade in={true}>
                        <>
                            <div className={classes.paper}>
                                <h2 className={classes.title}>
                                    Deseja realmente excluir este adiantamento {props.identifier}?
                                </h2>

                                <form className={classes.form} onSubmit={handleSubmit(props.onConfirm)}>
                                    <Divider className={classes.divider} />
                                    <Grid
                                        xs={12}
                                        container
                                        item
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center"
                                    >
                                        <Button
                                            onClick={() => {
                                                props.close()
                                            }}
                                            className={classes.button2}
                                            variant="contained"
                                            color="secundary"
                                            size="small"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.button}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            Confirmar
                                        </Button>
                                    </Grid>
                                </form>
                            </div>
                        </>
                    </Fade>
                </div>
            </Modal>
    );


}


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 5, 5),
        marginTop: 200,
        marginLeft: 500,
        marginRight: 500,
    },
    
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
   
}))