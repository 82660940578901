import * as Yup from 'yup';

export const CentroCustoEsquema = Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório'),
    tipo: Yup.string().required('Campo obrigatório'),
});

export const CentroCustoEsquemaSemTipo = Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório'),
});
