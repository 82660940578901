import { SET_FILTRO_LISTAGEM_CONTA_RECEBER } from '../actions/contaReceber'

export const storageContaReceber = 'contaReceber'

const initialState = {
    filtroListagem: {
        fazendaId: JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : undefined,
        tamanhoPagina: 100,
        pagina: 1,
    },
}

export const contaReceber = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_CONTA_RECEBER:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            }
        default:
            return state
    }
}
