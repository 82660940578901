import service from './service';

export default {
    buscarPorId(id) {
        return service.get(`/FornecedorCliente/${id}`);
    },
    buscarTudo(param) {
        return service.get(`/FornecedorCliente${param}`);
    },
    cadastrar(param) {
        return service.post(`/FornecedorCliente`, param);
    },
    atualizar(param) {
        return service.put(`/FornecedorCliente`, param);
    },
    ativarDesativar(id) {
        return service.put(`/FornecedorCliente/AtivarDesativar`, id);
    },
    deletar(id) {
        return service.delete(`/FornecedorCliente/${id}`);
    }
};
