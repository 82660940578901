import service from './service'

export default {
    buscarPorFazendaId(centroCustoId, fazendaId) {
        return service.get(`/Pessoa/BuscarPessoasPorFazendaId/${centroCustoId}/${fazendaId}`)
    },
    realizarVinculoComCentroDeCusto(params) {
        return service.post(`/Pessoa/RealizarVinculoComCentroDeCusto`, params)
    }
}
