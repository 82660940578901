import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import { TipoPatrimonioEsquema } from './TipoPatrimonio.validate'
import Page from '../../components/Page'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import {
    Container,
    Card,
    Grid,
    CardContent,
    TextField,
    makeStyles,
    FormControl,
} from '@material-ui/core'
import Titulo from 'components/Titulo'
import TipoPatrimonioService from 'services/TiposPatrimonioService'
import ToastHelper from '../../utils/toastHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlDescricao: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlValorTotal: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    formControlValorkm: {
        margin: theme.spacing(1),
        minWidth: '70%',
    },
    formControlDepreciavel: {
        margin: theme.spacing(1),
        minWidth: '70%',
    },
    gridDetalhesMaquina: {
        borderLeft: 'rgb(118, 118, 118) 1px solid',
        marginLeft: '5px',
        marginTop: '20px',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
}))

export const TipoPatrimonioCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()

    const {
        handleSubmit,
        setValue,
        errors,
        control,
    } = useForm({
        defaultValues: {
            codigo: '',
            nome: '',
            descricao: '',
        },
        resolver: yupResolver(TipoPatrimonioEsquema),
    })

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

    const {
        match: { params },
    } = props

    const [visualizar, setVisualizar] = useState(false)

    const buscarTipoPatrimonio = async (id) => {
        TipoPatrimonioService.buscarPorId(id)
            .then((response) => {
                if (response.status === 200) {
                    var tipoPatrimonio = response.data
                    setValue('codigo', tipoPatrimonio.codigo)
                    setValue('descricao', tipoPatrimonio.descricao)
                    setValue('nome', tipoPatrimonio.nome)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const onSubmit = async (dados) => {
        var tipoPatrimonio = {
            fazendaId: fazendaId,
            codigo: dados.codigo,
            nome: dados.nome,
            descricao: dados.descricao,
        }

        if (params.id) {
            tipoPatrimonio.id = params.id
            TipoPatrimonioService.atualizar(tipoPatrimonio)
                .then((response) => {
                    ToastHelper.success('Tipo de patrimônio atualizado com sucesso')
                    history.goBack()
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data)
                })
        } else {
            TipoPatrimonioService.cadastrar(tipoPatrimonio)
                .then((response) => {
                    ToastHelper.success('Tipo de patrimônio cadastrado com sucesso')
                    history.goBack()
                })
                .catch((error) => {
                    ToastHelper.error(error.response.data)
                })
        }
    }

    useEffect(() => {
        async function init() {
            if (params.id) {
                await buscarTipoPatrimonio(params.id)
            }

            if (params.modo === 'visualizar') {
                setVisualizar(true)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page className={classes.root} title="Cadastro de Tipo de Patrimônio">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Tipo de Patrimônio'} />
                <form
                    key={1}
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="nome"
                                            name="nome"
                                            disabled={visualizar}
                                            as={
                                                <TextField
                                                    label="Nome*"
                                                    variant="standard"
                                                    placeholder="Digite o nome"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.nome
                                                ? errors.nome.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="codigo"
                                            name="codigo"
                                            disabled={visualizar}
                                            as={
                                                <TextField
                                                    label="Código*"
                                                    variant="standard"
                                                    placeholder="Digite o código"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.codigo
                                                ? errors.codigo.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlDescricao}
                                    >
                                        <Controller
                                            control={control}
                                            id="descricao"
                                            name="descricao"
                                            disabled={visualizar}
                                            as={
                                                <TextField
                                                    label="Descrição"
                                                    variant="standard"
                                                    placeholder="Digite a descrição"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons desabilitaSubmit={visualizar} />
                </form>
            </Container>
        </Page>
    )
}
