import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import TransferenciaEstoqueListagem from './transferenciaEstoqueListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import TransferenciaEstoqueService from '../../services/TransferenciaEstoqueService'
import LocaisEstoqueService from '../../services/LocaisEstoqueService'
import ProdutosServicosService from '../../services/ProdutosServicosService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import DataHelper from '../../utils/dataHelpder'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const TransferenciaEstoque = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null
    const [loading, setLoading] = useState(false)
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.transferenciaEstoque.filtroListagem)
    )

    const [transferenciasEstoque, setTransferenciasEstoque] = useState()
    const [locais, setLocais] = useState([])
    const [produtosServicos, setProdutosServicos] = useState([])

    useEffect(() => {
        const init = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = true
            novoFiltro.ordem = 'Data'
            novoFiltro.tamanhoPagina = 100
            await buscarTransferenciasEstoque(novoFiltro)
            await buscarLocais()
            await buscarProdutosServicos()
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_TRANSFERENCIA_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarTransferenciasEstoque(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_TRANSFERENCIA_ESTOQUE',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/TransferenciaEstoque/cadastro`,
        })
    }

    const deletar = async (item) => {
        await TransferenciaEstoqueService.deletar(item.id).then((response) => {
            if (response.status === 200) {
                ToastHelper.success(
                    'Transferência entre estoques excluída com sucesso!'
                )
                let novoFiltro = { ...filtro }
                buscarTransferenciasEstoque(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const buscarTransferenciasEstoque = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await TransferenciaEstoqueService.buscarTudo(params).then(
            (response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.fazendaId = fazendaId
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                        ? filtro.tamanhoPagina
                        : 100
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    alteraFiltro(newFiltro)
                    let novosItens = trataItensParaTabela(response.data.itens)
                    setTransferenciasEstoque(novosItens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const buscarLocais = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        LocaisEstoqueService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                var locais = []
                response.data.itens.forEach((item) =>
                    locais.push({
                        value: item['id'],
                        label: item['nome'] + ' - ' + item['tipoEstoque'],
                    })
                )
                setLocais(locais)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const buscarProdutosServicos = async () => {
        setLoading(true)
        ProdutosServicosService.comControleEstoque(fazendaId).then(
            (response) => {
                if (response.status === 200) {
                    let newProdutos = ObjectHelper.getValuesParseToSelect(
                        response.data
                    )
                    setProdutosServicos(newProdutos)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarTransferenciasEstoque(novoFiltro)
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarTransferenciasEstoque(novoFiltro)
    }

    const trataItensParaTabela = (itens) => {
        let novosItens = []

        itens.map((item) => {
            item.data = item.data
                ? DataHelper.transformStringDataUsaToPtBr(
                      DataHelper.formatStringDataBackToFront(item.data)
                  )
                : ''
            novosItens.push(item)
            return item
        })

        return novosItens
    }

    const visualizar = async (item) => {
        history.push(`/app/transferenciaEstoque/visualizar/${item.id}`)
    }

    return (
        <Page className={classes.root} title="Transferência entre Estoques">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Transferência entre Estoques" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    temFiltroAvancado
                    filtroPeriodo
                    filtroLocalOrigemDestino={locais}
                    filtroProdutos={produtosServicos}
                />
                <Box mt={3}>
                    <TransferenciaEstoqueListagem
                        data={transferenciasEstoque}
                        pageSize={filtro.tamanhoPagina}
                        filtro={filtro}
                        isLoading={loading}
                        deletar={deletar}
                        visualizar={visualizar}
                        mudaOrdenacao={mudaOrdenacao}
                        onChangePage={(page) => onChangePage(page)}
                    />
                </Box>
            </Container>
        </Page>
    )
}
