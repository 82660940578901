import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
    ContaGerencialEsquema,
    ContaGerencialEsquema2,
    ContaGerencialEsquemaEditarComFilhos,
} from './ContaGerencial.validate'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ContaGerencialService from '../../services/ContaGerencialService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
}))

const operacoesTipo = [
    {
        label: 'Crédito',
        value: 0,
    },
    {
        label: 'Débito',
        value: 1,
    },
]

export const ContaGerencialCadastro = (props) => {
    let history = useHistory()
    const {
        match: { params },
    } = props
    const [titulo, setTitulo] = useState('Cadastro de conta gerencial')
    const [temFilhos, setTemFilhos] = useState(false)
    const [contasPai, setContasPai] = useState([])
    const [contaPai, setContaPai] = useState({})
    const { register, handleSubmit, setValue, errors } = useForm({
        defaultValues: {
            id: '',
            descricao: '',
            contaPai: undefined,
        },
        resolver: yupResolver(
            temFilhos && params.id
                ? ContaGerencialEsquemaEditarComFilhos
                : contaPai.value
                ? ContaGerencialEsquema2
                : ContaGerencialEsquema
        ),
    })

    const [item, setItem] = useState()
    const [operacaoSelecionada, setOperacaoSelecionada] = useState({})
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const classes = useStyles()

    useEffect(() => {
        async function init() {
            await buscarContasGerenciais()
            const {
                match: { params },
            } = props
            if (params.modo === 'editar') {
                await setTitulo('Editar conta gerencial')
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const adicionaSubConta = async (idContaPai, contasGerenciais) => {
        let conta = await contasGerenciais.filter(
            (x) => x.value === idContaPai
        )[0]

        if (conta !== undefined) {
            await setContaPai(conta)
            await setValue('contaPai', conta)
            let operacao = conta.operacao
            await setOperacaoSelecionada(operacao)
            await setValue('operacao', operacao)
            await ContaGerencialService.indicarItem(idContaPai).then(
                async (response) => {
                    if (response.statusText === 'OK') {
                        setValue('item', response.data)
                        setItem(response.data)
                    }
                }
            )
        } else {
            history.push(`/app/contaGerencial`)
            ToastHelper.error(
                'Conta desativada, operação não pode ser realizada.'
            )
        }
    }

    const buscarContasGerenciais = async () => {
        let novoFiltro = { fazendaId, ativo: true }
        novoFiltro.decrescente = false
        novoFiltro.ordem = 'Descricao'
        novoFiltro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(novoFiltro)
        await ContaGerencialService.buscarTudo(params).then(
            async (response) => {
                let contasPai =
                    await ObjectHelper.getValuesContaPaiParseToSelect(
                        response.data.itens
                    )
                await setContasPai(contasPai)

                const {
                    match: { params },
                } = props
                if (params.idContaPai) {
                    await adicionaSubConta(params.idContaPai, contasPai)
                }
                if (params.id) {
                    await buscarParaEdicao(params.id, contasPai)
                }
            }
        )
    }

    const buscarParaEdicao = (contaBancariaId, contasGerenciais) => {
        ContaGerencialService.buscarPorId(contaBancariaId).then((response) => {
            if (response.status === 200) {
                setTemFilhos(response.data.temFilhos)
                setValue('codigoAlternativo', response.data.codigoAlternativo)
                setValue('descricao', response.data.descricao)
                setValue('id', response.data.id)
                setValue('nivel', response.data.nivel)
                setValue('item', response.data.item)
                setItem(response.data.item)
                let operacao = operacoesTipo.filter(
                    (x) => x.value === response.data.operacao.valor
                )[0]

                if (response.data.paiId) {
                    let conta = contasGerenciais.filter(
                        (x) => x.value === response.data.paiId
                    )[0]
                    setValue('contaPai', conta)
                    setContaPai(conta)
                    setValue('operacao', operacao)
                    setOperacaoSelecionada(operacao)
                } else {
                    setOperacaoSelecionada(operacao)
                    setValue('operacao', operacao)
                }
            } else ToastHelper.error(response.statusText)
        })
    }

    const onSubmit = async (dados) => {
        let newData = { ...dados }
        newData.fazendaId = fazendaId
        if (contaPai) {
            newData.paiId = contaPai ? contaPai.value : ''
        }
        newData.operacao = operacaoSelecionada ? operacaoSelecionada.value : ''
        newData.numero = ''
        newData.ativo = true
        newData.item = parseInt(newData.item) ? parseInt(newData.item) : item

        if (params.id) {
            newData.id = params.id
            await ContaGerencialService.atualizar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success('Conta gerencial atualizado')
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
        } else {
            await ContaGerencialService.cadastrar(newData)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Conta gerencial cadastrada com sucesso'
                        )
                        history.goBack()
                    } else ToastHelper.error(response.statusText)
                })
                .catch(function (error) {
                    if (error.response) {
                        // Solicitação feita com resposta do servidor
                        ToastHelper.error(error.response.data)
                    }
                })
        }
    }

    const setCp = (conta) => {
        if (conta) {
            setContaPai(conta)
            setOperacaoSelecionada(conta.operacao)
        } else setContaPai({})
    }

    return (
        <Page className={classes.root} title="Conta Gerencial Cadastro">
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição*"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="descricao"
                                            name="descricao"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="number"
                                            label="Item*"
                                            disabled={temFilhos}
                                            className={classes.textField}
                                            inputRef={register}
                                            id="item"
                                            name="item"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.item
                                                ? errors.item.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="contaPai"
                                            id="contaPai"
                                            inputRef={register}
                                            options={contasPai}
                                            disabled={
                                                params.idContaPai || params.id
                                                    ? true
                                                    : false
                                            }
                                            value={contaPai}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setCp(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="contaPai"
                                                    id="contaPai"
                                                    variant="standard"
                                                    label="Conta Pai"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Código Alternativo"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="codigoAlternativo"
                                            name="codigoAlternativo"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.codigoAlternativo
                                                ? errors.codigoAlternativo
                                                      .message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="operacao"
                                            id="operacao"
                                            inputRef={register}
                                            disabled={
                                                contaPai.value ? true : false
                                            }
                                            options={operacoesTipo}
                                            value={operacaoSelecionada}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setOperacaoSelecionada(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="operacao"
                                                    id="operacao"
                                                    variant="standard"
                                                    label="Operação*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                        <p className={classes.errors}>
                                            {errors.operacao
                                                ? errors.operacao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
