import { CentroCusto } from '../pages/centroCusto/index'
import { CentroCustoCadastro } from '../pages/centroCusto/CentroCustoCadastro'
import { CentroCustoCadastroRateio } from '../pages/centroCusto/CentroCustoCadastro.rateio'
import { CentroCustoCadastroSafra } from '../pages/centroCusto/CentroCustoCadastro.safras'
import { AssociarColaboradores } from '../pages/centroCusto/associarColaboradores/AssociarColaboradores'

export const centroCustoRoute = [
    {
        path: '/app/centroCusto',
        key: 'APP_CENTRO_CUSTO',
        exact: true,
        component: CentroCusto,
    },
    {
        path: '/app/centroCusto/cadastro',
        key: 'APP_CENTRO_CUSTO_CADASTRO',
        exact: true,
        component: CentroCustoCadastro,
    },
    {
        path: '/app/centroCusto/cadastro/rateio/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CENTRO_CUSTO_CADASTRO',
        exact: true,
        component: CentroCustoCadastroRateio,
    },
    {
        path: '/app/centroCusto/cadastro/safra/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CENTRO_CUSTO_CADASTRO',
        exact: true,
        component: CentroCustoCadastroSafra,
    },
    {
        path: '/app/centroCusto/editar/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        key: 'APP_CENTRO_CUSTO_EDITAR',
        exact: true,
        component: CentroCustoCadastro,
    },
    {
        path: '/app/centroCusto/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/associarColaboradores',
        key: 'APP_CENTRO_CUSTO_EDITAR',
        exact: true,
        component: AssociarColaboradores,
    },
]
