import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import { PatrimonioEsquema } from './Patrimonio.validate'
import Page from '../../components/Page'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import {
    Container,
    Card,
    Grid,
    CardContent,
    FormControlLabel,
    TextField,
    makeStyles,
    Checkbox,
    FormControl,
} from '@material-ui/core'
import Titulo from 'components/Titulo'
import Autocomplete from '@material-ui/lab/Autocomplete'
import UnidadeDeMedidaService from '../../services/UnidadeDeMedidaService'
import TipoPatrimonioService from '../../services/TipoPatrimonioService'
import TipoValorService from '../../services/TipoValorService'
import TipoApuracaoService from '../../services/TipoApuracaoService'
import MetodoDepreciacaoService from '../../services/MetodoDepreciacaoService'
import CentroCustoService from 'services/CentroCustoService'
import PatrimonioService from 'services/PatrimonioService'
import ObjectHelper from '../../utils/objetcHelper'
import ToastHelper from '../../utils/toastHelper'
import NumberFormat from 'react-number-format'
import DataHelper from '../../utils/dataHelpder'
import MaquinaService from 'services/MaquinaService'
import {RateioCentroDeCusto} from './RateioCentroDeCusto'
import moment from 'moment/moment.js'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlDescricao: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlValorTotal: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    formControlValorkm: {
        margin: theme.spacing(1),
        minWidth: '70%',
    },
    formControlDepreciavel: {
        margin: theme.spacing(1),
        minWidth: '70%',
    },
    gridDetalhesMaquina: {
        borderLeft: 'rgb(118, 118, 118) 1px solid',
        marginLeft: '5px',
        marginTop: '20px',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
}))

export const PatrimonioCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()

    const {
        handleSubmit,
        setValue,
        getValues,
        watch,
        clearErrors,
        errors,
        control,
    } = useForm({
        defaultValues: {
            numero: '',
            tipoPatrimonio: null,
            descricao: '',
            quantidadeItem: '',
            unidade: null,
            tipoValor: null,
            valorUnitario: '',
            valorTotal: '',
            chassi: '',
            placa: '',
            tipoApuracao: null,
            valorHora: '',
            valorKm: '',
            centroCusto: null,
            maquina: '',
            dataApuracaoCusto: '',
            depreciavel: false,
            metodoDepreciacao: null,
            vidaUtil: '',
            referenciaVidaUtil: null,
            valorResidual: '',
        },
        resolver: yupResolver(PatrimonioEsquema),
    })

    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )

    const {
        match: { params },
    } = props

    const opcoesReferenciaVidaUtil = [
        { id: 0, nome: 'Dias' },
        { id: 1, nome: 'Meses' },
        { id: 2, nome: 'Anos' },
    ]

    const [opcoesTipoPatrimonio, setOpcoesTipoPatrimonio] = useState([])
    const [opcoesUnidadeMedida, setOpcoesUnidadeMedida] = useState([])
    const [opcoesTipoValor, setOpcoesTipoValor] = useState([])
    const [opcoesTipoApuracao, setOpcoesTipoApuracao] = useState([])
    const [opcoesMetodoDepreciacao, setOpcoesMetodoDepreciacao] = useState([])
    const [opcoesCentrosCusto, setOpcoesCentrosCusto] = useState([])
    const [opcoesMaquinas, setOpcoesMaquinas] = useState([])
    const [rateios, setRateios] = useState([])

    const [visualizar, setVisualizar] = useState(false)

    const buscarTiposDePatrimonio = async () => {
        TipoPatrimonioService.buscarTudo(fazendaId)
            .then((response) => {
                setOpcoesTipoPatrimonio(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarUnidadesDeMedida = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Sigla'
        novoFiltro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(novoFiltro)
        UnidadeDeMedidaService.buscarTudo(params)
            .then((response) => {
                let unidadesPermitidas = response.data.itens?.filter(m => m.sigla !== 'H');
                setOpcoesUnidadeMedida(unidadesPermitidas)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarMaquinas = async () => {
        MaquinaService.buscarPorFazendaId(fazendaId)
            .then(async (response) => {
                let itens = []
                await response.data.forEach(item => {
                    itens.push({id: item.id, nome: item.nome})
                });
                setOpcoesMaquinas(itens)
            })
            .catch((error) => {
                ToastHelper.error(error?.response?.data)
            })
    }

    const buscarTiposDeValor = async () => {
        TipoValorService.buscarTudo(fazendaId)
            .then((response) => {
                setOpcoesTipoValor(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarTiposApuracoes = async () => {
        TipoApuracaoService.buscarTudo(fazendaId)
            .then((response) => {
                setOpcoesTipoApuracao(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarMetodosDepreciacoes = async () => {
        MetodoDepreciacaoService.buscarTudo(fazendaId)
            .then((response) => {
                setOpcoesMetodoDepreciacao(response.data)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarCentrosDeCusto = async () => {
        let filtro = { fazendaId }
        filtro.fazendaId = fazendaId
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        CentroCustoService.buscarTudo(params)
            .then((response) => {
                var centroCustos = response.data.itens
                var results = centroCustos.map(function (item) {
                    return { id: item['id'], descricao: item['descricao'] }
                })
                setOpcoesCentrosCusto(results)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const buscarPatrimonio = async (id) => {
        PatrimonioService.buscarPorId(id)
            .then(async (response) => {
                if (response.status === 200) {
                    var patrimonio = response.data
                    await setValue('numero', patrimonio.numero)

                    await setValue('tipoPatrimonio', {
                        id: patrimonio.tipoPatrimonioId,
                        nome: patrimonio.tipoPatrimonioNome,
                        codigo: patrimonio.tipoPatrimonioCodigo,
                    })
                    await setValue('descricao', patrimonio.descricao)
                    await setValue('quantidadeItem', patrimonio.quantidadeItem)
                    await setValue('unidade', {
                            id: patrimonio.unidadeId,
                            sigla: patrimonio.unidadeSigla,
                        })
                    await setValue('tipoValor', {
                            id: patrimonio.tipoValorId,
                            nome: patrimonio.tipoValorNome,
                            codigo: patrimonio.tipoValorCodigo,
                        })
                    await setValue('valorUnitario', patrimonio.valorUnitario)
                    await setValue('valorTotal', patrimonio.valorTotal)

                        //Detalhes da máquina
                    await setValue(
                            'chassi',
                            patrimonio.chassi ? patrimonio.chassi : ''
                        )
                    await setValue('placa', patrimonio.placa ? patrimonio.placa : '')
                        if (patrimonio.tipoApuracaoId) {
                            setValue('tipoApuracao', {
                                id: patrimonio.tipoApuracaoId,
                                nome: patrimonio.tipoApuracaoNome,
                                codigo: patrimonio.tipoApuracaoCodigo,
                            })
                        }
                    await setValue(
                            'valorHora',
                            patrimonio.valorHora ? patrimonio.valorHora : ''
                        )
                    await setValue(
                            'valorKm',
                            patrimonio.valorKm ? patrimonio.valorKm : ''
                        )
                    await setValue(
                            'dataApuracaoCusto',
                            patrimonio.dataApuracaoCusto
                                ? patrimonio.dataApuracaoCusto
                                : ''
                        )

                        //Depreciavel
                    await setValue('depreciavel', patrimonio.depreciavel)
                    if (patrimonio.metodoDepreciacaoId) {
                        await setValue('metodoDepreciacao', {
                            id: patrimonio.metodoDepreciacaoId,
                            nome: patrimonio.metodoDepreciacaoNome,
                            codigo: patrimonio.metodoDepreciacaoCodigo,
                        })
                    }
                    await setValue(
                            'vidaUtil',
                            patrimonio.vidaUtil ? patrimonio.vidaUtil : ''
                        )
                    if (patrimonio.referenciaVidaUtil != null) {
                        await setValue(
                            'referenciaVidaUtil',
                            opcoesReferenciaVidaUtil.find(
                                (x) => x.id === patrimonio.referenciaVidaUtil
                            )
                        )
                    }
                    await setValue(
                            'valorResidual',
                            patrimonio.valorResidual ? patrimonio.valorResidual : ''
                        )

                    if (patrimonio.centroCustoId) {
                        await setValue('centroCusto', {
                            id: patrimonio.centroCustoId,
                            descricao: patrimonio.centroCustoDescricao,
                        })
                    }
                    if (patrimonio.maquinaId != null) {
                        await setValue('maquina', {
                            id: patrimonio.maquinaId,
                            nome: patrimonio.maquinaNome
                        })
                    }

                    if (patrimonio.rateios.length > 0) {
                        await patrimonio.rateios.map((item) => {
                            item.centroCusto = {
                                value: item.centroCustoId,
                                label: item.centroCusto,
                            }


                            var dataCorrigida = new Date(item.dataInicial)
                            dataCorrigida.setDate(dataCorrigida.getDate() + 1);

                            item.dataInicial = moment(
                                dataCorrigida,
                                'DD/MM/YYYY'
                            ).format('YYYY-MM')
                            return item
                        })
                        await setRateios(patrimonio.rateios)
                    }
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const onSubmit = async (dados) => {
        var validado = true

        if( getValues('depreciavel') ){
            var arrayRateios = rateios
            if(rateios.length === 0){
                ToastHelper.error(
                    'É obrigatório o rateio dos centros de custo para um patrimônio depreciável.'
                )
                return null
            }

            arrayRateios.forEach((item) => {
                item.id = params.id
                item.percentual = parseInt(item.percentual)
            })

            validado = false

            var centroCustoPorDatas = arrayRateios.reduce(function (obj, item) {
                obj[item.dataInicial] = obj[item.dataInicial] || []
                obj[item.dataInicial].push(item)
                return obj
            }, {})

            for (var data in centroCustoPorDatas) {
                let soma = 0
                centroCustoPorDatas[data].forEach((centroCusto) => {
                    soma += centroCusto.percentual
                })
                if (soma !== 100) {
                    validado = false
                    ToastHelper.error(
                        'Rateio diferente de 100%, no período de ' +
                            DataHelper.transformStringDataMonthUsaToPtBr(data)
                    )
                } else {
                    validado = true
                }
            }
        }

        if (validado) {
            var patrimonio = {
                fazendaId: fazendaId,
                numero: dados.numero,
                tipoPatrimonioId: dados.tipoPatrimonio.id,
                descricao: dados.descricao,
                quantidadeItem: dados.quantidadeItem
                ?   parseFloat(dados.quantidadeItem
                        .replace(',', '.')
                    )
                : null,
                unidadeId: dados.unidade.id,
                tipoValorId: dados.tipoValor.id,
                valorUnitario: isNaN(dados.valorUnitario)
                    ? dados.valorUnitario.replaceAll('.', '').replace(',', '.').replace('R$', '')
                    : dados.valorUnitario,
                valorTotal: dados.valorTotal
                ? parseFloat(dados.valorTotal).toFixed(2)
                : null,
                chassi: dados.chassi ? dados.chassi : null,
                placa: dados.placa ? dados.placa : null,
                tipoApuracaoId: dados.tipoApuracao ? dados.tipoApuracao.id : null,
                valorHora: dados.valorHora
                    ? parseFloat(
                          dados.valorHora
                              .replaceAll('.', '')
                              .replace(',', '.')
                              .replace('R$', '')
                      )
                    : null,
                valorKm: dados.valorKm
                    ? parseFloat(
                          dados.valorKm
                              .replaceAll('.', '')
                              .replace(',', '.')
                              .replace('R$', '')
                      )
                    : null,
                centroCustoId: dados.centroCusto ? dados.centroCusto.id : null,
                dataApuracaoCusto: dados.dataApuracaoCusto
                    ? dados.dataApuracaoCusto
                    : null,
                depreciavel: dados.depreciavel,
                metodoDepreciacaoId: dados.metodoDepreciacao
                    ? dados.metodoDepreciacao.id
                    : null,
                vidaUtil: dados.vidaUtil
                ?   isNaN(dados.vidaUtil) ? parseFloat(
                      dados.vidaUtil
                          .replaceAll('.', '')
                          .replace(',', '.')
                  ) : dados.vidaUtil
                : null,
                ReferenciaVidaUtil: dados.referenciaVidaUtil
                    ? dados.referenciaVidaUtil.id
                    : null,
                valorResidual: dados.valorResidual
                ? isNaN(dados.valorResidual) ? parseFloat(
                      dados.valorResidual
                          .replaceAll('.', '')
                          .replace(',', '.')
                          .replace('R$', '')
                  ) : dados.valorResidual
                : null,
                maquinaId: dados.maquina ? dados.maquina.id : null,
                rateios: rateios.length > 0 ? rateios : []
            }

            if (params.id) {
                patrimonio.id = params.id
                PatrimonioService.atualizar(patrimonio)
                    .then((response) => {
                        ToastHelper.success('Patrimônio atualizado com sucesso')
                        history.goBack()
                    })
                    .catch((error) => {
                        ToastHelper.error(error.response.data)
                    })
            } else {
                PatrimonioService.cadastrar(patrimonio)
                    .then((response) => {
                        ToastHelper.success('Patrimônio cadastrado com sucesso')
                        history.goBack()
                    })
                    .catch((error) => {
                        ToastHelper.error(error.response.data)
                    })
            }
        }
    }

    useEffect(() => {
        async function init() {
            //metodos iniciais
            await buscarTiposDePatrimonio()
            await buscarUnidadesDeMedida()
            await buscarTiposDeValor()
            await buscarTiposApuracoes()
            await buscarCentrosDeCusto()
            await buscarMetodosDepreciacoes()
            await buscarMaquinas()

            if (params.id) {
                await buscarPatrimonio(params.id)
            }

            if (params.modo === 'visualizar') {
                setVisualizar(true)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page className={classes.root} title="Cadastro de Patrimônio">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Patrimônio'} />
                <form
                    key={1}
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="descricao"
                                            name="descricao"
                                            disabled={visualizar}
                                            as={
                                                <TextField
                                                    label="Nome*"
                                                    variant="standard"
                                                    placeholder="Digite a descrição"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.descricao
                                                ? errors.descricao.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="numero"
                                            name="numero"
                                            disabled={visualizar}
                                            as={
                                                <TextField
                                                    label="Número*"
                                                    variant="standard"
                                                    placeholder="Digite o número"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.numero
                                                ? errors.numero.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="tipoPatrimonio"
                                            name="tipoPatrimonio"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={
                                                        opcoesTipoPatrimonio
                                                    }
                                                    disabled={visualizar}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.nome
                                                            : ''
                                                    }
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    size="small"
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.id === value?.id
                                                    }
                                                    value={getValues(
                                                        'tipoPatrimonio'
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Tipo de Patrimônio*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                    autoSelect
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.tipoPatrimonio
                                                ? errors.tipoPatrimonio.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                display="flex"
                                justify="flex-start"
                            >
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="quantidadeItem"
                                            name="quantidadeItem"
                                            as={
                                                <NumberFormat
                                                    label="Quantidade de itens*:"
                                                    thousandSeparator={false}
                                                    decimalSeparator={','}
                                                    decimalScale={2}
                                                    placeholder={"0,00"}
                                                    customInput={TextField}
                                                    disabled={visualizar}
                                                    inputProps={{
                                                        min: 0,
                                                        style: {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onValueChange={({ floatValue }) => {
                                                        if (!floatValue) {
                                                            return;
                                                        }

                                                        floatValue = floatValue.toFixed(2)

                                                        let valorUnitario = getValues('valorUnitario')
                                                        let valorTotal = getValues('valorTotal')

                                                        if (valorUnitario) {
                                                            valorUnitario = isNaN(valorUnitario)
                                                                ? valorUnitario
                                                                    .replaceAll('.', '')
                                                                    .replace(',', '.')
                                                                    .replace('R$', '')
                                                                : valorUnitario

                                                            setValue('valorTotal', floatValue * valorUnitario)
                                                            clearErrors('valorTotal')
                                                        } else if (valorTotal) {
                                                            valorTotal = isNaN(valorTotal)
                                                                ? valorTotal
                                                                    .replaceAll('.', '')
                                                                    .replace(',', '.')
                                                                    .replace('R$', '')
                                                                : valorTotal

                                                            setValue('valorUnitario', valorTotal / floatValue)
                                                            clearErrors('valorUnitario')
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.quantidadeItem
                                                ? errors.quantidadeItem.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={2}
                                    display="flex"
                                    className={classes.paddingGrid}
                                >
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="unidade"
                                            name="unidade"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={
                                                        opcoesUnidadeMedida
                                                    }
                                                    disabled={visualizar}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.sigla
                                                            : ''
                                                    }
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    size="small"
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.id === value?.id
                                                    }
                                                    value={getValues('unidade')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Unidade*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                    autoSelect
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.unidade
                                                ? errors.unidade.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="tipoValor"
                                            name="tipoValor"
                                            as={(props) => (
                                                <Autocomplete
                                                    options={opcoesTipoValor}
                                                    disabled={visualizar}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.nome
                                                            : ''
                                                    }
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    size="small"
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.id === value?.id
                                                    }
                                                    value={getValues(
                                                        'tipoValor'
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Tipo do Valor*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                    autoSelect
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.tipoValor
                                                ? errors.tipoValor.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="valorUnitario"
                                            name="valorUnitario"
                                            as={
                                                <NumberFormat
                                                    label="Valor Unitário*:"
                                                    prefix={'R$ '}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    decimalScale={2}
                                                    placeholder="R$ 0,00"
                                                    fixedDecimalScale={2}
                                                    customInput={TextField}
                                                    disabled={visualizar}
                                                    inputProps={{
                                                        min: 0,
                                                        style: {
                                                            textAlign: 'right',
                                                        },
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onValueChange={({ floatValue }) => {
                                                        if (!floatValue) {
                                                            return;
                                                        }

                                                        floatValue = floatValue.toFixed(2)

                                                        let quantidadeItem = getValues('quantidadeItem')
                                                        let valorTotal = getValues('valorTotal')

                                                        if (quantidadeItem) {
                                                            quantidadeItem = isNaN(quantidadeItem)
                                                                ? quantidadeItem
                                                                    .replaceAll('.', '')
                                                                    .replace(',', '.')
                                                                : quantidadeItem;

                                                            setValue('valorTotal', floatValue * quantidadeItem)
                                                            clearErrors('valorTotal')
                                                        } else if (valorTotal) {
                                                            valorTotal = isNaN(valorTotal)
                                                                ? valorTotal
                                                                    .replaceAll('.', '')
                                                                    .replace(',', '.')
                                                                    .replace('R$', '')
                                                                : valorTotal;

                                                            setValue('quantidadeItem', valorTotal / floatValue)
                                                            clearErrors('quantidadeItem')
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.valorUnitario
                                                ? errors.valorUnitario.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={
                                            classes.formControlValorTotal
                                        }
                                    >
                                        <Controller
                                            control={control}
                                            id="valorTotal"
                                            name="valorTotal"
                                            as={
                                                <NumberFormat
                                                    label="Valor Total*:"
                                                    placeholder="R$ 0,00"
                                                    prefix={'R$ '}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    decimalScale={2}
                                                    fixedDecimalScale={2}
                                                    customInput={TextField}
                                                    disabled={visualizar}
                                                    inputProps={{
                                                        min: 0,
                                                        style: {
                                                            textAlign: 'right',
                                                        }
                                                    }}
                                                    isplaceholderanimated={
                                                        (false, 'top')
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onValueChange={({ floatValue }) => {
                                                        if (!floatValue) {
                                                            return;
                                                        }

                                                        floatValue = floatValue.toFixed(2)

                                                        let quantidadeItem = getValues('quantidadeItem')
                                                        let valorUnitario = getValues('valorUnitario')

                                                        if (valorUnitario) {
                                                            valorUnitario = isNaN(valorUnitario)
                                                                ? valorUnitario
                                                                    .replaceAll('.', '')
                                                                    .replace(',', '.')
                                                                    .replace('R$', '')
                                                                : valorUnitario;

                                                            setValue('quantidadeItem', floatValue / valorUnitario)
                                                            clearErrors('quantidadeItem')
                                                        } else if (quantidadeItem) {
                                                            quantidadeItem = isNaN(quantidadeItem)
                                                                ? quantidadeItem
                                                                    .replaceAll('.', '')
                                                                    .replace(',', '.')
                                                                : quantidadeItem;

                                                            setValue('valorUnitario', floatValue / quantidadeItem)
                                                            clearErrors('valorUnitario')
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        <p className={classes.errors}>
                                            {errors.valorTotal
                                                ? errors.valorTotal.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {watch('tipoPatrimonio') &&
                                watch('tipoPatrimonio').codigo ===
                                    'MAQUINA' && (
                                    <>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                            className={
                                                classes.gridDetalhesMaquina
                                            }
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <h4
                                                        style={{
                                                            marginLeft: '5px',
                                                            marginTop: '15px',
                                                        }}
                                                    >
                                                        Detalhes da Máquina
                                                    </h4>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="chassi"
                                                            name="chassi"
                                                            disabled={
                                                                visualizar
                                                            }
                                                            as={
                                                                <TextField
                                                                    label="Chassi"
                                                                    variant="standard"
                                                                    placeholder="Digite o chassi:"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.chassi
                                                                ? errors.chassi
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="placa"
                                                            name="placa"
                                                            disabled={
                                                                visualizar
                                                            }
                                                            as={
                                                                <TextField
                                                                    label="Placa"
                                                                    variant="standard"
                                                                    placeholder="Digite a placa:"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.placa
                                                                ? errors.placa
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="tipoApuracao"
                                                            name="tipoApuracao"
                                                            as={(props) => (
                                                                <Autocomplete
                                                                    options={
                                                                        opcoesTipoApuracao
                                                                    }
                                                                    disabled={
                                                                        visualizar
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option
                                                                            ? option.nome
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        data
                                                                    ) => {
                                                                        if (
                                                                            data
                                                                        ) {
                                                                            if (
                                                                                data.codigo ===
                                                                                'PORKM'
                                                                            ) {
                                                                                setValue(
                                                                                    'valorHora',
                                                                                    ''
                                                                                )
                                                                                clearErrors(
                                                                                    'valorHora'
                                                                                )
                                                                            }
                                                                            if (
                                                                                data.codigo ===
                                                                                'PORHORA'
                                                                            ) {
                                                                                setValue(
                                                                                    'valorKm',
                                                                                    ''
                                                                                )
                                                                                clearErrors(
                                                                                    'valorKm'
                                                                                )
                                                                            }
                                                                        } else {
                                                                            setValue(
                                                                                'valorHora',
                                                                                ''
                                                                            )
                                                                            setValue(
                                                                                'valorKm',
                                                                                ''
                                                                            )
                                                                            clearErrors(
                                                                                'valorHora'
                                                                            )
                                                                            clearErrors(
                                                                                'valorKm'
                                                                            )
                                                                        }
                                                                        props.onChange(
                                                                            data
                                                                        )
                                                                    }}
                                                                    size="small"
                                                                    getOptionSelected={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option.id ===
                                                                        value.id
                                                                    }
                                                                    value={getValues(
                                                                        'tipoApuracao'
                                                                    )}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="standard"
                                                                            label="Tipo de Apuração:"
                                                                            placeholder="Selecione"
                                                                            error={
                                                                                errors.tipoApuracao
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    autoSelect
                                                                />
                                                            )}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.tipoApuracao
                                                                ? errors
                                                                      .tipoApuracao
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="valorHora"
                                                            name="valorHora"
                                                            as={
                                                                <NumberFormat
                                                                    label="Valor Hora:"
                                                                    placeholder="R$ 0,00"
                                                                    prefix={
                                                                        'R$ '
                                                                    }
                                                                    thousandSeparator={
                                                                        '.'
                                                                    }
                                                                    decimalSeparator={
                                                                        ','
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    fixedDecimalScale={
                                                                        2
                                                                    }
                                                                    customInput={
                                                                        TextField
                                                                    }
                                                                    inputProps={{
                                                                        min: 0,
                                                                        style: {
                                                                            textAlign:
                                                                                'right',
                                                                        },
                                                                    }}
                                                                    isplaceholderanimated={
                                                                        (false,
                                                                        'top')
                                                                    }
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    disabled={
                                                                        !(
                                                                            watch(
                                                                                'tipoApuracao'
                                                                            ) &&
                                                                            watch(
                                                                                'tipoApuracao'
                                                                            )
                                                                                .codigo ===
                                                                                'PORHORA'
                                                                        ) ||
                                                                        visualizar
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.valorHora
                                                                ? errors
                                                                      .valorHora
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControlValorkm
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="valorKm"
                                                            name="valorKm"
                                                            as={
                                                                <NumberFormat
                                                                    label="Valor km:"
                                                                    placeholder="R$ 0,00"
                                                                    prefix={
                                                                        'R$ '
                                                                    }
                                                                    thousandSeparator={
                                                                        '.'
                                                                    }
                                                                    decimalSeparator={
                                                                        ','
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    fixedDecimalScale={
                                                                        2
                                                                    }
                                                                    customInput={
                                                                        TextField
                                                                    }
                                                                    inputProps={{
                                                                        min: 0,
                                                                        style: {
                                                                            textAlign:
                                                                                'right',
                                                                        },
                                                                    }}
                                                                    isplaceholderanimated={
                                                                        (false,
                                                                        'top')
                                                                    }
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    disabled={
                                                                        !(
                                                                            watch(
                                                                                'tipoApuracao'
                                                                            ) &&
                                                                            watch(
                                                                                'tipoApuracao'
                                                                            )
                                                                                .codigo ===
                                                                                'PORKM'
                                                                        ) ||
                                                                        visualizar
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.valorKm
                                                                ? errors.valorKm
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="centroCusto"
                                                            name="centroCusto"
                                                            as={(props) => (
                                                                <Autocomplete
                                                                    options={
                                                                        opcoesCentrosCusto
                                                                    }
                                                                    disabled={
                                                                        visualizar
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option
                                                                            ? option.descricao
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        data
                                                                    ) => {
                                                                        props.onChange(
                                                                            data
                                                                        )
                                                                    }}
                                                                    size="small"
                                                                    getOptionSelected={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option.id ===
                                                                        value.id
                                                                    }
                                                                    value={watch('centroCusto')}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="standard"
                                                                            label="Centro de custos para apuração:"
                                                                            placeholder="Selecione"
                                                                            error={
                                                                                errors.centroCusto
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    autoSelect
                                                                />
                                                            )}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.tipoApuracao
                                                                ? errors
                                                                      .tipoApuracao
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="maquina"
                                                            name="maquina"
                                                            as={(props) => (
                                                                <Autocomplete
                                                                    options={
                                                                        opcoesMaquinas
                                                                    }
                                                                    disabled={
                                                                        visualizar
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option
                                                                            ? option.nome
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        data
                                                                    ) => {
                                                                        props.onChange(
                                                                            data
                                                                        )
                                                                    }}
                                                                    size="small"
                                                                    getOptionSelected={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option.id ===
                                                                        value.id
                                                                    }
                                                                    value={watch('maquina')}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="standard"
                                                                            label="Equipamento equivalente:"
                                                                            placeholder="Selecione"
                                                                            error={
                                                                                errors.maquina
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    )}
                                                                    autoSelect
                                                                />
                                                            )}
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.maquina
                                                                ? errors
                                                                      .maquina
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={4}
                                                    display="flex"
                                                >
                                                    <FormControl
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Controller
                                                            control={control}
                                                            id="dataApuracaoCusto"
                                                            name="dataApuracaoCusto"
                                                            disabled={
                                                                visualizar
                                                            }
                                                            as={
                                                                <TextField
                                                                    label="Data de referência para apuração de custos:"
                                                                    variant="standard"
                                                                    type="date"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={
                                                                        {
                                                                            inputProps: {max: '9999-12-31'}
                                                                        }
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                classes.errors
                                                            }
                                                        >
                                                            {errors.dataApuracaoCusto
                                                                ? errors
                                                                      .dataApuracaoCusto
                                                                      .message
                                                                : ''}
                                                        </p>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="depreciavel"
                                            name="depreciavel"
                                            as={(props) => (
                                                <FormControlLabel
                                                    label="Depreciável:"
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(e) => {
                                                                if (
                                                                    !e.target
                                                                        .checked
                                                                ) {
                                                                    clearErrors(
                                                                        'metodoDepreciacao'
                                                                    )
                                                                    clearErrors(
                                                                        'vidaUtil'
                                                                    )
                                                                    clearErrors(
                                                                        'referenciaVidaUtil'
                                                                    )
                                                                    clearErrors(
                                                                        'valorResidual'
                                                                    )
                                                                }
                                                                if(!e.target.checked){
                                                                    setValue('metodoDepreciacao', undefined)
                                                                    setValue('vidaUtil', '')
                                                                    setValue('referenciaVidaUtil', undefined)
                                                                    setValue('valorResidual', '')
                                                                }
                                                                props.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }}
                                                            checked={
                                                                props.value
                                                            }
                                                            disabled={
                                                                visualizar
                                                            }
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.depreciavel
                                                ? errors.depreciavel.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {watch('depreciavel') === true && (
                                    <>
                                    <Grid container item xs={12} display="flex">
                                        <Grid container item xs={3} display="flex">
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="metodoDepreciacao"
                                                    name="metodoDepreciacao"
                                                    as={(props) => (
                                                        <Autocomplete
                                                            options={
                                                                opcoesMetodoDepreciacao
                                                            }
                                                            disabled={visualizar}
                                                            getOptionLabel={(option) =>
                                                                option
                                                                    ? option.nome
                                                                    : ''
                                                            }
                                                            onChange={(_, data) => {
                                                                props.onChange(data)
                                                            }}
                                                            size="small"
                                                            getOptionSelected={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option?.id === value?.id
                                                            }
                                                            value={getValues(
                                                                'metodoDepreciacao'
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Método depreciação:*"
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                            autoSelect
                                                        />
                                                    )}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.metodoDepreciacao
                                                        ? errors.metodoDepreciacao
                                                            .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid container item xs={2} display="flex">
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="vidaUtil"
                                                    name="vidaUtil"
                                                    as={
                                                        <NumberFormat
                                                            label="Vida útil:*"
                                                            placeholder="0,0"
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}
                                                            decimalScale={1}
                                                            customInput={TextField}
                                                            disabled={visualizar}
                                                            inputProps={{
                                                                min: 0,
                                                                style: {
                                                                    textAlign: 'right',
                                                                },
                                                            }}
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.vidaUtil
                                                        ? errors.vidaUtil.message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid container item xs={3} display="flex">
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Controller
                                                    control={control}
                                                    id="referenciaVidaUtil"
                                                    name="referenciaVidaUtil"
                                                    as={(props) => (
                                                        <Autocomplete
                                                            options={
                                                                opcoesReferenciaVidaUtil
                                                            }
                                                            disabled={visualizar}
                                                            getOptionLabel={(option) =>
                                                                option
                                                                    ? option.nome
                                                                    : ''
                                                            }
                                                            onChange={(_, data) => {
                                                                props.onChange(data)
                                                            }}
                                                            size="small"
                                                            getOptionSelected={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option?.id === value?.id
                                                            }
                                                            value={watch(
                                                                'referenciaVidaUtil'
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Referência vida útil:*"
                                                                    placeholder="Selecione"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            )}
                                                            autoSelect
                                                        />
                                                    )}
                                                />
                                                <p className={classes.errors}>
                                                    {errors.referenciaVidaUtil
                                                        ? errors.referenciaVidaUtil
                                                            .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                        <Grid container item xs={3} display="flex">
                                            <FormControl
                                                className={
                                                    classes.formControlValorTotal
                                                }
                                            >
                                                <Controller
                                                    control={control}
                                                    id="valorResidual"
                                                    name="valorResidual"
                                                    as={
                                                        <NumberFormat
                                                            label="Valor residual:*"
                                                            placeholder="R$ 0,00"
                                                            prefix={'R$ '}
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}
                                                            decimalScale={2}
                                                            fixedDecimalScale={2}
                                                            customInput={TextField}
                                                            disabled={visualizar}
                                                            inputProps={{
                                                                min: 0,
                                                                style: {
                                                                    textAlign: 'right',
                                                                },
                                                            }}
                                                            isplaceholderanimated={
                                                                (false, 'top')
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                />
                                                <p className={classes.errors}>
                                                    {errors.valorResidual
                                                        ? errors.valorResidual.message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <RateioCentroDeCusto
                        dados={rateios}
                        setDados={setRateios}
                        patrimonioId={params.id || ''}
                        visualizar={visualizar}
                    />
                    <FooterBottons desabilitaSubmit={visualizar} />
                </form>
            </Container>
        </Page>
    )
}
