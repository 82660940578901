import React, { useState, useEffect } from 'react'
import { Button, Divider, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { useForm } from 'react-hook-form'
import ContasPagarReceberService from '../../../services/ContaPagarReceberService'
import ToastHelper from '../../../utils/toastHelper'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 5, 5),
        marginTop: 200,
        marginLeft: 500,
        marginRight: 500,
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    valorTotal: {
        display: 'inline-flex !important',
    },
}))

export default function ModalCancelarPagamento(props) {
    const classes = useStyles()
    const [valorDocumento, setValorDocumento] = useState()
    const [contasSelecionadas, setContasSelecionadas] = useState([])

    const { handleSubmit } = useForm({})

    useEffect(() => {
        async function init() {
            await setarContasSelecionadasParaConciliacao()
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.contasSelecionadas.length > 0) {
            let valorContas = 0
            props.contasSelecionadas.forEach((parcela) => {
                let stringValor = parcela.valorPago
                valorContas = valorContas + stringValor
            })
            setValorDocumento(
                valorContas.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModalQuitarContas])

    const setarContasSelecionadasParaConciliacao = async () => {
        props.contasSelecionadas.length > 0
            ? setContasSelecionadas(props.contasSelecionadas)
            : setContasSelecionadas([])
    }

    const submit = async () => {
        var params = []
        props.contasSelecionadas.forEach((item) => {
            params.push(item.id)
        })
        ContasPagarReceberService.cancelarConciliacao(params)
            .then((res) => {
                if (res.status === 200) {
                    ToastHelper.success('Operação realizada com sucesso')
                    props.buscarContas()
                    props.close()
                    props.mudaContasSelecionadas([])
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data.message)
            })
    }

    return (
        <>
            <div className={classes.paper}>
                <h2 className={classes.title}>
                    Deseja realmente cancelar este(s) conciliamento(s)?
                </h2>

                <form className={classes.form} onSubmit={handleSubmit(submit)}>
                    <Divider className={classes.divider} />
                    <Grid container item xs={12} display="flex">
                        <Grid
                            className={classes.valorTotal}
                            container
                            item
                            xs={12}
                            style={{ marginRight: '2%', marginTop: '2%' }}
                        >
                            <FormControl
                                className={classes.formControl}
                                display="inline-flex"
                            >
                                <div
                                    className={classes.valorTotal}
                                    display="inline-flex"
                                >
                                    <h4 style={{ marginRight: '15px' }}>
                                        {contasSelecionadas.length > 1
                                            ? '(=) Valor dos documentos: '
                                            : '(=) Valor do documento: '}
                                    </h4>
                                    <span>{valorDocumento}</span>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Button
                            onClick={() => {
                                props.close()
                            }}
                            className={classes.button2}
                            variant="contained"
                            color="inherit"
                            size="small"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </form>
            </div>
        </>
    )
}
