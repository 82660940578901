import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import LocaisEstoqueListagem from './LocaisEstoqueListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import LocaisEstoqueService from '../../services/LocaisEstoqueService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const LocaisEstoque = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [loading, setLoading] = useState(false)
    const [LocaisEstoque, setLocaisEstoque] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.caixa.filtroListagem)
    )

    useEffect(() => {
        buscarLocaisEstoque(filtro)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CAIXA',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        filtro.ativo = false
        buscarLocaisEstoque(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CAIXA',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        props.history.push({
            pathname: `/app/locaisEstoque/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarLocaisEstoque(novoFiltro)
    }

    const buscarLocaisEstoque = async (filtro) => {
        filtro.fazendaId = FazendaId
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        await LocaisEstoqueService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = FazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                alteraFiltro(newFiltro)
                setLocaisEstoque(response.data.itens)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const editar = (itemId) => {
        history.push(`/app/locaisEstoque/editar/${itemId}`)
    }

    const deletar = async (item) => {
        await LocaisEstoqueService.deletar(item.id)
        .then((response) => {
            if (response.status === 200) {
                ToastHelper.success('Local de estoque excluído com sucesso!')
                let novoFiltro = { ...filtro }
                buscarLocaisEstoque(novoFiltro)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
        .catch((error) =>{
            ToastHelper.error(error.response.data)
            setLoading(false)
        });
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarLocaisEstoque(novoFiltro)
    }

    const editarCelula = async (novoValor, valorAntigo, linha, coluna) => {
        var dados = LocaisEstoque
        dados[linha.tableData.id][coluna.field] = novoValor

        await LocaisEstoqueService.atualizar(dados[linha.tableData.id]).then(
            (response) => {
                if (response.status === 200) {
                    ToastHelper.success(
                        'Local de estoque atualizado com sucesso!'
                    )
                    let novoFiltro = { ...filtro }
                    buscarLocaisEstoque(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    const ativarDesativar = async (id) => {
        setLoading(true)
        LocaisEstoqueService.ativarDesativar(id)
            .then((response) => {
                if (response.status === 200) {
                    let novoFiltro = { ...filtro }
                    buscarLocaisEstoque(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    return (
        <Page className={classes.root} title="Locais de Estoque">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Locais Estoque" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                />
                <Box mt={3}>
                    <LocaisEstoqueListagem
                        data={LocaisEstoque}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        editarCelula={editarCelula}
                        ativarDesativar={ativarDesativar}
                    />
                </Box>
            </Container>
        </Page>
    )
}
