import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import FiltroSimples from 'components/BasicPageTopBar/FiltroSimples'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { Divider, Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '10px',
        marginBottom: '10px',
    },
}))

const Toolbar = ({ handleFiltro, filtroInicial, className, ...props }) => {
    const classes = useStyles()
    const [filtro, setFiltro] = useState(filtroInicial)
    const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded)
    /*const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);*/

    const setStateAcocordion = () => {
        let newFiltro = { ...filtro }
        newFiltro.filtroExpanded = !filtroExpanded
        setFiltro(newFiltro)
        setFiltroExpanded(!filtroExpanded)
    }

    const changeFiltro = (campo, event) => {
        let newFiltro = { ...filtro }
        newFiltro[campo] = event.target.value
        setFiltro(newFiltro)
    }

    const submit = (event) => {
        handleFiltro(filtro)
    }

    const [opcoesPagamentoA] = useState([
        {
            value: 'CLIENTE',
            label: 'Cliente',
        },
        {
            value: 'FORNECEDOR',
            label: 'Fornecedor',
        },
    ])

    const [vencimentoOpcoes] = useState([
        {
            id: 0,
            nome: 'Todos',
        },
        {
            id: 1,
            nome: 'Vencidos',
        },
        {
            id: 2,
            nome: 'Não Vencidos',
        },
    ])

    const [pagamentoOpcoes] = useState([
        {
            id: 0,
            nome: 'Todos',
        },
        {
            id: 1,
            nome: 'Pagos',
        },
        {
            id: 2,
            nome: 'Não Pagos',
        },
    ])

    const [compensacaoOpcoes] = useState([
        {
            id: 0,
            nome: 'Todos',
        },
        {
            id: 1,
            nome: 'Compensados',
        },
        {
            id: 2,
            nome: 'Não Compensados',
        },
    ])

    const [situacoes] = useState([
        'Definitivo',
        'Pago',
        'Conciliado',
    ])

    const { getValues, setValue, register } = useForm({
        defaultValues: {
            centroCusto: "",
            centroCustoId: "",
            compensacao: "",
            compensacaoObjeto: "",
            contaGerencial: "",
            contaGerencialId: "",
            dataCompensacaoFim: "",
            dataCompensacaoInicio: "",
            dataEmissaoFim: "",
            dataEmissaoInicio: "",
            dataLancamentoFim: "",
            dataLancamentoInicio: "",
            dataPagamentoFim: "",
            dataPagamentoInicio: "",
            dataVencimentoFim: "",
            dataVencimentoInicio: "",
            descricao: "",
            documento: "",
            documentoPagamento: "",
            pagamento: "",
            pagamentoA: "",
            pagamentoAValue: "",
            pagamentoObjeto: "",
            produtoServico: "",
            produtoServicoId: "",
            situacao: "",
            valorParcelaFim: "",
            valorParcelaInicio: "",
            valorTotalFim: "",
            valorTotalInicio: "",
            vencimento: "",
            vencimentoObjeto: "",
        }
    })

    const clearFiltros = () => {
        const {
            centroCusto,
            centroCustoId,
            compensacao,
            compensacaoObjeto,
            contaGerencial,
            contaGerencialId,
            dataCompensacaoFim,
            dataCompensacaoInicio,
            dataEmissaoFim,
            dataEmissaoInicio,
            dataLancamentoFim,
            dataLancamentoInicio,
            dataPagamentoFim,
            dataPagamentoInicio,
            dataVencimentoFim,
            dataVencimentoInicio,
            descricao,
            documento,
            documentoPagamento,
            pagamento,
            pagamentoA,
            pagamentoAValue,
            pagamentoObjeto,
            produtoServico,
            produtoServicoId,
            situacao,
            valorParcelaFim,
            valorParcelaInicio,
            valorTotalFim,
            valorTotalInicio,
            vencimento,
            vencimentoObjeto,
            ...rest
        } = filtro

        const newFiltro = {
            ...rest,
            filtro: ""
        }

        setValue("centroCusto", "")
        setValue("centroCustoId", "")
        setValue("compensacao", "")
        setValue("compensacaoObjeto", "")
        setValue("contaGerencial", "")
        setValue("contaGerencialId", "")
        setValue("dataCompensacaoFim", "")
        setValue("dataCompensacaoInicio", "")
        setValue("dataEmissaoFim", "")
        setValue("dataEmissaoInicio", "")
        setValue("dataLancamentoFim", "")
        setValue("dataLancamentoInicio", "")
        setValue("dataPagamentoFim", "")
        setValue("dataPagamentoInicio", "")
        setValue("dataVencimentoFim", "")
        setValue("dataVencimentoInicio", "")
        setValue("descricao", "")
        setValue("documento", "")
        setValue("documentoPagamento", "")
        setValue("pagamento", "")
        setValue("pagamentoA", "")
        setValue("pagamentoAValue", "")
        setValue("pagamentoObjeto", "")
        setValue("produtoServico", "")
        setValue("produtoServicoId", "")
        setValue("situacao", "")
        setValue("valorParcelaFim", "")
        setValue("valorParcelaInicio", "")
        setValue("valorTotalFim", "")
        setValue("valorTotalInicio", "")
        setValue("vencimento", "")
        setValue("vencimentoObjeto", "")

        setFiltro(newFiltro)
        handleFiltro(newFiltro)
    }

    useEffect(() => {
        setFiltro(filtroInicial)
    }, [filtroInicial])

    return (
        <Box item mt={3}>
            <Accordion
                expanded={filtroExpanded}
                onChange={(e) => setStateAcocordion}
            >
                <AccordionSummary
                    expandIcon={
                        !props.temFiltroAvancado ? '' : <ExpandMoreIcon />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={() => setStateAcocordion(!filtroExpanded)}
                >
                    <FiltroSimples
                        filtro={filtro}
                        changeFiltro={changeFiltro}
                        submit={submit}
                        temFiltroAvancado={props.temFiltroAvancado}
                        clickadd={props.clickadd}
                    />
                </AccordionSummary>
                {props.temFiltroAvancado && (
                    <AccordionDetails>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            type="text"
                                            label="Valor da Parcela Inicial:"
                                            onChange={(e) => {
                                                setValue("valorParcelaInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorParcelaInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().valorParcelaInicio}
                                            InputLabelProps={{ shrink: true }}
                                            customInput={TextField}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            {...register("valorParcelaInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            type="text"
                                            label="Valor da Parcela Final:"
                                            onChange={(e) => {
                                                setValue("valorParcelaFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorParcelaFim:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().valorParcelaFim}
                                            InputLabelProps={{ shrink: true }}
                                            customInput={TextField}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            {...register("valorParcelaFim")}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            type="text"
                                            label="Valor Total Inicial:"
                                            onChange={(e) => {
                                                setValue("valorTotalInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorTotalInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().valorTotalInicio}
                                            InputLabelProps={{ shrink: true }}
                                            customInput={TextField}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            {...register("valorTotalInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            type="text"
                                            label="Valor Total Final:"
                                            onChange={(e) => {
                                                setValue("valorTotalFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    valorTotalFim:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            value={getValues().valorTotalFim}
                                            InputLabelProps={{ shrink: true }}
                                            customInput={TextField}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            {...register("valorTotalFim")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Lançamento Inicial:"
                                            onChange={(e) => {
                                                setValue("dataLancamentoInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataLancamentoInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            className={classes.textField}
                                            value={getValues().dataLancamentoInicio}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataLancamentoInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Lançamento Final:"
                                            type="date"
                                            onChange={(e) => {
                                                setValue("dataLancamentoFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataLancamentoFim:
                                                        e.target.value,
                                                })
                                            }}
                                            className={classes.textField}
                                            value={getValues().dataLancamentoFim}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataLancamentoFim")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            id="pagamentoA"
                                            options={opcoesPagamentoA || []}
                                            getOptionLabel={(option) =>
                                                option?.label || ''
                                            }
                                            onChange={(event, value) => {
                                                setValue("pagamentoA", value ? value.label : "")
                                                setValue("pagamentoAValue", value)

                                                setFiltro({
                                                    ...filtro,
                                                    pagamentoA: value
                                                        ? value.label
                                                        : '',
                                                    pagamentoAValue: value
                                                        ? value
                                                        : '',
                                                    })
                                                }
                                            }
                                            value={getValues().pagamentoAValue ? getValues().pagamentoAValue : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Pagamento a:'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("pagamentoAValue")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Nº Documento:"
                                            onChange={(e) => {
                                                setValue("documento", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    documento: e.target.value,
                                                })
                                            }}
                                            value={getValues().documento}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            {...register("documento")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Compensação Inicial:"
                                            onChange={(e) => {
                                                setValue("dataCompensacaoInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataCompensacaoInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            value={getValues().dataCompensacaoInicio}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataCompensacaoInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Compensação Final:"
                                            onChange={(e) => {
                                                setValue("dataCompensacaoFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataCompensacaoFim:
                                                        e.target.value,
                                                })
                                            }}
                                            value={getValues().dataCompensacaoFim}
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataCompensacaoFim")}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={situacoes || []}
                                            getOptionLabel={(option) =>
                                                option || ''
                                            }
                                            onChange={(event, value) => {
                                                setValue("situacao", value)

                                                setFiltro({
                                                    ...filtro,
                                                    situacao: value ? value : '',
                                                })
                                            }}
                                            value={getValues().situacao ? getValues().situacao : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Situação:"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("situacao")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Doc. Pagamento:"
                                            value={getValues().documentoPagamento}
                                            onChange={(e) => {
                                                setValue("documentoPagamento", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    documentoPagamento:
                                                        e.target.value,
                                                })
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            {...register("documentoPagamento")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Vencimento Inicial:"
                                            onChange={(e) => {
                                                setValue("dataVencimentoInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataVencimentoInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            value={getValues().dataVencimentoInicio}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataVencimentoInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Vencimento Final:"
                                            onChange={(e) => {
                                                setValue("dataVencimentoFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataVencimentoFim:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            value={getValues().dataVencimentoFim}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataVencimentoFim")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            key={filtro.centroCusto}
                                            options={props.centroCusto || []}
                                            getOptionLabel={(option) =>
                                                option?.descricao || ''
                                            }
                                            onChange={(event, option) => {
                                                if (option !== null) {
                                                    setValue("centroCusto", option)
                                                    setValue("centroCustoId", option.id)

                                                    setFiltro({
                                                        ...filtro,
                                                        centroCusto: option,
                                                        centroCustoId:
                                                            option.id,
                                                    })
                                                } else {
                                                    setValue("centroCusto", "")
                                                    setValue("centroCustoId", "")

                                                    setFiltro({
                                                        ...filtro,
                                                        centroCusto: '',
                                                        centroCustoId: '',
                                                    })
                                                }
                                            }}
                                            value={getValues().centroCusto ? getValues().centroCusto : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Centro de Custo"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("centroCusto")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Descrição:"
                                            onChange={(e) => {
                                                setValue("descricao", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    descricao: e.target.value,
                                                })
                                            }}
                                            value={getValues().descricao}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            {...register("descricao")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Pagamento:"
                                            onChange={(e) => {
                                                setValue("dataPagamentoInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataPagamentoInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            value={getValues().dataPagamentoInicio}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataPagamentoInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Pagamento Final:"
                                            onChange={(e) => {
                                                setValue("dataPagamentoFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataPagamentoFim:
                                                        e.target.value,
                                                })
                                            }}
                                            type="date"
                                            value={getValues().dataPagamentoFim}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataPagamentoFim")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.contaGerencial || []}
                                            getOptionLabel={(option) =>
                                                option?.descricao || ''
                                            }
                                            onChange={(event, option) => {
                                                setValue("contaGerencial", option)
                                                setValue("contaGerencialId", option ? option.id : "")

                                                setFiltro({
                                                    ...filtro,
                                                    contaGerencial: option,
                                                    contaGerencialId: option
                                                        ? option.id
                                                        : '',
                                                })
                                            }}
                                            value={getValues().contaGerencial ? getValues().contaGerencial : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Conta Gerencial'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("contaGerencial")}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={props.produtosServicos}
                                            getOptionLabel={(option) =>
                                                option?.nome || ''
                                            }
                                            onChange={(event, option) => {
                                                setValue("produtoServico", option)
                                                setValue("produtoServicoId", option ? option.id : "")

                                                setFiltro({
                                                    ...filtro,
                                                    produtoServico: option,
                                                    produtoServicoId: option
                                                        ? option.id
                                                        : '',
                                                })
                                            }}
                                            value={getValues().produtoServico ? getValues().produtoServico : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={'Produtos/Serviços'}
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("produtoServico")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={vencimentoOpcoes}
                                            getOptionLabel={(option) =>
                                                option?.nome || ''
                                            }
                                            onChange={(event, option) => {
                                                setValue("vencimentoObjeto", option)
                                                setValue("vencimento", option ? option.id : "")

                                                setFiltro({
                                                    ...filtro,
                                                    vencimentoObjeto: option,
                                                    vencimento: option
                                                        ? option.id
                                                        : '',
                                                })
                                            }}
                                            value={getValues().vencimentoObjeto ? getValues().vencimentoObjeto : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Vencimento"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("vencimentoObjeto")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={pagamentoOpcoes}
                                            getOptionLabel={(option) =>
                                                option?.nome || ''
                                            }
                                            onChange={(event, option) => {
                                                setValue("pagamentoObjeto", option)
                                                setValue("pagamento", option ? option.id : "")

                                                setFiltro({
                                                    ...filtro,
                                                    pagamentoObjeto: option,
                                                    pagamento: option
                                                        ? option.id
                                                        : '',
                                                })
                                            }}
                                            value={getValues().pagamentoObjeto ? getValues().pagamentoObjeto : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Pagamento"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("pagamentoObjeto")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            options={compensacaoOpcoes}
                                            getOptionLabel={(option) =>
                                                option?.nome || ''
                                            }
                                            onChange={(event, option) => {
                                                setValue("compensacaoObjeto", option)
                                                setValue("compensacao", option ? option.id : "")

                                                setFiltro({
                                                    ...filtro,
                                                    compensacaoObjeto: option,
                                                    compensacao: option
                                                        ? option.id
                                                        : '',
                                                })
                                            }}
                                            value={getValues().compensacaoObjeto ? getValues().compensacaoObjeto : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Compensação"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            {...register("compensacaoObjeto")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Emissão Inicial:"
                                            name="dataEmissao"
                                            type="date"
                                            onChange={(e) => {
                                                setValue("dataEmissaoInicio", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataEmissaoInicio:
                                                        e.target.value,
                                                })
                                            }}
                                            className={classes.textField}
                                            value={getValues().dataEmissaoInicio}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataEmissaoInicio")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Data de Emissão Final:"
                                            name="dataEmissao"
                                            type="date"
                                            onChange={(e) => {
                                                setValue("dataEmissaoFim", e.target.value)

                                                setFiltro({
                                                    ...filtro,
                                                    dataEmissaoFim:
                                                        e.target.value,
                                                })
                                            }}
                                            className={classes.textField}
                                            value={getValues().dataEmissaoFim}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: '9999-12-31',
                                                },
                                            }}
                                            {...register("dataEmissaoFim")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />

                            <Grid
                                xs={12}
                                container
                                item
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                className={classes.margin}
                            >
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    size="small"
                                    onClick={clearFiltros}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={(e) => submit(e)}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
            </Accordion>
        </Box>
    )
}

export default Toolbar
