import React, { useState, useEffect } from 'react'
import Page from 'components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from 'components/BasicPageTopBar/Toolbar'
import AdiantamentoFornecedoresClientesListagem from '../components/adiantamentoFornecedoresClientes.listagem'
import Titulo from 'components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import AdiantamentoService from 'services/AdiantamentoFornecedoresClientesService'
import ToastHelper from 'utils/toastHelper'
import ObjectHelper from 'utils/objetcHelper'
import Request from '../request'


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const AdiantamentoFornecedoresClientesPagar = (props) => {
    
    const classes = useStyles()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [contas, setContas] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.adiantamento.filtroListagem)
    )


    useEffect(() => {
        const getAdiantamentos = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = false
            novoFiltro.ordem = 'documento'
            novoFiltro.tamanhoPagina = 100
            novoFiltro.creditoDebito = 1
            buscarTudo(novoFiltro)
        }
        getAdiantamentos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        setFiltro(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_ADIANTAMENTO_PAGAR',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (param) => {
        param.creditoDebito = 1;
        param.tamanhoPagina = 100;
        buscarTudo(param)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_ADIANTAMENTO_PAGAR',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        props.history.push({
            pathname: `/app/adiantamentoFornecedoresClientes/pagar/cadastro`,
            state: { pagarReceber: 'pagar' }
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarTudo(novoFiltro)
    }

    const buscarTudo = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        if (filtroParaPesquisa.ordem === undefined)
            filtroParaPesquisa.ordem = 'documento'
        await alteraFiltro(filtroParaPesquisa)
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await AdiantamentoService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.totalItens = response.data.result.totalItens
                    newFiltro.totalPaginas = response.data.result.totalPaginas
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina || 100
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    newFiltro.ordem = filtro.ordem

                    alteraFiltro(newFiltro)
                    setContas(response.data.result.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.data.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error('Erro ao tentar buscar adiantamentos')
                setLoading(false)
            })
    }

    const editar = (item) => {
        const type = item.dataPagamento ? 'visualizar' : 'editar'
        props.history.push({
            pathname: `/app/adiantamentoFornecedoresClientes/pagar/${type}/${item.id}`,
        })
    }




    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        if (numeroColuna === -1) return

        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        if (novoFiltro.ordem === 'nomeFantasia') 
            novoFiltro.ordem = 'FornecedorCliente.NomeFantasia'
            
        alteraFiltro(novoFiltro)
        buscarTudo(novoFiltro)
    }

   
    const [opcoesFornecedoresClientes, setOpcoesFornecedoresClientes] =
        useState([])

    const buscarFornecedoresClientes = async (param) => {
        let data = await Request.buscarFornecedoresClientes(param)
        data = await ObjectHelper.ordenaLista(data, 'label')
        setOpcoesFornecedoresClientes(data)
    }

    useEffect(() => {
        buscarFornecedoresClientes()
    }, [])

    return (
        <Page
            className={classes.root}
            title={'Adiantamentos a Pagar'}
        >
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Adiantamentos a Pagar'} />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    temFiltroAvancado
                    filtroPeriodoDataVencimento
                    filtroSituacaoAdiantamento
                    filtroFornecedoresClientes={opcoesFornecedoresClientes}

                />
                <Box mt={3}>
                    <AdiantamentoFornecedoresClientesListagem
                        data={contas}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={100}
                        editar={editar}
                        mudaOrdenacao={mudaOrdenacao}
                        renderizarLinhasEmBranco={false}
                        buscarTudo={() => buscarTudo(filtro)}
                        pagarReceber="pagar"
                    />
                </Box>
            </Container>
        </Page>
    )
}
