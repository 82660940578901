import * as Yup from 'yup'

export const MovimentoEstoqueEsquema = Yup.object().shape({
    tipoOperacao: Yup.string(),
    data: Yup.string().required('Campo obrigatório'),
    operacao: Yup.object().required('Campo obrigatório').nullable(),
    produto: Yup.object().required('Campo obrigatório').nullable(),
    local: Yup.object().required('Campo obrigatório').nullable(),
    quantidade: Yup.string()
        .required('Campo obrigatório')
        .test(
            'len',
            'Valor precisa ser maior do que zero',
            (val) => parseFloat(val.replaceAll('.', '').replace(',', '.')) > 0
        ),
    valorUnitario: Yup.string()
        .required('Campo obrigatório')
        .test(
            'len',
            'Valor precisa ser maior do que zero',
            (val) =>
                parseFloat(
                    val.replace('R$', '').replaceAll('.', '').replace(',', '.')
                ) > 0
        ),
    unidadeMedida: Yup.object().required('Campo obrigatório').nullable(),
    contaGerencial: Yup.object().required('Campo obrigatório').nullable(),
    safra: Yup.object()
        .nullable()
        .when('produto', {
            is: (val) => val && val.produtoAcabado,
            then: Yup.object().required('Campo obrigatório'),
        }),
    centroCusto: Yup.object()
        .nullable()
        .when('tipoOperacao', {
            is: (val) => val && val === 'saida',
            then: Yup.object().required('Campo obrigatório'),
        }),
})
