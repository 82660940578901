import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AppBar, IconButton, Toolbar, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Logo from '../../components/Logo/Logo'
import UserMenu from '../../components/UserMenu'
import MenuIcon from '@material-ui/icons/Menu'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FazendaService from '../../services/FazendaService'
import ObjectHelper from '../../utils/objetcHelper'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import { useHistory } from 'react-router-dom'
import watch from 'redux-watch'
import { Store } from '../../store'

const MenuProps = {
    PaperProps: {
        style: {
            width: 250,
            marginTop: 40,
        },
    },
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        width: '100%',
        top: 0,
    },
    appBar: {
        backgroundColor: '#fff',
    },
    toolbar: {
        display: 'flex',
    },
    right: {
        marginLeft: 'auto',
    },
    blankContent: {
        width: '250px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    routerLink: {
        marginRight: theme.spacing(7),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    logo: {
        marginRight: theme.spacing(5),
    },
    marginRightUserMenu: {
        marginRight: '1%',
    },
    menuButton: {
        marginRight: 36,
        color: '#2E7D32',
    },
    hide: {
        display: 'none',
    },
}))

const TopBar = ({
    className,
    onMobileNavOpen,
    onFazendaSelecionada,
    onFazendasLoading,
    onFazendasLoaded,
    ...rest
}) => {
    let history = useHistory()
    const classes = useStyles()
    const [fazendas, setFazendas] = useState([])
    const [fazendaSelecionada, setFazendaSelecionada] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
    )

    useEffect(() => {
        async function init() {
            const w = watch(Store.getState, 'fazenda.fazendaSelecionada')
            Store.subscribe(
                w((newVal, oldVal, objectPath) => {
                    setFazenda({ value: newVal.id, label: newVal.nomeRazao })
                })
            )
            await getFazendas()
            let fazenda = JSON.parse(localStorage.getItem('fazenda'))
            await setFazendaSelecionada(fazenda)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (onFazendaSelecionada) {
            if (fazendaSelecionada) {
                onFazendaSelecionada(true)
            } else {
                onFazendaSelecionada(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fazendaSelecionada])

    const getFazendas = async () => {
        onFazendasLoading()
        await FazendaService.buscarTudo()
            .then((response) => {
                let fazendas = ObjectHelper.getValuesFazendaParseToSelect(
                    response.data
                )
                setFazendas(fazendas)
            })
            .finally(() => {
                onFazendasLoaded()
            })
    }

    const setFazenda = (fazenda) => {
        setFazendaSelecionada(fazenda)
        localStorage.setItem('fazenda', JSON.stringify(fazenda))
        history.push('/app/conta/pagar')
        history.go()
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color={'primary'}
                        aria-label="open drawer"
                        onClick={
                            rest.open
                                ? rest.handleDrawerClose
                                : rest.handleDrawerOpen
                        }
                        edge="start"
                        style={{ marginRight: '15px'}}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: rest.open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Logo className={classes.logo} />
                    <FormControl className={classes.formControl}>
                        <InputLabel id="fazenda-menu-label">
                            Clientes
                        </InputLabel>
                        <Select
                            id="fazenda-menu-select"
                            value={fazendaSelecionada}
                            onChange={(e) => setFazenda(e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected && (
                                        <Chip
                                            size="small"
                                            key={selected.value}
                                            label={selected.label}
                                            className={classes.chip}
                                        />
                                    )}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {fazendas.map((obj) => (
                                <MenuItem key={obj} value={obj}>
                                    {obj.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/*
                        <Hidden lgUp>
                            <IconButton color="inherit" onClick={onMobileNavOpen}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    */}
                    <div className={classes.right}>
                        <UserMenu />
                    </div>
                    {rest.open && <div className={classes.blankContent}></div>}
                </Toolbar>
            </AppBar>
        </div>
    )
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
}

export default TopBar
