import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { yupResolver } from '@hookform/resolvers'
import { produtoEsquema } from './ProdutosServicos.validate'
import { servicoEsquema } from './ProdutosServicos.validate'
import { Box } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import IconeInfo from '@material-ui/icons/InfoOutlined'
import AddCircle from '@material-ui/icons/AddCircle'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NumberFormat from 'react-number-format'
import ModalCadastroCategoria from './cadastrosRapido/ModalCadastroCategoria'
import CategoriaProdutoServicoService from '../../services/CategoriaProdutosServicosService'
import UnidadeDeMedidaService from '../../services/UnidadeDeMedidaService'
import CentroDeCustoService from '../../services/CentroCustoService'
import FornecedorClienteService from '../../services/FornecedorClienteService'
import ContaGerencialService from '../../services/ContaGerencialService'
import LocalEstoqueService from '../../services/LocaisEstoqueService'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProdutosServicosService from '../../services/ProdutosServicosService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'

import ModalCadastroCentroCusto from './cadastrosRapido/ModalCadastroCentroCusto'
import ModalCadastroFornecedor from './cadastrosRapido/ModalCadastroFornecedor'
import ModalCadastroProdutoTecnico from './cadastrosRapido/ModalCadastroProdutoTecnico'
import ModalCadastroLocalEstoque from './cadastrosRapido/ModalCadastroLocalEstoque'
import insumoService from 'services/tecnico/insumoService'
import ModalCadastroContaGerencial from './cadastrosRapido/ModalCadastroContaGerencial'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlCadastroRapido: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
}))

export const ProdutosServicosCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const [loading, setLoading] = useState(true)
    const {
        match: { params },
    } = props
    const [eUmProduto, SeteUmProduto] = useState(true)
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        errors,
        control,
        watch,
        clearErrors,
        setError,
    } = useForm({
        defaultValues: {
            eUmProduto: true,
            id: '',
            produtoAcabado: false,
            produtoAdministrativo: false,
            categoria: null,
            codigo: '',
            nome: '',
            unidadeMedida: null,
            centroDeCustoPadrao: null,
            fornecedorPadrao: null,
            contaGerencialPadraoCompra: null,
            contaGerencialPadraoVenda: null,
            observacao: '',
            possuiControleDeEstoque: 'estoque',
            estoquePadrao: null,
            quantidadeMinima: '',
            produtoTecnicoRelacionado: null,
            localDeEstoquePadrao: null,
            eProdutoTecnico: false,
        },
        resolver: yupResolver(eUmProduto ? produtoEsquema : servicoEsquema),
    })
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [open, setOpen] = React.useState(false)
    const [openModalCadastroCentroCusto, setOpenModalCadastroCentroCusto] =
        useState(false)
    const [openModalCadastroFornecedor, setOpenModalCadastroFornecedor] =
        useState(false)
    const [
        openModalCadastroProdutoTecnico,
        setOpenModalCadastroProdutoTecnico,
    ] = useState(false)
    const [
        openModalCadastroContaGerencialCompra,
        setOpenModalCadastroContaGerencialCompra,
    ] = useState(false)
    const [openModalCadastroLocalEstoque, setOpenModalCadastroLocalEstoque] =
        useState(false)
    const [openModalCadastroContaGerencialVenda, setOpenModalCadastroContaGerencialVenda] =
        useState(false)
    const [opcoesCategorias, setOpcoesCategorias] = useState([])
    const [opcoesUnidadeMedida, setOpcoesUnidadeMedida] = useState([])
    const [opcoesCentroDeCusto, setOpcoesCentroDeCusto] = useState([])
    const [opcoesFornecedor, setOpcoesFornecedor] = useState([])
    const [
        opcoesContaGerencialPadraoVenda,
        setOpcoesContaGerencialPadraoVenda,
    ] = useState([])
    const [
        opcoesContaGerencialPadraoCompra,
        setOpcoesContaGerencialPadraoCompra,
    ] = useState([])
    const [opcoesLocalEstoque, setOpcoesLocalEstoque] = useState([])
    const [opcoesProdutoRelacionado, setOpcoesProdutoRelacionado] = useState([])
    const [unidadeMedida, setUnidadeMedida] = useState('')
    const [centroCustoPadrao, setCentroCustoPadrao] = useState('')
    const [fornecedorPadrao, setFornecedorPadrao] = useState('')

    useEffect(() => {
        async function init() {
            const {
                match: { params },
            } = props
            //metodos iniciais
            await buscarCategorias()
            await buscarUnidadesDeMedida()
            await buscarCentroDeCusto()
            await buscarFornecedores()
            await buscarContasGerenciaisPadrao()
            await buscarLocaisEstoque()
            await buscarProdutosRelacionados()
            if (params.id) {
                await buscarProdutoServicoPorId(params.id)
            } else await setLoading(false)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const buscarProdutoServicoPorId = (id) => {
        ProdutosServicosService.buscarPorId(id).then((response) => {
            SeteUmProduto(response.data.produto)
            setValue('eUmProduto', response.data.produto)

            if (response.data.produto)
                setValue('produtoAcabado', response.data.produtoAcabado)

            if (response.data.produto)
                setValue('eProdutoTecnico', response.data.produtoTecnico)

            if (response.data.possuiControleDeEstoque) {
                setValue('possuiControleDeEstoque', 'estoque')
            } else {
                setValue('possuiControleDeEstoque', 'direto')
            }
            setValue('nome', response.data.nome)
            setValue('codigo', response.data.codigo)
            setValue('id', response.data.id)
            setValue('observacao', response.data.observacao)
            setValue('categoria', {
                value: response.data.categoriaProdutoServico.id,
                label: response.data.categoriaProdutoServico.nome,
                ativo: undefined,
            })
            if (response.data.unidadeMedida) {
                let unidadeMedida = {
                    value: response.data.unidadeMedida.id,
                    label: response.data.unidadeMedida.sigla,
                }
                setValue('unidadeMedida', unidadeMedida)
                setUnidadeMedida(unidadeMedida)
            }
            if (response.data.centroCustoPadrao) {
                let centroCustoPadrao = {
                    value: response.data.centroCustoPadrao.id,
                    label: response.data.centroCustoPadrao.descricao,
                }
                setCentroCustoPadrao(centroCustoPadrao)
                setValue('centroDeCustoPadrao', centroCustoPadrao)
            }
            if (response.data.fornecedorPadrao) {
                let fornecedorPadrao = {
                    value: response.data.fornecedorPadrao.id,
                    label: response.data.fornecedorPadrao.nomeFantasia,
                }
                setValue('fornecedorPadrao', fornecedorPadrao)
                setFornecedorPadrao(fornecedorPadrao)
            }
            if (response.data.contaGerencialPadraoCompra) {
                let contaGerencialPadraoCompra = {
                    value: response.data.contaGerencialPadraoCompra.id,
                    label:
                        response.data.contaGerencialPadraoCompra.numero +
                        ' - ' +
                        response.data.contaGerencialPadraoCompra.descricao,
                }
                setValue(
                    'contaGerencialPadraoCompra',
                    contaGerencialPadraoCompra
                )
            }
            if (response.data.contaGerencialPadraoVenda) {
                let contaGerencialPadraoVenda = {
                    value: response.data.contaGerencialPadraoVenda.id,
                    label:
                        response.data.contaGerencialPadraoVenda.numero +
                        ' - ' +
                        response.data.contaGerencialPadraoVenda.descricao,
                }
                setValue('contaGerencialPadraoVenda', contaGerencialPadraoVenda)
            }
            if (response.data.localEstoquePadrao) {
                let localDeEstoque = {
                    id: response.data.localEstoquePadrao.id,
                    nome:
                        response.data.localEstoquePadrao.nome +
                        ' - ' +
                        response.data.localEstoquePadrao.tipoEstoque.nome,
                }
                setValue('localDeEstoquePadrao', localDeEstoque)
                setValue(
                    'quantidadeMinima',
                    response.data.quantidadeMinima || ''
                )
            }
            if (response.data.recurso) {
                let recurso = {
                    id: response.data.recurso.id,
                    descricao: response.data.recurso.nome,
                }
                setValue('produtoTecnicoRelacionado', recurso)
            }

            setLoading(false)
        })
    }

    const buscarLocaisEstoque = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Nome'
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.ativo = true
        let params = await ObjectHelper.createParams(novoFiltro)
        await LocalEstoqueService.buscarTudo(params).then(async (response) => {
            var locais = []
            await response.data.itens.forEach((item) =>
                locais.push({
                    id: item['id'],
                    nome: item['nome'] + ' - ' + item['tipoEstoque'],
                })
            )
            await setOpcoesLocalEstoque(locais)
        })
    }

    const buscarContasGerenciaisPadrao = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Descricao'
        novoFiltro.tipo = 1
        novoFiltro.ativo = true
        novoFiltro.operacao = 0
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.semPadroes = true
        let params = await ObjectHelper.createParams(novoFiltro)
        await ContaGerencialService.buscarTudo(params).then(
            async (response) => {
                let ContasGerenciais = []
                await response.data.itens.forEach((item) => {
                    ContasGerenciais.push({
                        label: item.numero + ' - ' + item.descricao,
                        value: item.id,
                    })
                })
                ContasGerenciais = await ObjectHelper.ordenaLista(
                    ContasGerenciais,
                    'label'
                )
                setOpcoesContaGerencialPadraoVenda(ContasGerenciais)
            }
        )

        novoFiltro.operacao = 1
        params = await ObjectHelper.createParams(novoFiltro)
        await ContaGerencialService.buscarTudo(params).then(
            async (response) => {
                let ContasGerenciais = []
                await response.data.itens.forEach((item) => {
                    ContasGerenciais.push({
                        label: item.numero + ' - ' + item.descricao,
                        value: item.id,
                    })
                })
                ContasGerenciais = await ObjectHelper.ordenaLista(
                    ContasGerenciais,
                    'label'
                )
                setOpcoesContaGerencialPadraoCompra(ContasGerenciais)
            }
        )
    }

    const buscarContasGerenciaisPadraoPorOperacao = async (operacao) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Descricao'
        novoFiltro.tipo = 1
        novoFiltro.ativo = true
        novoFiltro.operacao = operacao
        novoFiltro.tamanhoPagina = 99999
        novoFiltro.semPadroes = true
        let params = await ObjectHelper.createParams(novoFiltro)
        await ContaGerencialService.buscarTudo(params).then(
            async (response) => {
                let ContasGerenciais = []
                await response.data.itens.forEach((item) => {
                    ContasGerenciais.push({
                        label: item.numero + ' - ' + item.descricao,
                        value: item.id,
                    })
                })
                ContasGerenciais = await ObjectHelper.ordenaLista(
                    ContasGerenciais,
                    'label'
                )
                if (operacao === 0) {
                    setOpcoesContaGerencialPadraoVenda(ContasGerenciais)
                } else {
                    setOpcoesContaGerencialPadraoCompra(ContasGerenciais)
                }
            }
        )
    }

    const buscarFornecedores = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'NomeFantasia'
        novoFiltro.TipoFornecedorCliente = 0
        novoFiltro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(novoFiltro)
        FornecedorClienteService.buscarTudo(params).then((response) => {
            let fornecedores =
                ObjectHelper.getValuesNomeFantasiaLabelParseToSelect(
                    response.data.itens
                )
            setOpcoesFornecedor(fornecedores)
        })
    }

    const buscarCentroDeCusto = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Descricao'
        novoFiltro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(novoFiltro)
        CentroDeCustoService.buscarTudo(params).then((response) => {
            let centrosDeCusto =
                ObjectHelper.getValuesDescricaoLabelParseToSelect(
                    response.data.itens
                )
            setOpcoesCentroDeCusto(centrosDeCusto)
        })
    }

    const buscarUnidadesDeMedida = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.fazendaId = fazendaId
        novoFiltro.ordem = 'Sigla'
        novoFiltro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(novoFiltro)
        UnidadeDeMedidaService.buscarTudo(params).then((response) => {
            let unidadesDeMedida = ObjectHelper.parseValuesUnidadesToSelect(
                response.data.itens
            )
            setOpcoesUnidadeMedida(unidadesDeMedida)
        })
    }

    const buscarCategorias = async (nome) => {
        let novoFiltro = { fazendaId }
        novoFiltro.decrescente = false
        novoFiltro.ordem = 'Nome'
        novoFiltro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(novoFiltro)
        CategoriaProdutoServicoService.buscarTudo(params).then((response) => {
            let categorias = ObjectHelper.getValuesParseToSelect(
                response.data.itens
            )
            setOpcoesCategorias(categorias)
        })
    }

    const cadastraCategoria = (nome) => {
        CategoriaProdutoServicoService.cadastrar({
            nome: nome,
            fazendaId: fazendaId,
        })
            .then((response) => {
                setOpen(false)
                buscarCategorias()
                setValue('categoria', {
                    value: response.data.id,
                    label: response.data.nome,
                })
                clearErrors('categoria')
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const cadastrarCentroCusto = (values) => {
        var centroCustoDTO = values
        centroCustoDTO.fazendaId = fazendaId

        CentroDeCustoService.cadastrar(centroCustoDTO)
            .then((response) => {
                setOpenModalCadastroCentroCusto(false)
                buscarCentroDeCusto()
                setCentroCustoPadrao({
                    value: response.data.id,
                    label: response.data.descricao,
                })
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const cadastrarFornecedor = (values) => {
        var fornecedorDTO = values
        fornecedorDTO.fazendaId = fazendaId

        FornecedorClienteService.cadastrar(fornecedorDTO)
            .then((response) => {
                setOpenModalCadastroFornecedor(false)
                buscarFornecedores()
                setFornecedorPadrao({
                    value: response.data.id,
                    label: response.data.nomeFantasia,
                })
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const cadastrarProdutoTecnico = async (values) => {
        var produtoDTO = values
        produtoDTO.fazendaId = fazendaId
        produtoDTO.materiaisAuxiliares = []

        insumoService.cadastrar(produtoDTO).then((res) => {
            if (!res.data.isOk) {
                ToastHelper.error(res.data.mensagem)
            } else {
                ProdutosServicosService.buscarProdutosTecnicoRelacionado(
                    fazendaId
                ).then(async (response) => {
                    var newProduto = response.data.find(
                        (p) => p.nome === produtoDTO.nome
                    )
                    setValue('produtoTecnicoRelacionado', {
                        id: newProduto.id,
                        descricao: newProduto.nome,
                    })
                    setOpenModalCadastroProdutoTecnico(false)
                    clearErrors('produtoTecnicoRelacionado')
                })
            }
        })
    }

    const cadastrarLocalEstoque = async (values) => {
        let localEstoqueDTO = values
        values.fazendaId = fazendaId
        LocalEstoqueService.cadastrar(localEstoqueDTO)
            .then((res) => {
                setOpenModalCadastroLocalEstoque(false)
                buscarLocaisEstoque()
                setValue('localDeEstoquePadrao', {
                    nome: res.data.nome,
                    id: res.data.id,
                })
                clearErrors('localDeEstoquePadrao')
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
    }

    const onSubmit = async (dados) => {
        let produtoServico = {
            nome: dados.nome,
            codigo: dados.codigo,
            observacao: dados.observacao,
            categoriaProdutoServicoId: dados.categoria?.value,
            unidadeMedidaId: unidadeMedida ? unidadeMedida?.value : '',
            fazendaId: fazendaId,
            produto: eUmProduto,
            produtoAcabado: dados.produtoAcabado,
            produtoTecnico: dados.eProdutoTecnico,
            centroCustoPadraoId: centroCustoPadrao?.value,
            fornecedorPadraoId: fornecedorPadrao?.value,
            contaGerencialPadraoCompraId:
                dados.contaGerencialPadraoCompra?.value,
            contaGerencialPadraoVendaId: dados.contaGerencialPadraoVenda?.value,
            possuiControleDeEstoque:
                dados.possuiControleDeEstoque === 'estoque' ? true : false,
            localEstoquePadraoId: dados.localDeEstoquePadrao?.id,
            quantidadeMinima: dados.quantidadeMinima
                ? dados.quantidadeMinima.replaceAll('.', '').replace(',', '.')
                : null,
            recursoId: dados.produtoTecnicoRelacionado?.id,
        }
        if (params.id) {
            produtoServico.id = params.id ? params.id : ''
            ProdutosServicosService.atualizar(produtoServico)
                .then((response) => {
                    ToastHelper.success(
                        eUmProduto
                            ? 'Produto atualizado com sucesso'
                            : 'Serviço atualizado com sucesso'
                    )
                    history.goBack()
                })
                .catch((error) => {
                    if (
                        error.response.data ===
                        'Já existe um produtos/serviço com este código.'
                    )
                        setError('codigo', {
                            message: 'Este código já foi cadastrado',
                        })
                    ToastHelper.error(error.response.data)
                })
        } else {
            ProdutosServicosService.cadastrar(produtoServico)
                .then((response) => {
                    ToastHelper.success(
                        eUmProduto
                            ? 'Produto cadastrado com sucesso'
                            : 'Serviço cadastrado com sucesso'
                    )
                    if (params.modo === 'aba') {
                        window.close()
                    } else {
                        history.goBack()
                    }
                })
                .catch((error) => {
                    if (
                        error.response.data ===
                        'Já existe um produtos/serviço com este código.'
                    )
                        setError('codigo', {
                            message: 'Este código já foi cadastrado',
                        })

                    ToastHelper.error(error.response.data)
                })
        }
    }

    const buscarProdutosRelacionados = async () => {
        let opcoes = []
        ProdutosServicosService.buscarProdutosTecnicoRelacionado(fazendaId)
            .then(async (response) => {
                response.data.forEach((item) => {
                    opcoes.push({
                        id: item.id,
                        descricao: item.nome,
                    })
                })
                var opcoesOrdenadas = await ObjectHelper.ordenaLista(
                    await opcoes,
                    'descricao',
                    false
                )
                setOpcoesProdutoRelacionado(opcoesOrdenadas)
            })
            .catch((e) => {
                return ToastHelper.error(
                    'Ocorreu um erro, entre em contato com o administrador'
                )
            })
    }

    const isValidCode = async () => {
        var codigo = getValues().codigo
        if (codigo && codigo !== '' && codigo !== ' ') {
            var { data } = await ProdutosServicosService.isValidCode(
                fazendaId,
                getValues().codigo
            )
            if (data === true) {
                clearErrors('codigo')
                return true
            } else {
                setError('codigo', { message: 'Este código já foi cadastrado' })
                return false
            }
        } else {
            clearErrors('codigo')
            return true
        }
    }

    // eslint-disable-next-line no-unused-vars
    function stopPropagation(event) {
        event.stopPropagation()
    }

    return (
        <Page className={classes.root} title="Cadastro de Produtos e Serviços">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Produtos e Serviços'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <>
                                {loading && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.boxLoading}
                                    >
                                        <CircularProgress color="primary" />
                                    </Grid>
                                )}
                                <Grid container item xs={12} display="flex">
                                    <Grid container item xs={12} display="flex">
                                        <Box mt={2}>
                                            <FormLabel component="legend">
                                                Tipo:
                                            </FormLabel>
                                        </Box>
                                        <Box ml={2}>
                                            <RadioGroup
                                                row
                                                aria-label="position"
                                                name="position"
                                                defaultValue="top"
                                            >
                                                <FormControlLabel
                                                    inputRef={register}
                                                    name="eUmProduto"
                                                    value={eUmProduto}
                                                    checked={eUmProduto}
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label="Produto"
                                                    onChange={() => {
                                                        SeteUmProduto(
                                                            !eUmProduto
                                                        )
                                                        setValue(
                                                            'produto',
                                                            !eUmProduto
                                                        )
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value={!eUmProduto}
                                                    checked={!eUmProduto}
                                                    onChange={() => {
                                                        SeteUmProduto(
                                                            !eUmProduto
                                                        )
                                                        setValue(
                                                            'produto',
                                                            !eUmProduto
                                                        )
                                                    }}
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label="Serviço"
                                                />
                                            </RadioGroup>
                                        </Box>
                                        {eUmProduto && (
                                            <Box ml={4}>
                                                <Grid container item xs={12}>
                                                    <Controller
                                                        control={control}
                                                        id="eProdutoTecnico"
                                                        name="eProdutoTecnico"
                                                        as={(props) => (
                                                            <FormControlLabel
                                                                label="É um produto técnico"
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                !e
                                                                                    .target
                                                                                    .checked
                                                                            ) {
                                                                            }
                                                                            props.onChange(
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            )
                                                                        }}
                                                                        checked={
                                                                            props.value
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <Box mt={1}>
                                                        <Tooltip title="Produtos técnicos podem ser vinculados à produtos do módulo técnico para integração com o controle de estoque ao apontar serviços">
                                                            <IconeInfo />
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        )}
                                        {eUmProduto && (
                                            <Box ml={4}>
                                                <Grid container item xs={12}>
                                                    <Controller
                                                        control={control}
                                                        id="produtoAcabado"
                                                        name="produtoAcabado"
                                                        as={(props) => (
                                                            <FormControlLabel
                                                                label="É um produto acabado"
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                !e
                                                                                    .target
                                                                                    .checked
                                                                            ) {
                                                                            }
                                                                            props.onChange(
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            )
                                                                        }}
                                                                        checked={
                                                                            props.value
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <Box mt={1}>
                                                        <Tooltip title="Produto acabado é o produto destinado ao consumidor final, trata-se de um produto, que não requer modificações ou preparações para ser comercializado.">
                                                            <IconeInfo />
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} display="flex">
                                    <Grid container item xs={4} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                {...params}
                                                inputRef={register}
                                                name="nome"
                                                id="nome"
                                                variant="standard"
                                                label="Nome*"
                                                placeholder="Digite o nome"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <p className={classes.errors}>
                                                {errors.nome
                                                    ? errors.nome.message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={4} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <TextField
                                                {...params}
                                                inputRef={register}
                                                name="codigo"
                                                id="codigo"
                                                variant="standard"
                                                label="Código"
                                                placeholder="Digite o código"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={isValidCode}
                                            />
                                            <p className={classes.errors}>
                                                {errors.codigo
                                                    ? errors.codigo.message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={
                                                classes.formControlCadastroRapido
                                            }
                                        >
                                            <Controller
                                                control={control}
                                                id="categoria"
                                                name="categoria"
                                                as={(props) => (
                                                    <Autocomplete
                                                        options={
                                                            opcoesCategorias
                                                        }
                                                        disabled={false}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option
                                                                ? option.label
                                                                : ''
                                                        }
                                                        onChange={(_, data) => {
                                                            props.onChange(data)
                                                        }}
                                                        size="small"
                                                        getOptionSelected={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option?.value ===
                                                            value?.value
                                                        }
                                                        defaultValue={
                                                            getValues()
                                                                .categoria
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label="Categoria*"
                                                                placeholder="Selecione"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                        autoSelect
                                                    />
                                                )}
                                            />
                                            <p className={classes.errors}>
                                                {errors.categoria
                                                    ? errors.categoria.message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={1} display="flex">
                                        <Box ml={2} mt={2}>
                                            <Tooltip title="Cadastrar nova categoria">
                                                <AddCircle
                                                    color="primary"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleOpen()}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} display="flex">
                                    {eUmProduto && (
                                        <Grid
                                            container
                                            item
                                            xs={4}
                                            display="flex"
                                        >
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <Autocomplete
                                                    name="unidadeDeMedida"
                                                    id="unidadeDeMedida"
                                                    options={
                                                        opcoesUnidadeMedida
                                                    }
                                                    disabled={false}
                                                    value={unidadeMedida}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        setUnidadeMedida(
                                                            newValue
                                                        )
                                                        errors[
                                                            'unidadeDeMedida'
                                                        ] = ''
                                                    }}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? option.label
                                                            : ''
                                                    }
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option.value ===
                                                        value.value
                                                    }
                                                    size="small"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputRef={register}
                                                            name="unidadeDeMedida"
                                                            id="unidadeDeMedida"
                                                            variant="standard"
                                                            label="Unidade de Medida*"
                                                            placeholder="Selecione"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                    autoSelect
                                                />
                                                <p className={classes.errors}>
                                                    {errors.unidadeDeMedida
                                                        ? errors.unidadeDeMedida
                                                              .message
                                                        : ''}
                                                </p>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={
                                                classes.formControlCadastroRapido
                                            }
                                        >
                                            <Autocomplete
                                                name="centroDeCustoPadrao"
                                                id="centroDeCustoPadrao"
                                                options={opcoesCentroDeCusto}
                                                disabled={false}
                                                value={centroCustoPadrao}
                                                onChange={(event, newValue) => {
                                                    setCentroCustoPadrao(
                                                        newValue
                                                    )
                                                    errors[
                                                        'centroDeCustoPadrao'
                                                    ] = ''
                                                }}
                                                getOptionLabel={(option) =>
                                                    option ? option.label : ''
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.value === value.value
                                                }
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={register}
                                                        name="centroDeCustoPadrao"
                                                        id="centroDeCustoPadrao"
                                                        variant="standard"
                                                        label="Centro de Custo Padrão"
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                autoSelect
                                            />
                                            <p className={classes.errors}>
                                                {errors.centroDeCustoPadrao
                                                    ? errors.centroDeCustoPadrao
                                                          .message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={1} display="flex">
                                        <Box ml={2} mt={2}>
                                            <Tooltip title="Cadastrar novo centro de custo">
                                                <AddCircle
                                                    color="primary"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        setOpenModalCadastroCentroCusto(
                                                            true
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={
                                                classes.formControlCadastroRapido
                                            }
                                        >
                                            <Autocomplete
                                                name="fornecedorPadrao"
                                                id="fornecedorPadrao"
                                                options={opcoesFornecedor}
                                                disabled={false}
                                                value={fornecedorPadrao}
                                                onChange={(event, newValue) => {
                                                    setFornecedorPadrao(
                                                        newValue
                                                    )
                                                    errors['fornecedorPadrao'] =
                                                        ''
                                                }}
                                                getOptionLabel={(option) =>
                                                    option ? option.label : ''
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.value === value.value
                                                }
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={register}
                                                        name="fornecedorPadrao"
                                                        id="fornecedorPadrao"
                                                        variant="standard"
                                                        label="Fornecedor Padrão"
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                autoSelect
                                            />
                                            <p className={classes.errors}>
                                                {errors.fornecedorPadrao
                                                    ? errors.fornecedorPadrao
                                                          .message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={1} display="flex">
                                        <Box ml={2} mt={2}>
                                            <Tooltip title="Cadastrar novo fornecedor">
                                                <AddCircle
                                                    color="primary"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        setOpenModalCadastroFornecedor(
                                                            true
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} display="flex">
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={
                                                classes.formControlCadastroRapido
                                            }
                                        >
                                            <Controller
                                                control={control}
                                                id="contaGerencialPadraoCompra"
                                                name="contaGerencialPadraoCompra"
                                                as={(props) => (
                                                    <Autocomplete
                                                        options={
                                                            opcoesContaGerencialPadraoCompra
                                                        }
                                                        disabled={false}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option
                                                                ? option.label
                                                                : ''
                                                        }
                                                        onChange={(_, data) => {
                                                            props.onChange(data)
                                                        }}
                                                        size="small"
                                                        getOptionSelected={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option?.value ===
                                                            value?.value
                                                        }
                                                        defaultValue={getValues(
                                                            'contaGerencialPadraoCompra'
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label={
                                                                    watch(
                                                                        'possuiControleDeEstoque'
                                                                    ) ===
                                                                    'estoque'
                                                                        ? 'Conta Gerencial Padrão de Compra'
                                                                        : 'Conta Gerencial Padrão de Compra'
                                                                }
                                                                placeholder="Selecione"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                        autoSelect
                                                    />
                                                )}
                                            />
                                            <p className={classes.errors}>
                                                {errors.contaGerencialPadraoCompra
                                                    ? errors
                                                          .contaGerencialPadraoCompra
                                                          .message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={1} display="flex">
                                        <Box ml={2} mt={2}>
                                            <Tooltip title="Cadastrar nova conta gerencial padrão de compra">
                                                <AddCircle
                                                    color="primary"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        setOpenModalCadastroContaGerencialCompra(
                                                            true
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid container item xs={3} display="flex">
                                        <FormControl
                                            className={classes.formControlCadastroRapido}
                                        >
                                            <Controller
                                                control={control}
                                                id="contaGerencialPadraoVenda"
                                                name="contaGerencialPadraoVenda"
                                                as={(props) => (
                                                    <Autocomplete
                                                        options={
                                                            opcoesContaGerencialPadraoVenda
                                                        }
                                                        disabled={false}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option
                                                                ? option.label
                                                                : ''
                                                        }
                                                        onChange={(_, data) => {
                                                            props.onChange(data)
                                                        }}
                                                        size="small"
                                                        getOptionSelected={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option?.value ===
                                                            value?.value
                                                        }
                                                        defaultValue={getValues(
                                                            'contaGerencialPadraoVenda'
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label={
                                                                    watch(
                                                                        'produtoAcabado'
                                                                    )
                                                                        ? 'Conta Gerencial Padrão de Venda*'
                                                                        : 'Conta Gerencial Padrão de Venda'
                                                                }
                                                                placeholder="Selecione"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                        autoSelect
                                                    />
                                                )}
                                            />

                                            <p className={classes.errors}>
                                                {errors.contaGerencialPadraoVenda
                                                    ? errors
                                                          .contaGerencialPadraoVenda
                                                          .message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={1} display="flex">
                                        <Box ml={2} mt={2}>
                                            <Tooltip title="Cadastrar nova conta gerencial padrão de venda">
                                                <AddCircle
                                                    color="primary"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        setOpenModalCadastroContaGerencialVenda(
                                                            true
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    {watch('eProdutoTecnico') && eUmProduto && (
                                        <>
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControlCadastroRapido
                                                    }
                                                >
                                                    <Controller
                                                        name="produtoTecnicoRelacionado"
                                                        id="produtoTecnicoRelacionado"
                                                        control={control}
                                                        as={(props) => (
                                                            <Autocomplete
                                                                options={
                                                                    opcoesProdutoRelacionado
                                                                }
                                                                disabled={false}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option
                                                                        ? option.descricao
                                                                        : ''
                                                                }
                                                                onChange={(
                                                                    _,
                                                                    data
                                                                ) => {
                                                                    props.onChange(
                                                                        data
                                                                    )
                                                                }}
                                                                size="small"
                                                                getOptionSelected={(
                                                                    option,
                                                                    value
                                                                ) =>
                                                                    option?.id ===
                                                                    value?.id
                                                                }
                                                                value={getValues(
                                                                    'produtoTecnicoRelacionado'
                                                                )}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Produto Técnico Relacionado*"
                                                                        placeholder="Selecione"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                )}
                                                                autoSelect
                                                            />
                                                        )}
                                                    />
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        {errors.produtoTecnicoRelacionado
                                                            ? errors
                                                                  .produtoTecnicoRelacionado
                                                                  .message
                                                            : ''}
                                                    </p>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={1}
                                                display="flex"
                                            >
                                                <Box ml={2} mt={2}>
                                                    <Tooltip title="Cadastrar novo Produto Técnico">
                                                        <AddCircle
                                                            color="primary"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() =>
                                                                setOpenModalCadastroProdutoTecnico(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid container item xs={12} display="flex">
                                    <FormControl
                                        className={classes.formControlTextArea}
                                    >
                                        <TextField
                                            type="textArea"
                                            label="Observação"
                                            className={classes.textField}
                                            inputRef={register}
                                            id="observacao"
                                            name="observacao"
                                            multiline
                                            rows={2}
                                            rowsMax={4}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                {watch('eUmProduto') && (
                                    <>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                display="flex"
                                            >
                                                <Controller
                                                    control={control}
                                                    id="possuiControleDeEstoque"
                                                    name="possuiControleDeEstoque"
                                                    as={
                                                        <RadioGroup row>
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio color="primary" />
                                                                }
                                                                label="Possui controle de estoque"
                                                                value="estoque"
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio color="primary" />
                                                                }
                                                                label="Aplicação direta"
                                                                value="direto"
                                                            />
                                                        </RadioGroup>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            display="flex"
                                        >
                                            {watch('eUmProduto') &&
                                                watch(
                                                    'possuiControleDeEstoque'
                                                ) === 'estoque' && (
                                                    <>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={3}
                                                            display="flex"
                                                        >
                                                            <FormControl
                                                                className={
                                                                    classes.formControlCadastroRapido
                                                                }
                                                            >
                                                                <Controller
                                                                    control={
                                                                        control
                                                                    }
                                                                    id="localDeEstoquePadrao"
                                                                    name="localDeEstoquePadrao"
                                                                    as={(
                                                                        props
                                                                    ) => (
                                                                        <Autocomplete
                                                                            options={
                                                                                opcoesLocalEstoque
                                                                            }
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option
                                                                                    ? option.nome
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                _,
                                                                                data
                                                                            ) => {
                                                                                props.onChange(
                                                                                    data
                                                                                )
                                                                            }}
                                                                            size="small"
                                                                            getOptionSelected={(
                                                                                option,
                                                                                value
                                                                            ) =>
                                                                                option?.id ===
                                                                                value?.id
                                                                            }
                                                                            defaultValue={getValues(
                                                                                'localDeEstoquePadrao'
                                                                            )}
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    variant="standard"
                                                                                    label="Local de Estoque Padrão*"
                                                                                    placeholder="Selecione"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            autoSelect
                                                                        />
                                                                    )}
                                                                />
                                                                <p
                                                                    className={
                                                                        classes.errors
                                                                    }
                                                                >
                                                                    {errors.localDeEstoquePadrao
                                                                        ? errors
                                                                              .localDeEstoquePadrao
                                                                              .message
                                                                        : ''}
                                                                </p>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={1}
                                                            display="flex"
                                                        >
                                                            <Box ml={2} mt={2}>
                                                                <Tooltip title="Cadastrar novo Local de Estoque">
                                                                    <AddCircle
                                                                        id="quickNewStockLocation"
                                                                        color="primary"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() =>
                                                                            setOpenModalCadastroLocalEstoque(
                                                                                true
                                                                            )
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={4}
                                                            display="flex"
                                                        >
                                                            <FormControl
                                                                className={
                                                                    classes.formControlValor
                                                                }
                                                            >
                                                                <Controller
                                                                    control={
                                                                        control
                                                                    }
                                                                    id="quantidadeMinima"
                                                                    name="quantidadeMinima"
                                                                    as={
                                                                        <NumberFormat
                                                                            label="Quantidade Mínima:"
                                                                            customInput={
                                                                                TextField
                                                                            }
                                                                            thousandSeparator={
                                                                                '.'
                                                                            }
                                                                            decimalSeparator={
                                                                                ','
                                                                            }
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            inputProps={{
                                                                                min: 0,
                                                                                style: {
                                                                                    textAlign:
                                                                                        'right',
                                                                                },
                                                                            }}
                                                                            isplaceholderanimated={
                                                                                (false,
                                                                                'top')
                                                                            }
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                                <p
                                                                    className={
                                                                        classes.errors
                                                                    }
                                                                >
                                                                    {errors.quantidadeMinima
                                                                        ? errors
                                                                              .quantidadeMinima
                                                                              .message
                                                                        : ''}
                                                                </p>
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                )}
                                        </Grid>
                                    </>
                                )}
                            </>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
            <Modal
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <ModalCadastroCategoria
                        onClose={() => handleClose()}
                        submit={(nome) => cadastraCategoria(nome)}
                    />
                </div>
            </Modal>
            <ModalCadastroCentroCusto
                open={openModalCadastroCentroCusto}
                close={() => setOpenModalCadastroCentroCusto(false)}
                submit={(values) => cadastrarCentroCusto(values)}
            />
            <ModalCadastroFornecedor
                open={openModalCadastroFornecedor}
                close={() => setOpenModalCadastroFornecedor(false)}
                submit={(values) => cadastrarFornecedor(values)}
            />
            <ModalCadastroProdutoTecnico
                open={openModalCadastroProdutoTecnico}
                close={() => setOpenModalCadastroProdutoTecnico(false)}
                submit={(values) => cadastrarProdutoTecnico(values)}
            />
            <ModalCadastroLocalEstoque
                open={openModalCadastroLocalEstoque}
                close={() => setOpenModalCadastroLocalEstoque(false)}
                submit={(values) => cadastrarLocalEstoque(values)}
            />
            <ModalCadastroContaGerencial
                open={openModalCadastroContaGerencialCompra}
                close={() => setOpenModalCadastroContaGerencialCompra(false)}
                selecionarCadastrado={(dados) =>
                    setValue('contaGerencialPadraoCompra', {
                        value: dados.id,
                        label: dados.numero + ' - ' + dados.descricao,
                    })
                }
                buscarContasGerenciaisPadrao={() =>
                    buscarContasGerenciaisPadraoPorOperacao(1)
                }
                tipo={1}
                tipoTitulo="Compra"
            />
            <ModalCadastroContaGerencial
                open={openModalCadastroContaGerencialVenda}
                close={() => setOpenModalCadastroContaGerencialVenda(false)}
                selecionarCadastrado={(dados) =>
                    setValue('contaGerencialPadraoVenda', {
                        value: dados.id,
                        label: dados.numero + ' - ' + dados.descricao,
                    })
                }
                buscarContasGerenciaisPadrao={() =>
                    buscarContasGerenciaisPadraoPorOperacao(0)
                }
                tipo={0}
                tipoTitulo="Venda"
            />
        </Page>
    )
}
