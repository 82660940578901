import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Divider, Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: '80%',
        margin: theme.spacing(10),
        overflow: 'auto',
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    title: {
        color: '#2E7D32',
    },
    button2: {
        marginTop: '10px',
        marginRight: '10px',
    },
    button: {
        marginTop: '10px',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
}))

export default function ModalErrosPlanilha(props) {
    const classes = useStyles()

    return (
        <div className={classes.paper}>
            <h2 className={classes.title}>Erros</h2>
            <Divider className={classes.divider} />

            <Grid
                container
                item
                xs={12}
                display="flex"
                className={classes.errors}
            >
                <List>
                    {props.erros.map((erro) => {
                        return (
                            <div>
                                <ListItem>
                                    <ListItemText primary={erro} />
                                </ListItem>
                                <Divider />
                            </div>
                        )
                    })}
                </List>
            </Grid>
            <Grid
                xs={12}
                container
                item
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Button
                    onClick={props.close}
                    className={classes.button2}
                    variant="contained"
                    color="secundary"
                    size="small"
                >
                    Fechar
                </Button>
            </Grid>
        </div>
    )
}
