import React from 'react'
import Table from '../../components/Table'
import { TrendingFlat, SyncAlt } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: #000000de;
  cursor: pointer;

  &:hover {
    color: #2E7D32;
    text-decoration: underline;
  }
`

export default function EstoqueListagem(props) {
    const colunas = [
        {
            title: 'Local de Estoque',
            field: 'localEstoque',
            editable: 'never',
            render: (rowData) => (
                <StyledLink to={`/app/movimentoEstoque?locaisEstoqueId=${rowData.localEstoqueId}&produtoId=${rowData.produtoId}`}>
                    {rowData.localEstoque + ' - ' + rowData.tipoEstoque}
                </StyledLink>
            )
        },
        { title: 'Produto', field: 'produto', editable: 'never' },
        { title: 'Unidade', field: 'unidade', editable: 'never' },
        {   title: 'Quantidade',
            field: 'quantidade',
            editable: 'never',
            render: (rowData) =>
                parseFloat(rowData.quantidade.toFixed(2)),
        },
        {
            title: 'Valor Unitário',
            field: 'valorUnitario',
            editable: 'never',
            render: (rowData) =>
                rowData.valorUnitario?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
        },
    ]

    return (
        <>
            <Table
                title={'Listagem'}
                columns={colunas}
                data={props.data}
                onChangePage={(page) => props.onChangePage(page)}
                page={props.filtro.pagina}
                totalCount={props.filtro.totalItens}
                isLoading={props.isLoading}
                pageSize={props.pageSize}
                onOrderChange={(numeroColuna, orientacao) =>
                    props.mudaOrdenacao(numeroColuna, orientacao, colunas)
                }
                emptyRowsWhenPaging={false}
                actions={[
                    {
                        icon: TrendingFlat,
                        tooltip: 'Movimentar Estoque',
                        onClick: (event, rowData) =>
                            props.movimentarEstoque(rowData),
                    },
                    {
                        icon: SyncAlt,
                        tooltip: 'Transferência entre Estoque',
                        onClick: (event, rowData) =>
                            props.transferenciaEstoque(rowData),
                    },
                ]}
            />
        </>
    )
}
