import service from './service'

export default {
    buscarPorId(id) {
        return service.get(`/DespesaReceita/${id}`)
    },
    buscarTudo(param) {
        return service.get(`/DespesaReceita${param}`)
    },
    atualizarDataCompensacao(param) {
        return service.put(
            `/DespesaReceitaParcela/AtualizarDataCompensacao`,
            param
        )
    },
    conciliacaoBancaria(param) {
        return service.get(`/DespesaReceitaParcela/ConciliacaoBancaria` + param)
    },
    buscarParcelasPorDespesaReceitaId(param) {
        return service.get(
            `/DespesaReceitaParcela/buscarParcelasPorDespesaReceitaId/` + param
        )
    },
    buscarParcelasPorApontamentoId(param) {
        return service.get(
            `/DespesaReceitaParcela/buscarParcelasPorApontamentoId/` + param
        )
    },
    cadastrar(param) {
        return service.post(`/DespesaReceita`, param)
    },
    atualizar(param) {
        return service.put(`/DespesaReceita`, param)
    },
    ativarDesativar(id) {
        return service.put(`/DespesaReceita/AtivarDesativar`, id)
    },
    deletar(params) {
        return service.post(`/DespesaReceita/DeleteParcela`, params)
    },
    inserirProdutosServicos(params) {
        return service.post(`/DespesaReceita/InserirProdutosServicos`, params)
    },
    apagarProdutosServicos(params) {
        return service.delete(
            `/DespesaReceita/ApagarProdutosServicos/${params}`
        )
    },
    quitarContas(params) {
        return service.post(`/DespesaReceita/QuitarContas`, params)
    },
    cancelarPagamento(params) {
        return service.post(`/DespesaReceita/CancelarPagamento`, params)
    },
    cancelarConciliacao(params) {
        return service.put(`/DespesaReceitaParcela/CancelarConciliacao`, params)
    },
    buscarTudoFluxoCaixa(param) {
        return service.get(`/DespesaReceitaParcela/FluxoCaixa${param}`)
    },
    validaDocumento(params)
    {
        return service.post(`/DespesaReceita/ValidaDocumento`, params)

    }
}
