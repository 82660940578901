import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Tooltip, IconButton } from '@material-ui/core'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        flex: '1 1 100%',
        margin: 10,
    },
    th: {
        fontWeight: 'bold',
    },
    tableContainer: {
        marginBottom: 20,
    },
})

export const TabelaConversao = ({ title, columns, data, actions }) => {
    const classes = useStyles()
    return (
        <>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {title}
            </Typography>
            <TableContainer
                className={classes.tableContainer}
                component={Paper}
            >
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    className={classes.th}
                                    align="left"
                                >
                                    {column.title}
                                </TableCell>
                            ))}
                            {actions && (
                                <TableCell className={classes.th} align="right">
                                    Ações
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((rowData, index) => (
                            <TableRow key={index}>
                                {columns.map(({ field }, index) => (
                                    <TableCell
                                        key={index}
                                        component="th"
                                        scope="row"
                                    >
                                        {rowData[field]}
                                    </TableCell>
                                ))}
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                >
                                    {actions &&
                                        actions.map((action, index) => (
                                            <Tooltip
                                                title={action.tooltip}
                                                key={index}
                                            >
                                                <IconButton
                                                    aria-label={action.tooltip}
                                                    key={index}
                                                    size="small"
                                                    onClick={(e) =>
                                                        action.onClick(
                                                            e,
                                                            rowData
                                                        )
                                                    }
                                                >
                                                    {action.icon}
                                                </IconButton>
                                            </Tooltip>
                                        ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
