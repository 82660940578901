import { SET_FILTRO_LISTAGEM_PRODUTOS_SERVICOS } from '../actions/produtosServicos';

export const storageProdutosServicos = 'produtosServicos';

const initialState = {
    filtroListagem: {},
};

export const produtosServicos = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTRO_LISTAGEM_PRODUTOS_SERVICOS:
            return {
                ...state,
                filtroListagem: action.filtroListagem,
            };
        default:
            return state;
    }
};
