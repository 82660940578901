import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Backdrop, Grid, TextField, FormControl, Button, makeStyles, RadioGroup, FormControlLabel, Radio,  }from '@material-ui/core';
import { schema as schemaValidate } from './validate/ModalCadastroFornecedor.validate';
import { yupResolver } from '@hookform/resolvers';

export default function ModalCadastroFornecedor(props) {

    const classes = useStyles()

    const [tipoPessoa, setTipoPessoa] = useState('FISICA');

    const { register, handleSubmit, getValues, errors } = useForm({
        defaultValues: {
            nomeFantasia: "",
        },
        resolver: yupResolver(schemaValidate),
    })

    const onSubmit = () => {
        var values = getValues()
        values.tipoPessoa = tipoPessoa;
        values.tipoFornecedorCliente = 0;
        props.submit(values)
    }

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
                <div className={classes.paper}>


                        <h2 className={classes.title}>Cadastro de Fornecedor</h2>

                        <Grid container item xs={12} display="flex">

                                <FormControl className={classes.formControl}>
                                    <TextField
                                        inputRef={register}
                                        id="nomeFantasia"
                                        name="nomeFantasia"
                                        variant="standard"
                                        label={
                                            tipoPessoa === "FISICA"
                                            ? "Nome da Pessoa*"
                                            : "Nome Fantasia*"}
                                        placeholder={
                                            tipoPessoa === "FISICA"
                                            ? "Nome da Pessoa*"
                                            : "Nome Fantasia*"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <p className={classes.errors}>
                                        {errors.nomeFantasia
                                            ? errors.nomeFantasia.message
                                            : ''}
                                    </p>

                                </FormControl>

                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        aria-label="gender"
                                        name="gender1"
                                        value={tipoPessoa}
                                        onChange={(e, data) =>
                                            setTipoPessoa(
                                                data
                                            )
                                        }
                                    >
                                        <FormControlLabel
                                            value={'FISICA'}
                                            control={
                                                <Radio />
                                            }
                                            label="Física"
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value={
                                                'JURIDICA'
                                            }
                                            control={
                                                <Radio />
                                            }
                                            label="Jurídica"
                                            labelPlacement="start"
                                        />
                                    </RadioGroup>
                                </FormControl>

                            <Grid
                                xs={12}
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={classes.margin}
                            >
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    size="small"
                                    onClick={props.close}
                                >
                                    Cancelar
                                </Button>
                                <div className={classes.margin} />
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type='submit'
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Cadastrar
                                </Button>
                            </Grid>

                        </Grid>

                </div>
        </Modal>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    formControlDescricao: {
        margin: theme.spacing(1),
        minWidth: '90%',
        marginBottom: theme.spacing(2)
    },
    formControlCategoria: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    formControButton: {
        margin: theme.spacing(1),
        padding: '15px',
        height: '20%',
    },
    formControlTextArea: {
        margin: theme.spacing(1),
        minWidth: '94%',
    },
    errors: {
        color: 'red',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxLoading: {
        paddingLeft: '45%',
    },
    title: {
        color: '#2E7D32',
    },
}))
