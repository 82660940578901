import * as Yup from 'yup'

export const importacaoOrcamentoEsquema = Yup.object().shape({
    dataInicial: Yup.date()
        .required('Campo obrigatório')
        .typeError('Data inválida'),
    dataFinal: Yup.date()
        .required('Campo obrigatório')
        .typeError('Data inválida'),
    centroCusto: Yup.string().required('Campo obrigatório'),
})
