import { TipoEstoque } from '../pages/tipoEstoque/index';
import { TipoEstoqueCadastro } from '../pages/tipoEstoque/tipoEstoqueCadastro';

export const TipoEstoqueRoute = [
    {
        path: '/app/tipoEstoque',
        key: 'APP_TIPO_ESTOQUE',
        exact: true,
        component: TipoEstoque,
    },
    {
        path: '/app/tipoEstoque/cadastro',
        key: 'APP_TIPO_ESTOQUE_CADASTRO',
        exact: true,
        component: TipoEstoqueCadastro,
    },
    {
        path: '/app/tipoEstoque/:modo(editar)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})', // Ainda não há rota para visualizar
        key: 'APP_TIPO_ESTOQUE_EDITAR_VISUALIZAR',
        exact: true,
        component: TipoEstoqueCadastro,
    },
];
