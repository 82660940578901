import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import {
    Container,
    makeStyles,
    Grid,
    CardContent,
    Card,
    Button,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import Titulo from '../../components/Titulo/index'
import { useSelector } from 'react-redux'
import ObjectHelper from '../../utils/objetcHelper'
import ExtratoBancarioListagem from './extratoBancarioListagem'
import contasBancariaService from './../../services/ContaBancariaService'
import DataHelper from '../../utils/dataHelpder'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Controller, useForm } from 'react-hook-form'
import { ExtratoBancarioEsquema } from './extratoBancario.validate'
import { yupResolver } from '@hookform/resolvers'
import { Graficos } from './graficos'
import ToastHelper from '../../utils/toastHelper'
import Loading from '../../components/Loading'
import moment from 'moment'
import { Search as SearchIcon } from 'react-feather'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    filtro: {
        marginTop: theme.spacing(2),
    },
    content: {
        marginTop: theme.spacing(1),
    },
    charts: {
        maxHeight: '300px',
    },
    saldo: {
        padding: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    errors: {
        color: 'red',
    },
    tabela: {
        height: '100%',
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttonBuscar: {
        height: '40px',
    },
}))

export const ExtratoBancario = (props) => {
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [filtro] = useState(
        useSelector((state) => state.extratoBancario.filtroListagem)
    )

    const classes = useStyles()

    const [grafico, setGrafico] = useState([])
    const [saldoAtual, setSaldoAtual] = useState(0)
    const [contasBancaria, setContasBancarias] = useState([])
    const [extratos, setExtratos] = useState([])
    const [loading, setLoading] = React.useState(false)

    const valoresCreditoDebito = [
        { value: 0, label: 'Crédito' },
        { value: 1, label: 'Débito' },
    ]

    const valoresPeriodoExtratoBancario = [
        {
            value: 0,
            de: DataHelper.today(),
            ate: DataHelper.today(),
            label: 'Hoje',
        },
        {
            value: 1,
            de: DataHelper.subtraiData(Date.now(), 3),
            ate: DataHelper.today(),
            label: 'Últimos 3 dias',
        },
        {
            value: 2,
            de: DataHelper.subtraiData(Date.now(), 15),
            ate: DataHelper.today(),
            label: 'Últimos 15 dias',
        },
        {
            value: 3,
            de: DataHelper.subtraiData(Date.now(), 30),
            ate: DataHelper.today(),
            label: 'Último mês',
        },
        {
            value: 4,
            label: 'Período',
        },
    ]

    const { handleSubmit, setValue, getValues, watch, errors, control } =
        useForm({
            defaultValues: {
                periodo: '',
                contaBancaria: '',
                dataInicial: '',
                dataFinal: '',
                operacao: '',
            },
            resolver: yupResolver(ExtratoBancarioEsquema),
        })

    useEffect(() => {
        const init = async () => {
            await buscarContasBancaria()
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buscarExtratos = async (filtro) => {
        setLoading(true)
        let params = await ObjectHelper.createParams(filtro)
        contasBancariaService
            .buscarExtrato(params)
            .then((response) => {
                setExtratos(response.data.extratos)
                setGrafico(
                    response.data.grafico.map((item) => {
                        return { name: item.categoria, value: item.valor }
                    })
                )
                setSaldoAtual(response.data.saldoAtual)
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
            .finally((_) => {
                setLoading(false)
            })
    }

    const buscarContasBancaria = async () => {
        setLoading(true)
        let filtroPesquisa = { ...filtro }
        filtroPesquisa.fazendaId = fazendaId
        filtroPesquisa.ativo = true
        filtroPesquisa.tamanhoPagina = 9999999
        let params = await ObjectHelper.createParams(filtroPesquisa)
        contasBancariaService
            .buscarTudo(params)
            .then((response) => {
                setContasBancarias(
                    ObjectHelper.getValuesDescricaoLabelParseToSelect(
                        response.data.itens
                    )
                )
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
            })
            .finally((_) => {
                setLoading(false)
            })
    }

    const onSubmit = async (dados) => {
        const filtro = {
            dataInicial:
                dados.periodo.value === 4
                    ? moment(dados.dataInicial).format('MM/DD/YYYY')
                    : dados.periodo.de,
            dataFinal:
                dados.periodo.value === 4
                    ? moment(dados.dataFinal).format('MM/DD/YYYY')
                    : dados.periodo.ate,
            contaBancariaId: dados.contaBancaria.value,
            operacao: dados.operacao ? dados.operacao.value : null,
            fazendaId: JSON.parse(localStorage.getItem('fazenda'))
                ? JSON.parse(localStorage.getItem('fazenda')).value
                : null,
        }

        buscarExtratos(filtro)
    }

    return (
        <Page className={classes.root} title="Extrato Bancário">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Extrato Bancário" />
                <Card className={classes.filtro}>
                    <CardContent>
                        <Grid container display="flex">
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={2}
                                    display="flex"
                                >
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Controller
                                            control={control}
                                            id="periodo"
                                            name="periodo"
                                            as={(props) => (
                                                <Autocomplete
                                                    id="periodo"
                                                    size="small"
                                                    options={
                                                        valoresPeriodoExtratoBancario
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option.label
                                                            ? option.label
                                                            : ''
                                                    }
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option?.value ===
                                                        value?.value
                                                    }
                                                    onChange={(_, data) => {
                                                        props.onChange(data)
                                                    }}
                                                    value={getValues('periodo')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Período*"
                                                            placeholder="Selecione"
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <p className={classes.errors}>
                                            {errors.periodo
                                                ? errors.periodo.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                {watch('periodo') &&
                                    watch('periodo').value === 4 && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={2}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <Controller
                                                        control={control}
                                                        id="dataInicial"
                                                        name="dataInicial"
                                                        as={
                                                            <TextField
                                                                type="date"
                                                                isplaceholderanimated={
                                                                    (false,
                                                                    'top')
                                                                }
                                                                label="De*: "
                                                                id="de"
                                                                name="de"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(
                                                                    _,
                                                                    newValue
                                                                ) =>
                                                                    setValue(
                                                                        'dataInicial',
                                                                        newValue
                                                                    )
                                                                }
                                                                InputProps={
                                                                    {
                                                                        inputProps: {max: '9999-12-31'}
                                                                    }
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        {errors.dataInicial
                                                            ? errors.dataInicial
                                                                  .message
                                                            : ''}
                                                    </p>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={2}
                                                display="flex"
                                            >
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <Controller
                                                        control={control}
                                                        id="dataFinal"
                                                        name="dataFinal"
                                                        as={
                                                            <TextField
                                                                type="date"
                                                                isplaceholderanimated={
                                                                    (false,
                                                                    'top')
                                                                }
                                                                label="Até*: "
                                                                id="ate"
                                                                name="ate"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(
                                                                    _,
                                                                    newValue
                                                                ) =>
                                                                    setValue(
                                                                        'dataFinal',
                                                                        newValue
                                                                    )
                                                                }
                                                                InputProps={
                                                                    {
                                                                        inputProps: {max: '9999-12-31'}
                                                                    }
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <p
                                                        className={
                                                            classes.errors
                                                        }
                                                    >
                                                        {errors.dataFinal
                                                            ? errors.dataFinal
                                                                  .message
                                                            : ''}
                                                    </p>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )}
                            </>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={2}
                                display="flex"
                            >
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="contaBancaria"
                                        name="contaBancaria"
                                        error={
                                            errors.contaBancaria ? true : false
                                        }
                                        as={(props) => (
                                            <Autocomplete
                                                id="contaBancaria"
                                                size="small"
                                                options={contasBancaria}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                        ? option.label
                                                        : ''
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) =>
                                                    option?.value ===
                                                    value?.value
                                                }
                                                onChange={(_, data) => {
                                                    props.onChange(data)
                                                }}
                                                value={getValues(
                                                    'contaBancaria'
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Conta Bancária*"
                                                        placeholder="Selecione"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <p className={classes.errors}>
                                        {errors.contaBancaria
                                            ? errors.contaBancaria.message
                                            : ''}
                                    </p>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={2}
                                display="flex"
                            >
                                <FormControl className={classes.formControl}>
                                    <Controller
                                        control={control}
                                        id="operacao"
                                        name="operacao"
                                        error={errors.operacao ? true : false}
                                        as={(props) => (
                                            <Autocomplete
                                                id="operacao"
                                                size="small"
                                                options={valoresCreditoDebito}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                        ? option.label
                                                        : ''
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) =>
                                                    option?.value ===
                                                    value?.value
                                                }
                                                onChange={(_, data) => {
                                                    props.onChange(data)
                                                }}
                                                value={getValues('operacao')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Operação"
                                                        placeholder="Selecione"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={
                                    watch('periodo') &&
                                    watch('periodo').value === 4
                                        ? 2
                                        : 6
                                }
                                className={classes.button}
                            >
                                <Button
                                    size="small"
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    className={classes.buttonBuscar}
                                >
                                    <SearchIcon
                                        className={clsx(
                                            classes.icon,
                                            props.className
                                        )}
                                    />
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {extratos.length > 0 && (
                    <Grid
                        container
                        display="flex"
                        className={classes.content}
                        spacing={2}
                    >
                        <Grid item lg={9} md={12}>
                            <Card className={classes.tabela}>
                                <ExtratoBancarioListagem
                                    data={extratos}
                                    contaBancaria={watch('contaBancaria').label}
                                    dataInicial={
                                        watch('periodo').value === 4
                                            ? moment(
                                                  watch('dataInicial')
                                              ).format('DD/MM/YYYY')
                                            : DataHelper.transformStringDataUsaToPtBr(
                                                  watch('periodo').de
                                              )
                                    }
                                    dataFinal={
                                        watch('periodo').value === 4
                                            ? moment(watch('dataFinal')).format(
                                                  'DD/MM/YYYY'
                                              )
                                            : DataHelper.transformStringDataUsaToPtBr(
                                                  watch('periodo').ate
                                              )
                                    }
                                />
                            </Card>
                        </Grid>
                        <Grid item lg={3} md={0}>
                            <Grid xs={12}>
                                <Card className={classes.saldo}>
                                    <Typography variant="h3">
                                        Saldo Atual (Hoje):{' '}
                                        {saldoAtual >= 0 ? (
                                            <span
                                                style={{
                                                    color: 'blue',
                                                }}
                                            >
                                                {saldoAtual?.toLocaleString(
                                                    'pt-br',
                                                    {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }
                                                )}
                                            </span>
                                        ) : (
                                            <span style={{ color: 'red' }}>
                                                {saldoAtual?.toLocaleString(
                                                    'pt-br',
                                                    {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }
                                                )}
                                            </span>
                                        )}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid xs={12} className={classes.content}>
                                <Graficos data={grafico} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Loading open={loading} texto="Carregando" />
            </Container>
        </Page>
    )
}
