import React from 'react'
import MaterialTable from '@material-table/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MTablePagination } from 'material-table'
import Grid from '@material-ui/core/Grid'
import { Edit3 as Edit3Icon } from 'react-feather'
import AddIcon from '@material-ui/icons/Add'

export default function Table({ ...props }) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#009688',
            },
        },
    })

    const setRowBackgroundColor = (rowData) => {
        if (!!rowData.idPai) return '#F9F9F9'
        if (rowData.tipoOperacaoCodigo && rowData.tipoOperacaoCodigo.includes('TRANSFERENCIA_ESTOQUE')) return '#fdffad'
        if (rowData.tipoOperacao === 'SAIDA') return '#FF7070'
        return ''
    }

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    title={props.title}
                    columns={props.columns}
                    data={props.data}
                    style={props.style}
                    options={{
                        exportMenu: props.exportMenu ? props.exportMenu : [],
                        toolbar: props.removeToolbar ? false : true,
                        pageSize: props.pageSize ? props.pageSize : 100,
                        paging:
                            props.paging !== undefined ? props.paging : true,
                        padding: 'dense',
                        search: false,
                        actionsColumnIndex: -1,
                        selection: props.options
                            ? props.options.selection
                            : false,
                        draggable: props.draggable === false ? false : true,
                        fixedColumns: props.fixedColumns
                            ? props.fixedColumns
                            : false,
                        emptyRowsWhenPaging: props.emptyRowsWhenPaging,
                        rowStyle: (rowData) => ({
                            fontSize: 14,
                            backgroundColor: setRowBackgroundColor(rowData),
                            fontWeight:
                                rowData.categoria === 'Saldo inicial' ||
                                rowData.categoria === 'Saldo final'
                                    ? '500'
                                    : '200',
                        }),
                        maxBodyHeight: props.maxBodyHeight
                            ? props.maxBodyHeight
                            : '',
                        ...props.options,
                    }}
                    onBulkEditOpen={(edit) => {
                        if (props.onChangeEdit) {
                            props.onChangeEdit(edit)
                        }
                    }}
                    isLoading={props.isLoading}
                    cellEditable={
                        props.editarCelula && {
                            isCellEditable:(rowData) => !rowData.registroSistema,
                            onCellEditApproved: (
                                newValue,
                                oldValue,
                                rowData,
                                columnDef
                            ) => {
                                return new Promise((resolve, reject) => {
                                    props.editarCelula(
                                        newValue,
                                        oldValue,
                                        rowData,
                                        columnDef
                                    )
                                    setTimeout(resolve, 1000)
                                })
                            },
                        }
                    }
                    parentChildData={
                        props.haveParentChild
                            ? (row, rows) =>
                                  rows.find((a) => a.id === row.idPai)
                            : false
                    }
                    components={{
                        Pagination: (paginat) => {
                            const { classes, headerIsHidden, ...other } =
                                paginat
                            return (
                                <>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        justify="flex-end"
                                    >
                                        <MTablePagination
                                            {...other}
                                            onChangePage={(event, page) =>
                                                props.onChangePage(page + 1)
                                            }
                                            page={props.page - 1}
                                            count={props.totalCount}
                                            rowsPerPage={props.pageSize}
                                            localization={{
                                                labelRowsSelect: 'recursos',
                                                labelDisplayedRows:
                                                    '{from}-{to} de {count}',
                                            }}
                                        />
                                    </Grid>
                                </>
                            )
                        },
                        ...props.components,
                    }}
                    onRowClick={props.onRowClick}
                    editable={props.editable}
                    actions={props.actions}
                    onOrderChange={props.onOrderChange}
                    icons={{
                        Edit: Edit3Icon,
                        Add: (props) => {
                            return (
                                <AddIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                        backgroundColor: '#2e7d32',
                                        color: '#fff',
                                        borderRadius: 100,
                                    }}
                                />
                            )
                        },
                        ...props.icons,
                    }}
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro para exibir',
                            addTooltip: 'Adicionar',
                            deleteTooltip: 'Excluir',
                            editTooltip: 'Edição rápida',
                            editRow: {
                                deleteText: 'Deseja excluir essa linha?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Confirmar',
                            },
                        },
                        toolbar: {
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                        },
                    }}
                />
            </MuiThemeProvider>
        </>
    )
}
