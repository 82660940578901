import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../../components/Page'
import Titulo from '../../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Table from '../../../components/Table'
import {
    Container,
    Paper,
    TextField,
    Button,
    makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Parcelamento from './Parcelamento'
import DataHelper from '../../../utils/dataHelpder'
import ModalProdutosServicos from './ModalProdutosServicos'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import ContasRequests from '../requests'
import ToastHelper from 'utils/toastHelper'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ContaBancariaService from '../../../services/ContaBancariaService'
import FormaPagamentoService from '../../../services/TipoPagamentoRecebimentoService'
import ObjectHelper from 'utils/objetcHelper'
import NumberFormat from 'react-number-format'
import Request from '../requests'
import AsyncAutoComplete from '../../../components/AsyncAutoComplete/index'
import GestaoContratoService from '../../../services/GestaoContratoService'
import PedidoService from '../../../services/PedidosService'
import SelectWithButton from '../../../components/SelectWithButton'
import ClienteFornecedorCadastroModal from './ClienteFornecedorCadastroModal/ClienteFornecedorCadastroModal'
import FornecedorClienteService from 'services/FornecedorClienteService'
import { LabelWithInfo } from '../../../components/LabelWithInfo'
import servicoService from '../../../services/tecnico/servicoService'
import ModalConfirmDocumentoDuplicado from './ModalConfirmDocumentoDuplicado'
import ModalConfirmPedidos from './ModalConfirmPedidos'
import ModalConfirmContratos from './ModalConfirmContratos'
import CentroCustoService from 'services/CentroCustoService'
import PedidosService from '../../../services/PedidosService'

const defaultOpcoesSituacao = ['Aprovada', 'Baixada', 'Definitiva', 'Previsão']

const GreenCheckbox = withStyles({
    root: {
        color: green[300],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => {
    return (
        <Checkbox
            value={props.value}
            checked={props.value}
            color="default"
            {...props}
        />
    )
})

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
        padding: theme.spacing(2),
    },
    cardMeio: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonModalProdutosServicos: {
        //marginBottom: '-55px',
        zIndex: 9,
        marginRight: 10,
        marginTop: 10,
        float: 'right',
    },
    formControlFornecedorCliente: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    addCircleButton: {
        cursor: 'pointer',
        marginTop: '10px',
    },
    marginLeft7px: {
        marginLeft: '7px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export const ContaPagarReceberCadastro = (props) => {
    const fazendaId = JSON.parse(localStorage.getItem('fazenda')).value

    const [form, setForm] = useState({
        dataLancamento: DataHelper.today(),
    })
    const {
        match: { params },
    } = props

    let history = useHistory()
    const [visualizar, setVisualizar] = useState(false)
    const [openModalProdutosServicos, setOpenModalProdutosServicos] =
        React.useState(false)
    const [openModalFornecedorCliente, setOpenModalFornecedorCliente] =
        React.useState(false)
    const handleOpenModalProdutosServicos = () => {
        setOpenModalProdutosServicos(true)
    }
    const handleCloseModalProdutosServicos = async () => {
        setOpenModalProdutosServicos(false)
    }

    const [opcoesPagamentoA] = useState([
        {
            value: 'CLIENTE',
            label: 'Cliente',
        },
        {
            value: 'FORNECEDOR',
            label: 'Fornecedor',
        },
    ])

    const [opcoesFornecedoresClientes, setOpcoesFornecedoresClientes] =
        useState([])
    const [titulo, setTitulo] = useState(
        params.tipo === 'pagar'
            ? 'Cadastro de conta a pagar'
            : 'Cadastro de conta a receber'
    )
    const [editProduto, setEditProduto] = useState(false)
    const [editParcelas, setEditParcelas] = useState(false)
    const [showConfirmDocumentoDuplicado, setShowConfirmDocumentoDuplicado] =
        useState(false)
    const [showConfirmPedidos, setShowConfirmPedidos] = useState(false)
    const [showConfirmContratos, setShowConfirmContratos] = useState(false)
    const [contaAPagar] = useState(params.tipo === 'pagar' ? true : false)
    const { register, handleSubmit } = useForm({ defaultValues: {} })
    const [tiposDocumentos, setTiposDocumentos] = useState([])
    const [opcoesSituacoes] = useState(defaultOpcoesSituacao)
    const classes = useStyles()
    const [numeroParcelas, setNumeroParcelas] = useState()
    const [parcelas, setParcelas] = useState([])
    const [dataVencimento, setDataVencimento] = useState('')
    const [diasEntreParcelas, setDiasEntreParcelas] = useState()
    const [diaDaParcela, setDiaDaParcela] = useState()

    const [opcoesContas, setOpcoesContas] = useState([])
    const [opcoesFormaPagamento, setOpcoesFormaPagamento] = useState([])

    const [total, setTotal] = React.useState(0)
    const [valorLiquido, setValorLiquido] = React.useState(0)
    const [acrescimo, setAcrescimo] = React.useState(0)
    const [descontos, setDescontos] = React.useState(0)
    const [produtoServicoListagem, setprodutoServicoListagem] = React.useState(
        []
    )
    const [opcoesServicos, setOpcoesServicos] = useState([])
    const [erroDesconto , setErroDesconto] = useState(false);
    const [grupoSafraId , setGrupoSafraId] = useState(null);
    const [centroDeCusto , setCentroDeCusto] = useState(null);
    let [opcoesProdutosServicos, setOpcoesProdutosServicos] = React.useState([])
    let [opcoesCentroCusto, setOpcoesCentroCusto] = React.useState([])
    let [opcoesPatrimonio, setOpcoesPatrimonio] = React.useState([])
    let [opcoesLocaisEstoque, setOpcoesLocaisEstoque] = React.useState([])
    let [opcoesSafra, setOpcoesSafras] = React.useState([])
    let [opcoesGrupoSafras, setOpcoesGrupoSafras] = React.useState([])
    let [opcoesContaGerencial, setOpcoesContaGerencial] = React.useState([])
    let [opcoesProdutorRural, setOpcoesProdutorRural] = React.useState([])

    const [pedidos, setPedidos] = useState([])
    const [contratos, setContratos] = useState([])
    const [pedidoSelecionado, setPedidoSelecionado] = useState({
        valor: "",
        valorLiquido: "",
        campo: ""
    })
    const [contratoSelecionado, setContratoSelecionado] = useState({
        valor: "",
        valorLiquido: "",
        campo: ""
    })

    const buscarServicos = async () => {
        let data = await servicoService.buscar(fazendaId)
        setOpcoesServicos(data.data.result)
    }

    useEffect(() => {
        const {
            match: { params },
        } = props
        buscarServicos()
        buscarTiposDocumentos()
        buscarContasBancarias()
        buscarFormasPagamento()
        buscarProdutosServicos()
        buscarCentrosDeCusto()
        buscarPatrimonios()
        buscarLocaisEstoque()
        buscarGrupoSafra(centroDeCusto,false)
        buscarContasGerenciais()
        buscarProdutoresRurais()
        if (params.modo === 'editar' || params.modo === 'duplicar') {
            buscarPorId(params.id)
            if (params.modo === 'visualizar') {
                setVisualizar(true)
                setTitulo('Visualizar conta')
            }
        }
        if (params.tipo === 'receber' && params.modo === 'gerar' && params.id) {
            gerarContaDeContrato(params.id)
        }
        if (params.tipo === 'pagar' && params.modo === 'gerar' && params.id) {
            gerarContaDePedido(params.id)
        }

        const pagamentoA = contaAPagar
            ? opcoesPagamentoA[1].label
            : opcoesPagamentoA[0].label
        setForm({
            ...form,
            pagamentoA: pagamentoA,
        })

        buscarFornecedoresClientes(pagamentoA)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(grupoSafraId) {
            buscarSafra(centroDeCusto,grupoSafraId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[grupoSafraId])

    const onChangeEdit = (statusEdit, tableEdit) => {
        if (tableEdit === 'editPrdutosServicos') {
            setEditProduto(statusEdit)
        } else {
            setEditParcelas(statusEdit)
        }
    }
    useEffect(() => {
        let newValor = 0
        produtoServicoListagem.forEach((item) => {
            newValor += item.valorTotal
        })
        setTotal(newValor)
        setValorLiquido(
            newValor + (acrescimo ? acrescimo : 0) - (descontos ? descontos : 0)
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtoServicoListagem, acrescimo, descontos])

    useEffect(() => {
        parcelas.forEach((item) => {
            item.valorTotalNota = parseFloat(valorLiquido.toFixed(2))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valorLiquido])

    const buscarPatrimonios = async () => {
        let data = await Request.buscarPatrimonios()
        data = await ObjectHelper.ordenaLista(data, 'nome')
        setOpcoesPatrimonio(data)
    }

    const buscarProdutosServicos = async () => {
        let data = await Request.buscarProdutosServicos()
        data = await ObjectHelper.ordenaLista(data, 'nome')
        setOpcoesProdutosServicos(data)
    }

    const buscarUnidades = async (unidadeAtual) => {
        let data = await Request.buscarUnidadesDeMedida(unidadeAtual)
        data = await ObjectHelper.ordenaLista(data, 'label')
        return data
    }

    const buscarCentrosDeCusto = async () => {
        let data = await Request.buscarCentrosDeCusto()
        data = await ObjectHelper.ordenaLista(data, 'descricao')
        setOpcoesCentroCusto(data)
    }

    const buscarLocaisEstoque = async () => {
        let data = await Request.buscarLocaisEstoque()
        var locais = []
        data.forEach((item) =>
            locais.push({
                id: item['id'],
                nome: item['nome'] + ' - ' + item['tipoEstoque'],
            })
        )
        data = await ObjectHelper.ordenaLista(data, 'nome')
        setOpcoesLocaisEstoque(locais)
    }

    const buscarSafra = async (centroCusto,grupoSafraId,tabela) => {
        if(centroCusto && centroCusto.tipo === 0) {
            const idCentroCusto =  centroCusto.value ? centroCusto.value : centroCusto.id
            let data = await Request.buscarSafra(grupoSafraId)
            const centroCustoData =  await CentroCustoService.buscarPorId(idCentroCusto)
            const idsSafra = centroCustoData.data.safras.map((item) => item.safraId);
            let safraDoCentroCusto = data.filter((item) => idsSafra.includes(item.id));
            data = await ObjectHelper.ordenaLista(safraDoCentroCusto, 'nome')
            if(!tabela) setOpcoesSafras(data)
            return data.map((item) => ({nome:item.nome,id:item.id}));
        } else {
            let data = await Request.buscarSafra(grupoSafraId)
            data = await ObjectHelper.ordenaLista(data, 'nome')
            if(!tabela) setOpcoesSafras(data)
            return data.map((item) => ({nome:item.nome,id:item.id}));
        }
    }


    const buscarGrupoSafra = async (centroCusto,tabela) => {
        if(centroCusto && centroCusto.tipo === 0) {
            const idCentroCusto =  centroCusto.value ? centroCusto.value : centroCusto.id
            let data = await Request.buscarGrupoSafra()
            const centroCustoData =  await CentroCustoService.buscarPorId(idCentroCusto)
            const idsGrupoSafra = centroCustoData.data.safras.map((item) => item.grupoSafraId);
            let grupoSafraDoCentroCusto = data.filter((item) => idsGrupoSafra.includes(item.id));
            data = await ObjectHelper.ordenaLista(grupoSafraDoCentroCusto, 'nome')
            if(!tabela) setOpcoesGrupoSafras(data)
            return data.map((item) => ({nome:item.nome,id:item.id}));
        } else {
            let data = await Request.buscarGrupoSafra();
            data = await ObjectHelper.ordenaLista(data, 'nome')
            if(!tabela) setOpcoesGrupoSafras(data)
            return data.map((item) => ({nome:item.nome,id:item.id}));
        }
    }


    useEffect(() => {
        buscarGrupoSafra(centroDeCusto,false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[centroDeCusto])

    const buscarContasGerenciais = async () => {
        let data = await Request.buscarContasGerenciais(params.tipo)
        data = await ObjectHelper.ordenaLista(data, 'descricao')
        setOpcoesContaGerencial(data)
    }

    const onChangeAcrescimos = (e) => {
        let acrescimo = parseFloat(
            e.target ? e.target.value.replace('R$ ', '').replace(',', '.') : 0
        )
        if((total + acrescimo) < parseFloat(
            descontos ? descontos : 0
        )) {
            setErroDesconto(true)
        } else {
            setErroDesconto(false)
        }
        setAcrescimo(acrescimo)
        setValorLiquido(
            total + (acrescimo ? acrescimo : 0) - (descontos ? descontos : 0)
        )
    }

    const onChangeDescontos = (e) => {
        let desconto = parseFloat(
            e.target ? e.target.value.replace('R$ ', '').replace(',', '.') : 0
        );
        if((total + acrescimo) < desconto) {
            setErroDesconto(true)
        } else {
            setErroDesconto(false)
        }
        setDescontos(desconto)
        setValorLiquido(
            total + (acrescimo ? acrescimo : 0) - (desconto ? desconto : 0)
        )
    }

    const buscarFormasPagamento = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        let params = await ObjectHelper.createParams(filtro)
        await FormaPagamentoService.buscarTudo(params).then(
            async (response) => {
                if (response.status === 200) {
                    let itens =
                        await ObjectHelper.getValuesFormaPagamentoParseToSelect(
                            response.data.itens
                        )
                    setOpcoesFormaPagamento(itens)
                } else {
                    ToastHelper.error(response.statusText)
                }
            }
        )
    }

    const buscarContasBancarias = async () => {
        let filtro = {}
        filtro.fazendaId = fazendaId
        filtro.tamanhoPagina = 99999
        filtro.ativo = true
        let params = await ObjectHelper.createParams(filtro)
        await ContaBancariaService.buscarTudo(params).then((response) => {
            setOpcoesContas(
                ObjectHelper.getValuesDescricaoLabelParseToSelect(
                    response.data.itens
                )
            )
        })
    }

    const buscarPorId = async (id) => {
        let data = await ContasRequests.buscarConta(id)
        await buscarFornecedoresClientes(
            data.despesaReceita.pagamentoA.toLowerCase()
        )

        if (params.modo === 'duplicar') {
            setForm({
                ...data.despesaReceita,
                dataLancamento: DataHelper.today(),
                id: undefined,
                documento: '',
                dataEmissao: '',
                descricao: '',
                numeroPedido: '',
                numeroContrato: '',
            })
        } else {
            setForm(data.despesaReceita)
        }
        setprodutoServicoListagem(data.produtoServico)
        setTotal(data.valorTotal)
        setValorLiquido(data.despesaReceita.valorLiquido)
        let dataParcelas = []
        if (params.modo !== 'duplicar') {
            data.parcelas.forEach((item, index) => {
                dataParcelas.push({ ...item, tableData: { id: index } })
            })
        }
        setDescontos(data.despesaReceita.desconto)
        setAcrescimo(data.despesaReceita.acrescimo)
        setParcelas(dataParcelas)

        await buscarPedidos(data.despesaReceita.fornecedorClienteId)
        await buscarContratos(data.despesaReceita.fornecedorClienteId)

        setPedidoSelecionado({
            valor: data.despesaReceita.numeroPedido,
            valorLiquido: data.despesaReceita.pedido?.valorTotal,
            campo: formataPedido(data.despesaReceita.pedido)
        })

        setContratoSelecionado({
            valor: data.despesaReceita.numeroContrato,
            valorLiquido: data.despesaReceita.contrato?.valorLiquido,
            campo: formataContrato(data.despesaReceita.contrato)
        })
    }

    const buscarTiposDocumentos = async () => {
        let data = await ContasRequests.buscarTiposDocumentos(contaAPagar)
        if (data) data = await ObjectHelper.ordenaLista(data, 'descricao')

        setTiposDocumentos(data)
    }

    const buscarFornecedoresClientes = async (TipoFornecedorCliente) => {
        let data = await ContasRequests.buscarFornecedoresClientes(
            TipoFornecedorCliente
        )
        if (data) data = await ObjectHelper.ordenaLista(data, 'label')

        setOpcoesFornecedoresClientes(data)
    }

    const buscarPedidos = async (id) => {
        try {
            const { data } = await PedidosService.buscarFornecedorClientePorId(id)

            const pedidosLista = formataPedidosLista(data)

            setPedidos(pedidosLista)
        } catch(error) {
            console.log(error)
        }
    }

    const buscarContratos = async (id) => {
        try {
            const { data } = await GestaoContratoService.buscarFornecedorClientePorId(id)

            const contratosLista = formataContratosLista(data)

            setContratos(contratosLista)
        } catch(error) {
            console.log(error);
        }
    }

    const mudaNumeroParcelas = (event) => {
        setNumeroParcelas(event)
    }

    const mudaDiasEntreParcelas = (event) => {
        setDiasEntreParcelas(event)
    }

    const mudaDiaDaParcela = (event) => {
        setDiaDaParcela(event)
    }

    const limpar = () => {
        setParcelas([])
    }

    const [error, setError] = React.useState([])

    const validSubmit = () => {
        if(erroDesconto) {
            ToastHelper.error('O desconto não pode ser maior que o valor da nota!')
            return false;
        }
        let valorParcelas = 0
        if (produtoServicoListagem.length === 0) {
            ToastHelper.error('Voce não adicionou os produtos da nota!')
            return false
        }

        if (parcelas.length === 0) {
            ToastHelper.error('Voce não gerou as parcelas da nota!')
            return false
        }

        parcelas.forEach((item) => {
            valorParcelas += item.valor
        })
        if (
            parseFloat(valorParcelas).toFixed(2) !==
            parseFloat(valorLiquido).toFixed(2)
        ) {
            ToastHelper.error(
                'O valor total das parcelas (' +
                    valorParcelas.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }) +
                    ') não confere com o valor liquido (' +
                    valorLiquido.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }) +
                    ') da nota!'
            )
            return false
        }
        if (params.id) {
            if (produtoServicoListagem.length === 0) {
                ToastHelper.error(
                    'Necessário escolher pelo menos um produto ou serviço e adicionar uma forma de pagamento!'
                )
                return false
            }

            if (parcelas.length === 0) {
                ToastHelper.error(
                    'Necessário escolher pelo menos uma forma de pagamento!'
                )
                return false
            }
        }
        return true
    }

    const mudaDataVencimento = (novaData) => {
        if (novaData) setDataVencimento(novaData.target.value)
        else setDataVencimento('')
    }

    const geraParcelas = (novasParcelas) => {
        let parcelas = []
        novasParcelas.forEach((item, index) => {
            parcelas.push({ ...item, tableData: { id: index } })
        })
        setParcelas(parcelas);
    }

    const atualizaParcelas = (novasParcelas) => {
        let arrayParcelas = [...parcelas]
        var parcelasAtualizadas = []
        arrayParcelas.forEach((antigas) => {
            novasParcelas.forEach((novas) => {
                if (antigas.tableData.id === novas.tableData.id) {
                    antigas = novas
                }
            })
            parcelasAtualizadas.push(antigas)
        })
        setParcelas(parcelasAtualizadas)
    }

    const deletaParcela = (novasParcelas) => {
        let data = [...novasParcelas]
        setParcelas(data)
    }

    const colunas = [
        {
            title: 'Serviço',
            field: 'servico.nome',
            width: '20%',
            editComponent: (prop) => (
                <div className="width-200">
                    <Autocomplete
                        name="servico"
                        id="servico"
                        disabled={prop.rowData.produtoServico.produto}
                        options={opcoesServicos}
                        className={classes.textField}
                        value={prop.rowData.servico ? prop.rowData.servico : ''}
                        style={{ width: 250 }}
                        getOptionLabel={(option) => (option ? option.nome : '')}
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.servico = newValue
                            data.servicoId = newValue?.id
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="servico"
                                id="servico"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Item',
            field: 'produtoServico.nome',
            editComponent: (prop) => (
                <div>
                    <Autocomplete
                        name="produtoServico"
                        id="produtoServico"
                        options={opcoesProdutosServicos}
                        className={classes.textField}
                        disableClearable
                        value={
                            prop.rowData.produtoServico
                                ? prop.rowData.produtoServico
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => (option ? option.nome : '')}
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            newValue
                                ? (data.produtoServico = newValue)
                                : (data.produtoServico = null)
                            newValue
                                ? (data.produtoServicoId = newValue.id)
                                : (data.produtoServicoId = null)
                            if(newValue.possuiControleDeEstoque) {
                                data.centroCusto = null
                                data.centroCustoId = null
                                data.patrimonio = null
                                data.patrimonioId = null
                                data.alocacaoCusto = 'ESTOQUE'
                            }
                            if (!data.produtoServico?.produto) {
                                data.unidadeId = null
                                data.unidade = []
                                data.localEstoqueId = null
                                data.localEstoque = []
                            } else {
                                data.servico = null
                                data.servicoId = null
                            }
                            if (newValue) {
                                data.unidade = []
                                data.unidadeId = null
                            }
                            if(params.tipo === 'pagar') {
                                data.contaGerencial = {
                                    descricao: newValue?.contaGerencialPadraoCompra
                                        ? newValue.contaGerencialPadraoCompra.numero +
                                          ' - ' +
                                          newValue.contaGerencialPadraoCompra.descricao
                                        : '',
                                    id: newValue?.contaGerencialPadraoCompra?.id,
                                }
                                data.contaGerencialId = newValue?.contaGerencialPadraoCompra?.id

                            } else if (params.tipo === 'receber'){
                                data.contaGerencial = {
                                    descricao: newValue?.contaGerencialPadraoVenda
                                        ? newValue.contaGerencialPadraoVenda.numero +
                                          ' - ' +
                                          newValue.contaGerencialPadraoVenda.descricao
                                        : '',
                                    id: newValue?.contaGerencialPadraoVenda?.id,
                                }
                                data.contaGerencialId = newValue?.contaGerencialPadraoVenda?.id
                            }
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={
                                    prop.rowData.produtoServico === null ||
                                    prop.rowData.produtoServico === ''
                                }
                                helperText={
                                    prop.rowData.produtoServico === null ||
                                    prop.rowData.produtoServico === ''
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                                name="produtoServico"
                                id="produtoServico"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Quantidade',
            field: 'quantidade',
            editComponent: (prop) => (
                <div className="width-100">
                    <TextField
                        type="number"
                        value={prop.rowData.quantidade}
                        error={
                            prop.rowData.quantidade <= 0 ||
                            !prop.rowData.quantidade
                        }
                        helperText={
                            prop.rowData.quantidade <= 0
                                ? 'Informe um valor positivo!'
                                : !prop.rowData.quantidade
                                    ? 'Campo obrigatório!'
                                    : ''
                        }
                        onChange={(item) => {
                            var data = { ...prop.rowData }
                            item.target
                                ? (data.quantidade = item.target.value)
                                : (data.quantidade = 0)

                            let novoValorTotal =
                                data.valorUnitario * data.quantidade
                            data.valorTotal = novoValorTotal
                            prop.onRowDataChange(data)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Unidade',
            field: 'unidade.sigla',
            editComponent: (prop) => (
                <div className="width-100">
                    <AsyncAutoComplete
                        id="unidade"
                        name="unidade"
                        style={{ width: 150 }}
                        disableClearable
                        value={prop.rowData.unidade ? prop.rowData.unidade : ''}
                        className={classes.textField}
                        getOptionLabel={(option) =>
                            option ? option.sigla : ''
                        }
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.unidade = newValue ? newValue : []
                            data.unidadeId = newValue?.id
                            prop.onRowDataChange(data)
                        }}
                        defaultValue={
                            prop.rowData.unidade ? prop.rowData.unidade : ''
                        }
                        useCache
                        cacheHash={prop.rowData.produtoServico?.nome}
                        asyncLoadOptions={() =>
                            buscarUnidades(
                                prop.rowData.produtoServico?.unidadeMedidaId
                            )
                        }
                        error={!prop.rowData.unidadeId}
                                helperText={
                                    !prop.rowData.unidadeId
                                            ? 'Campo Obrigatório'
                                            : ''
                                }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="unidade"
                                id="unidade"
                                error={!prop.rowData.unidadeId}
                                helperText={
                                    !prop.rowData.unidadeId
                                            ? 'Campo Obrigatório'
                                            : ''
                                }
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Centro Custo',
            field: 'centroCusto.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="centroCusto"
                        id="centroCusto"
                        options={opcoesCentroCusto}
                        className={classes.textField}
                        disabled={
                            prop.rowData.alocacaoCusto !== 'CENTRO_DE_CUSTO'
                        }
                        value={
                            prop.rowData.centroCusto
                                ? prop.rowData.centroCusto
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) =>
                            option ? option.descricao : ''
                        }
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.centroCusto = newValue
                            data.patrimonio = ''
                            data.patrimonioId = null
                            data.safra = ''
                            data.safraId = null
                            data.grupoSafra = ''
                            data.grupoSafraId = null
                            data.localEstoque = ''
                            data.localEstoqueId = null
                            newValue
                                ? (data.centroCustoId = newValue.id)
                                : (data.centroCustoId = null)
                            prop.onRowDataChange(data)
                        }}
                        error={
                            prop.rowData.alocacaoCusto === 'CENTRO_DE_CUSTO' &&
                            !prop.rowData.centroCustoId
                        }
                        helperText={
                            prop.rowData.alocacaoCusto === 'CENTRO_DE_CUSTO' &&
                            !prop.rowData.centroCustoId
                                ? 'Campo obrigatório'
                                : ''
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="centroCusto"
                                id="centroCusto"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    prop.rowData.alocacaoCusto ===
                                        'CENTRO_DE_CUSTO' &&
                                    !prop.rowData.centroCustoId
                                }
                                helperText={
                                    prop.rowData.alocacaoCusto ===
                                        'CENTRO_DE_CUSTO' &&
                                    !prop.rowData.centroCustoId
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Patrimônio',
            field: 'patrimonio.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="patrimonio"
                        id="patrimonio"
                        options={opcoesPatrimonio}
                        className={classes.textField}
                        disabled={
                            prop.rowData.localEstoqueId || prop.rowData.safraId
                        }
                        value={
                            prop.rowData.patrimonio
                                ? prop.rowData.patrimonio
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) =>
                            option ? option.descricao : ''
                        }
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.patrimonio = newValue
                            data.centroCusto = ''
                            data.centroCustoId = null
                            data.safra = ''
                            data.safraId = null
                            data.localEstoque = ''
                            data.localEstoqueId = null
                            newValue
                                ? (data.patrimonioId = newValue.id)
                                : (data.patrimonioId = null)
                            prop.onRowDataChange(data)
                        }}
                        error={
                            prop.rowData.alocacaoCusto === 'PATRIMONIO' &&
                            !prop.rowData.patrimonioId
                        }
                        helperText={
                            prop.rowData.alocacaoCusto === 'PATRIMONIO' &&
                            !prop.rowData.patrimonioId
                                ? 'Campo obrigatório'
                                : ''
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="patrimonio"
                                id="patrimonio"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    prop.rowData.alocacaoCusto === 'PATRIMONIO' &&
                                    !prop.rowData.patrimonioId
                                }
                                helperText={
                                    prop.rowData.alocacaoCusto === 'PATRIMONIO' &&
                                    !prop.rowData.patrimonioId
                                        ? 'Campo obrigatório'
                                        : ''
                                }
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Local Estoque',
            field: 'localEstoque.nome',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="localEstoque"
                        id="localEstoque"
                        options={opcoesLocaisEstoque}
                        className={classes.textField}
                        disabled={
                            prop.rowData.centroCustoId ||
                            !prop.rowData.produtoServico?.produto ||
                            prop.rowData.patrimonioId
                        }
                        value={
                            prop.rowData.localEstoque
                                ? prop.rowData.localEstoque
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) => (option ? option.nome : '')}
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.localEstoque = newValue
                            data.centroCusto = ''
                            data.centroCustoId = null
                            newValue
                                ? (data.localEstoqueId = newValue.id)
                                : (data.localEstoqueId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="localEstoque"
                                id="localEstoque"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Safra',
            field: 'grupoSafra.nome',
            editComponent: (prop) => (
                <div className="width-100">
                    <AsyncAutoComplete
                        name="grupoSafra"
                        id="grupoSafra"
                        asyncLoadOptions={async () => {
                            const data = await  buscarGrupoSafra(
                                prop.rowData.centroCusto
                                ,true
                            )
                            if(data.length === 0) {
                                return null
                            }
                               return data
                        }}
                        useCache
                        cacheHash={prop.rowData.centroCusto?.descricao}
                        className={classes.textField}
                        value={prop.rowData.grupoSafra ? prop.rowData.grupoSafra : ''}
                        style={{ width: 150 }}
                        getOptionLabel={(option) => (option ? option.nome : '')}
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.grupoSafra = newValue
                           // data.centroCusto = ''
                           // data.centroCustoId = null
                            data.safra = ''
                            data.safraId = null
                            newValue
                                ? (data.grupoSafraId = newValue.id)
                                : (data.grupoSafraId = null)
                            prop.onRowDataChange(data)
                        }}
                        error={(!prop.rowData.centroCusto ||
                            prop.rowData.centroCusto.tipo !== 0 ||
                            prop.rowData.centroCusto.safras.length !== 0) &&
                             !prop.rowData.grupoSafraId}
                        helperText={
                            (!prop.rowData.centroCusto ||
                            prop.rowData.centroCusto.tipo !== 0 ||
                            prop.rowData.centroCusto.safras.length !== 0) &&
                            !prop.rowData.grupoSafraId
                                    ? 'Campo Obrigatório'
                                    : ''
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={(!prop.rowData.centroCusto ||
                                    prop.rowData.centroCusto.tipo !== 0||
                                    prop.rowData.centroCusto.safras.length !== 0) &&
                                     !prop.rowData.grupoSafraId}
                                helperText={
                                    (!prop.rowData.centroCusto ||
                                    prop.rowData.centroCusto.tipo !== 0||
                                    prop.rowData.centroCusto.safras.length !== 0) &&
                                    !prop.rowData.grupoSafraId
                                            ? 'Campo Obrigatório'
                                            : ''
                                }
                                name="safra"
                                id="safra"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Atividade',
            field: 'safra.nome',
            editComponent: (prop) => (
                <div className="width-100">
                    <AsyncAutoComplete
                        name="safra"
                        id="safra"
                        disabled={!prop.rowData.grupoSafraId}
                        asyncLoadOptions={() =>
                            buscarSafra(
                                prop.rowData.centroCusto,
                                prop.rowData.grupoSafraId,
                                true
                            )
                        }
                        className={classes.textField}
                        useCache
                        cacheHash={prop.rowData.grupoSafra?.nome}
                        defaultValue={prop.rowData.safra ? prop.rowData.safra : ''}
                        value={prop.rowData.safra ? prop.rowData.safra : ''}
                        style={{ width: 150 }}
                        getOptionLabel={(option) => (option ? option.nome : '')}
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.safra = newValue
                            //data.centroCusto = ''
                           // data.centroCustoId = null
                            newValue
                                ? (data.safraId = newValue.id)
                                : (data.safraId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="safra"
                                id="safra"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Conta Gerencial',
            field: 'contaGerencial.descricao',
            editComponent: (prop) => (
                <div className="width-100">
                    <Autocomplete
                        name="contaGerencial"
                        id="contaGerencial"
                        options={opcoesContaGerencial}
                        className={classes.textField}
                        disableClearable
                        value={
                            prop.rowData.contaGerencial
                                ? prop.rowData.contaGerencial
                                : ''
                        }
                        style={{ width: 150 }}
                        getOptionLabel={(option) =>
                            option ? option.descricao : ''
                        }
                        getOptionSelected={(option, value) =>
                            option?.id === value?.id
                        }
                        onChange={(event, newValue) => {
                            var data = { ...prop.rowData }
                            data.contaGerencial = newValue
                            newValue
                                ? (data.contaGerencialId = newValue.id)
                                : (data.contaGerencialId = null)
                            prop.onRowDataChange(data)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="contaGerencial"
                                id="contaGerencial"
                                variant="standard"
                                placeholder="Selecione"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!prop.rowData.contaGerencialId}
                                helperText={!prop.rowData.contaGerencialId ? "Campo Obrigatório!" : ''}
                            />
                        )}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Unitário',
            field: 'valorUnitario',
            render: (rowData) =>
                rowData.valorUnitario?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
            editComponent: (prop) => (
                <div>
                    <NumberFormat
                        prefix={'R$ '}
                        style={{ width: 140 }}
                        value={prop.rowData.valorUnitario}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        customInput={TextField}
                        error={
                            prop.rowData.valorUnitario <= 0 ||
                            !prop.rowData.valorUnitario
                        }
                        helperText={
                            prop.rowData.valorUnitario <= 0
                                ? 'Informe um valor positivo!'
                                : !prop.rowData.valorUnitario
                                    ? 'Campo obrigatório!'
                                    : ''
                        }
                        onValueChange={(item) => {
                            let novoValor = item.value
                            var data = { ...prop.rowData }
                            data.valorUnitario = parseFloat(novoValor || 0)
                            let novoValorTotal =
                                data.valorUnitario * data.quantidade
                            data.valorTotal = novoValorTotal
                            prop.onRowDataChange(data)
                        }}
                        id="valorUnitario"
                        name="valorUnitario"
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'right' },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Valor Total',
            field: 'valorTotal',
            type: 'currency',
            render: (rowData) =>
                rowData.valorTotal?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                }),
            editComponent: (prop) => (
                <div>
                    <NumberFormat
                        value={prop.rowData.valorTotal}
                        style={{ width: 100 }}
                        prefix={'R$ '}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        customInput={TextField}
                        disabled={
                            !prop.rowData.quantidade ||
                            prop.rowData.quantidade <= 0
                        }
                        error={
                            prop.rowData.valorTotal <= 0 ||
                            !prop.rowData.valorTotal
                        }
                        helperText={
                            prop.rowData.valorTotal <= 0
                                ? 'Informe um valor positivo!'
                                : !prop.rowData.valorTotal
                                    ? 'Campo obrigatório!'
                                    : ''
                        }
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'left',
                            },
                        }}
                        isplaceholderanimated={(false, 'top')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        id="valorTotal"
                        name="valorTotal"
                        onValueChange={(e) => {
                            let valorUnitario =
                                parseFloat(e.value || 0) /
                                parseFloat(prop.rowData.quantidade || 0)
                            var data = { ...prop.rowData }
                            data.valorTotal = parseFloat(e.value || 0)
                            data.valorUnitario = valorUnitario
                            prop.onRowDataChange(data)
                        }}
                    />
                </div>
            ),
        },
    ]

    const alteraProdutosServicosListagem = (produto) => {
        let newLista = [...produtoServicoListagem]
        newLista.push(produto)
        for (var i = 0, l = newLista.length; i < l; i++) {
            newLista[i].item = i + 1
        }
        setprodutoServicoListagem(newLista)
    }

    const validSubmitEditTableProdutosServicos = async (lista) => {
        let error = true
        await lista.forEach((item) => {
            if (
                item.produtoServico === null ||
                item.valorUnitario === (null || '' || 'R$' || '$') ||
                item.valorUnitario <= 0 ||
                item.quantidade <= 0 ||
                item.unidadeId == null ||
                item.contaGerencialId == null ||
                (item.alocacaoCusto === 'CENTRO_DE_CUSTO' &&
                    !item.centroCustoId) ||
                (item.alocacaoCusto === 'PATRIMONIO' &&
                    !item.patrimonioId) ||
                ((!item.centroCusto ||
                item.centroCusto.tipo !== 0 ||
                item.centroCusto.safras.length) &&
                 !item.grupoSafraId)

            ) {
                error = false
            }
        })

        return error
    }

    const checkRequiredFields = (error) => {
        let data = Object.entries(error)
        data.forEach((e) =>
            Object.values(e[1])?.forEach((i) => {
                ToastHelper.error(i)
            })
        )
    }

    const onSubmit = async () => {
        if (!validSubmit()) return

        let dados = form
        dados.creditoDebito = contaAPagar ? 1 : 0
        dados.id = params.modo !== 'duplicar' ? params.id : null
        dados.fazendaId = fazendaId
        dados.parcelas = parcelas
        dados.acrescimo = acrescimo || 0
        dados.desconto = descontos || 0
        dados.previsto = form.previsto ? form.previsto : false
        dados.valorLiquido = valorLiquido
        dados.valorProdutos = total
        dados.produtosServicos = produtoServicoListagem
        let data = ContasRequests.cadastrar(dados)
        data.then((res) => {
            ToastHelper.success('Conta registrada com sucesso!')
            if (contaAPagar) {
                history.push('/app/conta/pagar')
            } else {
                history.push('/app/conta/receber')
            }
        }).catch((e) => {
            const { data } = e.response
            if (e.response.status === 500)
                return ToastHelper.error(
                    e.response.data ||
                        'Ocorreu um erro, por favor acione o administrador do sistema.'
                )

            if (e.response.status === 400 || e.response.status === 422)
                checkRequiredFields(data)

            setError(data)
        })
    }

    const verificaSeExisteOMesmoDocumentoPraEsteFornecedorCliente =
        async () => {
            ContasRequests.jaExisteDocumentoFornecedorCliente(
                form.fornecedorClienteId,
                form.documento,
                form.id
            ).then((res) => {
                if (res.result === true) setShowConfirmDocumentoDuplicado(true)
                else onSubmit()
            })
        }

    const gerarContaDeContrato = async (id) => {
        let contrato = await GestaoContratoService.buscarPorId(id)
        await buscarFornecedoresClientes(
            contrato.data.gestaoContrato.pedidoA.toLowerCase()
        )
        var valorLiquido = 0
        contrato.data.produtoServico.forEach((item) => {
            valorLiquido += item.valorTotal
        })

        valorLiquido =
            valorLiquido +
            contrato.data.gestaoContrato.acrescimo -
            contrato.data.gestaoContrato.desconto
        setForm({
            ...form,
            pagamentoA: contrato.data.gestaoContrato.pedidoA,
            fornecedorCliente: {
                label: contrato.data.gestaoContrato.fornecedorCliente
                    .nomeFantasia,
                value: contrato.data.gestaoContrato.fornecedorClienteId,
            },
            fornecedorClienteId:
                contrato.data.gestaoContrato.fornecedorClienteId,
            descricao: contrato.data.gestaoContrato.descricao,
            numeroContrato: contrato.data.gestaoContrato.numeroPedido,
        })
        setprodutoServicoListagem(contrato.data.produtoServico)
        setTotal(contrato.data.valorTotal)
        setValorLiquido(valorLiquido)

        let valorTotalNota = 0
        contrato.data.parcelas.forEach((item) => {
            valorTotalNota += item.valor
        })
        contrato.data.parcelas.forEach((item) => {
            item.valorTotalNota = valorTotalNota
        })
        setParcelas(contrato.data.parcelas.map((parcela) => {
            parcela.situacao = "Definitivo";

            return parcela;
        }))
        setDescontos(
            contrato.data.gestaoContrato.desconto
                ? contrato.data.gestaoContrato.desconto
                : 0
        )
        setAcrescimo(
            contrato.data.gestaoContrato.acrescimo
                ? contrato.data.gestaoContrato.acrescimo
                : 0
        )
        params.id = ''
    }

    const gerarContaDePedido = async (id) => {
        let pedido = await PedidoService.buscarPorId(id)
        await buscarFornecedoresClientes(
            pedido.data.pedidos.pedidoFeitoA === 0 ? 'fornecedor' : 'cliente'
        )

        var valorLiquido = 0
        pedido.data.produtoServico.forEach((item) => {
            valorLiquido += item.valorTotal
        })

        valorLiquido =
            valorLiquido +
            pedido.data.pedidos.acrescimo -
            pedido.data.pedidos.desconto

        setForm({
            ...form,
            pagamentoA:
                pedido.data.pedidos.pedidoFeitoA === 0
                    ? 'Fornecedor'
                    : 'Cliente',
            fornecedorCliente: {
                label: pedido.data.pedidos.fornecedorCliente.nomeFantasia,
                value: pedido.data.pedidos.fornecedorClienteId,
            },
            fornecedorClienteId: pedido.data.pedidos.fornecedorClienteId,
            descricao: pedido.data.pedidos.descricao,
            numeroPedido: pedido.data.pedidos.numeroPedido,
        })
        setprodutoServicoListagem(pedido.data.produtoServico)
        setTotal(pedido.data.valorTotal)
        setValorLiquido(valorLiquido)

        let valorTotalNota = 0
        pedido.data.parcelas.forEach((item) => {
            valorTotalNota += item.valor
        })
        pedido.data.parcelas.forEach((item) => {
            item.valorTotalNota = valorTotalNota
        })
        setParcelas(pedido.data.parcelas.map((parcela) => {
            parcela.situacao = "Definitivo";

            return parcela;
        }))
        setDescontos(
            pedido.data.pedidos.desconto ? pedido.data.pedidos.desconto : 0
        )
        setAcrescimo(
            pedido.data.pedidos.acrescimo ? pedido.data.pedidos.acrescimo : 0
        )
        params.id = null
    }

    const cadastroFornecedorCliente = (data) => {
        FornecedorClienteService.cadastrar({ ...data, fazendaId: fazendaId })
            .then((response) => {
                setOpenModalFornecedorCliente(false)
                buscarFornecedoresClientes(form.pagamentoA)
                setForm({
                    ...form,
                    fornecedorCliente: {
                        label: response.data.nomeFantasia,
                        value: response.data.id,
                    },
                })
            })
            .catch((e) => {
                ToastHelper.error(e.response.data)
            })
    }

    const cadastroTipoDocumento = async () => {
        const win = window.open(`/app/tipoDocumento/cadastro/aba`, '_blank')
        win.addEventListener(
            'beforeunload',
            () => {
                buscarTiposDocumentos()
            },
            false
        )
    }

    const buscarProdutoresRurais = async () => {
        let data = await ContasRequests.buscarProdutorRural()

        setOpcoesProdutorRural(data)
    }

    const cadastroProdutorRural = async () => {
        const win = window.open(`/app/produtorRural/cadastro/aba`, '_blank')
        win.addEventListener(
            'beforeunload',
            () => {
                buscarProdutoresRurais()
            },
            false
        )
    }

    const formataPedido = (pedido) => {
        if (pedido?.dataPedido) {
            const numeroPedido = pedido.numeroPedido.toString()
            const dataPedido = DataHelper.transformStringDataUsaToPtBr(pedido.dataPedido)
            const valorTotal = pedido.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

            return `${numeroPedido} - ${dataPedido} - ${valorTotal}`
        }

        return ''
    }

    const formataContrato = (contrato) => {
        if (contrato?.dataPedido) {
            const numeroPedido = contrato.numeroPedido.toString()
            const dataPedido = DataHelper.transformStringDataUsaToPtBr(contrato.dataPedido)
            const valorTotal = contrato.valorLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

            return `${numeroPedido} - ${dataPedido} - ${valorTotal}`
        }

        return ''
    }

    const formataPedidosLista = (pedidosLista) => {
        return pedidosLista.map((pedido) => ({
            valor: pedido.numeroPedido,
            valorLiquido: pedido.valorTotal,
            campo: formataPedido(pedido)
        }))
    }

    const formataContratosLista = (contratosLista) => {
        return contratosLista.map((contrato) => ({
            valor: contrato.numeroPedido,
            valorLiquido: contrato.valorLiquido,
            campo: formataContrato(contrato)
        }))
    }

    const onHandleSubmit = (event) => {
        event.preventDefault()

        if (params.tipo === "pagar") {
            if (pedidoSelecionado?.valorLiquido && pedidoSelecionado?.valorLiquido < valorLiquido) {
                setShowConfirmPedidos(true)
            } else {
                handleSubmit(verificaSeExisteOMesmoDocumentoPraEsteFornecedorCliente())
            }
        }

        if (params.tipo === "receber") {
            if (contratoSelecionado?.valorLiquido && contratoSelecionado?.valorLiquido < valorLiquido) {
                setShowConfirmContratos(true)
            } else {
                handleSubmit(verificaSeExisteOMesmoDocumentoPraEsteFornecedorCliente())
            }
        }
    }

    return (
        <Page className={classes.root} title={titulo}>
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={onHandleSubmit}
                >
                    <Paper elevation={1} className={classes.card}>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={6} display="flex">
                                <h2
                                    style={{
                                        marginLeft: '5px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Dados Gerais
                                </h2>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={3} display="flex">
                            <Grid container item xs={6} display="flex">
                                <FormControl className={classes.formControl}>
                                    <SelectWithButton
                                        id="tipoDocumento"
                                        name="tipoDocumento"
                                        register={register}
                                        options={tiposDocumentos}
                                        value={
                                            form.tipoDocumento
                                                ? form.tipoDocumento
                                                : ''
                                        }
                                        getOptionLabel={(option) =>
                                            option ? option.descricao : ''
                                        }
                                        getOptionSelected={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setForm({
                                                    ...form,
                                                    tipoDocumento: newValue,
                                                    tipoDocumentoId:
                                                        newValue.id,
                                                })
                                            } else {
                                                const {
                                                    tipoDocumentoId,
                                                    ...newForm
                                                } = form
                                                setForm(newForm)
                                            }
                                        }}
                                        label="Tipo de Documento:*"
                                        placeholder="Selecione"
                                        cadastro={cadastroTipoDocumento}
                                        tooltip="Cadastro de tipo de documento"
                                        error={error && error.tipoDocumentoId}
                                        errorMessage={'Campo Obrigatório'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={6} display="flex">
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        type="text"
                                        label={LabelWithInfo({
                                            label: 'Documento*',
                                            tooltipText:
                                                'Informe SN se não houver ou não souber o número do documento',
                                        })}
                                        value={form.documento}
                                        disabled={visualizar}
                                        className={classes.textField}
                                        inputRef={register}
                                        id="documento"
                                        name="documento"
                                        onChange={(event) =>
                                            setForm({
                                                ...form,
                                                documento: event.target.value,
                                            })
                                        }
                                        isplaceholderanimated={(false, 'top')}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {error && error.documento && (
                                        <p className={classes.errors}>
                                            {' '}
                                            Campo Obrigatório
                                        </p>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={3} display="flex">
                            <Grid container item xs={6} display="flex">
                                <FormControl className={classes.formControl}>
                                    <Autocomplete
                                        id="pagamentoA"
                                        options={opcoesPagamentoA || []}
                                        getOptionLabel={(option) =>
                                            option ? option.label : ''
                                        }
                                        getOptionSelected={(option, value) =>
                                            option?.value === value?.value
                                        }
                                        disabled={visualizar || !parcelas.every((item) => !item.dataPagamento)}
                                        onChange={(event, value) => {
                                            setPedidos([])
                                            setContratos([])

                                            setPedidoSelecionado({ valor: "", valorLiquido: null, campo: "" })
                                            setContratoSelecionado({ valor: "", valorLiquido: null, campo: "" })

                                            if (value) {
                                                buscarFornecedoresClientes(
                                                    value.value
                                                )
                                                setForm({
                                                    ...form,
                                                    numeroContrato: null,
                                                    numeroPedido: null,
                                                    pagamentoA: value.label,
                                                    pagamentoAValue: value,
                                                    fornecedorCliente:
                                                        undefined,
                                                    fornecedorClienteId:
                                                        undefined,
                                                })
                                            } else {
                                                setForm({
                                                    ...form,
                                                    numeroContrato: null,
                                                    numeroPedido: null,
                                                    pagamentoA: null,
                                                    pagamentoAValue: null,
                                                    fornecedorCliente:
                                                        undefined,
                                                    fornecedorClienteId:
                                                        undefined,
                                                })
                                            }
                                        }}
                                        value={
                                            form.pagamentoA
                                                ? opcoesPagamentoA.find(
                                                      (x) =>
                                                          x.label ===
                                                          form.pagamentoA
                                                  )
                                                : contaAPagar
                                                ? opcoesPagamentoA[1]
                                                : opcoesPagamentoA[0]
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label={
                                                    contaAPagar
                                                        ? 'Pagamento a:*'
                                                        : 'Receber de:*'
                                                }
                                                placeholder="Selecione"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    {error && error.pagamentoA && (
                                        <p className={classes.errors}>
                                            {' '}
                                            Campo Obrigatório
                                        </p>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid container item xs={6} display="flex">
                                <FormControl className={classes.formControl}>
                                    <SelectWithButton
                                        id="fornecedorCliente"
                                        name="fornecedorCliente"
                                        options={
                                            opcoesFornecedoresClientes || []
                                        }
                                        value={
                                            form.fornecedorCliente
                                                ? form.fornecedorCliente
                                                : ''
                                        }
                                        getOptionLabel={(option) =>
                                            option ? option.label : ''
                                        }
                                        getOptionSelected={(option, value) =>
                                            option?.value === value?.value
                                        }
                                        onChange={(event, newValue) => {
                                            setPedidos([])
                                            setContratos([])

                                            setPedidoSelecionado({ valor: "", valorLiquido: null, campo: "" })
                                            setContratoSelecionado({ valor: "", valorLiquido: null, campo: "" })

                                            if (params.tipo === "pagar") {
                                                if (form.pagamentoA === "Cliente") {
                                                    PedidosService.buscarFornecedorClientePorId(newValue?.value).then((res) => {
                                                        if (res.data.length > 0) {
                                                            const pedidosLista = formataPedidosLista(res.data)

                                                            setPedidos(pedidosLista)

                                                            ToastHelper.info("Você tem pedido(s) cadastrado(s) para esse cliente!")
                                                        }
                                                    })
                                                }

                                                if (form.pagamentoA === "Fornecedor") {
                                                    PedidosService.buscarFornecedorClientePorId(newValue?.value).then((res) => {
                                                        if (res.data.length > 0) {
                                                            const pedidosLista = formataPedidosLista(res.data)

                                                            setPedidos(pedidosLista)

                                                            ToastHelper.info("Você tem pedido(s) cadastrado(s) para esse fornecedor!")
                                                        }
                                                    })
                                                }
                                            }

                                            if (params.tipo === "receber") {
                                                if (form.pagamentoA === "Cliente") {
                                                    GestaoContratoService.buscarFornecedorClientePorId(newValue?.value).then((res) => {
                                                        if (res.data.length > 0) {
                                                            const contratosLista = formataContratosLista(res.data)

                                                            setContratos(contratosLista)

                                                            ToastHelper.info("Você tem contrato(s) cadastrado(s) para esse cliente!")
                                                        }
                                                    })
                                                }

                                                if (form.pagamentoA === "Fornecedor") {
                                                    GestaoContratoService.buscarFornecedorClientePorId(newValue?.value).then((res) => {
                                                        if (res.data.length > 0) {
                                                            const contratosLista = formataContratosLista(res.data)

                                                            setContratos(contratosLista)

                                                            ToastHelper.info("Você tem contrato(s) cadastrado(s) para esse fornecedor!")
                                                        }
                                                    })
                                                }
                                            }

                                            setForm({
                                                ...form,
                                                numeroContrato: null,
                                                numeroPedido: null,
                                                fornecedorCliente: newValue,
                                                fornecedorClienteId:
                                                    newValue?.value,
                                            })
                                        }}
                                        label={
                                            form.pagamentoA
                                                ? form.pagamentoA ===
                                                  'Fornecedor'
                                                    ? 'Fornecedor:*'
                                                    : 'Cliente:*'
                                                : 'Cliente:*'
                                        }
                                        placeholder="Selecione"
                                        disabled={!form.pagamentoA || !parcelas.every((item) => !item.dataPagamento)}
                                        cadastro={() =>
                                            setOpenModalFornecedorCliente(true)
                                        }
                                        tooltip={
                                            form.pagamentoA
                                                ? form.pagamentoA ===
                                                  'Fornecedor'
                                                    ? 'Cadastro de Fornecedor'
                                                    : 'Cadastro de Cliente'
                                                : 'Cadastro de Fornecedor/Cliente'
                                        }
                                        error={
                                            error && error.fornecedorClienteId
                                        }
                                        errorMessage={'Campo Obrigatório'}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={3} display="flex">
                            <Grid container item xs={6} display="flex">
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="dataLancamento"
                                            label="Data de lançamento:"
                                            name="dataLancamento"
                                            type="date"
                                            value={
                                                form.dataLancamento
                                                    ? form.dataLancamento
                                                    : new Date()
                                            }
                                            defaultValue={
                                                form.dataLancamento
                                                    ? form.dataLancamento
                                                    : new Date()
                                            }
                                            disabled={true}
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    dataLancamento:
                                                        e.target.value,
                                                })
                                            }
                                            className={classes.textField}
                                            inputRef={register}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {error && error.dataLancamento && (
                                            <p className={classes.errors}>
                                                {' '}
                                                Campo Obrigatório
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            id="dataEmissao"
                                            label="Data de emissão:"
                                            name="dataEmissao"
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    dataEmissao: e.target.value,
                                                })
                                            }
                                            value={
                                                form.dataEmissao
                                                    ? form.dataEmissao
                                                    : ''
                                            }
                                            defaultValue={
                                                form.dataEmissao
                                                    ? form.dataEmissao
                                                    : ''
                                            }
                                            disabled={visualizar}
                                            type="date"
                                            className={classes.textField}
                                            inputRef={register}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                {
                                                    inputProps: {max: '9999-12-31'}
                                                }
                                            }
                                        />
                                        {error && error.dataEmissao && (
                                            <p className={classes.errors}>
                                                {' '}
                                                Campo Obrigatório
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {contaAPagar && (
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            id="numeroPedido"
                                            options={pedidos}
                                            disabled={visualizar}
                                            getOptionLabel={(option) => option?.campo || "" }
                                            onChange={(event, value) => {
                                                setPedidoSelecionado(value)

                                                setForm({
                                                    ...form,
                                                    numeroPedido: value?.valor ?? null
                                                })
                                            }}
                                            value={pedidoSelecionado}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Número do pedido:"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        {error && error.numeroPedido && (
                                            <p className={classes.errors}>
                                                {' '}
                                                Campo Obrigatório
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            {!contaAPagar && (
                                <Grid container item xs={6} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            id="numeroContrato"
                                            options={contratos}
                                            disabled={visualizar}
                                            getOptionLabel={(option) => option?.campo || "" }
                                            onChange={(event, value) => {
                                                setContratoSelecionado(value)

                                                setForm({
                                                    ...form,
                                                    numeroContrato: value?.valor ?? null
                                                })
                                            }}
                                            value={contratoSelecionado}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Número do contrato:"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        {error && error.numeroContrato && (
                                            <p className={classes.errors}>
                                                {' '}
                                                Campo Obrigatório
                                            </p>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container item xs={12} spacing={3} display="flex">
                            <Grid container item xs={6} display="flex">
                                <FormControl className={classes.formControl}>
                                    <SelectWithButton
                                        id="produtorRural"
                                        name="produtorRural"
                                        register={register}
                                        options={opcoesProdutorRural}
                                        value={
                                            form.produtorRural
                                                ? form.produtorRural
                                                : ''
                                        }
                                        getOptionLabel={(option) =>
                                            option ? option.nome : ''
                                        }
                                        getOptionSelected={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        onChange={(event, newValue) => {
                                            setForm({
                                                ...form,
                                                produtorRural: newValue,
                                                produtorRuralId: newValue
                                                    ? newValue.id
                                                    : '',
                                            })
                                        }}
                                        label="Produtor Rural:"
                                        placeholder="Selecione"
                                        cadastro={cadastroProdutorRural}
                                        tooltip="Cadastro de produtor rural"
                                        error={error && error.produtorRuralId}
                                        errorMessage={'Campo Obrigatório'}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            display="flex"
                            className={classes.marginLeft7px}
                        >
                            <Grid container item xs={3} display="flex">
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    desconsiderarLivroCaixa:
                                                        !form.desconsiderarLivroCaixa,
                                                })
                                            }}
                                            checked={
                                                form.desconsiderarLivroCaixa !==
                                                    undefined &&
                                                form.desconsiderarLivroCaixa ===
                                                    true
                                                    ? true
                                                    : false
                                            }
                                            value={
                                                form.desconsiderarLivroCaixa !==
                                                    undefined &&
                                                form.desconsiderarLivroCaixa ===
                                                    true
                                                    ? true
                                                    : false
                                            }
                                            name="desconsiderarLivroCaixa"
                                        />
                                    }
                                    label="Desconsiderar no livro caixa"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={3} display="flex">
                            <Grid container item xs={12} display="flex">
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        type="text"
                                        label="Descrição:"
                                        className={classes.textField}
                                        inputRef={register}
                                        id="descricao"
                                        name="descricao"
                                        value={form.descricao}
                                        disabled={visualizar}
                                        onChange={(event) =>
                                            setForm({
                                                ...form,
                                                descricao: event.target.value,
                                            })
                                        }
                                        multiline
                                        variant="outlined"
                                        rows={2}
                                        isplaceholderanimated={(false, 'top')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className={classes.cardMeio}>
                        <Grid container item xs={12} display="flex">
                            <Grid container item xs={6} display="flex">
                                <h2
                                    style={{
                                        marginLeft: '5px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Produtos e Serviços
                                </h2>
                            </Grid>
                        </Grid>
                        <Fab
                            onClick={handleOpenModalProdutosServicos}
                            className={classes.buttonModalProdutosServicos}
                            color="primary"
                            size="small"
                            aria-label="add"
                        >
                            <AddIcon />
                        </Fab>

                        <Grid
                            container
                            item
                            xs={12}
                            style={{ display: 'block' }}
                        >
                            <Table
                                id="table"
                                title={''}
                                columns={
                                    params.tipo === 'pagar'
                                        ? colunas
                                        : colunas.filter(
                                              (item) => item.title !== 'Serviço'
                                          )
                                }
                                data={produtoServicoListagem}
                                page={1}
                                totalCount={1}
                                rowStyle={{ width: 300 }}
                                paging={false}
                                pageSize={produtoServicoListagem.length}
                                emptyRowsWhenPaging={false}
                                onChangeEdit={(edit) =>
                                    onChangeEdit(edit, 'editPrdutosServicos')
                                }
                                editable={{
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve, reject) => {
                                            const dataDelete = [
                                                ...produtoServicoListagem,
                                            ]
                                            const index = oldData.tableData.id
                                            dataDelete.splice(index, 1)
                                            setprodutoServicoListagem(
                                                dataDelete
                                            )
                                            resolve()
                                        }),

                                    onBulkUpdate: (changes) =>
                                        new Promise(async (resolve, reject) => {
                                            let array = [
                                                ...produtoServicoListagem,
                                            ]

                                            for (let [
                                                // eslint-disable-next-line no-unused-vars
                                                key,
                                                value,
                                            ] of Object.entries(changes)) {
                                                const index =
                                                    value.oldData.tableData.id
                                                array[index] = value.newData
                                            }

                                            let valido =
                                                await validSubmitEditTableProdutosServicos(
                                                    array
                                                )
                                            if (valido) {
                                                setprodutoServicoListagem(array)
                                                resolve()
                                            } else {
                                                reject()
                                            }
                                        }),
                                }}
                            />

                            <Grid
                                container
                                item
                                xs={12}
                                style={{ marginTop: '15px' }}
                                display="flex"
                            >
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Quantidade de itens"
                                            value={
                                                produtoServicoListagem.length
                                            }
                                            disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Valor dos Produtos/Serviços"
                                            value={total.toLocaleString(
                                                'pt-br',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }
                                            )}
                                            disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            id="acrescimos"
                                            label="Acréscimos:"
                                            prefix={'R$ '}
                                            value={acrescimo}
                                            decimalSeparator={','}
                                            customInput={TextField}
                                            onChange={(e) =>
                                                onChangeAcrescimos(e)
                                            }
                                            inputProps={{
                                                min: 0,
                                                style: {
                                                    textAlign: 'left',
                                                },
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <NumberFormat
                                            id="descontos"
                                            label="Descontos:"
                                            prefix={'R$ '}
                                            value={descontos}
                                            decimalSeparator={','}
                                            customInput={TextField}
                                            onChange={(e) =>
                                                onChangeDescontos(e)
                                            }
                                            inputProps={{
                                                min: 0,
                                                style: {
                                                    textAlign: 'left',
                                                },
                                            }}
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                         {erroDesconto && (
                                        <p className={classes.errors}>
                                            {' '}
                                            Desconto maior que o valor da conta!
                                        </p>
                                    )}
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={2} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            label="Valor Líquido"
                                            value={valorLiquido.toLocaleString(
                                                'pt-br',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }
                                            )}
                                            disabled={true}
                                            type="text"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className={classes.card}>
                        <Grid container item xs={12} display="flex">
                            <Parcelamento
                                disabled={visualizar}
                                limpar={limpar}
                                numeroParcelas={numeroParcelas}
                                diasEntreParcelas={diasEntreParcelas}
                                mudaDiasEntreParcelas={mudaDiasEntreParcelas}
                                mudaNumeroParcelas={mudaNumeroParcelas}
                                geraParcelas={geraParcelas}
                                diaDaParcela={diaDaParcela}
                                mudaDiaDaParcela={mudaDiaDaParcela}
                                dataVencimento={dataVencimento}
                                mudaDataVencimento={mudaDataVencimento}
                                valorConta={valorLiquido}
                                parcelas={parcelas}
                                deletaParcela={deletaParcela}
                                atualizaParcelas={atualizaParcelas}
                                opcoesSituacoes={opcoesSituacoes}
                                opcoesContas={opcoesContas}
                                opcoesFormaPagamento={opcoesFormaPagamento}
                                onChangeEdit={(edit) =>
                                    onChangeEdit(edit, 'editParcelas')
                                }
                            />
                        </Grid>
                    </Paper>
                    <Grid
                        container
                        item
                        xs={12}
                        display="flex"
                        className={classes.card}
                    >
                        <Grid
                            xs={6}
                            container
                            item
                            direction="row"
                            alignItems="center"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => history.goBack()}
                            >
                                Voltar
                            </Button>
                        </Grid>
                        <Grid
                            xs={6}
                            container
                            item
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            {!props.desabilitaSubmit && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="small"
                                    disabled={editProduto || editParcelas}
                                >
                                    Confirmar
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Container>
            <Modal
                open={openModalProdutosServicos}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div>
                    <Fade in={openModalProdutosServicos}>
                        <ModalProdutosServicos
                            close={() => handleCloseModalProdutosServicos()}
                            opcoesProdutosServicos={opcoesProdutosServicos}
                            buscarContasGerenciais={buscarContasGerenciais}
                            opcoesServicos={opcoesServicos}
                            opcoesCentroCusto={opcoesCentroCusto}
                            opcoesPatrimonio={opcoesPatrimonio}
                            opcoesLocaisEstoque={opcoesLocaisEstoque}
                            opcoesSafra={opcoesSafra}
                            opcoesGrupoSafras={opcoesGrupoSafras}
                            opcoesContaGerencial={opcoesContaGerencial}
                            alteraProdutosServicosListagem={(produto) =>
                                alteraProdutosServicosListagem(produto)
                            }
                            setGrupoSafraId={setGrupoSafraId}
                            setCentroDeCusto={setCentroDeCusto}
                            centroDeCusto={centroDeCusto}
                            id={params.id}
                            tipo={params.tipo}
                            buscarProdutosServicos={buscarProdutosServicos}
                        />
                    </Fade>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={openModalFornecedorCliente}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <ClienteFornecedorCadastroModal
                        onClose={() => setOpenModalFornecedorCliente(false)}
                        submit={(data) => cadastroFornecedorCliente(data)}
                        pagamentoA={form.pagamentoA}
                    />
                </div>
            </Modal>
            <ModalConfirmDocumentoDuplicado
                open={showConfirmDocumentoDuplicado}
                cancel={() => {
                    setShowConfirmDocumentoDuplicado(false)
                }}
                continue={onSubmit}
            />
            <ModalConfirmPedidos
                open={showConfirmPedidos}
                cancel={() => {
                    setShowConfirmPedidos(false)
                }}
                continue={handleSubmit(verificaSeExisteOMesmoDocumentoPraEsteFornecedorCliente)}
            />
            <ModalConfirmContratos
                open={showConfirmContratos}
                cancel={() => {
                    setShowConfirmContratos(false)
                }}
                continue={handleSubmit(verificaSeExisteOMesmoDocumentoPraEsteFornecedorCliente)}
            />
        </Page>
    )
}

/**
 * @link labelWithTooltip requisição https://trello.com/c/u1IhHpBA/66-financeiro-contas-a-pagar-e-receber-numero-de-documento-3-vt-alinhado
 */
