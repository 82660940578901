import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import CentroNegocioListagem from './CentroNegocioListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import CentroNegocioService from '../../services/CentroNegocioService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const CentroNegocio = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [loading, setLoading] = useState(false)
    const [centroNegocio, setCentroNegocio] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.centroNegocio.filtroListagem)
    )

    useEffect(() => {
        async function init() {
            buscarCentroNegocio(filtro)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CENTRO_NEGOCIO',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        filtro.ativo = true
        buscarCentroNegocio(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CENTRO_NEGOCIO',
            filtroListagem: filtro,
        })
    }

    const cadastrar = () => {
        props.history.push({
            pathname: `/app/centroNegocio/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarCentroNegocio(novoFiltro)
    }

    const buscarCentroNegocio = async (filtro) => {
        filtro.fazendaId = FazendaId
        let params = await ObjectHelper.createParams(filtro)
        setLoading(true)
        await CentroNegocioService.buscarTudo(params).then((response) => {
            if (response.status === 200) {
                let newFiltro = { ...filtro }
                newFiltro.fazendaId = FazendaId
                newFiltro.totalItens = response.data.totalItens
                newFiltro.totalPaginas = response.data.totalPaginas
                newFiltro.tamanhoPagina = filtro.tamanhoPagina
                    ? filtro.tamanhoPagina
                    : 100
                newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                newFiltro.ativo = true
                alteraFiltro(newFiltro)
                setCentroNegocio(response.data.itens)
                setLoading(false)
            } else {
                ToastHelper.error(response.statusText)
                setLoading(false)
            }
        })
    }

    const editar = (itemId) => {
        history.push(`/app/centroNegocio/editar/${itemId}`)
    }

    const deletar = async (item) => {
        await CentroNegocioService.deletar(item.id)
            .then((response) => {
                if (response.status === 200) {
                    ToastHelper.success(
                        'Centro de negócio excluído com sucesso!'
                    )
                    let novoFiltro = { ...filtro }
                    buscarCentroNegocio(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    ToastHelper.error(error.response.data)
                }
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        alteraFiltro(novoFiltro)
        buscarCentroNegocio(novoFiltro)
    }

    const editarCelula = async (novoValor, valorAntigo, linha, coluna) => {
        var dados = centroNegocio
        dados[linha.tableData.id][coluna.field] = novoValor

        await CentroNegocioService.atualizar(dados[linha.tableData.id]).then(
            (response) => {
                if (response.status === 200) {
                    ToastHelper.success(
                        'Centro de negócio excluído com sucesso!'
                    )
                    let novoFiltro = { ...filtro }
                    buscarCentroNegocio(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            }
        )
    }

    return (
        <Page className={classes.root} title="Centros de Negócio">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Centros de Negócio" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                />
                <Box mt={3}>
                    <CentroNegocioListagem
                        data={centroNegocio}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        pageSize={filtro.tamanhoPagina}
                        editar={editar}
                        deletar={deletar}
                        mudaOrdenacao={mudaOrdenacao}
                        editarCelula={editarCelula}
                    />
                </Box>
            </Container>
        </Page>
    )
}
