import service from './service';

export default {
    cadastrar(param) {
        return service.post(`/CamposParcela`, param);
    },
    atualizar(param) {
        return service.put(`/CamposParcela`, param);
    },
    buscarTudo(param) {
        return service.get(`/CamposParcela${param}`);
    },
    deletar(id) {
        return service.delete(`/CamposParcela/${id}`);
    },
    buscarPeloId(id) {
        return service.get(`/CamposParcela/${id}`);
    },
    ativarDesativar(id) {
        return service.put(`/CamposParcela/AtivarDesativar/${id}`);
    },
};
