import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import { Box, Container, makeStyles } from '@material-ui/core'
import Toolbar from '../../components/BasicPageTopBar/Toolbar'
import ContaBancariaListagem from './ContaGerencialListagem'
import Titulo from '../../components/Titulo/index'
import { useSelector, useDispatch } from 'react-redux'
import ContaGerencialService from '../../services/ContaGerencialService'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

export const ContaGerencial = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [fazendaId] = useState(
        JSON.parse(localStorage.getItem('fazenda'))
            ? JSON.parse(localStorage.getItem('fazenda')).value
            : ''
    )
    const [loading, setLoading] = useState(false)
    const [bancos] = useState()
    const [contasGerenciais, setContasGerenciais] = useState()
    const [filtro, setFiltro] = useState(
        useSelector((state) => state.contaGerencial.filtroListagem)
    )

    useEffect(() => {
        const getContasGerenciais = async () => {
            let novoFiltro = { ...filtro }
            novoFiltro.decrescente = true
            novoFiltro.ordem = 'Numero'
            novoFiltro.tamanhoPagina = 100
            buscarContasGerenciais(novoFiltro)
        }
        getContasGerenciais()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alteraFiltro = async (filtro) => {
        await setFiltro(filtro)
        await dispatch({
            type: 'SET_FILTRO_LISTAGEM_CONTA_GERENCIAL',
            filtroListagem: filtro,
        })
    }

    const handleFiltro = (filtro) => {
        buscarContasGerenciais(filtro)
        dispatch({
            type: 'SET_FILTRO_LISTAGEM_CONTA_GERENCIAL',
            filtroListagem: filtro,
        })
    }

    const cadastrar = (item) => {
        props.history.push({
            pathname: `/app/contaGerencial/cadastro`,
        })
    }

    const onChangePage = async (page) => {
        let novoFiltro = { ...filtro }
        novoFiltro.pagina = page
        await alteraFiltro(novoFiltro)
        buscarContasGerenciais(novoFiltro)
    }

    const buscarContasGerenciais = async (filtro) => {
        let filtroParaPesquisa = { ...filtro }
        filtroParaPesquisa.fazendaId = fazendaId
        filtroParaPesquisa.banco = filtro.banco ? filtro.banco.value : ''
        filtroParaPesquisa.ativo = filtro.ativo ? filtro.ativo.value : ''
        filtroParaPesquisa.filtrarUso = true
        filtroParaPesquisa.tamanhoPagina = 99999
        filtroParaPesquisa.tipo = filtro.analiticoSintetico
            ? filtro.analiticoSintetico.value
            : ''
        let params = await ObjectHelper.createParams(filtroParaPesquisa)
        setLoading(true)
        await ContaGerencialService.buscarTudo(params)
            .then((response) => {
                if (response.status === 200) {
                    let newFiltro = { ...filtro }
                    newFiltro.fazendaId = fazendaId
                    newFiltro.totalItens = response.data.totalItens
                    newFiltro.totalPaginas = response.data.totalPaginas
                    newFiltro.filtrarUso = true
                    newFiltro.tamanhoPagina = filtro.tamanhoPagina
                        ? filtro.tamanhoPagina
                        : 100
                    newFiltro.pagina = filtro.pagina ? filtro.pagina : 1
                    alteraFiltro(newFiltro)
                    setContasGerenciais(response.data.itens)
                    setLoading(false)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const editar = (itemId) => {
        history.push(`/app/contaGerencial/editar/${itemId}`)
    }

    const ativarDesativar = async (itemId) => {
        setLoading(true)
        await ContaGerencialService.ativarDesativar({ id: itemId })
            .then((response) => {
                if (response.status === 200) {
                    let novoFiltro = { ...filtro }
                    buscarContasGerenciais(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const deletar = async (item) => {
        await ContaGerencialService.deletar(item.id)
            .then((response) => {
                if (response.status === 200) {
                    ToastHelper.success('Conta gerencial excluída com sucesso!')
                    let novoFiltro = { ...filtro }
                    buscarContasGerenciais(novoFiltro)
                } else {
                    ToastHelper.error(response.statusText)
                    setLoading(false)
                }
            })
            .catch((error) => {
                ToastHelper.error(error.response.data)
                setLoading(false)
            })
    }

    const mudaOrdenacao = (numeroColuna, orientacao, colunas) => {
        let novoFiltro = { ...filtro }
        novoFiltro.decrescente = !novoFiltro.decrescente
        novoFiltro.ordem = colunas[numeroColuna]?.field
        if (numeroColuna === 3) novoFiltro.ordem = 'operacao'
        alteraFiltro(novoFiltro)
        buscarContasGerenciais(novoFiltro)
    }

    const adicionaContaFilha = (idContaPai) => {
        history.push(`/app/contaGerencial/adicionaSubConta/${idContaPai}`)
    }

    return (
        <Page className={classes.root} title="Contas Gerenciais">
            <Container maxWidth={'xl'}>
                <Titulo titulo="Contas Gerenciais" />
                <Toolbar
                    handleFiltro={handleFiltro}
                    filtroInicial={filtro}
                    clickadd={cadastrar}
                    filtroBancos={bancos}
                    filtroAtivo
                    filtroAnaliticoSintetico
                    temFiltroAvancado
                />
                <Box mt={3}>
                    <ContaBancariaListagem
                        data={contasGerenciais}
                        onChangePage={(page) => onChangePage(page)}
                        filtro={filtro}
                        isLoading={loading}
                        editar={editar}
                        ativarDesativar={ativarDesativar}
                        deletar={deletar}
                        adicionaContaFilha={adicionaContaFilha}
                        mudaOrdenacao={mudaOrdenacao}
                    />
                </Box>
            </Container>
        </Page>
    )
}
