import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FooterBottons from '../../components/FooterBottons/FooterBottons'
import CamposParcelaService from '../../services/CamposParcelaService'
import ContasGerencialService from '../../services/ContaGerencialService'

import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { TipoEstoqueEsquema } from './camposParcela.validate'
import ToastHelper from '../../utils/toastHelper'
import ObjectHelper from '../../utils/objetcHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '90%',
    },
    formControlSituacao: {
        margin: theme.spacing(1),
        minWidth: '45%',
    },
    formControlValor: {
        margin: theme.spacing(1),
        minWidth: '85%',
    },
    errors: {
        color: 'red',
    },
    formControlObs: {
        margin: theme.spacing(1),
        minWidth: '95%',
    },
    divider: {
        margin: theme.spacing(1),
        marginTop: '15px',
        marginBottom: '15px',
    },
}))

export const CamposParcelaCadastro = (props) => {
    let history = useHistory()
    const classes = useStyles()
    const fazendaId = JSON.parse(localStorage.getItem('fazenda'))
        ? JSON.parse(localStorage.getItem('fazenda')).value
        : null

    const [titulo, setTitulo] = useState('Cadastrar Campos da Parcela')

    const [nome, setNome] = useState('')
    const [contaGerencialId, setContaGerencialId] = useState({
        value: '',
        label: '',
    })
    const [tipo, setTipo] = useState({
        value: '',
        label: '',
    })
    const [aplicadoContasPagar, setAplicadoContasPagar] = useState(false)
    const [aplicadoContasReceber, setAplicadoContasReceber] = useState(false)
    const [aplicadoAdiantamentosPagar, setAplicadoAdiantamentosPagar] = useState(false)
    const [aplicadoAdiantamentosReceber, setAplicadoAdiantamentosReceber] = useState(false)

    const tipos = [
        {
            value: 0,
            label: 'Desconto',
        },
        {
            value: 1,
            label: 'Acréscimo',
        },
    ]

    const [contasGerenciais, setContasGerenciais] = useState([])

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {},
        resolver: yupResolver(TipoEstoqueEsquema),
    })

    const {
        match: { params },
    } = props

    useEffect(() => {
        const init = async () => {
            await buscarContasGerenciais()
            if (params.id) {
                setTitulo('Editar Campos da Parcela')
                buscarParaEdicao(params.id)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (dados) => {
        let data = { ...dados }
        data.fazendaId = fazendaId
        data.nome = nome
        data.contaGerencialId = contaGerencialId.id
        data.tipo = tipo.value
        data.aplicadoContasPagar = aplicadoContasPagar
        data.aplicadoContasReceber = aplicadoContasReceber
        dados.aplicadoAdiantamentosPagar = aplicadoAdiantamentosPagar
        dados.aplicadoAdiantamentosReceber = aplicadoAdiantamentosReceber
        data.ativo = true
        if (params.id) {
            data.id = params.id
            CamposParcelaService.atualizar(data).then((response) => {
                if (response.status === 200) {
                    ToastHelper.success('Campos Parcela atualizado!')
                    history.goBack()
                } else {
                    ToastHelper.error(response.statusText)
                }
            })
        } else {
            data.ativo = true
            CamposParcelaService.cadastrar(data)
                .then((response) => {
                    if (response.status === 200) {
                        ToastHelper.success(
                            'Campos da Parcela cadastrado com sucesso!'
                        )
                        history.goBack()
                    }
                })
                .catch((error) => ToastHelper.error(error.response.data))
        }
    }

    const buscarParaEdicao = async (id) => {
        CamposParcelaService.buscarPeloId(id).then((response) => {
            if (response.status === 200) {
                setNome(response.data['nome'])
                setContaGerencialId({
                    value: response.data.contaGerencial
                        ? response.data.contaGerencial['id']
                        : '',
                    label: response.data.contaGerencial
                        ? response.data.contaGerencial['descricao']
                        : '',
                })
                setTipo(
                    tipos.find((item) => item.label === response.data['tipo'])
                )
                setAplicadoContasPagar(response.data['aplicadoContasPagar'])
                setAplicadoContasReceber(response.data['aplicadoContasReceber'])
                setAplicadoAdiantamentosPagar(response.data['aplicadoAdiantamentosPagar'])
                setAplicadoAdiantamentosReceber(response.data['aplicadoAdiantamentosReceber'])
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    const buscarContasGerenciais = async () => {
        let filtro = []
        filtro.fazendaId = fazendaId;
        filtro.tipo = 1;
        filtro.tamanhoPagina = 99999;
        filtro.semPadroes = true;
        let params = await ObjectHelper.createParams(filtro)
        ContasGerencialService.buscarTudo([params]).then(async (response) => {
            if (response.status === 200) {
                var options = []
                await response.data.itens.forEach((item) => {
                    options.push({
                        label: item.numero + ' - ' + item.descricao,
                        id: item.id,
                    })
                })
                options = await ObjectHelper.ordenaLista(options, 'label')
                setContasGerenciais(options)
            } else {
                ToastHelper.error(response.statusText)
            }
        })
    }

    return (
        <Page className={classes.root} title="Campos da Parcela">
            <Container maxWidth={'xl'}>
                <Titulo titulo={titulo} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item xs={12} display="flex">
                                <Grid container item xs={3} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <TextField
                                            type="text"
                                            label="Nome:*"
                                            value={nome}
                                            className={classes.textField}
                                            inputRef={register}
                                            onChange={(x) =>
                                                setNome(x.target.value)
                                            }
                                            id="nome"
                                            name="nome"
                                            isplaceholderanimated={
                                                (false, 'top')
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p className={classes.errors}>
                                            {errors.nome
                                                ? errors.nome.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="contaGerencialId"
                                            id="contaGerencialId"
                                            inputRef={register}
                                            options={contasGerenciais}
                                            value={
                                                contaGerencialId
                                                    ? contaGerencialId
                                                    : ''
                                            }
                                            defaultValue={
                                                contaGerencialId
                                                    ? contaGerencialId
                                                    : ''
                                            }
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setContaGerencialId(
                                                    newValue ? newValue : null
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="contaGerencialId"
                                                    id="contaGerencialId"
                                                    variant="standard"
                                                    label="Conta Gerencial:"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                        <p className={classes.errors}>
                                            {errors.contaGerencialId
                                                ? errors.contaGerencialId
                                                      .message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={4} display="flex">
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Autocomplete
                                            name="tipo"
                                            id="tipo"
                                            inputRef={register}
                                            options={tipos}
                                            value={tipo ? tipo : ''}
                                            defaultValue={tipo ? tipo : ''}
                                            getOptionLabel={(option) =>
                                                option.label ? option.label : ''
                                            }
                                            onChange={(event, newValue) => {
                                                setTipo(
                                                    newValue ? newValue : null
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={register}
                                                    name="tipo"
                                                    id="tipo"
                                                    variant="standard"
                                                    label="Tipo:*"
                                                    placeholder="Selecione"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                        />
                                        <p className={classes.errors}>
                                            {errors.tipo
                                                ? errors.tipo.message
                                                : ''}
                                        </p>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} display="flex">
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    display="flex"
                                    justify="flex-end"
                                >
                                    <FormControlLabel
                                        inputRef={register}
                                        name="aplicadoContasPagar"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={aplicadoContasPagar}
                                                onChange={() =>
                                                    setAplicadoContasPagar(
                                                        !aplicadoContasPagar
                                                    )
                                                }
                                            />
                                        }
                                        label="Aplicado à Contas a Pagar"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        inputRef={register}
                                        name="aplicadoContasReceber"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={aplicadoContasReceber}
                                                onChange={() =>
                                                    setAplicadoContasReceber(
                                                        !aplicadoContasReceber
                                                    )
                                                }
                                            />
                                        }
                                        label="Aplicado à Contas a Receber"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        inputRef={register}
                                        name="aplicadoAdiantamentosPagar"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={aplicadoAdiantamentosPagar}
                                                onChange={() =>
                                                    setAplicadoAdiantamentosPagar(
                                                        !aplicadoAdiantamentosPagar
                                                    )
                                                }
                                            />
                                        }
                                        label="Aplicado à Adiantamentos a Pagar"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        inputRef={register}
                                        name="aplicadoAdiantamentosReceber"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={aplicadoAdiantamentosReceber}
                                                onChange={() =>
                                                    setAplicadoAdiantamentosReceber(
                                                        !aplicadoAdiantamentosReceber
                                                    )
                                                }
                                            />
                                        }
                                        label="Aplicado à Adiantamentos a Receber"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <FooterBottons />
                </form>
            </Container>
        </Page>
    )
}
