import React, { useState, useEffect } from 'react'
import Page from '../../components/Page'
import Titulo from '../../components/Titulo/index'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    Container,
    Card,
    CardContent,
    TextField,
    makeStyles,
    Button,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import CentroCustoService from '../../services/CentroCustoService'
import SafraService from '../../services/SafraService'
import ToastHelper from '../../utils/toastHelper'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import * as Yup from 'yup'
import ObjectHelper from '../../utils/objetcHelper'
import MaterialTable from 'material-table'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Edit3 as Edit3Icon } from 'react-feather'
import GrupoSafraService from 'services/GrupoSafraService'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#009688',
        },
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    card: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '80%',
    },
    errors: {
        color: 'red',
    },
    margin: {
        margin: theme.spacing(1),
    },
    addButton: {
        marginTop: 10,
    },
}))

const CentroCustoRateioEsquema = Yup.object().shape({
    safraId: Yup.string().required('Campo obrigatório'),
    grupoSafraId: Yup.string().required('Campo obrigatório'),
})

export const CentroCustoCadastroSafra = (props) => {
    const {
        match: { params },
    } = props
    const classes = useStyles()
    const FazendaId = JSON.parse(localStorage.getItem('fazenda')).value

    const colunas = [
        {
            title: 'Safra',
            field: 'grupoSafra',
            editComponent: (props) => {
                return (
                    <Autocomplete
                        name="grupoSafraId"
                        id="grupoSafraId"
                        inputRef={register}
                        options={GruposSafras}
                        value={{
                            label: props.value,
                            value: props.rowData.centroCustoId,
                        }}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option) => option.value}
                        size="small"
                        onChange={(event, newValue) => {
                            var data = { ...props.rowData }
                            data.grupoSafraId = newValue
                            props.onRowDataChange(data)
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    name="grupoSafraId"
                                    id="grupoSafraId"
                                    inputRef={register}
                                    value={props.value}
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )
                        }}
                    />
                )
            },
        },
        {
            title: 'Atividade',
            field: 'safra',
            editComponent: (props) => {
                return (
                    <Autocomplete
                        name="safraId"
                        id="safraId"
                        inputRef={register}
                        options={safras}
                        value={{
                            label: props.value,
                            value: props.rowData.centroCustoId,
                        }}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option) => option.value}
                        size="small"
                        onChange={(event, newValue) => {
                            var data = { ...props.rowData }
                            data.safraId = newValue
                            props.onRowDataChange(data)
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    name="safraId"
                                    id="safraId"
                                    inputRef={register}
                                    value={props.value}
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )
                        }}
                    />
                )
            },
        },
    ]

    const [GruposSafras, setGruposSafras] = useState([])
    const [safras, setSafras] = useState([])
    const [dados, setDados] = useState([])
    const [selectedGrupoSafra, setSelectedGrupoSafra] = useState('')
    const [selectedSafra, setSelectedSafra] = useState('')
    const { register, handleSubmit, errors  } = useForm({
        defaultValues: {
            id: '',
            safraId: '',
            grupoSafraId:''
        },
        resolver: yupResolver(CentroCustoRateioEsquema),
    })

    const onSubmit = async (param) => {
        var array = dados
        param.grupoSafraId = selectedGrupoSafra.value
        param.grupoSafra = selectedGrupoSafra.label
        param.safraId = selectedSafra.value
        param.safra = selectedSafra.label
        param.centroCustoId = params.id
        await array.push(param)
        await setDados(array)
        await setSelectedGrupoSafra(undefined)
        await setSelectedSafra(undefined)
        await atualizaOpcoesDeSafra()
    }

    useEffect(() => {
        if (params.id) {
            buscarCentroCustoSafraPorId()
            buscarGruposSafras()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function atualiza() {
            await atualizaOpcoesDeSafra()
        }
        atualiza()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.length])

    const enviarDados = async () => {
        await CentroCustoService.cadastrarCentroCustoSafra({
            safras: dados,
            centroCustoId: params.id,
        })
        props.history.goBack()
        return ToastHelper.success('Cadastro efetuado com sucesso!')
    }

    const buscarCentroCustoSafraPorId = async () => {
        const { data } = await CentroCustoService.buscarCentroCustoSafrasPorId(
            params.id
        )
        setDados(data)
    }

    const buscarGruposSafras = async () => {
        const { data } = await GrupoSafraService.buscarGrupoSafras(FazendaId)
        var arrayConvert = await ObjectHelper.getValuesParseToSelect(data)
        await setGruposSafras(arrayConvert)
    }

    useEffect(() => {
        if(selectedGrupoSafra) {
            atualizaOpcoesDeSafra()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGrupoSafra])

    const deletarItem = async (novasParcelas) => {
        let data = [...novasParcelas]
        await setDados(data)
    }

    const atualizaOpcoesDeSafra = () => {
        if(selectedGrupoSafra) {
        SafraService.buscarSafrasPorGrupoSafra(FazendaId,selectedGrupoSafra.value).then((response) => {
            let arrayConvert = ObjectHelper.getValuesParseToSelect(
                response.data
            )
            dados.forEach((a) => {
                arrayConvert.forEach((b) => {
                    if (a.safraId === b.value) {
                        let index = arrayConvert.indexOf(b)
                        arrayConvert.splice(index, 1)
                    }
                })
            })
            setSafras(arrayConvert)
        })
    }
    }

    return (
        <Page className={classes.root} title="Cadastro de Centro de Custo">
            <Container maxWidth={'xl'}>
                <Titulo titulo={'Cadastro de Centro de Custos - Safras'} />
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container item md={12} display="flex">
                                <Grid container item md={6} display="flex">
                                    <Grid container item md={6} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Autocomplete
                                                name="grupoSafraId"
                                                id="grupoSafraId"
                                                options={GruposSafras}
                                                inputRef={register}
                                                value={selectedGrupoSafra}
                                                defaultValue={
                                                    selectedGrupoSafra
                                                }
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                size="large"
                                                onChange={(event, newValue) => {
                                                    setSelectedGrupoSafra(
                                                        newValue
                                                    )
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={register}
                                                        name="grupoSafraId"
                                                        id="grupoSafraId"
                                                        variant="standard"
                                                        label="Safra*"
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className={classes.errors}>
                                                {errors.grupoSafraId
                                                    ? errors.grupoSafraId
                                                          .message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={6} display="flex">
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Autocomplete
                                                name="safraId"
                                                id="safraId"
                                                options={safras}
                                                inputRef={register}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                      setSelectedSafra(newValue)
                                                    }
                                                }}
                                                value={selectedSafra}
                                                disabled={
                                                    !selectedGrupoSafra
                                                    //(form.centroCustoId || form.patrimonioId) &&
                                                    // props.tipo === 'pagar'
                                                }
                                                
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="safraId"
                                                        id="safraId"
                                                        inputRef={register}
                                                        variant="standard"
                                                        label={'Atividade*'}
                                                        placeholder="Selecione"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className={classes.errors}>
                                                {errors.safraId
                                                    ? errors.safraId.message
                                                    : ''}
                                            </p>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item md={1} display="flex">
                                    <Fab
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        className={classes.addButton}
                                        aria-label="add"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <div>
                        <MuiThemeProvider theme={theme}>
                            <MaterialTable
                                title={''}
                                columns={colunas}
                                data={dados}
                                options={{
                                    pageSize: 500,
                                    paging: false,
                                    padding: 'dense',
                                    rowStyle: {
                                        fontSize: 14,
                                    },
                                    search: false,
                                    actionsColumnIndex: -1,
                                    emptyRowsWhenPaging: false,
                                }}
                                editable={{
                                    /*onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    var array = dados;
                                                    array[
                                                        oldData.tableData.id
                                                    ] = newData;
                                                    setDados(array);
                                                    resolve();
                                                }, 1000);
                                            }),*/

                                    isDeletable: (rowData) => !props.disabled,

                                    onRowDelete: (oldData) =>
                                        new Promise(async (resolve, reject) => {
                                            const dataDelete = [...dados]
                                            const index = oldData.tableData.id
                                            await dataDelete.splice(index, 1)
                                            await deletarItem(dataDelete)

                                            /*ToastHelper.success(
                                                    'Parcela excluída com sucesso!',
                                                );*/
                                            resolve()
                                        }),
                                }}
                                /*actions={[
                                        {
                                            icon: DeleteIcon,
                                            tooltip: 'Excluir',
                                            onClick: (event, rowData) =>
                                                deletarItem(rowData),
                                        },
                                    ]}*/
                                // onOrderChange={props.onOrderChange}
                                icons={{ Edit: Edit3Icon }}
                                localization={{
                                    header: {
                                        actions: 'Ações',
                                    },
                                    body: {
                                        emptyDataSourceMessage:
                                            'Nenhum registro para exibir',
                                        addTooltip: 'Adicionar',
                                        deleteTooltip: 'Excluir',
                                        editTooltip: 'Edição rápida',
                                        editRow: {
                                            deleteText:
                                                'Deseja excluir essa linha?',
                                            cancelTooltip: 'Cancelar',
                                            saveTooltip: 'Confirmar',
                                        },
                                    },
                                    toolbar: {
                                        searchTooltip: 'Pesquisar',
                                        searchPlaceholder: 'Pesquisar',
                                    },
                                }}
                            />
                        </MuiThemeProvider>

                        <Grid
                            container
                            item
                            xs={12}
                            display="flex"
                            className={classes.card}
                        >
                            <Grid
                                xs={6}
                                container
                                item
                                direction="row"
                                alignItems="center"
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={() => props.history.goBack()}
                                >
                                    Voltar
                                </Button>
                            </Grid>
                            <Grid
                                xs={6}
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={enviarDados}
                                    size="small"
                                >
                                    Confirmar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Container>
        </Page>
    )
}
